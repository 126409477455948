import Axios from 'axios';
import { formatHandleError, numberToReal } from '../../helpers/formatData';

export const getMercadoLivreCategoryPrediction = async (company, productTitle) => {
  const response = await Axios.get(
    `/mercado-livre/categories/predict/${company}/${encodeURIComponent(productTitle)}`,
  );

  const { mercadoLivreCategory } = response.data;
  return mercadoLivreCategory;
};

export const getAllCategories = async ({ setSnackbar = () => {} }) => {
  try {
    const response = await Axios.get(`/catalog/categories`);

    const { categories } = response.data;
    return categories;
  } catch (error) {
    return formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao buscar categorias no Mercado Livre`,
      error,
    });
  }
};

export const getMercadoLivreCategoryRoot = async () => {
  const response = await Axios.get(`/mercado-livre/categories/`);

  const { mercadoLivreCategoryRoot } = response.data;
  return mercadoLivreCategoryRoot;
};

export const getMercadoLivreCategoryChildren = async (company, categoryId) => {
  const response = await Axios.get(`/mercado-livre/categories/${company}/${categoryId}`);

  const { mercadoLivreCategoryChildren } = response.data;
  return mercadoLivreCategoryChildren;
};

export const getMercadoLivreAttributes = async (company, categoryId) => {
  const response = await Axios.get(`/mercado-livre/attributes/${company}/${categoryId}`);

  const { attributesList } = response.data;
  return attributesList;
};

export const getMercadoLivreProduct = async (mlb) => {
  const response = await Axios.get(`/mercado-livre/products/${mlb}`);

  const { mercadoLivreProduct } = response.data;
  return mercadoLivreProduct;
};

export const updateMercadoLivreProduct = async (mlb, fieldsToUpdate) => {
  const response = await Axios.put(`/mercado-livre/products/${mlb}`, fieldsToUpdate);

  const { mercadoLivreProduct } = response.data;
  return mercadoLivreProduct;
};

export const deleteMercadoLivreProduct = async (mlb) => {
  const response = await Axios.delete(`/mercado-livre/products/${mlb}`);

  const { mercadoLivreProductDeleted } = response.data;
  return mercadoLivreProductDeleted;
};

export const getMercadoLivreAdForm = async ({
  productForm,
  mercadoLivreAd,
  mercadoLivreAdForm,
  updatedMercadoLivreProduct,
  setInitialMercadoLivreAdForm = () => {},
  setMercadoLivreAdForm = () => {},
  setLoadingMercadoLivreAdForm = () => {},
  setSnackbar = () => {},
}) => {
  try {
    if (!mercadoLivreAd.mlb) {
      return null;
    }

    setLoadingMercadoLivreAdForm(true);

    const mlProduct = await getMercadoLivreProduct(mercadoLivreAd.mlb);
    const mercadoLivreProduct = updatedMercadoLivreProduct || mlProduct;

    const mercadoLivreCategoryChildren = await getMercadoLivreCategoryChildren(
      mercadoLivreAd.company,
      mercadoLivreProduct.category_id,
    );

    const responseTypes = await Axios.get('/mercado-livre/ads/types');
    const { adTypes } = responseTypes.data;

    const selectedType = adTypes.find(
      (adType) => adType.id === mercadoLivreProduct.listing_type_id,
    );

    const responseDescription = await Axios.get(
      `https://api.mercadolibre.com/items/${mercadoLivreAd.mlb}/description`,
    );

    const formatedAdForm = {
      mlb: mercadoLivreProduct.id,
      permalink: mercadoLivreProduct.permalink,
      company: mercadoLivreAd.company,
      title: mercadoLivreProduct.title,
      brand: productForm.brand,
      category: {
        categoryId: mercadoLivreCategoryChildren.id,
        name: mercadoLivreCategoryChildren.name,
      },
      adType: {
        id: selectedType.id,
        name: selectedType.name,
        siteId: selectedType.site_id,
      },
      status: mercadoLivreProduct.status,
      health: mercadoLivreProduct.health,
      thumbnail: mercadoLivreProduct.thumbnail,
      videoLink: mercadoLivreProduct.video_id
        ? `https://www.youtube.com/watch?v=${mercadoLivreProduct.video_id}`
        : '',
      description: responseDescription.data.plain_text,
      attributes: mercadoLivreProduct.attributes.map((attribute) => ({
        id: attribute.id,
        unitId: attribute.values?.[0]?.struct?.unit || '',
        value: attribute.values?.[0]?.struct?.number || attribute.value_name,
      })),
      price: mercadoLivreProduct.price,
      stock: mercadoLivreProduct.available_quantity,
      offerPrice: mercadoLivreAd.offerPrice,
      quantity: mercadoLivreAd.quantity,
      soldQuantity: mercadoLivreProduct.sold_quantity,
      companies: [mercadoLivreAd.company],
      publishOnMlCatalog: mercadoLivreProduct.catalog_listing,
      mercadoLivreCatalogProductId: mercadoLivreProduct.catalog_product_id || '',
    };

    setInitialMercadoLivreAdForm(formatedAdForm);
    setMercadoLivreAdForm(formatedAdForm);
    return setLoadingMercadoLivreAdForm(false);
  } catch (error) {
    setLoadingMercadoLivreAdForm(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao buscar produto ${mercadoLivreAdForm.mlb} no Mercado Livre`,
      error,
    });
  }
};

export const getMercadoLivreProductsBySku = async ({
  setMercadoLivreAd = () => {},
  setMercadoLivreAds,
  setLoadingMercadoLivreAds = () => {},
  setSnackbar = () => {},
  productId,
}) => {
  try {
    setLoadingMercadoLivreAds(true);

    const response = await Axios.get(`/mercado-livre/products/by-sku/${productId}`);
    const { mercadoLivreAdsList } = response.data;

    if (mercadoLivreAdsList.length > 0) {
      setMercadoLivreAd(mercadoLivreAdsList[mercadoLivreAdsList.length - 1]);
    }

    setMercadoLivreAds(mercadoLivreAdsList);
    return setLoadingMercadoLivreAds(false);
  } catch (error) {
    setLoadingMercadoLivreAds(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao buscar produto ${productId} no Mercado Livre`,
      error,
    });
  }
};

export const updateMercadoLivreAdStatus = async ({
  mercadoLivreAdStatus,
  setMercadoLivreAds = () => {},
  setLoadingChangeMercadoLivreAdStatus = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingChangeMercadoLivreAdStatus(true);

    const response = await Axios.put(`/mercado-livre/products/ad-status`, {
      mercadoLivreAdStatus,
    });

    setLoadingChangeMercadoLivreAdStatus(false);
    const { mercadoLivreAds } = response.data;
    return setMercadoLivreAds(mercadoLivreAds);
  } catch (error) {
    setLoadingChangeMercadoLivreAdStatus(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao atualizar status de um anúncio no Mercado Livre`,
      error,
    });
  }
};

export const getMercadoLivreResume = async ({
  searchText = '',
  sellerId = '',
  filtersOptions = [],
  setLoadingProductsResume,
  setFiltersOptions,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingProductsResume(true);

    const response = await Axios.get(`/mercado-livre/products/resume`, {
      params: {
        searchText,
        sellerId,
        filtersOptions,
      },
    });

    setFiltersOptions(response.data);
    return setLoadingProductsResume(false);
  } catch (error) {
    setLoadingProductsResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar resumo dos produtos',
      error,
    });
  }
};

export const getMercadoLivreProducts = async ({
  setSnackbar = () => {},
  setMercadoLivreAdsList,
  setLoadingMercadoLivreAdsList = () => {},
  setMercadoLivreAdsCount = () => {},
  limit,
  skip,
  sortBy,
  sortDirection,
  searchText = '',
  sellerId = '',
  filtersOptions = [],
}) => {
  try {
    setLoadingMercadoLivreAdsList(true);

    const response = await Axios.get(`/mercado-livre/products`, {
      params: {
        limit,
        skip,
        sortBy,
        sortDirection,
        searchText,
        sellerId,
        filtersOptions,
      },
    });

    const { totalResults, mercadoLivreProductsList } = response.data;
    setMercadoLivreAdsCount(totalResults);
    setMercadoLivreAdsList(mercadoLivreProductsList);

    return setLoadingMercadoLivreAdsList(false);
  } catch (error) {
    setLoadingMercadoLivreAdsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao carregar produtos do Mercado Livre',
      error,
    });
  }
};

export const getMercadoLivrePrice = async ({
  company,
  productId,
  mlb,
  quantity,
  offerPrice,
  type,
  setMercadoLivreBasePrice = () => {},
  setMercadoLivreAdCost = () => {},
  setMercadoLivreShippingCost = () => {},
  setMercadoLivrePriceTooltip = () => {},
  setSnackbar = () => {},
}) => {
  try {
    const response = await Axios.get('/mercado-livre/ads/cost', {
      params: {
        company,
        productId,
        mlb,
        quantity,
        offerPrice,
        type,
      },
    });

    const { basePrice, newPrice, adCost, shippingCost } = response.data;

    setMercadoLivreBasePrice(basePrice);
    setMercadoLivreAdCost(adCost);
    setMercadoLivreShippingCost(shippingCost);

    const priceTooltipMessage = [`Produto R$ ${numberToReal(basePrice)}`];

    if (basePrice <= 7) {
      priceTooltipMessage.push('Fixa R$ 7,00');
    }

    if (adCost > 0) {
      priceTooltipMessage.push(`Taxa R$ ${numberToReal(adCost)}`);
    }

    if (shippingCost > 0) {
      priceTooltipMessage.push(`Frete R$ ${numberToReal(shippingCost)}`);
    }

    setMercadoLivrePriceTooltip(priceTooltipMessage.join(' + '));
    return newPrice;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao buscar taxas no Mercado Livre`,
      error,
    });
    return 0;
  }
};

export const getMercadoLivreShippingStamp = async ({
  orders,
  setLoadingShippingStamp,
  setSnackbar,
}) => {
  try {
    setLoadingShippingStamp(true);
    const ordersToMercadoLivreShipping = [];

    orders.forEach((order) => {
      if (order.othersIds?.idShippingMercadoLivre) {
        ordersToMercadoLivreShipping.push({
          shippingId: order.othersIds.idShippingMercadoLivre,
          company: order.company,
        });
      }
    });

    const response = await Axios.get(`/mercado-livre/stamps/bulk-print`, {
      params: {
        ordersToMercadoLivreShipping,
      },
    });

    const { stampsLinks } = response.data;

    stampsLinks.forEach((stamp) => {
      window.open(stamp);
    });

    setLoadingShippingStamp(false);
  } catch (error) {
    setLoadingShippingStamp(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Não conseguimos imprimir a etiqueta. Verifique o sistema de impressão.',
      error,
    });
  }
};
