import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { getCities } from '../../../services/melhorGestao/users';

function UserCityAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedCity,
  size = 'small',
  label = 'Cidade',
  disabled,
  error,
  variant,
  className,
}) {
  const [citiesList, setCitiesList] = useState([]);
  const [loadingCitiesList, setLoadingCitiesList] = useState(false);

  useEffect(() => {
    getCities({ setLoadingCitiesList, setCitiesList, setSnackbar });
  }, []);

  const filterCity = createFilterOptions({
    stringify: (option) => option.city + option.state,
  });

  return (
    <Autocomplete
      size={size}
      options={citiesList}
      filterOptions={filterCity}
      getOptionLabel={(option) => `${option.state} - ${option.city}`}
      isOptionEqualToValue={(option, value) =>
        option.city === value.city && option.state === value.state
      }
      renderOption={(props, option) => (
        <Typography {...{ ...props, key: `${option.state} - ${option.city}` }} variant="caption">
          {`${option.state} - ${option.city}`}
        </Typography>
      )}
      disabled={disabled}
      value={selectedCity}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      loading={loadingCitiesList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(UserCityAutocomplete);
