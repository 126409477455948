import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function CustomDatePicker({
  variant,
  fullWidth,
  size,
  margin,
  label,
  openTo,
  views,
  value,
  onChange = () => {},
  onError = () => {},
  format,
  readOnly,
  minDate,
  maxDate,
  disabled,
  error,
  color,
  className,
}) {
  let paddingTop;

  if (label && size === 'small') {
    if (!variant || variant === 'outlined') {
      paddingTop = '14px';
    }
    if (variant === 'filled') {
      paddingTop = '24px';
    }
  }

  let fontSize;

  if (size === 'small') {
    fontSize = 14;
  }

  return (
    <DatePicker
      label={label}
      openTo={openTo}
      views={views}
      value={dayjs(value)}
      onChange={onChange}
      onError={onError}
      format={format}
      readOnly={readOnly}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      error={error}
      slotProps={{
        textField: {
          color,
          variant,
          fullWidth,
          size,
          margin,
          inputProps: {
            style: {
              fontSize,
              paddingTop,
            },
          },
        },
      }}
      className={className}
    />
  );
}

export default CustomDatePicker;
