const newEntryProduct = {
  positionOnList: -1,
  name: '',
  supplierId: 'Não informado',
  gtin: '',
  ncm: '',
  cest: '',
  cfop: '',
  price: 0,
  icms: 0,
  icmsRate: 0,
  ipi: 0,
  ipiRate: 0,
  st: 0,
  hasSt: false,
  simples: 0.0,
  stRate: 0,
  pis: 0,
  cofins: 0,
  difalRate: 0,
  unitaryCost: 0,
  simplesCost: 0,
  totalCost: 0,
  othersCost: 0,
  othersCostRate: 0,
  shippingCost: 0,
  shippingRate: 0,
  discountValue: 0,
  entryQuantity: 0,
  quantity: 0,
  entryUnity: '',
  entryUnityId: 0,
  entryUnityRate: 1,
  unity: '',
  unityId: 0,
  unityRate: 1,
  entryUnitaryCost: 0,
  profitMargin: 0.35,
  additionalOnline: 0,
  hasAdditionalOnline: false,
  physicalStoreDiscount: 0,
  sincProductId: 0,
  sincName: '',
  sincLastCost: 0,
  sincLastPrice: 0,
  priceConfirmed: false,
  importData: {
    documentNumber: '',
    documentDate: null,
    clearanceSite: '',
    clearanceUf: '',
    viaTransport: '',
    intermediation: '',
    addition: '',
    seqAddition: '',
    afrmmValue: 0,
  },
};

export default newEntryProduct;
