import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useState } from 'react';
import dayjs from 'dayjs';
import SimpleDialog from '../../../Common/SimpleDialog';
import PaymentsTable from './PaymentsTable';
import CustomInput from '../../../CustomInput';
import {
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../helpers/formatData';
import paymentDescriptionList from '../../../../constant/paymentDescriptionList';
import CustomDatePicker from '../../../CustomDatePicker';
import PaymentMethods from '../../../Payments/PaymentMethods';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function PaymentsTab({
  products,
  payments,
  totalValueProducts,
  totalValue,
  setEntryOrder,
  blockFields,
}) {
  const classes = useStyles();

  const [selectedMethod, setSelectedMethod] = useState('Boleto');
  const [paymentValue, setPaymentValue] = useState(totalValue);
  const [dueDate, setDueDate] = useState(new Date());
  const [selectedCondition, setSelectedCondition] = useState('A VISTA');

  const [openAddPayment, setOpenAddPayment] = useState(false);
  const handleOpenAddPayment = () => setOpenAddPayment(true);
  const handleCloseAddPayment = () => setOpenAddPayment(false);

  const handleChangeDueDate = (date) => {
    setDueDate(date);
  };

  const handleChangeValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setPaymentValue(formatReal(value));
  };

  const handleConditionOnChange = (event, newValue) => {
    setSelectedCondition(newValue || 'A VISTA');
  };

  const handleConfirmPayment = () => {
    if (Number.isNaN(parseFloat(paymentValue)) || !(parseFloat(paymentValue) >= 0)) {
      return null;
    }

    let paymentQuantity = 0;
    let dueDateDays = 0;

    paymentDescriptionList.forEach((paymentCondition) => {
      if (selectedCondition === paymentCondition.string) {
        paymentQuantity = paymentCondition.quantityMonth;
        dueDateDays = paymentCondition.dueDateDays;
      }
    });

    const paymentsFormated = [];

    for (let index = 0; index < paymentQuantity; index += 1) {
      paymentsFormated.push({
        method: selectedMethod,
        value: parseFloat(paymentValue) / paymentQuantity,
        dueDate: dayjs(dueDate).add(dueDateDays, 'day'),
        condition: selectedCondition,
      });

      dueDateDays += 30;
    }

    setEntryOrder((oldFields) => ({
      ...oldFields,
      payments: paymentsFormated,
    }));

    return handleCloseAddPayment();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Pagamentos</Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOpenAddPayment} disabled={blockFields}>
              Adicionar Pagamento
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {products && products.length > 0 ? (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Total do pedido:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">R$ {numberToRealWithPeriod(totalValue)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Total dos produtos:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    R$ {numberToRealWithPeriod(totalValueProducts)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {payments && payments.length > 0 ? (
        <Grid item xs={12}>
          <Box marginTop={2}>
            <PaymentsTable paymentsList={payments} />
          </Box>
        </Grid>
      ) : null}

      {openAddPayment ? (
        <SimpleDialog
          maxWidth="xs"
          openDialog={openAddPayment}
          handleClose={handleCloseAddPayment}
          dialogTitle="Adicionar pagamento"
          dialogText="Adicionar o pagamento combinado com o fornecedor"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseAddPayment}
          handleConfirmButton={handleConfirmPayment}
          content={
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Valor"
                      value={paymentValue ? numberToRealWithPeriod(paymentValue) : '0,00'}
                      onChange={handleChangeValue}
                      error={
                        Number.isNaN(parseFloat(paymentValue)) || parseFloat(paymentValue) <= 0
                      }
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Desconto"
                      value={numberToRealWithPeriod(0)}
                      onChange={() => {}}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Acréscimo"
                      value={numberToRealWithPeriod(0)}
                      onChange={() => {}}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomDatePicker
                      size="small"
                      label="Data de vencimento"
                      format="DD/MM/YYYY"
                      value={dueDate}
                      onChange={handleChangeDueDate}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justifyContent="flex-end">
                      <Box marginTop="15px">
                        <Typography variant="h1">
                          R$ {numberToRealWithPeriod(totalValue)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Autocomplete
                  size="small"
                  options={paymentDescriptionList.map(
                    (paymentDescription) => paymentDescription.string,
                  )}
                  getOptionLabel={(option) => option}
                  value={selectedCondition}
                  onChange={handleConditionOnChange}
                  noOptionsText="Opção não encontrada"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condição"
                      variant="outlined"
                      error={!selectedCondition}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <PaymentMethods
                  paymentMethod={selectedMethod}
                  setPaymentMethod={(method) => {
                    setSelectedMethod(method);
                  }}
                  excludeMethods={['Crédito', 'Link de pagamento']}
                />
              </Grid>
            </Grid>
          }
          disableConfirmButton={!paymentValue}
        />
      ) : null}
    </Grid>
  );
}

export default memo(PaymentsTab);
