import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    alignItems: 'center',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  sku: {
    width: '8%',
    textAlign: 'center',
    fontSize: 8,
  },
  image: {
    width: '8%',
    textAlign: 'center',
    fontSize: 8,
  },
  name46: {
    width: '40%',
    margin: 1,
    fontSize: 8,
  },
  name58: {
    width: '50%',
    margin: 1,
    fontSize: 8,
  },
  code: {
    width: '22%',
    textAlign: 'center',
    fontSize: 8,
  },
  quantity: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
  unity: {
    width: '10%',
    textAlign: 'center',
    fontSize: 8,
  },
});

function TableHeader({ orderId }) {
  return (
    <View style={styles.row}>
      <Text style={styles.sku}>SKU</Text>
      <Text style={styles.image} />
      <Text style={orderId ? styles.name46 : styles.name58}>Item/Nome</Text>
      <Text style={styles.code}>Código</Text>
      <Text style={styles.quantity}>Qtd.</Text>
      {orderId ? <Text style={styles.unity}>Und.</Text> : null}
    </View>
  );
}

export default TableHeader;
