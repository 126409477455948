import Axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { formatDate, numberToReal } from '../../../helpers/formatData';
import SearchField from '../../Common/SearchField';
import OrderDetails from '../../Orders/Details';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import filterPaymentSituationsInitial from '../../../constant/filterPaymentSituationsInitial';
import UserAutocomplete from '../../Common/UserAutocomplete';
import CategoryPaymentAutocomplete from '../../Common/CategoryPaymentAutocomplete';
import { getOrder } from '../../../services/melhorGestao/orders';
import SituationsFiltersPapers from '../../Common/SituationsFiltersPapers';
import { getCategoriesByType } from '../../../services/melhorGestao/payments';
import CustomPagination from '../../Common/CustomPagination';

const useStyles = makeStyles((theme) => ({
  smallCell: {
    width: 40,
  },
  mediumCell: {
    width: 80,
  },
  noPaddingCell: {
    padding: 0,
  },
  img: {
    width: '100%',
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
  },
  fabLeftAbove: {
    position: 'fixed',
    bottom: 60,
  },
  fabLeftBelow: {
    position: 'fixed',
    bottom: 20,
  },
  headTableCheckbox: {
    width: 35,
  },
  tableRowEmptyStock: {
    backgroundColor: '#ffefef',
    '&:hover': {
      backgroundColor: '#ffe4e4',
    },
    cursor: 'pointer',
  },
  tableRowProduct: {
    cursor: 'pointer',
  },
  tableColumnCash: {
    minWidth: 90,
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
  inputPropsCenter: {
    textAlign: 'center',
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function PaymentsTable({
  paymentsList,
  countPayments,
  selectedPayment,
  setSearchTextSubmited,
  handleClickItem,
  setPayed = () => {},
  setStartDate = () => {},
  setEndDate = () => {},
  handleChangePage,
  page,
  setPage = () => {},
  limit,
  type,
  setType,
  loading,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [filterPayed, setFilterPayed] = useState({
    payed: true,
    notPayed: true,
    toOverdue: true,
    overdue: true,
  });

  const [situationsFilters, setSituationsFilters] = useState(filterPaymentSituationsInitial);

  const handleGetRenderValueStatus = () => {
    if (filterPayed.payed && filterPayed.notPayed && filterPayed.toOverdue && filterPayed.overdue) {
      return 'Todas situações';
    }
    if (
      filterPayed.payed &&
      !filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      return 'Apenas pagos';
    }
    if (
      !filterPayed.payed &&
      filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      return 'Apenas não pagos';
    }
    if (
      !filterPayed.payed &&
      !filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      return 'Nenhuma situação';
    }
    if (
      !filterPayed.payed &&
      filterPayed.notPayed &&
      filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      return 'A vencer';
    }
    if (
      !filterPayed.payed &&
      filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      filterPayed.overdue
    ) {
      return 'Vencidos';
    }
    return 'Outro';
  };

  const [openSelectPayedFilter, setOpenSelectPayedFilter] = useState(false);
  const handleOpenSelectPayedFilter = () => {
    setOpenSelectPayedFilter(true);
  };
  const handleCloseSelectPayedFilter = () => {
    setPage(1);
    setOpenSelectPayedFilter(false);
  };

  const handleConfirmFilter = () => {
    if (
      filterPayed.payed &&
      !filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      setPayed(true);
      setStartDate(null);
      return setEndDate(null);
    }
    if (
      !filterPayed.payed &&
      filterPayed.notPayed &&
      !filterPayed.toOverdue &&
      !filterPayed.overdue
    ) {
      setPayed(false);
      setStartDate(null);
      return setEndDate(null);
    }
    if (filterPayed.toOverdue && !filterPayed.overdue) {
      setFilterPayed((oldFields) => ({
        ...oldFields,
        payed: false,
        notPayed: true,
      }));
      setPayed(false);
      setStartDate(new Date());
      return setEndDate(new Date(new Date(new Date().setDate(new Date().getDate() + 15))));
    }
    if (!filterPayed.toOverdue && filterPayed.overdue) {
      setFilterPayed((oldFields) => ({
        ...oldFields,
        payed: false,
        notPayed: true,
      }));
      setPayed(false);
      setStartDate(null);
      return setEndDate(new Date());
    }
    if (filterPayed.toOverdue && filterPayed.overdue) {
      if (filterPayed.payed && !filterPayed.notPayed) {
        setPayed(true);
      }
      if (!filterPayed.payed && filterPayed.notPayed) {
        setPayed(false);
      }
      if (
        (filterPayed.payed && filterPayed.notPayed) ||
        (!filterPayed.payed && !filterPayed.notPayed)
      ) {
        setPayed();
      }
      setStartDate(null);
      return setEndDate(null);
    }
    return setPayed();
  };

  const [refreshFilterToken, setRefreshFilterToken] = useState(Math.random());
  const handleRefreshSituationFilter = useCallback(() => setRefreshFilterToken(Math.random()), []);

  useEffect(() => {
    handleConfirmFilter();
  }, [refreshFilterToken]);

  const checkIfAnyActive = (filterArray) => {
    let anyActive = false;
    filterArray.forEach((filter) => {
      if (filter.active) {
        anyActive = true;
      }
    });
    return anyActive;
  };

  const handleClickFilter = () => {
    setPage(1);
    if (checkIfAnyActive(situationsFilters)) {
      setFilterPayed({
        payed: situationsFilters.find((filter) => filter.id === 'showOnlyPaid').active,
        notPayed: situationsFilters.find((filter) => filter.id === 'showOnlyNotPaid').active,
        toOverdue: situationsFilters.find((filter) => filter.id === 'showOnlyToOverdue').active,
        overdue: situationsFilters.find((filter) => filter.id === 'showOnlyOverdue').active,
      });
    } else {
      setFilterPayed({
        payed: true,
        notPayed: true,
        toOverdue: true,
        overdue: true,
      });
    }
    handleRefreshSituationFilter();
  };

  const [checkedPayments, setCheckedPayments] = useState([]);

  const handleOnChangeSearchCategory = (event, newValue) => {
    setPage(1);
    if (newValue) {
      setSearchTextSubmited(newValue._id);
    } else {
      setSearchTextSubmited('');
    }
  };

  const [categoriesList, setCategoriesList] = useState([]);
  const [walletsList, setWalletsList] = useState([]);

  const getLists = async () => {
    const receivementCategories = await getCategoriesByType({
      type: 'Recebimento',
      setSnackbar,
    });
    const paymentCategories = await getCategoriesByType({
      type: 'Pagamento',
      setSnackbar,
    });
    if (receivementCategories || paymentCategories) {
      setCategoriesList([...receivementCategories, ...paymentCategories]);
    }

    const response = await Axios.get('/wallets/list');
    const wallets = response.data;
    setWalletsList(wallets);
  };

  useEffect(() => {
    getLists();
  }, []);

  const getLabelColorType = (payment) => {
    if (payment.payed) {
      if (payment.type === 'Recebimento') {
        return { bg: '#00c600', color: '#fff' };
      }
      return { bg: '#ff2222', color: '#fff' };
    }
    return { bg: undefined, color: undefined };
  };

  const getLabelColorStatus = (payment) => {
    if (payment.payed) {
      return { color: '#00c600' };
    }
    return { color: '#ff2222' };
  };

  const handleCheckPayment = ({ paymentId }) => {
    let newCheckedPayments = checkedPayments;
    newCheckedPayments = newCheckedPayments.filter(
      (checkedPaymentId) => checkedPaymentId !== paymentId,
    );
    newCheckedPayments.push(paymentId);

    return setCheckedPayments(newCheckedPayments);
  };

  const handleUncheckPayment = ({ paymentId }) => {
    let newCheckedPayments = checkedPayments;
    newCheckedPayments = newCheckedPayments.filter(
      (checkedPaymentId) => checkedPaymentId !== paymentId,
    );

    return setCheckedPayments(newCheckedPayments);
  };

  const isCheckedPayment = ({ paymentId }) => {
    if (checkedPayments.filter((checkedPaymentId) => checkedPaymentId === paymentId).length > 0) {
      return true;
    }
    return false;
  };

  const handleUncheckAll = () => setCheckedPayments([]);

  const handleCheckAll = () => {
    const paymentsIds = paymentsList.map((payment) => payment.paymentId);
    return setCheckedPayments(paymentsIds);
  };

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const handleOpenOrderDetails = async (orderId) => {
    if (orderId) {
      const order = await getOrder(orderId);
      setSelectedOrder(order);
      setOpenOrderDetails(true);
    }
  };

  const closeOrderDetails = useCallback(() => setOpenOrderDetails(false), []);

  const handleOnChangeSearchUser = (event, newValue) => {
    setPage(1);
    if (newValue) {
      setSearchTextSubmited(newValue._id);
    } else {
      setSearchTextSubmited('');
    }
  };

  return (
    <Box position="relative">
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={classes.contrastText}>
              Pagamentos
            </Typography>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Box width={200}>
                  <Grid container direction="column">
                    <FormControl
                      fullWidth
                      variant="filled"
                      size="small"
                      color="secondary"
                      className={classes.selectBackground}
                    >
                      <InputLabel>Situação do pagamento</InputLabel>

                      <Select
                        fullWidth
                        multiple
                        value={['Todas situações']}
                        renderValue={handleGetRenderValueStatus}
                        open={openSelectPayedFilter}
                        onClose={handleCloseSelectPayedFilter}
                        onClick={handleOpenSelectPayedFilter}
                        MenuProps={{ disableScrollLock: true }}
                      >
                        <Box>
                          <ClickAwayListener onClickAway={handleCloseSelectPayedFilter}>
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() => setType('Recebimento')}
                                >
                                  <Grid item>
                                    <Checkbox checked={type === 'Recebimento'} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="Recebimentos" />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() => setType('Pagamento')}
                                >
                                  <Grid item>
                                    <Checkbox checked={type === 'Pagamento'} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="Pagamentos" />
                                  </Grid>
                                </Grid>
                                <Divider />
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() =>
                                    setFilterPayed((oldFields) => ({
                                      ...oldFields,
                                      payed: !oldFields.payed,
                                    }))
                                  }
                                >
                                  <Grid item>
                                    <Checkbox checked={filterPayed.payed} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="Pagos" />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() =>
                                    setFilterPayed((oldFields) => ({
                                      ...oldFields,
                                      notPayed: !oldFields.notPayed,
                                    }))
                                  }
                                >
                                  <Grid item>
                                    <Checkbox checked={filterPayed.notPayed} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="Não pagos" />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() =>
                                    setFilterPayed((oldFields) => ({
                                      ...oldFields,
                                      toOverdue: !oldFields.toOverdue,
                                    }))
                                  }
                                >
                                  <Grid item>
                                    <Checkbox checked={filterPayed.toOverdue} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="A vencer" />
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  alignItems="center"
                                  onClick={() =>
                                    setFilterPayed((oldFields) => ({
                                      ...oldFields,
                                      overdue: !oldFields.overdue,
                                    }))
                                  }
                                >
                                  <Grid item>
                                    <Checkbox checked={filterPayed.overdue} />
                                  </Grid>
                                  <Grid item>
                                    <ListItemText primary="Vencidos" />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      handleCloseSelectPayedFilter();
                                      handleConfirmFilter();
                                    }}
                                    variant="contained"
                                  >
                                    Confirmar filtro
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ClickAwayListener>
                        </Box>
                      </Select>
                    </FormControl>
                  </Grid>
                </Box>
              </Grid>
              <Grid item>
                <Box width={200}>
                  <CategoryPaymentAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={handleOnChangeSearchCategory}
                    variant="filled"
                    className={classes.selectBackground}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width={200}>
                  <UserAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={handleOnChangeSearchUser}
                    variant="filled"
                    className={classes.selectBackground}
                  />
                </Box>
              </Grid>
              <Grid item>
                <SearchField submitSearch={setSearchTextSubmited} labelSearch="Buscar pagamento" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Box marginTop={2}>
        <SituationsFiltersPapers
          refreshFiltersWithQuantity={loading}
          situationsFilters={situationsFilters}
          setSituationsFilters={setSituationsFilters}
          handleClickFilter={handleClickFilter}
          payments
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <Box position="relative" marginBottom={5}>
          <SimpleBackdrop loading={loading} absolutePosition />

          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCheckbox} align="center">
                      <Tooltip
                        title={
                          <Typography align="center">
                            {checkedPayments.length > 0
                              ? 'Desselecionar todos os itens'
                              : 'Selecionar todos os itens desta página'}
                          </Typography>
                        }
                      >
                        <Checkbox
                          onClick={checkedPayments.length > 0 ? handleUncheckAll : handleCheckAll}
                          checked={checkedPayments.length > 0}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.smallCell}>ID</TableCell>
                    <TableCell className={classes.mediumCell}>Tipo</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>Carteira</TableCell>
                    <TableCell>Agente</TableCell>
                    <TableCell className={classes.tableColumnCash}>Valor</TableCell>
                    <TableCell className={classes.tableColumnCash}>Vencimento</TableCell>
                    <TableCell className={classes.tableColumnCash}>Situação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentsList.map((payment) => (
                    <TableRow
                      key={payment._id}
                      className={
                        selectedPayment && selectedPayment.paymentId === payment.paymentId
                          ? classes.tableRowSelected
                          : null
                      }
                    >
                      <TableCell align="center">
                        <Checkbox
                          checked={isCheckedPayment({ paymentId: payment.paymentId })}
                          onClick={() => {
                            if (isCheckedPayment({ paymentId: payment.paymentId })) {
                              handleUncheckPayment({ paymentId: payment.paymentId });
                            } else {
                              handleCheckPayment({ paymentId: payment.paymentId });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{payment.paymentId}</Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          className={classes.tableRowProduct}
                          onClick={() => handleClickItem(payment)}
                          label={payment.type}
                          style={{
                            backgroundColor: getLabelColorType(payment).bg,
                            color: getLabelColorType(payment).color,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {payment.category && categoriesList.length > 0
                          ? categoriesList.find(
                              (categoryFromList) => categoryFromList._id === payment.category,
                            ).name
                          : ''}
                      </TableCell>
                      <TableCell>
                        {payment.wallet && walletsList.length > 0
                          ? walletsList.find(
                              (walletFromList) => walletFromList._id === payment.wallet,
                            ).name
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Grid container direction="column" alignItems="flex-start">
                          <Grid item>
                            {payment.type === 'Pagamento' && payment.payee
                              ? payment.payee.name
                              : null}
                            {payment.type === 'Recebimento' && payment.payer
                              ? payment.payer.name
                              : null}
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                {payment.orderId ? (
                                  <Chip
                                    onClick={() => handleOpenOrderDetails(payment.orderId)}
                                    size="small"
                                    label={`Pedido ${payment.orderId}`}
                                  />
                                ) : null}
                              </Grid>
                              <Grid item>
                                {payment.orderId && payment.nfeId ? (
                                  <Chip
                                    onClick={() => handleOpenOrderDetails(payment.orderId)}
                                    size="small"
                                    label={`NF-e ${payment.nfeId}`}
                                  />
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        {payment.payed ? (
                          <Grid container>
                            {payment.value.payed >= payment.value.base ? (
                              <Grid item>
                                <Typography variant="caption">
                                  {`R$ ${numberToReal(payment.value.payed)}`}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Grid container direction="column">
                                  <Grid item>
                                    <Tooltip
                                      title={
                                        <Typography variant="caption">
                                          Valor pago parcialmente
                                        </Typography>
                                      }
                                    >
                                      <Typography style={{ color: '#00c600' }} variant="caption">
                                        {`R$ ${numberToReal(payment.value.payed)}`}
                                      </Typography>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item>
                                    <Tooltip
                                      title={
                                        <Typography variant="caption">
                                          Valor restante não pago
                                        </Typography>
                                      }
                                    >
                                      <Grid container alignItems="baseline">
                                        <Typography style={{ color: '#ff2222' }} variant="caption">
                                          {`R$ ${numberToReal(payment.value.base)}`}
                                        </Typography>
                                      </Grid>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        ) : (
                          <Grid container direction="column">
                            <Grid item>
                              <Tooltip
                                title={
                                  <Typography variant="caption">Valor pago parcialmente</Typography>
                                }
                              >
                                <Typography style={{ color: '#00c600' }} variant="caption">
                                  {`R$ ${numberToReal(payment.value.payed)}`}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                title={
                                  <Typography variant="caption">Valor restante não pago</Typography>
                                }
                              >
                                <Grid container alignItems="baseline">
                                  <Typography style={{ color: '#ff2222' }} variant="caption">
                                    {`R$ ${numberToReal(payment.value.base - payment.value.payed)}`}
                                  </Typography>
                                </Grid>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                      <TableCell>{formatDate(payment.dueDate)}</TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            color: getLabelColorStatus(payment).color,
                          }}
                        >
                          {payment.payed ? 'Pago' : 'Não pago'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {paymentsList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography>Nenhum pagamento para exibir</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <CustomPagination
                        page={page}
                        total={countPayments}
                        limit={limit}
                        handleChangePage={handleChangePage}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>

      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}

      {openOrderDetails ? (
        <Box>
          <OrderDetails
            openOrderDetails={openOrderDetails}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            closeOrderDetails={closeOrderDetails}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default memo(PaymentsTable);
