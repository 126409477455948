import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

import { onlyNumbersValue } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

function AddDocumentDialog({
  openAddDialogDocument,
  handleCloseAddDialogDocument,
  finance,
  setFinance,
}) {
  const [documentType, setDocumentType] = useState('Nota Fiscal');
  const [documentNumber, setDocumentNumber] = useState('');

  const handleAddDocument = () => {
    if (
      !finance.documents.find(
        (document) => document.type === documentType && document.number === documentNumber
      )
    ) {
      setFinance((oldFields) => ({
        ...oldFields,
        documents: [
          ...oldFields.documents,
          {
            type: documentType,
            number: documentNumber,
          },
        ],
      }));
    }
    handleCloseAddDialogDocument();
  };

  return (
    <SimpleDialog
      openDialog={openAddDialogDocument}
      handleClose={handleCloseAddDialogDocument}
      dialogTitle="Adicionar documento"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseAddDialogDocument}
      handleConfirmButton={handleAddDocument}
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel>Tipo</InputLabel>
              <Select
                onChange={(event) => setDocumentType(event.target.value)}
                value={documentType}
              >
                <MenuItem value="Nota Fiscal">Nota Fiscal</MenuItem>
                <MenuItem value="Pedido">Pedido</MenuItem>
                <MenuItem value="Doc. Cartão">Doc. Cartão</MenuItem>
                <MenuItem value="Pedido Externo">Pedido Externo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <CustomInput
              label="Número"
              value={documentNumber}
              onChange={(event) => setDocumentNumber(onlyNumbersValue(event.target.value))}
              error={documentNumber <= 0}
              variant="outlined"
            />
          </Grid>
        </Grid>
      }
    />
  );
}

export default AddDocumentDialog;
