import { Box, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import filterProductStatusInitial from '../../../../../constant/filterProductStatusInitial';
import filterProductOptionsInitial from '../../../../../constant/filterProductOptionsInitial';
import { searchProducts } from '../../../../../services/melhorGestao/products';
import FilterSelectOptions from '../../../../Common/FilterSelectOptions';
import UserAutocomplete from '../../../../Common/UserAutocomplete';
import CompanyAutocomplete from '../../../../Common/CompanyAutocomplete';
import TableRelatedProductsModal from './TableRelatedProductsModal';
import SimpleLinearProgress from '../../../../Common/SimpleLinearProgress';

const useStyles = makeStyles({
  paper: {
    height: '90vh',
  },
});

function RelatedProductsModal({
  openRelatedProductsModal,
  handleCloseRelatedProductsModal,
  handleConfirmSelect,
  alreadySelectedProducts,
}) {
  const classes = useStyles();
  const [submitedSearchText, setSubmitedSearchText] = useState('');
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [countProducts, setProductsCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);

  useEffect(() => {
    searchProducts({
      searchText: submitedSearchText,
      skip: page * limit - limit,
      limit,
      sortDirection: 'asc',
      setLoadingProductsList,
      setProductsList,
      setProductsCount,
      filterByCompany: selectedCompany?._id || selectedCompany,
      filterBySupplier: selectedSupplier?.userId,
      filtersOptions: [...filtersOptions, ...filtersStatus],
    });
  }, [
    page,
    limit,
    selectedCompany,
    selectedSupplier,
    filtersOptions,
    filtersStatus,
    submitedSearchText,
  ]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, productsList],
  );

  useEffect(() => {
    setPage(1);
  }, [selectedCompany, selectedSupplier, filtersOptions, filtersStatus]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openRelatedProductsModal}
      onClose={handleCloseRelatedProductsModal}
      classes={{ paper: classes.paper }}
    >
      <SimpleLinearProgress loading={loadingProductsList} fixedPosition />
      <Box margin={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(_, newValue) => setSelectedCompany(newValue)}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={200}>
                <UserAutocomplete
                  handleOnChange={(_, newValue) => {
                    setSelectedSupplier(newValue);
                  }}
                  selectedUser={selectedSupplier}
                  setSelectedUser={setSelectedSupplier}
                  label="Fornecedor"
                  variant="filled"
                  roles={['supplier']}
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Estoque"
                filtersOptions={filtersOptions}
                setFiltersOptions={setFiltersOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableRelatedProductsModal
          productsList={productsList.map((product) => ({
            productId: product.productId,
            imageSrc: product.images && product.images.length > 0 ? product.images[0].src : null,
            technicalName: product.technicalName,
            stock: product.stock - product.reservedStock,
            localization: product.localization,
          }))}
          countProducts={countProducts}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          alreadySelectedProducts={alreadySelectedProducts}
          handleConfirmSelect={handleConfirmSelect}
          onClose={handleCloseRelatedProductsModal}
          submitSearchProducts={setSubmitedSearchText}
          loadingProductsList={loadingProductsList}
        />
      </Box>
    </Dialog>
  );
}

export default memo(RelatedProductsModal);
