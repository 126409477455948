const newFinance = {
  installments: [],
  company: null,
  user: null,
  type: 'Recebimento',
  method: '',
  categories: [],
  value: 0,
  payed: false,
  payDate: null,
  documents: [],
  cheque: null,
  obs: '',
};

export default newFinance;
