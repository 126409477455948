const paymentDescriptionList = [
  {
    id: 0,
    string: 'A VISTA',
    dueDateDays: 0,
    quantityMonth: 1,
  },
  {
    id: 13,
    string: '7 DIAS',
    dueDateDays: 7,
    quantityMonth: 1,
  },
  {
    id: 14,
    string: '15 DIAS',
    dueDateDays: 15,
    quantityMonth: 1,
  },
  {
    id: 1,
    string: '30 DIAS',
    dueDateDays: 30,
    quantityMonth: 1,
  },
  {
    id: 2,
    string: '30/60 DIAS',
    dueDateDays: 30,
    quantityMonth: 2,
  },
  {
    id: 3,
    string: '30/60/90 DIAS',
    dueDateDays: 30,
    quantityMonth: 3,
  },
  {
    id: 4,
    string: '30/60/90/120 DIAS',
    dueDateDays: 30,
    quantityMonth: 4,
  },
  {
    id: 5,
    string: '30/60/90/120/150 DIAS',
    dueDateDays: 30,
    quantityMonth: 5,
  },
  {
    id: 6,
    string: '30/60/90/120/150/180 DIAS',
    dueDateDays: 30,
    quantityMonth: 6,
  },
  {
    id: 7,
    string: '30/60/90/120/150/180/210 DIAS',
    dueDateDays: 30,
    quantityMonth: 7,
  },
  {
    id: 8,
    string: '30/60/90/120/150/180/210/240 DIAS',
    dueDateDays: 30,
    quantityMonth: 8,
  },
  {
    id: 9,
    string: '30/60/90/120/150/180/210/240/270 DIAS',
    dueDateDays: 30,
    quantityMonth: 9,
  },
  {
    id: 10,
    string: '30/60/90/120/150/180/210/240/270/300 DIAS',
    dueDateDays: 30,
    quantityMonth: 10,
  },
  {
    id: 11,
    string: '30/60/90/120/150/180/210/240/270/300/330 DIAS',
    dueDateDays: 30,
    quantityMonth: 11,
  },
  {
    id: 12,
    string: '30/60/90/120/150/180/210/240/270/300/330/360 DIAS',
    dueDateDays: 30,
    quantityMonth: 12,
  },
];

export default paymentDescriptionList;
