const newMercadoLivreAd = {
  mlb: '',
  permalink: '',
  company: '',
  title: '',
  brand: '',
  category: {
    categoryId: '',
    name: '',
  },
  adType: {
    id: 'gold_pro',
    name: 'Premium',
    siteId: 'MLB',
  },
  status: '',
  health: 0,
  thumbnail: '',
  videoLink: '',
  description: '',
  attributes: [],
  price: 0,
  stock: 0,
  offerPrice: false,
  quantity: 1,
  soldQuantity: 0,
  companies: [],
  publishOnMlCatalog: false,
  mercadoLivreCatalogProductId: '',
};

export default newMercadoLivreAd;
