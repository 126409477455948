import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { memo } from 'react';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';

function SincronizedProductTab({ selectedProduct, handleOpenDialogSelectProduct }) {
  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box marginBottom={1}>
                  <Typography variant="h3">Produto</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleOpenDialogSelectProduct}>
                  Relacionar produto
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {selectedProduct.sincProductId ? (
            <>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={2}>
                    <Grid container direction="column">
                      <Typography variant="caption">Código</Typography>
                      <Typography>{selectedProduct.sincProductId}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container direction="column">
                      <Typography variant="caption">Nome</Typography>
                      <Typography>{selectedProduct.sincName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Typography variant="caption">Código fábrica</Typography>
                      <Typography>{selectedProduct.supplierId || 'Não informado'}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Typography variant="caption">Último custo</Typography>
                      <Typography>
                        {selectedProduct.sincLastCost
                          ? `R$ ${numberToRealWithPeriod(selectedProduct.sincLastCost)}`
                          : 'Não registrado'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <Typography variant="caption">Preço de venda atual</Typography>
                      <Typography>
                        R$ {numberToRealWithPeriod(selectedProduct.sincLastPrice)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </Paper>
  );
}

export default memo(SincronizedProductTab);
