import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { formatWeight } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

const useStyles = makeStyles({
  informationGrid: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: grey[100],
  },
  loadingTestArea: {
    margin: '0px 30px',
  },
});

function CreateBalanceDialog({
  balanceData,
  balanceForm,
  setBalanceForm,
  handleTestBalance,
  loadingBalanceWeight,
  openCreateBalanceDialog,
  handleCloseCreateBalanceDialog,
  handleConfirmCreateBalanceDialog,
  disableConfirmCreateBalanceDialog,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === 'substrStart') {
      setBalanceForm((oldFields) => ({
        ...oldFields,
        substrEnd: parseInt(fieldValue, 10) + 5,
      }));
    }

    setBalanceForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      openDialog={openCreateBalanceDialog}
      handleClose={handleCloseCreateBalanceDialog}
      dialogTitle="Adicionar uma balança"
      dialogText="Adicione uma balança compatível para fazer a leitura do peso"
      cancelButtonText="Cancelar"
      confirmButtonText="Adicionar"
      handleCancelButton={handleCloseCreateBalanceDialog}
      handleConfirmButton={handleConfirmCreateBalanceDialog}
      content={
        <Grid container justifyContent="space-between" direction="column" spacing={1}>
          <Grid item>
            <CustomInput
              label="Nome"
              name="name"
              value={balanceForm.name}
              error={!balanceForm.name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              label="Host"
              name="host"
              value={balanceForm.host}
              error={!balanceForm.host}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              number
              label="Porta"
              name="port"
              value={balanceForm.port}
              error={!balanceForm.port}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              number
              label="Posição de captura inicial"
              name="substrStart"
              value={balanceForm.substrStart}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <CustomInput
              number
              label="Posição de captura final"
              name="substrEnd"
              value={balanceForm.substrEnd}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <LoadingButton
                  loading={loadingBalanceWeight}
                  variant="contained"
                  color="primary"
                  autoFocus
                  disabled={!balanceForm.host || !balanceForm.port}
                  onClick={() => handleTestBalance(balanceForm)}
                >
                  Testar
                </LoadingButton>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography>Dados brutos recebidos da balança</Typography>}>
                  <Typography>Dados recebidos: {balanceData.string || 'Sem dados'}</Typography>
                </Tooltip>
                <Tooltip title={<Typography>Valor capturado dos dados recebidos</Typography>}>
                  <Typography>
                    Peso obtido: <b>{formatWeight(balanceData.weight || '00000')} Kg</b>
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box className={classes.informationGrid}>
              Coloque peso na balança para verificar e ajustar a posição de captura inicial conforme
              o peso mostrado no visor da balança.
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.informationGrid}>
              Caso o peso obtido não esteja correto faça o ajuste da posição de captura inicial
              comparando com os dados recebidos para posicionar corretamente a captura.
            </Box>
          </Grid>
        </Grid>
      }
      maxWidth="sm"
      disableConfirmButton={disableConfirmCreateBalanceDialog}
    />
  );
}

export default CreateBalanceDialog;
