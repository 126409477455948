import Axios from 'axios';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  FormControlLabel,
  Switch,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import CustomDatePicker from '../../CustomDatePicker';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import UserCityAutocomplete from '../../Common/UserCityAutocomplete';
import { formatHandleError } from '../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function InvoicesReports() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [filterInvoiceCompany, setFilterInvoiceCompany] = useState([]);

  const [filterInvoiceOperation, setFilterInvoiceOperation] = useState([
    {
      name: 'Saída',
      active: false,
    },
    {
      name: 'Entrada',
      active: false,
    },
  ]);

  const [filterInvoiceStatus, setFilterInvoiceStatus] = useState([
    {
      name: 'aprovado',
      active: false,
    },
    {
      name: 'reprovado',
      active: false,
    },
    {
      name: 'cancelado',
      active: false,
    },
    {
      name: 'denegado',
      active: false,
    },
    {
      name: 'processamento',
      active: false,
    },
    {
      name: 'contingencia',
      active: false,
    },
  ]);

  const [reportWithProducts, setReportWithProducts] = useState(false);
  const [filterInvoiceCfop, setFilterInvoiceCfop] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/reports/invoices/cfop');
        const cfopList = response.data;

        setFilterInvoiceCfop(
          cfopList.map((cfop) => ({
            name: cfop,
            active: false,
          })),
        );
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar a lista de CFOP das Notas Fiscais.',
          error,
        });
      }
    };

    fetchData();
  }, []);

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'month').endOf('month'));
  const [selectedCity, setSelectedCity] = useState(null);

  const getReports = async () => {
    try {
      setLoading(true);
      const getRoute = reportWithProducts ? '/reports/invoices/items' : '/reports/invoices';

      const response = await Axios.post(getRoute, {
        filterInvoiceCompany,
        filterInvoiceOperation,
        filterInvoiceStatus,
        filterInvoiceCfop,
        startDate: dayjs(startDate).startOf('day'),
        endDate: dayjs(endDate).endOf('day'),
        city: selectedCity?.city,
        state: selectedCity?.state,
      });

      const { url } = response.data;
      window.open(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar baixar o relatório de notas fiscais.',
        error,
      });
    }
  };

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleOnChangeCity = (event, newValue) => {
    if (newValue) {
      setSelectedCity(newValue);
    } else {
      setSelectedCity(null);
    }
  };

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <CompanySelectOptions
                    setSnackbar={setSnackbar}
                    inputLabel="Empresa"
                    filtersOptions={filterInvoiceCompany}
                    setFiltersOptions={setFilterInvoiceCompany}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Operação"
                    filtersOptions={filterInvoiceOperation}
                    setFiltersOptions={setFilterInvoiceOperation}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Status"
                    filtersOptions={filterInvoiceStatus}
                    setFiltersOptions={setFilterInvoiceStatus}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="CFOP"
                    filtersOptions={filterInvoiceCfop}
                    setFiltersOptions={setFilterInvoiceCfop}
                    hideButton
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Box width={200} className={classes.selectBackground}>
                    <UserCityAutocomplete
                      setSnackbar={setSnackbar}
                      handleOnChange={handleOnChangeCity}
                      selectedCity={selectedCity}
                      variant="filled"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data inicial"
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleChangeStartDate}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data final"
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleChangeEndDate}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={reportWithProducts}
                        onChange={() => setReportWithProducts(!reportWithProducts)}
                        checked={reportWithProducts}
                        color="secondary"
                      />
                    }
                    label="Mostrar produtos"
                  />
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                  <Grid item>
                    <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    getReports();
                  }}
                >
                  Baixar relatório
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default InvoicesReports;
