import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import React, { useState } from 'react';
import Axios from 'axios';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { formatHandleError } from '../../../helpers/formatData';
import CustomPagination from '../../Common/CustomPagination';

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: grey[100],
  },
}));

function TableErrors({
  integrationsErrors,
  countIntegrationsErrors,
  handleChangePage,
  page,
  limit,
  handleRefresh,
  setSnackbar,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const sendSelectedAction = async (errorData, actionName) => {
    try {
      setLoading(true);
      if (actionName === 'Excluir') {
        await Axios.delete('/integrations-errors', {
          params: {
            id: errorData._id,
          },
        });
      }

      if (actionName === 'Tentar novamente') {
        await Axios.patch('/integrations-errors', {
          id: errorData._id,
          functionName: errorData.function,
        });
      }
      setLoading(false);
      handleRefresh();
    } catch (error) {
      setLoading(false);
      handleRefresh();

      formatHandleError({
        setSnackbar,
        defaultMessage: `Algum erro ocorreu ao "${actionName}"`,
        error,
      });
    }
  };

  const handleGetUsefulId = (usefulIdOwner, usefulId, usefulIds) => {
    if (usefulIds && usefulIds.length > 0) {
      return `${usefulIdOwner}s ${usefulIds.join(',')}`;
    }
    if (!usefulId) {
      return 'Sem ID';
    }
    return `${usefulIdOwner} ${usefulId}`;
  };

  return (
    <Paper>
      <SimpleBackdrop loading={loading} />
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">ID Útil</TableCell>
              <TableCell align="center">Tentativas</TableCell>
              <TableCell align="center">Função</TableCell>
              <TableCell align="center">Dados</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationsErrors &&
              integrationsErrors.length > 0 &&
              integrationsErrors.map((errorData) => (
                <TableRow key={errorData._id}>
                  <TableCell align="center" width={100}>
                    {handleGetUsefulId(
                      errorData.usefulIdOwner,
                      errorData.usefulId,
                      errorData.usefulIds,
                    )}
                  </TableCell>
                  <TableCell align="center">{errorData.attempts || 0}</TableCell>
                  <TableCell align="center">{errorData.function}</TableCell>
                  <TableCell align="center" width={350}>
                    <TextField
                      disabled
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      defaultValue={errorData.data || 'Sem dados'}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {errorData.actions.map((action) => (
                      <Tooltip
                        key={action.name}
                        title={<Typography align="center">{action.name}</Typography>}
                      >
                        {action.name === 'Excluir' ? (
                          <IconButton
                            size="small"
                            onClick={() => sendSelectedAction(errorData, action.name)}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => sendSelectedAction(errorData, action.name)}
                          >
                            <CropRotateIcon />
                          </IconButton>
                        )}
                      </Tooltip>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            {integrationsErrors && integrationsErrors.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography>Não foi encontrado nenhum erro</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={7}>
                <CustomPagination
                  page={page}
                  total={countIntegrationsErrors}
                  limit={limit}
                  handleChangePage={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableErrors;
