import Axios from 'axios';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import dayjs from 'dayjs';
import { formatDate, formatHandleError, numberToRealWithPeriod } from '../../../helpers/formatData';
import ValuesBlock from './ValuesBlock';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';

const useStyles = makeStyles({
  greenColorIcon: {
    color: '#2ecc71',
  },
  redColorIcon: {
    color: '#e74c3c',
  },
});

function SquareWidget({
  darkMode,
  title,
  source,
  sellerId,
  filterOrderCompany,
  selectedUser,
  selectedCity,
  dateBy,
  preferenceDate,
  setSnackbar,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState({
    day: {
      value: 0,
      valuePaid: 0,
      salesCost: 0,
      financialCost: 0,
      shippingCost: 0,
      count: 0,
    },
    week: {
      value: 0,
      valuePaid: 0,
      salesCost: 0,
      financialCost: 0,
      shippingCost: 0,
      count: 0,
    },
    month: {
      value: 0,
      valuePaid: 0,
      salesCost: 0,
      financialCost: 0,
      shippingCost: 0,
      count: 0,
    },
    lastMonth: {
      value: 0,
      valuePaid: 0,
      salesCost: 0,
      financialCost: 0,
      shippingCost: 0,
      count: 0,
      dayMedian: 0,
      weekMedian: 0,
    },
    totalDaysOfMonth: 0,
  });

  const [commissionPercentage, setCommissionPercentage] = useState(0);

  const [commissionForm, setCommissionForm] = useState({
    commissionBase: 'valuePaid',
    commissionPercentage,
  });

  useEffect(() => {
    const fetchDataCommission = async () => {
      if (sellerId) {
        try {
          const response = await Axios.get(`/commission/${sellerId}`);
          setCommissionPercentage(response.data.commissionPercentage);

          setCommissionForm({
            commissionBase: response.data.commissionBase,
            commissionPercentage: response.data.commissionPercentage,
          });
        } catch (error) {
          formatHandleError({
            setSnackbar,
            defaultMessage: 'Algum erro ocorreu ao buscar comissão dos vendedores',
            error,
          });
        }
      }
    };

    const fetchData = async () => {
      try {
        setLoading(true);
        await fetchDataCommission();

        const response = await Axios.get('/orders/resume/source', {
          params: {
            source,
            filterOrderCompany,
            userId: selectedUser?.userId,
            seller: sellerId,
            city: selectedCity?.city,
            state: selectedCity?.state,
            date: preferenceDate,
            dateBy,
            onlyPaid: dateBy === 'payments.payDate',
          },
        });

        const { ordersResume } = response.data;
        setResume(ordersResume);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar resumo dos pedidos',
          error,
        });
      }
    };
    fetchData();
  }, []);

  const percentage = ((resume.month.value - resume.lastMonth.value) / resume.lastMonth.value) * 100;
  const trending = percentage > 0 ? 'up' : 'down';

  let classTrending = {
    padding: 1,
    boxShadow: '0 0 9px rgba(0,0,0,.25)',
  };

  if (loading) {
    classTrending = {
      ...classTrending,
      borderBottom: `20px solid #808080`,
    };
  } else if (trending === 'down') {
    classTrending = {
      ...classTrending,
      borderBottom: `20px solid #e74c3c`,
    };
  } else {
    classTrending = {
      ...classTrending,
      borderBottom: `20px solid #2ecc71`,
    };
  }

  if (!loading && darkMode) {
    classTrending = {
      ...classTrending,
      backgroundColor: '#26262b',
      color: '#fff',
    };
  }

  const handleChangeCommission = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setCommissionForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const [loadingSaveCommission, setLoadingCommission] = useState(false);
  const [openCommissionDialog, setOpenCommissionDialog] = useState(false);
  const handleOpenCommissionDialog = () => setOpenCommissionDialog(true);
  const handleCloseCommissionDialog = () => setOpenCommissionDialog(false);

  const handleSaveCommissionForm = async () => {
    try {
      setLoadingCommission(true);

      const response = await Axios.post('/commission', {
        sellerId,
        ...commissionForm,
      });

      setCommissionPercentage(response.data.commissionPercentage);
      setLoadingCommission(false);

      handleCloseCommissionDialog();
    } catch (error) {
      setLoadingCommission(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar comissão',
        error,
      });
    }
  };

  return (
    <Paper sx={classTrending}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            {sellerId ? (
              <Grid item>
                <Tooltip
                  title={
                    <Grid container direction="column" alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography align="center">
                          A comissão é calculada com base no valor pago pelos pedidos. Pedidos não
                          pagos não são considerados, enquanto pedidos pagos parcialmente apenas o
                          valor pago.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          Total pago R${' '}
                          {loading ? (
                            <Skeleton width={100} />
                          ) : (
                            numberToRealWithPeriod(resume.month.valuePaid)
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                >
                  <Grid container alignItems="center" spacing={0.4}>
                    <Grid item>
                      <Typography variant="caption">
                        Comissão ({commissionPercentage}%): R$
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="button">
                        {loading ? (
                          <Skeleton width={50} />
                        ) : (
                          numberToRealWithPeriod(
                            (commissionPercentage / 100) *
                              resume.month[commissionForm.commissionBase],
                          )
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleOpenCommissionDialog}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="button">MÊS PASSADO</Typography>
                </Grid>
                <Grid item>
                  <ValuesBlock loading={loading} data={resume.lastMonth} />
                </Grid>
              </Grid>
            }
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography variant="button">ESTE MÊS</Typography>
              </Grid>
              <Grid item>
                <ValuesBlock loading={loading} data={resume.month} />
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <Grid container spacing={0.3}>
                <Grid item>
                  <Typography>Média mês passado R$</Typography>
                </Grid>
                <Grid item>
                  <Typography>{numberToRealWithPeriod(resume.lastMonth.weekMedian)}</Typography>
                </Grid>
              </Grid>
            }
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography variant="button">ÚLTIMOS 7 DIAS</Typography>
              </Grid>
              <Grid item>
                <ValuesBlock loading={loading} data={resume.week} />
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <Grid container spacing={0.3}>
                <Grid item>
                  <Typography>Média mês passado R$</Typography>
                </Grid>
                <Grid item>
                  <Typography>{numberToRealWithPeriod(resume.lastMonth.dayMedian)}</Typography>
                </Grid>
              </Grid>
            }
          >
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography variant="button">HOJE</Typography>
              </Grid>
              <Grid item>
                <ValuesBlock loading={loading} data={resume.day} />
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item>
          {loading ? (
            <Skeleton width={200} />
          ) : (
            <Tooltip
              title={
                <>
                  <Typography align="center">
                    Diferença no valor total das vendas do mês passado com o deste mês proporcional
                    ao mesmo dia selecionado.
                  </Typography>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography>Mês passado</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {formatDate(dayjs(preferenceDate).subtract(1, 'month'))}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Média por dia</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            R${numberToRealWithPeriod(resume.lastMonth.dayMedian)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Total de vendas</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            R${numberToRealWithPeriod(resume.lastMonth.value)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Pedidos</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{resume.lastMonth.count}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography>Este mês</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{formatDate(dayjs(preferenceDate))}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Média por dia</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            R${numberToRealWithPeriod(resume.month.dayMedian)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Total de vendas</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>R${numberToRealWithPeriod(resume.month.value)}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">Pedidos</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{resume.month.count}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  {trending === 'up' && <TrendingUpIcon className={classes.greenColorIcon} />}
                  {trending === 'down' && <TrendingDownIcon className={classes.redColorIcon} />}
                </Grid>
                <Grid item>
                  <Typography variant="button">
                    {resume.month.value > 0 && resume.lastMonth.value > 0
                      ? Math.round(percentage)
                      : 'N/A'}
                    %
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {trending === 'up' ? 'acima do ' : 'abaixo do '}
                  </Typography>
                  <Typography variant="caption">mês passado</Typography>
                </Grid>
              </Grid>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {openCommissionDialog ? (
        <SimpleDialog
          openDialog={openCommissionDialog}
          handleClose={handleCloseCommissionDialog}
          dialogTitle={`Comissão ${title}`}
          dialogText="Ajuste a comissão do vendedor para ver o que ele poderá receber"
          cancelButtonText="Cancelar"
          confirmButtonText="Comfirmar"
          confirmButtonLoading={loadingSaveCommission}
          handleCancelButton={handleCloseCommissionDialog}
          handleConfirmButton={handleSaveCommissionForm}
          content={
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Base de calculo</InputLabel>
                  <Select
                    name="commissionBase"
                    onChange={handleChangeCommission}
                    value={commissionForm.commissionBase}
                  >
                    <MenuItem value="value">
                      Total R$ {numberToRealWithPeriod(resume.month.value)}
                    </MenuItem>
                    <MenuItem value="salesCost">
                      Custo R$ {numberToRealWithPeriod(resume.month.salesCost)}
                    </MenuItem>
                    <MenuItem value="valuePaid">
                      Pago R$ {numberToRealWithPeriod(resume.month.valuePaid)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <CustomInput
                  name="commissionPercentage"
                  label="Comissão"
                  value={commissionForm.commissionPercentage}
                  onChange={handleChangeCommission}
                  size="medium"
                  InputEndAdornment="%"
                  number
                />
              </Grid>
            </Grid>
          }
          maxWidth="sm"
        />
      ) : null}
    </Paper>
  );
}

export default React.memo(SquareWidget);
