import { Alert, Box, Chip, Drawer, Grid, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import OthersMakrteplacesSwitchs from './OthersMarketplacesSwitchs';
import DrawerSubmitArea from '../../Common/DrawerSubmitArea/DrawerSubmitArea';
import { handlePublishProducts } from '../../../services/melhorGestao/products';
import AdResultsDialog from './AdResultsDialog';
import MercadoLivreDetails from './MercadoLivreDetails';
import newMercadoLivreAd from '../../../constant/newMercadoLivreAd';
import CustomDivider from '../../CustomDivider';
import MadeiraMadeiraDetails from './MadeiraMadeiraDetails';
import newMadeiraMadeiraAd from '../../../constant/newMadeiraMadeiraAd';
import { getMercadoLivreAdForm } from '../../../services/melhorGestao/mercadoLivre';
import ShopeeDetails from './ShopeeDetails';
import newShopeeAd from '../../../constant/newShopeeAd';

const useStyles = makeStyles({
  box: {
    width: 620,
    marginBottom: 120,
  },
});

function MarketplacesDetails({
  openMarketplacesAdDetails,
  handleCloseMarketplacesAdDetails,
  productForm,
  mercadoLivreAd,
  setRefreshSearch,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [mercadoLivreAdForm, setMercadoLivreAdForm] = useState(newMercadoLivreAd);
  const [madeiraMadeiraAdForm, setMadeiraMadeiraAdForm] = useState(newMadeiraMadeiraAd);
  const [shopeeAdForm, setShopeeAdForm] = useState(newShopeeAd);

  const [publishOnMercadoLivre, setPublishOnMercadoLivre] = useState(false);
  const [publishOnMadeiraMadeira, setPublishOnMadeiraMadeira] = useState(false);
  const [publishOnShopee, setPublishOnShopee] = useState(false);
  const [publishOnSkyhub, setPublishOnSkyhub] = useState(false);
  const [publishOnMagazineLuiza, setPublishOnMagazineLuiza] = useState(false);
  const [publishOnViaVarejo, setPublishOnViaVarejo] = useState(false);
  const [publishOnGoogleShopping, setPublishOnGoogleShopping] = useState(false);

  const [loadingAdSaveForm, setLoadingAdSaveForm] = useState(false);
  const [adResultsList, setAdResultsList] = useState([]);

  const [openAdResultsDialog, setOpenAdResultsDialog] = useState(false);
  const handleOpenAdResultsDialog = () => setOpenAdResultsDialog(true);
  const handleCloseAdResultsDialog = () => setOpenAdResultsDialog(false);

  const handlePublish = async () => {
    setLoadingAdSaveForm(true);

    const othersResults = await handlePublishProducts({
      productsToPublish: [
        {
          ...productForm,
          adQuantity: 1,
        },
      ],
      mercadoLivreAdForm,
      madeiraMadeiraAdForm,
      shopeeAdForm,
      publishOnMercadoLivre,
      publishOnMadeiraMadeira,
      publishOnShopee,
      publishOnSkyhub,
      publishOnMagazineLuiza,
      publishOnViaVarejo,
      publishOnGoogleShopping,
    });

    setAdResultsList(othersResults);
    setRefreshSearch(Math.random());
    setLoadingAdSaveForm(false);

    return handleOpenAdResultsDialog();
  };

  useEffect(() => {
    const fetchData = () => {
      getMercadoLivreAdForm({
        productForm,
        mercadoLivreAd,
        mercadoLivreAdForm,
        setMercadoLivreAdForm: (adForm) =>
          setMercadoLivreAdForm({
            ...adForm,
            mlb: '',
            brand: productForm.brand,
            videoLink: productForm.videoLink,
            description: productForm.description,
            thumbnail: productForm.images?.[0]?.src || '',
            soldQuantity: 0,
            publishOnMlCatalog: false,
            mercadoLivreCatalogProductId: '',
          }),
        setSnackbar,
      });

      setShopeeAdForm({
        ...newShopeeAd,
        name: productForm.name,
        videoLink: productForm.videoLink,
        description: productForm.description,
      });
    };
    fetchData();
  }, []);

  return (
    <Drawer
      anchor="right"
      open={openMarketplacesAdDetails}
      onClose={handleCloseMarketplacesAdDetails}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={5} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Chip color="primary" label="Anúnciar produto" />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h3">{productForm.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <MercadoLivreDetails
              adForm={mercadoLivreAdForm}
              setAdForm={setMercadoLivreAdForm}
              productForm={productForm}
              setSnackbar={setSnackbar}
              publishOnMercadoLivre={publishOnMercadoLivre}
              setPublishOnMercadoLivre={setPublishOnMercadoLivre}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <MadeiraMadeiraDetails
              adForm={madeiraMadeiraAdForm}
              setAdForm={setMadeiraMadeiraAdForm}
              productForm={productForm}
              setSnackbar={setSnackbar}
              publishOnMadeiraMadeira={publishOnMadeiraMadeira}
              setPublishOnMadeiraMadeira={setPublishOnMadeiraMadeira}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <ShopeeDetails
              adForm={shopeeAdForm}
              setAdForm={setShopeeAdForm}
              productForm={productForm}
              setSnackbar={setSnackbar}
              publishOnShopee={publishOnShopee}
              setPublishOnShopee={setPublishOnShopee}
            />
          </Grid>
          <CustomDivider />
          <OthersMakrteplacesSwitchs
            publishOnSkyhub={publishOnSkyhub}
            setPublishOnSkyhub={setPublishOnSkyhub}
            publishOnMagazineLuiza={publishOnMagazineLuiza}
            setPublishOnMagazineLuiza={setPublishOnMagazineLuiza}
            publishOnViaVarejo={publishOnViaVarejo}
            setPublishOnViaVarejo={setPublishOnViaVarejo}
            publishOnGoogleShopping={publishOnGoogleShopping}
            setPublishOnGoogleShopping={setPublishOnGoogleShopping}
          />
        </Grid>
      </Box>

      <DrawerSubmitArea
        handleClose={handleCloseMarketplacesAdDetails}
        handleConfirm={handlePublish}
        formChanged
        loadingConfirm={loadingAdSaveForm}
        buttonConfirmLabel="Anunciar"
      />

      {openAdResultsDialog ? (
        <AdResultsDialog
          openAdResultsDialog={openAdResultsDialog}
          handleCloseAdResultsDialog={handleCloseAdResultsDialog}
          adResultsList={adResultsList}
          productsList={[productForm]}
        />
      ) : null}

      {snackbar.open ? (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Drawer>
  );
}

export default MarketplacesDetails;
