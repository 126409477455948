import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import orderStatusColor from '../../../constant/orderStatusColor';

function SquareWidget({ title, month, week, day }) {
  return (
    <Paper
      sx={{
        border: 0,
        borderBottom: `20px solid ${orderStatusColor(title)}`,
        color: '#26262b',
        boxShadow: '0 0 9px rgba(0,0,0,.25)',
        '&:hover': {
          boxShadow: '0 2 12px rgba(0,0,0,.25)',
        },
      }}
    >
      <Box padding={2}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box marginBottom="10px">
              <Typography variant="button" color={orderStatusColor(title)}>
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingTop="5px" paddingBottom="5px">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="caption">ESTE MÊS</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{month.count}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingTop="5px" paddingBottom="5px">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="caption">ÚLTIMOS 7 DIAS</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{week.count}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingTop="5px" paddingBottom="5px">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="caption">HOJE</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{day.count}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default React.memo(SquareWidget);
