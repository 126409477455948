import { Box, CircularProgress, Grid, Grow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { memo, useEffect, useState } from 'react';
import { getShopeeProductsBySku } from '../../../../../services/melhorGestao/shopee';
import ShopeeAdResume from './ShopeeAdResume';
import CustomDivider from '../../../../CustomDivider';

function ShopeeAd({
  classes,
  productForm,
  setProductForm,
  setFormChanged,
  refreshSearch,
  setRefreshSearch,
}) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingShopeeAds, setLoadingShopeeAds] = useState(false);
  const [shopeeAds, setShopeeAds] = useState([]);

  useEffect(() => {
    if (productForm.productId) {
      getShopeeProductsBySku({
        setShopeeAds,
        setLoadingShopeeAds,
        productId: productForm.productId,
      });
    }
  }, [productForm.productId, refreshSearch]);

  if (!shopeeAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img className={classes.sourceBadge} src="/assets/imgs/shopee-badge.svg" alt="" />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Shopee
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingShopeeAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {shopeeAds.length > 0 &&
                shopeeAds.map((shopeeAd) => (
                  <Grid item key={shopeeAd.item_id}>
                    <ShopeeAdResume
                      shopeeAd={shopeeAd}
                      product={productForm}
                      setProductForm={setProductForm}
                      setFormChanged={setFormChanged}
                      setRefreshSearch={setRefreshSearch}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

export default memo(ShopeeAd);
