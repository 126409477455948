import { Grid } from '@mui/material';
import React from 'react';
import PlataformIcon from './PlataformIcon';

function PlataformsListIcons({ companiesConfigurations, marketplace, product }) {
  if (!marketplace) {
    return null;
  }

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Site"
          image="site-badge.svg"
          adsList={
            product.active.siteMarkFerragens
              ? companiesConfigurations.map((company) => ({
                  id: product.productId,
                  company: company._id,
                }))
              : []
          }
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Mercado Livre"
          image="mercado-livre-badge.svg"
          adsList={marketplace.mercadoLivre}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Shopee"
          image="shopee-badge.svg"
          adsList={marketplace.shopee}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Madeira Madeira"
          image="madeira-madeira-badge.svg"
          adsList={marketplace.madeiraMadeira}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Skyhub"
          image="americanas-badge.svg"
          adsList={marketplace.skyhub}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Magazine Luiza"
          image="magalu-badge.svg"
          adsList={marketplace.magazineLuiza}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Via Varejo"
          image="via-badge.svg"
          adsList={marketplace.viaVarejo}
        />
      </Grid>
      <Grid item>
        <PlataformIcon
          companiesConfigurations={companiesConfigurations}
          name="Google Shopping"
          image="google-shopping-badge.svg"
          adsList={marketplace.googleShopping}
        />
      </Grid>
    </Grid>
  );
}

export default PlataformsListIcons;
