import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link as LinkMaterial,
  Paper,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LinkIcon from '@mui/icons-material/Link';
import { numberToReal } from '../../../../../../helpers/formatData';
import { getMagazineLuizaPrice } from '../../../../../../services/melhorGestao/magazineLuiza';
import SimpleImage from '../../../../../Common/SimpleImage';
import LimitTypography from '../../../../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  thumbnail: {
    width: '100%',
  },
  boxPositionRelative: {
    position: 'relative',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxEditIcon: {
    position: 'absolute',
    bottom: 155,
    right: 0,
  },
  smallIcon: {
    marginRight: 10,
  },
  sellerLogoGridMark: {
    height: 48,
    borderRadius: 8,
    backgroundColor: '#000',
  },
  sellerLogoImgMark: {
    width: 160,
  },
  gridHeight: {
    height: 48,
  },
  gridPrice: {
    height: 48,
    width: 75,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 136,
    paddingBottom: '0px!important',
  },
}));
function MagazineLuizaAdResume({ magazineLuizaAd, product, setProductForm, setFormChanged }) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const [loadingMagazineLuizaAdStatus] = useState(false);

  const handleCloseSnackbar = () =>
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });

  const [magazineLuizaAdPrice, setMagazineLuizaAdPrice] = useState(magazineLuizaAd.Price.ListPrice);
  const [magazineLuizaOfferAdPrice, setMagazineLuizaOfferAdPrice] = useState(
    magazineLuizaAd.Price.SalePrice,
  );

  const [offerPrice, setOfferPrice] = useState(false);
  const [offerChanged, setOfferChanged] = useState(false);

  const handleChangePriceBase = async () => {
    let index;
    const updatedProduct = product;

    updatedProduct.marketplaces.forEach((marketplace, indexInside) => {
      if (
        marketplace.marketplace === 'Magazine Luiza' &&
        parseInt(marketplace.marketplaceOwnId, 10) === updatedProduct.productId
      ) {
        index = indexInside;
      }
    });

    if (index === undefined) {
      return false;
    }

    if (offerChanged) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }
    setOfferChanged(!offerChanged);

    setOfferPrice(!offerPrice);
    updatedProduct.marketplaces[index].offerPrice = !offerPrice;
    const productInOffer = product.offer
      ? updatedProduct.marketplaces[index].offerPrice || false
      : false;

    const magazineLuizaPrice = await getMagazineLuizaPrice(
      updatedProduct.marketplaces[index].company,
      product.productId,
      updatedProduct.marketplaces[index].quantity,
      productInOffer,
      setSnackbar,
    );

    setMagazineLuizaAdPrice(parseFloat(magazineLuizaPrice.newProductPrice));
    setMagazineLuizaOfferAdPrice(parseFloat(magazineLuizaPrice.newPromotionalPrice));
    return setProductForm(updatedProduct);
  };

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    product.marketplaces.forEach((marketplace) => {
      if (
        marketplace.marketplace === 'Magazine Luiza' &&
        parseInt(marketplace.marketplaceOwnId, 10) === product.productId
      ) {
        setOfferPrice(marketplace.offerPrice === undefined ? false : marketplace.offerPrice);
      }
    });

    const getQuantity = product.marketplaces?.find(
      (marketplace) =>
        marketplace.marketplace === 'Magazine Luiza' &&
        marketplace.marketplaceOwnId === magazineLuizaAd.IdSku,
    )?.quantity;

    setQuantity(getQuantity || 0);
  }, [product.marketplaces]);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">Título do anúncio</Typography>
            </Grid>
            <Grid item>
              <LimitTypography text={magazineLuizaAd.Name} maxLength={55} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="caption">Vendedor</Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.sellerLogoGridMark}
                      >
                        <img
                          className={classes.sellerLogoImgMark}
                          src="/assets/imgs/logo-mark-ferragens-white.svg"
                          alt=""
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço de</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>{numberToReal(magazineLuizaAdPrice)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço por</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>{numberToReal(magazineLuizaOfferAdPrice)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={magazineLuizaAd.Status ? 'primary' : 'disabled'}
                                />

                                <Typography variant="caption" color="textPrimary">
                                  {magazineLuizaAd.Status && 'Ativo'}
                                  {!magazineLuizaAd.Status && 'Pausado'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      {loadingMagazineLuizaAdStatus && (
                        <CircularProgress variant="indeterminate" size={26} />
                      )}
                    </Grid>

                    <Grid item>
                      <Chip label={magazineLuizaAd.IdSku} />
                    </Grid>

                    <Grid item>
                      <Chip label={`Estoque: ${magazineLuizaAd.StockQuantity}`} />
                    </Grid>

                    {quantity ? (
                      <Grid item>
                        <Chip label={`Kit com: ${quantity}`} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <SimpleImage
                src={magazineLuizaAd.UrlImages[0]}
                height={100}
                width={100}
                alt={magazineLuizaAd.Name}
                className={classes.thumbnail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        <Box className={classes.boxEditIcon}>
          <Tooltip
            title={
              <Typography align="center">Preço do produto a ser considerado mais taxas</Typography>
            }
          >
            <FormControlLabel
              control={
                <Switch
                  value={offerPrice}
                  onChange={handleChangePriceBase}
                  checked={offerPrice}
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  {offerPrice ? 'Preço de oferta' : 'Preço normal'}
                </Box>
              }
            />
          </Tooltip>

          <Tooltip
            title={<Typography align="center">Link do produto na Magazine Luiza</Typography>}
          >
            <LinkMaterial href="https://www.magazineluiza.com.br" target="_blank" rel="noreferrer">
              <IconButton size="small" className={classes.smallIcon}>
                <LinkIcon />
              </IconButton>
            </LinkMaterial>
          </Tooltip>
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default MagazineLuizaAdResume;
