import { Grid } from '@mui/material';
import React from 'react';
import CustomInput from '../../../CustomInput';

function NotesOnInvoiceBlok({ orderForm, setOrderForm }) {
  return (
    <Grid container direction="column" spacing={1}>
      {orderForm.source === 'Devolução fornecedor' || orderForm.source === 'Remessa conserto' ? (
        <Grid item>
          <CustomInput
            label="Chave da nota fiscal de referência"
            value={orderForm.invoice?.nfeKeyReferenced || ''}
            onChange={(event) =>
              setOrderForm((oldFields) => ({
                ...oldFields,
                invoice: { ...oldFields.invoice, nfeKeyReferenced: event.target.value },
              }))
            }
            disabled={orderForm.invoice?.status === 'aprovado'}
          />
        </Grid>
      ) : null}
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <CustomInput
              label="Observações na nota fiscal"
              value={orderForm.invoice?.obs || ''}
              onChange={(event) =>
                setOrderForm((oldFields) => ({
                  ...oldFields,
                  invoice: { ...oldFields.invoice, obs: event.target.value },
                }))
              }
              disabled={orderForm.invoice?.status === 'aprovado'}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomInput
              label="ID pedido de compra"
              value={orderForm.invoice?.customerOrderId || ''}
              onChange={(event) =>
                setOrderForm((oldFields) => ({
                  ...oldFields,
                  invoice: { ...oldFields.invoice, customerOrderId: event.target.value },
                }))
              }
              disabled={orderForm.invoice?.status === 'aprovado'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NotesOnInvoiceBlok;
