import {
  Box,
  Chip,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { formatProductToOrder, numberToReal } from '../../../../helpers/formatData';
import SearchField from '../../../Common/SearchField';
import SimpleDialog from '../../../Common/SimpleDialog';
import PrintQuote from './PrintQuote';
import SimpleImage from '../../../Common/SimpleImage';
import StockBlock from '../../elements/StockBlock';
import { getUsers } from '../../../../services/melhorGestao/users';
import CustomInput from '../../../CustomInput';
import CustomPagination from '../../../Common/CustomPagination';
import SimpleLinearProgress from '../../../Common/SimpleLinearProgress';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 40,
  },
  mediumCell: {
    width: 120,
  },
  noPaddingCell: {
    padding: 0,
  },
  img: {
    width: '100%',
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
  },
  searchTopRight: {
    position: 'fixed',
    top: 20,
  },
  fabLeftBelow: {
    position: 'fixed',
    bottom: 20,
  },
  tableRowEmptyStock: {
    backgroundColor: '#ffefef',
    '&:hover': {
      backgroundColor: '#ffe4e4',
    },
  },
  tableRowProduct: {
    cursor: 'pointer',
  },
  tableColumnCash: {
    width: 90,
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
}));

function QuoteProductTable({
  selectedCompany,
  productsList,
  countProducts,
  handleChangePage,
  page,
  limit,
  submitSearchProducts,
  filterSupplierCode,
  handleCloseDialog,
  handleConfirmSelect,
  alreadySelectedProducts,
  sendToEntryOrder,
  loadingProductsList,
}) {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    setSelectedProducts(alreadySelectedProducts || []);
  }, [alreadySelectedProducts]);

  const handleUnselectProduct = useCallback(
    ({ product }) => {
      const newSelectedProducts = selectedProducts.filter(
        (selectedProduct) => selectedProduct.productId !== product.productId,
      );

      const reorderedProducts = newSelectedProducts.map((newSelectedProduct, index) => ({
        ...newSelectedProduct,
        positionOnList: index,
      }));

      setSelectedProducts(reorderedProducts);
    },
    [selectedProducts],
  );

  const checkSelectedProduct = useCallback(
    ({ product }) => {
      let isSelected = false;
      selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.productId === product.productId) {
          isSelected = true;
        }
      });
      return isSelected;
    },
    [selectedProducts],
  );

  const [itemsTotal, setItemsTotal] = useState(0);

  useEffect(() => {
    let totalItemsAccumulator = 0;

    selectedProducts.forEach((selectedProduct) => {
      totalItemsAccumulator += selectedProduct.cost * selectedProduct.quantity;
    });

    setItemsTotal(totalItemsAccumulator);
  }, [selectedProducts]);

  const handleChangeSelectedQuantity = useCallback(
    ({ quantity, product }) => {
      let quantityOnOrder = quantity;

      if (quantityOnOrder < 0) {
        quantityOnOrder = 0;
      }

      const onlySelectedSupplier = product.suppliers.find(
        (supplier) => supplier.userId === filterSupplierCode,
      );

      setSelectedProducts((alreadySelecteds) => {
        const productIndex = alreadySelecteds.findIndex(
          (selectedProduct) => selectedProduct.productId === product.productId,
        );

        const formattedProduct = {
          ...formatProductToOrder(product),
          quantity: quantityOnOrder,
          selectedPrice: product.price,
          supplierOwnId: onlySelectedSupplier ? onlySelectedSupplier.factoryId : '',
          positionOnList: productIndex !== -1 ? productIndex : selectedProducts.length,
        };

        if (productIndex >= 0) {
          return alreadySelecteds.map((selectedProduct, index) =>
            index === productIndex ? formattedProduct : selectedProduct,
          );
        }
        return [...alreadySelecteds, formattedProduct];
      });
    },
    [selectedProducts, filterSupplierCode],
  );

  useEffect(() => {
    if (filterSupplierCode) {
      const productsWithSupplierOwnId = selectedProducts.map((selectedProduct) => {
        const onlySelectedSupplier = selectedProduct.suppliers.find(
          (supplier) => supplier.userId === filterSupplierCode,
        );

        return {
          ...selectedProduct,
          supplierOwnId: onlySelectedSupplier ? onlySelectedSupplier.factoryId : '',
        };
      });
      setSelectedProducts(productsWithSupplierOwnId);
    }
  }, [filterSupplierCode]);

  const [openDialogPrintPdf, setOpenDialogPrintPdf] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const handleOpenDialogPrintOrder = () => {
    if (selectedProducts.length > 0) {
      setLoadingPrint(true);

      return setTimeout(() => {
        setLoadingPrint(false);
        setOpenDialogPrintPdf(true);
      }, 1000);
    }
    return handleCloseDialog();
  };

  const handleCloseDialogPrintOrder = () => setOpenDialogPrintPdf(false);

  const [usersNames, setUsersNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const suppliers = [];

      selectedProducts.forEach((selectedProduct) => {
        selectedProduct.suppliers.forEach((supplier) => {
          if (!suppliers.find((findSupplier) => findSupplier.userId === supplier.userId)) {
            suppliers.push(supplier);
          }
        });
      });

      const usersList = await getUsers(suppliers.map((supplier) => supplier.userId));

      setUsersNames(
        usersList.map((user) => ({
          userId: user.userId,
          name: user.name,
        })),
      );
    };
    fetchData();
  }, [selectedProducts]);

  const findNameByUserId = (userId) => {
    let name = 'Sem nome';
    if (usersNames.length > 0) {
      const user = usersNames.find((userName) => userName.userId === userId);
      if (user) {
        name = user.name;
      }
    }
    return name;
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <SimpleLinearProgress loading={loadingPrint} fixedPosition />

      <Box position="relative" right="10px">
        <Box className={classes.fabLeftBelow}>
          <Fab variant="extended" size="small">
            Total dos produtos: R$ {numberToReal(itemsTotal)}
          </Fab>
        </Box>
      </Box>

      <Grid item xs={12} sm={6}>
        <Grid container justifyContent="space-between" alignItems="baseline">
          <Typography variant="h6">Selecionar produtos:</Typography>
          <Typography variant="caption">Exibindo {productsList.length} produtos</Typography>
        </Grid>

        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell}>SKU</TableCell>
                <TableCell className={classes.smallCell}>Imagem</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Localização</TableCell>
                <TableCell align="center">Estoque</TableCell>
                <TableCell align="center">Preço</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList.map((product) => (
                <TableRow
                  className={
                    product.stock - product.reservedStock > 0 || !!checkSelectedProduct({ product })
                      ? classes.tableRowProduct
                      : classes.tableRowEmptyStock
                  }
                  key={product.productId}
                  selected={!!checkSelectedProduct({ product })}
                  onClick={() => {
                    const maxQuantity = Math.floor(
                      product.maximumStock - (product.stock - product.reservedStock),
                    );

                    handleChangeSelectedQuantity({
                      quantity: maxQuantity > 0 ? maxQuantity : 1,
                      product,
                    });
                  }}
                >
                  <TableCell align="center">{product.productId}</TableCell>
                  <TableCell align="center">
                    {product.images && product.images.length > 0 ? (
                      <SimpleImage
                        src={product.images[0].src}
                        height={75}
                        width={75}
                        alt={product.name}
                        className={classes.img}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption">{product.technicalName}</Typography>
                      </Grid>
                      <Grid item>
                        {product.stock - product.reservedStock <= 0 ? (
                          <Chip size="small" label="Sem estoque" />
                        ) : null}
                        {product.stock - product.reservedStock > 0 &&
                        product.stock - product.reservedStock <= product.minimumStock ? (
                          <Chip size="small" label="Estoque mínimo" />
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="caption">{product.localization}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <StockBlock product={product} filterByCompany={selectedCompany} />
                  </TableCell>
                  <TableCell align="center">R${numberToReal(product.cost)}</TableCell>
                </TableRow>
              ))}

              {productsList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <SimpleLinearProgress loading={loadingProductsList} />
                    {!loadingProductsList ? (
                      <Typography>Nenhum produto para exibir</Typography>
                    ) : null}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <CustomPagination
                    page={page}
                    total={countProducts}
                    limit={limit}
                    handleChangePage={handleChangePage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Produtos selecionados para orçamento:</Typography>
        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell} align="center">
                  SKU
                </TableCell>
                <TableCell className={classes.smallCell} align="center">
                  Imagem
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell className={classes.tableColumnCash} align="center">
                  Preço
                </TableCell>
                <TableCell className={classes.smallCell} align="center">
                  Quantidade
                </TableCell>
                <TableCell className={classes.tableColumnCash} align="center">
                  Total
                </TableCell>
                <TableCell className={classes.smallCell} align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((selectedProduct) => (
                <TableRow key={selectedProduct.productId}>
                  <TableCell align="center">{selectedProduct.productId}</TableCell>
                  <TableCell align="center">
                    {selectedProduct.images && selectedProduct.images.length > 0 ? (
                      <SimpleImage
                        src={selectedProduct.images[0].src}
                        height={75}
                        width={75}
                        alt={selectedProduct.name}
                        className={classes.img}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption">{selectedProduct.technicalName}</Typography>
                      </Grid>
                      {selectedProduct.suppliers && selectedProduct.suppliers.length > 0 ? (
                        <Grid item>
                          {selectedProduct.suppliers.map((supplier) => (
                            <Tooltip
                              key={supplier.userId + supplier.factoryId}
                              title={`${supplier.userId} - ${findNameByUserId(supplier.userId)}`}
                            >
                              <Chip size="small" label={supplier.factoryId} />
                            </Tooltip>
                          ))}
                        </Grid>
                      ) : null}
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>R${numberToReal(selectedProduct.cost)}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <CustomInput
                      value={selectedProduct.quantity}
                      onChange={(event) =>
                        handleChangeSelectedQuantity({
                          quantity: event.target.value,
                          product: selectedProduct,
                        })
                      }
                      size="medium"
                      number
                      centerText
                    />
                  </TableCell>
                  <TableCell align="center">
                    R${numberToReal(selectedProduct.cost * selectedProduct.quantity)}
                  </TableCell>
                  <TableCell align="center">
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => handleUnselectProduct({ product: selectedProduct })}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}

              {selectedProducts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography>Nenhum produto selecionado</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>

      <Box position="relative" right="235px">
        <Box className={classes.searchTopRight}>
          <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar produto" />
        </Box>
      </Box>

      <Box position="relative" right={selectedProducts.length > 0 ? '105px' : '61px'}>
        {sendToEntryOrder ? (
          <Box className={classes.fabRight}>
            <Fab
              variant="extended"
              color={selectedProducts.length > 0 ? 'primary' : 'default'}
              onClick={() => {
                if (selectedProducts.length > 0) {
                  handleConfirmSelect({ selectedProducts });
                }
                handleCloseDialog();
              }}
            >
              {selectedProducts.length > 0 ? 'Inserir itens' : 'Fechar'}
            </Fab>
          </Box>
        ) : (
          <Box className={classes.fabRight}>
            <Fab
              variant="extended"
              color={selectedProducts.length > 0 ? 'primary' : 'default'}
              onClick={handleOpenDialogPrintOrder}
            >
              {selectedProducts.length > 0 ? 'Imprimir PDF' : 'Fechar'}
            </Fab>
          </Box>
        )}
      </Box>

      {openDialogPrintPdf && (
        <SimpleDialog
          openDialog={openDialogPrintPdf}
          handleClose={handleCloseDialogPrintOrder}
          dialogTitle="Imprimir cotação"
          content={
            <PrintQuote selectedCompany={selectedCompany} selectedProducts={selectedProducts} />
          }
        />
      )}
    </Grid>
  );
}

export default memo(QuoteProductTable);
