import { Box, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CustomDatePicker from '../../CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function ResumeTopBar({ preferenceDate, setPreferenceDate }) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" className={classes.topBarTitle}>
            Resumo de Pedidos
          </Typography>
        </Grid>

        <Grid item>
          <Box width={200} className={classes.selectBackground}>
            <CustomDatePicker
              value={preferenceDate}
              onChange={(date) => setPreferenceDate(date)}
              label="Data base"
              format="DD/MM/YYYY"
              size="small"
              variant="filled"
            />
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default ResumeTopBar;
