import Axios from 'axios';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createInvoicesDevolutions } from '../../../../../services/melhorGestao/invoices';

const useStyles = makeStyles((theme) => ({
  checkMobile: {
    [theme.breakpoints.down('xs')]: {
      width: 155,
      marginLeft: -405,
    },
  },
}));

function InvoiceDevolutionButton({
  orderForm,
  devolution,
  setDevolutions = () => {},
  setSnackbar,
}) {
  const classes = useStyles();

  const [loadingCheckNfeStatus, setLoadingCheckNfeStatus] = useState(false);

  const handleCreateInvoices = async () => {
    setLoadingCheckNfeStatus(true);
    const results = await createInvoicesDevolutions({
      setSnackbar,
      setLoadingInvoices: setLoadingCheckNfeStatus,
      devolutionsIds: [devolution.devolutionId],
    });

    if (!results) {
      setLoadingCheckNfeStatus(false);
      return null;
    }

    const response = await Axios.get('/devolution/list', {
      params: { orderId: orderForm.orderId },
    });

    const getUpdatedDevolutions = response.data.devolutions;
    setDevolutions(getUpdatedDevolutions);
    setLoadingCheckNfeStatus(false);

    return setSnackbar({
      message: results[0].description,
      open: true,
      type: results[0].result ? 'success' : 'error',
    });
  };

  return orderForm.invoice?.key &&
    orderForm.invoice.status === 'aprovado' &&
    devolution?.items?.length > 0 &&
    !devolution?.invoice?.key ? (
    <Grid item className={classes.checkMobile}>
      <LoadingButton
        loading={loadingCheckNfeStatus}
        variant="contained"
        color="secondary"
        onClick={handleCreateInvoices}
      >
        Emitir NF-e devolução
      </LoadingButton>
    </Grid>
  ) : null;
}

export default InvoiceDevolutionButton;
