import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  Link as LinkMaterial,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import MercadoLivreEditTitle from './MercadoLivreEditTitle';
import CategoryAndAttributesButtons from './CategoryAndAttributesButtons';
import { numberToReal } from '../../../../helpers/formatData';
import { getMercadoLivrePrice } from '../../../../services/melhorGestao/mercadoLivre';
import DialogMercadoLivreCatalogSearch from './DialogMercadoLivreCatalogSearch';
import CustomInput from '../../../CustomInput';

function MercadoLivreDetails({
  adForm,
  setAdForm,
  productForm,
  publishOnMercadoLivre,
  setPublishOnMercadoLivre,
  setSnackbar = () => {},
}) {
  const [openMercadoLivreCatalogSearch, setOpenMercadoLivreCatalogSearch] = useState(false);
  const handleOpenMercadoLivreCatalogSearch = () => setOpenMercadoLivreCatalogSearch(true);
  const handleConfirmMercadoLivreCatalogSearch = () => setOpenMercadoLivreCatalogSearch(false);

  const handleCloseMercadoLivreCatalogSearch = () => {
    setAdForm((oldFields) => ({ ...oldFields, publishOnMlCatalog: false }));
    setOpenMercadoLivreCatalogSearch(false);
  };

  useEffect(() => {
    if (adForm.publishOnMlCatalog) {
      handleOpenMercadoLivreCatalogSearch();
    } else {
      setAdForm((oldFields) => ({ ...oldFields, mercadoLivreCatalogProductId: '' }));
    }
  }, [adForm.publishOnMlCatalog]);

  const [mercadoLivreBasePrice, setMercadoLivreBasePrice] = useState(0);
  const [mercadoLivreAdCost, setMercadoLivreAdCost] = useState(0);
  const [mercadoLivreShippingCost, setMercadoLivreShippingCost] = useState(0);
  const [mercadoLivrePriceTooltip, setMercadoLivrePriceTooltip] = useState(
    'Preencha os dados do anúncio para simular o preço',
  );

  useEffect(() => {
    const fetchData = async () => {
      if (
        adForm.companies.length > 0 &&
        productForm.productId &&
        adForm.quantity > 0 &&
        adForm.adType?.id
      ) {
        const mercadoLivrePrice = await getMercadoLivrePrice({
          company: adForm.companies[0],
          productId: productForm.productId,
          mlb: adForm.mlb,
          quantity: adForm.quantity,
          offerPrice: adForm.offerPrice,
          type: adForm.adType.id,
          setMercadoLivreBasePrice,
          setMercadoLivreAdCost,
          setMercadoLivreShippingCost,
          setMercadoLivrePriceTooltip,
          setSnackbar,
        });

        setAdForm((oldFields) => ({
          ...oldFields,
          price: mercadoLivrePrice,
        }));
      } else {
        setMercadoLivreBasePrice(0);
        setMercadoLivreAdCost(0);
        setMercadoLivreShippingCost(0);
        setMercadoLivrePriceTooltip('Preencha os dados do anúncio para simular o preço');

        setAdForm((oldFields) => ({
          ...oldFields,
          price: 0,
        }));
      }
    };
    fetchData();
  }, [
    adForm.companies,
    productForm.productId,
    adForm.quantity,
    adForm.offerPrice,
    adForm.adType?.id,
  ]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Mercado Livre</Typography>
          </Grid>
          {!adForm.mlb ? (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    value={publishOnMercadoLivre}
                    onClick={() => setPublishOnMercadoLivre(!publishOnMercadoLivre)}
                    checked={publishOnMercadoLivre}
                    color="primary"
                  />
                }
              />
            </Grid>
          ) : (
            <Tooltip
              title={<Typography align="center">Link do produto no Mercado Livre</Typography>}
            >
              <LinkMaterial href={adForm.permalink} target="_blank" rel="noreferrer">
                <IconButton size="small">
                  <LinkIcon />
                </IconButton>
              </LinkMaterial>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {publishOnMercadoLivre ? (
        <>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Tooltip
                  title={
                    <Typography align="center">
                      Digite a quantidade deste produto para compor no anúncio.
                    </Typography>
                  }
                >
                  <Box>
                    <CustomInput
                      label="Kit com"
                      variant="standard"
                      helperText={`${adForm.soldQuantity} vendas`}
                      value={adForm.quantity}
                      onChange={(event) =>
                        setAdForm((oldFields) => ({
                          ...oldFields,
                          quantity: event.target.value,
                        }))
                      }
                      color="secondary"
                      number
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <MercadoLivreEditTitle
                  productForm={productForm}
                  adForm={adForm}
                  setAdForm={setAdForm}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CategoryAndAttributesButtons
              adForm={adForm}
              setAdForm={setAdForm}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Tooltip
                      title={
                        <Typography align="center">
                          Preço da venda sem taxas: R$ {numberToReal(mercadoLivreBasePrice)}
                        </Typography>
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item>
                          <Typography>Taxa Mercado Livre: </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            R$ {numberToReal(mercadoLivreAdCost)}
                            {mercadoLivreBasePrice <= 7 ? ' + R$ 7,00' : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        <Typography align="center">
                          {adForm.price >= 79
                            ? 'Frete grátis para o cliente, pagamos este valor.'
                            : 'Frete cobrado do cliente'}
                        </Typography>
                      }
                    >
                      <Grid container alignItems="baseline" spacing={1}>
                        <Grid item>
                          <Typography>Frete: </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            R$ {numberToReal(mercadoLivreShippingCost)}
                            {adForm.price >= 79 ? ' (Nosso)' : ' (Cliente)'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography align="center">{mercadoLivrePriceTooltip}</Typography>}>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item>
                      <Typography variant="h6">Preço do anúncio:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color={adForm.price < 7 ? 'error' : 'textPrimary'}>
                        R$ {numberToReal(adForm.price)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {openMercadoLivreCatalogSearch ? (
        <DialogMercadoLivreCatalogSearch
          product={productForm}
          openMercadoLivreCatalogSearch={openMercadoLivreCatalogSearch}
          handleCloseMercadoLivreCatalogSearch={handleCloseMercadoLivreCatalogSearch}
          mercadoLivreCatalogProductId={adForm.mercadoLivreCatalogProductId}
          setMercadoLivreCatalogProductId={(mercadoLivreCatalogProductId) =>
            setAdForm((oldFields) => ({ ...oldFields, mercadoLivreCatalogProductId }))
          }
          handleConfirmMercadoLivreCatalogSearch={handleConfirmMercadoLivreCatalogSearch}
        />
      ) : null}
    </Grid>
  );
}

export default memo(MercadoLivreDetails);
