import { Box, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  boxAddress: {
    position: 'relative',
    marginBottom: 9,
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
    width: '100%',
  },
  boxAddressButtons: {
    position: 'absolute',
    right: 5,
  },
});

function AddressCard({ address, addressIndex, handleDeleteAddress = () => {} }) {
  const classes = useStyles();

  return (
    <Box className={classes.boxAddress}>
      {addressIndex !== undefined ? (
        <Box className={classes.boxAddressButtons}>
          <IconButton
            size="small"
            onClick={() => handleDeleteAddress({ deleteIndex: addressIndex })}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          <Grid container direction="column">
            <Typography variant="caption">CEP:</Typography>
            <Typography>{address.postalCode}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container direction="column">
            <Typography variant="caption">Logradouro:</Typography>
            <Typography>{address.street}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="caption">Número:</Typography>
            <Typography>{address.streetNumber}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="caption">UF:</Typography>
            <Typography>{address.state}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container direction="column">
            <Typography variant="caption">Cidade:</Typography>
            <Typography>{address.city}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container direction="column">
            <Typography variant="caption">Bairro:</Typography>
            <Typography>{address.district}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container direction="column">
            <Typography variant="caption">Complemento:</Typography>
            <Typography>{address.complement || 'Sem complemento'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddressCard;
