import React, { useState } from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Link as LinkMaterial,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import { numberToReal } from '../../../../../../helpers/formatData';
import CircularProgressWithLabel from '../../../../../Common/CircularProgressWithLabel';
import { updateMercadoLivreAdStatus } from '../../../../../../services/melhorGestao/mercadoLivre';
import SimpleImage from '../../../../../Common/SimpleImage';
import LimitTypography from '../../../../../Common/LimitTypography';
import MercadoLivreEditAdDialog from './MercadoLivreEditAdDialog';
import DialogIconDeleteMercadoLivreAd from '../../../../../MarketplacesAds/MarketplacesDetails/MercadoLivreDetails/DialogIconDeleteMercadoLivreAd';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  quantityInput: {
    width: 130,
  },
  select: {
    width: 200,
  },
  gridMarginRight: {
    marginRight: 15,
  },
  thumbnail: {
    width: '100%',
  },
  boxPositionRelative: {
    position: 'relative',
  },
  gridAbsolute: {
    position: 'absolute',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxDeleteIcon: {
    position: 'absolute',
    bottom: 155,
    right: 430,
  },
  boxEditIcon: {
    position: 'absolute',
    bottom: 155,
    right: 0,
  },
  smallIcon: {
    marginRight: 10,
  },
  sellerLogoGrid: {
    height: 48,
    borderRadius: 8,
    backgroundColor: '#000',
  },
  sellerLogoImg: {
    width: 160,
  },
  gridHeight: {
    height: 48,
  },
  gridPrice: {
    height: 48,
    width: 75,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 136,
    paddingBottom: '0px!important',
  },
}));

function MercadoLivreAdResume({
  mercadoLivreConfig,
  mercadoLivreAd,
  productForm,
  setRefreshSearch,
  setSnackbar,
}) {
  const classes = useStyles();

  const [mercadoLivreAds, setMercadoLivreAds] = useState(mercadoLivreAd);

  const handleChangePriceBase = () => {
    updateMercadoLivreAdStatus({
      mercadoLivreAdStatus: {
        mlb: mercadoLivreAds.mlb,
        productId: mercadoLivreAds.productId,
        quantity: mercadoLivreAds.quantity,
        offerPrice: !mercadoLivreAds.offerPrice,
      },
      setMercadoLivreAds,
      setSnackbar,
    });
  };

  const [openMercadoLivreEditAdDialog, setOpenMercadoLivreEditAdDialog] = useState(false);
  const handleOpenMercadoLivreEditAdDialog = () => setOpenMercadoLivreEditAdDialog(true);
  const handleCloseMercadoLivreEditAdDialog = () => setOpenMercadoLivreEditAdDialog(false);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">Título do anúncio</Typography>
            </Grid>
            <Grid item>
              <LimitTypography text={mercadoLivreAds.title} maxLength={55} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="caption">Vendedor</Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.sellerLogoGrid}
                      >
                        <SimpleImage
                          src={mercadoLivreConfig.srcLogo}
                          alt="Vendedor"
                          className={classes.sellerLogoImg}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>{numberToReal(mercadoLivreAds.price)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.gridMarginRight}>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Qualidade</Typography>
                            </Grid>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              className={classes.gridHeight}
                            >
                              <Grid item>
                                {mercadoLivreAds.catalogListing ? (
                                  'Catálogo'
                                ) : (
                                  <CircularProgressWithLabel value={mercadoLivreAds.health * 100} />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={
                                    mercadoLivreAds.status === 'active' ? 'primary' : 'disabled'
                                  }
                                />
                                {mercadoLivreAds.status ? (
                                  <Typography variant="caption" color="textPrimary">
                                    {mercadoLivreAds.status === 'active' && 'Ativo'}
                                    {mercadoLivreAds.status === 'paused' && 'Pausado'}
                                    {mercadoLivreAds.status === 'closed' && 'Finalizado'}
                                    {mercadoLivreAds.status === 'under_review' && 'A revisar'}
                                  </Typography>
                                ) : (
                                  <Typography variant="caption" color="textPrimary">
                                    Excluído
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      <Chip label={mercadoLivreAds.mlb} />
                    </Grid>
                    <Grid item>
                      <Chip label={`${mercadoLivreAds.soldQuantity} vendas`} />
                    </Grid>

                    <Grid item>
                      <Chip label={`Estoque: ${mercadoLivreAds.stock}`} />
                    </Grid>
                    <Grid item>
                      <Chip label={`Kit com: ${mercadoLivreAds.quantity}`} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <SimpleImage
                src={mercadoLivreAds.thumbnail}
                imgHeight={90}
                imgWidth={90}
                height={90}
                width={90}
                alt={mercadoLivreAds.title}
                className={classes.thumbnail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        <Box className={classes.boxDeleteIcon}>
          <DialogIconDeleteMercadoLivreAd
            mercadoLivreAds={mercadoLivreAds}
            setRefreshSearch={setRefreshSearch}
            setSnackbar={setSnackbar}
          />
        </Box>
        <Box className={classes.boxEditIcon}>
          {!mercadoLivreAds.catalogListing ? (
            <Tooltip
              title={
                <Typography align="center">
                  Preço do produto a ser considerado mais taxas
                </Typography>
              }
            >
              <FormControlLabel
                disabled={mercadoLivreAds.status !== 'active'}
                control={
                  <Switch
                    value={mercadoLivreAds.offerPrice}
                    onChange={handleChangePriceBase}
                    checked={mercadoLivreAds.offerPrice}
                    color="primary"
                  />
                }
                label={
                  <Box className={classes.smallTypography}>
                    {mercadoLivreAds.offerPrice ? 'Preço de oferta' : 'Preço normal'}
                  </Box>
                }
              />
            </Tooltip>
          ) : null}

          <Tooltip title={<Typography align="center">Link do produto no Mercado Livre</Typography>}>
            <LinkMaterial href={mercadoLivreAds.permalink} target="_blank" rel="noreferrer">
              <IconButton size="small" className={classes.smallIcon}>
                <LinkIcon />
              </IconButton>
            </LinkMaterial>
          </Tooltip>

          {!mercadoLivreAds.catalogListing && mercadoLivreAds.status === 'active' ? (
            <Tooltip title={<Typography align="center">Editar anúncio</Typography>}>
              <IconButton
                size="small"
                color="primary"
                onClick={handleOpenMercadoLivreEditAdDialog}
                className={classes.editIcon}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
      {openMercadoLivreEditAdDialog ? (
        <MercadoLivreEditAdDialog
          openMercadoLivreEditAdDialog={openMercadoLivreEditAdDialog}
          handleCloseMercadoLivreEditAdDialog={handleCloseMercadoLivreEditAdDialog}
          productForm={productForm}
          mercadoLivreAd={mercadoLivreAd}
          setMercadoLivreAd={setMercadoLivreAds}
        />
      ) : null}
    </Paper>
  );
}

export default MercadoLivreAdResume;
