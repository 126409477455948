import Axios from 'axios';

export const getViaVarejoProductsBySku = async ({
  setViaVarejoAds,
  setLoadingViaVarejoAds,
  productId,
}) => {
  try {
    setLoadingViaVarejoAds(true);
    const response = await Axios.get(`/via-varejo/products/by-sku/${productId}`);
    const { viaVarejoProductsList } = response.data;
    setLoadingViaVarejoAds(false);
    return setViaVarejoAds(viaVarejoProductsList);
  } catch (error) {
    setLoadingViaVarejoAds(false);
    return error;
  }
};

export const deleteViaVarejoProduct = async (company, productId) => {
  try {
    const response = await Axios.delete(`/via-varejo/products/${company}/${productId}`);
    const { viaVarejoProductDeleted } = response.data;
    return viaVarejoProductDeleted;
  } catch (error) {
    return error;
  }
};

export const getViaVarejoPrice = (ourProduct, quantity, inOffer) => {
  const promotionalPrice = (inOffer ? ourProduct.offerPrice : ourProduct.price) * quantity;
  const productPrice = (inOffer ? ourProduct.price : ourProduct.price * 1.1) * quantity;

  return {
    newPromotionalPrice: (promotionalPrice * 1.15).toFixed(2),
    newProductPrice: (productPrice * 1.15).toFixed(2),
  };
};
