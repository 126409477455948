import { Alert, Box, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import React, { useState } from 'react';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import { base64ToHtml, formatHandleError, htmlToBase64 } from '../../../helpers/formatData';

function EmailDialog({
  openEmailDialog,
  handleOpenEmailDialog,
  handleCloseEmailDialog,
  fieldsTemplate,
  setFieldsTemplate,
}) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loading, setLoading] = useState(false);

  const handleSaveTemplateEmail = async () => {
    try {
      setLoading(true);

      if (fieldsTemplate.templateEmailId) {
        await Axios.put(`/emails/${fieldsTemplate.templateEmailId}`, {
          templateEmail: {
            name: fieldsTemplate.name,
            matter: fieldsTemplate.matter,
            htmlMessage: fieldsTemplate.htmlMessage,
          },
        });
      } else {
        await Axios.post('/emails', {
          templateEmail: {
            name: fieldsTemplate.name,
            matter: fieldsTemplate.matter,
            htmlMessage: fieldsTemplate.htmlMessage,
          },
        });
      }

      setLoading(false);
      window.location.reload();
      handleCloseEmailDialog();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao salvar o template',
        error,
      });
    }
  };

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === 'htmlMessage') {
      return setFieldsTemplate((oldFields) => ({
        ...oldFields,
        htmlMessage: htmlToBase64(fieldValue),
      }));
    }

    return setFieldsTemplate((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      dialogTitle="Novo Template"
      openDialog={openEmailDialog}
      handleOpenDialog={handleOpenEmailDialog}
      handleClose={handleCloseEmailDialog}
      disableConfirmButton={
        !fieldsTemplate.name || !fieldsTemplate.matter || !fieldsTemplate.htmlMessage
      }
      content={
        <Box minWidth={850}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CustomInput
                name="name"
                label="Nome do Template"
                rows={1}
                value={fieldsTemplate.name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <CustomInput
                name="matter"
                label="Assunto do e-mail"
                rows={1}
                value={fieldsTemplate.matter}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <CustomInput
                name="htmlMessage"
                label="Mensagem ou HTML do e-mail"
                rows={20}
                value={base64ToHtml(fieldsTemplate.htmlMessage)}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Box>
      }
      cancelButtonText="Cancelar"
      confirmButtonLoading={loading}
      confirmButtonText="Salvar Template"
      handleCancelButton={handleCloseEmailDialog}
      handleConfirmButton={handleSaveTemplateEmail}
    />
  );
}
export default EmailDialog;
