import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function FilterSelectOptions({
  inputLabel = 'Filtrar',
  width = 200,
  size = 'small',
  multiple = true,
  variant = 'filled',
  autoConfirm,
  filtersOptions,
  setFiltersOptions,
  hideButton,
  disabled,
}) {
  const classes = useStyles();

  const [temporaryFiltersOptions, setTemporaryFiltersOptions] = useState(filtersOptions);
  const [openFilterSelect, setOpenFilterSelect] = useState(false);
  const handleOpenFilterSelect = () => setOpenFilterSelect(true);
  const handleCloseFilterSelect = () => setOpenFilterSelect(false);

  const handleGetRenderValue = () => {
    let active = 0;
    let filterName = '';
    filtersOptions.forEach((filter) => {
      if (filter.active) {
        active += 1;
        filterName = filter.name || filter.description;
      }
    });
    if (active === 0) {
      return 'Nenhum selecionado';
    }
    if (active === 1) {
      return filterName;
    }
    if (active === filtersOptions.length) {
      return 'Todos selecionados';
    }
    return 'Vários selecionados';
  };

  const handleSetFilter = ({ index, selectAll, selectNone }) => {
    if (selectAll) {
      const newFilterOptions = temporaryFiltersOptions;
      newFilterOptions.forEach((filterOption, indexInside) => {
        newFilterOptions[indexInside].active = true;
      });
      return setTemporaryFiltersOptions(() => [...newFilterOptions]);
    }
    if (selectNone) {
      const newFilterOptions = temporaryFiltersOptions;
      newFilterOptions.forEach((filterOption, indexInside) => {
        newFilterOptions[indexInside].active = false;
      });
      return setTemporaryFiltersOptions(() => [...newFilterOptions]);
    }

    const newFilterOptions = temporaryFiltersOptions;
    if (!multiple) {
      newFilterOptions.forEach((filterOption, indexInside) => {
        if (index !== indexInside) {
          newFilterOptions[indexInside].active = false;
        }
      });
    }
    newFilterOptions[index].active = !newFilterOptions[index].active;
    return setTemporaryFiltersOptions(() => [...newFilterOptions]);
  };

  const handleConfirmFilter = () => {
    setFiltersOptions(temporaryFiltersOptions);
    handleCloseFilterSelect();
  };

  if (autoConfirm) {
    useEffect(() => {
      handleConfirmFilter();
    }, [temporaryFiltersOptions]);
  }

  useEffect(() => {
    setTemporaryFiltersOptions(filtersOptions);
  }, [filtersOptions]);

  return (
    <Box width={width} className={classes.selectBackground}>
      <FormControl fullWidth variant={variant} size={size} color="secondary">
        <InputLabel>{inputLabel}</InputLabel>

        <Select
          fullWidth
          multiple
          value={['Todos selecionados']}
          renderValue={handleGetRenderValue}
          open={openFilterSelect}
          onClose={handleCloseFilterSelect}
          onClick={disabled ? () => {} : handleOpenFilterSelect}
          MenuProps={{ disableScrollLock: true }}
          disabled={disabled}
        >
          <Box width={250}>
            <ClickAwayListener onClickAway={handleCloseFilterSelect}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  {temporaryFiltersOptions.map((filter, index) => (
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      key={filter.id || filter.name || filter.description}
                      onClick={() => handleSetFilter({ index })}
                    >
                      <Grid item>
                        <Checkbox checked={filter.active} />
                      </Grid>
                      <Grid item>
                        {filter.name && <ListItemText primary={filter.name} />}
                        {filter.description && (
                          <Typography
                            style={{
                              fontSize: 12,
                              color: 'grey',
                            }}
                          >
                            {filter.description}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {multiple && filtersOptions.length > 7 && (
                  <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Button size={size} onClick={() => handleSetFilter({ selectNone: true })}>
                          Marcar Nenhum
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button size={size} onClick={() => handleSetFilter({ selectAll: true })}>
                          Marcar Todos
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!autoConfirm && !hideButton ? (
                  <Grid item>
                    <Grid container alignItems="center" justifyContent="center">
                      <Button size={size} onClick={handleConfirmFilter} variant="contained">
                        Confirmar
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </ClickAwayListener>
          </Box>
        </Select>
      </FormControl>
    </Box>
  );
}

export default memo(FilterSelectOptions);
