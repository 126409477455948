import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo, useState, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getUsers } from '../../../../../services/melhorGestao/users';
import LimitTypography from '../../../../Common/LimitTypography';

function TableSuppliers({ suppliers, setProductForm }) {
  const [loadingUsersNames, setLoadingUsersNames] = useState(false);
  const [usersNames, setUsersNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingUsersNames(true);
      const usersList = await getUsers(suppliers.map((supplier) => supplier.userId));

      setUsersNames(
        usersList.map((user) => ({
          userId: user.userId,
          name: user.name,
        })),
      );
      setLoadingUsersNames(false);
    };
    fetchData();
  }, []);

  const findNameByUserId = (userId) => {
    let name = 'Sem nome';
    if (usersNames.length > 0) {
      const user = usersNames.find((userName) => userName.userId === userId);
      if (user) {
        name = user.name;
      }
    }
    return name;
  };

  return (
    <Box position="relative" marginBottom={5}>
      {loadingUsersNames ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">Código de fábrica</TableCell>
                  <TableCell align="center">Remover</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppliers.length > 0 &&
                  suppliers.map((supplier) => (
                    <TableRow key={supplier._id}>
                      <TableCell align="center">{supplier.userId}</TableCell>
                      <TableCell align="center">
                        <LimitTypography
                          text={findNameByUserId(supplier.userId)}
                          maxLength={30}
                          variant="inherit"
                        />
                      </TableCell>
                      <TableCell align="center">{supplier.factoryId}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() =>
                            setProductForm((oldFields) => ({
                              ...oldFields,
                              suppliers: suppliers.filter(
                                (delSupplier) => delSupplier._id !== supplier._id,
                              ),
                            }))
                          }
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {suppliers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography>Nenhum fornecedor para exibir</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}

export default memo(TableSuppliers);
