import Axios from 'axios';
import { Box, CircularProgress, Grid } from '@mui/material';
import React, { useState } from 'react';
import { cleanUpDuplicateSpaces } from '../../../../../../../helpers/formatData';
import SimpleDialog from '../../../../../../Common/SimpleDialog';
import CustomInput from '../../../../../../CustomInput';

function DialogCreateCategory({
  openDialogCreateCategory,
  handleCloseDialogCreateCategory,
  selectedCategory,
  setRefreshToken,
}) {
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);

  const createNewCategory = async (category) => {
    try {
      setLoadingCreateCategory(true);
      const createdCategory = await Axios.post('/catalog/categories', {
        category,
      });

      setNewCategoryName('');
      setLoadingCreateCategory(false);
      handleCloseDialogCreateCategory();
      setRefreshToken(Math.random());
      return createdCategory;
    } catch (error) {
      setLoadingCreateCategory(false);
      const errorMessage =
        error && error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Algum erro ocorreu ao criar categoria';

      return console.log(errorMessage);
    }
  };

  const handleCreateCategory = async () => {
    await createNewCategory({
      name: cleanUpDuplicateSpaces(newCategoryName),
      parent: selectedCategory && selectedCategory.slug ? selectedCategory.slug : '',
      parentName: selectedCategory && selectedCategory.name ? selectedCategory.name : '',
    });
  };

  return (
    <SimpleDialog
      content={
        <Box>
          <Grid container justifyContent="center" alignItems="center">
            {loadingCreateCategory ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item>
                <CustomInput
                  label="Nome da categoria"
                  onChange={(event) => setNewCategoryName(event.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      }
      dialogTitle="Nova categoria ou sub-categoria"
      handleClose={handleCloseDialogCreateCategory}
      openDialog={openDialogCreateCategory}
      handleConfirmButton={handleCreateCategory}
      disableConfirmButton={loadingCreateCategory}
      confirmButtonText="Criar"
    />
  );
}

export default DialogCreateCategory;
