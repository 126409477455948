import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { blue, green } from '@mui/material/colors';
import { numberToRealWithPeriod } from '../../../../../helpers/formatData';
import DialogProductCost from './DialogProductCost';
import simulateMongoObjectId from '../../../../../helpers/simulateMongoObjectId';

const useStyles = makeStyles((theme) => ({
  redText: {
    color: '#f11',
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
  },
}));

function ProductsTable({ products, saveProductCost, entryOrderType, blockFields }) {
  const classes = useStyles();

  const [openDialogProductCost, setOpenDialogProductCost] = useState(false);
  const handleOpenDialogProductCost = useCallback(() => setOpenDialogProductCost(true), []);
  const handleCloseDialogProductCost = useCallback(() => setOpenDialogProductCost(false), []);
  const [selectedProduct, setSelectedProduct] = useState({});

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    return handleOpenDialogProductCost();
  };

  return (
    <Paper elevation={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={300}>Nome</TableCell>
            <TableCell width={90} align="center">
              Unidade
            </TableCell>
            <TableCell width={90} align="center">
              Quantidade
            </TableCell>
            <TableCell width={150} align="center">
              Preço de venda
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products && products.length > 0 ? (
            products
              .sort((a, b) => {
                if (a.positionOnList > b.positionOnList) {
                  return 1;
                }
                if (a.positionOnList < b.positionOnList) {
                  return -1;
                }
                return 0;
              })
              .map((product) => (
                <TableRow
                  key={simulateMongoObjectId()}
                  className={
                    selectedProduct.positionOnList === product.positionOnList
                      ? classes.tableRowSelected
                      : null
                  }
                >
                  <TableCell width={300}>
                    <Grid container direction="column">
                      <Box textOverflow="ellipsis">
                        <Typography variant={product.sincProductId ? 'caption' : 'body1'}>
                          {product.supplierId ? `[${product.supplierId}] ` : null}
                          {product.name}
                        </Typography>
                      </Box>
                      {product.sincProductId ? (
                        <Grid>
                          <Chip size="small" label={`SKU ${product.sincProductId}`} />
                          <Typography>
                            {product.positionOnList + 1} - {product.sincName}
                          </Typography>
                        </Grid>
                      ) : (
                        <Typography className={classes.redText}>Não relacionado</Typography>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell width={90} align="center">
                    <Grid container direction="column">
                      {product.entryUnity ? (
                        <Tooltip title={<Typography>Unidade de compra</Typography>}>
                          <Typography variant="caption" color={blue[800]}>
                            {product.entryUnity} ({product.entryUnityRate})
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography color="error">Indefinida</Typography>
                      )}
                      {product.unity ? (
                        <Tooltip title={<Typography>Unidade de venda</Typography>}>
                          <Typography variant="caption" color={green[800]}>
                            {product.unity} ({product.unityRate})
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography color="error">Indefinida</Typography>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell width={90} align="center">
                    <Grid container direction="column">
                      <Typography variant="caption" color={blue[800]}>
                        {product.entryQuantity}
                      </Typography>
                      <Typography variant="caption" color={green[800]}>
                        {product.quantity}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell width={150} align="center">
                    {product.sincProductId ? (
                      <Grid container direction="column">
                        <Grid item>
                          {product.entryUnitaryCost ? (
                            <Typography variant="caption">
                              Base: R${' '}
                              {numberToRealWithPeriod(
                                product.entryUnitaryCost + product.additionalOnline,
                              )}
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid item>
                          {product.entryQuantity && product.entryUnitaryCost ? (
                            <Typography variant="caption">
                              Total: R${' '}
                              {numberToRealWithPeriod(
                                product.entryQuantity *
                                  (product.entryUnitaryCost + product.additionalOnline),
                              )}
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center" justifyContent="center" spacing={1}>
                            <Grid item>
                              {product.price ? (
                                <Typography variant="h3">
                                  R$ {numberToRealWithPeriod(product.price)}
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => handleSelectProduct(product)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          {product.priceConfirmed ? (
                            <Chip label="Confirmado" color="primary" />
                          ) : (
                            <Chip label="Não confirmado" color="primary" variant="outlined" />
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleSelectProduct(product)}
                        color="primary"
                      >
                        Custeio e precificação
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
              .sort((a, b) => a.positionOnList > b.positionOnList)
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography>Não há nenhum item neste pedido</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
      {openDialogProductCost ? (
        <DialogProductCost
          openDialog={openDialogProductCost}
          handleCloseDialog={handleCloseDialogProductCost}
          entryOrderType={entryOrderType}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          saveProductCost={saveProductCost}
          blockFields={blockFields}
        />
      ) : null}
    </Paper>
  );
}

export default memo(ProductsTable);
