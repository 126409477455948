import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import SimpleDialog from '../../../../Common/SimpleDialog';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatDateWithHour,
} from '../../../../../helpers/formatData';
import orderStatusColor from '../../../../../constant/orderStatusColor';

function StatusHistoryDialog({
  orderForm,
  openDialogStatusHistory,
  handleCloseDialogStatusHistory,
}) {
  return (
    <SimpleDialog
      openDialog={openDialogStatusHistory}
      content={
        <Timeline position="alternate">
          {orderForm.statusHistory && orderForm.statusHistory.length > 0
            ? orderForm.statusHistory.map((statusHistory, index) => {
                const { toStatus, description, updatedAt } = statusHistory;
                const changedBy = statusHistory.changedBy || 'Desconhecido';
                const isLastItem = index === orderForm.statusHistory.length - 1;

                return (
                  <TimelineItem key={statusHistory._id}>
                    <TimelineOppositeContent>
                      <Typography>{formatDateWithHour(updatedAt)}</Typography>
                      <Typography variant="caption">
                        {capitalizeString(extractFirstAndLastName(changedBy))}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        sx={{ borderColor: orderStatusColor(toStatus) }}
                      />
                      {!isLastItem ? <TimelineConnector /> : null}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="h2">{toStatus}</Typography>
                      <Typography variant="caption">{description}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
            : null}
        </Timeline>
      }
      dialogTitle="Alterações da situação do pedido"
      handleClose={handleCloseDialogStatusHistory}
    />
  );
}

export default StatusHistoryDialog;
