import { Box, Divider, Grid, Typography } from '@mui/material';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import { formatHandleError } from '../../../../helpers/formatData';
import ImagesManagerDialog from '../../../Common/ImagesManagerDialog';
import BannerImgTab from '../BannerImgTab';

function BlogPostDialog({
  openDialogBlogPost,
  handleOpenDialogPost,
  handleCloseDialogPost,
  handleRefreshBlogPosts,
  fieldsBlogPost,
  setFieldsBlogPost,
  setSnackbar,
}) {
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [openImagesManagerDialog, setOpenImagesManagerDialog] = useState(false);
  const handleOpenImagesManagerDialog = () => setOpenImagesManagerDialog(true);
  const handleCloseImagesManagerDialog = () => setOpenImagesManagerDialog(false);

  const handleChangeBlogTitle = (event) => {
    setFieldsBlogPost((oldFields) => ({
      ...oldFields,
      title: event.target.value,
    }));
  };

  const handleChangeBlogText = (event) => {
    setFieldsBlogPost((oldFields) => ({
      ...oldFields,
      text: event.target.value,
    }));
  };

  const handleChangeBlogImageLink = (event) => {
    setFieldsBlogPost((oldFields) => ({
      ...oldFields,
      imageLink: event.target.value,
    }));
  };

  const handleSelectedSrcBanner = (src) => {
    setFieldsBlogPost((oldFields) => ({
      ...oldFields,
      imageSrc: src,
    }));

    handleCloseImagesManagerDialog();
  };

  useEffect(() => {
    if (!fieldsBlogPost.title) {
      setErrorMessage('Deve ser informado um título para a postagem');
      return setDisableConfirmButton(true);
    }
    if (!fieldsBlogPost.text) {
      setErrorMessage('Insira um texto para compor o conteúdo da postagem');
      return setDisableConfirmButton(true);
    }
    if (!fieldsBlogPost.imageSrc) {
      setErrorMessage('Insira uma imagem relacionada à postagem');
      return setDisableConfirmButton(true);
    }
    if (!fieldsBlogPost.imageLink) {
      setErrorMessage('Informe um link do site para redirecionar ao clicar na imagem');
      return setDisableConfirmButton(true);
    }
    return setDisableConfirmButton(false);
  }, [fieldsBlogPost]);

  const handleSaveBlogPost = async () => {
    try {
      if (fieldsBlogPost.postId) {
        await Axios.put(`/blogPosts/${fieldsBlogPost.postId}`, {
          blogPost: fieldsBlogPost,
        });
      } else {
        await Axios.post('/blogPosts', {
          blogPost: fieldsBlogPost,
        });
      }

      handleRefreshBlogPosts();
      handleCloseDialogPost();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar a postagem do Blog',
        error,
      });
    }
  };

  return (
    <SimpleDialog
      dialogTitle="Adicionar postagem no blog"
      openDialog={openDialogBlogPost}
      handleOpenDialog={handleOpenDialogPost}
      handleClose={handleCloseDialogPost}
      disableConfirmButton={disableConfirmButton}
      content={
        <Box width="800px" height="400px">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CustomInput
                label="Título da postagem"
                rows={1}
                value={fieldsBlogPost.title}
                variant="outlined"
                onChange={handleChangeBlogTitle}
              />
            </Grid>
            <Grid item>
              <CustomInput
                label="Texto da postagem"
                rows={10}
                value={fieldsBlogPost.text}
                variant="outlined"
                onChange={handleChangeBlogText}
              />
            </Grid>
            <Grid item>
              <CustomInput
                label="Link do produto na imagem"
                rows={1}
                value={fieldsBlogPost.imageLink}
                variant="outlined"
                onChange={handleChangeBlogImageLink}
              />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Grid container>
                {fieldsBlogPost.title ? (
                  <Grid item xs={4}>
                    <BannerImgTab
                      src={fieldsBlogPost.imageSrc}
                      alt={fieldsBlogPost.title}
                      bannerType="imgBlogPost"
                      onImgClick={() => {
                        handleOpenImagesManagerDialog();
                      }}
                    />
                  </Grid>
                ) : null}
                {errorMessage && disableConfirmButton ? (
                  <Grid item xs={8}>
                    <Typography color="error">{errorMessage}</Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {openImagesManagerDialog ? (
              <ImagesManagerDialog
                openImagesManagerDialog={openImagesManagerDialog}
                handleCloseImagesManagerDialog={handleCloseImagesManagerDialog}
                onSelectSrcImage={(src) => handleSelectedSrcBanner(src)}
                selectedSrcImage={fieldsBlogPost.imageSrc}
                directory="banner"
                title={fieldsBlogPost.title}
              />
            ) : null}
          </Grid>
        </Box>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Enviar"
      handleCancelButton={handleCloseDialogPost}
      handleConfirmButton={handleSaveBlogPost}
    />
  );
}

export default BlogPostDialog;
