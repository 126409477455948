import { Box, CircularProgress, Typography } from '@mui/material';
import React, { memo } from 'react';

function CircularProgressWithLabel({ value }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        color={value > 80 ? 'primary' : 'secondary'}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">
          <b>{`${Math.round(value)}%`}</b>
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(CircularProgressWithLabel);
