import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import KeyIcon from '@mui/icons-material/Key';
import { makeStyles } from '@mui/styles';
import CustomPagination from '../../Common/CustomPagination';
import { formatDateWithHour, numberToRealWithPeriod } from '../../../helpers/formatData';
import LimitTypography from '../../Common/LimitTypography';
import SimpleLinearProgress from '../../Common/SimpleLinearProgress';

const useStyles = makeStyles({
  typographyCursor: {
    cursor: 'pointer',
  },
});

function TableInvoices({
  invoicesList,
  countInvoices,
  handleChangePage,
  page,
  limit,
  loadingInvoicesList,
  setSnackbar,
}) {
  const classes = useStyles();

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      setSnackbar({
        message: `${text} copiado`,
        open: true,
        type: 'success',
      });
    } catch (error) {
      setSnackbar({
        message: `Falha ao copiar. ${error?.message || ''}`,
        open: true,
        type: 'error',
      });
    }
  };

  return (
    <Box position="relative" marginBottom={5}>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Modelo</TableCell>
                <TableCell align="center">Serie</TableCell>
                <TableCell align="center">Operação</TableCell>
                <TableCell align="center">Nº</TableCell>
                <TableCell>Emitente</TableCell>
                <TableCell>Data de Emi.</TableCell>
                <TableCell>Destinatário</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Chave</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesList.map((invoice) => {
                let invoiceChecked;

                if (invoice.nfeProc) {
                  invoiceChecked = invoice.nfeProc;
                } else {
                  invoiceChecked = invoice;
                }

                return (
                  <TableRow key={invoice._id}>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {invoiceChecked?.NFe?.infNFe?.ide?.mod === '55' ? 'NF-e' : ''}
                        {invoiceChecked?.NFe?.infNFe?.ide?.mod === '65' ? 'NFC-e' : ''}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {invoiceChecked?.NFe?.infNFe?.ide?.serie || ''}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {invoiceChecked?.NFe?.infNFe?.ide?.tpNF === '0' ? 'Entrada' : ''}
                        {invoiceChecked?.NFe?.infNFe?.ide?.tpNF === '1' ? 'Saída' : ''}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {invoiceChecked?.NFe?.infNFe?.ide?.nNF || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LimitTypography
                        text={invoiceChecked?.NFe?.infNFe?.emit?.xNome || ''}
                        maxLength={30}
                        variant="caption"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">
                        {invoiceChecked?.NFe?.infNFe?.ide?.dhEmi
                          ? formatDateWithHour(invoiceChecked.NFe.infNFe.ide.dhEmi)
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LimitTypography
                        text={invoiceChecked?.NFe?.infNFe?.dest?.xNome || '* NÃO IDENTIFICADO *'}
                        maxLength={30}
                        variant="caption"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        R${' '}
                        {numberToRealWithPeriod(
                          invoiceChecked?.NFe?.infNFe?.total?.ICMSTot?.vNF || 0,
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {invoiceChecked?.protNFe?.infProt?.chNFe ? (
                        <Tooltip title={invoiceChecked.protNFe.infProt.chNFe}>
                          <IconButton
                            size="small"
                            onClick={() => handleCopy(invoiceChecked.protNFe.infProt.chNFe)}
                          >
                            <KeyIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton disabled>
                          <KeyIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

              {page !== 1 && invoicesList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography
                          onClick={(event) => handleChangePage(event, 1)}
                          className={classes.typographyCursor}
                        >
                          Nenhum pedido nesta página, clique aqui para voltar a página.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : null}

              {page === 1 && invoicesList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <SimpleLinearProgress loading={loadingInvoicesList} />
                    {!loadingInvoicesList ? (
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography>Nenhum pedido para exibir</Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={9}>
                  <CustomPagination
                    page={page}
                    total={countInvoices}
                    limit={limit}
                    handleChangePage={handleChangePage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default TableInvoices;
