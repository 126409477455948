import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: grey[100],
  },
}));

function TableSettingsOccurrenceType({ occurrencesList }) {
  const classes = useStyles();

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {occurrencesList &&
              occurrencesList.length > 0 &&
              occurrencesList.map((category) => (
                <TableRow key={category._id}>
                  <TableCell align="center">{category.name}</TableCell>
                </TableRow>
              ))}
            {occurrencesList && occurrencesList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>Não foi cadastrado nenhum tipo de ocorrência</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableSettingsOccurrenceType;
