import React, { memo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../../CustomInput';
import TitleHelpContent from './TitleHelpContent';
import SimpleDialog from '../../../../Common/SimpleDialog';

const useStyles = makeStyles({
  helpTitleTypography: {
    cursor: 'pointer',
  },
});

function MercadoLivreEditTitle({ productForm, adForm, setAdForm, setSnackbar }) {
  const classes = useStyles();

  const [openDialogTitleHelp, setOpenDialogTitleHelp] = useState(false);
  const handleOpenDialogTitleHelp = () => setOpenDialogTitleHelp(true);
  const handleCloseDialogTitleHelp = () => setOpenDialogTitleHelp(false);

  const repeatProductTitle = () => {
    if (productForm.name.length > 60) {
      setSnackbar({
        message:
          'Atenção! O título do anúncio foi cortado pois contém mais de 60 caracteres. Adapte-o para caber em 60 caracteres.',
        open: true,
        type: 'error',
      });
    }

    return setAdForm((oldFields) => ({
      ...oldFields,
      title: productForm.name.substring(0, 60),
    }));
  };

  const handleChangeMercadoLivreTitle = (event) => {
    const name = event.target.value;

    return setAdForm((oldFields) => ({
      ...oldFields,
      title: name.substring(0, 60),
    }));
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <CustomInput
          length={60}
          label="Título do anúncio"
          helperText={
            adForm.soldQuantity > 0
              ? 'O título do anúncio não pode ser alterado após ter vendas'
              : null
          }
          value={adForm.title}
          onChange={handleChangeMercadoLivreTitle}
          name="name"
          disabled={adForm.soldQuantity > 0}
          color="secondary"
        />
      </Grid>
      {adForm.soldQuantity === 0 ? (
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography onClick={repeatProductTitle} className={classes.helpTitleTypography}>
                Puxar título do produto
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                onClick={handleOpenDialogTitleHelp}
                className={classes.helpTitleTypography}
              >
                Dicas para um bom título
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{`${adForm.title.length}/60`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {openDialogTitleHelp ? (
        <SimpleDialog
          dialogTitle="Como criar um bom título para o Mercado Livre"
          openDialog={openDialogTitleHelp}
          handleClose={handleCloseDialogTitleHelp}
          content={<TitleHelpContent />}
        />
      ) : null}
    </Grid>
  );
}

export default memo(MercadoLivreEditTitle);
