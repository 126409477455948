import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const searchEntriesOrders = async ({
  limit = 20,
  skip = 0,
  searchText = '',
  sortBy = 'createdAt',
  sortDirection = 'desc',
  filterOrderStatus,
  filterOrderCompany,
  setLoadingEntriesList,
  setEntriesList,
  setCountEntries = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingEntriesList(true);

    const response = await Axios.get('/entries/search', {
      params: {
        limit,
        skip,
        searchText,
        sortBy,
        sortDirection,
        filterOrderStatus,
        filterOrderCompany,
      },
    });

    const { totalResults, entriesList } = response.data;

    setEntriesList(entriesList);
    setCountEntries(totalResults);
    return setLoadingEntriesList(false);
  } catch (error) {
    setLoadingEntriesList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar as entradas',
      error,
    });
  }
};

export default searchEntriesOrders;
