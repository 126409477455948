import {
  Alert,
  Box,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Axios from 'axios';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import searchBlogPosts from '../../../../services/melhorGestao/blogPosts';
import SimpleDialog from '../../../Common/SimpleDialog';
import BlogPostDialog from './BlogPostDialog';
import newBlogPost from '../../../../constant/newBlogPost';
import FabGroupButtons from '../../../Common/FabGroupButtons';
import { formatHandleError } from '../../../../helpers/formatData';
import CustomPagination from '../../../Common/CustomPagination';

const useStyles = makeStyles(() => ({
  buttonPointer: {
    cursor: 'pointer',
  },
}));

function TableBlogPosts() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [fieldsBlogPost, setFieldsBlogPost] = useState();
  const [openDialogBlogPost, setOpenDialogBlogPost] = useState(false);
  const [selectPostToDelete, setSelectPostToDelete] = useState();
  const [openDialogConfirmDeletePost, setOpenDialogConfirmDeletePost] = useState(false);

  const handleOpenDialogPost = (post) => {
    setOpenDialogBlogPost(true);
    setFieldsBlogPost(post);
  };

  const handleOpenDialogNewPost = () => {
    setOpenDialogBlogPost(true);
    setFieldsBlogPost(newBlogPost);
  };

  const handleCloseDialogPost = () => {
    setOpenDialogBlogPost(false);
  };

  const handleOpenDialogConfirmDeleteBlogPost = (post) => {
    setSelectPostToDelete(post);
    setOpenDialogConfirmDeletePost(true);
  };

  const handleCloseDialogConfirmDeleteBlogPost = () => {
    setOpenDialogConfirmDeletePost(false);
  };

  const handleEditBlogPost = (post) => {
    handleOpenDialogPost(post);
  };

  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [blogPostsList, setBlogPostsList] = useState([]);
  const [loadingBlogPostsList, setLoadingBlogPostsList] = useState(false);
  const [totalBlogPosts, setTotalBlogPosts] = useState(0);

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const handleRefreshBlogPosts = () => setRefreshToken(Math.random());

  const getBlogPosts = useCallback(() => {
    searchBlogPosts(
      {
        skip: page * limit - limit,
        limit,
        setLoadingBlogPostsList,
        setBlogPostsList,
        setTotalBlogPosts,
      },
      [page, limit],
    );
  }, [totalBlogPosts, refreshToken, page]);

  useEffect(() => {
    getBlogPosts();
  }, [totalBlogPosts, refreshToken, page]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, blogPostsList],
  );

  const handleDeleteBlogPost = async () => {
    try {
      await Axios.delete(`/blogPosts`, {
        params: {
          id: selectPostToDelete._id,
        },
      });

      setOpenDialogConfirmDeletePost(false);
      handleRefreshBlogPosts();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao excluir postagem do Blog',
        error,
      });
    }
  };

  const [fabButtons, setFabButtons] = useState([]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Nova Postagem',
        onClick: handleOpenDialogNewPost,
        icon: <PostAddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <Paper>
      <Box marginTop={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="8%">
                ID
              </TableCell>
              <TableCell width="76%">Título</TableCell>
              <TableCell align="center" width="8%">
                <EditIcon />
              </TableCell>
              <TableCell align="center" width="8%">
                <DeleteForeverIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loadingBlogPostsList && blogPostsList.length > 0
              ? blogPostsList?.map((post) => (
                  <TableRow key={post.postId}>
                    <TableCell align="center">{post.postId}</TableCell>
                    <TableCell>{post.title}</TableCell>
                    <TableCell align="center">
                      <EditIcon
                        className={classes.buttonPointer}
                        onClick={() => handleEditBlogPost(post)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <DeleteForeverIcon
                        className={classes.buttonPointer}
                        onClick={(event) => {
                          event.stopPropagation();
                          return handleOpenDialogConfirmDeleteBlogPost(post);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
            {page !== 1 && blogPostsList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography onClick={(event) => handleChangePage(event, 1)}>
                        Nenhuma postagem nesta página.
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            {page === 1 && blogPostsList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Typography>
                        {loadingBlogPostsList ? 'Carregando...' : 'Nenhuma postagem para exibir'}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4}>
                <CustomPagination
                  page={page}
                  total={totalBlogPosts}
                  limit={limit}
                  handleChangePage={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
        <SimpleBackdrop loading={loadingBlogPostsList} absolutePosition />
      </Box>
      {openDialogBlogPost ? (
        <BlogPostDialog
          openDialogBlogPost={openDialogBlogPost}
          handleOpenDialogPost={handleOpenDialogPost}
          handleCloseDialogPost={handleCloseDialogPost}
          handleRefreshBlogPosts={handleRefreshBlogPosts}
          fieldsBlogPost={fieldsBlogPost}
          setFieldsBlogPost={setFieldsBlogPost}
          setSnackbar={setSnackbar}
        />
      ) : null}
      {openDialogConfirmDeletePost && (
        <SimpleDialog
          openDialog={openDialogConfirmDeletePost}
          handleClose={handleCloseDialogConfirmDeleteBlogPost}
          dialogTitle="Excluir Postagem do Blog"
          dialogText="Confirma a exclusão da postagem? (A ação não poderá ser desfeita)"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmDeleteBlogPost}
          handleConfirmButton={handleDeleteBlogPost}
        />
      )}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <FabGroupButtons fabButtons={fabButtons} />
    </Paper>
  );
}

export default TableBlogPosts;
