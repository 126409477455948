import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  linear: {
    zIndex: theme.zIndex.drawer + 1,
  },
  fixedPosition: {
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function SimpleLinearProgress({ loading, fixedPosition }) {
  const classes = useStyles();

  return loading ? (
    <Box className={fixedPosition ? classes.fixedPosition : classes.linear}>
      <LinearProgress color="secondary" />
    </Box>
  ) : null;
}

export default memo(SimpleLinearProgress);
