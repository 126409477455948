import Axios from 'axios';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import ProductsTable from './ProductsTable';
import newEntryProduct from '../../../../constant/newEntryProduct';
import QuoteProductDialog from '../../../Products/Dialogs/QuoteProductDialog';

function ProductsTab({ entryOrder, setEntryOrder, saveProductCost, blockFields }) {
  const [openDialogProducts, setOpenDialogProducts] = useState(false);
  const handleOpenDialogProducts = () => setOpenDialogProducts(true);
  const handleCloseDialogProducts = () => setOpenDialogProducts(false);

  const handleSelectProductsToOrder = async ({ selectedProducts }) => {
    const entryProducts = [];

    await selectedProducts.reduce(async (promise, product, index) => {
      await promise;

      const response = await Axios.get('/entries/by-product', {
        params: {
          productId: product.productId,
        },
      });

      const getEntryOrder = response.data;

      if (getEntryOrder) {
        entryProducts.push(
          ...getEntryOrder.products
            .filter(
              (entryProduct) =>
                entryProduct.sincProductId && entryProduct.sincProductId === product.productId,
            )
            .map((entryProduct) => ({
              ...newEntryProduct,
              ...entryProduct,
              positionOnList: index,
              price: product.price,
              unitaryCost: product.cost,
              entryQuantity: product.quantity,
              quantity: product.quantity,
            })),
        );
      } else {
        entryProducts.push({
          ...newEntryProduct,
          ...(product.taxFields?.taxes || {}),
          ...(product.taxFields?.unity?.unityId
            ? {
                unityId: product.taxFields.unity.unityId,
                unity: product.taxFields.unity.name,
                unityRate: product.taxFields.unity.quantity,
              }
            : {}),
          ...(product.taxFields?.entryUnity?.unityId
            ? {
                entryUnityId: product.taxFields.entryUnity.unityId,
                entryUnity: product.taxFields.entryUnity.name,
                entryUnityRate: product.taxFields.entryUnity.quantity,
              }
            : {}),
          positionOnList: index,
          name: product.technicalName,
          supplierId:
            product.suppliers && product.suppliers.length > 0
              ? product.suppliers[0].factoryId
              : 'Sem código de fábrica',
          gtin: product.othersIds?.gtin || '',
          ncm: product.taxFields?.ncm || '',
          cest: product.taxFields?.cest || '',
          origin: product.taxFields?.origin || '',
          price: product.price,
          unitaryCost: product.cost,
          sincProductId: product.productId,
          sincName: product.name || product.technicalName,
          sincLastCost: product.cost,
          sincLastPrice: product.price,
        });
      }
    }, Promise.resolve());

    const totalValueProducts = entryProducts
      .map((product) => product.entryQuantity * product.entryUnitaryCost)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    setEntryOrder((oldFields) => ({
      ...oldFields,
      products: entryProducts,
      totalValueProducts,
      totalValue: totalValueProducts,
    }));

    handleCloseDialogProducts();
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Produtos</Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOpenDialogProducts} disabled={blockFields}>
              Escolher produtos
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {entryOrder.products && entryOrder.products.length > 0 ? (
        <Grid item xs={12}>
          <Box marginTop={2}>
            <ProductsTable
              products={entryOrder.products}
              saveProductCost={saveProductCost}
              entryOrderType={entryOrder.type}
              blockFields={blockFields}
            />
          </Box>
        </Grid>
      ) : null}

      {openDialogProducts ? (
        <QuoteProductDialog
          openDialog={openDialogProducts}
          handleCloseDialog={handleCloseDialogProducts}
          handleConfirmSelect={handleSelectProductsToOrder}
          selectSupplierDocument={entryOrder.supplier.cnpj}
          alreadySelectedProducts={entryOrder.products.map((product) => ({
            productId: product.sincProductId || -1,
            quantity: product.quantity,
          }))}
          sendToEntryOrder
        />
      ) : null}
    </Grid>
  );
}

export default memo(ProductsTab);
