import React, { memo, useEffect, useState } from 'react';
import { Alert, Box, Drawer, Grid, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createOrder, updateOrder as updatedOrder } from '../../../services/melhorGestao/orders';
import newOrder from '../../../constant/newOrder';
import { formatHandleError } from '../../../helpers/formatData';
import getModifiedKeys from '../../../helpers/getModifiedKeys';
import CustomDivider from '../../CustomDivider';
import checkValidCpfCnpj from '../../../helpers/checkValidCpfCnpj';
import TopTab from './TopTab';
import StatusTab from './StatusTab';
import ProductsTab from './ProductsTab';
import CustomerTab from './CustomerTab';
import NotesOnInvoiceBlok from './NotesOnInvoiceBlok';
import ShippingTab from './ShippingTab';
import PaymentsTab from './PaymentsTab';
import DevolutionsTab from './DevolutionsTab';
import OnlyCompanyTab from './OnlyCompanyTab';
import SubmitArea from './SubmitArea';
import { updateUser } from '../../../services/melhorGestao/users';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
}));

function OrderDetails({
  openOrderDetails,
  selectedOrder = newOrder,
  closeOrderDetails = () => {},
  handleOpenCheckByWeight = () => {},
  submitSearchOrders = () => {},
  setFilterOrderStatus = () => {},
  setFilterOrderSource = () => {},
  setFilterOrderCompany = () => {},
}) {
  const classes = useStyles();

  const [initialOrderForm, setInitialOrderForm] = useState(selectedOrder);
  const [orderForm, setOrderForm] = useState(selectedOrder);
  const [loadingUpdateOrder, setLoadingUpdateOrder] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleSaveForm = async ({
    updateFields = {},
    customSaveMessage = '',
    dontShowMessage = false,
  }) => {
    try {
      if (orderForm.orderId) {
        const getUpdatedOrder = await updatedOrder({
          order: { ...orderForm, ...updateFields },
          setLoadingUpdateOrder,
          setSnackbar,
        });

        if (!getUpdatedOrder) {
          return null;
        }

        setOrderForm(getUpdatedOrder);
        setInitialOrderForm(getUpdatedOrder);
      } else {
        const newOrderResponse = await createOrder({
          order: { ...orderForm },
          setLoadingSaveOrder: setLoadingUpdateOrder,
          setSnackbar,
        });

        if (!newOrderResponse) {
          return null;
        }

        setOrderForm(newOrderResponse);
        setInitialOrderForm(newOrderResponse);
      }

      if (orderForm.customer?.userId) {
        await updateUser({
          user: {
            userId: orderForm.customer.userId,
            'documents.inscricaoEstadual': orderForm.customer?.documents?.inscricaoEstadual || '',
          },
          setSnackbar,
        });
      }

      if (!dontShowMessage) {
        setSnackbar({
          message: customSaveMessage || 'Pedido salvo com sucesso',
          open: true,
          type: 'success',
        });
      }

      return null;
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Por algum motivo as alterações do pedido não foram salvas',
        error,
      });
    }
  };

  useEffect(() => {
    const ignoredKeys = ['discountTotal', 'itemsTotal', 'orderTotal'];
    const keys = getModifiedKeys(orderForm, initialOrderForm, ignoredKeys);

    if (keys.length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [orderForm, initialOrderForm]);

  const confirmCloseProductDetails = () => {
    if (!formChanged) {
      return closeOrderDetails();
    }
    return setOrderForm(initialOrderForm);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [totalValue, setTotalValue] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  useEffect(() => {
    let totalValueAccumulator = 0;
    let totalPaidAccumulator = 0;

    if (orderForm.payments.length > 0) {
      orderForm.payments.forEach((payment) => {
        if (!payment.type || payment.type === 'Recebimento') {
          totalValueAccumulator += payment.value;
          totalPaidAccumulator += payment.valuePaid / 100;
        }
      });
    }

    setTotalValue(totalValueAccumulator);
    setTotalPaid(totalPaidAccumulator);
  }, [orderForm.payments]);

  const [seller, setSeller] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [userItemsBought, setUserItemsBought] = useState([]);
  const [devolutions, setDevolutions] = useState([]);
  const [devolutionData, setDevolutionData] = useState(null);

  return (
    <Drawer
      sx={{
        zIndex: 1300,
      }}
      anchor="right"
      open={openOrderDetails}
      onClose={confirmCloseProductDetails}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TopTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setSelectedCompany={setSelectedCompany}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <StatusTab
                orderForm={orderForm}
                setOrderForm={setOrderForm}
                setSeller={setSeller}
                setSnackbar={setSnackbar}
                closeOrderDetails={closeOrderDetails}
              />
            </Box>
          </Grid>
          <CustomDivider />
          <Grid item>
            <ProductsTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              userItemsBought={userItemsBought}
              devolutions={devolutions}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <CustomerTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              userItemsBought={userItemsBought}
              setUserItemsBought={setUserItemsBought}
              setSnackbar={setSnackbar}
            />
          </Grid>
          {orderForm.customer?.documents?.cpf || orderForm.customer?.documents?.cnpj ? (
            <Grid item>
              <NotesOnInvoiceBlok orderForm={orderForm} setOrderForm={setOrderForm} />
            </Grid>
          ) : null}
          <CustomDivider />
          <Grid item>
            <ShippingTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              handleSaveForm={handleSaveForm}
              formChanged={formChanged}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <PaymentsTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              devolutions={devolutions}
              devolutionData={devolutionData}
              formChanged={formChanged}
              totalPaid={totalPaid}
              totalValue={totalValue}
            />
          </Grid>
          <CustomDivider />
          <Grid item>
            <DevolutionsTab
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              formChanged={formChanged}
              devolutions={devolutions}
              setDevolutions={setDevolutions}
              setDevolutionData={setDevolutionData}
              userItemsBought={userItemsBought}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <CustomDivider />
          {selectedCompany && checkValidCpfCnpj(selectedCompany.cnpj) ? (
            <Grid item>
              <OnlyCompanyTab
                orderForm={orderForm}
                setOrderForm={setOrderForm}
                selectedCompany={selectedCompany}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <SubmitArea
        orderForm={orderForm}
        setOrderForm={setOrderForm}
        setInitialOrderForm={setInitialOrderForm}
        formChanged={formChanged}
        selectedCompany={selectedCompany}
        seller={seller}
        totalPaid={totalPaid}
        devolutions={devolutions}
        handleSaveForm={handleSaveForm}
        loadingUpdateOrder={loadingUpdateOrder}
        confirmCloseProductDetails={confirmCloseProductDetails}
        closeOrderDetails={closeOrderDetails}
        handleOpenCheckByWeight={handleOpenCheckByWeight}
        submitSearchOrders={submitSearchOrders}
        setFilterOrderStatus={setFilterOrderStatus}
        setFilterOrderSource={setFilterOrderSource}
        setFilterOrderCompany={setFilterOrderCompany}
        setSnackbar={setSnackbar}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Drawer>
  );
}

export default memo(OrderDetails);
