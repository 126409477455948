import _ from 'lodash';

const getModifiedKeys = (obj1, obj2, ignoreKeys = [], currentKey = '') => {
  const modifiedKeys = [];

  const keys = _.union(Object.keys(obj1 || {}), Object.keys(obj2 || {}));

  keys.forEach((key) => {
    if (ignoreKeys.includes(key)) {
      return;
    }

    const nestedKey = currentKey ? `${currentKey}.${key}` : key;

    if (_.isObject(obj1[key]) && _.isObject(obj2[key])) {
      modifiedKeys.push(...getModifiedKeys(obj1[key], obj2[key], ignoreKeys, nestedKey));
    } else if (!_.isEqual(obj1[key], obj2[key])) {
      modifiedKeys.push(nestedKey);
    }
  });

  return modifiedKeys;
};

export default getModifiedKeys;
