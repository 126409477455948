import Axios from 'axios';
import { Alert, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { formatDate, formatHandleError } from '../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  certAlert: {
    cursor: 'pointer',
  },
}));

function SendCompanyCertificate({
  selectedCompany,
  setSelectedCompany,
  handleGetCompanies,
  setSnackbar,
}) {
  const classes = useStyles();

  const [loadingCertificate, setLoadingCertificate] = useState(false);
  const [selectedCertFile, setSelectedCertFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleUploadCert = async () => {
    try {
      if (!selectedCompany.companyId || !selectedCertFile || !selectedCompany.certPassword) {
        return setSnackbar({
          open: true,
          type: 'error',
          message: 'Selecione um arquivo e informe a senha do certificado',
        });
      }

      setLoadingCertificate(true);

      const certFileBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(selectedCertFile);
      });

      const response = await Axios.post(`/configuration/certificate/${selectedCompany.companyId}`, {
        certFileBase64: certFileBase64.split(',')[1],
        certPassword: selectedCompany.certPassword,
      });

      await handleGetCompanies();
      setSelectedCompany(response.data);

      setSelectedCertFile(null);
      setLoadingCertificate(false);

      return setSnackbar({
        open: true,
        type: 'success',
        message: 'Certificado salvo com sucesso',
      });
    } catch (error) {
      setSelectedCertFile(null);
      setLoadingCertificate(false);

      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao enviar o certificado digital',
        error,
      });
    }
  };

  const getCertificateStatus = () => {
    if (selectedCertFile?.name) {
      return {
        message: selectedCertFile.name,
        severity: 'info',
      };
    }

    if (!selectedCompany.certExpirationDate) {
      return {
        message: 'Certificado não enviado',
        severity: 'error',
      };
    }

    if (dayjs().isAfter(dayjs(selectedCompany.certExpirationDate))) {
      return {
        message: `Expirou em ${formatDate(selectedCompany.certExpirationDate)}`,
        severity: 'error',
      };
    }

    const expirationHours = dayjs(selectedCompany.certExpirationDate).diff(dayjs(), 'hour');

    if (expirationHours < 24) {
      return {
        message: `Expira em ${expirationHours} hora${expirationHours > 1 ? 's' : ''}`,
        severity: 'warning',
      };
    }

    const expirationDays = dayjs(selectedCompany.certExpirationDate).diff(dayjs(), 'day');

    if (expirationDays < 30) {
      return {
        message: `Expira em ${expirationDays} dia${expirationDays > 1 ? 's' : ''}`,
        severity: 'warning',
      };
    }

    return {
      message: `Expira em ${formatDate(selectedCompany.certExpirationDate)}`,
      severity: 'success',
    };
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <label htmlFor="upload-cert">
          <input
            type="file"
            id="upload-cert"
            style={{ display: 'none' }}
            onChange={(event) => setSelectedCertFile(event.target.files[0])}
          />
          <Alert severity={getCertificateStatus().severity} className={classes.certAlert}>
            {getCertificateStatus().message}
          </Alert>
        </label>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          label="Senha"
          key={selectedCompany.companyId}
          variant="filled"
          size="small"
          type={showPassword ? 'text' : 'password'}
          value={selectedCompany.certPassword || ''}
          onChange={(event) =>
            setSelectedCompany((oldFields) => ({
              ...oldFields,
              certPassword: event.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <LoadingButton loading={loadingCertificate} variant="contained" onClick={handleUploadCert}>
          Enviar
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default SendCompanyCertificate;
