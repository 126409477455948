import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';

function TitleHelpContent() {
  return (
    <Box width="600px">
      <Typography align="justify">
        O título é fundamental para que os compradores encontrem o seu produto. Por isso, ele deve
        ser o mais claro possível.
      </Typography>

      <ul>
        <li>
          <Typography align="justify">
            Crie o título{' '}
            <b>
              com Produto + Marca + modelo do produto + algumas especificações que ajudem a
              identificar o produto.
            </b>
          </Typography>
        </li>
        <li>
          <Typography align="justify">
            <b>Evite colocar no título informações sobre outros serviços,</b> como devoluções, frete
            grátis ou parcelamento. Estes dados serão incluídos por nós para que os compradores
            possam vê-los antes mesmo de entrar no anúncio.
          </Typography>
        </li>
        <li>
          <Typography align="justify">
            <b>Caso seu produto seja novo, usado ou recondicionado, não inclua isto no título,</b>{' '}
            nós informaremos no detalhe do anúncio.
          </Typography>
        </li>
        <li>
          <Typography align="justify">
            Não coloque no anúncio informações sobre desconto. Se você oferecer algum desconto,
            destacaremos isso no seu anúncio, indicando a porcentagem do desconto.
          </Typography>
        </li>
        <li>
          <Typography align="justify">
            Separe as palavras com espaço, <b>não use sinais de pontuação nem símbolos.</b>
          </Typography>
        </li>
        <li>
          <Typography align="justify">
            Revise para garantir que <b>não tenha erros de ortografia.</b>
          </Typography>
        </li>
      </ul>
      <Typography align="justify">
        <u>Lembre-se: Só é possível editar o título dos anúncios sem vendas.</u>
      </Typography>
    </Box>
  );
}

export default memo(TitleHelpContent);
