import React, { memo } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../../../../contexts/auth';
import SimpleImage from '../../../Common/SimpleImage';

const useStyles = makeStyles({
  companyBadge: {
    width: 35,
    height: 35,
    marginTop: 5,
  },
});

function OrderCompanyLogo({ orderCompany }) {
  const classes = useStyles();

  const { companies } = useAuth();

  const getCompany = companies.find(
    (findCompany) => findCompany._id === orderCompany || findCompany.name === orderCompany,
  );

  return getCompany ? (
    <Tooltip title={<Typography align="center">{getCompany.name}</Typography>}>
      <Box>
        {getCompany.srcLogoIcon ? (
          <SimpleImage
            key={getCompany.srcLogoIcon}
            src={getCompany.srcLogoIcon}
            height={35}
            width={35}
            alt={getCompany.name}
            className={classes.companyBadge}
          />
        ) : (
          <Typography variant="h4">{getCompany.name.substring(0, 2)}</Typography>
        )}
      </Box>
    </Tooltip>
  ) : null;
}

export default memo(OrderCompanyLogo);
