import Axios from 'axios';
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SimpleDialog from '../../Common/SimpleDialog';
import { formatHandleError } from '../../../helpers/formatData';

function ImportXmlDialog({ openImportXmlDialog, handleCloseImportXmlDialog, setSnackbar }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingImportXml, setLoadingImportXml] = useState(false);

  const handleFileChange = (event) => {
    setErrorMessage('');

    const filesArray = Array.from(event.target.files);
    setSelectedFiles(filesArray);
  };

  const handleConfirmUpload = async () => {
    try {
      setLoadingImportXml(true);
      const formData = new FormData();

      selectedFiles.forEach((selectedFile) => {
        formData.append('xml', selectedFile);
      });

      const response = await Axios.post('/invoices/xml', formData);
      const { message } = response.data;

      setSnackbar({
        message,
        open: true,
        type: 'success',
      });

      setLoadingImportXml(false);
      return handleCloseImportXmlDialog();
    } catch (error) {
      setLoadingImportXml(false);

      return formatHandleError({
        setSnackbar: (value) => setErrorMessage(value.message),
        defaultMessage: 'Algum erro ocorreu ao carregar o arquivo',
        error,
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      const { items } = e.dataTransfer;
      const itemsArray = Array.from(items);

      const files = itemsArray
        .filter((item) => item.kind === 'file')
        .map((item) => item.getAsFile());

      setSelectedFiles(files);
    } else {
      const filesArray = Array.from(e.dataTransfer.files);
      setSelectedFiles(filesArray);
    }
  };

  return (
    <SimpleDialog
      openDialog={openImportXmlDialog}
      handleClose={handleCloseImportXmlDialog}
      dialogTitle="Importar XML NF-e"
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              sx={{
                border: '1px dotted gray',
                borderRadius: '10px',
                padding: '26px',
                cursor: 'pointer',
              }}
              height={80}
              width={400}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="upload-xml">
                <input
                  type="file"
                  id="upload-xml"
                  accept=".xml, .zip"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  multiple
                />
                {selectedFiles.length > 0 ? (
                  <Typography>
                    {selectedFiles.length} arquivo{selectedFiles.length > 1 ? 's' : ''} selecionado
                    {selectedFiles.length > 1 ? 's' : ''}
                  </Typography>
                ) : (
                  <Typography>Arrastar ou selecionar arquivos</Typography>
                )}
              </label>
            </Box>
          </Grid>
          {errorMessage ? (
            <Grid item>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          ) : null}
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      disableConfirmButton={selectedFiles.length === 0}
      confirmButtonLoading={loadingImportXml}
      handleConfirmButton={handleConfirmUpload}
      handleCancelButton={handleCloseImportXmlDialog}
    />
  );
}

export default ImportXmlDialog;
