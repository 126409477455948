import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const getSkyhubProductsBySku = async ({ setSkyhubAds, setLoadingSkyhubAds, productId }) => {
  try {
    setLoadingSkyhubAds(true);
    const response = await Axios.get(`/skyhub/products/by-sku/${productId}`);
    const { skyhubProductsList } = response.data;
    setLoadingSkyhubAds(false);
    return setSkyhubAds(skyhubProductsList);
  } catch (error) {
    setLoadingSkyhubAds(false);
    return error;
  }
};

export const deleteSkyhubProduct = async (company, productId) => {
  try {
    const response = await Axios.delete(`/skyhub/products/${company}/${productId}`);
    const { skyhubProductDeleted } = response.data;
    return skyhubProductDeleted;
  } catch (error) {
    return error;
  }
};

export const getSkyhubPrice = (ourProduct, quantity, inOffer) => {
  const promotionalPrice = (inOffer ? ourProduct.offerPrice : ourProduct.price) * quantity;
  const productPrice = (inOffer ? ourProduct.price : ourProduct.price * 1.1) * quantity;

  return {
    newPromotionalPrice: (promotionalPrice * 1.15).toFixed(2),
    newProductPrice: (productPrice * 1.15).toFixed(2),
  };
};

export const getSkyhubShippingStamp = async ({ orders, setLoadingShippingStamp, setSnackbar }) => {
  try {
    setLoadingShippingStamp(true);
    const ordersToSkyhubShipping = [];

    orders.forEach((order) => {
      if (order.othersIds?.idSkyhub) {
        ordersToSkyhubShipping.push({ idSkyhub: order.othersIds.idSkyhub });
      }
    });

    const response = await Axios.get(`/skyhub/stamps/bulk-print`, {
      params: {
        ordersToSkyhubShipping,
      },
    });

    const { stampsLinks } = response.data;

    stampsLinks.forEach((stamp) => {
      window.open(stamp);
    });

    setLoadingShippingStamp(false);
  } catch (error) {
    setLoadingShippingStamp(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Não conseguimos imprimir a etiqueta. Verifique o sistema de impressão.',
      error,
    });
  }
};
