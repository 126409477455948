import Axios from 'axios';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import newWeighingBalance from '../../../constant/newWeighingBalance';
import CreateBalanceDialog from './CreateBalanceDialog';
import { formatWeight } from '../../../helpers/formatData';
import {
  getWeighingBalances,
  getBalanceWeight,
} from '../../../services/melhorGestao/weighingBalances';
import SimpleBackdrop from '../../Common/SimpleBackdrop';

const useStyles = makeStyles((theme) => ({
  addCompanyIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
  },
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function WeighingBalance() {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [balances, setBalances] = useState([]);
  const [balanceForm, setBalanceForm] = useState(newWeighingBalance);
  const [selectedBalance, setSelectedBalance] = useState(null);

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const handleRefreshBalances = () => setRefreshToken(Math.random());

  const [loadingBalanceWeight, setLoadingBalanceWeight] = useState(false);
  const [balanceData, setBalanceDate] = useState({});

  const [loadingBalances, setLoadingBalances] = useState(false);
  const [disableConfirmCreateBalanceDialog, setDisableConfirmCreateBalanceDialog] = useState(true);

  const [openCreateBalanceDialog, setOpenCreateBalanceDialog] = useState(false);
  const handleOpenCreateBalanceDialog = () => setOpenCreateBalanceDialog(true);
  const handleCloseCreateBalanceDialog = () => setOpenCreateBalanceDialog(false);

  useEffect(() => {
    if (balanceForm.name && balanceForm.host && balanceForm.port) {
      setDisableConfirmCreateBalanceDialog(false);
    } else {
      setDisableConfirmCreateBalanceDialog(true);
    }
  }, [balanceForm]);

  const handleGetBalances = async () => {
    try {
      setLoadingBalances(true);
      const getBalances = await getWeighingBalances();
      setBalances(getBalances);
      setSelectedBalance(getBalances[0] || null);
      setLoadingBalances(false);
    } catch (error) {
      setLoadingBalances(false);
      let errorMessage = 'Algum erro ocorreu ao cerregar a lista de balanças';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  const handleCreateBalance = async () => {
    try {
      const response = await Axios.post('/weighing-balances', balanceForm);
      setBalances([...balances, response.data]);
      handleCloseCreateBalanceDialog();
      setSelectedBalance(response.data);
      setBalanceDate({});
    } catch (error) {
      let errorMessage = 'Algum erro ocorreu ao adicionar uma balança';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    handleGetBalances();
  }, [refreshToken]);

  const handleTestBalance = async (balance) => {
    try {
      setLoadingBalanceWeight(true);
      const testedBalance = await getBalanceWeight(balance);
      setBalanceDate(testedBalance);

      setSnackbar({
        message: testedBalance.message,
        open: true,
        type: testedBalance.success ? 'success' : 'error',
      });
      setLoadingBalanceWeight(false);
    } catch (error) {
      setLoadingBalanceWeight(false);
      let errorMessage = 'Algum erro ocorreu no teste de leitura da balança';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  const handleDeleteBalance = async () => {
    try {
      if (selectedBalance.balanceId) {
        await Axios.delete(`/weighing-balances/${selectedBalance.balanceId}`);
        handleRefreshBalances();
      }
    } catch (error) {
      let errorMessage = 'Algum erro ocorreu ao deletar a balança';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">Balanças</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={<Typography>Adicionar uma balança</Typography>}>
                    <IconButton
                      onClick={() => {
                        setBalanceForm(newWeighingBalance);
                        handleOpenCreateBalanceDialog();
                      }}
                    >
                      <AddCircleIcon className={classes.addCompanyIcon} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>

            <MenuList>
              {balances &&
                balances.length > 0 &&
                balances.map((balance) => (
                  <MenuItem
                    key={balance.balanceId}
                    onClick={() => {
                      setBalanceDate({});
                      setSelectedBalance(balance);
                    }}
                    className={
                      selectedBalance && selectedBalance.balanceId === balance.balanceId
                        ? classes.menuItemSelected
                        : null
                    }
                  >
                    {balance.name}
                  </MenuItem>
                ))}
            </MenuList>
          </Paper>
        </Grid>

        {selectedBalance && selectedBalance.balanceId ? (
          <Grid item sm={8}>
            <Paper>
              <Box padding={2}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">{selectedBalance.name}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleDeleteBalance}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Grid container direction="column" justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography>
                        Posição de captura: {selectedBalance.substrStart}º ao{' '}
                        {selectedBalance.substrEnd}º caractere
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Host: {selectedBalance.host}:{selectedBalance.port}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <LoadingButton
                            loading={loadingBalanceWeight}
                            variant="contained"
                            color="primary"
                            autoFocus
                            onClick={() => handleTestBalance(selectedBalance)}
                          >
                            Testar
                          </LoadingButton>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={<Typography>Dados brutos recebidos da balança</Typography>}
                          >
                            <Typography>
                              Dados recebidos: {balanceData.string || 'Sem dados'}
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            title={<Typography>Valor capturado dos dados recebidos</Typography>}
                          >
                            <Typography>
                              Peso obtido: <b>{formatWeight(balanceData.weight || '00000')} Kg</b>
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
      {openCreateBalanceDialog ? (
        <CreateBalanceDialog
          balanceData={balanceData}
          balanceForm={balanceForm}
          setBalanceForm={setBalanceForm}
          handleTestBalance={handleTestBalance}
          loadingBalanceWeight={loadingBalanceWeight}
          openCreateBalanceDialog={openCreateBalanceDialog}
          handleCloseCreateBalanceDialog={handleCloseCreateBalanceDialog}
          handleConfirmCreateBalanceDialog={handleCreateBalance}
          disableConfirmCreateBalanceDialog={disableConfirmCreateBalanceDialog}
          setSnackbar={setSnackbar}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <SimpleBackdrop loading={loadingBalances} />
    </Box>
  );
}

export default WeighingBalance;
