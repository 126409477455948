import { Alert, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import React, { memo, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { changeOrderStatus } from '../../../../../services/melhorGestao/orders';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import InvoiceEmissionButton from './InvoiceEmissionButton';
import { formatHandleError } from '../../../../../helpers/formatData';
import CustomDatePicker from '../../../../CustomDatePicker';

function ChangeOrderStatusButton({
  orderForm,
  handleSaveForm,
  formChanged,
  setOrderForm = () => {},
  setInitialOrderForm = () => {},
  handleOpenCheckByWeight,
  submitSearchOrders,
  setFilterOrderStatus,
  setFilterOrderSource,
  setFilterOrderCompany,
  closeOrderDetails,
}) {
  const getNextStatus = () => {
    if (orderForm.status === 'Pedido cancelado') {
      return { name: 'Orçamento', button: 'Criar orçamento' };
    }
    if (orderForm.status === 'Orçamento') {
      return {
        name: 'Pedido criado',
        button: 'Criar pedido',
        description: 'Ao criar o pedido, não poderá mais ser editado, o estoque será reservado.',
      };
    }
    if (orderForm.status === 'Pedido criado') {
      return {
        name: 'Pedido confirmado',
        button: 'Confirmar pedido',
      };
    }
    if (orderForm.status === 'Pedido confirmado') {
      return {
        name: 'Pedido faturado',
        button: 'Faturar pedido',
      };
    }
    if (orderForm.status === 'Pedido faturado') {
      return {
        name: 'Pedido embalado',
        button: 'Marcar como embalado',
      };
    }
    if (
      orderForm.status === 'Pedido embalado' ||
      orderForm.status === 'Pedido enviado' ||
      orderForm.status === 'Pedido entregue'
    ) {
      return {
        name: 'Pedido concluído',
        button: 'Marcar como concluído',
      };
    }
    return null;
  };

  const nextStatus = getNextStatus();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const handleOpenComfirmDialog = () => setOpenDialogConfirm(true);
  const handleCloseComfirmDialog = () => setOpenDialogConfirm(false);

  const defaultNfeKey = '7496';

  const [invoiceForm, setInvoiceForm] = useState({
    key: '',
    number: '',
    serie: '',
    emissionDate: null,
    totalValue: orderForm.orderTotal,
    status: 'aprovado',
  });

  const handleChangeInvoice = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    return setInvoiceForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const handleChangeEmissionDate = (emissionDate) => {
    setInvoiceForm((oldFields) => ({
      ...oldFields,
      emissionDate,
    }));
  };

  const handleComfirm = async () => {
    try {
      if (nextStatus?.name) {
        if (orderForm.status === 'Pedido confirmado') {
          await handleSaveForm({
            updateFields: {
              invoice: {
                ...invoiceForm,
                ...(invoiceForm.key === defaultNfeKey
                  ? {
                      key: '',
                      number: 0,
                      serie: 0,
                      emissionDate: new Date(),
                    }
                  : {}),
              },
            },
            dontShowMessage: true,
          });
        }

        const updatedOrder = await changeOrderStatus({
          orderId: orderForm.orderId,
          toStatus: nextStatus.name,
          setLoadingUpdateOrder: setLoadingUpdateStatus,
          setSnackbar,
        });

        if (nextStatus.description) {
          handleCloseComfirmDialog();
        }

        if (updatedOrder) {
          setOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
          setInitialOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
        }
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar NF-e',
        error,
      });
    }
  };

  const handleOpenComfirm = () => {
    if (nextStatus.name === 'Pedido embalado') {
      return handleOpenCheckByWeight();
    }
    return handleOpenComfirmDialog();
  };

  const [loadingAddNfe, setLoadingAddNfe] = useState(false);

  const [openAddNfeDialog, setOpenAddNfeDialog] = useState(false);
  const handleOpenAddNfeDialog = () => setOpenAddNfeDialog(true);
  const handleCloseAddNfeDialog = () => setOpenAddNfeDialog(false);

  const handleConfirmAddNfeDialog = async () => {
    setLoadingAddNfe(true);
    await handleComfirm();

    setLoadingAddNfe(false);
    return handleCloseAddNfeDialog();
  };

  const nfeInput = useRef();

  return (
    <Box>
      {orderForm.status === 'Pedido confirmado' ? (
        <Button variant="contained" color="secondary" onClick={handleOpenAddNfeDialog}>
          Informar NF-e
        </Button>
      ) : null}
      {nextStatus && nextStatus.name !== 'Pedido faturado' ? (
        <LoadingButton
          loading={loadingUpdateStatus}
          variant="contained"
          color="primary"
          onClick={nextStatus.description ? handleOpenComfirm : handleComfirm}
        >
          {nextStatus.button}
        </LoadingButton>
      ) : null}
      {openDialogConfirm ? (
        <SimpleDialog
          openDialog={openDialogConfirm}
          handleClose={handleCloseComfirmDialog}
          dialogTitle={nextStatus.button}
          dialogText={`O status mudará para "${nextStatus.name}"`}
          content={<Typography>{nextStatus.description}</Typography>}
          cancelButtonText="Cancelar"
          maxWidth="xs"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseComfirmDialog}
          handleConfirmButton={handleComfirm}
          disableConfirmButton={formChanged}
        />
      ) : null}
      {openAddNfeDialog ? (
        <SimpleDialog
          openDialog={openAddNfeDialog}
          handleClose={handleCloseAddNfeDialog}
          dialogTitle="Emitir/Informar NF-e"
          dialogText="Digite os dados da Nota Fiscal caso já exista. Ao confirmar ou emitir uma nova NF-e o estoque será baixado."
          actionButton={
            <InvoiceEmissionButton
              orderForm={orderForm}
              setOrderForm={setOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              setSnackbar={setSnackbar}
              submitSearchOrders={submitSearchOrders}
              setFilterOrderStatus={setFilterOrderStatus}
              setFilterOrderSource={setFilterOrderSource}
              setFilterOrderCompany={setFilterOrderCompany}
              handleCloseAddNfeDialog={handleCloseAddNfeDialog}
              closeOrderDetails={closeOrderDetails}
            />
          }
          content={
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CustomInput
                      name="serie"
                      label="Série"
                      value={invoiceForm.serie}
                      onChange={handleChangeInvoice}
                      number
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      name="number"
                      label="Número"
                      value={invoiceForm.number}
                      onChange={handleChangeInvoice}
                      number
                    />
                  </Grid>
                  <Grid item>
                    <CustomDatePicker
                      size="small"
                      label="Data de emissão"
                      format="DD/MM/YYYY"
                      variant="standard"
                      value={invoiceForm.emissionDate}
                      onChange={handleChangeEmissionDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CustomInput
                  name="key"
                  label="Chave da NF-e"
                  value={invoiceForm.key}
                  onChange={handleChangeInvoice}
                  error={
                    invoiceForm.key !== defaultNfeKey && invoiceForm.key.split('').length !== 44
                  }
                  inputRef={nfeInput}
                />
              </Grid>
              <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </Grid>
          }
          maxWidth="sm"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          confirmButtonLoading={loadingAddNfe}
          handleCancelButton={handleCloseAddNfeDialog}
          handleConfirmButton={handleConfirmAddNfeDialog}
          disableConfirmButton={
            invoiceForm.key !== defaultNfeKey && invoiceForm.key.split('').length !== 44
          }
        />
      ) : null}
      {!openAddNfeDialog ? (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  );
}

export default memo(ChangeOrderStatusButton);
