import Axios from 'axios';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import MercadoLivreAttributeDialog from './MercadoLivreAttributeDialog';
import MercadoLivreCategoryDialog from './MercadoLivreCategoryDialog';
import CompanySelectOptions from '../../../../Common/CompanySelectOptions';
import PreviewVideoForProduct from '../../../../Products/Details/InformationTab/PreviewVideoForProduct';
import { formatHandleError } from '../../../../../helpers/formatData';
import MercadoLivreAdTypesMenu from './MercadoLivreAdTypesMenu';

const useStyles = makeStyles(() => ({
  sellerLogoGrid: {
    height: 45,
    borderRadius: 8,
    backgroundColor: '#000',
    padding: 10,
  },
  sellerLogoImg: {
    width: 160,
  },
  gridDescriptionInput: {
    minWidth: 600,
  },
}));

function CategoryAndAttributesButtons({ adForm, setAdForm, setSnackbar }) {
  const classes = useStyles();

  const [openDialogConfirmChangeCategory, setOpenDialogConfirmChangeCategory] = useState(false);
  const handleOpenDialogConfirmChangeCategory = () => setOpenDialogConfirmChangeCategory(true);
  const handleCloseDialogConfirmChangeCategory = useCallback(
    () => setOpenDialogConfirmChangeCategory(false),
    [],
  );

  const [openMercadoLivreAttributeDialog, setOpenMercadoLivreAttributeDialog] = useState(false);
  const handleOpenMercadoLivreAttributeDialog = () => setOpenMercadoLivreAttributeDialog(true);
  const handleCloseMercadoLivreAttributeDialog = useCallback(
    () => setOpenMercadoLivreAttributeDialog(false),
    [],
  );

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const handleOpenCategoryDialog = () => setOpenCategoryDialog(true);
  const handleCloseCategoryDialog = useCallback(() => setOpenCategoryDialog(false), []);

  const handlePredictCategory = async () => {
    setOpenDialogConfirmChangeCategory(false);
    setAdForm((oldFields) => ({
      ...oldFields,
      attributes: [],
    }));
    handleOpenCategoryDialog();
  };

  const handleTryPredictCategory = () => {
    if (adForm.attributes && adForm.attributes.length > 0) {
      return handleOpenDialogConfirmChangeCategory();
    }
    return handlePredictCategory();
  };

  const handleChangeCategory = useCallback(
    (category) => {
      setAdForm((oldFields) => ({
        ...oldFields,
        category: {
          categoryId: category.category_id || category.id,
          name: category.category_name || category.name,
        },
      }));
      return handleCloseCategoryDialog();
    },
    [setAdForm, handleCloseCategoryDialog],
  );

  const [openDialogDescription, setOpenDialogDescription] = useState(false);
  const handleOpenDialogDescription = useCallback(() => setOpenDialogDescription(true), []);
  const handleCloseDialogDescription = useCallback(() => setOpenDialogDescription(false), []);

  const [openDialogVideoProduct, setOpenDialogVideoProduct] = useState(false);
  const handleOpenDialogVideoProduct = useCallback(() => setOpenDialogVideoProduct(true), []);
  const handleCloseDialogVideoProduct = useCallback(() => setOpenDialogVideoProduct(false), []);

  const [validUrl, setValidUrl] = useState(true);
  const [loadingValidatorUrl, setLoadingValidatorUrl] = useState(false);

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setAdForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
    return handleCloseCategoryDialog();
  };

  const [filterCompaniesByIds, setFilterCompaniesByIds] = useState([]);
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/mercado-livre/configs');

        const mlActiveConfigs = response.data.filter((mlConfig) => mlConfig.active);
        const mlCompaniesIds = mlActiveConfigs.map((mlConfig) => mlConfig.company);

        setFilterCompaniesByIds(mlCompaniesIds);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: `Algum erro ocorreu ao buscar configurações do Mercado Livre`,
          error,
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!adForm.mlb) {
      const companies = [];

      filterOrderCompany.forEach((company) => {
        if (company.active) {
          companies.push(company.id);
        }
      });

      setAdForm((oldFields) => ({
        ...oldFields,
        companies,
      }));
    }
  }, [adForm.mlb, filterOrderCompany]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="caption">Categoria:</Typography>
              </Grid>
              <Grid item>
                {adForm.category && adForm.category.name !== '' ? (
                  <Typography variant="caption">{adForm.category.name}</Typography>
                ) : (
                  <Typography variant="caption" color="error">
                    Não selecionada
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {filterCompaniesByIds.length > 0 ? (
              <CompanySelectOptions
                inputLabel="Empresa/Conta"
                variant="standard"
                autoConfirm
                filtersOptions={filterOrderCompany}
                setFiltersOptions={setFilterOrderCompany}
                filterCompaniesByIds={filterCompaniesByIds}
                selectedCompaniesByIds={adForm.companies}
                disabled={!!adForm.mlb}
              />
            ) : (
              <Skeleton variant="rectangular" width={200} height={45} />
            )}
          </Grid>
          <Grid item>
            <MercadoLivreAdTypesMenu
              adForm={adForm}
              setAdForm={setAdForm}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  {!adForm.mlb && adForm.title && adForm.companies.length > 0
                    ? 'Escolha a categoria padrão'
                    : null}
                  {!adForm.mlb && !adForm.title ? 'Preencha o título do anúncio' : null}
                  {!adForm.mlb && adForm.title && adForm.companies.length === 0
                    ? 'Selecione uma ou mais empresas'
                    : null}
                  {adForm.mlb ? 'A categoria não pode ser alterada' : null}
                </Typography>
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleTryPredictCategory}
                  disabled={!adForm.title || !adForm.companies.length || !!adForm.mlb}
                >
                  Categoria
                </Button>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  {adForm.category && adForm.category.categoryId && adForm.companies.length > 0
                    ? 'Preencha as características'
                    : null}
                  {!adForm.category || !adForm.category.categoryId
                    ? 'Selecione uma categoria'
                    : null}
                  {adForm.category && adForm.category.categoryId && adForm.companies.length === 0
                    ? 'Selecione uma ou mais empresas'
                    : null}
                </Typography>
              }
            >
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleOpenMercadoLivreAttributeDialog}
                  disabled={
                    !adForm.category || !adForm.category.categoryId || !adForm.companies.length
                  }
                >
                  Características
                </Button>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogDescription}>
              Descrição
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleOpenDialogVideoProduct}>
              Vídeo
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Publicar produto diretamente no catálogo do Mercado Livre.
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    value={adForm.publishOnMlCatalog}
                    onChange={() =>
                      setAdForm((oldFields) => ({
                        ...oldFields,
                        publishOnMlCatalog: !adForm.publishOnMlCatalog,
                      }))
                    }
                    checked={adForm.publishOnMlCatalog}
                    color="primary"
                  />
                }
                label="Publicar no catálogo"
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Preço do produto a ser considerado mais taxas
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  <Switch
                    value={adForm.offerPrice}
                    onChange={() =>
                      setAdForm((oldFields) => ({
                        ...oldFields,
                        offerPrice: !adForm.offerPrice,
                      }))
                    }
                    checked={adForm.offerPrice}
                    color="primary"
                  />
                }
                label="Preço de oferta"
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {openDialogConfirmChangeCategory ? (
        <SimpleDialog
          openDialog={openDialogConfirmChangeCategory}
          handleClose={handleCloseDialogConfirmChangeCategory}
          dialogTitle="Tem certeza que deseja alterar a categoria?"
          dialogText="Caso você altere a categoria você irá perder os dados cadastrados nas características"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar alteração de categoria"
          handleCancelButton={handleCloseDialogConfirmChangeCategory}
          handleConfirmButton={handlePredictCategory}
        />
      ) : null}
      {openMercadoLivreAttributeDialog ? (
        <MercadoLivreAttributeDialog
          adForm={adForm}
          setAdForm={setAdForm}
          handleCloseMercadoLivreAttributeDialog={handleCloseMercadoLivreAttributeDialog}
          openMercadoLivreAttributeDialog={openMercadoLivreAttributeDialog}
        />
      ) : null}
      {openCategoryDialog ? (
        <MercadoLivreCategoryDialog
          openDialog={openCategoryDialog}
          handleClose={handleCloseCategoryDialog}
          handleClick={handleChangeCategory}
          adForm={adForm}
        />
      ) : null}
      {openDialogDescription ? (
        <SimpleDialog
          content={
            <Grid className={classes.gridDescriptionInput}>
              <CustomInput
                name="description"
                label="Descrição"
                rows={20}
                value={adForm.description}
                onChange={handleChange}
              />
            </Grid>
          }
          dialogTitle="Alterar descrição"
          dialogText="Altere abaixo a descrição do produto"
          handleClose={handleCloseDialogDescription}
          openDialog={openDialogDescription}
        />
      ) : null}
      {openDialogVideoProduct && (
        <SimpleDialog
          content={
            <Box>
              <PreviewVideoForProduct
                videoLink={adForm.videoLink}
                validURL={validUrl}
                setValidUrl={setValidUrl}
                setLoadingValidatorUrl={setLoadingValidatorUrl}
              />
              <CustomInput
                name="videoLink"
                label="Altere ou adicione abaixo o link do vídeo"
                value={adForm.videoLink || ''}
                error={!validUrl}
                onChange={handleChange}
                loading={loadingValidatorUrl}
                disabled={!!adForm.mlb}
              />
            </Box>
          }
          dialogTitle="Vídeo do produto"
          dialogText={adForm.mlb ? 'O vídeo pode ser alterado no produto' : ''}
          handleClose={handleCloseDialogVideoProduct}
          openDialog={openDialogVideoProduct}
        />
      )}
    </Grid>
  );
}

export default CategoryAndAttributesButtons;
