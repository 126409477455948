import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import Axios from 'axios';
import React, { useState } from 'react';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import filterProductOptionsInitial from '../../../constant/filterProductOptionsInitial';
import filterProductStatusInitial from '../../../constant/filterProductStatusInitial';
import SupplierSelectOptions from '../../Common/SupplierSelectOptions';

function ProductsReports() {
  const [filterByCompanyStock, setFilterByCompanyStock] = useState([]);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [filtersBySuppliers, setFiltersBySuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getReports = async () => {
    try {
      setLoading(true);

      const response = await Axios.post('/reports/products', {
        filterByCompanyStock,
        filtersBySuppliers,
        filtersOptions: [...filtersOptions, ...filtersStatus],
      });

      const { url } = response.data;

      window.open(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <CompanySelectOptions
                    inputLabel="Empresa"
                    filtersOptions={filterByCompanyStock}
                    setFiltersOptions={setFilterByCompanyStock}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <SupplierSelectOptions
                    inputLabel="Fornecedor"
                    filtersOptions={filtersBySuppliers}
                    setFiltersOptions={setFiltersBySuppliers}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Estoque"
                    multiple={false}
                    filtersOptions={filtersOptions}
                    setFiltersOptions={setFiltersOptions}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Status"
                    filtersOptions={filtersStatus}
                    setFiltersOptions={setFiltersStatus}
                    hideButton
                  />
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                  <Grid item>
                    <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    getReports();
                  }}
                >
                  Baixar relatório
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default ProductsReports;
