import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import { isURL } from '../../../../../services/melhorGestao/validator';

function PreviewVideoForProduct({
  videoLink,
  validURL,
  setValidUrl,
  setLoadingValidatorUrl = () => {},
}) {
  const [timeoutValidatorUrlId, setTimeoutValidatorUrlId] = useState(false);

  useEffect(() => {
    setLoadingValidatorUrl(true);
    if (timeoutValidatorUrlId) {
      clearTimeout(timeoutValidatorUrlId);
    }
    setTimeoutValidatorUrlId(
      setTimeout(async () => {
        const resultURL = await isURL(videoLink);
        setValidUrl(resultURL);

        setLoadingValidatorUrl(false);
      }, 1000),
    );
  }, [videoLink]);

  return (
    <Grid container wrap="nowrap">
      <Box sx={{ width: 640, marginRight: 0.5, my: 5 }}>
        {validURL && videoLink && videoLink.split('').length > 18 ? (
          <ReactPlayer url={videoLink} />
        ) : (
          <Grid container wrap="nowrap" />
        )}
      </Box>
    </Grid>
  );
}

export default PreviewVideoForProduct;
