import React, { useEffect, useState } from 'react';
import {
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteForeverOutlined } from '@mui/icons-material';
import Axios from 'axios';
import SimpleBackdrop from '../../../../../Common/SimpleBackdrop';
import { formatHandleError } from '../../../../../../helpers/formatData';

const useStyles = makeStyles({
  smallCell: {
    width: 40,
  },
  mediumCell: {
    width: 100,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
});

function CitiesListTable({ setSelectedCity }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [configuredCities, setConfiguredCities] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      message: '',
      open: false,
    }));
  };

  useEffect(() => {
    const getConfiguredCities = async () => {
      setLoading(true);
      try {
        const response = await Axios.get('/own-shipping');
        setConfiguredCities(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar os dados da cidade',
          error,
        });
      }
    };

    getConfiguredCities();
  }, []);

  const handleDeleteCity = async (cityId) => {
    setLoading(true);
    try {
      const response = await Axios.delete(`/own-shipping/${cityId}`);
      setConfiguredCities(response.data);
      setLoading(false);
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao remover o cadastro da cidade',
        error,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer>
        <SimpleBackdrop loading={loading} absolutePosition />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Cidade</TableCell>
              <TableCell align="center" className={classes.mediumCell}>
                CEP Inicial
              </TableCell>
              <TableCell align="center" className={classes.mediumCell}>
                CEP Final
              </TableCell>
              <TableCell align="center" className={classes.mediumCell}>
                Motoristas
              </TableCell>
              <TableCell align="center" className={classes.smallCell}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {configuredCities && configuredCities.length ? (
              configuredCities.map((city) => (
                <TableRow
                  key={city.startCep}
                  className={classes.cursorPointer}
                  onClick={() => setSelectedCity(city)}
                >
                  <TableCell align="center">{city.cityName}</TableCell>
                  <TableCell align="center">{city.startCep}</TableCell>
                  <TableCell align="center">{city.endCep}</TableCell>
                  <TableCell align="center">{city.drivers.length}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={`Remover configurações de rota de ${city.cityName}`}>
                      <DeleteForeverOutlined
                        className={classes.cursorPointer}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteCity(city.cityId);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Não há cidades cadastradas
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default CitiesListTable;
