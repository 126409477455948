import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const searchDiscountCoupons = async ({
  limit = 20,
  skip = 0,
  setLoadingDiscountCouponsList = () => {},
  setDiscountCouponsList,
  setTotalDiscountCoupons,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingDiscountCouponsList(true);

    const response = await Axios.get('/discountCoupon', {
      params: {
        skip,
        limit,
      },
    });

    const { totalCoupons, discountCoupons } = response.data;

    setDiscountCouponsList(discountCoupons);
    setTotalDiscountCoupons(totalCoupons);

    return setLoadingDiscountCouponsList(false);
  } catch (error) {
    setLoadingDiscountCouponsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar os cupons de desconto',
      error,
    });
  }
};

export default searchDiscountCoupons;
