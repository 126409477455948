import React, { useCallback, useState } from 'react';
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Axios from 'axios';
import SimpleDialog from '../../../Common/SimpleDialog';
import BannerImgTab from '../BannerImgTab';
import { formatHandleError } from '../../../../helpers/formatData';

function BannerPaper({ company, bannerType, homeBanner, refreshCms, setSnackbar }) {
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const handleOpenDialogConfirmDeleteOffer = useCallback(() => setOpenDialogConfirmDelete(true));
  const handleCloseDialogConfirmDelete = useCallback(() => setOpenDialogConfirmDelete(false));

  const handleConfirmDeleteBanner = async () => {
    try {
      handleCloseDialogConfirmDelete();

      await Axios.delete('/cms', {
        params: {
          company,
          type: bannerType,
          id: homeBanner._id,
        },
      });

      refreshCms();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao excluir o Banner',
        error,
      });
    }
  };

  const handleChangePositionBanner = async (to) => {
    try {
      await Axios.get('/cms/position', {
        params: {
          company,
          type: bannerType,
          id: homeBanner._id,
          to,
        },
      });

      refreshCms();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao mudar a posição do Banner',
        error,
      });
    }
  };

  return (
    <Paper elevation={3}>
      <Box padding={2}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Tooltip title={<Typography align="center">Alterar a posição</Typography>}>
                      <IconButton size="small" onClick={() => handleChangePositionBanner('up')}>
                        <ArrowDropUpIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={<Typography align="center">Alterar a posição</Typography>}>
                      <IconButton size="small" onClick={() => handleChangePositionBanner('down')}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {bannerType === 'carouselAds' ? (
                  <Tooltip title={homeBanner.alt}>
                    <Typography variant="caption">
                      {homeBanner.alt && homeBanner.alt.length > 15
                        ? `${homeBanner.alt.substring(0, 15).trim()}...`
                        : homeBanner.alt}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="caption">{homeBanner.alt}</Typography>
                )}
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleOpenDialogConfirmDeleteOffer}>
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <BannerImgTab
              src={homeBanner.src}
              srcMobile={homeBanner.srcMobile}
              alt={homeBanner.alt}
              bannerType={bannerType}
              onImgClick={() => {}}
            />
          </Grid>
        </Grid>
      </Box>

      {openDialogConfirmDelete && (
        <SimpleDialog
          cancelButtonText="Cancelar"
          content={
            <Grid container>
              <Grid item>
                <Typography align="center">Você está prestes a excluir este banner.</Typography>
                <Typography align="center">Esta ação não poderá ser desfeita.</Typography>
              </Grid>
            </Grid>
          }
          confirmButtonText="Excluir"
          dialogTitle="Excluir banner"
          handleCancelButton={handleCloseDialogConfirmDelete}
          handleClose={handleCloseDialogConfirmDelete}
          handleConfirmButton={handleConfirmDeleteBanner}
          openDialog={openDialogConfirmDelete}
        />
      )}
    </Paper>
  );
}

export default BannerPaper;
