import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState } from 'react';
import { getProducts } from '../../../../services/melhorGestao/products';
import SimpleImage from '../../../Common/SimpleImage';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 40,
  },
  noPaddingCell: {
    padding: 0,
  },
  img: {
    width: '100%',
  },
}));

function CompositionTable({ productForm }) {
  const classes = useStyles();
  const [productInputs, setProductInputs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (productForm.productInputs && productForm.productInputs.length > 0) {
        const products = await getProducts({
          productsIds: productForm.productInputs.map((productInput) => productInput.productId),
          allProducts: false,
        });

        if (products) {
          setProductInputs(products);
        }
      }
    };
    fetchData();
  }, [productForm]);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.smallCell}>SKU</TableCell>
            <TableCell className={classes.smallCell}>Imagem</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Quantidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productInputs && productInputs.length > 0 ? (
            productInputs.map((productInput) => (
              <TableRow key={productInput.productId}>
                <TableCell>{productInput.productId}</TableCell>
                <TableCell className={classes.noPaddingCell}>
                  {productInput.images && productInput.images.length > 0 ? (
                    <SimpleImage
                      src={productInput.images[0].src}
                      height={75}
                      width={75}
                      alt={productInput.name}
                      className={classes.img}
                    />
                  ) : null}
                </TableCell>
                <TableCell>{productInput.name}</TableCell>
                <TableCell align="center" width={100}>
                  {productForm.productInputs.find(
                    (findInput) => findInput.productId === productInput.productId,
                  )?.quantity || 0}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography>Não há nada selecionado como composição deste produto</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default memo(CompositionTable);
