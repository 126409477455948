import React, { useEffect, useState } from 'react';
import { Box, Button, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import MadeiraMadeiraEditTitle from './MadeiraMadeiraEditTitle';
import MadeiraMadeiraCategoryDialog from './MadeiraMadeiraCategoryDialog';
import { getMadeiraMadeiraPrice } from '../../../../services/melhorGestao/madeiraMadeira';
import CustomInput from '../../../CustomInput';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import checkConsiderOffersOnMarketplaces from '../../../../helpers/checkConsiderOffersOnMarketplaces';

function MadeiraMadeiraDetails({
  adForm,
  setAdForm,
  productForm,
  setSnackbar,
  publishOnMadeiraMadeira,
  setPublishOnMadeiraMadeira,
}) {
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const handleChangeMadeiraMadeiraCategory = (category) => {
    setAdForm((oldFields) => ({
      ...oldFields,
      category: {
        categoryId: category.id,
        name: category.name,
      },
    }));
    return setOpenCategoryDialog(false);
  };

  const [basePrice, setBasePrice] = useState(productForm.price);
  const [offerPrice, setOfferPrice] = useState(false);

  const handleChangePriceBase = () => {
    if (offerPrice) {
      setBasePrice(productForm.price);
    }
    if (!offerPrice) {
      setBasePrice(checkConsiderOffersOnMarketplaces(productForm));
    }
    setOfferPrice(!offerPrice);
  };

  const [madeiraMadeiraAdCost, setMadeiraMadeiraAdCost] = useState(0);
  const [mdeiraMadeiraAdPrice, setMadeiraMadeiraAdPrice] = useState(0);
  const [mdeiraMadeiraOfferAdPrice, setMadeiraMadeiraOfferAdPrice] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      if (adForm.quantity > 0) {
        const calculatedBasePrice = adForm.quantity * basePrice;
        const productInOffer = productForm.offer ? offerPrice || false : false;
        const madeiraMadeiraPrice = getMadeiraMadeiraPrice(
          productForm,
          adForm.quantity,
          productInOffer,
        );

        setMadeiraMadeiraAdCost(calculatedBasePrice - calculatedBasePrice * (1 - 15 / 100));
        setMadeiraMadeiraAdPrice(parseFloat(madeiraMadeiraPrice.newProductPrice));
        setMadeiraMadeiraOfferAdPrice(parseFloat(madeiraMadeiraPrice.newPromotionalPrice));

        setAdForm((oldFields) => ({
          ...oldFields,
          price: parseFloat(madeiraMadeiraPrice.newPromotionalPrice),
          offerPrice,
        }));
      } else {
        setMadeiraMadeiraAdCost(0);
        setMadeiraMadeiraAdPrice(0);
        setMadeiraMadeiraOfferAdPrice(0);

        setAdForm((oldFields) => ({
          ...oldFields,
          price: 0,
          offerPrice,
        }));
      }
    };
    fetchData();
  }, [offerPrice, adForm.quantity]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Madeira Madeira</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnMadeiraMadeira}
                  onClick={() => setPublishOnMadeiraMadeira(!publishOnMadeiraMadeira)}
                  checked={publishOnMadeiraMadeira}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {publishOnMadeiraMadeira ? (
        <>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Tooltip
                  title={
                    <Typography align="center">
                      Digite a quantidade deste produto para compor no anúncio.
                    </Typography>
                  }
                >
                  <Box>
                    <CustomInput
                      label="Kit com"
                      value={adForm.quantity}
                      onChange={(event) =>
                        setAdForm((oldFields) => ({
                          ...oldFields,
                          quantity: event.target.value,
                        }))
                      }
                      color="secondary"
                      number
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <MadeiraMadeiraEditTitle
                  productForm={productForm}
                  adForm={adForm}
                  setAdForm={setAdForm}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {adForm.name
                        ? 'Escolha a categoria padrão para os anúncios deste produto'
                        : 'Para selecionar a categoria preencha o título padrão do anúncio'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenCategoryDialog(true)}
                      disabled={!adForm || !adForm.name}
                    >
                      Categoria
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Categoria:</Typography>
                  </Grid>
                  <Grid item>
                    {adForm.category && adForm.category.name !== '' ? (
                      <Typography variant="caption">{adForm.category.name}</Typography>
                    ) : (
                      <Typography variant="caption" color="error">
                        Não selecionada
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      Preço do produto a ser considerado mais taxas
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        value={adForm.offerPrice}
                        onChange={handleChangePriceBase}
                        checked={adForm.offerPrice}
                        color="primary"
                      />
                    }
                    label="Preço de oferta"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {adForm.quantity > 0 && adForm.price ? (
              <Grid container justifyContent="space-between">
                <Grid container direction="column" item xs={6}>
                  <Grid item>
                    <Tooltip
                      title={
                        <Typography align="center">
                          Preço da venda sem taxas: R${' '}
                          {numberToRealWithPeriod(adForm.quantity * basePrice)}
                          {' Porcentagem da taxa: 15%'}
                        </Typography>
                      }
                    >
                      <Typography>
                        Taxas Madeira Madeira: R$ {numberToRealWithPeriod(madeiraMadeiraAdCost)}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      <Typography align="center">
                        {mdeiraMadeiraOfferAdPrice < 30
                          ? 'O valor mínimo de um anúncio no Madeira Madeira é de R$ 30,00'
                          : `Cálculo preço por: Valor base (R$ ${numberToRealWithPeriod(
                              adForm.quantity * basePrice,
                            )}) + 15%`}
                      </Typography>
                    }
                  >
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item>
                        <Typography variant="h6">Preço do anúncio:</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Typography variant="h3">de:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h3"
                              color={mdeiraMadeiraAdPrice < 30 ? 'error' : 'textPrimary'}
                            >
                              R$ {numberToRealWithPeriod(mdeiraMadeiraAdPrice)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h3">por:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h6"
                              color={mdeiraMadeiraOfferAdPrice < 30 ? 'error' : 'textPrimary'}
                            >
                              R$ {numberToRealWithPeriod(mdeiraMadeiraOfferAdPrice)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item>
            <Typography color={grey[500]} variant="body2">
              Ao clicar em anunciar o produto será enviado para o Madeira Madeira. O valor mínimo de
              um anúncio no Madeira Madeira é de R$ 30,00. Se necessário aumente a quantidade no
              anúncio para chegar ao valor e anunciar um kit de quantidade.
            </Typography>
          </Grid>
        </>
      ) : null}
      {openCategoryDialog ? (
        <MadeiraMadeiraCategoryDialog
          openDialog={openCategoryDialog}
          handleClose={() => setOpenCategoryDialog(false)}
          dialogTitle="Selecione a categoria mais indicada"
          searchTerm={adForm.name ? adForm.name.split(' ')[0] : ''}
          handleClick={handleChangeMadeiraMadeiraCategory}
        />
      ) : null}
    </Grid>
  );
}

export default MadeiraMadeiraDetails;
