import { Box, CircularProgress, Grid, Grow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { getSkyhubProductsBySku } from '../../../../../services/melhorGestao/skyhub';
import SkyhubAdResume from './SkyhubAdResume';
import CustomDivider from '../../../../CustomDivider';

function AmericanasAd({
  classes,
  productForm,
  setProductForm,
  setFormChanged,
  refreshSearch,
  setRefreshSearch,
}) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingSkyhubAds, setLoadingSkyhubAds] = useState(false);
  const [skyhubAds, setSkyhubAds] = useState([]);

  useEffect(() => {
    if (productForm.productId) {
      getSkyhubProductsBySku({
        setSkyhubAds,
        setLoadingSkyhubAds,
        productId: productForm.productId,
      });
    }
  }, [productForm.productId, refreshSearch]);

  if (!skyhubAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img
                  className={classes.sourceBadge}
                  src="/assets/imgs/americanas-badge.svg"
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Americanas
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingSkyhubAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {skyhubAds.length > 0 &&
                skyhubAds.map((skyhubAd) => (
                  <Grid item key={skyhubAd.sku}>
                    <SkyhubAdResume
                      skyhubAd={skyhubAd}
                      product={productForm}
                      setProductForm={setProductForm}
                      setFormChanged={setFormChanged}
                      setRefreshSearch={setRefreshSearch}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

export default AmericanasAd;
