import Axios from 'axios';
import React, { memo, useCallback, useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link as LinkMaterial,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../CustomInput';
import checkValidCpfCnpj from '../../../../helpers/checkValidCpfCnpj';
import WebmaniaClassesImpostosMenu from '../../../Common/WebmaniaClassesImpostosMenu';
import productOrigins from '../../../../constant/productTaxesInformations';
import MercadoLivreTaxRulesMenu from '../../../Common/MercadoLivreTaxRulesMenu';
import { formatHandleError } from '../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  classesImpostosPaper: {
    height: 80,
    padding: 5,
    backgroundColor: theme.palette.primary.primary,
  },
}));

function FiscalTab({ productForm, setProductForm, setSnackbar }) {
  const classes = useStyles();

  const handleChange = useCallback((event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    return setProductForm((oldFields) => ({
      ...oldFields,
      taxFields: {
        ...oldFields.taxFields,
        [fieldName]: fieldValue,
      },
    }));
  }, []);

  const [companiesConfigurations, setCompaniesConfigurations] = useState([]);
  const [classesImpostosVenda, setClassesImpostosVenda] = useState([]);
  const [mercadoLivreTaxRules, setMercadoLivreTaxRules] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/configuration/companies');
        const companies = response.data;
        setCompaniesConfigurations(companies);

        setClassesImpostosVenda(
          companies
            .filter((company) => checkValidCpfCnpj(company.cnpj))
            .map((company) => {
              const findClasseImpostosVenda = productForm.taxFields?.classesImpostosVenda?.find(
                (classeImpostosVenda) => classeImpostosVenda.company === company._id,
              );

              return {
                _id: findClasseImpostosVenda?._id || '',
                webmaniaRef: findClasseImpostosVenda?.webmaniaRef || '',
                company: company._id,
              };
            }),
        );

        const responseMlConfig = await Axios.get('/mercado-livre/configs');
        const mlActiveConfigs = responseMlConfig.data.filter((mlConfig) => mlConfig.active);
        const mlCompaniesIds = mlActiveConfigs.map((mlConfig) => mlConfig.company);

        setMercadoLivreTaxRules(
          companies
            .filter((company) => mlCompaniesIds.includes(company._id))
            .filter((company) => checkValidCpfCnpj(company.cnpj))
            .map((company) => {
              const findMercadoLivreTaxRules = productForm.taxFields?.mercadoLivreTaxRules?.find(
                (mercadoLivreTaxRule) => mercadoLivreTaxRule.company === company._id,
              );

              return {
                _id: findMercadoLivreTaxRules?._id || '',
                taxRuleId: findMercadoLivreTaxRules?.taxRuleId || '',
                company: company._id,
              };
            }),
        );
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar lista impostos',
          error,
        });
      }
    };
    fetchData();
  }, []);

  const handleChangeWebmaniaClasseRef = useCallback(
    (event) => {
      const fieldValue = event.target.value;
      const company = event.target.name;

      setClassesImpostosVenda((oldFields) =>
        oldFields.map((classeImpostosVenda) => {
          if (classeImpostosVenda.company === company) {
            return {
              webmaniaRef: fieldValue,
              company,
            };
          }
          return classeImpostosVenda;
        }),
      );
    },
    [classesImpostosVenda],
  );

  const handleChangeMercadoLivreTaxRules = useCallback(
    (event) => {
      const fieldValue = event.target.value;
      const company = event.target.name;

      setMercadoLivreTaxRules((oldFields) =>
        oldFields.map((mercadoLivreTaxRule) => {
          if (mercadoLivreTaxRule.company === company) {
            return {
              taxRuleId: fieldValue,
              company,
            };
          }
          return mercadoLivreTaxRule;
        }),
      );
    },
    [mercadoLivreTaxRules],
  );

  useEffect(() => {
    setProductForm((oldFields) => ({
      ...oldFields,
      taxFields: {
        ...oldFields.taxFields,
        classesImpostosVenda: classesImpostosVenda.filter(
          (classeImpostosVenda) =>
            classeImpostosVenda.webmaniaRef && classeImpostosVenda.webmaniaRef !== 'Padrão',
        ),
        mercadoLivreTaxRules: mercadoLivreTaxRules.filter(
          (mercadoLivreTaxRule) =>
            mercadoLivreTaxRule.taxRuleId && mercadoLivreTaxRule.taxRuleId !== 'Padrão',
        ),
      },
    }));
  }, [classesImpostosVenda, mercadoLivreTaxRules]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Fiscal</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box sx={{ width: 620 }}>
          <FormControl fullWidth variant="filled" size="small">
            <InputLabel>Origem do produto</InputLabel>
            <Select
              name="origin"
              value={productForm.taxFields.origin}
              onChange={handleChange}
              variant="filled"
            >
              {productOrigins.map((origin) => (
                <MenuItem key={origin.id} value={origin.id}>
                  <Typography variant="inherit">{origin.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="ncm"
              label="NCM"
              value={productForm.taxFields.ncm}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              name="cest"
              label="Cest"
              value={productForm.taxFields.cest}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LinkMaterial
              href="https://portalunico.siscomex.gov.br/classif/#/sumario?perfil=publico"
              target="_blank"
              rel="noreferrer"
            >
              <Typography>Consultar NCM On-line</Typography>
            </LinkMaterial>
          </Grid>
          <Grid item xs={6}>
            <LinkMaterial href="https://www.codigocest.com.br" target="_blank" rel="noreferrer">
              <Typography>Consultar Cest com NCM</Typography>
            </LinkMaterial>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Classes de impostos de venda do Webmania</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {classesImpostosVenda.map((classeImpostosVenda) => {
            const companyConfiguration = companiesConfigurations.find(
              (findCompany) => findCompany._id === classeImpostosVenda.company,
            );

            return (
              <Grid item xs={6} key={classeImpostosVenda.company}>
                <Paper elevation={2} className={classes.classesImpostosPaper}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">{companyConfiguration.name}</Typography>
                    </Grid>
                    <Grid item>
                      <WebmaniaClassesImpostosMenu
                        setSnackbar={setSnackbar}
                        handleOnChange={handleChangeWebmaniaClasseRef}
                        companyId={companyConfiguration._id}
                        selectedWebmaniaClasseImposto={
                          productForm.taxFields?.classesImpostosVenda?.find(
                            (selectedClasseImpostosVenda) =>
                              selectedClasseImpostosVenda.webmaniaRef ===
                              classeImpostosVenda.webmaniaRef,
                          )?.webmaniaRef || 'Padrão'
                        }
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Regras de impostos de venda do Mercado Livre</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {mercadoLivreTaxRules.map((mercadoLivreTaxRule) => {
            const companyConfiguration = companiesConfigurations.find(
              (findCompany) => findCompany._id === mercadoLivreTaxRule.company,
            );

            return (
              <Grid item xs={6} key={mercadoLivreTaxRule.company}>
                <Paper elevation={2} className={classes.classesImpostosPaper}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">{companyConfiguration.name}</Typography>
                    </Grid>
                    <Grid item>
                      <MercadoLivreTaxRulesMenu
                        setSnackbar={setSnackbar}
                        handleOnChange={handleChangeMercadoLivreTaxRules}
                        companyId={companyConfiguration._id}
                        selectedMercadoLivreTaxRule={
                          productForm.taxFields?.mercadoLivreTaxRules?.find(
                            (selectedMercadoLivreTaxRule) =>
                              selectedMercadoLivreTaxRule.taxRuleId ===
                              mercadoLivreTaxRule.taxRuleId,
                          )?.taxRuleId || 'Padrão'
                        }
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(FiscalTab);
