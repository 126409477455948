import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../../../../CustomInput';
import productOrigins from '../../../../../../../constant/productTaxesInformations';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function ImportProductDataTab({ selectedProduct, setSelectedProduct }) {
  const classes = useStyles();

  const handleChangeImportData = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setSelectedProduct((oldFields) => ({
      ...oldFields,
      importData: {
        ...oldFields.importData,
        [fieldName]: fieldValue,
      },
    }));
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setSelectedProduct((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <Paper>
      <Box padding={2}>
        <Box marginBottom={1}>
          <Typography variant="h3">Dados do produto importado</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomInput
                  label="Número da adição"
                  name="addition"
                  value={selectedProduct.importData?.addition || ''}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  onChange={handleChangeImportData}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  label="Sequencial do item"
                  name="seqAddition"
                  value={selectedProduct.importData?.seqAddition || ''}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  onChange={handleChangeImportData}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  label="Valor da AFRMM"
                  name="afrmmValue"
                  value={selectedProduct.importData?.afrmmValue || ''}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  onChange={handleChangeImportData}
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  number
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomInput
                  label="NCM"
                  name="ncm"
                  value={selectedProduct.ncm}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Origem do produto</InputLabel>
                  <Select
                    name="origin"
                    value={selectedProduct.origin}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {productOrigins.map((origin) => (
                      <MenuItem key={origin.id} value={origin.id}>
                        <Typography variant="inherit">{origin.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
export default memo(ImportProductDataTab);
