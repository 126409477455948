import { Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { numberToReal } from '../../../../../helpers/formatData';
import UnblockDialog from './UnblockDialog';
import getTasks from '../../../../../services/melhorGestao/tasks';
import { useAuth } from '../../../../../contexts/auth';

const useStyles = makeStyles((theme) => ({
  redText: {
    color: theme.palette.error.main,
  },
  orderMobile: {
    [theme.breakpoints.down('xs')]: {
      width: 140,
      marginLeft: -280,
    },
  },
}));

function ProfitabilityBlok({ orderForm, setOrderForm }) {
  const classes = useStyles();

  const [normalTotal, setNormalTotal] = useState(0);
  const [normalTotalOffer, setNormalTotalOffer] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [totalFinancialCost, setTotalFinancialCost] = useState(0);

  const [totalCost, setTotalCost] = useState(0);
  const [profitability, setProfitability] = useState(0);

  useEffect(() => {
    let financialCost = 0;

    let normalTotalItems = 0;
    let normalTotalOfferItems = 0;

    let itemsTotalSelected = 0;
    let salesCostTotalItems = 0;

    orderForm.payments.forEach((payment) => {
      if ((!payment.type || payment.type === 'Recebimento') && payment.status === 'Pago') {
        financialCost += payment.financialCost;
      }
    });

    orderForm.items.forEach((item) => {
      const salesCost = item.taxFields?.taxes?.salesCost || 0;

      normalTotalItems += item.price * item.quantity;
      normalTotalOfferItems += item.offerPrice * item.quantity;

      itemsTotalSelected += item.selectedPrice * item.quantity;
      salesCostTotalItems += salesCost * item.quantity;
    });

    setTotalFinancialCost(financialCost);

    setNormalTotal(normalTotalItems);
    setNormalTotalOffer(normalTotalOfferItems);

    setTotalSelected(itemsTotalSelected);
    setTotalCost(salesCostTotalItems);

    setProfitability(itemsTotalSelected - salesCostTotalItems - financialCost);
  }, [orderForm.shipping, orderForm.payments, orderForm.items]);

  const [openDialogUnblock, setOpenDialogUnblock] = useState(false);
  const handleOpenDialogUnblock = () => setOpenDialogUnblock(true);
  const handleCloseDialogUnblock = () => setOpenDialogUnblock(false);

  const loggedUser = useAuth().user;
  const [releaseOrderTask, setReleaseOrderTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const getTask = tasksStaff.find((task) => task.name === 'Liberar pedido');
        if (getTask && getTask.active) {
          setReleaseOrderTask(getTask.active);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography>Preço normal:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(normalTotal)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography>Preço promoção:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(normalTotalOffer)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {!orderForm.unblockDiscount ? (
            <Tooltip
              title={
                <Typography align="center">
                  {releaseOrderTask
                    ? 'Liberar pedido com bloqueio de desconto'
                    : 'Seu usuário não está autorizado a liberar pedidos'}
                </Typography>
              }
            >
              <Grid item style={{ padding: 15 }}>
                <Button
                  className={classes.orderMobile}
                  variant="contained"
                  onClick={handleOpenDialogUnblock}
                  disabled={!releaseOrderTask}
                >
                  Liberar pedido
                </Button>
              </Grid>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          {orderForm.discountTotal && normalTotal ? (
            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                alignItems="baseline"
                className={classes.redText}
              >
                <Grid item>
                  <Typography>Desconto aplicado:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3">
                    R$ {numberToReal(orderForm.discountTotal)} (
                    {Math.round((1 - (1 - orderForm.discountTotal / normalTotal)) * 100)}
                    %)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
              <Grid item>
                <Typography>Preço de venda:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(totalSelected)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {totalCost ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Custo de venda:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.redText}>
                    R$ {numberToReal(totalCost)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {totalFinancialCost ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Taxas:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.redText}>
                    R$ {numberToReal(totalFinancialCost)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {profitability ? (
            <Grid item style={{ padding: 15 }}>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Rentabilidade:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={profitability < 0 ? classes.redText : null}>
                    R$ {numberToReal(profitability)} (
                    {Math.round((1 - (1 - profitability / totalSelected)) * 100)}
                    %)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {openDialogUnblock ? (
        <UnblockDialog
          openDialogUnblock={openDialogUnblock}
          handleCloseDialogUnblock={handleCloseDialogUnblock}
          setOrderForm={setOrderForm}
        />
      ) : null}
    </Grid>
  );
}

export default ProfitabilityBlok;
