const newPayment = {
  _id: '',
  name: '',
  value: 0,
  additionalValue: 0,
  discountValue: 0,
  finalValue: 0,
  valuePaid: 0,
  condition: 'A VISTA',
  category: {
    _id: '',
    categoryId: 0,
    name: '',
    value: 0,
    type: '',
  },
  wallet: {
    _id: '',
    walletId: 0,
    name: '',
    value: 0,
  },
  dueDate: new Date(),
  payDate: '',
  payed: false,
  type: 'Recebimento',
  payer: {
    _id: '',
    userId: 0,
    name: '',
    email: '',
    documents: {
      cpf: '',
      cnpj: '',
    },
  },
  payee: {
    _id: '',
    userId: 0,
    name: '',
    email: '',
    documents: {
      cpf: '',
      cnpj: '',
    },
  },
  paymentMethod: 'Aguardando financeiro',
  description: '',
};

export default newPayment;
