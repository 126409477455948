import { Box, CircularProgress, Grid, Grow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { getGoogleShoppingProductsBySku } from '../../../../../services/melhorGestao/googleShopping';
import GoogleShoppingAdResume from './GoogleShoppingAdResume';
import CustomDivider from '../../../../CustomDivider';

function GoogleShoppingAd({ classes, productForm, refreshSearch, setRefreshSearch }) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingGoogleShoppingAds, setLoadingGoogleShoppingAds] = useState(false);
  const [googleShoppingAds, setGoogleShoppingAds] = useState([]);

  useEffect(() => {
    if (productForm.productId) {
      getGoogleShoppingProductsBySku({
        setGoogleShoppingAds,
        setLoadingGoogleShoppingAds,
        productId: productForm.productId,
      });
    }
  }, [productForm.productId, refreshSearch]);

  if (!googleShoppingAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img
                  className={classes.sourceBadge}
                  src="/assets/imgs/google-shopping-badge.svg"
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Google Shopping
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingGoogleShoppingAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {googleShoppingAds.length > 0 &&
                googleShoppingAds.map((googleShoppingAd) => (
                  <Grid item key={googleShoppingAd.offerId}>
                    <GoogleShoppingAdResume
                      googleShoppingAd={googleShoppingAd}
                      product={productForm}
                      setRefreshSearch={setRefreshSearch}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

export default GoogleShoppingAd;
