import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Slider,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../Common/SimpleDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { handlePublishProducts, updateProduct } from '../../../services/melhorGestao/products';
import CustomDatePicker from '../../CustomDatePicker';
import marksUpTo50 from '../../../constant/marksUpTo50';
import { formatHandleError } from '../../../helpers/formatData';
import OthersMakrteplacesSwitchs from '../../MarketplacesAds/MarketplacesDetails/OthersMarketplacesSwitchs';
import AdResultsDialog from '../../MarketplacesAds/MarketplacesDetails/AdResultsDialog';

function MassProcessProductsDialog({
  openDialogProcessProducts,
  handleCloseDialogProcessProducts,
  checkedProducts,
  handleRefreshAll,
}) {
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [productsList, setProductsList] = useState([]);

  const getUpdatedProductList = useCallback(async () => {
    try {
      setLoadingProcessing(true);

      const response = await Axios.get('/catalog/products/list/id', {
        params: {
          productsIds: checkedProducts,
        },
      });
      const updatedProductList = response.data.productsList;

      setLoadingProcessing(false);
      handleRefreshAll();
      return setProductsList(updatedProductList);
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar obter a lista de produtos',
        error,
      });
    }
  }, [checkedProducts]);

  useEffect(() => {
    getUpdatedProductList();
  }, []);

  const [offerPricePercentage, setOfferPricePercentage] = useState(0);
  const handleChangeOfferPriceSlider = useCallback(
    (event, newValue) => setOfferPricePercentage(newValue),
    [],
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 5)));
  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const [openDialogOffer, setOpenDialogOffer] = useState(false);
  const handleOpenDialogOffer = useCallback(() => setOpenDialogOffer(true), []);
  const handleCloseDialogOffer = useCallback(() => setOpenDialogOffer(false), []);

  const handleCreateOffer = async () => {
    handleCloseDialogOffer();
    setLoadingProcessing(true);

    await productsList.reduce(async (promise, product) => {
      await promise;
      const offers = [...product.offers];

      offers.push({
        name: `Desconto de ${offerPricePercentage}%`,
        description: 'Desconto criado a partir do painel de edição de produtos em massa',
        type: 'Temporário',
        quantity: 1,
        discountPercentage: offerPricePercentage,
        startDate,
        endDate,
      });

      let newOfferPrice = {};
      if (startDate <= new Date() && endDate >= new Date()) {
        const currentOffer = offers.reduce((a, b) =>
          a.discountPercentage > b.discountPercentage ? a : b,
        );

        newOfferPrice = {
          offer: true,
          offerPrice: product.price * (1 - currentOffer.discountPercentage / 100),
        };
      }

      await updateProduct({
        product: {
          ...product,
          ...newOfferPrice,
          offers,
        },
      });
    }, Promise.resolve());

    setLoadingProcessing(false);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (startDate < new Date(new Date().setDate(new Date().getDate() - 1))) {
      return setStartDate(new Date());
    }
    if (startDate > endDate) {
      setErrorMessage('A data de início deve ser menor que a de término');
      return setDisableConfirmButton(true);
    }
    if (startDate > new Date(new Date().setDate(new Date().getDate() + 60))) {
      setErrorMessage('A data de início deve ser dentro de 60 dias');
      return setDisableConfirmButton(true);
    }
    if (endDate > new Date(new Date().setDate(new Date().getDate() + 90))) {
      setErrorMessage('A data de término deve contar no máximo 60 dias a partir do início');
      return setDisableConfirmButton(true);
    }
    if (offerPricePercentage === 0) {
      setErrorMessage('A valor da porcentagem deve ser no mínimo 1%');
      return setDisableConfirmButton(true);
    }
    if (productsList.length === 1) {
      setErrorMessage('Isso será feito no produto selecionado.');
    } else {
      setErrorMessage('Isso será feito nos produtos selecionados.');
    }
    return setDisableConfirmButton(false);
  }, [startDate, endDate, offerPricePercentage]);

  const [openDialogPublish, setOpenDialogPublish] = useState(false);
  const handleOpenDialogPublish = useCallback(() => setOpenDialogPublish(true), []);
  const handleCloseDialogPublish = useCallback(() => setOpenDialogPublish(false), []);

  const [publishOnShopee, setPublishOnShopee] = useState(false);
  const [publishOnSkyhub, setPublishOnSkyhub] = useState(false);
  const [publishOnMagazineLuiza, setPublishOnMagazineLuiza] = useState(false);
  const [publishOnViaVarejo, setPublishOnViaVarejo] = useState(false);
  const [publishOnGoogleShopping, setPublishOnGoogleShopping] = useState(false);

  const [openAdResultsDialog, setOpenAdResultsDialog] = useState(false);
  const handleOpenAdResultsDialog = () => setOpenAdResultsDialog(true);
  const handleCloseAdResultsDialog = () => setOpenAdResultsDialog(false);
  const [adResultsList, setAdResultsList] = useState([]);

  const handleConfirmPublish = async () => {
    handleCloseDialogPublish();

    const results = await handlePublishProducts({
      setLoadingPublishedProducts: setLoadingProcessing,
      productsToPublish: productsList,
      publishOnShopee,
      publishOnSkyhub,
      publishOnMagazineLuiza,
      publishOnViaVarejo,
      publishOnGoogleShopping,
    });

    setAdResultsList(results);
    handleRefreshAll();
    return handleOpenAdResultsDialog();
  };

  const [disableConfirmPublish, setDisableConfirmPublish] = useState(true);

  useEffect(() => {
    if (publishOnShopee) {
      setDisableConfirmPublish(false);
    } else if (publishOnSkyhub) {
      setDisableConfirmPublish(false);
    } else if (publishOnMagazineLuiza) {
      setDisableConfirmPublish(false);
    } else if (publishOnViaVarejo) {
      setDisableConfirmPublish(false);
    } else if (publishOnGoogleShopping) {
      setDisableConfirmPublish(false);
    } else {
      setDisableConfirmPublish(true);
    }
  }, [
    publishOnShopee,
    publishOnSkyhub,
    publishOnMagazineLuiza,
    publishOnViaVarejo,
    publishOnGoogleShopping,
  ]);

  const [openProductsStatusDialog, setOpenProductsStatusDialog] = useState(false);
  const handleOpenProductsStatusDialog = () => setOpenProductsStatusDialog(true);
  const handleCloseProductsStatusDialog = () => setOpenProductsStatusDialog(false);

  const [productStatus, setProductStatus] = useState('active');
  const [productForSale, setProductForSale] = useState(true);
  const [productSiteMarkFerragens, setProductSiteMarkFerragens] = useState(false);

  const [productSkyHub, setProductSkyHub] = useState(false);
  const [productMercadoLivre, setProductMercadoLivre] = useState(false);

  const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false);

  useEffect(() => {
    setProductSkyHub(productSiteMarkFerragens);
    setProductMercadoLivre(productSiteMarkFerragens);
  }, [productStatus, productSiteMarkFerragens]);

  const handleConfirmUpdateProductStatus = async () => {
    handleCloseProductsStatusDialog();
    setLoadingUpdateProduct(true);

    await checkedProducts.reduce(async (promise, productId) => {
      await promise;

      try {
        await updateProduct({
          product: {
            productId,
            status: productStatus,
            'active.forSale': productForSale,
            'active.siteMarkFerragens': productSiteMarkFerragens,
            'active.skyHub': productSkyHub,
            'active.mercadoLivre': productMercadoLivre,
          },
        });
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: `Algum erro ocorreu ao atualizar o produto ${productId}`,
          error,
        });
      }
    }, Promise.resolve());

    setLoadingUpdateProduct(false);
    handleRefreshAll();
  };

  return (
    <>
      <SimpleDialog
        content={
          <>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {productsList.length > 0
                        ? 'Cria uma oferta de desconto para os produtos selecionados'
                        : 'Nenhum produto foi selecionado.'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!(productsList.length > 0)}
                      onClick={handleOpenDialogOffer}
                    >
                      Criar oferta
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {productsList.length > 0
                        ? 'Enviar os produtos que foram selecionados para os Marketplaces'
                        : 'Nenhum produto foi selecionado.'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!(productsList.length > 0)}
                      onClick={handleOpenDialogPublish}
                    >
                      Publicar nos Marketplaces
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {productsList.length > 0
                        ? 'Altera o status dos produtos selecionados'
                        : 'Nenhum produto foi selecionado.'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!(productsList.length > 0)}
                      onClick={handleOpenProductsStatusDialog}
                    >
                      Alterar status dos produtos
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>

            <SimpleBackdrop loading={loadingProcessing} />
            <SimpleBackdrop loading={loadingUpdateProduct} />
          </>
        }
        dialogTitle="Editar produtos selecionados"
        dialogText={
          productsList.length === 1
            ? `Foi selecionado ${productsList.length} produto.`
            : `Foram selecionados ${productsList.length} produtos.`
        }
        handleClose={handleCloseDialogProcessProducts}
        openDialog={openDialogProcessProducts}
      />

      {openDialogOffer && (
        <SimpleDialog
          openDialog={openDialogOffer}
          handleClose={handleCloseDialogOffer}
          dialogTitle="Criar oferta de desconto"
          dialogText="Informe o valor do desconto em porcentagem. Máximo 50%"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogOffer}
          handleConfirmButton={handleCreateOffer}
          disableConfirmButton={disableConfirmButton}
          content={
            <Grid container direction="column">
              <Grid item>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Inicia em</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline" justifyContent="center">
                          <CustomDatePicker
                            size="small"
                            format="DD/MM/YYYY"
                            value={startDate}
                            onChange={handleChangeStartDate}
                            error={startDate > endDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Termina em</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline" justifyContent="center">
                          <CustomDatePicker
                            size="small"
                            format="DD/MM/YYYY"
                            value={endDate}
                            onChange={handleChangeEndDate}
                            error={endDate < startDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Desconto em %</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline" justifyContent="center">
                          {offerPricePercentage === 0 ? (
                            <Typography variant="h6" color="error">
                              {offerPricePercentage}%
                            </Typography>
                          ) : (
                            <Typography variant="h6">{offerPricePercentage}%</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box marginTop={2} padding="0px 14px">
                  <Slider
                    min={0}
                    max={50}
                    step={1}
                    value={offerPricePercentage}
                    onChange={handleChangeOfferPriceSlider}
                    aria-labelledby="input-slider"
                    marks={marksUpTo50}
                  />
                </Box>
              </Grid>
              <Grid item>
                {errorMessage && disableConfirmButton && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {errorMessage && !disableConfirmButton && <Typography>{errorMessage}</Typography>}
              </Grid>
            </Grid>
          }
        />
      )}
      {openDialogPublish && (
        <SimpleDialog
          openDialog={openDialogPublish}
          handleClose={handleCloseDialogPublish}
          dialogTitle="Publicar produtos nos Marketplaces"
          dialogText="Envia os produtos selecionados para os Marketplaces escolhidos a baixo"
          cancelButtonText="Cancelar"
          confirmButtonText="Publicar"
          handleCancelButton={handleCloseDialogPublish}
          handleConfirmButton={handleConfirmPublish}
          disableConfirmButton={disableConfirmPublish}
          content={
            <Grid container direction="column">
              <OthersMakrteplacesSwitchs
                publishOnShopee={publishOnShopee}
                setPublishOnShopee={setPublishOnShopee}
                publishOnSkyhub={publishOnSkyhub}
                setPublishOnSkyhub={setPublishOnSkyhub}
                publishOnMagazineLuiza={publishOnMagazineLuiza}
                setPublishOnMagazineLuiza={setPublishOnMagazineLuiza}
                publishOnViaVarejo={publishOnViaVarejo}
                setPublishOnViaVarejo={setPublishOnViaVarejo}
                publishOnGoogleShopping={publishOnGoogleShopping}
                setPublishOnGoogleShopping={setPublishOnGoogleShopping}
                hiddenDivider
              />
            </Grid>
          }
        />
      )}
      {openAdResultsDialog ? (
        <AdResultsDialog
          openAdResultsDialog={openAdResultsDialog}
          handleCloseAdResultsDialog={handleCloseAdResultsDialog}
          adResultsList={adResultsList}
          productsList={productsList}
        />
      ) : null}
      {openProductsStatusDialog && (
        <SimpleDialog
          openDialog={openProductsStatusDialog}
          handleClose={handleCloseProductsStatusDialog}
          dialogTitle="Alterar status dos produtos"
          dialogText="Altera o status dos produtos selecionados"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseProductsStatusDialog}
          handleConfirmButton={handleConfirmUpdateProductStatus}
          content={
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Ativo na loja</Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="status"
                          value={productStatus === 'active'}
                          onClick={() =>
                            setProductStatus(productStatus === 'active' ? 'unactive' : 'active')
                          }
                          checked={productStatus === 'active'}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Divider />
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Para venda</Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          value={productForSale}
                          onClick={() => {
                            setProductForSale(!productForSale);
                            if (productForSale) {
                              setProductSiteMarkFerragens(false);
                            }
                          }}
                          checked={productForSale}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Divider />
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Ativo no site</Typography>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          value={productSiteMarkFerragens}
                          onClick={() => {
                            setProductSiteMarkFerragens(!productSiteMarkFerragens);
                            if (!productSiteMarkFerragens) {
                              setProductForSale(true);
                            }
                          }}
                          checked={productSiteMarkFerragens}
                          color="primary"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}

export default MassProcessProductsDialog;
