const filterMlAdsSituationsInitial = [
  {
    id: 'showOnlyClosed',
    description: 'Anúncios finalizados',
    active: false,
  },
  {
    id: 'showOnlyUnderReview',
    description: 'Anúncios a revisar',
    active: false,
  },
  {
    id: 'showOnlyNotSynchronized',
    description: 'Não sincronizados',
    active: false,
  },
  {
    id: 'showOnlyPriceNotUpdated',
    description: 'Preços desatualizados',
    active: false,
  },
  {
    id: 'showOnlyStockNotUpdated',
    description: 'Estoques desatualizados',
    active: false,
  },
];

export default filterMlAdsSituationsInitial;
