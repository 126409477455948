import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  submitArea: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: 662,
    height: 70,
  },
  containerSubmitArea: {
    height: 70,
  },
}));

function DrawerSubmitArea({
  handleClose,
  handleConfirm,
  formChanged,
  loadingConfirm,
  buttonConfirmLabel = 'Salvar',
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.submitArea} elevation={5}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.containerSubmitArea}
      >
        <Grid item>
          <Box marginLeft={2}>
            <Button variant="contained" color="default" onClick={handleClose}>
              {formChanged ? 'Cancelar alterações' : 'Fechar'}
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              <Typography align="center">
                {!formChanged ? 'Aguardando alterações' : null}
                {formChanged ? 'Salvar alterações' : null}
              </Typography>
            }
          >
            <Box marginRight={2}>
              <LoadingButton
                loading={loadingConfirm}
                variant="contained"
                color="primary"
                disabled={!formChanged}
                onClick={handleConfirm}
              >
                {buttonConfirmLabel}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(DrawerSubmitArea);
