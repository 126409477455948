import { Grid, IconButton, Link as LinkMaterial } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const useStyles = makeStyles({
  closeIcon: {
    color: '#ddd',
  },
});

function InvoiceInquiryLink({ invoice }) {
  const classes = useStyles();

  let nfeLink;

  if (invoice?.key) {
    nfeLink = `http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConteudo=7PhJ+gAVw2g=&tipoConsulta=completa&nfe=${invoice.key}`;
  }

  return (
    <Grid container alignItems="center">
      {invoice?.key && (invoice.danfe || nfeLink) ? (
        <Grid item>
          <LinkMaterial
            onClick={(event) => event.stopPropagation()}
            href={invoice.danfe || nfeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton size="small">
              <img src="/assets/imgs/nfe-icon.svg" width={20} alt="Icone NFe" />
            </IconButton>
          </LinkMaterial>
        </Grid>
      ) : (
        <Grid item>
          {invoice?.key === '' ? (
            <img src="/assets/imgs/nfe-icon.svg" width={20} alt="Icone NFe" />
          ) : (
            <CloseIcon className={classes.closeIcon} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default InvoiceInquiryLink;
