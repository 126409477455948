import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import React, { useEffect, useState } from 'react';
import thermalPrinterOrders from '../../../../../services/melhorGestao/thermalPrinter';
import SimpleDialog from '../../../../Common/SimpleDialog';
import PrintOrderPdf from './PrintOrderPdf';

function PrintOrderIconButton({
  orderForm,
  formChanged,
  selectedCompany,
  seller,
  devolutions,
  setSnackbar,
}) {
  const [openDialogConfirmPrintType, setOpenDialogConfirmPrintType] = useState(false);
  const handleOpenDialogConfirmPrintType = () => setOpenDialogConfirmPrintType(true);
  const handleCloseDialogConfirmPrintType = () => setOpenDialogConfirmPrintType(false);

  const [openDialogPrintOrder, setOpenDialogPrintOrder] = useState(false);
  const [printCompleteOrder, setPrintCompleteOrder] = useState(false);

  const handleOpenDialogPrintOrder = (trueOrFalse) => {
    setPrintCompleteOrder(trueOrFalse);
    setOpenDialogPrintOrder(true);
  };

  const handleCloseDialogPrintOrder = () => setOpenDialogPrintOrder(false);

  const [blockPrint, setBlockPrint] = useState(true);
  const [blockPrintMessage, setBlockPrintMessage] = useState('');

  useEffect(() => {
    if (!orderForm.company) {
      setBlockPrintMessage('Escolha a empresa que fará a venda');
      return setBlockPrint(true);
    }
    if (!orderForm.source) {
      setBlockPrintMessage('Escolha a origem do pedido');
      return setBlockPrint(true);
    }
    if (!orderForm.items || orderForm.items.length === 0) {
      setBlockPrintMessage('Escolha os produtos');
      return setBlockPrint(true);
    }
    if (!orderForm.customer || !orderForm.customer.name) {
      setBlockPrintMessage('Escolha o cliente');
      return setBlockPrint(true);
    }
    if (!orderForm.shipping.method || !orderForm.shipping.company) {
      setBlockPrintMessage('Escolha uma forma de entrega');
      return setBlockPrint(true);
    }
    setBlockPrintMessage('Imprimir pedido');
    return setBlockPrint(false);
  }, [orderForm, formChanged]);

  return (
    <Tooltip title={<Typography align="center">{blockPrintMessage}</Typography>}>
      <Box>
        <IconButton onClick={handleOpenDialogConfirmPrintType} disabled={blockPrint}>
          <PrintIcon />
        </IconButton>
        {openDialogConfirmPrintType ? (
          <SimpleDialog
            openDialog={openDialogConfirmPrintType}
            handleClose={handleCloseDialogConfirmPrintType}
            dialogTitle="Impressão"
            content={
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleCloseDialogConfirmPrintType();
                      thermalPrinterOrders({
                        setSnackbar,
                        ordersToPrint: [orderForm],
                        withPrice: true,
                      });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Térmica
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <Typography align="center">
                        Imprime pedido com valor e itens atualizados (sem estornos/devoluções)
                      </Typography>
                    }
                  >
                    <Button
                      onClick={() => {
                        handleCloseDialogConfirmPrintType();
                        handleOpenDialogPrintOrder(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      PDF Resumido
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={
                      <Typography align="center">
                        Imprime todos os dados do pedido, com ou sem devoluções
                      </Typography>
                    }
                  >
                    <Button
                      onClick={() => {
                        handleCloseDialogConfirmPrintType();
                        handleOpenDialogPrintOrder(true);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      PDF Completo
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          />
        ) : null}
        {openDialogPrintOrder ? (
          <SimpleDialog
            openDialog={openDialogPrintOrder}
            handleClose={handleCloseDialogPrintOrder}
            dialogTitle="Imprimir pedido"
            content={
              <PrintOrderPdf
                selectedCompany={selectedCompany}
                seller={seller}
                order={orderForm}
                devolutions={devolutions}
                printCompleteOrder={printCompleteOrder}
              />
            }
          />
        ) : null}
      </Box>
    </Tooltip>
  );
}

export default PrintOrderIconButton;
