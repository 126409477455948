import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Link as LinkMaterial,
  Typography,
  IconButton,
  Paper,
  Snackbar,
  Alert,
  Skeleton,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../../Common/SimpleDialog';
import SimpleImage from '../../../../Common/SimpleImage';
import SearchField from '../../../../Common/SearchField';
import LimitTypography from '../../../../Common/LimitTypography';
import { formatHandleError } from '../../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  productPaper: {
    height: 110,
    padding: 5,
    backgroundColor: theme.palette.primary.primary,
  },
}));

function DialogMercadoLivreCatalogSearch({
  product,
  openMercadoLivreCatalogSearch,
  handleCloseMercadoLivreCatalogSearch,
  mercadoLivreCatalogProductId,
  setMercadoLivreCatalogProductId,
  handleConfirmMercadoLivreCatalogSearch,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loadingMercadoLivreCatalogSearch, setLoadingMercadoLivreCatalogSearch] = useState(false);
  const [mercadoLivreCatalogProductList, setMercadoLivreCatalogProductList] = useState([]);

  const searchMercadoLivreCatalogProducts = async (searchText) => {
    try {
      if (searchText) {
        setMercadoLivreCatalogProductList([]);
        setLoadingMercadoLivreCatalogSearch(true);
        const response = await Axios.get('/mercado-livre/products/catalog', {
          params: {
            searchText,
          },
        });

        const { mercadoLivreCatalogProducts } = response.data;
        setMercadoLivreCatalogProductList(mercadoLivreCatalogProducts);
        setLoadingMercadoLivreCatalogSearch(false);
      }
    } catch (error) {
      setLoadingMercadoLivreCatalogSearch(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar produtos no Mercado Livre',
        error,
      });
    }
  };

  useEffect(() => {
    if (product.othersIds?.gtin) {
      searchMercadoLivreCatalogProducts(product.othersIds.gtin);
    } else {
      searchMercadoLivreCatalogProducts(product.name);
    }
  }, []);

  return (
    <SimpleDialog
      openDialog={openMercadoLivreCatalogSearch}
      handleClose={handleCloseMercadoLivreCatalogSearch}
      dialogTitle="Catálogo do Mercado Livre"
      dialogText="Selecione no catálogo o produto relacionado ao que está sendo anúnciado"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseMercadoLivreCatalogSearch}
      handleConfirmButton={handleConfirmMercadoLivreCatalogSearch}
      disableConfirmButton={!mercadoLivreCatalogProductId}
      actionButton={
        <SearchField
          submitSearch={searchMercadoLivreCatalogProducts}
          labelSearch="Pesquisar no catálogo"
          preSearchText={product.othersIds.gtin ? product.othersIds.gtin : product.name}
        />
      }
      content={
        <Box width="900px">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Paper elevation={2} className={classes.productPaper}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>Produto que está sendo anúnciado:</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box maxWidth="75px" maxHeight="75px">
                          {product.images.length > 0 ? (
                            <SimpleImage
                              src={product.images[0].src}
                              height={75}
                              width={75}
                              alt={product.name}
                            />
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <LimitTypography
                              style={{ color: 'grey' }}
                              text={product.name}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <LimitTypography
                              text={product.technicalName}
                              maxLength={60}
                              variant="caption"
                            />
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: 'grey' }} variant="caption">
                              Localização: {product.localization} - SKU: {product.productId} -
                              Marca: {product.brand}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Paper elevation={4}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Imagem</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Nome</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mercadoLivreCatalogProductList.length > 0
                      ? mercadoLivreCatalogProductList.map((item) => (
                          <TableRow
                            key={item.id}
                            onClick={() => setMercadoLivreCatalogProductId(item.id)}
                            className={
                              mercadoLivreCatalogProductId === item.id
                                ? classes.tableRowSelected
                                : classes.tableRow
                            }
                          >
                            <TableCell>
                              <Tooltip title={item.id}>
                                <LinkMaterial
                                  href={`https://www.mercadolivre.com.br/p/${item.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <IconButton size="small" className={classes.smallIcon}>
                                    <LinkIcon />
                                  </IconButton>
                                </LinkMaterial>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Box maxWidth="75px" maxHeight="75px">
                                {item.pictures.length > 0 ? (
                                  <SimpleImage
                                    src={item.pictures[0].url}
                                    imgHeight={75}
                                    imgWidth={75}
                                    alt={item.name}
                                  />
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell>{item.status === 'active' ? 'Ativo' : 'Inativo'}</TableCell>
                            <TableCell>
                              <Grid container direction="column">
                                <Grid item>
                                  <Typography variant="caption">{item.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography style={{ color: 'grey' }} variant="caption">
                                    {item.attributes
                                      .filter(
                                        (attribute) =>
                                          attribute.id === 'BRAND' ||
                                          attribute.id === 'MODEL' ||
                                          attribute.id === 'UNITS_PER_PACKAGE',
                                      )
                                      .map(
                                        (attribute) => `${attribute.name}: ${attribute.value_name}`,
                                      )
                                      .join(' - ')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}

                    {loadingMercadoLivreCatalogSearch
                      ? [1, 2, 3].map((item) => (
                          <TableRow key={item}>
                            <TableCell>
                              <Skeleton variant="rectangular" width={40} height={12} />
                            </TableCell>
                            <TableCell>
                              <Box maxWidth="75px" maxHeight="75px">
                                <Skeleton variant="rectangular" width={75} height={75} />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="rectangular" width={40} height={12} />
                            </TableCell>
                            <TableCell>
                              <Grid container direction="column">
                                <Grid item>
                                  <Skeleton variant="rectangular" width={400} height={12} />
                                </Grid>
                                <Grid item>
                                  <Skeleton variant="rectangular" width={300} height={12} />
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}

                    {!loadingMercadoLivreCatalogSearch &&
                    mercadoLivreCatalogProductList.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Grid container justifyContent="center" alignItems="center">
                            <Grid item>
                              <Typography>Produto não encontrado no catálogo</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Box>
      }
    />
  );
}

export default DialogMercadoLivreCatalogSearch;
