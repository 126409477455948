import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import simulateMongoObjectId from '../../../../../helpers/simulateMongoObjectId';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';

function DialogAttributes({
  openDialogAttributes,
  handleCloseDialogAttributes,
  productForm,
  setProductForm,
}) {
  const [attributesFieldsFromProduct, setAttributesFieldsFromProduct] = useState(
    productForm.attributes || [],
  );

  const [fieldName, setFieldName] = useState('');
  const [fieldValue, setFieldValue] = useState('');

  const handleChangeFieldName = (event) => {
    setFieldName(event.target.value);
  };

  const handleChangeFieldValue = (event) => {
    setFieldValue(event.target.value);
  };

  const handleAddAttributeFieldFromProduct = () => {
    setAttributesFieldsFromProduct((oldFields) => [
      ...oldFields,
      { _id: simulateMongoObjectId(), name: fieldName, value: fieldValue },
    ]);

    setFieldName('');
    setFieldValue('');
  };

  const handleDeleteAttributeFieldFromProduct = (indexToDelete) => {
    const updatedAttributesFields = [];

    attributesFieldsFromProduct.forEach((attributeField, index) => {
      if (indexToDelete !== index) {
        updatedAttributesFields.push(attributeField);
      }
    });

    setAttributesFieldsFromProduct(updatedAttributesFields);
  };

  const handleChangeAttributeName = (event) => {
    const updatedAttributes = attributesFieldsFromProduct;

    updatedAttributes[event.target.name] = {
      ...updatedAttributes[event.target.name],
      name: event.target.value,
    };

    setAttributesFieldsFromProduct([...updatedAttributes]);
  };

  const handleChangeAttributeValue = (event) => {
    const updatedAttributes = attributesFieldsFromProduct;

    updatedAttributes[event.target.name] = {
      ...updatedAttributes[event.target.name],
      value: event.target.value,
    };

    setAttributesFieldsFromProduct([...updatedAttributes]);
  };

  const handleComfirmAttributes = () => {
    setProductForm((oldFields) => ({
      ...oldFields,
      attributes: attributesFieldsFromProduct,
    }));

    handleCloseDialogAttributes();
  };

  return (
    <SimpleDialog
      openDialog={openDialogAttributes}
      handleClose={handleCloseDialogAttributes}
      dialogTitle="Características técnicas"
      dialogText="Adicione ou edite os campos e as características técnicas do produto"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogAttributes}
      handleConfirmButton={handleComfirmAttributes}
      content={
        <Grid container direction="column" spacing={2}>
          {attributesFieldsFromProduct.map((attribute, index) => (
            <Grid item key={attribute._id}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <CustomInput
                    value={attribute.name}
                    onChange={handleChangeAttributeName}
                    name={String(index)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <CustomInput
                    value={attribute.value}
                    onChange={handleChangeAttributeValue}
                    name={String(index)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleDeleteAttributeFieldFromProduct(index)}
                    variant="contained"
                    color="default"
                  >
                    Remover
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <CustomInput
                  value={fieldName}
                  onChange={handleChangeFieldName}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <CustomInput
                  value={fieldValue}
                  onChange={handleChangeFieldValue}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={handleAddAttributeFieldFromProduct}
                  variant="contained"
                  color="primary"
                >
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}

export default DialogAttributes;
