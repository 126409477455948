import Axios from 'axios';
import React, { useState } from 'react';
import { Alert, Box, Grid, Snackbar } from '@mui/material';
import dayjs from 'dayjs';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomDatePicker from '../../CustomDatePicker';
import { formatHandleError } from '../../../helpers/formatData';

function ReportInvoiceDialog({
  openReportInvoiceDialog,
  handleCloseReportInvoiceDialog,
  filterModelOptions,
  filterSerieOptions,
  filterOperationOptions,
  filterCompanyOptions,
  filterUserOptions,
  defaultStartDate,
  defaultEndDate,
}) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [loading, setLoading] = useState(false);

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleConfirmButton = async () => {
    try {
      setLoading(true);

      const response = await Axios.get('/invoices/report', {
        params: {
          filterModelOptions,
          filterSerieOptions,
          filterOperationOptions,
          filterCompanyOptions,
          filterUserOptions,
          startDate: dayjs(startDate).startOf('day'),
          endDate: dayjs(endDate).endOf('day'),
        },
      });

      const { url } = response.data;
      window.open(url);

      setLoading(false);
      handleCloseReportInvoiceDialog();
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao baixar relatório das Notas Fiscais',
        error,
      });
    }
  };

  return (
    <SimpleDialog
      openDialog={openReportInvoiceDialog}
      handleClose={handleCloseReportInvoiceDialog}
      dialogTitle="Relatório"
      dialogText="Clique em comfirmar para baixar o relatório em CSV"
      content={
        <Box>
          <Grid container spacing={1}>
            <Grid item>
              <Box width={200}>
                <CustomDatePicker
                  size="small"
                  label="Data inicial"
                  format="DD/MM/YYYY"
                  value={startDate}
                  onChange={handleChangeStartDate}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={200}>
                <CustomDatePicker
                  size="small"
                  label="Data final"
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={handleChangeEndDate}
                />
              </Box>
            </Grid>
          </Grid>
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      confirmButtonLoading={loading}
      handleConfirmButton={handleConfirmButton}
      handleCancelButton={handleCloseReportInvoiceDialog}
    />
  );
}

export default ReportInvoiceDialog;
