import React, { memo } from 'react';
import { Typography } from '@mui/material';
import orderStatusColor from '../../../../constant/orderStatusColor';
import { skipFirstWord } from '../../../../helpers/formatData';

function OrderStatusTypography({ orderStatus, onlyStatusName }) {
  let status = orderStatus;

  if (onlyStatusName) {
    status = skipFirstWord(orderStatus);
  }

  return <Typography color={orderStatusColor(orderStatus)}>{status}</Typography>;
}

export default memo(OrderStatusTypography);
