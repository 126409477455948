const newShopeeAd = {
  shopeeProductId: 0,
  company: '',
  name: '',
  category: {
    id: 0,
    name: '',
  },
  brand: {
    id: 0,
    name: '',
  },
  videoLink: '',
  description: '',
  price: 0,
  offerPrice: false,
  quantity: 1,
  companies: [],
};

export default newShopeeAd;
