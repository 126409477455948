const filterPaymentSituationsInitial = [
  {
    id: 'showOnlyPaid',
    description: 'Pagos',
    active: false,
  },
  {
    id: 'showOnlyNotPaid',
    description: 'Não pagos',
    active: false,
  },
  {
    id: 'showOnlyToOverdue',
    description: 'A vencer',
    active: false,
  },
  {
    id: 'showOnlyOverdue',
    description: 'Vencidos',
    active: false,
  },
];

export default filterPaymentSituationsInitial;
