import { Alert, Box, Grid, MenuItem, MenuList, Paper, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import TableErrors from './Table';
import { formatHandleError } from '../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function IntegrationsErrors() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [integrationName, setIntegrationName] = useState('');
  const [integrationPlatforms, setIntegrationPlatforms] = useState([]);
  const [integrationsErrors, setIntegrationsErrors] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);

  const handleRefresh = () => {
    setPage(1);
    setRefreshToken(Math.random());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePlatforms = await Axios.get('/integrations-errors/platforms');
        setIntegrationPlatforms(responsePlatforms.data);

        if (responsePlatforms.data.length > 0) {
          const platform = integrationName || responsePlatforms.data[0];
          setIntegrationName(platform);

          const responseErrors = await Axios.get('/integrations-errors', {
            params: {
              platform,
              skip: page * limit - limit,
              limit,
            },
          });

          setIntegrationsErrors(responseErrors.data.integrationsErrors);
          setTotalResults(responseErrors.data.totalResults);
        } else {
          setIntegrationName('');
          setIntegrationsErrors([]);
          setTotalResults(0);
        }
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar lista de plataformas',
          error,
        });
      }
    };
    fetchData();
  }, [page, limit, refreshToken, integrationName]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, integrationsErrors],
  );

  useEffect(() => {
    setPage(1);
  }, [integrationName]);

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">Erros de integração</Typography>
            </Box>

            {integrationPlatforms.length > 0 ? (
              <MenuList>
                {integrationPlatforms.map((platform) => (
                  <MenuItem
                    key={platform}
                    onClick={() => setIntegrationName(platform)}
                    className={integrationName === platform ? classes.menuItemSelected : null}
                  >
                    {platform}
                  </MenuItem>
                ))}
              </MenuList>
            ) : (
              <MenuList>
                <MenuItem>Nenhuma plataforma encontrada</MenuItem>
              </MenuList>
            )}
          </Paper>
        </Grid>

        <Grid item sm={8}>
          <Paper>
            <Box padding={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{integrationName || 'Plataforma'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3">
                    {integrationsErrors.length} de {totalResults}
                  </Typography>
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <TableErrors
                  integrationsErrors={integrationsErrors}
                  handleChangePage={handleChangePage}
                  page={page}
                  limit={limit}
                  countIntegrationsErrors={totalResults}
                  handleRefresh={handleRefresh}
                  setSnackbar={setSnackbar}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default IntegrationsErrors;
