import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useState } from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import UserAutocomplete from '../../../Common/UserAutocomplete';

const useStyles = makeStyles({
  select: {
    minWidth: 190,
  },
  input: {
    fontSize: 25,
  },
  inputAdornment: {
    marginBottom: 0,
  },
});

function ShippingTab({ shipping, handleChangeShipping }) {
  const classes = useStyles();

  const [shippingFields, setShippingFields] = useState({
    value: shipping.value || 0,
    insurance: shipping.insurance || 0,
    volume: shipping.volume || 0,
    grossWeight: shipping.grossWeight || 0,
    netWeight: shipping.netWeight || 0,
    payer: shipping.payer,
    company: shipping.company?.cnpj,
  });

  const [openDialogSelectShipping, setOpenDialogSelectShipping] = useState(false);
  const handleOpenDialogSelectShipping = useCallback(() => setOpenDialogSelectShipping(true), []);
  const handleCloseDialogSelectShipping = useCallback(() => setOpenDialogSelectShipping(false), []);

  const handleConfirmSelectShipping = useCallback(() => {
    if (shippingFields.payer) {
      handleChangeShipping(shippingFields);
      handleCloseDialogSelectShipping();
    }
  }, [shippingFields]);

  const handleChangePayer = (event) => {
    if (event.target.value === 'Emitente (CIF)') {
      setShippingFields((oldFields) => ({
        ...oldFields,
        value: 0,
        payer: event.target.value,
      }));
    } else {
      setShippingFields((oldFields) => ({
        ...oldFields,
        payer: event.target.value,
      }));
    }
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setShippingFields((oldFields) => ({
      ...oldFields,
      [fieldName]: parseFloat(fieldValue) || 0,
    }));
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Entrega</Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOpenDialogSelectShipping}>
              Escolher Entrega
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {shipping.payer ? (
        <Grid item xs={12}>
          <Box marginTop={1}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Grid container direction="column">
                  <Typography variant="caption">Frete por conta do</Typography>
                  <Typography>{shipping.payer}</Typography>
                </Grid>
              </Grid>
              {shipping.value ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Valor do frete</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(shipping.value)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {shipping.insurance ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Valor do seguro</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(shipping.insurance)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {shipping.volume ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Volumes</Typography>
                    <Typography>{shipping.volume}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {shipping.grossWeight ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Peso bruto</Typography>
                    <Typography>{`${shipping.grossWeight}`.replace(/\./g, ',')} Kg</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {shipping.netWeight ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Peso líquido</Typography>
                    <Typography>{`${shipping.netWeight}`.replace(/\./g, ',')} Kg</Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
      ) : null}

      {openDialogSelectShipping ? (
        <SimpleDialog
          openDialog={openDialogSelectShipping}
          handleClose={handleCloseDialogSelectShipping}
          maxWidth="sm"
          confirmButtonText="Confirmar"
          handleConfirmButton={handleConfirmSelectShipping}
          handleCancelButton={handleCloseDialogSelectShipping}
          cancelButtonText="Cancelar"
          dialogText="Caso o frete seja por conta do destinatário ele será incluído no custo dos produtos. Por isto se você alterar esta informação você terá que confirmar os preços dos produtos novamente."
          disableConfirmButton={
            shippingFields.value <= 0 && shippingFields.payer !== 'Emitente (CIF)'
          }
          content={
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <FormControl fullWidth variant="filled" size="small">
                      <InputLabel>Tipo</InputLabel>
                      <Select
                        fullWidth
                        className={classes.select}
                        onChange={handleChangePayer}
                        value={shippingFields.payer || ''}
                        error={!shippingFields.payer}
                      >
                        <MenuItem value="Emitente (CIF)">Emitente (CIF)</MenuItem>
                        <MenuItem value="Destinatário (FOB)">Destinatário (FOB)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <CustomInput
                      name="value"
                      label="Valor do frete"
                      value={shippingFields.value}
                      onChange={handleChange}
                      error={shippingFields.value <= 0 && shippingFields.payer !== 'Emitente (CIF)'}
                      disabled={shippingFields.payer === 'Emitente (CIF)'}
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      size="medium"
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <UserAutocomplete
                      handleOnChange={(event, newValue) =>
                        setShippingFields((oldFields) => ({
                          ...oldFields,
                          company: newValue,
                        }))
                      }
                      selectedUser={shippingFields.company}
                      setSelectedUser={(user) =>
                        setShippingFields((oldFields) => ({
                          ...oldFields,
                          company: user,
                        }))
                      }
                      label="Transportadora"
                      roles={['shippingCompany']}
                      variant="filled"
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CustomInput
                      name="insurance"
                      label="Valor do seguro"
                      value={shippingFields.insurance}
                      onChange={handleChange}
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      size="medium"
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CustomInput
                      name="volume"
                      label="Volumes"
                      value={shippingFields.volume}
                      onChange={handleChange}
                      InputAdornmentIcon={Inventory2OutlinedIcon}
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      name="grossWeight"
                      label="Peso bruto (kg)"
                      value={shippingFields.grossWeight}
                      onChange={handleChange}
                      InputAdornmentIcon={FitnessCenterIcon}
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      name="netWeight"
                      label="Peso líquido (kg)"
                      value={shippingFields.netWeight}
                      onChange={handleChange}
                      InputAdornmentIcon={FitnessCenterIcon}
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          dialogTitle="Selecione o valor do frete"
        />
      ) : null}
    </Grid>
  );
}

export default memo(ShippingTab);
