const filterProductStatusInitial = [
  {
    id: 'showOnlyActive',
    description: 'Ativos na loja',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlyUnactive',
    description: 'Inativos na loja',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlySiteActive',
    description: 'Ativos no site',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlySiteUnactive',
    description: 'Inativos no site',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlyForSaleYes',
    description: 'Para venda sim',
    active: true,
    default: 'checked',
  },
  {
    id: 'showOnlyForSaleNo',
    description: 'Para venda não',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'showOnlyNormalProducts',
    description: 'Produtos normais',
    active: true,
    default: 'checked',
  },
  {
    id: 'showOnlyComposition',
    description: 'Produtos compostos',
    active: false,
    default: 'unchecked',
  },
];

export default filterProductStatusInitial;
