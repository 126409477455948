import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link as LinkMaterial,
  Paper,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LinkIcon from '@mui/icons-material/Link';
import { numberToReal } from '../../../../../../helpers/formatData';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import {
  deleteViaVarejoProduct,
  getViaVarejoPrice,
} from '../../../../../../services/melhorGestao/viaVarejo';
import SimpleImage from '../../../../../Common/SimpleImage';
import LimitTypography from '../../../../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  thumbnail: {
    width: '100%',
  },
  boxPositionRelative: {
    position: 'relative',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxDeleteIcon: {
    position: 'absolute',
    bottom: 155,
    right: 430,
  },
  boxEditIcon: {
    position: 'absolute',
    bottom: 155,
    right: 0,
  },
  smallIcon: {
    marginRight: 10,
  },
  sellerLogoGridMark: {
    height: 48,
    borderRadius: 8,
    backgroundColor: '#000',
  },
  sellerLogoImgMark: {
    width: 160,
  },
  gridHeight: {
    height: 48,
  },
  gridPrice: {
    height: 48,
    width: 75,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 136,
    paddingBottom: '0px!important',
  },
}));
function ViaVarejoAdResume({
  viaVarejoAd,
  product,
  setProductForm,
  setFormChanged,
  setRefreshSearch,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const [loadingViaVarejoAdStatus] = useState(false);
  const [openDialogViaVarejoDeleteProduct, setOpenDialogViaVarejoDeleteProduct] = useState(false);

  const viaVarejoProductId = viaVarejoAd.skus?.[0]?.idSkuLojista || viaVarejoAd.sku.idSkuLojista;

  const viaVarejoStock = viaVarejoAd.skus?.[0]?.estoque
    ? viaVarejoAd.skus[0].estoque.quantidade
    : viaVarejoAd.sku.estoques[0].quantidade;

  const viaVarejoPrice = viaVarejoAd.skus?.[0]?.preco
    ? viaVarejoAd.skus[0].preco.padrao
    : viaVarejoAd.sku.precos[0].de;

  const viaVarejoOfferPrice = viaVarejoAd.skus?.[0]?.preco
    ? viaVarejoAd.skus[0].preco.oferta
    : viaVarejoAd.sku.precos[0].por;

  const viaVarejoStatus = viaVarejoAd.sku?.status?.[0]?.ativo === 'Y';

  const handleCloseSnackbar = () =>
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });

  const handleDeleteViaVarejoProduct = async () => {
    const company = 'Mark Ferragens';
    const request = await deleteViaVarejoProduct(company, viaVarejoProductId);

    setOpenDialogViaVarejoDeleteProduct(false);
    setRefreshSearch(Math.random());

    if (request.response?.data) {
      const response = request.response.data;

      return setSnackbar({
        message:
          response.data?.error ||
          response.responseMessage ||
          'Algum erro ocorreu ao tentar excluir o anúncio',
        open: true,
        type: 'error',
      });
    }

    return setSnackbar({
      message: 'Anúncio excluido com sucesso',
      open: true,
      type: 'success',
    });
  };

  const [viaVarejoAdPrice, setViaVarejoAdPrice] = useState(viaVarejoPrice);
  const [viaVarejoOfferAdPrice, setViaVarejoOfferAdPrice] = useState(viaVarejoOfferPrice);

  const [offerPrice, setOfferPrice] = useState(false);
  const [offerChanged, setOfferChanged] = useState(false);

  const handleChangePriceBase = () => {
    let index;
    const updatedProduct = product;

    updatedProduct.marketplaces.forEach((marketplace, indexInside) => {
      if (
        marketplace.marketplace === 'Via Varejo' &&
        parseInt(marketplace.marketplaceOwnId, 10) === updatedProduct.productId
      ) {
        index = indexInside;
      }
    });

    if (index === undefined) {
      return false;
    }

    if (offerChanged) {
      setFormChanged(false);
    } else {
      setFormChanged(true);
    }

    setOfferChanged(!offerChanged);
    setOfferPrice(!offerPrice);

    updatedProduct.marketplaces[index].offerPrice = !offerPrice;
    const productInOffer = product.offer
      ? updatedProduct.marketplaces[index].offerPrice || false
      : false;

    const magazineLuizaPrice = getViaVarejoPrice(
      product,
      updatedProduct.marketplaces[index].quantity,
      productInOffer,
    );

    setViaVarejoAdPrice(parseFloat(magazineLuizaPrice.newProductPrice));
    setViaVarejoOfferAdPrice(parseFloat(magazineLuizaPrice.newPromotionalPrice));
    return setProductForm(updatedProduct);
  };

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    product.marketplaces.forEach((marketplace) => {
      if (
        marketplace.marketplace === 'Via Varejo' &&
        parseInt(marketplace.marketplaceOwnId, 10) === product.productId
      ) {
        setOfferPrice(marketplace.offerPrice === undefined ? false : marketplace.offerPrice);
      }
    });

    const getQuantity = product.marketplaces?.find(
      (marketplace) => marketplace.marketplace === 'Via Varejo' && marketplace.marketplaceOwnId,
    )?.quantity;

    setQuantity(getQuantity || 0);
  }, [product.marketplaces]);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">Título do anúncio</Typography>
            </Grid>
            <Grid item>
              <LimitTypography text={viaVarejoAd.titulo} maxLength={55} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="caption">Vendedor</Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.sellerLogoGridMark}
                      >
                        <img
                          className={classes.sellerLogoImgMark}
                          src="/assets/imgs/logo-mark-ferragens-white.svg"
                          alt=""
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço de </Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>{numberToReal(viaVarejoAdPrice)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço por</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>{numberToReal(viaVarejoOfferAdPrice)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={viaVarejoStatus ? 'primary' : 'disabled'}
                                />

                                <Typography variant="caption" color="textPrimary">
                                  {viaVarejoStatus ? 'Ativo' : 'Pausado'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      {loadingViaVarejoAdStatus && (
                        <CircularProgress variant="indeterminate" size={26} />
                      )}
                    </Grid>

                    <Grid item>
                      <Chip label={viaVarejoProductId} />
                    </Grid>

                    <Grid item>
                      <Chip label={`Estoque: ${viaVarejoStock}`} />
                    </Grid>

                    {quantity ? (
                      <Grid item>
                        <Chip label={`Kit com: ${quantity}`} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <SimpleImage
                src={
                  viaVarejoAd.skus?.[0]?.imagens?.[0]
                    ? viaVarejoAd.skus[0].imagens[0]
                    : viaVarejoAd.sku.imagens[0]
                }
                height={100}
                width={100}
                alt={viaVarejoAd.titulo}
                className={classes.thumbnail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        <Box className={classes.boxDeleteIcon}>
          <Tooltip
            title={
              <Typography align="center">
                Excluir permanentemente o anúncio do Via Varejo e demais sites do grupo
              </Typography>
            }
          >
            <IconButton size="small" onClick={() => setOpenDialogViaVarejoDeleteProduct(true)}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className={classes.boxEditIcon}>
          <Tooltip
            title={
              <Typography align="center">Preço do produto a ser considerado mais taxas</Typography>
            }
          >
            <FormControlLabel
              control={
                <Switch
                  value={offerPrice}
                  onChange={handleChangePriceBase}
                  checked={offerPrice}
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  {offerPrice ? 'Preço de oferta' : 'Preço normal'}
                </Box>
              }
            />
          </Tooltip>

          {viaVarejoAd.sku?.idSkuSite ? (
            <Tooltip title={<Typography align="center">Link do produto no Via Varejo</Typography>}>
              <LinkMaterial
                href={`https://produto.extra.com.br/${viaVarejoAd.sku.idSkuSite}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton size="small" className={classes.smallIcon}>
                  <LinkIcon />
                </IconButton>
              </LinkMaterial>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <Typography align="center">
                  O produto ainda não foi publicado, verifique no painel do Via Varejo
                </Typography>
              }
            >
              <IconButton size="small" className={classes.smallIcon}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {openDialogViaVarejoDeleteProduct && (
        <SimpleDialog
          cancelButtonText="Cancelar"
          content={
            <Grid container>
              <Grid item>
                <Typography align="center">
                  Você está prestes a excluir permanentemente um anúncio
                </Typography>
                <Typography align="center">
                  da Via Varejo, esta ação não poderá ser desfeita.
                </Typography>
              </Grid>
            </Grid>
          }
          confirmButtonText="Excluir"
          dialogTitle="Excluir anúncio do Via Varejo"
          handleCancelButton={() => setOpenDialogViaVarejoDeleteProduct(false)}
          handleClose={() => setOpenDialogViaVarejoDeleteProduct(false)}
          handleConfirmButton={handleDeleteViaVarejoProduct}
          openDialog={openDialogViaVarejoDeleteProduct}
        />
      )}
    </Paper>
  );
}

export default ViaVarejoAdResume;
