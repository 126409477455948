import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomDatePicker from '../../../../CustomDatePicker';
import CustomInput from '../../../../CustomInput';
import marksUpTo50 from '../../../../../constant/marksUpTo50';
import marksUpTo100 from '../../../../../constant/marksUpTo100';
import FilterSelectOptions from '../../../../Common/FilterSelectOptions';
import { formatHandleError } from '../../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  grids: {
    marginBottom: 5,
  },
  switch: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function CouponDialog({
  openDialogDiscountCoupon,
  handleOpenDialogDiscountCoupon,
  handleCloseDialogDiscountCoupon,
  fieldsDiscountCoupon,
  setFieldsDiscountCoupon,
  handleRefreshDiscountCoupons,
  setSnackbar,
}) {
  const classes = useStyles();

  const handleChangeCouponType = (_, newValue) => {
    if (newValue?.slug) {
      setFieldsDiscountCoupon((oldFields) => ({
        ...oldFields,
        discountType: newValue.slug,
      }));
    } else {
      setFieldsDiscountCoupon((oldFields) => ({
        ...oldFields,
        discountType: '',
      }));
    }
  };
  const handleChangeMinimumValueToGetDiscount = (event) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      minimumValueToGetDiscount: event.target.value,
    }));
  };

  const handleChangeCouponStatus = () =>
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      active: !fieldsDiscountCoupon.active,
    }));

  const handleChangeCouponCode = (event) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      code: event.target.value,
    }));
  };

  const handleChangeCouponLimit = (event) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      limitCouponUse: event.target.value,
    }));
  };

  const handleChangeCouponLimitPerUser = (event) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      limitPerUser: event.target.value,
    }));
  };

  const handleChangeStartDate = (date) =>
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      startDate: date,
    }));

  const handleChangeEndDate = (date) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      endDate: date,
    }));
  };

  const handleChangeCouponPercentageSlider = useCallback((_, newValue) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      discountPercentage: newValue,
    }));
  }, []);

  const handleChangeSourceCoupon = (sourceCoupon) => {
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      sourceCoupon,
    }));
  };

  const [isUnlimited, setIsUnlimited] = useState(fieldsDiscountCoupon.limitCouponUse === 0);

  const handleDefineUnlimitedCoupon = () => {
    if (isUnlimited) {
      setFieldsDiscountCoupon((oldFields) => ({
        ...oldFields,
        limitCouponUse: 0,
      }));
      return setIsUnlimited(false);
    }
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      limitCouponUse: 0,
    }));
    return setIsUnlimited(true);
  };

  const [isUnlimitedLimitPerUser, setIsUnlimitedLimitPerUser] = useState(
    fieldsDiscountCoupon.limitPerUser === 0,
  );

  const handleDefineUnlimitedCouponPerUser = () => {
    if (isUnlimitedLimitPerUser) {
      setFieldsDiscountCoupon((oldFields) => ({
        ...oldFields,
        limitPerUser: 0,
      }));
      return setIsUnlimitedLimitPerUser(false);
    }
    setFieldsDiscountCoupon((oldFields) => ({
      ...oldFields,
      limitPerUser: 0,
    }));
    return setIsUnlimitedLimitPerUser(true);
  };

  const [isUnlimitedEndDate, setIsUnlimitedDate] = useState(!fieldsDiscountCoupon.endDate);

  const handleUnlimitedEndDate = () => {
    if (isUnlimitedEndDate) {
      handleChangeEndDate(new Date(new Date().setDate(new Date().getDate() + 5)));
      return setIsUnlimitedDate(false);
    }
    handleChangeEndDate(null);
    return setIsUnlimitedDate(true);
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (
      new Date(fieldsDiscountCoupon.startDate) <
      new Date(new Date().setDate(new Date().getDate() - 1))
    ) {
      return setFieldsDiscountCoupon((oldFields) => ({
        ...oldFields,
        startDate: new Date(),
      }));
    }
    if (
      new Date(fieldsDiscountCoupon.startDate) >
      new Date(new Date().setDate(new Date().getDate() + 60))
    ) {
      setErrorMessage('A data de início deve ser dentro de 60 dias');
      return setDisableConfirmButton(true);
    }
    if (
      fieldsDiscountCoupon.endDate &&
      new Date(fieldsDiscountCoupon.startDate) > new Date(fieldsDiscountCoupon.endDate)
    ) {
      setErrorMessage('A data de início deve ser menor que a de término');
      return setDisableConfirmButton(true);
    }
    if (
      fieldsDiscountCoupon.endDate &&
      new Date(fieldsDiscountCoupon.endDate) >
        new Date(new Date().setDate(new Date().getDate() + 90))
    ) {
      setErrorMessage('A data de término deve contar no máximo 60 dias a partir do início');
      return setDisableConfirmButton(true);
    }
    if (fieldsDiscountCoupon.discountPercentage === 0) {
      setErrorMessage('A valor da porcentagem deve ser no mínimo 1%');
      return setDisableConfirmButton(true);
    }

    if (!fieldsDiscountCoupon.discountType) {
      setErrorMessage('Selecione o tipo de desconto');
      return setDisableConfirmButton(true);
    }
    if (
      fieldsDiscountCoupon.discountType === 'frete' &&
      !fieldsDiscountCoupon.minimumValueToGetDiscount
    ) {
      setErrorMessage('Informe o valor mínino da compra a receber desconto de frete');
      return setDisableConfirmButton(true);
    }
    if (!fieldsDiscountCoupon.code) {
      setErrorMessage('O código do cupom é obrigatório');
      return setDisableConfirmButton(true);
    }
    if (fieldsDiscountCoupon.limitCouponUse < 1 && !isUnlimited) {
      setErrorMessage('O valor máximo de cupons disponíveis deve ser informado');
      return setDisableConfirmButton(true);
    }
    if (fieldsDiscountCoupon.limitPerUser < 1 && !isUnlimitedLimitPerUser) {
      setErrorMessage('O valor máximo de uso de cupom por usuário deve ser informado');
      return setDisableConfirmButton(true);
    }
    return setDisableConfirmButton(false);
  }, [fieldsDiscountCoupon, isUnlimited, isUnlimitedLimitPerUser]);

  const handleSaveDiscountCoupon = async () => {
    try {
      if (fieldsDiscountCoupon.couponId) {
        await Axios.put(`/discountCoupon/${fieldsDiscountCoupon.couponId}`, {
          discountCoupon: fieldsDiscountCoupon,
        });
      } else {
        await Axios.post('/discountCoupon', {
          discountCoupon: fieldsDiscountCoupon,
        });
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao salvar o cupom de desconto',
        error,
      });
    }
    handleCloseDialogDiscountCoupon();
    handleRefreshDiscountCoupons();
  };

  return (
    <SimpleDialog
      dialogTitle={
        <Grid container alignItems="center" spacing={1}>
          <Grid item>Criar cupom</Grid>
          <Grid item>
            <Box width={120}>
              <Autocomplete
                size="small"
                options={[
                  { name: 'Produto', slug: 'produto' },
                  { name: 'Frete', slug: 'frete' },
                ]}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option) => option.slug}
                renderOption={(props, option) => <Typography {...props}>{option.name}</Typography>}
                onChange={handleChangeCouponType}
                noOptionsText="Opção não encontrada"
                renderInput={(params) => (
                  <TextField {...params} label="Aplicar no" variant="filled" />
                )}
              />
            </Box>
          </Grid>
          {fieldsDiscountCoupon.sourceCoupon ? (
            <Grid item>
              <Box width={120}>
                <FilterSelectOptions
                  inputLabel="Origem"
                  size="small"
                  filtersOptions={fieldsDiscountCoupon.sourceCoupon}
                  setFiltersOptions={handleChangeSourceCoupon}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      }
      openDialog={openDialogDiscountCoupon}
      handleOpenDialog={handleOpenDialogDiscountCoupon}
      handleClose={handleCloseDialogDiscountCoupon}
      disableConfirmButton={disableConfirmButton}
      content={
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              className={classes.grids}
            >
              <Grid item xs={5}>
                <CustomInput
                  label="Código do Cupom"
                  variant="standard"
                  value={fieldsDiscountCoupon.code}
                  onChange={handleChangeCouponCode}
                />
              </Grid>
              {fieldsDiscountCoupon.discountType === 'frete' ? (
                <Grid item xs={5}>
                  <CustomInput
                    label="Valor mínimo para desconto no frete"
                    variant="standard"
                    value={fieldsDiscountCoupon.minimumValueToGetDiscount}
                    onChange={handleChangeMinimumValueToGetDiscount}
                  />
                </Grid>
              ) : null}
              <Grid item xs={3} className={classes.switch}>
                <FormControlLabel
                  control={
                    <Switch
                      value={fieldsDiscountCoupon.active}
                      onChange={handleChangeCouponStatus}
                      checked={fieldsDiscountCoupon.active}
                      name="active"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {fieldsDiscountCoupon.active ? 'Ativo' : 'Inativo'}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      value={isUnlimitedEndDate}
                      onChange={handleUnlimitedEndDate}
                      checked={isUnlimitedEndDate}
                      name="active"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {isUnlimitedEndDate ? 'Ilimitado por data' : 'Limitado por data'}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} className={classes.grids}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={7}>
                    <CustomInput
                      label="Cupons por usuário"
                      disabled={isUnlimitedLimitPerUser}
                      value={fieldsDiscountCoupon.limitPerUser}
                      onChange={handleChangeCouponLimitPerUser}
                    />
                  </Grid>
                  <FormControlLabel
                    label={<Typography variant="caption">Ilimitado</Typography>}
                    control={
                      <Checkbox
                        onClick={handleDefineUnlimitedCouponPerUser}
                        checked={isUnlimitedLimitPerUser}
                        size="small"
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={7}>
                    <CustomInput
                      label="Limite de uso"
                      disabled={isUnlimited}
                      value={fieldsDiscountCoupon.limitCouponUse}
                      onChange={handleChangeCouponLimit}
                    />
                  </Grid>
                  <FormControlLabel
                    label={<Typography variant="caption">Ilimitado</Typography>}
                    control={
                      <Checkbox
                        onClick={handleDefineUnlimitedCoupon}
                        checked={isUnlimited}
                        size="small"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} className={classes.grids}>
              <Grid item xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Início</Typography>
                  </Grid>
                  <Grid item>
                    <CustomDatePicker
                      variant="standard"
                      fullWidth
                      size="small"
                      format="DD/MM/YYYY"
                      value={fieldsDiscountCoupon.startDate}
                      onChange={handleChangeStartDate}
                      error={fieldsDiscountCoupon.startDate > fieldsDiscountCoupon.endDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Término</Typography>
                  </Grid>
                  <Grid item>
                    <CustomDatePicker
                      variant="standard"
                      fullWidth
                      size="small"
                      format="DD/MM/YYYY"
                      value={fieldsDiscountCoupon.endDate}
                      onChange={handleChangeEndDate}
                      disabled={isUnlimitedEndDate}
                      error={fieldsDiscountCoupon.endDate < fieldsDiscountCoupon.startDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Slider
                  name="discountPercentage"
                  min={1}
                  max={fieldsDiscountCoupon.discountType === 'frete' ? 100 : 50}
                  step={1}
                  value={fieldsDiscountCoupon.discountPercentage}
                  onChange={handleChangeCouponPercentageSlider}
                  aria-labelledby="input-slider"
                  marks={fieldsDiscountCoupon.discountType === 'frete' ? marksUpTo100 : marksUpTo50}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid container direction="column" alignItems="center">
                  <Grid item variant="caption">
                    <Typography variant="caption">Desconto</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Typography variant="h6">
                        {fieldsDiscountCoupon.discountPercentage}%
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {errorMessage && disableConfirmButton && (
              <Typography color="error">{errorMessage}</Typography>
            )}
          </Grid>
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Salvar Cupom"
      handleCancelButton={handleCloseDialogDiscountCoupon}
      handleConfirmButton={handleSaveDiscountCoupon}
    />
  );
}

export default CouponDialog;
