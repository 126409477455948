const filterOrderSituationsInitial = [
  {
    id: 'Pedido confirmado',
    description: 'Para faturar',
    active: false,
  },
  {
    id: 'Pedido faturado',
    description: 'Para embalar',
    active: false,
  },
  {
    id: 'Pedido embalado',
    description: 'Para enviar',
    active: false,
  },
  {
    id: 'Pedido enviado',
    description: 'A caminho',
    active: false,
  },
  {
    id: 'blockedByDiscount',
    description: 'Desconto a liberar',
    active: false,
  },
  {
    id: 'pendingOccurrences',
    description: 'Ocorrências a resolver',
    active: false,
  },
];

export default filterOrderSituationsInitial;
