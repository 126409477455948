import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import newOtherDocument from '../../../../constant/newOtherDocument';
import { formatCpfCpnj, onlyNumbersValue } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

function OtherDocumentDialog({
  openDialogOtherDocument,
  handleCloseDialogOtherDocument,
  handleAddOtherDocument,
}) {
  const [otherDocument, setOtherDocument] = useState(newOtherDocument);

  const handleConfirm = useCallback(() => {
    handleAddOtherDocument({ otherDocument });
    return handleCloseDialogOtherDocument();
  }, [otherDocument]);

  const handleChange = (event) => {
    let fieldValue = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === 'cpf') {
      fieldValue = onlyNumbersValue(event.target.value);
    }

    if (fieldName === 'name') {
      fieldValue = event.target.value;
    }

    setOtherDocument((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      maxWidth="sm"
      content={
        <Grid container direction="row" spacing={1}>
          <Grid item xs={4}>
            <Grid container direction="column">
              <CustomInput
                label="CPF"
                name="cpf"
                onChange={handleChange}
                value={formatCpfCpnj(otherDocument.cpf)}
              />
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container direction="column">
              <CustomInput
                label="Nome"
                value={otherDocument.name}
                onChange={handleChange}
                name="name"
              />
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle="Adicionar outro documento"
      handleCancelButton={handleCloseDialogOtherDocument}
      handleClose={handleCloseDialogOtherDocument}
      handleConfirmButton={handleConfirm}
      openDialog={openDialogOtherDocument}
    />
  );
}

export default OtherDocumentDialog;
