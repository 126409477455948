import React, { memo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  sourceBadge: {
    width: 35,
    height: 35,
    marginTop: 5,
  },
});

const sources = [
  {
    source: 'Loja física',
    title: 'Pedido realizado pala Loja física',
    src: '/assets/imgs/loja-fisica-badge.svg',
  },
  {
    source: 'Site',
    title: 'Pedido realizado pelo Site',
    src: '/assets/imgs/site-badge.svg',
  },
  {
    source: 'App',
    title: 'Pedido realizado pelo App',
    src: '/assets/imgs/mobile-badge.svg',
  },
  {
    source: 'Telemarketing',
    title: 'Pedido realizado pelo Telemarketing',
    src: '/assets/imgs/telemarketing-badge.svg',
  },
  {
    source: 'Mercado Livre',
    title: 'Pedido realizado pelo Mercado Livre',
    src: '/assets/imgs/mercado-livre-badge.svg',
  },
  {
    source: 'Mercado Livre Full',
    title: 'Pedido realizado pelo Mercado Livre Full',
    src: '/assets/imgs/mercado-livre-full-badge.svg',
  },
  {
    source: 'Shopee',
    title: 'Pedido realizado pelo Shopee',
    src: '/assets/imgs/shopee-badge.svg',
  },
  {
    source: 'Americanas',
    title: 'Pedido realizado pelo Americanas',
    src: '/assets/imgs/americanas-badge.svg',
  },
  {
    source: 'Madeira Madeira',
    title: 'Pedido realizado pelo Madeira Madeira',
    src: '/assets/imgs/madeira-madeira-badge.svg',
  },
  {
    source: 'Magazine Luiza',
    title: 'Pedido realizado pela Magazine Luiza',
    src: '/assets/imgs/magalu-badge.svg',
  },
  {
    source: 'Via Varejo',
    title: 'Pedido realizado pelo Via Varejo',
    src: '/assets/imgs/via-badge.svg',
  },
  {
    source: 'Devolução fornecedor',
    title: 'Pedido realizado para devolução ao fornecedor',
    src: '/assets/imgs/devolution-supplier-badge.svg',
  },
  {
    source: 'Remessa conserto',
    title: 'Pedido realizado para envio de mercadoria para conserto',
    src: '/assets/imgs/remessa-conserto-badge.svg',
  },
  {
    source: 'Uso e consumo',
    title: 'Pedido realizado para fins de baixa de estoque',
    src: '/assets/imgs/baixa-estoque-badge.svg',
  },
  {
    source: 'Transferência de estoque',
    title: 'Pedido realizado para fins de transferência de estoque',
    src: '/assets/imgs/transferencia-estoque-badge.svg',
  },
];

function OrderSourceLogo({ orderSource }) {
  const classes = useStyles();

  const getSource = sources.find((findSource) => findSource.source === orderSource);

  return getSource ? (
    <Tooltip title={<Typography align="center">{getSource.title}</Typography>}>
      <img className={classes.sourceBadge} src={getSource.src} alt={getSource.title} />
    </Tooltip>
  ) : null;
}

export default memo(OrderSourceLogo);
