const newOffer = {
  name: 'Desconto para todos produtos',
  description: 'Desconto criado pelo painel de promoções fixas',
  type: 'Fixo',
  status: 'Ativo',
  quantity: 1,
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 5)),
  discountPercentage: 0,
  offerFor: '',
  offerForCode: '',
};

export default newOffer;
