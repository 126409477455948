import React from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import PrintPage from './PrintPage';

function PrintQuote({ selectedCompany, selectedProducts, orderId }) {
  const itemsPerPage = 18;
  const itemsPages = [];

  for (let i = 0; i < selectedProducts.length; i += itemsPerPage) {
    const chunk = selectedProducts.slice(i, i + itemsPerPage);
    itemsPages.push(chunk);
  }

  return (
    <PDFViewer width="1000" height="600" className="app">
      <Document>
        {itemsPages.map((items, index) => (
          <PrintPage
            key={`${index + 1}-${itemsPages.length}`}
            selectedCompany={selectedCompany}
            orderId={orderId}
            items={items}
            pageNumber={index + 1}
            pageQuantity={itemsPages.length}
          />
        ))}
      </Document>
    </PDFViewer>
  );
}

export default PrintQuote;
