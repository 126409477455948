import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const searchProducts = async ({
  limit = 20,
  skip = 0,
  searchText = '',
  sortBy = 'productId',
  sortDirection = 'desc',
  setLoadingProductsList,
  setProductsList,
  setProductsCount = () => {},
  setMarketplacesTotalResults = () => {},
  setCompaniesConfigurations = () => {},
  filterByTags,
  filterByCompany,
  filterBySupplier,
  filtersOptions,
  setSnackbar = () => {},
  filtersByCategories,
  filtersByBrands,
  onlySku,
}) => {
  try {
    setLoadingProductsList(true);

    const searchResponse = await Axios.get('/catalog/products/search-admin', {
      params: {
        limit,
        skip,
        searchText,
        sortBy,
        sortDirection,
        filterByTags,
        filterByCompany,
        filterBySupplier,
        filtersOptions,
        filtersByCategories,
        filtersByBrands,
        onlySku,
      },
    });

    const { totalResults, productsList } = searchResponse.data;

    const companiesResponse = await Axios.get('/configuration/companies');
    const companiesList = companiesResponse.data;
    setCompaniesConfigurations(companiesList);

    const productsListWithCompanies = productsList.map((product) => ({
      ...product,
      stocks: product.stocks.map((stock) => ({
        ...stock,
        company: companiesList.find((company) => stock.company === company._id),
      })),
      prices: product.prices.map((price) => ({
        ...price,
        company: companiesList.find((company) => price.company === company._id),
      })),
    }));

    setProductsList(productsListWithCompanies);
    setProductsCount(totalResults);

    if (productsList.length > 0) {
      const marketplacesResponse = await Axios.get(`/catalog/products/marketplaces`, {
        params: {
          productsIds: productsList.map((product) => product.productId),
        },
      });

      setMarketplacesTotalResults(marketplacesResponse.data);
    } else {
      setMarketplacesTotalResults([]);
    }

    return setLoadingProductsList(false);
  } catch (error) {
    setLoadingProductsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar os produtos',
      error,
    });
  }
};

export const getProduct = async (productId) => {
  try {
    const response = await Axios.get('/catalog/product-admin', {
      params: {
        id: productId,
      },
    });

    const { product } = response.data;
    return product;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao buscar o produto');
    return null;
  }
};

export const getProducts = async ({ limit, productsIds, allProducts, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.get(`/catalog/products`, {
      params: {
        limit,
        productsIds,
        sortBy: 'productId',
        allProducts,
      },
    });

    const { productsList } = response.data;
    return productsList;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar os produtos',
      error,
    });

    return null;
  }
};

export const createProduct = async ({ product, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/catalog/products', {
      product,
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar produto',
      error,
    });

    return null;
  }
};

export const updateProduct = async ({ product, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.put(`/catalog/product/${product.productId}`, {
      product,
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar produto',
      error,
    });

    return null;
  }
};

export const getProductsResume = async ({
  searchText = '',
  filterByTags = [],
  filterBySupplier = '',
  filtersOptions = [],
  setLoadingProductsResume,
  setFiltersOptions,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingProductsResume(true);

    const response = await Axios.get(`/catalog/products/resume`, {
      params: {
        searchText,
        filterByTags,
        filterBySupplier,
        filtersOptions,
      },
    });

    setFiltersOptions(response.data);
    return setLoadingProductsResume(false);
  } catch (error) {
    setLoadingProductsResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar resumo dos produtos',
      error,
    });
  }
};

export const handlePublishProducts = async ({
  setLoadingPublishedProducts = () => {},
  productsToPublish,
  mercadoLivreAdForm,
  madeiraMadeiraAdForm,
  shopeeAdForm,
  publishOnMercadoLivre,
  publishOnMadeiraMadeira,
  publishOnShopee,
  publishOnSkyhub,
  publishOnMagazineLuiza,
  publishOnViaVarejo,
  publishOnGoogleShopping,
}) => {
  const results = [];
  setLoadingPublishedProducts(true);

  if (publishOnMercadoLivre) {
    try {
      const response = await Axios.post('/mercado-livre/products', {
        productId: productsToPublish[0].productId,
        title: mercadoLivreAdForm.title,
        categoryId: mercadoLivreAdForm.category.categoryId,
        price: mercadoLivreAdForm.price,
        offerPrice: mercadoLivreAdForm.offerPrice,
        listingTypeId: mercadoLivreAdForm.adType.id,
        videoLink: mercadoLivreAdForm.videoLink,
        attributes: mercadoLivreAdForm.attributes,
        description: mercadoLivreAdForm.description,
        quantity: mercadoLivreAdForm.quantity,
        companies: mercadoLivreAdForm.companies,
        catalogListing: mercadoLivreAdForm.publishOnMlCatalog,
        catalogProductId: mercadoLivreAdForm.mercadoLivreCatalogProductId,
      });

      results.push({
        platform: 'Mercado Livre',
        createdProductsList: productsToPublish,
        errors: response.data.errors,
      });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Mercado Livre não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Mercado Livre',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnMadeiraMadeira) {
    let updatedProductForm = {
      ...productsToPublish[0],
      ...madeiraMadeiraAdForm,
    };

    try {
      const errors = [];

      const products = [
        {
          ...updatedProductForm,
          adQuantity: madeiraMadeiraAdForm.quantity,
        },
      ];

      if (!madeiraMadeiraAdForm.name) {
        errors.push({
          message: `O campo Nome no cadastro do produto precisa ser preenchido.`,
        });
      }
      if (!madeiraMadeiraAdForm.price) {
        errors.push({
          message: `O campo Preço no cadastro do produto precisa ser preenchido.`,
        });
      }
      if (!products[0].description) {
        errors.push({
          message: `O campo Descrição no cadastro do produto precisa ser preenchido.`,
        });
      }
      if (!products[0].brand) {
        errors.push({
          message: `O campo Marca no cadastro do produto precisa ser preenchido.`,
        });
      }
      if (products[0].images.length < 3) {
        errors.push({
          message: `O produto precisa ter no mínimo 3 imagens.`,
        });
      }
      if (products[0].package.weight === '0.000') {
        errors.push({
          message: `O peso do produto precisa ser no mínimo 0.001.`,
        });
      }
      if (products[0].package.width < 0.01) {
        errors.push({
          message: `A largura do produto precisa ser no mínimo 0.01.`,
        });
      }
      if (products[0].package.height < 0.01) {
        errors.push({
          message: `A altura do produto precisa ser no mínimo 0.01.`,
        });
      }
      if (products[0].package.length < 0.01) {
        errors.push({
          message: `O comprimento do produto precisa ser no mínimo 0.01.`,
        });
      }
      if (products[0].attributes.length === 0) {
        errors.push({
          message: `As características do produto precisam ser preenchidas.`,
        });
      }

      if (errors.length) {
        results.push({
          platform: 'Madeira Madeira',
          createdProductsList: [],
          errors,
        });
      } else {
        const response = await Axios.post('/madeira-madeira/products', { products });

        if (response.data.createdProductsList.length) {
          const updatedProduct = response.data.createdProductsList[0];

          updatedProductForm = {
            ...updatedProductForm,
            ...updatedProduct,
          };
        } else {
          let message = 'Por algum motivo o anúncio Madeira Madeira não pode ser cadastrado.';
          if (response.data && response.data.detail) {
            message = response.data.detail;
          }
          results.push({
            platform: 'Madeira Madeira',
            createdProductsList: [],
            errors: [{ message }],
          });
        }

        results.push({
          platform: 'Madeira Madeira',
          createdProductsList: productsToPublish,
          errors: [],
        });
      }
    } catch (error) {
      let message = 'Por algum motivo o anúncio Madeira Madeira não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Madeira Madeira',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnShopee) {
    try {
      const response = await Axios.post('/shopee/product', {
        product: productsToPublish[0],
        adForm: shopeeAdForm,
      });
      results.push({ platform: 'Shopee', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Shopee não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Shopee',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnSkyhub) {
    try {
      const response = await Axios.post('/skyhub/products', { products: productsToPublish });
      results.push({ platform: 'Americanas', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Americanas não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Americanas',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnMagazineLuiza) {
    try {
      const response = await Axios.post('/magazine-luiza/products', {
        products: productsToPublish,
      });
      results.push({ platform: 'Magazine Luiza', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Magazine Luiza não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Magazine Luiza',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnViaVarejo) {
    try {
      const response = await Axios.post('/via-varejo/products', { products: productsToPublish });
      results.push({ platform: 'Via Varejo', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Via Varejo não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Via Varejo',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  if (publishOnGoogleShopping) {
    try {
      const response = await Axios.post('/google-shopping/products', {
        products: productsToPublish,
      });
      results.push({ platform: 'Google Shopping', ...response.data });
    } catch (error) {
      let message = 'Por algum motivo o anúncio Google Shopping não pode ser cadastrado.';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      results.push({
        platform: 'Google Shopping',
        createdProductsList: [],
        errors: [{ message }],
      });
    }
  }

  setLoadingPublishedProducts(false);
  return results;
};
