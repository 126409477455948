import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import { numberToReal } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';

function ProductsTable({ itemsList, devolution }) {
  return (
    <Paper elevation={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">SKU</TableCell>
            <TableCell align="center">Imagem</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Preço</TableCell>
            <TableCell align="center">Quantidade</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsList && itemsList.length > 0 ? (
            itemsList.map((item) => (
              <TableRow key={item.productId}>
                <TableCell align="center">{item.productId}</TableCell>
                <TableCell align="center" width={60}>
                  {item.images && item.images.length > 0 ? (
                    <SimpleImage src={item.images[0].src} height={40} width={40} alt={item.name} />
                  ) : null}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center" width={100}>
                  R$ {numberToReal(item.selectedPrice)}
                </TableCell>
                <TableCell align="center" width={100}>
                  {item.quantity}
                </TableCell>
                <TableCell align="center" width={100}>
                  R$ {numberToReal(item.selectedPrice * item.quantity)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              {devolution ? (
                <TableCell colSpan={6} align="center">
                  <Typography>
                    Todos itens deste pedido foram devolvidos, confira mais a baixo
                  </Typography>
                </TableCell>
              ) : (
                <TableCell colSpan={6} align="center">
                  <Typography>Não há nenhum item neste pedido</Typography>
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default memo(ProductsTable);
