import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import simulateMongoObjectId from '../../../../../helpers/simulateMongoObjectId';

const useStyles = makeStyles(() => ({
  sourceBadge: {
    width: 20,
    height: 20,
  },
  sourceBadgeGrey: {
    filter: 'grayscale(100%)',
    opacity: 0.5,
    width: 20,
    height: 20,
  },
  baloonBadge: {
    right: -7,
    bottom: 0,
    padding: '0px 5px',
    position: 'absolute',
    borderRadius: 20,
  },
}));

function PlataformIcon({ companiesConfigurations, name, image, adsList }) {
  const classes = useStyles();

  const getFilterAds = (ads, company) =>
    ads.company === company._id || ads.company === company.nomeFantasia;

  const companiesByAds = companiesConfigurations.filter(
    (company) => adsList.findIndex((ads) => getFilterAds(ads, company)) >= 0,
  );

  return (
    <Box width={29} marginX="2px">
      <Tooltip
        title={
          <>
            <Typography align="center">{name}</Typography>

            {companiesConfigurations.map((company) => {
              const adsByCompany = adsList.filter((ads) => getFilterAds(ads, company));

              return (
                <Typography key={simulateMongoObjectId()}>
                  {adsByCompany.length} {company.name}
                </Typography>
              );
            })}
          </>
        }
      >
        <Box width="100%">
          <img
            width="85%"
            className={
              companiesConfigurations.length ? classes.sourceBadge : classes.sourceBadgeGrey
            }
            src={`/assets/imgs/${image}`}
            alt={name}
          />

          <Box position="relative">
            {companiesConfigurations.length > 0 ? (
              <Box
                className={classes.baloonBadge}
                bgcolor={companiesByAds.length > 1 ? '#ffb000' : '#e0e0e0'}
              >
                {adsList.length}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default memo(PlataformIcon);
