import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 2,
    borderWidth: 1,
    borderColor: '#000',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
});

function Items({ orderId, items }) {
  return (
    <View style={styles.tableContainer}>
      <TableHeader orderId={orderId} />
      <TableRow orderId={orderId} items={items} />
    </View>
  );
}

export default Items;
