import { Box, Paper, Table, TableContainer } from '@mui/material';
import React from 'react';
import ChequesTableFooter from './TableFooter';
import ChequesTableHead from './TableHead';
import ChequesTableBody from './TableBody';
import SimpleBackdrop from '../../Common/SimpleBackdrop';

function ChequesTable({
  chequesList,
  countCheques,
  selectedCheque,
  handleClickCheque,
  handleClickMovements,
  page,
  handleChangePage,
  limit,
  selectedChequeInList,
  checkedCheques,
  setCheckedCheques,
  loadingChequesList,
}) {
  const handleCheckCheque = ({ chequeId }) => {
    let newCheckedCheques = checkedCheques;
    newCheckedCheques = newCheckedCheques.filter((checkedChequeId) => checkedChequeId !== chequeId);
    newCheckedCheques.push(chequeId);

    return setCheckedCheques(newCheckedCheques);
  };

  const handleUncheckCheque = ({ chequeId }) => {
    let newCheckedCheques = checkedCheques;
    newCheckedCheques = newCheckedCheques.filter((checkedChequeId) => checkedChequeId !== chequeId);

    return setCheckedCheques(newCheckedCheques);
  };

  const isCheckedCheque = ({ chequeId }) => {
    if (checkedCheques.filter((checkedChequeId) => checkedChequeId === chequeId).length > 0) {
      return true;
    }
    return false;
  };

  const handleUncheckAll = () => setCheckedCheques([]);

  const handleCheckAll = () => {
    const chequesIds = chequesList
      .filter((cheque) => cheque.situation === 'Recebido' || cheque.situation === 'Devolvido')
      .map((cheque) => cheque.chequeId);
    return setCheckedCheques(chequesIds);
  };

  return (
    <Box position="relative">
      <SimpleBackdrop loading={loadingChequesList} absolutePosition />
      <Paper elevation={4}>
        <TableContainer>
          <Table>
            <ChequesTableHead
              checkedCheques={checkedCheques}
              handleUncheckAll={handleUncheckAll}
              handleCheckAll={handleCheckAll}
            />
            <ChequesTableBody
              isCheckedCheque={isCheckedCheque}
              handleUncheckCheque={handleUncheckCheque}
              handleCheckCheque={handleCheckCheque}
              chequesList={chequesList}
              selectedCheque={selectedCheque}
              handleClickCheque={handleClickCheque}
              handleClickMovements={handleClickMovements}
              page={page}
              handleChangePage={handleChangePage}
              selectedChequeInList={selectedChequeInList}
              loadingChequesList={loadingChequesList}
            />
            <ChequesTableFooter
              page={page}
              limit={limit}
              countCheques={countCheques}
              handleChangePage={handleChangePage}
            />
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default ChequesTable;
