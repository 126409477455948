import { Grid, Typography } from '@mui/material';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import checkValidCpfCnpj from '../../../../helpers/checkValidCpfCnpj';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import CustomInput from '../../../CustomInput';
import { useAuth } from '../../../../contexts/auth';
import getTasks from '../../../../services/melhorGestao/tasks';

function PriceAndStock({ productForm, setProductForm, setSnackbar }) {
  const loggedUser = useAuth().user;
  const [pricesAndStocksByCompanies, setPricesAndStocksByCompanies] = useState([]);
  const [otherCompany, setOtherCompany] = useState();

  const handleGetCompanies = async () => {
    try {
      const response = await Axios.get('/configuration/companies');
      setPricesAndStocksByCompanies(
        response.data
          .filter((company) => checkValidCpfCnpj(company.cnpj))
          .map((company) => {
            const prices =
              productForm.prices?.filter((findPrice) => findPrice.company === company._id) || [];

            const stocks =
              productForm.stocks?.filter((findPrice) => findPrice.company === company._id) || [];

            return {
              _id: company._id,
              prices,
              stocks,
              company: company.razaoSocial,
            };
          }),
      );

      setOtherCompany(response.data.find((company) => !checkValidCpfCnpj(company.cnpj)));
    } catch (error) {
      setSnackbar({
        message: error.message,
        open: true,
        type: 'error',
      });
    }
  };

  const [stockManagerTask, setStockManagerTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const getTask = tasksStaff.find((task) => task.name === 'Gerenciar estoque');
        if (getTask && getTask.active) {
          setStockManagerTask(getTask.active);
        }
      }
    };

    fetchData();
    handleGetCompanies();
  }, []);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h6">Preço e Estoque</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              label="Estoque máximo"
              value={productForm.maximumStock}
              onChange={(event) => {
                let value = parseInt(event.target.value, 10);
                if (Number.isNaN(value) || value < 0) {
                  value = 0;
                }

                setProductForm((oldFields) => ({
                  ...oldFields,
                  maximumStock: value,
                }));
              }}
              disabled={!stockManagerTask}
              InputAdornmentIcon={AddBoxOutlinedIcon}
              number
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              label="Estoque mínimo"
              value={productForm.minimumStock}
              onChange={(event) => {
                let value = parseInt(event.target.value, 10);
                if (Number.isNaN(value) || value < 0) {
                  value = 0;
                }

                setProductForm((oldFields) => ({
                  ...oldFields,
                  minimumStock: value,
                }));
              }}
              disabled={!stockManagerTask}
              InputAdornmentIcon={IndeterminateCheckBoxOutlinedIcon}
              number
            />
          </Grid>
        </Grid>
      </Grid>
      {pricesAndStocksByCompanies.map((pricesAndStocksByCompany) => (
        <Grid item key={pricesAndStocksByCompany._id}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">{pricesAndStocksByCompany.company}</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {pricesAndStocksByCompany.prices.map((price) => (
                  <Grid item xs={6} key={price._id}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Preço"
                          value={numberToRealWithPeriod(price.price)}
                          disabled
                          InputAdornmentIcon={AttachMoneyIcon}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Preço de oferta"
                          value={numberToRealWithPeriod(price.offerPrice)}
                          disabled
                          InputAdornmentIcon={AttachMoneyIcon}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {pricesAndStocksByCompany.prices.length === 0 ? (
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Preço"
                          value={numberToRealWithPeriod(0)}
                          disabled
                          InputAdornmentIcon={AttachMoneyIcon}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Preço de oferta"
                          value={numberToRealWithPeriod(0)}
                          disabled
                          InputAdornmentIcon={AttachMoneyIcon}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {pricesAndStocksByCompany.stocks.map((stock) => (
                  <Grid item xs={6} key={stock._id}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Estoque"
                          value={stock.quantity}
                          disabled
                          InputAdornmentIcon={ArchiveOutlinedIcon}
                          number
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Estoque reservado"
                          value={stock.reserved}
                          disabled
                          InputAdornmentIcon={ArchiveOutlinedIcon}
                          number
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {pricesAndStocksByCompany.stocks.length === 0 ? (
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Estoque"
                          value={0}
                          disabled
                          InputAdornmentIcon={ArchiveOutlinedIcon}
                          number
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomInput
                          label="Estoque reservado"
                          value={0}
                          disabled
                          InputAdornmentIcon={ArchiveOutlinedIcon}
                          number
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {otherCompany ? (
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">{otherCompany.razaoSocial}</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomInput
                        label="Preço"
                        value={numberToRealWithPeriod(productForm.price)}
                        disabled
                        InputAdornmentIcon={AttachMoneyIcon}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        label="Preço de oferta"
                        value={numberToRealWithPeriod(productForm.offerPrice)}
                        disabled
                        InputAdornmentIcon={AttachMoneyIcon}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <CustomInput
                        label="Estoque"
                        value={productForm.stock}
                        onChange={(event) => {
                          setProductForm((oldFields) => ({
                            ...oldFields,
                            stock: event.target.value,
                          }));
                        }}
                        disabled={!stockManagerTask}
                        InputAdornmentIcon={ArchiveOutlinedIcon}
                        number
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInput
                        label="Estoque reservado"
                        value={productForm.reservedStock}
                        disabled
                        InputAdornmentIcon={ArchiveOutlinedIcon}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default PriceAndStock;
