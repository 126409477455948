import { TableCell, TableFooter, TableRow } from '@mui/material';
import React from 'react';
import CustomPagination from '../../../../Common/CustomPagination';

function OrdersTableFooter({ page, totalOrders, limit, handleChangePage }) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={7}>
          <CustomPagination
            page={page}
            total={totalOrders}
            limit={limit}
            handleChangePage={handleChangePage}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default OrdersTableFooter;
