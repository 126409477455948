import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import paymentMethodList from '../../../constant/paymentMethodList';

const useStyles = makeStyles({
  paymentIcon: {
    filter: 'grayscale(1)',
    opacity: 0.5,
  },
  paymentIconBox: {
    width: 96,
    height: 96,
  },
  paymentIconSelected: {
    filter: 'none',
    opacity: 1,
  },
});

function PaymentMethods({ blockEdit, paymentMethod, setPaymentMethod, excludeMethods = [] }) {
  const classes = useStyles();

  const paymentMethods = paymentMethodList.filter(
    (method) => !excludeMethods.includes(method.name)
  );

  return (
    <Grid container justifyContent="center">
      {paymentMethods.map((method) => (
        <Grid item key={method.name}>
          <Button
            className={classes.paymentIconBox}
            onClick={() => {
              if (method.name === paymentMethod) {
                return setPaymentMethod('Aguardando financeiro');
              }
              return setPaymentMethod(method.name);
            }}
            disabled={blockEdit}
          >
            <img
              src={method.srcIcon}
              alt=""
              className={
                paymentMethod === method.name ? classes.paymentIconSelected : classes.paymentIcon
              }
            />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(PaymentMethods);
