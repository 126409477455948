import {
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { searchUsers } from '../../../services/melhorGestao/users';
import SearchField from '../../Common/SearchField';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomPagination from '../../Common/CustomPagination';
import SimpleLinearProgress from '../../Common/SimpleLinearProgress';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 400,
  },
  mediumCell: {
    width: 350,
  },
  headTableCheckbox: {
    width: 35,
  },
}));

function UsersTable({
  openUsersTable,
  handleOpenUsersTable,
  handleCloseUsersTable,
  handleOpenDialogConfirmSendEmail,
}) {
  const classes = useStyles();

  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(100);

  const getUsers = async () => {
    searchUsers(
      {
        limit,
        skip: page * limit - limit,
        searchText: searchTextSubmited,
        setUsersList,
        setTotalUsers,
        setLoadingUsersList,
      },
      [page, limit, searchTextSubmited],
    );
  };

  useEffect(() => {
    getUsers();
  }, [page, limit, searchTextSubmited]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, usersList],
  );

  const [checkedUsers, setCheckedUsers] = useState([]);

  const handleUncheckAllUsers = () => setCheckedUsers([]);

  const handleCheckAllUsers = () => {
    const usersEmails = usersList.map((user) => ({ email: user.email }));
    return setCheckedUsers(usersEmails);
  };

  const handleCheckUser = ({ email }) => {
    let newCheckedUsers = checkedUsers;
    newCheckedUsers = newCheckedUsers.filter(
      (checkedUserEmail) => checkedUserEmail.email !== email,
    );
    newCheckedUsers.push({ email });

    return setCheckedUsers(newCheckedUsers);
  };

  const handleUncheckUser = ({ email }) => {
    let newCheckedUsers = checkedUsers;
    newCheckedUsers = newCheckedUsers.filter(
      (checkedUserEmail) => checkedUserEmail.email !== email,
    );

    return setCheckedUsers(newCheckedUsers);
  };

  const isCheckedUser = ({ email }) => {
    if (checkedUsers.filter((checkedUserEmail) => checkedUserEmail.email === email).length > 0) {
      return true;
    }
    return false;
  };

  const emailsToSend = checkedUsers.map((user) => user.email);

  const handleSaveEmail = async () => {
    handleCloseUsersTable();
    handleOpenDialogConfirmSendEmail(emailsToSend);
  };

  return (
    <SimpleDialog
      dialogTitle="Selecionar Usuários"
      openDialog={openUsersTable}
      handleOpenDialog={handleOpenUsersTable}
      handleClose={handleCloseUsersTable}
      disableConfirmButton={!checkedUsers.length}
      actionButton={
        <SearchField submitSearch={setSearchTextSubmited} labelSearch="Pesquisar usuário" />
      }
      content={
        <Paper>
          <SimpleLinearProgress loading={loadingUsersList} fixedPosition />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headTableCheckbox} align="center">
                    <Tooltip
                      title={
                        <Typography align="center">
                          {checkedUsers.length > 0 ? 'Desmarcar tudo' : 'Selecionar tudo'}
                        </Typography>
                      }
                    >
                      <Checkbox
                        onClick={
                          checkedUsers.length > 0 ? handleUncheckAllUsers : handleCheckAllUsers
                        }
                        checked={checkedUsers.length > 0}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.smallCell}>Nome</TableCell>
                  <TableCell className={classes.mediumCell}>E-mail</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loadingUsersList || usersList.length > 0 ? (
                  usersList.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell align="center">
                        <Checkbox
                          checked={isCheckedUser({ email: user.email })}
                          onClick={() => {
                            if (isCheckedUser({ email: user.email })) {
                              handleUncheckUser({ email: user.email, name: user.name });
                            } else {
                              handleCheckUser({ email: user.email, name: user.name });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <SimpleLinearProgress loading={loadingUsersList} />
                      {!loadingUsersList ? (
                        <Grid container justifyContent="center" alignItems="center">
                          <Grid item>
                            <Typography>Nenhum template para exibir</Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomPagination
                      page={page}
                      total={totalUsers}
                      limit={limit}
                      handleChangePage={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Enviar"
      handleCancelButton={handleCloseUsersTable}
      handleConfirmButton={() => handleSaveEmail()}
    />
  );
}

export default UsersTable;
