import { Box, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CustomDatePicker from '../../../CustomDatePicker';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    width: 180,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function FinanceResumeTopBar({
  company,
  setCompany,
  preferenceDate,
  setPreferenceDate = () => {},
  endDate,
  setEndDate = () => {},
}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Resumo do Financeiro
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <CompanyAutocomplete
                handleOnChange={(event, newValue) => setCompany(newValue)}
                selectedCompany={company}
                variant="filled"
                className={classes.selectBackground}
              />
            </Grid>
            {preferenceDate ? (
              <Grid item>
                <Box className={classes.selectBackground}>
                  <CustomDatePicker
                    value={preferenceDate}
                    onChange={(date) => setPreferenceDate(date)}
                    label="Data inicial"
                    format="DD/MM/YYYY"
                    size="small"
                    variant="filled"
                  />
                </Box>
              </Grid>
            ) : null}
            {endDate ? (
              <Grid item>
                <Box className={classes.selectBackground}>
                  <CustomDatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    label="Data final"
                    format="DD/MM/YYYY"
                    size="small"
                    variant="filled"
                  />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default FinanceResumeTopBar;
