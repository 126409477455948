import { AppBar, Box, Grid, Toolbar, Link as LinkMaterial } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import dayjs from 'dayjs';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import UsersDetails from '../Users/Details';
import { ReactComponent as ProductsIcon } from '../../icons/product.svg';
import { ReactComponent as UsersIcon } from '../../icons/user.svg';
import { ReactComponent as OrdersIcon } from '../../icons/order.svg';
import getTasks from '../../services/melhorGestao/tasks';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 170,
  },
  menuDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  backgroundWithColor: {
    top: 0,
    left: 0,
    right: 0,
    height: 193,
    position: 'absolute',
    background: theme.palette.primary.dark,
    pointerEvents: 'none',
    backgroundSize: 'cover',
    zIndex: -1,
  },
}));

function Header() {
  const classes = useStyles();
  const { user, update, logout } = useAuth();

  const [menuItems, setmenuItems] = useState([]);
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);
  const [loadingAllTasks, setLoadingAllTasks] = useState(true);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [refreshToken, setRefreshToken] = useState(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        const items = [];
        const tasksStaff = await getTasks({ userForm: user, setLoadingAllTasks });

        const ordersLink = tasksStaff.find((task) => task.name === 'Visualizar pedidos');
        if (ordersLink && ordersLink.active) {
          items.push({
            link: '/pedidos',
            name: 'Pedidos',
            icon: OrdersIcon,
          });
        }

        const productsLink = tasksStaff.find((task) => task.name === 'Visualizar produtos');
        if (productsLink && productsLink.active) {
          items.push({
            link: '/produtos',
            name: 'Produtos',
            icon: ProductsIcon,
          });
        }

        const usersLink = tasksStaff.find((task) => task.name === 'Visualizar usuários');
        if (usersLink && usersLink.active) {
          items.push({
            link: '/usuarios',
            name: 'Usuários',
            icon: UsersIcon,
          });
        }

        const reportLink = tasksStaff.find((task) => task.name === 'Criar pagamentos');
        if (reportLink && reportLink.active) {
          items.push({
            link: '/relatorios',
            name: 'Relatórios',
            icon: FindInPageIcon,
          });
        }

        const salesLink = tasksStaff.find((task) => task.name === 'Criar pagamentos');
        if (salesLink && salesLink.active) {
          items.push({
            link: '/vendas',
            name: 'Vendas',
            icon: AssessmentIcon,
          });
        }

        const financesLink = tasksStaff.find((task) => task.name === 'Visualizar financeiro');
        if (financesLink && financesLink.active) {
          items.push({
            link: '/financeiro',
            name: 'Financeiro',
            icon: MonetizationOnIcon,
          });
        }

        const resumeFinancesLink = tasksStaff.find((task) => task.name === 'Visualizar financeiro');
        if (resumeFinancesLink && resumeFinancesLink.active) {
          items.push({
            link: '/financeiro/resumo',
            name: 'Resumo do Financeiro',
            icon: TrendingUpIcon,
          });
        }

        setmenuItems(items);
      }
    };
    fetchData();
  }, [user, refreshToken]);

  useEffect(() => {
    if (user && user.staffLoggedAt) {
      const date = dayjs(user.staffLoggedAt).add(1, 'day');
      if (date.isBefore(dayjs())) {
        update();
      }
    } else if (user && user.staffLoggedAt === undefined) {
      update();
    }
  }, [window.location.pathname]);

  const handleOpenDialogDetail = useCallback(() => setOpenDialogDetail(true), []);
  const handleCloseDialogDetail = useCallback(() => {
    update();
    setRefreshToken(Math.random());
    setOpenDialogDetail(false);
  }, []);

  return (
    <>
      <AppBar position="sticky">
        {!loadingAllTasks && (
          <Toolbar>
            <Grid container alignItems="center" justifyContent="space-between">
              <LinkMaterial href="/">
                <img
                  className={classes.logo}
                  alt="Logo Mais Hub"
                  src="/assets/imgs/logo-mais-hub.svg"
                />
              </LinkMaterial>
              <DesktopMenu
                logout={logout}
                loggedUser={user}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                className={classes.menuDesktop}
                menuItems={menuItems}
                openUserDialogDetail={handleOpenDialogDetail}
              />
              <MobileMenu
                logout={logout}
                loggedUser={user}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                className={classes.menuMobile}
                menuItems={menuItems}
                openUserDialogDetail={handleOpenDialogDetail}
              />
            </Grid>
          </Toolbar>
        )}
      </AppBar>
      <Box className={classes.backgroundWithColor} />

      {openDialogDetail && (
        <Box>
          <UsersDetails
            openDialogDetail={openDialogDetail}
            handleCloseDialogDetail={handleCloseDialogDetail}
            selectedUser={user}
            refreshOnSave={() => {}}
          />
        </Box>
      )}
    </>
  );
}

export default Header;
