import React from 'react';
import Footer from './Footer';
import TableItems from './TableItems';

function PartialOrderPrint({ order, items, pageNumber, pageQuantity }) {
  const ordemItems = [];

  items.forEach((item) => {
    if (!item.isDevolution) {
      ordemItems.push(item);
    }
  });
  return (
    <>
      <TableItems items={ordemItems} />
      <Footer order={order} pageNumber={pageNumber} pageQuantity={pageQuantity} />
    </>
  );
}

export default PartialOrderPrint;
