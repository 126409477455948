import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import CustomInput from '../../../../../../CustomInput';
import CustomDatePicker from '../../../../../../CustomDatePicker';
import {
  productImportIntermediations,
  productImportTransports,
} from '../../../../../../../constant/productTaxesInformations';

function ImportDataTab({ selectedProduct, setSelectedProduct }) {
  const handleChangeImportData = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setSelectedProduct((oldFields) => ({
      ...oldFields,
      importData: {
        ...oldFields.importData,
        [fieldName]: fieldValue,
      },
    }));
  };

  const handleChangeDocumentDate = (date) => {
    setSelectedProduct((oldFields) => ({
      ...oldFields,
      importData: {
        ...oldFields.importData,
        documentDate: date,
      },
    }));
  };

  return (
    <Paper>
      <Box padding={2}>
        <Box marginBottom={1}>
          <Typography variant="h3">Dados da importação</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomInput
              label="Número do documento"
              name="documentNumber"
              value={selectedProduct.importData?.documentNumber || ''}
              size="medium"
              variant="standard"
              color="secondary"
              onChange={handleChangeImportData}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomDatePicker
              label="Data do documento"
              size="medium"
              variant="standard"
              color="secondary"
              format="DD/MM/YYYY"
              value={selectedProduct.importData?.documentDate || null}
              onChange={handleChangeDocumentDate}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel>Via transporte</InputLabel>
              <Select
                name="viaTransport"
                value={selectedProduct.importData?.viaTransport || ''}
                onChange={handleChangeImportData}
                variant="filled"
              >
                {productImportTransports.map((origin) => (
                  <MenuItem key={origin.id} value={origin.id}>
                    <Typography variant="inherit">{origin.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <CustomInput
              label="Local do desembaraço"
              name="clearanceSite"
              value={selectedProduct.importData?.clearanceSite || ''}
              size="medium"
              variant="standard"
              color="secondary"
              onChange={handleChangeImportData}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomInput
              label="UF do desembaraço"
              name="clearanceUf"
              value={selectedProduct.importData?.clearanceUf || ''}
              size="medium"
              variant="standard"
              color="secondary"
              onChange={handleChangeImportData}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="filled" size="small">
              <InputLabel>Intermediação</InputLabel>
              <Select
                name="intermediation"
                value={selectedProduct.importData?.intermediation || ''}
                onChange={handleChangeImportData}
                variant="filled"
              >
                {productImportIntermediations.map((origin) => (
                  <MenuItem key={origin.id} value={origin.id}>
                    <Typography variant="inherit">{origin.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
export default memo(ImportDataTab);
