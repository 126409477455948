import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { grey } from '@mui/material/colors';
import AddCompanyDialog from './AddCompanyDialog';
import newCompany from '../../../constant/newCompany';
import { formatCpfCpnj, formatHandleError } from '../../../helpers/formatData';
import AddressCard from '../../Users/Details/AddressCard';
import SimpleImage from '../../Common/SimpleImage';
import ImagesManagerDialog from '../../Common/ImagesManagerDialog';
import SendCompanyCertificate from './SendCompanyCertificate';
import CompanyLogPaper from './IntegrationsPaper/CompanyLog';
import IntegrationsPaper from './IntegrationsPaper';
import SelectIntegrationDialog from './SelectIntegrationDialog';
import { availableIntegrations } from '../../../constant/availableIntegrations';

const useStyles = makeStyles((theme) => ({
  addCompanyIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
  },
  companyBadge: {
    width: 35,
    marginTop: 5,
  },
  boxImage: {
    cursor: 'pointer',
  },
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  gridIeSt: {
    position: 'relative',
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
  },
}));

function Companies() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [companies, setCompanies] = useState([]);
  const [companyForm, setCompanyForm] = useState(newCompany);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [disableConfirmAddCompanyDialog, setDisableConfirmAddCompanyDialog] = useState(true);

  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const handleOpenAddCompanyDialog = () => setOpenAddCompanyDialog(true);
  const handleCloseAddCompanyDialog = () => setOpenAddCompanyDialog(false);

  const handleGetCompanies = async () => {
    try {
      const response = await Axios.get('/configuration/companies');
      setCompanies(response.data);

      if (!selectedCompany) {
        setSelectedCompany(response.data[0] || null);
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar empresas',
        error,
      });
    }
  };

  useEffect(() => {
    handleGetCompanies();
  }, []);

  const handleAddOrUpdateCompany = async () => {
    try {
      if (companyForm.companyId) {
        const response = await Axios.put(
          `/configuration/companies/${companyForm.companyId}`,
          companyForm,
        );
        setSelectedCompany(response.data);
        handleCloseAddCompanyDialog();
        handleGetCompanies();
      } else {
        const response = await Axios.post('/configuration/companies', companyForm);
        setSelectedCompany(response.data);
        handleCloseAddCompanyDialog();
        handleGetCompanies();
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao adicionar ou atualizar a empresa',
        error,
      });
    }
  };

  const [openImagesManagerDialog, setOpenImagesManagerDialog] = useState(false);
  const handleOpenImagesManagerDialog = () => setOpenImagesManagerDialog(true);
  const handleCloseImagesManagerDialog = () => setOpenImagesManagerDialog(false);

  const [selectedSrcLogo, setSelectedSrcLogo] = useState('');
  const [logoFieldName, setLogoFieldName] = useState('');

  const handleSelectedLogo = async (src) => {
    try {
      const reponseUpdate = await Axios.put(
        `/configuration/companies/${selectedCompany.companyId}`,
        {
          [logoFieldName]: src,
        },
      );

      setSelectedCompany(reponseUpdate.data);
      handleCloseImagesManagerDialog();
      handleGetCompanies();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao adicionar ou atualizar a logo da empresa',
        error,
      });
    }
  };

  useEffect(() => {
    if (
      companyForm.name &&
      companyForm.cnpj &&
      companyForm.nomeFantasia &&
      companyForm.razaoSocial &&
      companyForm.type
    ) {
      setDisableConfirmAddCompanyDialog(false);
    } else {
      setDisableConfirmAddCompanyDialog(true);
    }
  }, [companyForm]);

  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [openDialogSelectIntegration, setOpenDialogSelectIntegration] = useState(false);

  const handleCloseDialogSelectIntegration = () => {
    setOpenDialogSelectIntegration(false);
  };

  const [selectedCity, setSelectedCity] = useState(null);
  const [activeIntegrations, setActiveIntegrations] = useState([]);

  useEffect(() => {
    const getActiveIntegrations = async () => {
      try {
        const response = await Axios.get(
          `/configuration/integrations/${selectedCompany.companyId}`,
        );

        setActiveIntegrations(response.data);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as integrações',
          error,
        });
      }
    };

    if (selectedCompany) {
      getActiveIntegrations();
    }
  }, [selectedCompany]);

  const handleSelectIntegration = (integration) => {
    const emptyIntegration = availableIntegrations
      .flatMap((availableIntegration) => availableIntegration.options)
      .find((option) => option.name === integration.name);

    setSelectedIntegration({
      ...emptyIntegration,
      ...integration,
      configuration: { ...emptyIntegration.configuration, ...integration.configuration },
      isNew: false,
    });

    setSelectedCity(null);
  };

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    setSelectedIntegration(null);
  };

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper>
                <Box padding={2}>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h5">Empresas</Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<Typography>Adicionar uma empresa</Typography>}>
                        <IconButton
                          onClick={() => {
                            setCompanyForm(newCompany);
                            handleOpenAddCompanyDialog();
                          }}
                        >
                          <AddCircleIcon className={classes.addCompanyIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <MenuList>
                  {companies && companies.length > 0
                    ? companies.map((company) => (
                        <MenuItem
                          key={company.companyId}
                          onClick={() => handleSelectCompany(company)}
                          className={
                            selectedCompany && selectedCompany._id === company._id
                              ? classes.menuItemSelected
                              : null
                          }
                        >
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                              <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                  {company.srcLogoIcon ? (
                                    <SimpleImage
                                      key={company.srcLogoIcon}
                                      src={company.srcLogoIcon}
                                      height={35}
                                      width={35}
                                      alt={company.name}
                                      className={classes.companyBadge}
                                    />
                                  ) : (
                                    <Typography variant="h4">
                                      {company.name.substring(0, 2)}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item>
                                  {company.companyId} - {company.name}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Chip
                                size="small"
                                label={company.active ? 'Ativa' : 'Inativa'}
                                color={company.active ? 'primary' : 'default'}
                              />
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))
                    : null}
                </MenuList>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Paper>
                <Box padding={2}>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h5">Integrações</Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<Typography>Adicionar integração</Typography>}>
                        <IconButton
                          onClick={() => {
                            setOpenDialogSelectIntegration(true);
                            setSelectedCity(null);
                          }}
                        >
                          <AddCircleIcon className={classes.addCompanyIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <MenuList>
                  {activeIntegrations.length > 0
                    ? activeIntegrations.map((integration) => (
                        <MenuItem
                          onClick={() => handleSelectIntegration(integration)}
                          key={integration.name}
                          className={selectedCompany ? classes.menuItemSelected : null}
                        >
                          {integration.name}
                        </MenuItem>
                      ))
                    : null}
                </MenuList>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {selectedCompany && selectedCompany.cnpj && !selectedIntegration ? (
          <Grid item sm={8}>
            <Paper>
              <Box padding={2}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5">
                      {selectedCompany.name} ({selectedCompany.nomeFantasia})
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setCompanyForm(selectedCompany);
                        handleOpenAddCompanyDialog();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Grid container direction="column" justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography>{selectedCompany.razaoSocial}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {formatCpfCpnj(selectedCompany.cnpj)} ({selectedCompany.type})
                      </Typography>
                    </Grid>
                    {selectedCompany.automaticStockTransfer ? (
                      <Grid item>
                        <Typography>
                          Emissão automática de NF-e de transferência de estoque ativa
                        </Typography>
                      </Grid>
                    ) : null}
                    {selectedCompany.ieSt && selectedCompany.ieSt.length > 0 ? (
                      <>
                        <Grid item>
                          <Typography variant="h6">Inscrições Estaduais</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            {selectedCompany.ieSt.map((st, index) => (
                              <Grid item xs={6} key={`${index + 1}`}>
                                <Grid container className={classes.gridIeSt}>
                                  <Grid item xs={3}>
                                    <Typography>{st.state}</Typography>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Typography>{st.inscricaoEstadual}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <Grid item>
                      <Typography variant="h6">Certificado</Typography>
                    </Grid>
                    <Grid item>
                      <SendCompanyCertificate
                        selectedCompany={selectedCompany}
                        setSelectedCompany={setSelectedCompany}
                        handleGetCompanies={handleGetCompanies}
                        setSnackbar={setSnackbar}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Endereço</Typography>
                    </Grid>
                    <Grid item>
                      <AddressCard address={selectedCompany.address} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Logotipos</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-evenly">
                        <Grid item>
                          <Box
                            bgcolor="#fff"
                            width="70px"
                            height="70px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
                            onClick={() => {
                              setSelectedSrcLogo(selectedCompany.srcLogoIcon);
                              setLogoFieldName('srcLogoIcon');
                              handleOpenImagesManagerDialog();
                            }}
                            className={classes.boxImage}
                          >
                            {selectedCompany.srcLogoIcon ? (
                              <SimpleImage
                                key={selectedCompany.srcLogoIcon}
                                src={selectedCompany.srcLogoIcon}
                                height={70}
                                width={70}
                                alt={selectedCompany.name}
                              />
                            ) : (
                              <Typography variant="caption">Ícone</Typography>
                            )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            bgcolor="#fff"
                            width="300px"
                            height="100px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            boxShadow="0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
                            onClick={() => {
                              setSelectedSrcLogo(selectedCompany.srcLogo);
                              setLogoFieldName('srcLogo');
                              handleOpenImagesManagerDialog();
                            }}
                            className={classes.boxImage}
                          >
                            {selectedCompany.srcLogo ? (
                              <SimpleImage
                                key={selectedCompany.srcLogo}
                                src={selectedCompany.srcLogo}
                                height={100}
                                width={300}
                                alt={selectedCompany.name}
                              />
                            ) : (
                              <Typography variant="caption">Logotipo</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ) : null}
        {selectedIntegration ? (
          <Grid item sm={8}>
            {selectedIntegration.name === 'Mark Ferragens' ? (
              <CompanyLogPaper
                listCities={!selectedIntegration.isNew}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
              />
            ) : (
              <IntegrationsPaper
                key={selectedIntegration.name}
                integration={selectedIntegration}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                setSnackbar={setSnackbar}
              />
            )}
          </Grid>
        ) : null}
      </Grid>
      {openAddCompanyDialog ? (
        <AddCompanyDialog
          companyForm={companyForm}
          setCompanyForm={setCompanyForm}
          openAddCompanyDialog={openAddCompanyDialog}
          handleCloseAddCompanyDialog={handleCloseAddCompanyDialog}
          handleConfirmAddCompanyDialog={handleAddOrUpdateCompany}
          disableConfirmAddCompanyDialog={disableConfirmAddCompanyDialog}
        />
      ) : null}
      {openImagesManagerDialog && selectedCompany?._id ? (
        <ImagesManagerDialog
          openImagesManagerDialog={openImagesManagerDialog}
          handleCloseImagesManagerDialog={handleCloseImagesManagerDialog}
          onSelectSrcImage={(src) => handleSelectedLogo(src)}
          selectedSrcImage={selectedSrcLogo}
          directory={`logos-${selectedCompany._id}`}
          title={selectedCompany.razaoSocial}
        />
      ) : null}
      {openDialogSelectIntegration ? (
        <SelectIntegrationDialog
          openDialogSelectIntegration={openDialogSelectIntegration}
          handleCloseDialogSelectIntegration={handleCloseDialogSelectIntegration}
          setSelectedIntegration={setSelectedIntegration}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Companies;
