import { Box, Grid, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { changeEntryOrderStatus } from '../../../../../services/melhorGestao/orders';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';

function ChangeOrderStatusButton({
  entryOrder,
  blockButton,
  setEntryOrder = () => {},
  setInitialEntryOrder = () => {},
  handleRefresh = () => {},
  setSnackbar = () => {},
}) {
  const getNextStatus = () => {
    if (entryOrder.status === 'Pedido cancelado') {
      return { name: 'Orçamento', button: 'Criar orçamento' };
    }
    if (entryOrder.status === 'Orçamento') {
      return { name: 'Pedido confirmado', button: 'Confirmar pedido' };
    }
    if (entryOrder.status === 'Pedido confirmado') {
      return { name: 'Pedido faturado', button: 'Faturar pedido' };
    }
    if (entryOrder.status === 'Pedido faturado') {
      return { name: 'Pedido concluído', button: 'Marcar como concluído' };
    }
    if (entryOrder.status === 'Pedido concluído') {
      return {
        name: 'Pedido cancelado',
        button: 'Cancelar pedido',
        withReason: true,
        description: 'Ao cancelar um pedido concluído, o estoque dos produtos serão alterados.',
      };
    }
    return null;
  };

  const nextStatus = getNextStatus();
  const [loadingUpdateOrder, setLoadingUpdateOrder] = useState(false);

  const [openDialogConfirmReason, setOpenDialogConfirmReason] = useState(false);
  const handleOpenComfirmReasonDialog = () => setOpenDialogConfirmReason(true);
  const handleCloseComfirmReasonDialog = () => setOpenDialogConfirmReason(false);
  const [cancelOrderReason, setCancelOrderReason] = useState('');

  const handleComfirm = async () => {
    if (!blockButton && nextStatus?.name) {
      const updatedOrder = await changeEntryOrderStatus({
        entryOrderId: entryOrder.entryOrderId,
        toStatus: nextStatus.name,
        reason: cancelOrderReason,
        setLoadingUpdateOrder,
        setSnackbar,
      });

      if (nextStatus.withReason) {
        handleCloseComfirmReasonDialog();
      }

      if (updatedOrder) {
        setEntryOrder((oldFields) => ({ ...oldFields, ...updatedOrder }));
        setInitialEntryOrder((oldFields) => ({ ...oldFields, ...updatedOrder }));
        handleRefresh();
      }
    }
  };

  return nextStatus ? (
    <Box>
      <LoadingButton
        loading={loadingUpdateOrder}
        variant="contained"
        color={nextStatus.withReason ? 'secondary' : 'primary'}
        disabled={blockButton}
        onClick={nextStatus.withReason ? handleOpenComfirmReasonDialog : handleComfirm}
      >
        {nextStatus.button}
      </LoadingButton>
      {openDialogConfirmReason ? (
        <SimpleDialog
          openDialog={openDialogConfirmReason}
          handleClose={handleCloseComfirmReasonDialog}
          dialogTitle={nextStatus.button}
          dialogText={`O status mudará para "${nextStatus.name}"`}
          content={
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography>{nextStatus.description}</Typography>
              </Grid>
              <Grid item>
                <CustomInput
                  label="Informe o motivo"
                  variant="outlined"
                  value={cancelOrderReason}
                  onChange={(event) => setCancelOrderReason(event.target.value)}
                  error={!cancelOrderReason}
                />
              </Grid>
            </Grid>
          }
          cancelButtonText="Cancelar"
          maxWidth="xs"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseComfirmReasonDialog}
          handleConfirmButton={handleComfirm}
          disableConfirmButton={!cancelOrderReason}
        />
      ) : null}
    </Box>
  ) : null;
}

export default memo(ChangeOrderStatusButton);
