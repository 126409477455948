import { Box, Chip, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';
import CustomInput from '../../../../../../CustomInput';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
  inputAdornmentCost: {
    marginBottom: -1,
  },
});

function ValuesFromCost({
  selectedProduct,
  handleChangeIpiRate = () => {},
  handleChangeStRate = () => {},
  handleChangeShippingRate = () => {},
  handleChangeOthersCostRate,
  handleChangeDifalValue,
  ipiValue,
  stValue,
  shippingValue,
  othersCostValue,
  bouthCostValue,
  difalValue,
  blockFields,
}) {
  const classes = useStyles();

  const [ipiRate, setIpiRate] = useState(selectedProduct.ipiRate);
  const [stRate, setStRate] = useState(selectedProduct.stRate);
  const [othersCostRate, setOthersCostRate] = useState(selectedProduct.othersCostRate);
  const [difalRate, setDifalRate] = useState(selectedProduct.difalRate);
  const [shippingRate, setShippingRate] = useState(selectedProduct.shippingRate);

  const changeIpiValue = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    setIpiRate(value);
    handleChangeIpiRate(value);
  };

  const changeStValue = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    setStRate(value);
    handleChangeStRate(value);
  };

  const changeDifalValue = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    setDifalRate(value);
    handleChangeDifalValue(value);
  };

  const changeShippingValue = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    setShippingRate(value);
    handleChangeShippingRate(value);
  };

  const changeOthersCost = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    setOthersCostRate(value);
    handleChangeOthersCostRate(value);
  };

  return (
    <Paper>
      <Box padding={2}>
        <Box marginBottom={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">Valores sobre o preço de compra</Typography>
            </Grid>
            <Grid item>
              {selectedProduct.hasSt ? (
                <Chip size="small" label="Produto com ST" color="primary" />
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container alignItems="flex-end" spacing={0.5}>
              <Grid item xs={8}>
                <CustomInput
                  label="IPI"
                  value={numberToRealWithPeriod(ipiValue)}
                  size="medium"
                  variant="standard"
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  value={ipiRate}
                  onChange={changeIpiValue}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  InputEndAdornment="%"
                  InputAdornmentStyle={classes.inputAdornment}
                  error={!(ipiRate >= 0)}
                  disabled={blockFields}
                  number
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container alignItems="flex-end" spacing={0.5}>
              <Grid item xs={8}>
                <CustomInput
                  label="ST"
                  value={numberToRealWithPeriod(stValue)}
                  size="medium"
                  variant="standard"
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  value={stRate}
                  onChange={changeStValue}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  InputEndAdornment="%"
                  InputAdornmentStyle={classes.inputAdornment}
                  error={!(stRate >= 0)}
                  disabled={blockFields}
                  number
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container alignItems="flex-end" spacing={0.5}>
              <Grid item xs={8}>
                <CustomInput
                  label="Alíquota"
                  value={numberToRealWithPeriod(difalValue)}
                  size="medium"
                  variant="standard"
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  value={difalRate}
                  onChange={changeDifalValue}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  InputEndAdornment="%"
                  InputAdornmentStyle={classes.inputAdornment}
                  error={!(difalRate >= 0)}
                  disabled={blockFields}
                  number
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container alignItems="flex-end" spacing={0.5}>
              <Grid item xs={8}>
                <CustomInput
                  label="Frete"
                  value={numberToRealWithPeriod(shippingValue)}
                  size="medium"
                  variant="standard"
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  value={shippingRate}
                  onChange={changeShippingValue}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  InputEndAdornment="%"
                  InputAdornmentStyle={classes.inputAdornment}
                  error={!(shippingRate >= 0)}
                  disabled={blockFields}
                  number
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container alignItems="flex-end" spacing={0.5}>
              <Grid item xs={8}>
                <CustomInput
                  label="Outros"
                  value={numberToRealWithPeriod(othersCostValue)}
                  size="medium"
                  variant="standard"
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  value={othersCostRate}
                  onChange={changeOthersCost}
                  size="medium"
                  variant="standard"
                  color="secondary"
                  InputEndAdornment="%"
                  InputAdornmentStyle={classes.inputAdornment}
                  error={!(othersCostRate >= 0)}
                  disabled={blockFields}
                  number
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Box position="relative" top="-8px" height="48px">
              <Grid container alignItems="flex-end">
                <Grid item xs={12}>
                  <CustomInput
                    label="Custo de compra"
                    value={numberToRealWithPeriod(bouthCostValue)}
                    size="medium"
                    variant="filled"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornmentCost}
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
export default memo(ValuesFromCost);
