import { Box, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ValueBlock from './ValueBlock';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';

const useStyles = makeStyles({
  greenColorIcon: {
    color: '#2ecc71',
  },
  redColorIcon: {
    color: '#e74c3c',
  },
});

function SquareWidget({
  darkMode,
  title,
  totalReceipt,
  totalPayment,
  countReceipt,
  countPayment,
  lastTitle,
  lastClosingValue,
  cumulativeClosing,
}) {
  const classes = useStyles();

  let percentage =
    totalReceipt > 0 && totalPayment > 0 ? Math.round((totalReceipt / totalPayment - 1) * 100) : 0;

  if (totalReceipt > 0 && totalPayment <= 0) {
    percentage = 100;
  }
  if (totalPayment >= 0 && totalReceipt < 0) {
    percentage = -100;
  }

  const trending = percentage > 0 ? 'up' : 'down';

  let totalReceiptWithClosingValue = totalReceipt;
  let totalPaymentWithClosingValue = totalPayment;

  if (cumulativeClosing > 0) {
    totalReceiptWithClosingValue = totalReceipt + cumulativeClosing;
  }
  if (cumulativeClosing < 0) {
    totalPaymentWithClosingValue = totalPayment + Math.abs(cumulativeClosing);
  }

  let lastClosingPercentage =
    totalReceipt > 0 && totalPayment > 0
      ? Math.round((totalReceiptWithClosingValue / totalPaymentWithClosingValue - 1) * 100)
      : 0;

  if (totalReceiptWithClosingValue > 0 && totalPaymentWithClosingValue <= 0) {
    lastClosingPercentage = 100;
  }
  if (totalPaymentWithClosingValue >= 0 && totalReceiptWithClosingValue < 0) {
    lastClosingPercentage = -100;
  }

  const lastClosingTrending = lastClosingPercentage > 0 ? 'up' : 'down';

  let classTrending =
    trending === 'down'
      ? {
          border: 0,
          borderBottom: `20px solid #e74c3c`,
          color: '#26262b',
          boxShadow: '0 0 9px rgba(0,0,0,.25)',
          '&:hover': {
            boxShadow: '0 2 12px rgba(0,0,0,.25)',
          },
        }
      : {
          border: 0,
          borderBottom: `20px solid #2ecc71 `,
          color: '#26262b',
          boxShadow: '0 0 9px rgba(0,0,0,.25)',
          '&:hover': {
            boxShadow: '0 2 12px rgba(0,0,0,.25)',
          },
        };

  if (darkMode) {
    classTrending =
      trending === 'up'
        ? {
            border: 0,
            borderBottom: `20px solid #2ecc71`,
            backgroundColor: '#26262b',
            color: '#fff',
            boxShadow: '0 0 9px rgba(0,0,0,.25)',
            '&:hover': {
              boxShadow: '0 2 12px rgba(0,0,0,.25)',
            },
          }
        : {
            border: 0,
            borderBottom: `20px solid #e74c3c`,
            backgroundColor: '#26262b',
            color: '#fff',
            boxShadow: '0 0 9px rgba(0,0,0,.25)',
            '&:hover': {
              boxShadow: '0 2 12px rgba(0,0,0,.25)',
            },
          };
  }

  return (
    <Paper sx={classTrending}>
      <Box padding={2} height="445px" width="300px">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Box marginBottom="10px">
              <Typography variant="h5">{title}</Typography>
            </Box>
          </Grid>
          <Box marginTop={1}>
            <Grid item>
              <Box paddingTop="5px" paddingBottom="5px">
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <ValueBlock
                      title="Valor de entrada"
                      value={totalReceipt}
                      quantity={countReceipt}
                    />
                  </Grid>
                  <Grid item>
                    <ValueBlock
                      title="Valor de saída"
                      value={totalPayment}
                      quantity={countPayment}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Divider />
            <Grid item>
              <Box paddingTop="5px" paddingBottom="5px">
                <Tooltip
                  title={
                    <Typography align="center">
                      Esta porcentagem é calculada com base na diferença do valor de entrada para o
                      de saída
                    </Typography>
                  }
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                      {trending === 'up' && <TrendingUpIcon className={classes.greenColorIcon} />}
                      {trending === 'down' && <TrendingDownIcon className={classes.redColorIcon} />}
                    </Grid>
                    <Grid item>
                      <Typography variant="button">{percentage}%</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="caption">
                            entrada {trending === 'up' ? 'acima da' : 'abaixo da'} saída
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            R$ {numberToRealWithPeriod(totalReceipt - totalPayment)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            </Grid>
            <Divider />
            <Grid item>
              <Box paddingTop="5px" paddingBottom="5px">
                <Tooltip
                  title={
                    <Typography align="center">
                      Fechamento calculado com base na entrada menos saída do mês de {lastTitle}
                    </Typography>
                  }
                >
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography variant="h3">Fechamento de {lastTitle}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="center" alignItems="baseline">
                        <Grid item>
                          <Box marginRight="3px">
                            <Typography variant="overline">R$</Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>{` ${numberToRealWithPeriod(lastClosingValue)}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            </Grid>
            <Divider />
            <Grid item>
              <Box paddingTop="5px" paddingBottom="5px">
                <Tooltip
                  title={
                    <Typography align="center">
                      Fechamento com valor acumulado para mais ou para menos dos meses anteriores
                    </Typography>
                  }
                >
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Typography variant="h3">Fechamento acumulado</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="center" alignItems="baseline">
                        <Grid item>
                          <Box marginRight="3px">
                            <Typography variant="overline">R$</Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>{` ${numberToRealWithPeriod(cumulativeClosing)}`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            </Grid>
            <Divider />
            <Grid item>
              <Box paddingTop="5px" paddingBottom="5px">
                <Tooltip
                  title={
                    <Typography align="center">
                      Esta porcentagem é calculada com base na soma do valor acumulado mais valor de
                      entrada sendo positivo ou saída sendo negativo
                    </Typography>
                  }
                >
                  <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                      {lastClosingTrending === 'up' && (
                        <TrendingUpIcon className={classes.greenColorIcon} />
                      )}
                      {lastClosingTrending === 'down' && (
                        <TrendingDownIcon className={classes.redColorIcon} />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="button">{lastClosingPercentage}%</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="caption">
                            {lastClosingTrending === 'up' ? 'ganho' : 'gasto'} acumulado
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            R${' '}
                            {numberToRealWithPeriod(
                              totalReceiptWithClosingValue - totalPaymentWithClosingValue
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Tooltip>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Paper>
  );
}

export default React.memo(SquareWidget);
