import Axios from 'axios';
import React, { useEffect } from 'react';
import FilterSelectOptions from '../FilterSelectOptions';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../helpers/formatData';

function SupplierSelectOptions({
  setSnackbar = () => {},
  multiple = true,
  width = 200,
  size = 'small',
  autoConfirm,
  filtersOptions,
  setFiltersOptions,
  hideButton,
}) {
  const formatOptions = (user) => ({
    id: user.userId,
    description: `${user.userId} - ${capitalizeString(extractFirstAndLastName(user.name))}`,
    active: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/users/search', {
          params: {
            limit: 2000,
            roles: ['supplier'],
          },
        });

        const { usersList } = response.data;
        setFiltersOptions(usersList.map((user) => formatOptions(user)));
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar fornecedores',
          error,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <FilterSelectOptions
      inputLabel="Fornecedor"
      width={width}
      size={size}
      multiple={multiple}
      autoConfirm={autoConfirm}
      filtersOptions={filtersOptions}
      setFiltersOptions={setFiltersOptions}
      hideButton={hideButton}
    />
  );
}

export default SupplierSelectOptions;
