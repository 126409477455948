import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Link as LinkMaterial,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import RemoveIcon from '@mui/icons-material/Remove';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import React, { memo } from 'react';
import { numberToReal, formatDate } from '../../../../../helpers/formatData';

function PaymentsTable({ payments, handleEditPayment, handleDeletePayment }) {
  const getLabelColorStatus = (payment) => {
    if (payment.status === 'Pago') {
      return { color: '#00c600' };
    }
    return { color: '#ff2222' };
  };

  return (
    <Paper elevation={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Método</TableCell>
            <TableCell align="center">Valor</TableCell>
            <TableCell align="center">Taxas</TableCell>
            <TableCell align="center">Vencimento</TableCell>
            <TableCell align="center">Situação</TableCell>
            <TableCell align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment, index) => (
            <TableRow key={`${payment.type}-${payment.value}-${index + 1}`}>
              <TableCell width={32}>
                {payment.type === 'Pagamento' ? (
                  <Grid item>
                    <RemoveIcon sx={{ color: '#ff2222', marginTop: 0.6 }} />
                  </Grid>
                ) : (
                  <Grid item>
                    <AddIcon sx={{ color: '#00c600', marginTop: 0.6 }} />
                  </Grid>
                )}
              </TableCell>
              <TableCell align="center">
                <Typography variant="caption">{payment.method}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="caption">R$ {numberToReal(payment.value)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="caption">R$ {numberToReal(payment.financialCost)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="caption">
                  {payment.dueDate ? formatDate(payment.dueDate) : 'Indefinido'}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  style={{
                    color: getLabelColorStatus(payment).color,
                  }}
                >
                  {payment.status}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {payment.boleto?.link ? (
                  <Tooltip title="Link do boleto">
                    <LinkMaterial
                      href={payment.boleto.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton size="small">
                        <DescriptionOutlinedIcon />
                      </IconButton>
                    </LinkMaterial>
                  </Tooltip>
                ) : null}
                {payment.checkoutLink ? (
                  <Tooltip title="Link de pagamento">
                    <LinkMaterial
                      href={payment.checkoutLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton size="small">
                        <LinkIcon />
                      </IconButton>
                    </LinkMaterial>
                  </Tooltip>
                ) : null}
                <IconButton onClick={() => handleEditPayment({ editIndex: index })}>
                  <EditOutlinedIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <IconButton onClick={() => handleDeletePayment({ deleteIndex: index })}>
                  <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          {payments.length === 0 && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography>Não há nenhum pagamento neste pedido</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default memo(PaymentsTable);
