import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import FilterSelectOptions from '../Common/FilterSelectOptions';
import SearchField from '../Common/SearchField';
import CompanySelectOptions from '../Common/CompanySelectOptions';

const useStyles = makeStyles((theme) => ({
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

function TopBarEntries({
  filterOrderCompany,
  setFilterOrderCompany,
  filterOrderStatus,
  setFilterOrderStatus,
  submitSearchOrders,
}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" className={classes.contrastText}>
            Entradas de Produtos
          </Typography>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <CompanySelectOptions
                inputLabel="Empresa"
                filtersOptions={filterOrderCompany}
                setFiltersOptions={setFilterOrderCompany}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Situação do pedido"
                filtersOptions={filterOrderStatus}
                setFiltersOptions={setFilterOrderStatus}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearchOrders} labelSearch="Pesquisar pedido" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarEntries);
