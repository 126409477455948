import {
  Box,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SimpleImage from '../../../../Common/SimpleImage';
import CustomPagination from '../../../../Common/CustomPagination';
import SearchField from '../../../../Common/SearchField';
import { getProducts } from '../../../../../services/melhorGestao/products';
import SimpleLinearProgress from '../../../../Common/SimpleLinearProgress';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 40,
  },
  nameCell: {
    width: 180,
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
  },
  searchTopRight: {
    position: 'fixed',
    top: 20,
  },
  tableRowEmptyStock: {
    backgroundColor: '#fff2f2',
    '&:hover': {
      backgroundColor: '#ffefef',
    },
    cursor: 'pointer',
  },
  tableRowProduct: {
    cursor: 'pointer',
  },
  tableColumnCash: {
    width: 90,
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
}));

function TableRelatedProductsModal({
  productsList,
  countProducts,
  handleChangePage,
  page,
  limit,
  alreadySelectedProducts,
  handleConfirmSelect,
  onClose,
  submitSearchProducts,
  loadingProductsList,
}) {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    const getSelectedProductsData = async () => {
      const selectedProductsList = await getProducts({
        productsIds: alreadySelectedProducts,
        limit: alreadySelectedProducts.length,
        allProducts: true,
      });
      setSelectedProducts(
        selectedProductsList.map((product) => ({
          productId: product.productId,
          technicalName: product.technicalName,
          stock: product.stock - product.reservedStock,
          imageSrc: product.images && product.images.length > 0 ? product.images[0].src : null,
          localization: product.localization,
        })),
      );
    };
    if (alreadySelectedProducts?.length) {
      getSelectedProductsData();
    }
  }, [alreadySelectedProducts]);

  const handleSelectProduct = (product) => {
    setSelectedProducts((alreadySelecteds) => {
      const productIndex = alreadySelecteds.findIndex(
        (selectedProduct) => selectedProduct.productId === product.productId,
      );

      if (productIndex >= 0) {
        return alreadySelecteds.map((selectedProduct, index) =>
          index === productIndex ? product : selectedProduct,
        );
      }
      return [...alreadySelecteds, product];
    });
  };

  const handleUnselectProduct = (product) => {
    const newSelectedProducts = selectedProducts.filter(
      (selectedProduct) => selectedProduct.productId !== product.productId,
    );

    const reorderedProducts = newSelectedProducts.map((newSelectedProduct, index) => ({
      ...newSelectedProduct,
      positionOnList: index,
    }));

    setSelectedProducts(reorderedProducts);
  };

  const checkSelectedProduct = useCallback(
    (product) => {
      let isSelected = false;
      selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.productId === product.productId) {
          isSelected = true;
        }
      });
      return isSelected;
    },
    [selectedProducts],
  );

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Produtos selecionados:</Typography>
        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell} align="center">
                  SKU
                </TableCell>
                <TableCell className={classes.smallCell} align="center">
                  Imagem
                </TableCell>
                <TableCell className={classes.nameCell}>Nome</TableCell>
                <TableCell className={classes.tableColumnCash} align="center">
                  Estoque
                </TableCell>
                <TableCell className={classes.smallCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((selectedProduct) => (
                <TableRow key={selectedProduct.productId}>
                  <TableCell align="center">{selectedProduct.productId}</TableCell>
                  <TableCell align="center">
                    {selectedProduct.imageSrc ? (
                      <SimpleImage
                        src={selectedProduct.imageSrc}
                        height={50}
                        width={50}
                        alt={selectedProduct.technicalName}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{selectedProduct.technicalName}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{selectedProduct.stock}</Typography>
                  </TableCell>
                  <TableCell>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => handleUnselectProduct(selectedProduct)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}

              {selectedProducts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <SimpleLinearProgress loading={loadingProductsList} />
                    {!loadingProductsList ? (
                      <Typography>Nenhum produto selecionado</Typography>
                    ) : null}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Selecionar produtos:</Typography>

        <Paper className={classes.paperTable}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.smallCell} align="center">
                    SKU
                  </TableCell>
                  <TableCell className={classes.smallCell} align="center">
                    Imagem
                  </TableCell>
                  <TableCell className={classes.nameCell}>Nome</TableCell>
                  <TableCell>Local</TableCell>
                  <TableCell>Estoque</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsList.map((product) => (
                  <TableRow
                    className={
                      product.stock > 0 ? classes.tableRowProduct : classes.tableRowEmptyStock
                    }
                    key={product.productId}
                    selected={!!checkSelectedProduct(product)}
                    onClick={() => {
                      handleSelectProduct(product);
                    }}
                  >
                    <TableCell align="center">{product.productId}</TableCell>
                    <TableCell align="center">
                      {product.imageSrc ? (
                        <SimpleImage
                          src={product.imageSrc}
                          height={50}
                          width={50}
                          alt={product.name}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="caption">{product.technicalName}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{product.localization}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{product.stock}</Typography>
                    </TableCell>
                  </TableRow>
                ))}

                {productsList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography>Nenhum produto para exibir</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomPagination
                      page={page}
                      total={countProducts}
                      limit={limit}
                      handleChangePage={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      <Box position="relative" right="235px">
        <Box className={classes.searchTopRight}>
          <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar produto" />
        </Box>
      </Box>

      <Box position="relative" right={selectedProducts.length > 0 ? '105px' : '61px'}>
        <Box className={classes.fabRight}>
          <Fab
            variant="extended"
            color={selectedProducts.length > 0 ? 'primary' : 'default'}
            onClick={() => {
              handleConfirmSelect(selectedProducts.map((product) => product.productId));
              onClose();
            }}
          >
            {selectedProducts.length > 0 ? 'Inserir itens' : 'Continuar'}
          </Fab>
        </Box>
      </Box>
    </Grid>
  );
}

export default memo(TableRelatedProductsModal);
