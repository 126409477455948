import { Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';

function StockBlock({ product, filterByCompany, companiesConfigurations }) {
  let { stocks } = product;

  if (filterByCompany) {
    stocks = product.stocks.filter((stock) => {
      if (typeof filterByCompany === 'string') {
        return stock.company?._id === filterByCompany;
      }
      if (typeof filterByCompany === 'object') {
        return stock.company?._id === filterByCompany?._id;
      }
      return true;
    });
  } else if (companiesConfigurations) {
    stocks = product.stocks.filter((stock) => {
      const company = companiesConfigurations.find(
        (findCompany) => findCompany._id === stock.company?._id,
      );

      if (company) {
        return company.active;
      }
      return true;
    });
  }

  return (
    <>
      <Tooltip
        title={
          <>
            <Typography>Estoque Mínimo: {product.minimumStock}</Typography>
            <Typography>Estoque Máximo: {product.maximumStock}</Typography>
            <Typography>Estoque: {product.stock}</Typography>
            <Typography>Reservado: {product.reservedStock}</Typography>
            <Typography>Disponível: {product.stock - product.reservedStock}</Typography>
          </>
        }
      >
        <Typography style={{ fontSize: 13 }}>{product.stock - product.reservedStock}</Typography>
      </Tooltip>
      <Grid container direction="column">
        {stocks &&
          stocks.map((stock) => (
            <Grid
              item
              key={stock._id}
              style={stock.quantity <= 0 ? { color: '#ff0000' } : { color: '#007000' }}
            >
              <Tooltip
                title={
                  <>
                    <Typography>Estoque: {stock.quantity}</Typography>
                    <Typography>Reservado: {stock.reserved}</Typography>
                  </>
                }
              >
                <Typography style={{ fontSize: 13 }}>
                  {stock.quantity}
                  {stock.company?.name
                    ? ` (${stock.company.name.split(' ')[0].substring(0, 1)})`
                    : ''}
                </Typography>
              </Tooltip>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default StockBlock;
