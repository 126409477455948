import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import newPhone from '../../../../constant/newPhone';
import { formatPhone, onlyNumbersValue } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

function PhoneDialog({ openDialogPhone, handleCloseDialogPhone, handleAddPhone }) {
  const [phone, setPhone] = useState(newPhone);

  const handleConfirm = useCallback(() => {
    handleAddPhone({ phone });
    return handleCloseDialogPhone();
  }, [phone]);

  const handleChange = (event) => {
    let fieldValue = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === 'number') {
      fieldValue = onlyNumbersValue(event.target.value);
    }

    setPhone((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      maxWidth="sm"
      content={
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="column">
              <CustomInput
                label="Número"
                value={formatPhone(phone.number)}
                onChange={handleChange}
                name="number"
              />
            </Grid>
          </Grid>
        </Grid>
      }
      dialogTitle="Adicionar telefone"
      handleCancelButton={handleCloseDialogPhone}
      handleClose={handleCloseDialogPhone}
      handleConfirmButton={handleConfirm}
      openDialog={openDialogPhone}
    />
  );
}

export default PhoneDialog;
