import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../Common/SimpleDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { updateCheque } from '../../../services/melhorGestao/cheques';
import { formatHandleError, numberToRealWithPeriod } from '../../../helpers/formatData';

function MassProcessCheques({
  openDialogProcessCheques,
  handleCloseDialogProcessCheques,
  checkedCheques,
  handleRefreshAll,
}) {
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [chequesList, setChequesList] = useState([]);

  const getUpdatedChequeList = useCallback(async () => {
    try {
      setLoadingProcessing(true);

      const responseCheques = await Axios.patch('/financial/cheques', {
        ids: checkedCheques,
      });
      const cheques = responseCheques.data;

      setLoadingProcessing(false);
      handleRefreshAll();
      return setChequesList(cheques);
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar obter a lista de produtos',
        error,
      });
    }
  }, [checkedCheques]);

  useEffect(() => {
    getUpdatedChequeList();
  }, []);

  const [openDialogDepositCheques, setOpenDialogDepositCheques] = useState();
  const handleOpenDialogDepositCheques = () => setOpenDialogDepositCheques(true);
  const handleCloseDialogDepositCheques = () => setOpenDialogDepositCheques(false);

  const [bank, setBank] = useState('');

  const handleConfirmDepositCheques = async () => {
    handleCloseDialogDepositCheques();
    setLoadingProcessing(true);

    await checkedCheques.reduce(async (promise, chequeId) => {
      await promise;

      try {
        const getCheque = chequesList.find((cheque) => cheque.chequeId === chequeId);

        if (
          getCheque &&
          getCheque.movements.length > 0 &&
          (getCheque.situation === 'Recebido' || getCheque.situation === 'Devolvido')
        ) {
          updateCheque({
            setSnackbar,
            cheque: {
              chequeId,
              situation: 'Depositado',
              movements: [
                ...getCheque.movements,
                {
                  finance: getCheque.movements[0].finance,
                  from: getCheque.movements[0].to,
                  to: getCheque.movements[0].to,
                  reason: 'Depositado',
                  details: `Cheque depositado no banco ${bank}`,
                  occurredDate: new Date(),
                },
              ],
            },
          });
        }
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao atualizar o cheque',
          error,
        });
      }
    }, Promise.resolve());

    setLoadingProcessing(false);
    handleRefreshAll();
  };

  return (
    <SimpleDialog
      content={
        <>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {chequesList.length > 0
                      ? 'Altera a situação do cheque para depositado'
                      : 'Nenhum cheque foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!(chequesList.length > 0)}
                    onClick={handleOpenDialogDepositCheques}
                  >
                    Informar depósito
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          {openDialogDepositCheques ? (
            <SimpleDialog
              maxWidth="sm"
              content={
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>
                      {chequesList.length === 1
                        ? `Selecionado: ${chequesList.length} cheque`
                        : `Selecionados: ${chequesList.length} cheques`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Valor total: R${' '}
                      {numberToRealWithPeriod(
                        chequesList
                          .map((cheque) => cheque.value)
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <FormControl fullWidth variant="standard">
                        <InputLabel>Banco</InputLabel>
                        <Select onChange={(event) => setBank(event.target.value)} value={bank}>
                          <MenuItem value="Siccob">Siccob</MenuItem>
                          <MenuItem value="Bradesco">Bradesco</MenuItem>
                          <MenuItem value="Caixa">Caixa</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              }
              dialogTitle="Informar depósito"
              dialogText="Confirme o depósito dos cheques selecionados, esta ação não é reversível, após o depósito do cheque a movimentação será encerrada. Serão depositados apenas cheques com a situação 'Recebido'."
              handleClose={handleCloseDialogDepositCheques}
              openDialog={openDialogDepositCheques}
              cancelButtonText="Cancelar"
              confirmButtonText="Confirmar"
              handleCancelButton={handleCloseDialogDepositCheques}
              handleConfirmButton={handleConfirmDepositCheques}
              disableConfirmButton={!bank}
            />
          ) : null}
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <SimpleBackdrop loading={loadingProcessing} />
        </>
      }
      dialogTitle="Cheques selecionados"
      dialogText={
        chequesList.length === 1
          ? `Foi selecionado ${chequesList.length} cheque.`
          : `Foram selecionados ${chequesList.length} cheques.`
      }
      handleClose={handleCloseDialogProcessCheques}
      openDialog={openDialogProcessCheques}
    />
  );
}

export default MassProcessCheques;
