import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const getCategoriesByType = async ({ type = '', setSnackbar = () => {} }) => {
  try {
    const response = await Axios.get('/payments/categories', {
      params: {
        limit: 2000,
        type,
      },
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar as categorias',
      error,
    });

    return null;
  }
};

export const searchPayments = async ({
  setLoading,
  setCountPayments = () => {},
  page,
  limit,
  wallets,
  searchTextSubmited,
  startDate,
  endDate,
  payed,
  type,
  setSnackbar = () => {},
}) => {
  try {
    setLoading(true);

    const response = await Axios.get(`/payments/`, {
      params: {
        skip: page * limit - limit,
        limit,
        wallets,
        searchText: searchTextSubmited,
        startDate,
        endDate,
        payed,
        type,
      },
    });
    const { totalResults, payments } = response.data;

    setCountPayments(totalResults);
    return payments;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar as categorias',
      error,
    });

    return null;
  }
};

export const getPaymentsByOrderId = async (orderId) => {
  try {
    const response = await Axios.get(`/payments/order/${orderId}`);
    const payments = response.data;
    return payments;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao buscar um pagamento');
    return [];
  }
};

export const getPaymentsResume = async ({
  setLoadingPaymentsResume,
  setFiltersPaymentsStatus,
  filtersPaymentsStatus,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingPaymentsResume(true);
    const response = await Axios.get(`/payments/resume`, {
      params: {
        filtersPaymentsStatus,
      },
    });

    setFiltersPaymentsStatus(response.data);
    return setLoadingPaymentsResume(false);
  } catch (error) {
    setLoadingPaymentsResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar o resumo dos pagamentos',
      error,
    });
  }
};
