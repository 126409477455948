import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import ChangeOrderStatusButton from './ChangeOrderStatusButton';
import PrintOrderIconButton from './PrintOrderIconButton';

const useStyles = makeStyles((theme) => ({
  submitArea: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: 662,
    height: 70,
  },
  containerSubmitArea: {
    height: 70,
  },
}));

function SubmitArea({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  formChanged,
  selectedCompany,
  seller,
  totalPaid,
  devolutions,
  handleSaveForm,
  loadingUpdateOrder,
  confirmCloseProductDetails,
  closeOrderDetails,
  handleOpenCheckByWeight,
  submitSearchOrders,
  setFilterOrderStatus,
  setFilterOrderSource,
  setFilterOrderCompany,
  setSnackbar,
}) {
  const classes = useStyles();

  const [blockButtonStatus, setBlockButton] = useState(true);
  const [blockButtonStatusMessage, setBlockButtonStatusMessage] = useState('');

  useEffect(() => {
    if (formChanged || !orderForm.orderId) {
      setBlockButtonStatusMessage('Salve o pedido');
      return setBlockButton(true);
    }
    if (!orderForm.unblockDiscount) {
      setBlockButtonStatusMessage('Pedido bloqueado');
      return setBlockButton(true);
    }
    if (orderForm.status === 'Pedido criado') {
      if (orderForm.payments?.length === 0) {
        setBlockButtonStatusMessage('Adicione pagamento');
        return setBlockButton(true);
      }

      const valueReceived = orderForm.payments
        .filter((payment) => payment.type === 'Recebimento')
        .reduce((total, payment) => total + payment.value, 0);

      if (parseFloat(valueReceived.toFixed(2)) < parseFloat(orderForm.orderTotal.toFixed(2))) {
        setBlockButtonStatusMessage('Pagamento insuficiente');
        return setBlockButton(true);
      }
      if (
        !(orderForm.source === 'Loja física' || orderForm.source === 'Telemarketing') &&
        parseFloat(totalPaid.toFixed(2)) < parseFloat(orderForm.orderTotal.toFixed(2))
      ) {
        setBlockButtonStatusMessage('Total pago insuficiente');
        return setBlockButton(true);
      }
    }
    setBlockButtonStatusMessage('');
    return setBlockButton(false);
  }, [orderForm, formChanged, totalPaid]);

  const [blockSave, setBlockSave] = useState(true);
  const [blockSaveMessage, setBlockSaveMessage] = useState('');

  useEffect(() => {
    if (!formChanged) {
      setBlockSaveMessage('O pedido não teve alterações');
      return setBlockSave(true);
    }
    if (!orderForm.company) {
      setBlockSaveMessage('Escolha a empresa que fará a venda');
      return setBlockSave(true);
    }
    if (!orderForm.source) {
      setBlockSaveMessage('Escolha a origem do pedido');
      return setBlockSave(true);
    }
    if (!orderForm.customer || !orderForm.customer.name) {
      setBlockSaveMessage('Escolha o cliente');
      return setBlockSave(true);
    }
    if (!orderForm.shipping.method || !orderForm.shipping.company) {
      setBlockSaveMessage('Escolha uma forma de entrega');
      return setBlockSave(true);
    }
    setBlockSaveMessage('Salvar o pedido');
    return setBlockSave(false);
  }, [orderForm, formChanged]);

  return (
    <Paper className={classes.submitArea} elevation={5}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.containerSubmitArea}
      >
        <Grid item>
          <Box marginLeft={2}>
            <Button variant="contained" color="default" onClick={confirmCloseProductDetails}>
              {formChanged ? 'Desfazer alterações' : 'Fechar'}
            </Button>
          </Box>
        </Grid>
        {blockButtonStatus ? (
          <Typography style={{ color: '#ff2222' }}>{blockButtonStatusMessage}</Typography>
        ) : (
          <Grid item>
            <ChangeOrderStatusButton
              orderForm={orderForm}
              handleSaveForm={handleSaveForm}
              formChanged={formChanged}
              setOrderForm={setOrderForm}
              setInitialOrderForm={setInitialOrderForm}
              handleOpenCheckByWeight={handleOpenCheckByWeight}
              submitSearchOrders={submitSearchOrders}
              setFilterOrderStatus={setFilterOrderStatus}
              setFilterOrderSource={setFilterOrderSource}
              setFilterOrderCompany={setFilterOrderCompany}
              closeOrderDetails={closeOrderDetails}
            />
          </Grid>
        )}
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <PrintOrderIconButton
                orderForm={orderForm}
                formChanged={formChanged}
                selectedCompany={selectedCompany}
                seller={seller}
                devolutions={devolutions}
                setSnackbar={setSnackbar}
              />
            </Grid>
            <Tooltip title={<Typography align="center">{blockSaveMessage}</Typography>}>
              <Grid item>
                <Box marginRight={2}>
                  <LoadingButton
                    loading={loadingUpdateOrder}
                    variant="contained"
                    color="primary"
                    disabled={blockSave}
                    onClick={handleSaveForm}
                  >
                    Salvar
                  </LoadingButton>
                </Box>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default SubmitArea;
