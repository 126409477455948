import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import ShopeeEditTitle from './ShopeeEditTitle';
import ShopeeCategoryDialog from './ShopeeCategoryDialog';
import CustomInput from '../../../CustomInput';
import { formatHandleError, numberToRealWithPeriod } from '../../../../helpers/formatData';
import ShopeeBrandsAutocomplete from './ShopeeBrandsAutocomplete';
import CompanySelectOptions from '../../../Common/CompanySelectOptions';
import SimpleDialog from '../../../Common/SimpleDialog';
import PreviewVideoForProduct from '../../../Products/Details/InformationTab/PreviewVideoForProduct';
import { getShopeePrice } from '../../../../services/melhorGestao/shopee';

function ShopeeDetails({
  adForm,
  setAdForm,
  productForm,
  setSnackbar,
  publishOnShopee,
  setPublishOnShopee,
}) {
  const [openDialogDescription, setOpenDialogDescription] = useState(false);
  const handleOpenDialogDescription = () => setOpenDialogDescription(true);
  const handleCloseDialogDescription = () => setOpenDialogDescription(false);

  const [openDialogVideoProduct, setOpenDialogVideoProduct] = useState(false);
  const handleOpenDialogVideoProduct = () => setOpenDialogVideoProduct(true);
  const handleCloseDialogVideoProduct = () => setOpenDialogVideoProduct(false);

  const [validUrl, setValidUrl] = useState(true);
  const [loadingValidatorUrl, setLoadingValidatorUrl] = useState(false);

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setAdForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const [filterCompaniesByIds, setFilterCompaniesByIds] = useState([]);
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/shopee/configs');
        console.log(response.data);

        const shActiveConfigs = response.data.filter((mlConfig) => mlConfig.active);
        const shCompaniesIds = shActiveConfigs.map((mlConfig) => mlConfig.company);

        setFilterCompaniesByIds(shCompaniesIds);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: `Algum erro ocorreu ao buscar configurações do Shopee`,
          error,
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!adForm.shopeeProductId) {
      const companies = [];

      filterOrderCompany.forEach((company) => {
        if (company.active) {
          companies.push(company.id);
        }
      });

      setAdForm((oldFields) => ({
        ...oldFields,
        companies,
      }));
    }
  }, [adForm.shopeeProductId, filterOrderCompany]);

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  const handleChangeShopeeCategory = (category) => {
    setAdForm((oldFields) => ({
      ...oldFields,
      category,
    }));

    setOpenCategoryDialog(false);
  };

  const handleChangeShopeeBrand = (_, brand) => {
    if (brand) {
      setAdForm((oldFields) => ({
        ...oldFields,
        brand,
      }));
    } else {
      setAdForm((oldFields) => ({
        ...oldFields,
        brand: {
          id: 0,
          name: '',
        },
      }));
    }
  };

  const [offerPrice, setOfferPrice] = useState(adForm.offerPrice || false);

  useEffect(() => {
    const fetchData = async () => {
      if (adForm.quantity > 0) {
        const newPrice = await getShopeePrice({
          productId: productForm.productId,
          quantity: adForm.quantity,
          offerPrice,
          setSnackbar,
        });

        setAdForm((oldFields) => ({
          ...oldFields,
          price: newPrice,
          offerPrice,
        }));
      } else {
        setAdForm((oldFields) => ({
          ...oldFields,
          price: 0,
          offerPrice,
        }));
      }
    };
    fetchData();
  }, [offerPrice, adForm.quantity]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Shopee</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnShopee}
                  onClick={() => setPublishOnShopee(!publishOnShopee)}
                  checked={publishOnShopee}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {publishOnShopee ? (
        <>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Tooltip
                  title={
                    <Typography align="center">
                      Digite a quantidade deste produto para compor no anúncio.
                    </Typography>
                  }
                >
                  <Box>
                    <CustomInput
                      label="Kit com"
                      value={adForm.quantity}
                      onChange={(event) =>
                        setAdForm((oldFields) => ({
                          ...oldFields,
                          quantity: event.target.value,
                        }))
                      }
                      color="secondary"
                      number
                    />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <ShopeeEditTitle
                  productForm={productForm}
                  adForm={adForm}
                  setAdForm={setAdForm}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {filterCompaniesByIds.length > 0 ? (
                  <CompanySelectOptions
                    width={220}
                    inputLabel="Empresa/Conta"
                    variant="standard"
                    autoConfirm
                    filtersOptions={filterOrderCompany}
                    setFiltersOptions={setFilterOrderCompany}
                    filterCompaniesByIds={filterCompaniesByIds}
                    selectedCompaniesByIds={adForm.companies}
                    disabled={!!adForm.shopeeProductId}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={220} height={45} />
                )}
              </Grid>
              <Grid item>
                <Box width={220}>
                  <ShopeeBrandsAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={handleChangeShopeeBrand}
                    selectedBrand={adForm.brand}
                    companyId={1}
                    categoryId={adForm?.category?.id}
                    variant="standard"
                    disabled={!adForm?.category?.id}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {adForm?.name
                        ? 'Selecione a categoria correspondente a este produto'
                        : 'Para selecionar a categoria preencha o título'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenCategoryDialog(true)}
                      disabled={!adForm?.name}
                    >
                      Categoria
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleOpenDialogDescription}>
                  Descrição
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleOpenDialogVideoProduct}>
                  Vídeo
                </Button>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      Preço do produto a ser considerado mais taxas
                    </Typography>
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        value={adForm.offerPrice}
                        onChange={() => setOfferPrice(!offerPrice)}
                        checked={adForm.offerPrice}
                        color="primary"
                      />
                    }
                    label="Preço de oferta"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Categoria:</Typography>
                  </Grid>
                  <Grid item>
                    {adForm.category && adForm.category.name !== '' ? (
                      <Typography variant="caption">{adForm.category.name}</Typography>
                    ) : (
                      <Typography variant="caption" color="error">
                        Não selecionada
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item>
                    <Typography variant="h6">Preço do anúncio:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">R$ {numberToRealWithPeriod(adForm.price)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}
      {openCategoryDialog ? (
        <ShopeeCategoryDialog
          openDialog={openCategoryDialog}
          handleClose={() => setOpenCategoryDialog(false)}
          dialogTitle="Selecione a categoria mais indicada"
          searchTerm={adForm.name ? adForm.name.split(' ')[0] : ''}
          handleClick={handleChangeShopeeCategory}
        />
      ) : null}
      {openDialogDescription ? (
        <SimpleDialog
          content={
            <Box width={600}>
              <CustomInput
                name="description"
                label="Descrição"
                rows={20}
                value={adForm.description}
                onChange={handleChange}
              />
            </Box>
          }
          dialogTitle="Alterar descrição"
          dialogText="Altere abaixo a descrição do produto"
          handleClose={handleCloseDialogDescription}
          openDialog={openDialogDescription}
        />
      ) : null}
      {openDialogVideoProduct && (
        <SimpleDialog
          content={
            <Box>
              <PreviewVideoForProduct
                videoLink={adForm.videoLink}
                validURL={validUrl}
                setValidUrl={setValidUrl}
                setLoadingValidatorUrl={setLoadingValidatorUrl}
              />
              <CustomInput
                name="videoLink"
                label="Altere ou adicione abaixo o link do vídeo"
                value={adForm.videoLink || ''}
                error={!validUrl}
                onChange={handleChange}
                loading={loadingValidatorUrl}
                disabled={!!adForm.mlb}
              />
            </Box>
          }
          dialogTitle="Vídeo do produto"
          dialogText={adForm.mlb ? 'O vídeo pode ser alterado no produto' : ''}
          handleClose={handleCloseDialogVideoProduct}
          openDialog={openDialogVideoProduct}
        />
      )}
    </Grid>
  );
}

export default ShopeeDetails;
