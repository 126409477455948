import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import React from 'react';
import CustomDivider from '../../../CustomDivider';

function OthersMakrteplacesSwitchs({
  publishOnSkyhub,
  setPublishOnSkyhub,
  publishOnMagazineLuiza,
  setPublishOnMagazineLuiza,
  publishOnViaVarejo,
  setPublishOnViaVarejo,
  publishOnGoogleShopping,
  setPublishOnGoogleShopping,
  hiddenDivider,
}) {
  return (
    <>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Americanas</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnSkyhub}
                  onClick={() => setPublishOnSkyhub(!publishOnSkyhub)}
                  checked={publishOnSkyhub}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {!hiddenDivider ? <CustomDivider /> : null}
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Magazine Luiza</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnMagazineLuiza}
                  onClick={() => setPublishOnMagazineLuiza(!publishOnMagazineLuiza)}
                  checked={publishOnMagazineLuiza}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {!hiddenDivider ? <CustomDivider /> : null}
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Via Varejo</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnViaVarejo}
                  onClick={() => setPublishOnViaVarejo(!publishOnViaVarejo)}
                  checked={publishOnViaVarejo}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {!hiddenDivider ? <CustomDivider /> : null}
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Google Shopping</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={publishOnGoogleShopping}
                  onClick={() => setPublishOnGoogleShopping(!publishOnGoogleShopping)}
                  checked={publishOnGoogleShopping}
                  color="primary"
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default OthersMakrteplacesSwitchs;
