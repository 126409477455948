import React from 'react';
import { Box, Chip, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatDate, numberToReal } from '../../../../helpers/formatData';
import LimitTypography from '../../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  boxPositionRelative: {
    position: 'relative',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxDeleteIcon: {
    position: 'absolute',
    bottom: 150,
    right: 0,
  },
  gridHeight: {
    height: 48,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 136,
    paddingBottom: '0px!important',
  },
}));

function OurProductOfferPaper({ productForm, setProductForm, productOffer, productOfferIndex }) {
  const classes = useStyles();

  const productOfferPrice = productForm.price * (1 - productOffer.discountPercentage / 100);

  const handleDeleteOffer = () => {
    const offers = [];

    productForm.offers.forEach((offer, index) => {
      if (index !== productOfferIndex) {
        offers.push(offer);
      }
    });

    let newOfferPrice = {};
    const currentOffers = [];

    offers.forEach((offer) => {
      if (offer.offerFor !== 'payment') {
        if (new Date(offer.startDate) <= new Date() && new Date(offer.endDate) >= new Date()) {
          currentOffers.push(offer);
        }
      }
    });

    if (currentOffers.length > 0) {
      const currentOffer = currentOffers.reduce((a, b) =>
        a.discountPercentage > b.discountPercentage ? a : b
      );

      newOfferPrice = {
        offer: true,
        offerPrice: productForm.price * (1 - currentOffer.discountPercentage / 100),
      };
    } else {
      newOfferPrice = {
        offer: false,
        offerPrice: 0,
      };
    }

    return setProductForm((oldFields) => ({
      ...oldFields,
      ...newOfferPrice,
      offers,
    }));
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">{productOffer.name}</Typography>
            </Grid>
            <Grid item>
              <LimitTypography
                text={
                  productOffer.offerForCode
                    ? `${productOffer.offerForCode} - ${productOffer.description}`
                    : productOffer.description
                }
                maxLength={55}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço de</Typography>
                            </Grid>
                            <Grid container className={classes.gridHeight} alignItems="center">
                              <Typography variant="caption">R$</Typography>
                              <Typography>{numberToReal(productForm.price)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço por</Typography>
                            </Grid>
                            <Grid container className={classes.gridHeight} alignItems="center">
                              <Typography variant="caption">R$</Typography>
                              <Typography>{numberToReal(productOfferPrice)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Tipo</Typography>
                            </Grid>
                            <Grid container className={classes.gridHeight} alignItems="center">
                              <Typography>{productOffer.type}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={productForm.status === 'active' ? 'primary' : 'disabled'}
                                />
                                <Typography variant="caption" color="textPrimary">
                                  {productForm.status === 'active' ? 'Ativo' : 'Inativo'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      <Chip label={`Quantidade mínima: ${productOffer.quantity}`} />
                    </Grid>
                    <Grid item>
                      <Chip label={`Início: ${formatDate(productOffer.startDate)}`} />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={`Término: ${
                          productOffer.endDate
                            ? formatDate(productOffer.endDate)
                            : 'Data indefinida'
                        }`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" style={{ color: '#ff0000' }}>
                -{productOffer.discountPercentage}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        {!productOffer.offerId && (
          <Box className={classes.boxDeleteIcon}>
            <Tooltip
              title={
                <Typography align="center">
                  Excluir permanentemente a promoção do produto
                </Typography>
              }
            >
              <IconButton size="small" onClick={() => handleDeleteOffer()}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export default OurProductOfferPaper;
