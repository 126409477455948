import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../../../../CustomInput';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function DiscountsFromPrice({
  priceValue,
  selectedProduct,
  handleChangePhysicalStoreDiscount,
  handleChangePhysicalDiscountRate,
  blockFields,
}) {
  const classes = useStyles();

  const [priceValueWithDiscount, setPriceValueWithDiscount] = useState(0);
  const [physicalStoreDiscount, setPhysicalStoreDiscount] = useState(0);
  const [physicalStoreDiscountPercentage, setPhysicalStoreDiscountPercentage] = useState(0);

  useEffect(() => {
    if (priceValue) {
      const physicalDiscountRate = selectedProduct.physicalDiscountRate || 0;

      const formatedValue = priceValue * (physicalDiscountRate / 100);
      setPriceValueWithDiscount(priceValue - formatedValue);

      handleChangePhysicalStoreDiscount(formatedValue);
      setPhysicalStoreDiscount(parseFloat(formatedValue.toFixed(2)));

      handleChangePhysicalDiscountRate(physicalDiscountRate);
      setPhysicalStoreDiscountPercentage(physicalDiscountRate);
    }
  }, [priceValue]);

  const changePhysicalStoreDiscount = (event) => {
    const { value } = event.target;
    handleChangePhysicalStoreDiscount(parseFloat(value) || 0);

    if (priceValue) {
      setPriceValueWithDiscount(priceValue - value);

      const percentage = (1 - (1 - value / priceValue)) * 100;
      setPhysicalStoreDiscountPercentage(Math.round(percentage));
      handleChangePhysicalDiscountRate(Math.round(percentage));
    } else {
      setPhysicalStoreDiscountPercentage(0);
    }

    setPhysicalStoreDiscount(value);
  };

  const changePhysicalStoreDiscountPercentage = (event) => {
    const { value } = event.target;
    handleChangePhysicalDiscountRate(parseInt(value, 10) || 0);

    if (priceValue) {
      const formatedValue = priceValue * (value / 100);
      handleChangePhysicalStoreDiscount(formatedValue);

      setPriceValueWithDiscount(priceValue - formatedValue);
      setPhysicalStoreDiscount(parseFloat(formatedValue.toFixed(2)));
    }

    setPhysicalStoreDiscountPercentage(value);
  };

  return (
    <Paper>
      <Paper>
        <Box padding={2}>
          <Box marginBottom={1}>
            <Typography variant="h3">Descontos no preço de venda</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Grid container alignItems="flex-end" spacing={0.5}>
                <Grid item xs={6}>
                  <CustomInput
                    label="Desconto loja física"
                    value={physicalStoreDiscount}
                    onChange={changePhysicalStoreDiscount}
                    size="medium"
                    variant="standard"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomInput
                    value={physicalStoreDiscountPercentage}
                    onChange={changePhysicalStoreDiscountPercentage}
                    size="medium"
                    variant="standard"
                    color="secondary"
                    InputEndAdornment="%"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="caption">Venda loja física</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h1">{`R$ ${numberToRealWithPeriod(priceValueWithDiscount)}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Paper>
  );
}
export default memo(DiscountsFromPrice);
