import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../../Common/SimpleDialog';

function ChequeDevolutionDialog({
  openDialogChequeDevolution,
  handleCloseDialogChequeDevolution,
  handleCloseFinanceDialog,
  finance,
  setPayed,
  setSnackbar,
  handleRefresh,
}) {
  const [chequeDevolutionReason, setChequeDevolutionReason] = useState('Cheque devolvido');

  const handleCreateChequeDevolution = async () => {
    try {
      await Axios.post('/financial/cheques/devolution', {
        financeId: finance.financeId,
        reason: chequeDevolutionReason,
      });

      setPayed('Não pago');
      handleCloseDialogChequeDevolution();
      handleCloseFinanceDialog();
      handleRefresh();

      setSnackbar({
        message: 'Cheque devolvido com sucesso',
        open: true,
        type: 'success',
      });
    } catch (error) {
      let errorObject = {};
      let errorMessage = 'Algum erro ocorreu ao devolver o cheque';

      if (error.response && error.response.data) {
        errorObject = error.response.data;
      }
      if (errorObject.message) {
        errorMessage = errorObject.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  return (
    <SimpleDialog
      maxWidth="xs"
      content={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>Selecione um motivo</Typography>
          </Grid>
          <Grid item>
            <Box width={400}>
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  onChange={(event) => setChequeDevolutionReason(event.target.value)}
                  value={chequeDevolutionReason}
                >
                  <MenuItem value="Cheque devolvido">Cheque devolvido</MenuItem>
                  <MenuItem value="Cheque sustado">Cheque sustado</MenuItem>
                  <MenuItem value="Divergência de dados">Divergência de dados</MenuItem>
                  <MenuItem value="Outro">Outro</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      }
      dialogTitle="Devolução de cheque"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleConfirmButton={handleCreateChequeDevolution}
      handleClose={handleCloseDialogChequeDevolution}
      openDialog={openDialogChequeDevolution}
    />
  );
}

export default ChequeDevolutionDialog;
