import React from 'react';
import { Page, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';
import Customer from './Customer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingVertical: 30,
    paddingHorizontal: 60,
    flexDirection: 'column',
  },
});

function LayoutPrint({ children, selectedCompany, order, seller }) {
  return (
    <Page size="A4" style={styles.page} orientation="portrait">
      <Title selectedCompany={selectedCompany} order={order} />
      <Customer order={order} seller={seller} />
      {children}
    </Page>
  );
}

export default LayoutPrint;
