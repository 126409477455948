import { Alert, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SimpleDialog from '../../../Common/SimpleDialog';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
}));

function AdResultsDialog({
  openAdResultsDialog,
  handleCloseAdResultsDialog,
  adResultsList,
  productsList,
}) {
  const classes = useStyles();

  return (
    <SimpleDialog
      content={
        <Grid container direction="column" spacing={2}>
          {adResultsList &&
            adResultsList.map((adResults) => (
              <Grid item key={Math.random()}>
                <Paper elevation={3} className={classes.paper}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography>{adResults.platform}</Typography>
                    </Grid>
                    {adResults.createdProductsList && adResults.createdProductsList.length > 0 ? (
                      <Grid item>
                        <Alert severity="success">
                          Produtos publicados: {adResults.createdProductsList.length}
                        </Alert>
                      </Grid>
                    ) : null}
                    {productsList.length - adResults.createdProductsList.length > 0 ? (
                      <Grid item>
                        <Alert severity="info">
                          Produtos não publicados:{' '}
                          {productsList.length - adResults.createdProductsList.length}
                        </Alert>
                      </Grid>
                    ) : null}
                    {adResults.errors &&
                      adResults.errors.map((error) => (
                        <Grid item key={Math.random()}>
                          <Alert severity="error">{error.message}</Alert>
                        </Grid>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            ))}
        </Grid>
      }
      dialogTitle="Resultado da publicação"
      dialogText="Resultado da publicação dos produtos nos Marketplaces"
      handleClose={handleCloseAdResultsDialog}
      openDialog={openAdResultsDialog}
    />
  );
}

export default AdResultsDialog;
