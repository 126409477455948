import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../Common/SimpleDialog';
import { createProduct, updateProduct } from '../../../services/melhorGestao/products';
import InformationTab from './InformationTab';
import AdvertsTab from './AdvertsTab';
import PriceficationTab from './PriceficationTab';
import newProduct from '../../../constant/newProduct';
import getModifiedKeys from '../../../helpers/getModifiedKeys';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import { cleanUpDuplicateSpaces, cleanUpDuplicateSpacesInText } from '../../../helpers/formatData';
import OffersTab from './OffersTab';
import FiscalTab from './FiscalTab';
import PriceAndStock from './PriceAndStockTab';
import CustomDivider from '../../CustomDivider';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
  select: {
    minWidth: 180,
  },
  submitArea: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: 662,
    height: 70,
  },
  containerSubmitArea: {
    height: 70,
  },
  updateImagesArea: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: -150,
    },
    margin: '0px 20px',
  },
  smallTypography: {
    fontSize: '0.85rem',
  },
}));

function ProductDetails({ openProductDetails, selectedProduct = newProduct, closeProductDetails }) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false);
  const [initialProductForm, setInitialProductForm] = useState(selectedProduct);
  const [productForm, setProductForm] = useState(selectedProduct);
  const [formChanged, setFormChanged] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [tabDetails, setTabDetails] = useState('Informações');

  const handleChangeTabDetails = (tab) => setTabDetails(tab);

  useEffect(() => {
    const keys = getModifiedKeys(productForm, initialProductForm, ['offerPrice']);

    if (keys.length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [productForm, initialProductForm]);

  const handleChange = useCallback((event) => {
    let fieldValue = event.target.value;
    const fieldType = event.target.type;
    const fieldName = event.target.name;

    if (fieldType === 'checkbox') {
      if (fieldValue === 'false') {
        fieldValue = true;
      } else {
        fieldValue = false;
      }
    }

    if (fieldName === 'status') {
      return setProductForm((oldFields) => ({
        ...oldFields,
        status: oldFields.status === 'active' ? 'unactive' : 'active',
        active: {
          ...oldFields.active,
          ekoos: oldFields.status !== 'active',
        },
      }));
    }

    if (fieldName === 'active') {
      return setProductForm((oldFields) => ({
        ...oldFields,
        active: {
          ...oldFields.active,
          siteMarkFerragens: !oldFields.active.siteMarkFerragens,
          skyHub: !oldFields.active.siteMarkFerragens,
          mercadoLivre: !oldFields.active.siteMarkFerragens,
        },
      }));
    }

    if (fieldName === 'technicalName') {
      return setProductForm((oldFields) => ({
        ...oldFields,
        technicalName: fieldValue.toUpperCase(),
      }));
    }

    if (fieldName === 'gtin') {
      return setProductForm((oldFields) => ({
        ...oldFields,
        othersIds: {
          ...oldFields.othersIds,
          gtin: fieldValue,
        },
      }));
    }

    if (fieldName === 'composition') {
      return setProductForm((oldFields) => ({
        ...oldFields,
        productInputs: [],
        composition: fieldValue,
      }));
    }

    return setProductForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  }, []);

  const confirmCloseProductDetails = () => {
    if (!formChanged) {
      return closeProductDetails();
    }
    return setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => setConfirmDialogOpen(false);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleSaveForm = async () => {
    if (
      productForm.composition === true &&
      (!productForm.productInputs || productForm.productInputs.length === 0)
    ) {
      return setSnackbar({
        message: 'Um produto composto precisa de ter sua composição escolhida para ser salvo.',
        open: true,
        type: 'error',
      });
    }

    if (productForm.offer && productForm.price < productForm.offerPrice) {
      return setSnackbar({
        message: 'O preço promocional deve ser menor do que o preço normal.',
        open: true,
        type: 'error',
      });
    }

    setLoadingUpdateProduct(true);

    const product = {
      ...newProduct,
      ...productForm,
      name: productForm.name ? cleanUpDuplicateSpaces(productForm.name) : '',
      technicalName: productForm.technicalName
        ? cleanUpDuplicateSpaces(productForm.technicalName)
        : '',
      localization: productForm.localization
        ? cleanUpDuplicateSpaces(productForm.localization)
        : '',
      brand: productForm.brand ? cleanUpDuplicateSpaces(productForm.brand) : '',
      description: productForm.description
        ? cleanUpDuplicateSpacesInText(productForm.description)
        : '',
    };

    if (productForm.productId) {
      const updatedProduct = await updateProduct({ product, setSnackbar });
      if (updatedProduct) {
        setProductForm(updatedProduct);
        setInitialProductForm(updatedProduct);
      }
    } else {
      const createdProduct = await createProduct({ product, setSnackbar });
      if (createdProduct) {
        setProductForm(createdProduct);
        setInitialProductForm(createdProduct);
      }
    }

    return setLoadingUpdateProduct(false);
  };

  return (
    <Drawer anchor="right" open={openProductDetails} onClose={confirmCloseProductDetails}>
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Chip
                  color="primary"
                  label={productForm.productId ? `SKU: ${productForm.productId}` : 'Novo produto'}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={productForm.status === 'active'}
                      onChange={handleChange}
                      checked={productForm.status === 'active'}
                      name="status"
                      color="primary"
                    />
                  }
                  label={<Box className={classes.smallTypography}>Ativo na loja</Box>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={productForm.active.siteMarkFerragens}
                      onChange={handleChange}
                      checked={productForm.active.siteMarkFerragens}
                      name="active"
                      color="primary"
                    />
                  }
                  label={<Box className={classes.smallTypography}>Ativo no site</Box>}
                />
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <FormControl fullWidth variant="filled" size="small">
                      <InputLabel>Exibir</InputLabel>
                      <Select
                        className={classes.select}
                        onChange={(event) => handleChangeTabDetails(event.target.value)}
                        value={tabDetails}
                      >
                        <MenuItem value="Informações">Informações</MenuItem>
                        <MenuItem value="Preço e Estoque">Preço e Estoque</MenuItem>
                        <MenuItem value="Precificação">Precificação</MenuItem>
                        <MenuItem value="Fiscal">Fiscal</MenuItem>
                        <MenuItem value="Promoções">Promoções</MenuItem>
                        <MenuItem value="Anúncios">Anúncios</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            {tabDetails === 'Informações' && (
              <InformationTab
                productForm={productForm}
                handleChange={handleChange}
                setProductForm={setProductForm}
                formChanged={formChanged}
              />
            )}
            {tabDetails === 'Preço e Estoque' && (
              <PriceAndStock
                productForm={productForm}
                setProductForm={setProductForm}
                setSnackbar={setSnackbar}
              />
            )}
            {tabDetails === 'Precificação' && (
              <PriceficationTab productForm={productForm} setProductForm={setProductForm} />
            )}
            {tabDetails === 'Fiscal' && (
              <FiscalTab
                productForm={productForm}
                setProductForm={setProductForm}
                setSnackbar={setSnackbar}
              />
            )}
            {tabDetails === 'Promoções' && (
              <OffersTab productForm={productForm} setProductForm={setProductForm} />
            )}
            {tabDetails === 'Anúncios' && (
              <AdvertsTab
                productForm={productForm}
                setProductForm={setProductForm}
                setFormChanged={setFormChanged}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.submitArea} elevation={5}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.containerSubmitArea}
        >
          <Grid item>
            <Box marginLeft={2}>
              <Button variant="contained" color="default" onClick={confirmCloseProductDetails}>
                {formChanged ? 'Cancelar' : 'Fechar'}
              </Button>
            </Box>
          </Grid>
          <Grid item className={classes.updateImagesArea}>
            <FormControlLabel
              control={
                <Switch
                  value={productForm.recentlyUpdatedImages}
                  onChange={handleChange}
                  checked={productForm.recentlyUpdatedImages}
                  name="recentlyUpdatedImages"
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  Atualizar imagens
                  <br />
                  nos Marketplaces
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <Box marginRight={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={!formChanged}
                onClick={handleSaveForm}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {confirmDialogOpen && (
        <SimpleDialog
          openDialog={confirmDialogOpen}
          handleClose={handleCloseConfirmDialog}
          dialogTitle="Você não salvou as alterações"
          dialogText="Você irá perder todas as alterações que realizou caso deseje sair"
          cancelButtonText="Sair sem salvar"
          confirmButtonText="Salvar alterações"
          handleCancelButton={closeProductDetails}
          handleConfirmButton={handleSaveForm}
        />
      )}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <SimpleBackdrop loading={loadingUpdateProduct} />
    </Drawer>
  );
}

export default memo(ProductDetails);
