import React from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import mergeOrderItems from '../../../../../../helpers/mergeOrderItems';
import PartialOrderPrint from './PartialOrderPrint';
import CompleteOrderPrint from './CompleteOrderPrint';
import LayoutPrint from './LayoutPrint';

function PrintOrderPdf({ selectedCompany, seller, order, devolutions, printCompleteOrder }) {
  const orderItems = order.items;
  const devolutionItems = mergeOrderItems(devolutions.map((devolution) => devolution.items));

  const allItems = [
    ...orderItems.map((item) => ({ isDevolution: false, ...item })),
    ...devolutionItems.map((item) => ({ isDevolution: true, ...item })),
  ];

  const itemsPerPage = 48;
  const itemsPages = [];

  for (let i = 0; i < allItems.length; i += itemsPerPage) {
    const chunk = allItems.slice(i, i + itemsPerPage);
    itemsPages.push(chunk);
  }

  const totalValueDevolutions = devolutions.reduce(
    (total, devolution) => total + devolution.devolutionTotal,
    0,
  );

  return (
    <PDFViewer width="1000" height="600" className="app">
      <Document>
        <LayoutPrint selectedCompany={selectedCompany} order={order} seller={seller}>
          {itemsPages.map((items, index) =>
            printCompleteOrder ? (
              <CompleteOrderPrint
                key={`${index + 1}-${itemsPages.length}`}
                order={order}
                items={items}
                devolutionsTotal={totalValueDevolutions}
                pageNumber={index + 1}
                pageQuantity={itemsPages.length}
              />
            ) : (
              <PartialOrderPrint
                key={`${index + 1}-${itemsPages.length}`}
                order={order}
                items={items}
                pageNumber={index + 1}
                pageQuantity={itemsPages.length}
              />
            ),
          )}
        </LayoutPrint>
      </Document>
    </PDFViewer>
  );
}

export default PrintOrderPdf;
