import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState, useEffect } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import Axios from 'axios';
import BannerPaper from './BannerPaper';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import FabGroupButtons from '../../Common/FabGroupButtons';
import TableBlogPosts from './TableBlogPosts/Table';
import { formatHandleError } from '../../../helpers/formatData';
import ImagesManagerDialog from '../../Common/ImagesManagerDialog';
import BannerImgTab from './BannerImgTab';

const useStyles = makeStyles((theme) => ({
  addCompanyIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
  },
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  buttonAddImage: {
    width: 140,
    top: -17,
    left: -200,
    cursor: 'pointer',
  },
  buttonAddImageMobile: {
    width: 140,
    top: -17,
    left: 40,
    cursor: 'pointer',
  },
  typographyAddImage: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const bannerTypeList = [
  {
    id: 'homeBanners',
    name: 'Banners Maiores',
  },
  {
    id: 'narrowBanners',
    name: 'Banners Estreitos',
  },
  {
    id: 'threeBlocksAds',
    name: 'Três Banners Menores',
  },
  {
    id: 'carouselAds',
    name: 'Carrossel de Produtos',
  },
  {
    id: 'collections',
    name: 'Coleções de Produtos',
  },
  {
    id: 'bestBrands',
    name: 'Melhores Marcas',
  },
  {
    id: 'blogBanners',
    name: 'Banners do Blog',
  },
  {
    id: 'blogPosts',
    name: 'Postagens do Blog',
  },
];

function SettingsCms() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [cmsList, setCmsList] = useState([]);
  const [selectedCms, setSelectedCms] = useState({});
  const [selectedBannerType, setSelectedBannerType] = useState(bannerTypeList[0]);
  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [blockSend, setBlockSend] = useState(true);
  const [bannerName, setBannerName] = useState({
    alt: '',
    link: '/',
    rgbBackground: '',
  });

  const [openDialogNewBanner, setOpenDialogNewBanner] = useState(false);
  const handleOpenDialogNewBanner = useCallback(() => setOpenDialogNewBanner(true), []);
  const handleCloseDialogNewBanner = useCallback(() => setOpenDialogNewBanner(false), []);

  const handleRefreshCms = useCallback(() => setRefreshToken(Math.random()), []);

  const getBanners = useCallback(async () => {
    try {
      const response = await Axios.get('/cms');

      setCmsList(response.data);
      setSelectedCms(response.data[0] || {});
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar CMS',
        error,
      });
    }
  }, [refreshToken]);

  useEffect(() => {
    getBanners();
  }, [refreshToken]);

  const [openImagesManagerDialog, setOpenImagesManagerDialog] = useState(false);
  const handleOpenImagesManagerDialog = () => setOpenImagesManagerDialog(true);
  const handleCloseImagesManagerDialog = () => setOpenImagesManagerDialog(false);

  const [bannerSrc, setBannerSrc] = useState('');
  const [bannerSrcMobile, setBannerSrcMobile] = useState('');
  const [selectedSrcBanner, setSelectedSrcBanner] = useState('');
  const [srcBannerFieldName, setSrcBannerFieldName] = useState('');

  const handleSelectedSrcBanner = (src) => {
    if (srcBannerFieldName === 'src') {
      setBannerSrc(src);
      setSelectedSrcBanner(src);
      handleCloseImagesManagerDialog();
    }
    if (srcBannerFieldName === 'srcMobile') {
      setBannerSrcMobile(src);
      setSelectedSrcBanner(src);
      handleCloseImagesManagerDialog();
    }
  };

  useEffect(() => {
    if (selectedBannerType.id === 'homeBanners') {
      if (
        bannerSrc &&
        bannerSrcMobile &&
        bannerName.alt &&
        bannerName.link &&
        bannerName.rgbBackground
      ) {
        setBlockSend(false);
      } else {
        setBlockSend(true);
      }
    } else if (bannerSrc && bannerName.alt && bannerName.link) {
      setBlockSend(false);
    } else {
      setBlockSend(true);
    }
  }, [bannerSrc, bannerSrcMobile, bannerName]);

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setBannerName((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleConfirmUploadBanner = async () => {
    try {
      if (bannerSrc) {
        let srcFields = {};

        srcFields = {
          src: bannerSrc,
        };

        if (bannerSrcMobile) {
          srcFields = {
            ...srcFields,
            srcMobile: bannerSrcMobile,
          };
        }

        let productFields = {};

        if (selectedBannerType.id === 'carouselAds' && selectedProduct) {
          productFields = {
            price: selectedProduct.offer ? selectedProduct.offerPrice : selectedProduct.price,
            productId: selectedProduct.productId,
          };
        }

        await Axios.post('/cms', {
          ...bannerName,
          ...srcFields,
          ...productFields,
          company: selectedCms.company,
          type: selectedBannerType.id,
        });

        setBannerName({
          alt: '',
          link: '/',
        });

        setBannerSrc('');
        setBannerSrcMobile('');
        handleRefreshCms();
      }

      handleCloseDialogNewBanner();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar CMS',
        error,
      });
    }
  };

  const [searchProductText, setSearchProductText] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [timeoutProductsSearchId, setTimeoutProductsSearchId] = useState();

  const handleProductOnChange = (event, newValue) => {
    if (newValue) {
      setSelectedProduct(newValue);
      setBannerName({
        alt: newValue.name,
        link: `/produto/${newValue.slug}`,
      });
    } else {
      setSelectedProduct(null);
      setBannerName({
        alt: '',
        link: '/',
      });
    }
  };

  const searchAllProducts = async () => {
    try {
      setLoadingProductsList(true);
      const response = await Axios.get('/catalog/products/search-admin', {
        params: {
          limit: 20,
          skip: 0,
          searchText: searchProductText,
          sortDirection: 'asc',
          filtersOptions: [{ id: 'showOnlySiteActive', active: true }],
        },
      });
      setProductsList(response.data.productsList);
      setLoadingProductsList(false);
    } catch (error) {
      setLoadingProductsList(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar produtos',
        error,
      });
    }
  };

  useEffect(() => {
    setLoadingProductsList(true);
    if (timeoutProductsSearchId) {
      clearTimeout(timeoutProductsSearchId);
    }
    setTimeoutProductsSearchId(setTimeout(() => searchAllProducts(), 1000));
  }, [searchProductText]);

  const filterProduct = createFilterOptions({
    stringify: (option) => option.name + option.productId,
  });

  const [fabButtons, setFabButtons] = useState([]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Novo Banner',
        onClick: handleOpenDialogNewBanner,
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Paper>
                <Box padding={2}>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h5">Meus sites</Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<Typography>Adicionar um novo site</Typography>}>
                        <IconButton onClick={() => {}}>
                          <AddCircleIcon className={classes.addCompanyIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>

                <MenuList>
                  {cmsList.map((getCms) => (
                    <MenuItem
                      key={getCms._id}
                      onClick={() => setSelectedCms(getCms)}
                      className={
                        selectedCms.company === getCms.company ? classes.menuItemSelected : null
                      }
                    >
                      {getCms.company}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grid>
            {selectedCms.company ? (
              <Grid item sm={12}>
                <Paper>
                  <MenuList>
                    {bannerTypeList.map((typeItem) => (
                      <MenuItem
                        key={typeItem.id}
                        onClick={() => setSelectedBannerType(typeItem)}
                        className={
                          selectedBannerType.id === typeItem.id ? classes.menuItemSelected : null
                        }
                      >
                        {typeItem.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item sm={8}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">
                {selectedBannerType.name}
                {selectedCms.company ? ` (${selectedCms.company})` : ''}
              </Typography>

              <Box marginTop={2}>
                {selectedCms[selectedBannerType.id] ? (
                  <Grid container spacing={1}>
                    {selectedCms[selectedBannerType.id].map((homeBanner) => (
                      <Grid item key={homeBanner._id}>
                        <BannerPaper
                          company={selectedCms.company}
                          bannerType={selectedBannerType.id}
                          homeBanner={homeBanner}
                          refreshCms={handleRefreshCms}
                          setSnackbar={setSnackbar}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : null}

                {selectedBannerType.id === 'blogBanners' && !selectedCms.blogBanners.length ? (
                  <Typography align="center"> Não há nenhum banner no blog</Typography>
                ) : null}

                {selectedBannerType.id === 'blogPosts' ? <TableBlogPosts /> : null}

                {openDialogNewBanner ? (
                  <SimpleDialog
                    openDialog={openDialogNewBanner}
                    handleClose={handleCloseDialogNewBanner}
                    dialogTitle="Adicionar banner"
                    content={
                      <Box width="800px" height="400px">
                        <Grid container direction="column" spacing={2}>
                          {selectedBannerType.id === 'carouselAds' ? (
                            <Grid item>
                              <Autocomplete
                                size="small"
                                options={productsList}
                                filterOptions={filterProduct}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                  option.productId === value.productId
                                }
                                renderOption={(props, option) => (
                                  <Typography {...{ ...props, key: option._id }} variant="caption">
                                    {option.name}
                                  </Typography>
                                )}
                                onChange={handleProductOnChange}
                                noOptionsText="Embalagem não encontrada"
                                onInputChange={(event, newInputValue) => {
                                  setSearchProductText(newInputValue);
                                }}
                                loading={loadingProductsList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione o produto"
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {loadingProductsList ? (
                                            <CircularProgress variant="indeterminate" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          ) : null}
                          <Grid item>
                            <CustomInput
                              label="Nome"
                              name="alt"
                              value={bannerName.alt}
                              error={!bannerName.alt}
                              disabled={Boolean(selectedProduct)}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <CustomInput
                              label="Link"
                              name="link"
                              value={bannerName.link}
                              error={!bannerName.link}
                              disabled={Boolean(selectedProduct)}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          {selectedBannerType.id === 'homeBanners' ? (
                            <Grid item>
                              <CustomInput
                                label="RGB da cor de fundo do site"
                                name="rgbBackground"
                                value={bannerName.rgbBackground}
                                error={!bannerName.rgbBackground}
                                disabled={Boolean(selectedProduct)}
                                onChange={handleChange}
                                variant="outlined"
                              />
                            </Grid>
                          ) : null}
                          <Grid item>
                            <Divider />
                          </Grid>
                          <Grid item>
                            {bannerName.alt ? (
                              <BannerImgTab
                                src={bannerSrc}
                                srcMobile={bannerSrcMobile}
                                alt="Imagem banner"
                                bannerType={selectedBannerType.id}
                                onImgClick={(fieldName) => {
                                  setSrcBannerFieldName(fieldName);
                                  handleOpenImagesManagerDialog();
                                }}
                              />
                            ) : (
                              <Typography color="error">
                                {selectedBannerType.id === 'carouselAds'
                                  ? 'Informe um produto ou o nome'
                                  : 'Informe um nome para a imagem'}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    }
                    cancelButtonText="Cancelar"
                    confirmButtonText="Enviar"
                    handleCancelButton={handleCloseDialogNewBanner}
                    handleConfirmButton={handleConfirmUploadBanner}
                    disableConfirmButton={blockSend}
                  />
                ) : null}

                {openImagesManagerDialog ? (
                  <ImagesManagerDialog
                    openImagesManagerDialog={openImagesManagerDialog}
                    handleCloseImagesManagerDialog={handleCloseImagesManagerDialog}
                    onSelectSrcImage={(src) => handleSelectedSrcBanner(src)}
                    selectedSrcImage={selectedSrcBanner}
                    directory={selectedBannerType.id}
                    title={bannerName.alt}
                  />
                ) : null}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      {selectedBannerType.id !== 'blogPosts' ? <FabGroupButtons fabButtons={fabButtons} /> : null}
    </Box>
  );
}

export default SettingsCms;
