import { Grid } from '@mui/material';
import React, { memo } from 'react';
import LimitTypography from '../../../Common/LimitTypography';

function CustomerNameArea({ customer }) {
  let grayName;

  if (customer.officialDocument?.name && customer.officialDocument.name !== customer.name) {
    grayName = (
      <Grid item>
        <LimitTypography
          style={{ fontSize: 11, color: 'grey' }}
          text={customer.officialDocument.name}
          maxLength={60}
          additionalText="(comprador)"
        />
      </Grid>
    );
  } else if (customer.documents?.pessoa && customer.documents.pessoa === 'PJ') {
    grayName = (
      <Grid item>
        <LimitTypography
          style={{ fontSize: 11, color: 'grey' }}
          text={customer.name}
          maxLength={60}
          additionalText="(comprador)"
        />
      </Grid>
    );
  }

  return (
    <Grid container direction="column">
      {grayName}
      <Grid item style={{ display: 'flex', flexDirection: 'row', columnGap: 5 }}>
        {!customer.documents?.pessoa || customer.documents.pessoa === 'PF' ? (
          <LimitTypography
            text={customer.userId ? `${customer.userId} - ${customer.name}` : customer.name}
            maxLength={50}
            variant="caption"
          />
        ) : (
          <LimitTypography
            text={
              customer.userId
                ? `${customer.userId} - ${customer.documents.razaoSocial}`
                : customer.documents.razaoSocial
            }
            maxLength={50}
            variant="caption"
          />
        )}
      </Grid>
    </Grid>
  );
}

export default memo(CustomerNameArea);
