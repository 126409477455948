import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { numberToRealWithPeriod } from '../../../../../../../../helpers/formatData';

const useStyles = makeStyles({
  underlineText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

function ProfitabilityExamples({
  selectedProduct,
  priceValue,
  simplesValue,
  mlPriceValue,
  bouthCostValue,
}) {
  const classes = useStyles();

  const [showExample, setShowExample] = useState('');

  return (
    <Grid container direction="column">
      <Grid item>
        <Box marginBottom={1} marginTop={2}>
          <Typography variant="h3">Exemplos de rentabilidade</Typography>
        </Box>
      </Grid>

      <Grid item>
        <Grid item>
          <Typography
            className={classes.underlineText}
            onClick={() =>
              showExample === 'Mercado Livre' ? setShowExample('') : setShowExample('Mercado Livre')
            }
          >
            Mercado Livre (
            {(
              ((mlPriceValue +
                selectedProduct.additionalOnline -
                bouthCostValue -
                mlPriceValue * simplesValue -
                mlPriceValue * 0.17) /
                mlPriceValue) *
              100
            ).toFixed(2)}
            % de lucro)
          </Typography>

          {showExample === 'Mercado Livre' ? (
            <Box marginLeft={2}>
              <Grid container direction="column">
                <Tooltip
                  title={
                    <>
                      <Typography align="center">Cálculo do preço:</Typography>
                      <Typography align="center">
                        Preço de venda base + adicional de vendas online + R$ 6,00. Caso este valor
                        seja menor que R$ 20,00 adiciona-se + R$ 5,00
                      </Typography>
                    </>
                  }
                >
                  <Typography>
                    Preço de venda (sem frete): R${' '}
                    {numberToRealWithPeriod(mlPriceValue + selectedProduct.additionalOnline)}
                  </Typography>
                </Tooltip>
                <Typography>
                  Comissão (17%): R$ {numberToRealWithPeriod(mlPriceValue * 0.17)}
                </Typography>
                <Typography>
                  Custo de compra: R$ {numberToRealWithPeriod(bouthCostValue)}
                </Typography>
                <Typography>
                  SIMPLES: R$ {numberToRealWithPeriod(mlPriceValue * simplesValue)}
                </Typography>
                <Typography>
                  Resultado: R${' '}
                  {numberToRealWithPeriod(
                    mlPriceValue +
                      selectedProduct.additionalOnline -
                      bouthCostValue -
                      mlPriceValue * 0.17 -
                      mlPriceValue * simplesValue
                  )}
                </Typography>
              </Grid>
            </Box>
          ) : null}

          <Typography
            onClick={() =>
              showExample === 'Loja física' ? setShowExample('') : setShowExample('Loja física')
            }
            className={classes.underlineText}
          >
            Loja física (
            {(
              ((priceValue - priceValue * 0.02 - bouthCostValue - priceValue * simplesValue) /
                priceValue) *
              100
            ).toFixed(2)}
            % de lucro)
          </Typography>

          {showExample === 'Loja física' ? (
            <Box marginLeft={2}>
              <Grid container direction="column">
                <Tooltip
                  title={
                    <>
                      <Typography align="center">Cálculo do preço:</Typography>
                      <Typography align="center">Preço de venda base</Typography>
                    </>
                  }
                >
                  <Typography>Preço de venda: R$ {numberToRealWithPeriod(priceValue)}</Typography>
                </Tooltip>

                <Typography>
                  Comissões (2%): R$ {numberToRealWithPeriod(priceValue * 0.02)}
                </Typography>
                <Typography>
                  Custo de compra: R$ {numberToRealWithPeriod(bouthCostValue)}
                </Typography>
                <Typography>
                  Simples: R$ {numberToRealWithPeriod(priceValue * simplesValue)}
                </Typography>
                <Typography>
                  Resultado: R${' '}
                  {numberToRealWithPeriod(
                    priceValue - priceValue * 0.02 - bouthCostValue - priceValue * simplesValue
                  )}
                </Typography>
              </Grid>
            </Box>
          ) : null}

          <Typography
            onClick={() => (showExample === 'Site' ? setShowExample('') : setShowExample('Site'))}
            className={classes.underlineText}
          >
            Site (
            {(
              ((priceValue +
                selectedProduct.additionalOnline -
                bouthCostValue -
                priceValue * simplesValue) /
                priceValue) *
              100
            ).toFixed(2)}
            % de lucro)
          </Typography>

          {showExample === 'Site' ? (
            <Box marginLeft={2}>
              <Grid container direction="column">
                <Tooltip
                  title={
                    <>
                      <Typography align="center">Cálculo do preço:</Typography>
                      <Typography align="center">
                        Preço de venda base + adicional de vendas online
                      </Typography>
                    </>
                  }
                >
                  <Typography>
                    Preço de venda: R${' '}
                    {numberToRealWithPeriod(priceValue + selectedProduct.additionalOnline)}
                  </Typography>
                </Tooltip>
                <Typography>
                  Custo de compra: R$ {numberToRealWithPeriod(bouthCostValue)}
                </Typography>
                <Typography>
                  Simples: R${' '}
                  {numberToRealWithPeriod(
                    (priceValue + selectedProduct.additionalOnline) * simplesValue
                  )}
                </Typography>
                <Typography>
                  Resultado: R${' '}
                  {numberToRealWithPeriod(
                    priceValue +
                      selectedProduct.additionalOnline -
                      bouthCostValue -
                      priceValue * simplesValue
                  )}
                </Typography>
              </Grid>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProfitabilityExamples;
