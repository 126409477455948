const newAddress = {
  postalCode: '',
  complement: '',
  streetNumber: '',
  street: '',
  city: '',
  state: '',
  district: '',
};

export default newAddress;
