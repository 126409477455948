import { Box, CircularProgress, Grid, Grow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { getViaVarejoProductsBySku } from '../../../../../services/melhorGestao/viaVarejo';
import ViaVarejoAdResume from './ViaVarejoAdResume';
import CustomDivider from '../../../../CustomDivider';

function ViaVarejoAd({
  classes,
  productForm,
  setProductForm,
  setFormChanged,
  refreshSearch,
  setRefreshSearch,
}) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingViaVarejoAds, setLoadingViaVarejoAds] = useState(false);
  const [viaVarejoAds, setViaVarejoAds] = useState([]);

  useEffect(() => {
    if (productForm.productId) {
      getViaVarejoProductsBySku({
        setViaVarejoAds,
        setLoadingViaVarejoAds,
        productId: productForm.productId,
      });
    }
  }, [productForm.productId, refreshSearch]);

  if (!viaVarejoAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img className={classes.sourceBadge} src="/assets/imgs/via-badge.svg" alt="" />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Via Varejo
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingViaVarejoAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {viaVarejoAds.length > 0 &&
                viaVarejoAds.map((viaVarejoAd) => (
                  <Grid
                    item
                    key={viaVarejoAd.skus?.[0]?.idSkuLojista || viaVarejoAd.sku.idSkuLojista}
                  >
                    <ViaVarejoAdResume
                      viaVarejoAd={viaVarejoAd}
                      product={productForm}
                      setProductForm={setProductForm}
                      setFormChanged={setFormChanged}
                      setRefreshSearch={setRefreshSearch}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

export default ViaVarejoAd;
