import React, { useState } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchField({ startAdornment, submitSearch, labelSearch, preSearchText = '' }) {
  const [searchText, setSearchText] = useState(preSearchText);

  const onChangeSearchText = (event) => {
    const newSearchText = event.target.value;
    return setSearchText(newSearchText);
  };

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) {
      return submitSearch(searchText);
    }
    return null;
  };

  return (
    <Paper sx={{ paddingLeft: 2, paddingRight: 1, paddingTop: 0.4, paddingBottom: 0.2 }}>
      <InputBase
        startAdornment={startAdornment}
        value={searchText}
        onChange={onChangeSearchText}
        onKeyDown={submitOnEnter}
        placeholder={labelSearch}
      />
      <IconButton onClick={() => submitSearch(searchText)}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SearchField;
