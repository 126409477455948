import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatHandleError } from '../../../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  select: {
    width: 200,
  },
}));

function MercadoLivreAdTypesMenu({ adForm, setAdForm, setSnackbar }) {
  const classes = useStyles();

  const [mercadoLivreAdTypes, setMercadoLivreAdTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/mercado-livre/ads/types');
        const { adTypes } = response.data;
        setMercadoLivreAdTypes(adTypes);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: `Algum erro ocorreu ao buscar tipos de anúncio no Mercado Livre`,
          error,
        });
      }
    };
    fetchData();
  }, []);

  const handleChangeType = (event) => {
    const selectedType = mercadoLivreAdTypes.find((adType) => adType.name === event.target.value);

    setAdForm((oldFields) => ({
      ...oldFields,
      adType: {
        id: selectedType.id,
        name: selectedType.name,
        siteId: selectedType.site_id,
      },
    }));
  };

  return mercadoLivreAdTypes.length > 0 ? (
    <FormControl fullWidth variant="standard" size="small" color="secondary">
      <InputLabel>Tipo de anúncio</InputLabel>
      <Select className={classes.select} onChange={handleChangeType} value={adForm.adType.name}>
        {mercadoLivreAdTypes.map((adType) => (
          <MenuItem key={adType.id} value={adType.name}>
            {adType.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <Skeleton variant="rectangular" width={200} height={45} />
  );
}

export default MercadoLivreAdTypesMenu;
