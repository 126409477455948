import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    alignItems: 'center',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  sku: {
    width: '5%',
    textAlign: 'center',
    fontSize: 8,
  },
  name: {
    width: '55%',
    margin: 1,
    fontSize: 8,
  },
  localization: {
    width: '11%',
    textAlign: 'center',
    fontSize: 8,
  },
  price: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
  quantity: {
    width: '5%',
    textAlign: 'center',
    fontSize: 8,
  },
  total: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
});

function TableHeader() {
  return (
    <View style={styles.row}>
      <Text style={styles.sku}>SKU</Text>
      <Text style={styles.name}>Nome</Text>
      <Text style={styles.localization}>Localização</Text>
      <Text style={styles.price}>Preço</Text>
      <Text style={styles.quantity}>Qtd.</Text>
      <Text style={styles.total}>Total</Text>
    </View>
  );
}

export default TableHeader;
