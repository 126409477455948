import { Alert, Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { formatCpfCpnj } from '../../../../helpers/formatData';
import CustomerDialog from '../../../Orders/Details/CustomerTab/CustomerDialog';
import LimitTypography from '../../../Common/LimitTypography';

function SupplierTab({ supplier, supplierRolesFilters, setEntryOrder, blockFields }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [openDialogCustomer, setOpenDialogCustomer] = useState(false);
  const handleOpenDialogCustomer = () => setOpenDialogCustomer(true);
  const handleCloseDialogCustomer = () => setOpenDialogCustomer(false);

  const handleSelectedSupplier = (data) => {
    const { user } = data;

    if (!user.exporter && user.documents && !user.documents.cnpj) {
      handleCloseDialogCustomer();

      return setSnackbar({
        message: 'Escolha um fornecedor que possua CNPJ',
        open: true,
        type: 'error',
      });
    }

    setEntryOrder((oldFields) => ({
      ...oldFields,
      supplier: {
        userId: user.userId,
        cnpj: user.documents.cnpj,
        ie: user.documents.inscricaoEstadual,
        name: user.name,
        razaoSocial: user.documents.razaoSocial,
        address: user.addresses[0] || {},
      },
    }));

    return handleCloseDialogCustomer();
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Fornecedor</Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOpenDialogCustomer} disabled={blockFields}>
              Escolher Fornecedor
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {supplier.name ? (
        <Grid item xs={12}>
          <Box marginTop={1}>
            <Grid container>
              <Grid item xs={8}>
                <Grid container direction="column">
                  <Typography variant="caption">Nome</Typography>
                  <LimitTypography text={supplier.name} maxLength={40} />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="column">
                  <Typography variant="caption">CNPJ</Typography>
                  <Typography>{formatCpfCpnj(supplier.cnpj)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ) : null}

      {openDialogCustomer ? (
        <CustomerDialog
          openCustomerDialog={openDialogCustomer}
          handleCloseCustomerDialog={handleCloseDialogCustomer}
          handleSelectCustomerToOrder={handleSelectedSupplier}
          customerRolesFilters={supplierRolesFilters}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default memo(SupplierTab);
