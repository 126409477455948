import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles({
  checkIcon: {
    color: '#fff',
    backgroundColor: '#47ab81',
    borderRadius: 16,
    marginRight: 10,
  },
  uncontrolledText: {
    cursor: 'pointer',
  },
});

function TableCellControlled({ ad, selectAd }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <Grid item onClick={() => selectAd(ad)} className={classes.uncontrolledText}>
        {ad.productId ? (
          <Grid container>
            <Grid item>
              <CheckIcon className={classes.checkIcon} />
            </Grid>
            <Grid item>
              <Typography>Kit com: {ad.quantity}</Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography align="center">Não sincronizado</Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(TableCellControlled);
