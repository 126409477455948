import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const thermalPrinterOrders = async ({
  setLoadingProcessing = () => {},
  setSnackbar = () => {},
  ordersToPrint = [],
  withPrice = false,
}) => {
  try {
    setLoadingProcessing(true);
    await ordersToPrint.reduce(async (promise, order) => {
      await promise;

      await Axios.post(`${process.env.REACT_APP_REST_URL_PRINTER}/thermal-printer/printer/order`, {
        ...order,
        withPrice,
      });
    }, Promise.resolve());

    setLoadingProcessing(false);
  } catch (error) {
    setLoadingProcessing(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao conectar a impressora térmica',
      error,
    });
  }
};

export default thermalPrinterOrders;
