import Axios from 'axios';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { makeStyles } from '@mui/styles';
import OrderStatusTypography from '../../elements/OrderStatusTypography';
import UserAutocomplete from '../../../Common/UserAutocomplete';
import SimpleDialog from '../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../helpers/formatData';
import StatusHistoryDialog from './StatusHistoryDialog';

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 190,
  },
}));

function StatusTab({ orderForm, setOrderForm, setSeller, setSnackbar, closeOrderDetails }) {
  const classes = useStyles();

  const handleChangeSeller = (event, newValue) =>
    setOrderForm((oldFields) => ({
      ...oldFields,
      seller: newValue,
    }));

  const [openDialogStatusHistory, setOpenDialogStatusHistory] = useState(false);
  const handleOpenDialogStatusHistory = () => setOpenDialogStatusHistory(true);
  const handleCloseDialogStatusHistory = () => setOpenDialogStatusHistory(false);

  const [loadingRepeatOrder, setLoadingRepeatOrder] = useState(false);
  const [openDialogRepeatOrder, setOpenDialogRepeatOrder] = useState(false);
  const handleOpenDialogRepeatOrder = () => setOpenDialogRepeatOrder(true);
  const handleCloseDialogRepeatOrder = () => setOpenDialogRepeatOrder(false);

  const handleConfirmRepeatOrder = async () => {
    try {
      if (orderForm.orderId) {
        setLoadingRepeatOrder(true);
        await Axios.post(`/orders/repeat-order/${orderForm.orderId}`);
        handleCloseDialogRepeatOrder();
        setLoadingRepeatOrder(false);
      }
      return closeOrderDetails();
    } catch (error) {
      setLoadingRepeatOrder(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao repetir o pedido',
        error,
      });
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Grid container direction="column">
              <Typography variant="caption">Situação</Typography>
              <OrderStatusTypography orderStatus={orderForm.status} />
              <Typography variant="caption">{orderForm.statusPending}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">Histórico de alteração da situação do pedido</Typography>
              }
            >
              <Box>
                <IconButton
                  onClick={handleOpenDialogStatusHistory}
                  disabled={!orderForm.statusHistory || !orderForm.statusHistory.length > 0}
                >
                  <HistoryIcon />
                  <Typography variant="caption">Histórico</Typography>
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                <Typography align="center">
                  Cria um orçamento novo com os mesmo itens deste pedido.
                </Typography>
              }
            >
              <Box>
                <IconButton
                  onClick={handleOpenDialogRepeatOrder}
                  disabled={!orderForm.orderId || !orderForm.items || !orderForm.items.length < 0}
                >
                  <HistoryIcon />
                  <Typography variant="caption">Repetir</Typography>
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <UserAutocomplete
          setSnackbar={setSnackbar}
          handleOnChange={handleChangeSeller}
          selectedUser={orderForm.seller}
          setSelectedUser={setSeller}
          label="Vendedor"
          disabled={orderForm.status !== 'Orçamento'}
          roles={['staff']}
          variant="filled"
          className={classes.select}
        />
      </Grid>
      {openDialogStatusHistory ? (
        <StatusHistoryDialog
          orderForm={orderForm}
          openDialogStatusHistory={openDialogStatusHistory}
          handleCloseDialogStatusHistory={handleCloseDialogStatusHistory}
        />
      ) : null}
      {openDialogRepeatOrder ? (
        <SimpleDialog
          openDialog={openDialogRepeatOrder}
          handleClose={handleCloseDialogRepeatOrder}
          dialogTitle="Repetir pedido"
          dialogText="Deseja repetir este pedido? Será criado um novo orçamento com os mesmo itens deste pedido."
          cancelButtonText="Cancelar"
          content=""
          confirmButtonText="Confirmar"
          confirmButtonLoading={loadingRepeatOrder}
          maxWidth="xs"
          handleCancelButton={handleCloseDialogRepeatOrder}
          handleConfirmButton={handleConfirmRepeatOrder}
        />
      ) : null}
    </Grid>
  );
}

export default StatusTab;
