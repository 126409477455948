import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CustomDatePicker from '../../CustomDatePicker';
import UserAutocomplete from '../../Common/UserAutocomplete';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import { formatHandleError } from '../../../helpers/formatData';
import { useAuth } from '../../../contexts/auth';
import getTasks from '../../../services/melhorGestao/tasks';

const useStyles = makeStyles({
  select: {
    minWidth: 200,
  },
});

function FinancesReports() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [filterFinanceCompany, setFilterFinanceCompany] = useState([]);
  const [financeType, setFinanceType] = useState('Recebimento');
  const [user, setUser] = useState(null);
  const [seller, setSeller] = useState(null);

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'month').endOf('month'));
  const [loading, setLoading] = useState(false);

  const getReports = async (reportType) => {
    try {
      setLoading(true);

      const response = await Axios.post('/reports/finances/', {
        filterFinanceCompany,
        financeType,
        userId: user?._id,
        sellerId: seller?._id,
        startDate: dayjs(startDate).startOf('day'),
        endDate: dayjs(endDate).endOf('day'),
        reportType,
      });

      const { url } = response.data;
      window.open(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao baixar relatório do financeiro',
        error,
      });
    }
  };

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleSelectedCustomer = (event, newValue) => {
    if (newValue) {
      setUser(newValue);
    } else {
      setUser(null);
    }
  };

  const handleSelectedSeller = (event, newValue) => {
    if (newValue) {
      setSeller(newValue);
    } else {
      setSeller(null);
    }
  };

  const loggedUser = useAuth().user;
  const [viewFinancialTask, setViewFinancialTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const getTask = tasksStaff.find((task) => task.name === 'Visualizar financeiro');
        if (getTask && getTask.active) {
          setViewFinancialTask(getTask.active);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <CompanySelectOptions
                        inputLabel="Empresa"
                        filtersOptions={filterFinanceCompany}
                        setFiltersOptions={setFilterFinanceCompany}
                        hideButton
                      />
                    </Grid>
                    <Grid item>
                      <Box width={200}>
                        <FormControl fullWidth variant="filled" size="small">
                          <InputLabel>Tipo</InputLabel>
                          <Select
                            onChange={(event) => setFinanceType(event.target.value)}
                            value={financeType}
                          >
                            <MenuItem value="Recebimento">Recebimento</MenuItem>
                            {viewFinancialTask ? (
                              <MenuItem value="Pagamento">Pagamento</MenuItem>
                            ) : null}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <UserAutocomplete
                        handleOnChange={handleSelectedCustomer}
                        selectedUser={user}
                        label="Cliente"
                        variant="filled"
                        className={classes.select}
                      />
                    </Grid>
                    <Grid item>
                      <UserAutocomplete
                        handleOnChange={handleSelectedSeller}
                        selectedUser={seller}
                        label="Vendedor"
                        roles={['staff']}
                        variant="filled"
                        className={classes.select}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="Data inicial"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={handleChangeStartDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="Data final"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={handleChangeEndDate}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                  <Grid item>
                    <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        getReports('csv');
                      }}
                    >
                      Baixar CSV
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        getReports('pdf');
                      }}
                    >
                      Baixar PDF
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FinancesReports;
