import {
  Checkbox,
  Grid,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  typographyCursor: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelectedMain: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function ChequesTableBody({
  isCheckedCheque,
  handleUncheckCheque,
  handleCheckCheque,
  chequesList,
  selectedCheque,
  handleClickCheque,
  handleClickMovements,
  page,
  handleChangePage,
  selectedChequeInList,
  loadingChequesList,
}) {
  const classes = useStyles();

  const handleColorSelectedCheque = (chequeSelected, chequeSelectedInList, cheque) => {
    if (chequeSelectedInList && chequeSelectedInList._id === cheque._id) {
      return classes.tableRowSelectedMain;
    }
    if (chequeSelected && chequeSelected._id === cheque._id) {
      return classes.tableRowSelected;
    }
    return classes.tableRow;
  };

  return (
    <TableBody>
      {chequesList.length > 0 &&
        chequesList.map((cheque) => (
          <TableRow
            key={cheque._id}
            className={handleColorSelectedCheque(selectedCheque, selectedChequeInList, cheque)}
            onClick={() => handleClickCheque(cheque)}
          >
            <TableCell>
              {cheque.situation === 'Recebido' || cheque.situation === 'Devolvido' ? (
                <Checkbox
                  checked={isCheckedCheque({ chequeId: cheque.chequeId })}
                  onClick={(event) => {
                    if (isCheckedCheque({ chequeId: cheque.chequeId })) {
                      handleUncheckCheque({ chequeId: cheque.chequeId });
                    } else {
                      handleCheckCheque({ chequeId: cheque.chequeId });
                    }
                    event.stopPropagation();
                  }}
                />
              ) : null}
            </TableCell>
            <TableCell>{cheque.chequeNumber}</TableCell>
            <TableCell>{cheque.name}</TableCell>
            <TableCell>{cheque.barCode}</TableCell>
            <TableCell>{cheque.bankNumber}</TableCell>
            <TableCell>R$ {numberToRealWithPeriod(cheque.value)}</TableCell>
            <TableCell>
              <IconButton
                onClick={(event) => {
                  handleClickMovements(cheque);
                  event.stopPropagation();
                }}
              >
                <HistoryIcon />
              </IconButton>
            </TableCell>
            <TableCell>{cheque.situation}</TableCell>
          </TableRow>
        ))}

      {page !== 1 && chequesList.length === 0 && (
        <TableRow>
          <TableCell colSpan={8}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography
                  onClick={(event) => handleChangePage(event, 1)}
                  className={classes.typographyCursor}
                >
                  Nenhum cheque nesta página, clique aqui para voltar a página.
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}

      {page === 1 && chequesList.length === 0 && (
        <TableRow>
          <TableCell colSpan={8}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography>
                  {loadingChequesList ? 'Carregando...' : 'Nenhum cheque para exibir'}
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default ChequesTableBody;
