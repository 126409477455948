import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState, memo } from 'react';

function WalletAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedWallet,
  label = 'Carteira',
  disabled,
  error,
  variant,
}) {
  const [walletsList, setWalletsList] = useState([]);
  const [loadingWallets, setLoadingWallets] = useState(false);

  const getWallets = async () => {
    try {
      setLoadingWallets(true);
      const response = await Axios.get('/wallets/list');
      const wallets = response.data;
      setWalletsList(wallets);
      setLoadingWallets(false);
      return true;
    } catch (err) {
      setLoadingWallets(false);
      console.log(err);
      return setSnackbar({
        message: 'Algum erro ocorreu ao tentar carregar as carteiras',
        open: true,
        type: 'error',
      });
    }
  };

  const filterWallet = createFilterOptions({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    getWallets();
  }, []);

  return (
    <Autocomplete
      size="small"
      options={walletsList}
      filterOptions={filterWallet}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.walletId === value.walletId}
      renderOption={(props, option) => (
        <Typography {...{ ...props, key: option._id }} variant="caption">
          {option.name}
        </Typography>
      )}
      disabled={disabled}
      value={selectedWallet}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      loading={loadingWallets}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
    />
  );
}

export default memo(WalletAutocomplete);
