import React, { memo } from 'react';
import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import SearchField from '../../Common/SearchField';
import CustomDatePicker from '../../CustomDatePicker';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import FilterSelectOptions from '../../Common/FilterSelectOptions';

const useStyles = makeStyles((theme) => ({
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    width: 200,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarInvoice({
  submitSearchInvoices,
  filterModelOptions,
  setFilterModelOptions,
  filterSerieOptions,
  setFilterSerieOptions,
  filterOperationOptions,
  setFilterOperationOptions,
  filterCompanyOptions,
  setFilterCompanyOptions,
  filterUserOptions,
  setFilterUserOptions,
  startDate,
  setStartDate,
  setEndDate,
}) {
  const classes = useStyles();

  const handleChangeMonth = (date) => {
    setStartDate(dayjs(date).startOf('month'));
    setEndDate(dayjs(date).endOf('month'));
  };

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography variant="h5" className={classes.contrastText}>
            Buscador de NF-e
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <FilterSelectOptions
                inputLabel="Modelo"
                filtersOptions={filterModelOptions}
                setFiltersOptions={setFilterModelOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Serie"
                filtersOptions={filterSerieOptions}
                setFiltersOptions={setFilterSerieOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Operação"
                filtersOptions={filterOperationOptions}
                setFiltersOptions={setFilterOperationOptions}
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                label="Mês"
                variant="filled"
                size="small"
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                onChange={handleChangeMonth}
                format="MM/YYYY"
                className={classes.selectBackground}
              />
            </Grid>
            <Grid item>
              <CompanySelectOptions
                inputLabel="Emitente"
                filtersOptions={filterCompanyOptions}
                setFiltersOptions={setFilterCompanyOptions}
              />
            </Grid>
            <Grid item>
              <CompanySelectOptions
                inputLabel="Destinatário"
                filtersOptions={filterUserOptions}
                setFiltersOptions={setFilterUserOptions}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearchInvoices} labelSearch="Pesquisar NF-e" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarInvoice);
