import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const getShippingStamps = async ({ orders, setLoadingShippingStamp, setSnackbar }) => {
  try {
    setLoadingShippingStamp(true);

    const carrierRoutes = [
      {
        company: 'Jadlog',
        route: 'melhorenvio',
      },
      {
        company: 'Correios',
        route: 'melhorenvio',
      },
      {
        company: 'Loggi',
        route: 'melhorenvio',
      },
      {
        company: 'Mandaê',
        route: 'mandae',
      },
      {
        company: 'Jamef',
        route: 'jamef',
      },
    ];

    const authorizedCarriers = carrierRoutes.map((carrierRoute) => carrierRoute.company);

    const groupedShippingCompanies = orders.reduce((shippingCompanies, order) => {
      if (order.shipping && authorizedCarriers.includes(order.shipping.company)) {
        const existingGroup = shippingCompanies.find(
          (group) => group.company === order.shipping.company,
        );

        if (existingGroup) {
          existingGroup.orderIds.push(order.orderId);
        } else {
          const carrier = carrierRoutes.find(
            (carrierRoute) => carrierRoute.company === order.shipping.company,
          );

          if (carrier) {
            shippingCompanies.push({
              company: carrier.company,
              route: carrier.route,
              orderIds: [order.orderId],
            });
          }
        }
      }
      return shippingCompanies;
    }, []);

    await groupedShippingCompanies.reduce(async (promise, shippingCompany) => {
      await promise;

      const response = await Axios.post(`/shipping/stamp/${shippingCompany.route}`, {
        ordersIds: shippingCompany.orderIds,
      });

      const { stampLink } = response.data;

      if (stampLink && stampLink.url) {
        window.open(stampLink.url);
      }
    }, Promise.resolve());

    setLoadingShippingStamp(false);
  } catch (error) {
    setLoadingShippingStamp(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Não conseguimos imprimir a etiqueta. Verifique o sistema de impressão.',
      error,
    });
  }
};

export default getShippingStamps;
