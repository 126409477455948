import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import React, { useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatDate, numberToReal } from '../../../../../helpers/formatData';

function UserOrdersButton({ userOrders }) {
  const [openDialogUserOrders, setOpenDialogUserOrders] = useState(false);
  const handleOpenDialogUserOrders = () => setOpenDialogUserOrders(true);
  const handleCloseDialogUserOrders = () => setOpenDialogUserOrders(false);

  return (
    <Box>
      <Tooltip title={<Typography align="center">Outros pedidos deste cliente</Typography>}>
        <IconButton onClick={handleOpenDialogUserOrders} size="small">
          <AssignmentOutlinedIcon />
        </IconButton>
      </Tooltip>
      {openDialogUserOrders ? (
        <SimpleDialog
          openDialog={openDialogUserOrders}
          handleClose={handleCloseDialogUserOrders}
          dialogTitle="Outros pedidos deste cliente"
          content={
            <Box width={600}>
              <Paper elevation={4}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Código</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">Situação</TableCell>
                      <TableCell align="center">Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userOrders && userOrders.length > 0
                      ? userOrders.map((order) => (
                          <TableRow key={order.orderId}>
                            <TableCell align="center">{order.orderId}</TableCell>
                            <TableCell align="center">R${numberToReal(order.orderTotal)}</TableCell>
                            <TableCell align="center">{order.status}</TableCell>
                            <TableCell align="center">{formatDate(order.createdAt)}</TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Box>
          }
        />
      ) : null}
    </Box>
  );
}

export default UserOrdersButton;
