import { FormControlLabel, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { capitalizeString, extractFirstAndLastName } from '../../../../helpers/formatData';
import getTasks from '../../../../services/melhorGestao/tasks';
import { useAuth } from '../../../../contexts/auth';

function OnlyCompanyTab({ orderForm, setOrderForm, selectedCompany }) {
  const loggedUser = useAuth().user;
  const [companyManagerTask, setCompanyManagerTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const getTask = tasksStaff.find((task) => task.name === 'Gerenciar pagamentos');
        if (getTask && getTask.active) {
          setCompanyManagerTask(getTask.active);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              value={orderForm.onlyCompanyStock || false}
              onChange={() =>
                setOrderForm((oldFields) => ({
                  ...oldFields,
                  onlyCompanyStock: !orderForm.onlyCompanyStock,
                }))
              }
              checked={orderForm.onlyCompanyStock || false}
              disabled={!companyManagerTask || !!orderForm.invoice?.key}
              color="secondary"
            />
          }
          label={`Somente ${capitalizeString(extractFirstAndLastName(selectedCompany.name))}`}
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              value={orderForm.previaDanfe || false}
              onChange={() =>
                setOrderForm((oldFields) => ({
                  ...oldFields,
                  previaDanfe: !orderForm.previaDanfe,
                }))
              }
              checked={orderForm.previaDanfe || false}
              disabled={!!orderForm.invoice?.key}
              color="secondary"
            />
          }
          label="Prévia de DANFE"
        />
      </Grid>
    </Grid>
  );
}

export default OnlyCompanyTab;
