import { Chip, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';

const useStyles = makeStyles({
  select: {
    minWidth: 200,
  },
});

function TopBar({
  entryOrderId,
  company,
  type,
  handleChangeCompany,
  handleChangeType,
  setSelectedCompany,
}) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Chip color="primary" label={entryOrderId ? `Código: ${entryOrderId}` : 'Nova entrada'} />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <CompanyAutocomplete
              handleOnChange={handleChangeCompany}
              selectedCompany={company}
              setSelectedCompany={setSelectedCompany}
              error={!company}
              variant="filled"
              className={classes.select}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel>Tipo</InputLabel>
                <Select
                  fullWidth
                  className={classes.select}
                  onChange={(event) => handleChangeType(event.target.value)}
                  value={type || ''}
                  error={!type}
                >
                  <MenuItem value="Compra para revenda">Compra para revenda</MenuItem>
                  <MenuItem value="Importação para revenda">Importação para revenda</MenuItem>
                  <MenuItem value="Fabricação para revenda">Fabricação para revenda</MenuItem>
                  <MenuItem value="Devolução para revenda">Devolução para revenda</MenuItem>
                  <MenuItem value="Ativo imobilizado">Ativo imobilizado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(TopBar);
