import { Alert, Box, Button, Grid, Snackbar, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../Common/SimpleDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import WebmaniaNfResultsDialog from './WebmaniaNfResultsDialog';
import thermalPrinterOrders from '../../../services/melhorGestao/thermalPrinter';
import { formatHandleError } from '../../../helpers/formatData';
import getShippingStamps from '../../../services/melhorGestao/shipping';
import { changeOrderStatus } from '../../../services/melhorGestao/orders';
import { getMercadoLivreShippingStamp } from '../../../services/melhorGestao/mercadoLivre';
import { getMagazineLuizaShippingStamp } from '../../../services/melhorGestao/magazineLuiza';
import { getSkyhubShippingStamp } from '../../../services/melhorGestao/skyhub';
import { getShopeeShippingStamp } from '../../../services/melhorGestao/shopee';

function ProcessOrdersDialog({
  openDialogProcessOrders,
  handleCloseDialogProcessOrders,
  checkedOrders,
  handleRefreshAll,
}) {
  const [loadingProcessing, setLoadingProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [ordersToPrint, setOrdersToPrint] = useState([]);
  const [ordersToBilling, setOrdersToBilling] = useState([]);
  const [ordersToShipping, setOrdersToShipping] = useState([]);
  const [ordersWithInvoice, setOrdersWithInvoice] = useState([]);
  const [ordersToFinish, setOrdersToFinish] = useState([]);

  const getUpdatedOrderList = useCallback(async () => {
    try {
      setLoadingProcessing(true);
      const ordersToBillingArray = [];
      const ordersToShippingArray = [];
      const ordersWithInvoiceArray = [];
      const ordersToFinishArray = [];

      const response = await Axios.get('/orders/list/id', {
        params: {
          ordersIds: checkedOrders,
        },
      });

      const updatedOrderList = response.data.ordersList;
      setLoadingProcessing(false);

      updatedOrderList.forEach((order) => {
        if (order.status === 'Pedido confirmado') {
          ordersToBillingArray.push(order);
        }
        if (order.status === 'Pedido faturado') {
          ordersToShippingArray.push(order);
          if (order.shipping.method === 'Retirar na Loja') {
            ordersToFinishArray.push(order);
          }
        }
        if (order.invoice && order.invoice.key) {
          ordersWithInvoiceArray.push(order);
        }
        if (order.status === 'Pedido entregue') {
          ordersToFinishArray.push(order);
        }
        if (order.status === 'Pedidos embalado') {
          if (order.shipping.method === 'Retirar na Loja') {
            ordersToFinishArray.push(order);
          }
        }
      });

      setOrdersToPrint(updatedOrderList);
      setOrdersToBilling(ordersToBillingArray);
      setOrdersToShipping(ordersToShippingArray);
      setOrdersWithInvoice(ordersWithInvoiceArray);
      setOrdersToFinish(ordersToFinishArray);

      return handleRefreshAll();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar obter a lista de pedidos',
        error,
      });
    }
  }, [checkedOrders]);

  const [webmaniaNfResultList, setWebmaniaNfResultList] = useState([]);
  const [openWebmaniaNfResultsDialog, setOpenWebmaniaNfResultsDialog] = useState(false);
  const handleOpenWebmaniaNfResultsDialog = () => setOpenWebmaniaNfResultsDialog(true);
  const handleCloseWebmaniaNfResultsDialog = () => setOpenWebmaniaNfResultsDialog(false);

  const getOrdersInvoice = async () => {
    try {
      setLoadingProcessing(true);
      const responseWebmania = await Axios.post('/invoices', {
        ordersIds: ordersToBilling.map((order) => order.orderId),
      });

      if (responseWebmania.data && responseWebmania.data.length > 0) {
        setWebmaniaNfResultList(responseWebmania.data);
        handleOpenWebmaniaNfResultsDialog();
      }
      setLoadingProcessing(false);
      return getUpdatedOrderList();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu durante o faturamento dos pedidos.',
        error,
      });
    }
  };

  const printShippingStamp = async () => {
    try {
      setLoadingProcessing(true);
      const ordersToMercadoLivreShipping = [];
      const ordersToMagazineLuizaShipping = [];
      const ordersToSkyhubShipping = [];
      const ordersToShopeeShipping = [];
      const ordersToCarrierShipping = [];

      ordersToShipping.forEach((order) => {
        if (order.status === 'Pedido faturado') {
          if (order.shipping?.company === 'Mercado Livre') {
            ordersToMercadoLivreShipping.push(order);
          } else if (order.shipping?.company === 'Magazine Luiza') {
            ordersToMagazineLuizaShipping.push(order);
          } else if (order.shipping?.company === 'Americanas Entrega') {
            ordersToSkyhubShipping.push(order);
          } else if (order.shipping?.company === 'Shopee') {
            ordersToShopeeShipping.push(order);
          } else {
            ordersToCarrierShipping.push(order);
          }
        }
      });

      if (ordersToMercadoLivreShipping.length > 0) {
        getMercadoLivreShippingStamp({
          orders: ordersToMercadoLivreShipping,
          setLoadingShippingStamp: setLoadingProcessing,
          setSnackbar,
        });
      }
      if (ordersToMagazineLuizaShipping.length > 0) {
        getMagazineLuizaShippingStamp({
          orders: ordersToMagazineLuizaShipping,
          setLoadingShippingStamp: setLoadingProcessing,
          setSnackbar,
        });
      }
      if (ordersToSkyhubShipping.length > 0) {
        getSkyhubShippingStamp({
          orders: ordersToSkyhubShipping,
          setLoadingShippingStamp: setLoadingProcessing,
          setSnackbar,
        });
      }
      if (ordersToShopeeShipping.length > 0) {
        getShopeeShippingStamp({
          orders: ordersToShopeeShipping,
          setLoadingShippingStamp: setLoadingProcessing,
          setSnackbar,
        });
      }
      if (ordersToCarrierShipping.length > 0) {
        getShippingStamps({
          orders: ordersToCarrierShipping,
          setLoadingShippingStamp: setLoadingProcessing,
          setSnackbar,
        });
      }
      setLoadingProcessing(false);
      return getUpdatedOrderList();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu durante a impressão das etiquetas de entrega',
        error,
      });
    }
  };

  const printInvoiceStamp = async () => {
    try {
      setLoadingProcessing(true);
      const ordersToPrintInvoiceStamp = [];

      ordersWithInvoice.forEach((order) => {
        if (order.status === 'Pedido faturado') {
          ordersToPrintInvoiceStamp.push(order);
        }
      });

      await Axios.post('/invoices/stamps', {
        ordersIds: [...ordersToPrintInvoiceStamp.map((order) => order.orderId)],
      });

      setLoadingProcessing(false);
      setSnackbar({
        message: 'Impressão solicitada',
        open: true,
        type: 'success',
      });
      return getUpdatedOrderList();
    } catch (error) {
      setLoadingProcessing(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu durante a impressão de etiqueta DANFE.',
        error,
      });
    }
  };

  const finishOrders = async () => {
    const promiseFinishOrders = [];

    ordersToFinish.forEach((order) => {
      if (order.status !== 'Pedido concluído') {
        promiseFinishOrders.push(
          changeOrderStatus({
            orderId: order.orderId,
            toStatus: 'Pedido concluído',
            setLoadingUpdateOrder: setLoadingProcessing,
            setSnackbar,
          }),
        );
      }
    });

    await Promise.all(promiseFinishOrders);
    setOrdersToFinish([]);
    handleRefreshAll();
  };

  useEffect(() => {
    getUpdatedOrderList();
  }, []);

  return (
    <SimpleDialog
      content={
        <>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersToPrint.length > 0
                      ? 'Imprime os pedidos selecionados.'
                      : 'Nenhum pedido foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      thermalPrinterOrders({
                        setLoadingProcessing,
                        setSnackbar,
                        ordersToPrint,
                        withPrice: false,
                      })
                    }
                    disabled={!(ordersToPrint.length > 0)}
                  >
                    Imprimir pedidos
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersToBilling.length > 0
                      ? 'Busca ou emite a nota fiscal dos pedidos com situação de "Pedido confirmado". Envia a nota fiscal para os marketplaces e atualiza a situação do pedido para Pedido faturado.'
                      : 'Nenhum pedido com situação "Pedido confirmado" foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={getOrdersInvoice}
                    disabled={!(ordersToBilling.length > 0)}
                  >
                    Faturar pedidos confirmados
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersToShipping.length > 0
                      ? 'Caso o pedido esteja com a situação de "Pedido faturado" realiza a compra da etiqueta de entrega do Melhor Envio ou solicita as etiquetas de entrega dos marketplaces.'
                      : 'Nenhum pedido com situação "Pedido faturado" foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={printShippingStamp}
                    disabled={!(ordersToShipping.length > 0)}
                  >
                    Imprimir etiqueta de entrega
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersWithInvoice.length > 0
                      ? 'Imprime etiqueta de nota fiscal dos pedidos cujo a nota fiscal está emitida.'
                      : 'Nenhum pedido com nota fiscal emitida selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={printInvoiceStamp}
                    disabled={!(ordersWithInvoice.length > 0)}
                  >
                    Imprimir etiqueta DANFE
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">
                    {ordersToFinish.length > 0
                      ? 'Conclui pedidos faturados que já foram entregues.'
                      : 'Nenhum pedido entregue foi selecionado.'}
                  </Typography>
                }
              >
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={finishOrders}
                    disabled={!(ordersToFinish.length > 0)}
                  >
                    Concluir pedidos entregues
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          {webmaniaNfResultList ? (
            <WebmaniaNfResultsDialog
              openWebmaniaNfResultsDialog={openWebmaniaNfResultsDialog}
              handleCloseWebmaniaNfResultsDialog={handleCloseWebmaniaNfResultsDialog}
              webmaniaNfResultList={webmaniaNfResultList}
            />
          ) : null}
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <SimpleBackdrop loading={loadingProcessing} />
        </>
      }
      dialogTitle="Processar pedidos"
      handleClose={handleCloseDialogProcessOrders}
      openDialog={openDialogProcessOrders}
    />
  );
}

export default ProcessOrdersDialog;
