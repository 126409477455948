import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { deleteMercadoLivreProduct } from '../../../../../services/melhorGestao/mercadoLivre';
import { formatHandleError } from '../../../../../helpers/formatData';

function DialogIconDeleteMercadoLivreAd({
  mercadoLivreAds,
  setRefreshSearch,
  setSnackbar = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [openDialogDeleteAd, setOpenDialogDeleteAd] = useState(false);

  const handleDeleteMercadoLivreProduct = async () => {
    try {
      setLoading(true);
      await deleteMercadoLivreProduct(mercadoLivreAds.mlb);
      setLoading(false);
      setRefreshSearch(Math.random());
      setOpenDialogDeleteAd(false);
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao excluir anúncio',
        error,
      });
    }
  };

  return (
    <>
      <Tooltip
        title={
          <Typography align="center">Excluir permanentemente o anúncio do Mercado Livre</Typography>
        }
      >
        <IconButton size="small" onClick={() => setOpenDialogDeleteAd(true)}>
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      {openDialogDeleteAd ? (
        <SimpleDialog
          cancelButtonText="Cancelar"
          content={
            <Grid container>
              <Grid item>
                <Typography align="center">
                  Você está prestes a excluir permanentemente um anúncio
                </Typography>
                <Typography align="center">
                  do Mercado Livre, esta ação não poderá ser desfeita.
                </Typography>
              </Grid>
            </Grid>
          }
          confirmButtonText="Excluir"
          dialogTitle="Excluir anúncio do Mercado Livre"
          handleCancelButton={() => setOpenDialogDeleteAd(false)}
          handleClose={() => setOpenDialogDeleteAd(false)}
          handleConfirmButton={handleDeleteMercadoLivreProduct}
          confirmButtonLoading={loading}
          openDialog={openDialogDeleteAd}
        />
      ) : null}
    </>
  );
}

export default DialogIconDeleteMercadoLivreAd;
