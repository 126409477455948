import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { numberToRealWithPeriod } from '../../../../../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  boxBaseCost: {
    height: '30px',
    backgroundColor: '#09111f',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxIcms: {
    height: '30px',
    backgroundColor: '#0f2950',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxIpi: {
    height: '30px',
    backgroundColor: '#1c325d',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxSt: {
    height: '30px',
    backgroundColor: '#455683',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxShipping: {
    height: '30px',
    backgroundColor: '#5e6c99',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxOthersCost: {
    height: '30px',
    backgroundColor: '#6170a1',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxSimples: {
    height: '30px',
    backgroundColor: '#c3d0fd',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
  boxProfit: {
    backgroundColor: theme.palette.secondary.main,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

function GraphBar({
  priceValue,
  costValue,
  difalValue,
  ipiValue,
  stValue,
  shippingValue,
  othersCostValue,
  simplesValue,
  profitValue,
}) {
  const classes = useStyles();

  const maxBarSize = 500;

  return (
    <Grid container>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Preço de compra</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(costValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxBaseCost}
            style={{
              width: (costValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Diferença de Alíquota</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(difalValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxIcms}
            style={{
              width: (difalValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">IPI</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(ipiValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxIpi}
            style={{
              width: (ipiValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">ST</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(stValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxSt}
            style={{
              width: (stValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Frete</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(shippingValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxShipping}
            style={{
              width: (shippingValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Outros</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(othersCostValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxOthersCost}
            style={{
              width: (othersCostValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Simples Nacional</Typography>
              <Typography align="center">
                R$ {numberToRealWithPeriod(simplesValue * priceValue)}
              </Typography>
            </>
          }
        >
          <Box
            className={classes.boxSimples}
            style={{
              width: ((simplesValue * priceValue) / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography align="center">Margem de Lucro</Typography>
              <Typography align="center">R$ {numberToRealWithPeriod(profitValue)}</Typography>
            </>
          }
        >
          <Box
            className={classes.boxProfit}
            style={{
              width: (profitValue / priceValue) * maxBarSize,
              height: 30,
            }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default GraphBar;
