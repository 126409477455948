/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Grid, Typography } from '@mui/material';
import React, { memo } from 'react';
import OrderStatusTypography from '../../../Orders/elements/OrderStatusTypography';
import InvoiceBlok from './InvoiceBlok';

function StatusTab({ status, statusPending, handleUploadXml, invoice }) {
  return (
    <Box flexGrow={1}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Grid container direction="column">
                <Typography variant="caption">Situação</Typography>
                <OrderStatusTypography orderStatus={status || 'Pedido concluído'} />
                <Typography variant="caption">{statusPending || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {invoice && invoice.key ? (
            <Grid item xs={12}>
              <InvoiceBlok invoice={invoice} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <input accept="text/xml" id="image-upload" type="file" onChange={handleUploadXml} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(StatusTab);
