import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { BarChart } from '@mui/x-charts';
import CategoryPaymentManager from '../../../Common/CategoryPaymentManager';
import FinanceResumeTopBar from '../TopBar';
import { formatHandleError, numberToRealWithPeriod } from '../../../../helpers/formatData';

function FinanceResumeCategories() {
  const [company, setCompany] = useState(null);
  const [category, setCategory] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));

  const emptyResume = {
    year: {
      totalReceipt: 0,
      totalPayment: 0,
      countReceipt: 0,
      countPayment: 0,
    },
    months: [
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Jan',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Fev',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Mar',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Abr',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Mai',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Jun',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Jul',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Ago',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Set',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Out',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Nov',
      },
      {
        totalReceipt: 0,
        totalPayment: 0,
        month: 'Dez',
      },
    ],
  };

  const [totalFinances, setTotalFinances] = useState(emptyResume);
  const [yearNumber, setYearNumber] = useState(dayjs(startDate).year());

  const chartSetting = {
    width: 600,
    height: 300,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseTotal = await Axios.get('/financial/resume/values', {
          params: {
            company: company?._id ? company?._id : '',
            category: category?._id ? category?._id : '',
            date: startDate,
          },
        });

        const financesResumeTotal = responseTotal.data.financesResume;

        setTotalFinances(financesResumeTotal);
        setYearNumber(financesResumeTotal.year.yearNumber);
      } catch (error) {
        formatHandleError({
          setSnackbar: () => {},
          defaultMessage: 'Algum erro ocorreu ao buscar resumo do financeiro',
          error,
        });
      }
    };
    fetchData();
  }, [company, category, startDate]);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <FinanceResumeTopBar
          company={company}
          setCompany={setCompany}
          preferenceDate={startDate}
          setPreferenceDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
      <Box marginTop={2} marginBottom={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Paper>
              <Box padding={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5">Pagamento</Typography>
                  </Grid>
                  <Grid item>
                    <CategoryPaymentManager
                      setSnackbar={() => {}}
                      onChange={(value) => setCategory(value)}
                      type="Pagamento"
                      disableCreateCategory
                      finance={{
                        company: company?._id ? company?._id : '',
                        user: '',
                        startDate,
                        endDate,
                        payed: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">Recebimento</Typography>
                  </Grid>
                  <Grid item>
                    <CategoryPaymentManager
                      setSnackbar={() => {}}
                      onChange={(value) => setCategory(value)}
                      type="Recebimento"
                      disableCreateCategory
                      finance={{
                        company: company?._id ? company?._id : '',
                        user: '',
                        startDate,
                        endDate,
                        payed: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <Paper>
              <Box padding={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h5">
                      {category && category.name ? `${category.name} em ` : 'Total de todos em '}
                      {yearNumber}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <BarChart
                      dataset={totalFinances.months.map((financeMonth) => ({
                        ...financeMonth,
                        month: financeMonth.month.substring(0, 3),
                      }))}
                      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                      series={[
                        {
                          dataKey: 'totalReceipt',
                          label: 'Recebimento',
                          valueFormatter: (value) => `R$ ${numberToRealWithPeriod(value)}`,
                          color: '#4285F4',
                        },
                        {
                          dataKey: 'totalPayment',
                          label: 'Pagamento',
                          valueFormatter: (value) => `R$ ${numberToRealWithPeriod(value)}`,
                          color: '#EA4335',
                        },
                      ]}
                      {...chartSetting}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default FinanceResumeCategories;
