import Axios from 'axios';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../../Common/SimpleDialog';
import {
  capitalizeString,
  formatDateWithHour,
  formatHandleError,
  numberToRealWithPeriod,
} from '../../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  underlineText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

function OthersInvoicesButton({ orderId, setSnackbar }) {
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);

  const [openDialogInvoices, setOpenDialogInvoices] = useState(false);
  const handleOpenDialogInvoices = () => setOpenDialogInvoices(true);
  const handleCloseDialogInvoices = () => setOpenDialogInvoices(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`/invoices/${orderId}`);
        const { emittedInvoices } = response.data;

        setInvoices(emittedInvoices);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar notas fiscais do pedido',
          error,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <Box>
      <Typography
        onClick={handleOpenDialogInvoices}
        variant="caption"
        className={classes.underlineText}
      >
        Ver Todas
      </Typography>
      {openDialogInvoices ? (
        <SimpleDialog
          openDialog={openDialogInvoices}
          handleClose={handleCloseDialogInvoices}
          dialogTitle="Notas fiscais vinculadas a este pedido"
          content={
            <Box width={900}>
              <Paper elevation={4}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Número</TableCell>
                      <TableCell align="center">Acesso</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Total da Nota</TableCell>
                      <TableCell align="center">Operação</TableCell>
                      <TableCell align="center">Modelo</TableCell>
                      <TableCell align="center">Motivo</TableCell>
                      <TableCell align="center">Data Emissão</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices && invoices.length > 0
                      ? invoices.map((invoice) => (
                          <TableRow key={invoice.key}>
                            <TableCell>{invoice.number}</TableCell>
                            <TableCell align="center">
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Chip
                                    onClick={() => window.open(invoice.danfe)}
                                    size="small"
                                    label="DANFE"
                                  />
                                </Grid>
                                <Grid item>
                                  <Chip
                                    onClick={() => window.open(invoice.xml)}
                                    size="small"
                                    label="XML"
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="center">{capitalizeString(invoice.status)}</TableCell>
                            <TableCell align="center">
                              R${numberToRealWithPeriod(invoice.totalValue)}
                            </TableCell>
                            <TableCell align="center">{invoice.operation}</TableCell>
                            <TableCell align="center">{invoice.model}</TableCell>
                            <TableCell align="center">{invoice.reason}</TableCell>
                            <TableCell align="center">
                              {formatDateWithHour(invoice.emissionDate)}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Box>
          }
        />
      ) : null}
    </Box>
  );
}

export default OthersInvoicesButton;
