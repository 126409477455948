import React from 'react';
import ChequeDetails from '../../../Cheques/Details';
import newCheque from '../../../../constant/newCheque';

function ChequeDialog({
  payDate,
  openChequeDialog,
  handleCloseChequeDialog,
  handleSelectChequeToPayment,
}) {
  const handleConfirmCheque = (cheque) => {
    if (cheque.barCode) {
      return handleSelectChequeToPayment(cheque);
    }

    return null;
  };

  return (
    <ChequeDetails
      goodFor={payDate}
      openDialogDetail={openChequeDialog}
      handleCloseDialogDetail={handleCloseChequeDialog}
      selectedCheque={newCheque}
      handleConfirmCheque={handleConfirmCheque}
    />
  );
}

export default ChequeDialog;
