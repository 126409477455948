import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { makeStyles } from '@mui/styles';
import ProfitabilityButton from '../../../Orders/Details/CustomerTab/ProfitabilityButton';
import { useAuth } from '../../../../contexts/auth';
import {
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../helpers/formatData';
import CustomInput from '../../../CustomInput';
import getTasks from '../../../../services/melhorGestao/tasks';
import CreditDialog from './CreditDialog';
import CreditHistoryDialog from './CreditHistoryDialog';
import ProfitabilityBlok from '../../../Orders/Details/CustomerTab/ProfitabilityBlok';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 4,
  },
});

function FinancialTab({ userForm, setUserForm, setInitialUserForm, refreshOnSave, setSnackbar }) {
  const classes = useStyles();

  const loggedUser = useAuth().user;

  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const handleOpenCreditDialog = () => setOpenCreditDialog(true);
  const handleCloseCreditDialog = () => setOpenCreditDialog(false);

  const [openDialogCreditHistory, setOpenDialogCreditHistory] = useState(false);
  const handleOpenDialogCreditHistory = () => setOpenDialogCreditHistory(true);
  const handleCloseDialogCreditHistory = () => setOpenDialogCreditHistory(false);

  const [creditManagerTask, setCreditManagerTask] = useState(false);

  useEffect(() => {
    if (userForm.userId) {
      const fetchData = async () => {
        if (loggedUser) {
          const tasksStaff = await getTasks({ userForm: loggedUser });

          const getTask = tasksStaff.find((task) => task.name === 'Gerenciar financeiro');
          if (getTask && getTask.active) {
            setCreditManagerTask(getTask.active);
          }
        }
      };
      fetchData();
    }
  }, []);

  const handleChangeNegativeCreditLimit = (event) => {
    const newTotalValue = onlyNumbersValue(event.target.value);

    setUserForm((oldFields) => ({
      ...oldFields,
      credit: {
        ...oldFields.credit,
        negativeCreditLimit: formatReal(newTotalValue),
      },
    }));
  };

  const [totalSelected, setTotalSelected] = useState(0);
  const [totalCost, setCostTotal] = useState(0);

  const [profitability, setProfitability] = useState(0);
  const [profitabilityPercentage, setProfitabilityPercentage] = useState(0);

  const [loadingProfitability, setLoadingProfitability] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Financeiro</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userForm.monthlyPayer || false}
                        onChange={() =>
                          setUserForm(() => ({
                            ...userForm,
                            monthlyPayer: !userForm.monthlyPayer,
                          }))
                        }
                        size="small"
                      />
                    }
                    label="Mensalista"
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                <ProfitabilityButton
                  customer={userForm}
                  setSnackbar={setSnackbar}
                  totalSelected={totalSelected}
                  setTotalSelected={setTotalSelected}
                  totalCost={totalCost}
                  setCostTotal={setCostTotal}
                  profitability={profitability}
                  setProfitability={setProfitability}
                  profitabilityPercentage={profitabilityPercentage}
                  setProfitabilityPercentage={setProfitabilityPercentage}
                  setLoadingProfitability={setLoadingProfitability}
                />
              </Grid>
              <Tooltip title={<Typography align="center">Gerenciar crédito</Typography>}>
                <Grid item>
                  <IconButton
                    onClick={handleOpenCreditDialog}
                    disabled={!creditManagerTask}
                    size="small"
                  >
                    <MonetizationOnOutlinedIcon />
                  </IconButton>
                </Grid>
              </Tooltip>
              <Tooltip title={<Typography align="center">Movimentação de crédito</Typography>}>
                <Grid item>
                  <IconButton onClick={handleOpenDialogCreditHistory} size="small">
                    <HistoryIcon />
                  </IconButton>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <ProfitabilityBlok
            totalSelected={totalSelected}
            totalCost={totalCost}
            profitability={profitability}
            profitabilityPercentage={profitabilityPercentage}
            loadingProfitability={loadingProfitability}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <CustomInput
              label="Limite de crédito a prazo"
              value={numberToRealWithPeriod(userForm.credit?.negativeCreditLimit || 0)}
              onChange={handleChangeNegativeCreditLimit}
              InputStartAdornment="R$"
              InputAdornmentStyle={classes.inputAdornment}
              disabled={!creditManagerTask}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" spacing={4}>
              <Grid item>
                <Typography variant="caption">Débito a pagar</Typography>
                <Typography variant="h3" style={{ color: '#ff2222' }}>
                  R$ {numberToRealWithPeriod(userForm.credit?.totalPending || 0)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Crédito</Typography>
                <Typography
                  variant="h3"
                  style={{ color: userForm.credit?.totalValue < 0 ? '#ff2222' : '#0d00fc' }}
                >
                  R$ {numberToRealWithPeriod(userForm.credit?.totalValue || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CreditDialog
        openCreditDialog={openCreditDialog}
        handleCloseCreditDialog={handleCloseCreditDialog}
        userForm={userForm}
        setUserForm={setUserForm}
        setInitialUserForm={setInitialUserForm}
        refreshOnSave={refreshOnSave}
      />
      <CreditHistoryDialog
        openDialogCreditHistory={openDialogCreditHistory}
        handleCloseDialogCreditHistory={handleCloseDialogCreditHistory}
        userForm={userForm}
      />
    </Grid>
  );
}

export default FinancialTab;
