import { Autocomplete, TextField } from '@mui/material';
import React, { memo } from 'react';

function CustomSelect({
  label,
  helperText,
  comboOptions,
  allowCustomValue,
  value = '',
  name,
  disabled,
  onInputChange = () => {},
  onChange = () => {},
}) {
  return (
    <Autocomplete
      id={name}
      fullWidth
      value={value}
      onInputChange={(event, newInputValue) => {
        onInputChange({
          target: {
            name,
            value: newInputValue,
          },
        });
      }}
      onChange={(event, newValue) => {
        onChange({
          target: {
            name,
            value: newValue,
          },
        });
      }}
      disabled={disabled}
      inputValue={value === -1 ? 'Não se aplica' : value}
      noOptionsText="Opção não encontrada"
      freeSolo={allowCustomValue || false}
      options={comboOptions ? comboOptions.map((option) => option.name) : null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
}

export default memo(CustomSelect);
