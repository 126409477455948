import { Box, Typography } from '@mui/material';
import React from 'react';
import SimpleDialog from '../../../../../Common/SimpleDialog';

function UnblockDialog({ openDialogUnblock, handleCloseDialogUnblock, setOrderForm }) {
  const handleConfirmUnblock = () => {
    setOrderForm((oldFields) => ({
      ...oldFields,
      unblockDiscount: true,
    }));

    handleCloseDialogUnblock();
  };

  return (
    <SimpleDialog
      openDialog={openDialogUnblock}
      content={
        <Box minWidth="250px">
          <Typography>
            O pedido foi bloqueado pelo desconto/rentabilidade, deseja liberar?
          </Typography>
        </Box>
      }
      dialogTitle="Liberar pedido"
      handleClose={handleCloseDialogUnblock}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogUnblock}
      handleConfirmButton={handleConfirmUnblock}
    />
  );
}

export default UnblockDialog;
