import React, { useEffect, useState } from 'react';
import { Grid, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function CustomPagination({ page, total, limit, handleChangePage, showInput = true }) {
  const [pageNumber, setPageNumber] = useState(page);

  const handleChangeManuallyPage = (event) => {
    const { value } = event.target;
    setPageNumber(Number(value));
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && pageNumber > 0 && pageNumber <= Math.ceil(total / limit)) {
      handleChangePage(event, pageNumber);
    }
  };

  useEffect(() => {
    setPageNumber(page);
  }, [page]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          color="primary"
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Grid>
      {showInput ? (
        <Grid item>
          <TextField
            style={{ width: 100 }}
            size="small"
            value={pageNumber}
            variant="outlined"
            multiline={false}
            onChange={handleChangeManuallyPage}
            onKeyDown={onKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: -15 }}>
                  <IconButton onClick={(event) => handleChangePage(event, pageNumber)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default CustomPagination;
