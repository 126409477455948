import { Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { numberToRealWithPeriod } from '../../../../../helpers/formatData';

function ProfitabilityBlok({
  totalSelected,
  totalCost,
  profitability,
  profitabilityPercentage,
  loadingProfitability,
}) {
  return (
    <>
      <Grid item xs={8}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Vendas / Custos neste mês</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                {loadingProfitability ? (
                  <Skeleton animation="wave" width={100} height={25} />
                ) : (
                  <Typography style={{ color: '#00c600' }}>
                    R$ {numberToRealWithPeriod(totalSelected)}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography>/</Typography>
              </Grid>
              <Grid item>
                {loadingProfitability ? (
                  <Skeleton animation="wave" width={100} height={25} />
                ) : (
                  <Typography style={{ color: '#ff2222' }}>
                    R$ {numberToRealWithPeriod(totalCost)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="column">
          <Typography variant="caption">Rentabilidade neste mês</Typography>
          {loadingProfitability ? (
            <Skeleton animation="wave" width={100} height={25} />
          ) : (
            <Typography variant="h2">
              R$ {numberToRealWithPeriod(profitability)} ({profitabilityPercentage}%)
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ProfitabilityBlok;
