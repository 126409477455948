import { Autocomplete, TextField, Typography } from '@mui/material';
import Axios from 'axios';
import React, { useState, memo, useEffect } from 'react';

function CityAutocomplete({
  handleOnChange = () => {},
  selectedCity,
  setSelectedCity = () => {},
  size = 'small',
  label = 'Filtrar por cidade',
  variant,
  className,
}) {
  const [searchText, setSearchText] = useState('');
  const [citiesList, setCitiesList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadingCitiesList, setLoadingCitiesList] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (typeof selectedCity === 'object') {
        setSelectedCity(selectedCity);
        return setSelectedValue(selectedCity);
      }
      setSelectedCity(null);
      return setSelectedValue(null);
    };
    fetchData();
  }, [selectedCity]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCitiesList(true);
        const cities = await Axios.get('/mobile-admin/autocomplete', {
          params: {
            type: 'cities',
            seller: JSON.parse(localStorage.getItem('user'))._id,
            searchText,
          },
        });

        setCitiesList(cities.data);
        setLoadingCitiesList(false);
      } catch (err) {
        setLoadingCitiesList(false);
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <Autocomplete
      size={size}
      options={citiesList}
      getOptionLabel={(option) => option.cityName}
      isOptionEqualToValue={(option, value) => option.cityName === value.cityName}
      renderOption={(props, option) => (
        <Typography {...{ ...props, key: option.cityName }} variant="caption">
          {option.cityName}
        </Typography>
      )}
      value={selectedValue}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      onInputChange={(_, newInputValue) => {
        setSearchText(newInputValue);
      }}
      loading={loadingCitiesList}
      renderInput={(params) => <TextField {...params} label={label} variant={variant} />}
      className={className}
    />
  );
}

export default memo(CityAutocomplete);
