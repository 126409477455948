import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';

function VolumesDialog({
  volumes,
  openVolumesDialog,
  handleCloseVolumesDialog,
  setOrderForm,
  orderIsInvoiced,
}) {
  const options = ['Volume', 'Perfil'];

  const [newVolume, setNewVolume] = useState({
    type: options[0],
    quantity: 1,
  });

  const [volumesList, setVolumesList] = useState(volumes || []);
  const [totalVolumes, setTotalVolumes] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'error' });

  useEffect(() => {
    setTotalVolumes(
      volumes && volumes.length > 0
        ? volumes.reduce((acc, volume) => acc + volume.quantity, 0)
        : volumesList.reduce((acc, volume) => acc + volume.quantity, 0),
    );
  }, [volumesList]);

  const handleChangeVolumeType = (_, newValue) =>
    setNewVolume((oldFields) => ({
      ...oldFields,
      type: newValue,
    }));

  const handleChangeVolumeQuantity = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value) {
      setNewVolume((oldFields) => ({
        ...oldFields,
        quantity: value,
      }));
    }
  };

  const handleAddVolumeAtList = () => {
    if (!newVolume.quantity || !newVolume.type) {
      return setSnackbar({
        open: true,
        message: 'Informe o tipo e a quantidade corretamente',
        type: 'error',
      });
    }

    const existingVolumeIndex = volumesList.findIndex((volume) => volume.type === newVolume.type);

    if (existingVolumeIndex >= 0) {
      const updatedVolumesList = [...volumesList];
      updatedVolumesList[existingVolumeIndex].quantity += newVolume.quantity;
      setVolumesList(updatedVolumesList);
      return setNewVolume({ type: newVolume.type, quantity: 1 });
    }

    setVolumesList([...volumesList, newVolume]);
    return setNewVolume({ type: newVolume.type, quantity: 1 });
  };

  const handleRemoveVolumeFromAtList = (type) => {
    const updatedVolumes = volumesList.filter((volume) => volume.type !== type);
    setVolumesList(updatedVolumes);
  };

  const confirmVolumes = () => {
    if (!volumesList.length) {
      return setSnackbar({
        open: true,
        message: 'Informe os volumes para confirmar',
        type: 'error',
      });
    }
    setOrderForm((oldFields) => ({
      ...oldFields,
      shipping: {
        ...oldFields.shipping,
        volumes: { volumesList },
      },
    }));
    return handleCloseVolumesDialog();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', type: 'error' });
  };

  return (
    <SimpleDialog
      openDialog={openVolumesDialog}
      handleClose={handleCloseVolumesDialog}
      dialogTitle={`Volumes (${totalVolumes})`}
      maxWidth="xs"
      dialogText="Defina quais e quantos volumes compõem esse pedido"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      disableConfirmButton={orderIsInvoiced}
      handleCancelButton={handleCloseVolumesDialog}
      handleConfirmButton={confirmVolumes}
      content={
        <Grid container spacing={1} alignItems="end">
          {volumesList && volumesList.length > 0
            ? volumesList.map((volume, index) => (
                <Grid item key={volume.type}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4}>
                      <CustomInput
                        value={volume.type}
                        name={String(index)}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomInput
                        value={volume.quantity}
                        name={String(index)}
                        variant="outlined"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => handleRemoveVolumeFromAtList(volume.type)}
                        variant="contained"
                        color="default"
                        disabled={orderIsInvoiced}
                      >
                        Remover
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : null}
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              options={options}
              value={newVolume.type}
              onChange={handleChangeVolumeType}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              noOptionsText="Opção não encontrada"
              renderOption={(props, option) => (
                <Typography {...{ ...props, key: option }} variant="caption">
                  {option}
                </Typography>
              )}
              renderInput={(params) => <TextField {...params} />}
              disabled={orderIsInvoiced}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Quantidade"
              variant="outlined"
              value={newVolume.quantity}
              onChange={handleChangeVolumeQuantity}
              inputProps={{ maxLength: 2 }}
              disabled={orderIsInvoiced}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={handleAddVolumeAtList}
              variant="contained"
              color="primary"
              disabled={orderIsInvoiced}
            >
              Adicionar
            </Button>
          </Grid>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
      }
    />
  );
}

export default VolumesDialog;
