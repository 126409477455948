const filterProductOptionsInitial = [
  {
    id: 'showOnlyAboveMinimumStock',
    description: 'Estoque real abaixo do mínimo',
    active: false,
  },
  {
    id: 'showOnlyPositiveFiscalStock',
    description: 'Estoque fiscal positivo',
    active: false,
  },
  {
    id: 'showOnlyNegativeFiscalStock',
    description: 'Estoque fiscal negativo',
    active: false,
  },
  {
    id: 'showOnlySuperFiscalStock',
    description: 'Estoque fiscal acima do real',
    active: false,
  },
  {
    id: 'showOnlyWrongFiscalStock',
    description: 'Estoque fiscal diferente do real',
    active: false,
  },
];

export default filterProductOptionsInitial;
