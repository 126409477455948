import Axios from 'axios';
import { Box, Button, Grid, Tooltip, Typography, Link as LinkMaterial } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { formatHandleError, numberToReal } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import ShippingDialog from './ShippingDialog';
import VolumesDialog from './VolumesDialog';
import getShippingStamps from '../../../../services/melhorGestao/shipping';
import { getUser } from '../../../../services/melhorGestao/users';
import CustomDivider from '../../../CustomDivider';
import { getMercadoLivreShippingStamp } from '../../../../services/melhorGestao/mercadoLivre';
import { getMagazineLuizaShippingStamp } from '../../../../services/melhorGestao/magazineLuiza';
import { getSkyhubShippingStamp } from '../../../../services/melhorGestao/skyhub';
import { getShopeeShippingStamp } from '../../../../services/melhorGestao/shopee';

const useStyles = makeStyles({
  companyPicture: {
    height: 25,
  },
});

function ShippingTab({ orderForm, setOrderForm, handleSaveForm, formChanged, setSnackbar }) {
  const classes = useStyles();

  const [trackingMelhorEnvio, setTrackingMelhorEnvio] = useState('');
  const handleChangeIdMelhorEnvio = (event) => setTrackingMelhorEnvio(event.target.value);
  const [openDialogIdMelhorEnvio, setOpenDialogIdMelhorEnvio] = useState(false);

  const handleOpenDialogIdMelhorEnvio = async () => {
    if (orderForm.othersIds && orderForm.othersIds.idMelhorEnvio) {
      const response = await Axios.get('/shipping/melhorenvio-orders', {
        params: {
          searchText: orderForm.othersIds.idMelhorEnvio,
        },
      });

      const melhorEnvioOrders = response.data;

      if (melhorEnvioOrders.data && melhorEnvioOrders.data.length) {
        setTrackingMelhorEnvio(
          melhorEnvioOrders.data[0].tracking || melhorEnvioOrders.data[0].authorization_code,
        );
      }
    }
    setOpenDialogIdMelhorEnvio(true);
  };

  const handleCloseDialogIdMelhorEnvio = () => setOpenDialogIdMelhorEnvio(false);

  const handleConfirmIdMelhorEnvio = async () => {
    try {
      const response = await Axios.get('/shipping/melhorenvio-orders', {
        params: {
          searchText: trackingMelhorEnvio,
        },
      });
      const melhorEnvioOrders = response.data;

      if (melhorEnvioOrders.data && melhorEnvioOrders.data.length) {
        handleSaveForm({
          updateFields: {
            othersIds: {
              ...orderForm.othersIds,
              idMelhorEnvio: melhorEnvioOrders.data[0].id,
            },
          },
        });

        setOpenDialogIdMelhorEnvio(false);
      } else {
        setSnackbar({
          message: 'Não encontramos no Melhor Envio este rastreio',
          open: true,
          type: 'error',
        });
      }
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar o rastreio no Melhor Envio',
        error,
      });
    }
  };

  const [openDialogShipping, setOpenDialogShipping] = useState(false);
  const handleOpenDialogShipping = useCallback(() => setOpenDialogShipping(true), []);
  const handleCloseDialogShipping = useCallback(() => setOpenDialogShipping(false), []);

  const handleSelectShipping = useCallback(
    ({ shipping }) => {
      setOrderForm((oldFields) => ({
        ...oldFields,
        shipping,
      }));

      return handleCloseDialogShipping();
    },
    [handleCloseDialogShipping],
  );

  const [openVolumesDialog, setOpenVolumesDialog] = useState(false);
  const handleOpenVolumesDialog = () => setOpenVolumesDialog(true);
  const handleCloseVolumesDialog = () => setOpenVolumesDialog(false);

  const [loadingPrintInvoiceStamp, setLoadingPrintInvoiceStamp] = useState(false);

  const handlePrintDanfeStamp = async () => {
    try {
      setLoadingPrintInvoiceStamp(true);
      await Axios.post(`/invoices/stamps`, { ordersIds: [orderForm.orderId] });
      setLoadingPrintInvoiceStamp(false);

      setSnackbar({
        message: 'Impressão solicitada',
        open: true,
        type: 'success',
      });
    } catch (error) {
      setLoadingPrintInvoiceStamp(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar imprimir a DANFE',
        error,
      });
    }
  };

  const [loadingPrintOrderStamp, setLoadingPrintOrderStamp] = useState(false);
  const [openDialogConfirmPrintOrderStamp, setOpenDialogConfirmPrintOrderStamp] = useState(false);

  const handleOpenDialogConfirmPrintOrderStamp = () => {
    if (!orderForm.shipping.volumes.volumesList.length) {
      return setSnackbar({
        open: true,
        message: 'Preencha as informações de volume do pedido',
        type: 'error',
      });
    }
    return setOpenDialogConfirmPrintOrderStamp(true);
  };

  const handleCloseDialogConfirmPrintOrderStamp = () => setOpenDialogConfirmPrintOrderStamp(false);

  const printOrderStamp = async () => {
    try {
      setLoadingPrintOrderStamp(true);

      await Axios.post('/orders/stamps', {
        ordersIds: [orderForm.orderId],
        labelType: 'order',
      });

      setLoadingPrintOrderStamp(false);

      setSnackbar({
        message: 'Impressão solicitada',
        open: true,
        type: 'success',
      });

      return handleCloseDialogConfirmPrintOrderStamp();
    } catch (error) {
      setLoadingPrintOrderStamp(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu tentar imprimir a etiqueta',
        error,
      });

      return handleCloseDialogConfirmPrintOrderStamp();
    }
  };

  const [loadingShippingStamp, setLoadingShippingStamp] = useState(false);

  const handleOpenDialogConfirmPrintStamp = async () => {
    if (orderForm.shipping?.company === 'Mercado Livre') {
      return getMercadoLivreShippingStamp({
        orders: [orderForm],
        setLoadingShippingStamp,
        setSnackbar,
      });
    }
    if (orderForm.shipping?.company === 'Magazine Luiza') {
      return getMagazineLuizaShippingStamp({
        orders: [orderForm],
        setLoadingShippingStamp,
        setSnackbar,
      });
    }
    if (orderForm.shipping?.company === 'Americanas Entrega') {
      return getSkyhubShippingStamp({
        orders: [orderForm],
        setLoadingShippingStamp,
        setSnackbar,
      });
    }
    if (orderForm.shipping?.company === 'Shopee') {
      return getShopeeShippingStamp({
        orders: [orderForm],
        setLoadingShippingStamp,
        setSnackbar,
      });
    }
    return getShippingStamps({
      orders: [orderForm],
      setLoadingShippingStamp,
      setSnackbar,
    });
  };

  const [blockPrintStampMessage, setBlockPrintStampMessage] = useState('');
  const [blockPrintStamp, setBlockPrintStamp] = useState(false);

  useEffect(() => {
    if (orderForm.shipping) {
      if (orderForm.status === 'Pedido faturado' || orderForm.status === 'Pedido embalado') {
        setBlockPrintStampMessage(`Imprimir etiqueta ${orderForm.shipping.company}`);
        return setBlockPrintStamp(false);
      }
    }
    if (orderForm.status === 'Pedido criado' || orderForm.status === 'Pedido confirmado') {
      setBlockPrintStampMessage('Para imprimir etiqueta o pedido precisa estar faturado.');
      return setBlockPrintStamp(true);
    }
    if (orderForm.status === 'Pedido enviado' || orderForm.status === 'Pedido entregue') {
      setBlockPrintStampMessage('Este pedido já foi enviado.');
      return setBlockPrintStamp(true);
    }
    setBlockPrintStampMessage('Possívelmente esse pedido já foi concluído.');
    return setBlockPrintStamp(true);
  }, [orderForm.shipping, orderForm.status]);

  const getDefaultShippingMethod = async () => {
    const user = await getUser(orderForm.customer.userId);

    if (!user || !user?.addresses || user.addresses.length === 0) {
      return null;
    }

    try {
      const response = await Axios.get(`/shipping/get-shipping-values`, {
        params: {
          products: orderForm.items.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          })),
          postalCode: user.addresses[0].postalCode,
          source: orderForm.source,
        },
      });

      const { shippingValues } = response.data;

      const shipping = {
        ...orderForm.shipping,
        ...shippingValues[0],
        address: user.addresses[0],
      };

      return handleSelectShipping({ shipping });
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar os fretes',
        error,
      });
    }
  };

  useEffect(() => {
    if (
      !orderForm.orderId &&
      orderForm.customer &&
      orderForm.customer.userId &&
      orderForm.items.length > 0
    ) {
      getDefaultShippingMethod();
    }
  }, [orderForm.customer, orderForm.items]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Entrega</Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                {orderForm.othersIds && orderForm.othersIds.idMelhorEnvio ? (
                  <Button variant="contained" onClick={handleOpenDialogIdMelhorEnvio}>
                    Atualizar rastreio
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {orderForm.customer && !orderForm.customer.name
                        ? 'Para selecionar a entrega você precisa escolher o cliente.'
                        : null}
                      {orderForm.customer &&
                      orderForm.customer.name &&
                      orderForm.items &&
                      orderForm.items.length === 0 &&
                      orderForm.status !== 'Pedido devolvido'
                        ? 'Para selecionar a entrega você precisa escolher os produtos.'
                        : null}
                      {orderForm.customer &&
                      orderForm.customer.name &&
                      orderForm.items &&
                      orderForm.items.length > 0 &&
                      (orderForm.status === 'Orçamento' ||
                        orderForm.status === 'Pedido criado' ||
                        orderForm.status === 'Pedido confirmado' ||
                        orderForm.status === 'Pedido embalado') &&
                      orderForm.status !== 'Pedido devolvido'
                        ? 'Selecione um método de entrega'
                        : null}
                      {(orderForm.items &&
                        orderForm.items.length > 0 &&
                        orderForm.status !== 'Orçamento' &&
                        orderForm.status !== 'Pedido criado' &&
                        orderForm.status !== 'Pedido confirmado' &&
                        orderForm.status !== 'Pedido embalado') ||
                      orderForm.status === 'Pedido devolvido'
                        ? 'A entrega não pode ser alterada.'
                        : null}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleOpenDialogShipping}
                      disabled={
                        !(orderForm.customer && orderForm.customer.name) ||
                        (orderForm.status !== 'Orçamento' &&
                          orderForm.status !== 'Pedido criado' &&
                          orderForm.status !== 'Pedido confirmado' &&
                          orderForm.status !== 'Pedido faturado' &&
                          orderForm.status !== 'Pedido embalado') ||
                        !orderForm.items ||
                        orderForm.items.length === 0
                      }
                    >
                      Escolher entrega
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orderForm.shipping?.method && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item>
                <img
                  src={orderForm.shipping.companyPicture}
                  alt={orderForm.shipping.company}
                  className={classes.companyPicture}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Typography variant="caption">Método</Typography>
                  {orderForm.othersIds?.idTrackingShipping ? (
                    <Typography>
                      <LinkMaterial
                        href={
                          orderForm.shipping.trackingLink ||
                          `https://melhorrastreio.com.br/rastreio/${orderForm.othersIds.idTrackingShipping}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {orderForm.shipping.company} {orderForm.shipping.method}
                      </LinkMaterial>
                    </Typography>
                  ) : (
                    <Typography>
                      {orderForm.shipping.company} {orderForm.shipping.method}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {orderForm.shipping?.deliveryRange?.min && orderForm.shipping?.deliveryRange?.max ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Prazo</Typography>
                    <Typography>
                      de {orderForm.shipping.deliveryRange.min} a{' '}
                      {orderForm.shipping.deliveryRange.max} dias úteis
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item>
                <Button variant="outlined" onClick={handleOpenVolumesDialog}>
                  Volumes
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {orderForm.shipping?.address?.postalCode ? (
            <Grid item xs={12}>
              <Grid container direction="column">
                <Typography variant="caption">Endereço</Typography>
                <Typography>
                  {orderForm.shipping.address.street
                    ? `${orderForm.shipping.address.street}, `
                    : null}
                  {orderForm.shipping.address.streetNumber
                    ? `${orderForm.shipping.address.streetNumber}, `
                    : null}
                  {orderForm.shipping.address.complement
                    ? `${orderForm.shipping.address.complement}, `
                    : null}
                  {orderForm.shipping.address.district
                    ? `${orderForm.shipping.address.district}, `
                    : null}
                  {orderForm.shipping.address.city}/{orderForm.shipping.address.state} - CEP:{' '}
                  {orderForm.shipping.address.postalCode}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                  <Grid item>
                    <Typography>Peso conferido:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {orderForm.shipping && orderForm.shipping.packageWeight
                        ? `${orderForm.shipping.packageWeight.toFixed(3).replace(/\./g, ',')} Kg`
                        : 'Não conferido'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                  <Grid item>
                    <Typography>Total da entrega:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      R$ {numberToReal(orderForm.shipping.price)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                {orderForm.invoice &&
                orderForm.invoice.key &&
                orderForm.invoice.status === 'aprovado' ? (
                  <Tooltip
                    title={<Typography align="center">Imprimir etiqueta Nota Fiscal</Typography>}
                  >
                    <LoadingButton
                      loading={loadingPrintInvoiceStamp}
                      variant="contained"
                      color="primary"
                      startIcon={<PrintIcon />}
                      onClick={handlePrintDanfeStamp}
                    >
                      Etiqueta DANFE
                    </LoadingButton>
                  </Tooltip>
                ) : null}
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {formChanged
                        ? 'Salve o pedido antes de imprimir'
                        : 'Imprimir etiqueta do pedido'}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      onClick={handleOpenDialogConfirmPrintOrderStamp}
                      variant="contained"
                      color="primary"
                      disabled={!orderForm.orderId || formChanged}
                      startIcon={<PrintIcon />}
                    >
                      Etiqueta de Pedido
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item>
                {orderForm.shipping &&
                  orderForm.shipping.method &&
                  orderForm.shipping.company !== 'Mark Ferragens' && (
                    <Tooltip
                      title={<Typography align="center">{blockPrintStampMessage}</Typography>}
                    >
                      <Box>
                        <LoadingButton
                          loading={loadingShippingStamp}
                          variant="contained"
                          color="primary"
                          disabled={blockPrintStamp}
                          startIcon={<PrintIcon />}
                          onClick={handleOpenDialogConfirmPrintStamp}
                        >
                          Etiqueta de Entrega
                        </LoadingButton>
                      </Box>
                    </Tooltip>
                  )}
              </Grid>
            </Grid>
          </Grid>

          <CustomDivider />
          <Grid item xs={12}>
            <CustomInput
              label="Observações do pedido"
              value={orderForm?.observation || ''}
              onChange={(event) =>
                setOrderForm((oldFields) => ({
                  ...oldFields,
                  observation: event.target.value,
                }))
              }
            />
          </Grid>
        </>
      )}
      {openDialogIdMelhorEnvio ? (
        <SimpleDialog
          openDialog={openDialogIdMelhorEnvio}
          handleClose={handleCloseDialogIdMelhorEnvio}
          dialogTitle="Insira o rastreio do Melhor Envio"
          dialogText="Digite abaixo o número de rastreio usado do Melhor Envio."
          content={
            <CustomInput
              name="idMelhorEnvio"
              label="Melhor Envio Rastreio"
              variant="outlined"
              value={trackingMelhorEnvio}
              onChange={handleChangeIdMelhorEnvio}
            />
          }
          cancelButtonText="Cancelar"
          confirmButtonText="Confirma"
          handleCancelButton={handleCloseDialogIdMelhorEnvio}
          handleConfirmButton={handleConfirmIdMelhorEnvio}
        />
      ) : null}
      {openDialogShipping ? (
        <ShippingDialog
          setSnackbar={setSnackbar}
          handleCloseDialogShipping={handleCloseDialogShipping}
          handleSelectShipping={handleSelectShipping}
          openDialogShipping={openDialogShipping}
          orderForm={orderForm}
        />
      ) : null}
      {openVolumesDialog ? (
        <VolumesDialog
          volumes={orderForm.shipping.volumes?.volumesList}
          openVolumesDialog={openVolumesDialog}
          handleCloseVolumesDialog={handleCloseVolumesDialog}
          setOrderForm={setOrderForm}
          orderIsInvoiced={orderForm.invoice?.status === 'aprovado'}
        />
      ) : null}
      {openDialogConfirmPrintOrderStamp ? (
        <SimpleDialog
          openDialog={openDialogConfirmPrintOrderStamp}
          handleClose={handleCloseDialogConfirmPrintOrderStamp}
          dialogTitle="Etiqueta de Pedido"
          dialogText="Deseja imprimir a etiqueta de Pedido?."
          cancelButtonText="Cancelar"
          confirmButtonLoading={loadingPrintOrderStamp}
          content=""
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmPrintOrderStamp}
          handleConfirmButton={printOrderStamp}
        />
      ) : null}
    </Grid>
  );
}

export default ShippingTab;
