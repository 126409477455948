import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect } from 'react';

const useStyles = makeStyles(() => ({
  inputAdornment: {
    marginBottom: 5,
  },
  inputPropsCenter: {
    textAlign: 'center',
  },
  hideArrows: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

function CustomInput({
  name,
  label,
  value,
  onChange = () => {},
  size = 'small',
  InputAdornmentIcon,
  InputAdornmentStyle,
  InputStartAdornment,
  InputEndAdornment,
  InputStyle,
  loading,
  number,
  password,
  disabled,
  error,
  helperText,
  length,
  variant,
  color,
  inputRef,
  rows,
  centerText,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  let fontSize;

  if (size === 'small') {
    fontSize = 14;
  }

  let startAdornment;

  if (InputAdornmentIcon) {
    startAdornment = (
      <InputAdornment position="start" className={InputAdornmentStyle || classes.inputAdornment}>
        <InputAdornmentIcon />
      </InputAdornment>
    );
  } else if (!InputAdornmentIcon && InputStartAdornment) {
    startAdornment = (
      <InputAdornment position="start" className={InputAdornmentStyle || classes.inputAdornment}>
        {InputStartAdornment}
      </InputAdornment>
    );
  }

  let endAdornment;

  if (loading) {
    endAdornment = (
      <InputAdornment position="end" className={InputAdornmentStyle || classes.inputAdornment}>
        <CircularProgress variant="indeterminate" size={20} />
      </InputAdornment>
    );
  } else if (!loading && InputEndAdornment) {
    endAdornment = (
      <InputAdornment position="end" className={InputAdornmentStyle || classes.inputAdornment}>
        {InputEndAdornment}
      </InputAdornment>
    );
  }

  let type = 'text';

  if (number) {
    type = 'number';
  } else if (password) {
    type = 'password';
  }

  return (
    <TextField
      name={name}
      label={label}
      value={value}
      type={type}
      onChange={onChange}
      size={size}
      fullWidth
      multiline={!!rows}
      minRows={rows || 2}
      autoComplete="chrome-off"
      variant={variant || 'standard'}
      color={color}
      helperText={helperText}
      disabled={disabled}
      error={error}
      inputRef={inputRef}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        autoComplete: 'off',
        startAdornment,
        endAdornment,
        inputProps: {
          style: {
            fontSize,
          },
          autoComplete: 'off',
          maxLength: length || null,
          className: centerText ? classes.inputPropsCenter : InputStyle || null,
        },
      }}
      className={number ? classes.hideArrows : null}
    />
  );
}

export default memo(CustomInput);
