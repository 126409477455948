import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useState } from 'react';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

const useStyles = makeStyles({
  select: {
    minWidth: 190,
  },
  input: {
    fontSize: 25,
  },
  inputAdornment: {
    marginBottom: 0,
  },
});

function TaxesTab({ taxes, handleChangeTaxes }) {
  const classes = useStyles();

  const [taxesFields, setTaxesFields] = useState({
    importTaxes: taxes?.importTaxes || 0,
    afrmmValue: taxes?.afrmmValue || 0,
    accessories: taxes?.accessories || 0,
    customs: taxes?.customs || 0,
  });

  const [openDialogSelectShipping, setOpenDialogSelectShipping] = useState(false);
  const handleOpenDialogSelectShipping = useCallback(() => setOpenDialogSelectShipping(true), []);
  const handleCloseDialogSelectShipping = useCallback(() => setOpenDialogSelectShipping(false), []);

  const handleConfirmTaxes = useCallback(() => {
    handleChangeTaxes(taxesFields);
    handleCloseDialogSelectShipping();
  }, [taxesFields]);

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setTaxesFields((oldFields) => ({
      ...oldFields,
      [fieldName]: parseFloat(fieldValue) || 0,
    }));
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Despesas</Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" onClick={handleOpenDialogSelectShipping}>
              Informar Despesas
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {taxes ? (
        <Grid item xs={12}>
          <Box marginTop={1}>
            <Grid container justifyContent="space-between" spacing={2}>
              {taxes.importTaxes ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Importação</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(taxes.importTaxes)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {taxes.afrmmValue ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">AFRMM</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(taxes.afrmmValue)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {taxes.accessories ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Acessorias</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(taxes.accessories)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {taxes.customs ? (
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="caption">Aduaneiras</Typography>
                    <Typography>{`R$ ${numberToRealWithPeriod(taxes.customs)}`}</Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
      ) : null}

      {openDialogSelectShipping ? (
        <SimpleDialog
          openDialog={openDialogSelectShipping}
          handleClose={handleCloseDialogSelectShipping}
          maxWidth="sm"
          confirmButtonText="Confirmar"
          handleConfirmButton={handleConfirmTaxes}
          handleCancelButton={handleCloseDialogSelectShipping}
          cancelButtonText="Cancelar"
          dialogText="Os valores dos impostos informados aqui serão incluídos no custo dos produtos. Por isto se você alterar estas informações você terá que confirmar os preços dos produtos novamente."
          content={
            <Grid container spacing={2}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <CustomInput
                      name="importTaxes"
                      label="Imposto de Importação"
                      value={taxesFields.importTaxes}
                      onChange={handleChange}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      name="afrmmValue"
                      label="Total da AFRMM"
                      value={taxesFields.afrmmValue}
                      onChange={handleChange}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <CustomInput
                      name="accessories"
                      label="Despesas com acessorias"
                      value={taxesFields.accessories}
                      onChange={handleChange}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                  <Grid item>
                    <CustomInput
                      name="customs"
                      label="Despesas aduaneiras"
                      value={taxesFields.customs}
                      onChange={handleChange}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      InputStyle={classes.input}
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          dialogTitle="Informe os valores dos impostos"
        />
      ) : null}
    </Grid>
  );
}

export default memo(TaxesTab);
