import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: grey[100],
  },
}));

function TableSettingsCategory({ categoriesList }) {
  const classes = useStyles();

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Categoria pai</TableCell>
              <TableCell align="center">URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoriesList &&
              categoriesList.length > 0 &&
              categoriesList.map((category) => (
                <TableRow key={category._id}>
                  <TableCell align="center">{category.name}</TableCell>
                  <TableCell align="center">{category.parentName || 'Principal'}</TableCell>
                  <TableCell align="center">{category.slug}</TableCell>
                </TableRow>
              ))}
            {categoriesList && categoriesList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography>Não foi cadastrada nenhuma categoria</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TableSettingsCategory;
