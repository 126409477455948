import { Alert, Grid } from '@mui/material';
import React from 'react';
import SimpleDialog from '../../../Common/SimpleDialog';

function WebmaniaNfResultsDialog({
  openWebmaniaNfResultsDialog,
  handleCloseWebmaniaNfResultsDialog,
  webmaniaNfResultList,
}) {
  return (
    <SimpleDialog
      content={
        <Grid container direction="column" spacing={1}>
          {webmaniaNfResultList &&
            webmaniaNfResultList.map((webmaninaNfResult) => (
              <React.Fragment key={webmaninaNfResult.number}>
                {webmaninaNfResult.orderIds.map((orderId) => (
                  <Grid item key={orderId}>
                    <Alert severity={webmaninaNfResult.result ? 'success' : 'error'}>
                      Pedido {orderId} - {webmaninaNfResult.description} (
                      {webmaninaNfResult.company})
                    </Alert>
                  </Grid>
                ))}
              </React.Fragment>
            ))}
        </Grid>
      }
      maxWidth="lg"
      dialogTitle="Resultado do Webmania"
      dialogText="Resultado da validação de notas fiscais pelo Webmania"
      handleClose={handleCloseWebmaniaNfResultsDialog}
      openDialog={openWebmaniaNfResultsDialog}
    />
  );
}

export default WebmaniaNfResultsDialog;
