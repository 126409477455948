const filterProductOptionsInitial = [
  {
    id: 'showOnlyCustomersWithTotalPending',
    description: 'Pedidos a prazo não pagos',
    active: false,
  },
  {
    id: 'showOnlyCustomersWithCreditLimit',
    description: 'Limite a prazo definido',
    active: false,
  },
  {
    id: 'showOnlyCustomersWithAvailableCredit',
    description: 'Crédito disponível',
    active: false,
  },
  {
    id: 'showOnlyCustomersWithNegativeCredit',
    description: 'Crédito devedor',
    active: false,
  },
];

export default filterProductOptionsInitial;
