import { TableCell, TableFooter, TableRow } from '@mui/material';
import React from 'react';
import CustomPagination from '../../../Common/CustomPagination';

function ChequesTableFooter({ page, limit, countCheques, handleChangePage }) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={8}>
          <CustomPagination
            page={page}
            total={countCheques}
            limit={limit}
            handleChangePage={handleChangePage}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default ChequesTableFooter;
