import { TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  smallCell: {
    width: 50,
  },
  mediumCell: {
    width: 100,
  },
});

function UsersTableHead({ docAlongWithName }) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.smallCell}>Código</TableCell>
        <TableCell>Nome</TableCell>
        {!docAlongWithName ? <TableCell>CPF/CNPJ</TableCell> : null}
        <TableCell>Cidade</TableCell>
        <TableCell className={classes.mediumCell}>
          {docAlongWithName ? 'Débito' : 'Crédito'}
        </TableCell>
        {!docAlongWithName ? <TableCell>Débito</TableCell> : null}
        {!docAlongWithName ? <TableCell>Limite</TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

export default UsersTableHead;
