const newProduct = {
  productId: 0,
  name: '',
  technicalName: '',
  description: '',
  slug: '',
  status: 'unactive',
  active: {
    forSale: true,
    siteMarkFerragens: false,
    skyHub: false,
    mercadoLivre: false,
    ekoos: false,
  },
  package: {
    weight: '0.000',
    width: 0,
    height: 0,
    length: 0,
  },
  stock: 0,
  stocks: [],
  reservedStock: 0,
  maximumStock: 0,
  minimumStock: 0,
  composition: false,
  productInputs: [],
  images: [],
  recentlyUpdatedImages: false,
  videoLink: '',
  brand: '',
  tags: [],
  cost: 0,
  lastCost: 0,
  price: 0,
  cashPrice: 0,
  markup: 50,
  offer: false,
  offerPrice: 0,
  offers: [],
  categories: [],
  relatedProducts: [],
  taxFields: {
    ncm: '',
    cest: '',
    origin: '',
    unity: {
      unityId: 0,
      name: '',
      quantity: 1,
    },
    classesImpostosVenda: [],
  },
  localization: '',
  othersIds: {
    gtin: '',
  },
  suppliersIds: [],
  suppliers: [],
  marketplaces: [],
  mercadoLivre: {
    name: '',
    categoryId: '',
  },
  reviews: {
    weight: true,
    price: true,
  },
};

export default newProduct;
