import { Grid, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import SimpleImage from '../../../Common/SimpleImage';

const useStyles = makeStyles(() => ({
  imageItem: {
    cursor: 'pointer',
  },
}));

const bannersDimensions = [
  {
    type: 'homeBanners',
    width: 1920,
    height: 753,
    mobileWidth: 543,
    mobileHeight: 304,
  },
  {
    type: 'threeBlocksAds',
    width: 405,
    height: 165,
    mobileWidth: 405,
    mobileHeight: 165,
  },
  {
    type: 'carouselAds',
    width: 280,
    height: 320,
    mobileWidth: 280,
    mobileHeight: 320,
  },
  {
    type: 'collections',
    width: 396,
    height: 388,
    mobileWidth: 396,
    mobileHeight: 388,
  },
  {
    type: 'bestBrands',
    width: 320,
    height: 190,
    mobileWidth: 320,
    mobileHeight: 190,
  },
  {
    type: 'blogBanners',
    width: 1920,
    height: 753,
    mobileWidth: 543,
    mobileHeight: 304,
  },
  {
    type: 'imgBlogPost',
    width: 320,
    height: 320,
    mobileWidth: 320,
    mobileHeight: 320,
  },
];

function BannerImgTab({ src, srcMobile, alt, bannerType, onImgClick = () => {} }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={srcMobile ? 'space-between' : 'center'}>
      <Grid item xs={8} onClick={() => onImgClick('src')} className={classes.imageItem}>
        {src ? (
          <SimpleImage
            key={src}
            src={src}
            width={bannersDimensions.find((d) => d.type === bannerType)?.width}
            height={bannersDimensions.find((d) => d.type === bannerType)?.height}
            alt={alt}
            imgWidth="95%"
          />
        ) : (
          <Typography>Adicionar imagem</Typography>
        )}
      </Grid>
      {bannerType === 'homeBanners' || bannerType === 'blogBanners' ? (
        <Grid item xs={4} onClick={() => onImgClick('srcMobile')} className={classes.imageItem}>
          {srcMobile ? (
            <SimpleImage
              key={srcMobile}
              src={srcMobile}
              width={bannersDimensions.find((d) => d.type === bannerType)?.mobileWidth}
              height={bannersDimensions.find((d) => d.type === bannerType)?.mobileHeight}
              alt={alt}
              imgWidth="95%"
            />
          ) : (
            <Typography>Adicionar imagem mobile</Typography>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
}

export default BannerImgTab;
