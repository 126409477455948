import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../Common/SimpleDialog';
import {
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../helpers/formatData';
import CustomDatePicker from '../../../CustomDatePicker';
import CustomInput from '../../../CustomInput';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function AddInstallmentsDialog({
  openAddDialogInstallments,
  handleCloseAddDialogInstallments,
  finance,
  setFinance,
  numberOfInstallments,
  setNumberOfInstallments,
}) {
  const classes = useStyles();

  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const recalculateInstallments = (installmentIndex, newValue) => {
    const originalTotalValue = finance.value;

    const updatedInstallments = finance.installments.map((installment, index) => {
      if (index === installmentIndex) {
        return {
          ...installment,
          value: newValue,
          locked: true,
        };
      }

      return installment;
    });

    const allLocked = updatedInstallments.every((installment) => installment.locked);
    const lastIndex = updatedInstallments.length - 1;

    if (allLocked) {
      if (installmentIndex === lastIndex) {
        updatedInstallments[0].locked = false;
      } else {
        updatedInstallments[lastIndex].locked = false;
      }
    }

    const lockedInstallments = updatedInstallments.filter((installment) => installment.locked);
    const lockedTotalValue = lockedInstallments.reduce((acc, imt) => acc + imt.value, 0);

    const unlockedInstallments = updatedInstallments.filter((installment) => !installment.locked);
    const remainingTotalValue = originalTotalValue - lockedTotalValue;

    const remainingInstallmentsCount = unlockedInstallments.length;
    const remainingInstallmentValue = remainingTotalValue / remainingInstallmentsCount;

    const updatedRemainingInstallments = updatedInstallments.map((installment) => {
      if (!installment.locked) {
        return {
          ...installment,
          value: remainingInstallmentValue,
        };
      }

      return installment;
    });

    if (Math.min(...updatedRemainingInstallments.map((installment) => installment.value)) < 10) {
      setDisableConfirmButton(true);
    } else {
      setDisableConfirmButton(false);
    }

    setFinance({
      ...finance,
      installments: updatedRemainingInstallments,
    });
  };

  return (
    <SimpleDialog
      openDialog={openAddDialogInstallments}
      handleClose={handleCloseAddDialogInstallments}
      dialogTitle="Adicionar parcelamento"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseAddDialogInstallments}
      handleConfirmButton={handleCloseAddDialogInstallments}
      disableConfirmButton={disableConfirmButton}
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth variant="filled">
              <InputLabel>Número de parcelas</InputLabel>
              <Select
                onChange={(event) => setNumberOfInstallments(event.target.value)}
                value={numberOfInstallments}
              >
                {[...Array(12)].map((_, index) => (
                  <MenuItem value={index + 1} key={`${index + 1}`}>
                    {index + 1} x R$ {numberToRealWithPeriod(finance.value / (index + 1))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {finance.installments && finance.installments.length > 0 ? (
              <Grid container direction="column" spacing={2}>
                {finance.installments.map((installment, index) => {
                  let minDate = index ? dayjs(finance.payDate).add(index, 'day') : undefined;
                  if (finance.installments[index - 1]) {
                    minDate = dayjs(finance.installments[index - 1].payDate).add(1, 'day');
                  }

                  let maxDate = index ? dayjs(finance.payDate).add(index, 'month') : undefined;
                  if (finance.installments[index + 1]) {
                    maxDate = dayjs(finance.installments[index + 1].payDate).subtract(1, 'day');
                  }

                  return (
                    <Grid item xs={12} key={`${index + 1}`}>
                      <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
                        <Grid item>
                          <Typography>{index + 1}</Typography>
                        </Grid>
                        <Grid item>
                          <CustomInput
                            value={numberToRealWithPeriod(installment.value)}
                            onChange={(event) => {
                              const value = onlyNumbersValue(event.target.value);
                              const formatedValue = parseFloat(formatReal(value));
                              recalculateInstallments(index, formatedValue);
                            }}
                            size="small"
                            variant="outlined"
                            error={
                              Number.isNaN(parseFloat(installment.value)) ||
                              parseFloat(installment.value) < 10
                            }
                            InputStartAdornment={<Typography variant="body2">R$</Typography>}
                            InputAdornmentStyle={classes.inputAdornment}
                          />
                        </Grid>
                        <Grid item>
                          <CustomDatePicker
                            fullWidth
                            size="small"
                            variant="outlined"
                            format="DD/MM/YYYY"
                            value={installment.payDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            error={!installment.payDate}
                            onChange={(date) =>
                              setFinance((oldFields) => ({
                                ...oldFields,
                                payDate: index === 0 ? date : oldFields.payDate,
                                installments: oldFields.installments.map(
                                  (oldInstallment, oldIndex) => {
                                    if (oldIndex === index) {
                                      return {
                                        ...oldInstallment,
                                        payDate: date,
                                      };
                                    }
                                    return oldInstallment;
                                  }
                                ),
                              }))
                            }
                            onError={(error) => setDisableConfirmButton(!!error)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      }
    />
  );
}

export default AddInstallmentsDialog;
