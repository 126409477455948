import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  RequestQuoteOutlined,
  LocalShippingOutlined,
  ProductionQuantityLimitsOutlined,
  ShoppingCartCheckout,
  ImageAspectRatio,
  Inventory2Outlined,
} from '@mui/icons-material';
import TopBarReports from './TopBarReports';
import OrdersReports from './OrdersReports';
import InvoicesReports from './InvoicesReports';
import ProductsReports from './ProductsReports';
import PickingsReports from './PickingsReports';
import StocksReports from './StocksReports';
import FinancesReports from './FinancesReports';

const menuItems = [
  {
    name: 'Pedidos',
    icon: ShoppingCartCheckout,
  },
  {
    name: 'Produtos',
    icon: ProductionQuantityLimitsOutlined,
  },
  {
    name: 'Estoque',
    icon: Inventory2Outlined,
  },
  {
    name: 'Financeiro',
    icon: RequestQuoteOutlined,
  },
  {
    name: 'Notas',
    icon: ImageAspectRatio,
  },
  {
    name: 'Coletas',
    icon: LocalShippingOutlined,
  },
];

function Reports() {
  const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0].name);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <TopBarReports
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
        />
      </Box>
      {selectedMenuItem === 'Pedidos' ? <OrdersReports /> : null}
      {selectedMenuItem === 'Produtos' ? <ProductsReports /> : null}
      {selectedMenuItem === 'Estoque' ? <StocksReports /> : null}
      {selectedMenuItem === 'Financeiro' ? <FinancesReports /> : null}
      {selectedMenuItem === 'Notas' ? <InvoicesReports /> : null}
      {selectedMenuItem === 'Coletas' ? <PickingsReports /> : null}
    </Box>
  );
}

export default Reports;
