const userRolesFiltersInitials = [
  {
    name: 'Cliente',
    active: true,
    role: 'customer',
  },
  {
    name: 'Fornecedor',
    active: true,
    role: 'supplier',
  },
  {
    name: 'Exportador',
    active: true,
    role: 'exporter',
  },
  {
    name: 'Transportadora',
    active: true,
    role: 'shippingCompany',
  },
  {
    name: 'Colaborador',
    active: true,
    role: 'staff',
  },
];

export default userRolesFiltersInitials;
