import { Skeleton } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { getCategoriesByType } from '../../../services/melhorGestao/payments';
import CategoryPaymentTree from './CategoryPaymentTree';
import newPaymentCategory from '../../../constant/newPaymentCategory';

function CategoryPaymentManage({
  setSnackbar = () => {},
  onChange = () => {},
  type = 'Pagamento',
  disableCreateCategory,
  finance,
}) {
  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [selectedCategory, setSelectedCategory] = useState(newPaymentCategory);
  const [categoriesList, setCategoriesList] = useState([]);
  const [loadingCategoriesList, setLoadingCategoriesList] = useState(false);
  const [timeoutCategorySearchId, setTimeoutCategorySearchId] = useState();

  const searchCategories = async () => {
    setLoadingCategoriesList(true);
    const categories = await getCategoriesByType({
      type,
      setSnackbar,
    });
    if (categories) {
      setCategoriesList(categories);
    }
    setLoadingCategoriesList(false);
    return false;
  };

  useEffect(() => {
    setLoadingCategoriesList(true);
    if (timeoutCategorySearchId) {
      clearTimeout(timeoutCategorySearchId);
    }
    setTimeoutCategorySearchId(setTimeout(() => searchCategories(), 1000));
  }, [type, refreshToken]);

  useEffect(() => {
    onChange(selectedCategory);
  }, [selectedCategory]);

  return loadingCategoriesList ? (
    [
      { key: 1, width: 250 },
      { key: 2, width: 300 },
      { key: 3, width: 130 },
      { key: 4, width: 280 },
      { key: 5, width: 150 },
      { key: 6, width: 220 },
    ].map((n) => <Skeleton key={n.key} animation="wave" width={n.width} height={25} />)
  ) : (
    <CategoryPaymentTree
      type={type}
      categoriesList={categoriesList}
      setRefreshToken={setRefreshToken}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      disableCreateCategory={disableCreateCategory}
      finance={finance}
    />
  );
}

export default memo(CategoryPaymentManage);
