import Axios from 'axios';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { floatToBigInt, numberToRealWithPeriod } from '../../../../helpers/formatData';
import PaymentsTable from './PaymentsTable';
import { getOrder } from '../../../../services/melhorGestao/orders';
import newOrderPayment from '../../../../constant/newOrderPayment';
import PaymentDialog from './PaymentDialog';

function PaymentsTab({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  devolutions,
  devolutionData,
  formChanged,
  totalPaid,
  totalValue,
}) {
  const [blockAddPaymentButton, setBlockAddPaymentButton] = useState(true);
  const [paymentButtonMessage, setPaymentButtonMessage] = useState('');

  useEffect(() => {
    if (formChanged || !orderForm.orderId) {
      setBlockAddPaymentButton(true);
      return setPaymentButtonMessage('Salve o pedido para adicionar pagamento');
    }

    if (orderForm.status === 'Orçamento') {
      setBlockAddPaymentButton(true);
      return setPaymentButtonMessage('Você não pode inserir pagamento em um orçamento');
    }

    const valueReceived = orderForm.payments
      .filter((payment) => payment.type === 'Recebimento')
      .reduce((total, payment) => total + payment.value, 0);

    const fullyPaidOrder =
      parseFloat(totalPaid.toFixed(2)) >= parseFloat(orderForm.orderTotal.toFixed(2));

    if (devolutions?.length > 0 && fullyPaidOrder) {
      if (!valueReceived && orderForm.items.length === 0) {
        setBlockAddPaymentButton(true);
        return setPaymentButtonMessage('Este pedido foi devolvido e não teve recebimento');
      }

      const valueReturned = orderForm.payments
        .filter((payment) => payment.type === 'Pagamento')
        .reduce((total, payment) => total + payment.value, 0);

      const totalDevolutions = devolutions.map((devolution) => devolution.devolutionTotal);
      const totalReturn = totalDevolutions.reduce((total, devolTotal) => total + devolTotal, 0);

      if (parseFloat(valueReturned.toFixed(2)) >= parseFloat(totalReturn.toFixed(2))) {
        setBlockAddPaymentButton(true);
        return setPaymentButtonMessage('Este pedido já tem reembolso confira se está pago');
      }

      if (parseFloat(valueReceived.toFixed(2)) >= parseFloat(totalReturn.toFixed(2))) {
        setBlockAddPaymentButton(false);
        return setPaymentButtonMessage('Adicionar reembolso da devolução');
      }
    }

    if (fullyPaidOrder) {
      setBlockAddPaymentButton(true);
      return setPaymentButtonMessage('Este pedido já está totalmente pago');
    }

    if (orderForm.status === 'Pedido cancelado') {
      setBlockAddPaymentButton(true);
      return setPaymentButtonMessage('Este pedido foi cancelado e não teve recebimento');
    }

    if (parseFloat(valueReceived.toFixed(2)) >= parseFloat(orderForm.orderTotal.toFixed(2))) {
      setBlockAddPaymentButton(true);
      return setPaymentButtonMessage('Este pedido já tem pagamento suficiente');
    }

    setBlockAddPaymentButton(false);
    return setPaymentButtonMessage('Inserir pagamento neste pedido');
  }, [
    formChanged,
    orderForm.status,
    orderForm.orderTotal,
    orderForm.payments,
    devolutions,
    totalValue,
    totalPaid,
  ]);

  const [paymentForm, setPaymentForm] = useState(newOrderPayment);
  const [openDialogPayment, setOpenDialogPayment] = useState(false);

  const handleOpenDialogPayment = ({ orderPayment, editIndex = -1 }) => {
    setPaymentForm({
      ...newOrderPayment,
      ...orderPayment,
      editIndex,
    });

    return setOpenDialogPayment(true);
  };

  const handleCloseDialogPayment = () => setOpenDialogPayment(false);

  const handleEditPayment = ({ editIndex }) => {
    const orderPayment = orderForm.payments.find((payment, index) => index === editIndex);
    handleOpenDialogPayment({ orderPayment, editIndex });
  };

  const handleDeletePayment = ({ deleteIndex }) => {
    const updatedPayments = orderForm.payments.filter((payment, index) => index !== deleteIndex);
    return setOrderForm((oldFields) => ({
      ...oldFields,
      payments: updatedPayments,
    }));
  };

  const handlePaymentDevolution = async () => {
    let data;

    if (devolutionData && devolutionData.devolutionId) {
      data = devolutionData;
    } else {
      const response = await Axios.get('/devolution', {
        params: { orderId: orderForm.orderId },
      });

      data = response.data.devolution;
    }

    const { orderTotal } = orderForm;

    let isPartiallyDelivered = false;

    data.items.forEach((devolutionItem) => {
      orderForm.items.forEach((orderItem) => {
        if (devolutionItem.productId === orderItem.productId) {
          if (devolutionItem.quantity !== orderItem.quantity) {
            isPartiallyDelivered = true;
          }
        }
      });
    });

    if (!isPartiallyDelivered) {
      if (orderForm.items.length) {
        isPartiallyDelivered = true;
      }
    }

    const getUpdatedOrder = await getOrder(orderForm.orderId);
    setInitialOrderForm(getUpdatedOrder);
    setOrderForm(getUpdatedOrder);

    const value = data.devolutionTotal;

    if (!isPartiallyDelivered) {
      if (totalPaid > 0) {
        handleOpenDialogPayment({
          orderPayment: {
            type: 'Pagamento',
            method: orderForm.payments[0].method,
            value,
            valuePaid: floatToBigInt(value),
            othersIds: {
              ...orderForm.payments[0].othersIds,
            },
          },
        });
      }
    }

    if (isPartiallyDelivered) {
      if (totalPaid > 0) {
        handleOpenDialogPayment({
          orderPayment: {
            type: 'Pagamento',
            method: orderForm.payments[0].method,
            value,
            valuePaid: floatToBigInt(value),
            othersIds: {
              ...orderForm.payments[0].othersIds,
            },
          },
        });
      }
      if (totalPaid === 0) {
        handleOpenDialogPayment({
          orderPayment: {
            type: 'Recebimento',
            value: orderTotal,
            valuePaid: floatToBigInt(orderTotal),
          },
        });
      }
    }
  };

  useEffect(() => {
    if (devolutionData) {
      handlePaymentDevolution();
    }
  }, [devolutionData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Pagamentos</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<Typography align="center">{paymentButtonMessage}</Typography>}>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    const valueReceived = orderForm.payments
                      .filter((payment) => payment.type === 'Recebimento')
                      .reduce((total, payment) => total + payment.value, 0);

                    const fullyPaidOrder =
                      parseFloat(totalPaid.toFixed(2)) >=
                      parseFloat(orderForm.orderTotal.toFixed(2));

                    const addValue = orderForm.orderTotal - valueReceived;

                    const valueReturned = orderForm.payments
                      .filter((payment) => payment.type === 'Pagamento')
                      .reduce((total, payment) => total + payment.value, 0);

                    const returnValue = devolutions
                      .map((devolution) => devolution.devolutionTotal)
                      .reduce((curr, acc) => curr + acc, 0);

                    if (returnValue > valueReturned && fullyPaidOrder) {
                      handlePaymentDevolution();
                    } else if (addValue > 0) {
                      handleOpenDialogPayment({
                        orderPayment: {
                          type: 'Recebimento',
                          value: addValue,
                          valuePaid: floatToBigInt(addValue),
                        },
                      });
                    }
                  }}
                  disabled={blockAddPaymentButton}
                >
                  Adicionar pagamento
                </Button>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {orderForm.items && orderForm.items.length > 0 && orderForm.payments.length > 0 ? (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Total pago:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">R$ {numberToRealWithPeriod(totalPaid || 0)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Total do pedido:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    R$ {numberToRealWithPeriod(orderForm.orderTotal)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {orderForm.orderId ? (
        <Grid item xs={12}>
          <PaymentsTable
            payments={orderForm.payments}
            handleEditPayment={handleEditPayment}
            handleDeletePayment={handleDeletePayment}
          />
        </Grid>
      ) : null}
      {openDialogPayment ? (
        <PaymentDialog
          openDialogPayment={openDialogPayment}
          handleCloseDialogPayment={handleCloseDialogPayment}
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          orderForm={orderForm}
          setOrderForm={setOrderForm}
          setInitialOrderForm={setInitialOrderForm}
          devolutions={devolutions}
        />
      ) : null}
    </Grid>
  );
}

export default PaymentsTab;
