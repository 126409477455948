const filterMlAdsOptionsInitial = [
  {
    id: 'showOnlyActive',
    description: 'Anúncios ativos',
    active: false,
  },
  {
    id: 'showOnlyPaused',
    description: 'Anúncios pausados',
    active: false,
  },
  {
    id: 'showOnlyClosed',
    description: 'Anúncios finalizados',
    active: false,
  },
  {
    id: 'showOnlyUnderReview',
    description: 'Anúncios a revisar',
    active: false,
  },
  {
    id: 'showOnlyNotSynchronized',
    description: 'Não sincronizados',
    active: false,
  },
  {
    id: 'showOnlyPriceNotUpdated',
    description: 'Preços desatualizados',
    active: false,
  },
  {
    id: 'showOnlyStockNotUpdated',
    description: 'Estoques desatualizados',
    active: false,
  },
  {
    id: 'showOnlyFreeShipping',
    description: 'Com frete grátis',
    active: false,
  },
];

export default filterMlAdsOptionsInitial;
