import React from 'react';
import { Page, StyleSheet } from '@react-pdf/renderer';
import Items from './Items';
import Title from './Title';
import Footer from './Footer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    flexDirection: 'column',
  },
});

function PrintPage({ selectedCompany, orderId, items, pageNumber, pageQuantity }) {
  return (
    <Page size="A4" style={styles.page} orientation="portrait">
      <Title selectedCompany={selectedCompany} orderId={orderId} />
      <Items orderId={orderId} items={items} />
      <Footer pageNumber={pageNumber} pageQuantity={pageQuantity} />
    </Page>
  );
}

export default PrintPage;
