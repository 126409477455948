import { TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  headTableCellId: {
    width: 65,
  },
  headTableCellCompany: {
    width: 65,
  },
  headTableCellSource: {
    width: 65,
  },
  headTableCellDate: {
    width: 65,
  },
  headTableCellStatus: {
    width: 150,
  },
});

function OrdersTableHead() {
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.headTableCellId} align="center">
          Código
        </TableCell>
        <TableCell className={classes.headTableCellCompany} align="center">
          Empresa
        </TableCell>
        <TableCell className={classes.headTableCellSource} align="center">
          Origem
        </TableCell>
        <TableCell align="center">Total</TableCell>
        <TableCell className={classes.headTableCellDate} align="center">
          Data
        </TableCell>
        <TableCell className={classes.headTableCellStatus} align="center">
          Situação
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default OrdersTableHead;
