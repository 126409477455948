import { Box, Fab, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(3),
  },
}));

function FabGroupButtons({ fabButtons = [] }) {
  const classes = useStyles();
  return (
    <Box className={classes.fab}>
      <Grid container direction="column" spacing={2}>
        {fabButtons.map((fabButton) => (
          <Grid item key={fabButton.title}>
            <Box>
              <Tooltip
                placement="left"
                title={<Typography align="center">{fabButton.title}</Typography>}
              >
                {fabButton.link ? (
                  <Link
                    to={fabButton.link}
                    onClick={fabButton.onClick ? fabButton.onClick : () => {}}
                  >
                    <Fab color={fabButton.featured ? 'primary' : 'default'}>{fabButton.icon}</Fab>
                  </Link>
                ) : (
                  <Fab
                    onClick={fabButton.onClick ? fabButton.onClick : () => {}}
                    color={fabButton.featured ? 'primary' : 'default'}
                  >
                    {fabButton.icon}
                  </Fab>
                )}
              </Tooltip>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(FabGroupButtons);
