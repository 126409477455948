import React, { useEffect, useState } from 'react';
import { Alert, Box, CircularProgress, Grid, Snackbar } from '@mui/material';
import SimpleDialog from '../../../../../../Common/SimpleDialog';
import newMercadoLivreAd from '../../../../../../../constant/newMercadoLivreAd';
import {
  getMercadoLivreAdForm,
  updateMercadoLivreProduct,
} from '../../../../../../../services/melhorGestao/mercadoLivre';
import getModifiedKeys from '../../../../../../../helpers/getModifiedKeys';
import { formatHandleError } from '../../../../../../../helpers/formatData';
import MercadoLivreDetails from '../../../../../../MarketplacesAds/MarketplacesDetails/MercadoLivreDetails';

function MercadoLivreEditAdDialog({
  openMercadoLivreEditAdDialog,
  handleCloseMercadoLivreEditAdDialog,
  productForm,
  mercadoLivreAd,
  setMercadoLivreAd = () => {},
}) {
  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });
  };

  const [loadingMercadoLivreAdForm, setLoadingMercadoLivreAdForm] = useState(true);
  const [loadingUpdateMercadoLivreAd, setLoadingUpdateMercadoLivreAd] = useState(false);
  const [initialMercadoLivreAdForm, setInitialMercadoLivreAdForm] = useState(newMercadoLivreAd);
  const [mercadoLivreAdForm, setMercadoLivreAdForm] = useState(newMercadoLivreAd);

  useEffect(() => {
    getMercadoLivreAdForm({
      productForm,
      mercadoLivreAd,
      mercadoLivreAdForm,
      setInitialMercadoLivreAdForm,
      setMercadoLivreAdForm,
      setLoadingMercadoLivreAdForm,
      setSnackbar,
    });
  }, []);

  const handleUpdateMercadoLivreAd = async () => {
    try {
      setLoadingUpdateMercadoLivreAd(true);

      const keys = getModifiedKeys(mercadoLivreAdForm, initialMercadoLivreAdForm);
      const keysToUpdate = [...new Set(keys.map((key) => key.split('.')[0]))];

      if (keysToUpdate.length === 0) {
        setLoadingUpdateMercadoLivreAd(false);
        return handleCloseMercadoLivreEditAdDialog();
      }

      const fieldsToUpdate = {};

      keysToUpdate.forEach((key) => {
        fieldsToUpdate[key] = mercadoLivreAdForm[key];
      });

      const updatedMercadoLivreProduct = await updateMercadoLivreProduct(
        mercadoLivreAdForm.mlb,
        fieldsToUpdate,
      );

      await getMercadoLivreAdForm({
        productForm,
        mercadoLivreAd,
        mercadoLivreAdForm,
        updatedMercadoLivreProduct,
        setInitialMercadoLivreAdForm,
        setMercadoLivreAdForm: (adForm) => {
          setMercadoLivreAdForm(adForm);
          setMercadoLivreAd(adForm);
        },
        setSnackbar,
      });

      setLoadingUpdateMercadoLivreAd(false);
      return handleCloseMercadoLivreEditAdDialog();
    } catch (error) {
      setLoadingUpdateMercadoLivreAd(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: `Algum erro ocorreu ao atualizar produto ${mercadoLivreAdForm.mlb} no Mercado Livre`,
        error,
      });
    }
  };

  return (
    <SimpleDialog
      openDialog={openMercadoLivreEditAdDialog}
      handleClose={handleCloseMercadoLivreEditAdDialog}
      dialogTitle="Alterar anúncio"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      confirmButtonLoading={loadingUpdateMercadoLivreAd}
      handleCancelButton={handleCloseMercadoLivreEditAdDialog}
      handleConfirmButton={handleUpdateMercadoLivreAd}
      content={
        loadingMercadoLivreAdForm ? (
          <Box width={600} height={390} alignContent="center">
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          </Box>
        ) : (
          <Box width={600}>
            <MercadoLivreDetails
              adForm={mercadoLivreAdForm}
              setAdForm={setMercadoLivreAdForm}
              productForm={productForm}
              publishOnMercadoLivre={openMercadoLivreEditAdDialog}
              setPublishOnMercadoLivre={() => {}}
              setSnackbar={setSnackbar}
            />
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Box>
        )
      }
    />
  );
}

export default MercadoLivreEditAdDialog;
