const getUserCreditLimit = (user) => {
  if (user.credit) {
    let creditLimit = user.credit.totalValue || 0;
    if (user.credit.totalValue > 0) {
      creditLimit += user.credit.totalValue;
    }
    if (user.credit.totalValue < 0) {
      creditLimit -= Math.abs(user.credit.totalValue);
    }

    return creditLimit > 0 ? creditLimit : 0;
  }
  return 0;
};

export default getUserCreditLimit;
