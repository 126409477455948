import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { formatHandleError } from '../../../../helpers/formatData';
import LimitTypography from '../../../Common/LimitTypography';

function Spreadsheet({ setImportedProductsFromXlsx, setSnackbar = () => {} }) {
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    const handleConfirmUpload = async () => {
      let withError = false;
      let defaultMessage = 'Algum erro ocorreu ao carregar o arquivo';

      try {
        if (!selectedFile) {
          return false;
        }

        const formData = new FormData();
        formData.append('xlsx', selectedFile);

        const responseXlsx = await Axios.post('/xlsx', formData);
        const tableItems = responseXlsx.data;

        if (!tableItems.length) {
          setSnackbar({
            message: 'Preencha a planilha',
            open: true,
            type: 'error',
          });
          return false;
        }

        tableItems.forEach((item) => {
          if (!item.SKU || !item.Nome || !item.Quantidade || item['Preço Médio'] === undefined) {
            withError = true;
            defaultMessage = 'Planilha inválida, verifique se todos os campos foram preenchidos.';
          }
        });

        if (withError) {
          setSnackbar({
            message: defaultMessage,
            open: true,
            type: 'error',
          });
          return false;
        }

        setImportedProductsFromXlsx(
          tableItems.map((item) => ({
            productId: item.SKU,
            name: item.Nome,
            quantity: item.Quantidade,
            selectedPrice: item['Preço Médio'],
          })),
        );

        return true;
      } catch (error) {
        return formatHandleError({
          setSnackbar,
          defaultMessage,
          error,
        });
      }
    };
    handleConfirmUpload();
  }, [selectedFile]);

  const handleDownloadSpreadsheetModel = () => {
    const link = document.createElement('a');
    link.href = '/modelo_import_prod_pedido.xlsx';
    link.download = 'modelo_import_prod_pedido.xlsx';
    link.click();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      if (e.dataTransfer.items[0].kind === 'file') {
        const file = e.dataTransfer.items[0].getAsFile();
        setSelectedFile(file);
      }
    } else {
      setSelectedFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Box
          sx={{
            border: '1px dotted gray',
            borderRadius: '10px',
            padding: '5px',
            cursor: 'pointer',
          }}
          height={35}
          width={300}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label htmlFor="upload-planilha">
            <input
              type="file"
              id="upload-planilha"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            {!selectedFile || !selectedFile?.name ? (
              <Typography>Arrastar ou selecionar uma planilha</Typography>
            ) : (
              <LimitTypography text={selectedFile.name} maxLength={41} />
            )}
          </label>
        </Box>
      </Grid>
      <Grid item>
        <Tooltip title={<Typography>Remover planilha enviada</Typography>}>
          <IconButton
            onClick={() => {
              setSelectedFile();
              setImportedProductsFromXlsx([]);
            }}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={<Typography>Fazer download da planilha de modelo</Typography>}>
          <IconButton onClick={handleDownloadSpreadsheetModel}>
            <SimCardDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default Spreadsheet;
