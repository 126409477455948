import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const searchBlogPosts = async ({
  limit = 20,
  skip = 0,
  sortBy = 'postId',
  sortDirection = 'desc',
  setLoadingBlogPostsList,
  setBlogPostsList,
  setTotalBlogPosts = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingBlogPostsList(true);

    const response = await Axios.get('/blogPosts', {
      params: {
        skip,
        limit,
        sortBy,
        sortDirection,
      },
    });

    const { totalPosts, blogPosts } = response.data;
    setTotalBlogPosts(totalPosts);
    setBlogPostsList(blogPosts);
    return setLoadingBlogPostsList(false);
  } catch (error) {
    setLoadingBlogPostsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar as postagens do blog',
      error,
    });
  }
};

export default searchBlogPosts;
