import { Box, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { formatCpfCpnj } from '../../../../helpers/formatData';

const useStyles = makeStyles({
  boxAddress: {
    position: 'relative',
    marginBottom: 9,
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
    width: '100%',
  },
  boxAddressButtons: {
    position: 'absolute',
    right: 5,
  },
});

function OthersDocumentsCard({ otherDocument, otherDocumentIndex, handleDeleteOthersDocuments }) {
  const classes = useStyles();

  return (
    <Box className={classes.boxAddress}>
      <Box className={classes.boxAddressButtons}>
        <IconButton
          size="small"
          onClick={() => handleDeleteOthersDocuments({ deleteIndex: otherDocumentIndex })}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <Grid container direction="column">
            <Typography variant="caption">Nome:</Typography>
            <Typography>{otherDocument.name}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container direction="column">
            <Typography variant="caption">CPF:</Typography>
            <Typography>{formatCpfCpnj(otherDocument.cpf)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OthersDocumentsCard;
