import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import calculateProductCost from '../../../../../../../helpers/calculateProductCost';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';
import CustomInput from '../../../../../../CustomInput';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function ValuesFromPrice({
  selectedProduct,
  handleChangeSimples,
  handleChangeProfitMargin,
  handleChangeAdditionalOnline,
  blockFields,
}) {
  const classes = useStyles();

  const [simples, setSimples] = useState(selectedProduct.simples * 100);
  const [profitMargin, setSetProfitMargin] = useState(selectedProduct.profitMargin * 100);
  const [additionalOnline, setAdditionalOnline] = useState(selectedProduct.additionalOnline);
  const [additionalOnlinePercentage, setAdditionalOnlinePercentage] = useState(0);

  const changeSimples = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    handleChangeSimples(value / 100);
    setSimples(value);
  };

  const changeProfitMargin = (event) => {
    let { value } = event.target;

    if (value < 0) {
      value = 0;
    }

    if (value > 70) {
      value = 70;
    }

    handleChangeProfitMargin(value / 100);
    setSetProfitMargin(value);
  };

  const changeAdditionalOnline = (event) => {
    const { value } = event.target;
    const { cost } = calculateProductCost(selectedProduct);

    handleChangeAdditionalOnline(parseFloat(value) || 0);

    if (cost) {
      const percentage = (1 - (1 - value / cost)) * 100;
      setAdditionalOnlinePercentage(Math.round(percentage));
    } else {
      setAdditionalOnlinePercentage(0);
    }

    setAdditionalOnline(value);
  };

  const changeAdditionalOnlinePercentage = (event) => {
    const { value } = event.target;
    const { cost } = calculateProductCost(selectedProduct);

    if (cost) {
      const formatedValue = cost * (value / 100);
      handleChangeAdditionalOnline(formatedValue);
      setAdditionalOnline(parseFloat(formatedValue.toFixed(2)));
    }

    setAdditionalOnlinePercentage(value);
  };

  useEffect(() => {
    const { cost } = calculateProductCost(selectedProduct);

    if (cost) {
      const percentage = (1 - (1 - selectedProduct.additionalOnline / cost)) * 100;
      setAdditionalOnlinePercentage(Math.round(Math.round(percentage)));
    } else {
      setAdditionalOnlinePercentage(0);
    }
  }, [selectedProduct.entryUnitaryCost]);

  useEffect(() => {
    setSimples(selectedProduct.simples * 100);
  }, [selectedProduct.stRate]);

  return (
    <Paper>
      <Paper>
        <Box padding={2}>
          <Box marginBottom={1}>
            <Typography variant="h3">Valores sobre o preço de venda</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container alignItems="flex-end" spacing={0.5}>
                <Grid item xs={8}>
                  <CustomInput
                    label="Simples Nacional"
                    value={numberToRealWithPeriod(
                      calculateProductCost(selectedProduct).simplesCost,
                    )}
                    size="medium"
                    variant="standard"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInput
                    value={simples}
                    onChange={changeSimples}
                    size="medium"
                    variant="standard"
                    color="secondary"
                    InputEndAdornment="%"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems="flex-end" spacing={0.5}>
                <Grid item xs={8}>
                  <CustomInput
                    label="Margem de lucro"
                    value={numberToRealWithPeriod(calculateProductCost(selectedProduct).profit)}
                    size="medium"
                    variant="standard"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInput
                    value={profitMargin}
                    onChange={changeProfitMargin}
                    error={!(profitMargin > 0)}
                    size="medium"
                    variant="standard"
                    color="secondary"
                    InputEndAdornment="%"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems="flex-end" spacing={0.5}>
                <Grid item xs={8}>
                  <CustomInput
                    label={additionalOnline >= 0 ? 'Adicional online' : 'Desconto online'}
                    value={additionalOnline}
                    onChange={changeAdditionalOnline}
                    size="medium"
                    variant="standard"
                    InputStartAdornment="R$"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInput
                    value={additionalOnlinePercentage}
                    onChange={changeAdditionalOnlinePercentage}
                    size="medium"
                    variant="standard"
                    color="secondary"
                    InputEndAdornment="%"
                    InputAdornmentStyle={classes.inputAdornment}
                    disabled={blockFields}
                    number
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Paper>
  );
}
export default memo(ValuesFromPrice);
