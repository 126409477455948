import React, { useCallback, useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CategoryTree from './CategoryTree';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import SearchField from '../../../../Common/SearchField';
import DialogViaVarejo from './DialogViaVarejo';
import CustomInput from '../../../../CustomInput';

const useStyles = makeStyles({
  sourceBadge: {
    width: 45,
    height: 45,
    marginTop: 5,
  },
});

function DialogCategory({ openDialogCategory, handleCloseDialogCategory }) {
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState();
  const [categoriesList, setCategoriesList] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const getAllCategories = async () => {
    try {
      setLoadingCategories(true);

      const response = await Axios.get('/catalog/categories');
      const { categories } = response.data;

      setCategoriesList(categories);
      setLoadingCategories(false);
    } catch (error) {
      console.log(error);
      setLoadingCategories(false);
    }
  };

  const [refreshToken, setRefreshToken] = useState();

  useEffect(() => {
    getAllCategories();
  }, [refreshToken]);

  const [searchTextViaVarejo, setSearchTextViaVarejo] = useState('');
  const [openDialogCategoryViaVarejo, setOpenDialogCategoryViaVarejo] = useState(false);

  const handleOpenDialogCategoryViaVarejo = useCallback((searchText) => {
    setSearchTextViaVarejo(searchText);
    setOpenDialogCategoryViaVarejo(true);
  }, []);

  const handleCloseDialogCategoryViaVarejo = useCallback(
    () => setOpenDialogCategoryViaVarejo(false),
    [],
  );

  const handleSelectViaVarejoCategory = (category) => {
    setSelectedCategory((oldFields) => ({
      ...oldFields,
      viaVarejoCategory: category,
    }));

    handleCloseDialogCategoryViaVarejo();
  };

  const handleSaveCategory = async () => {
    try {
      if (selectedCategory && selectedCategory._id) {
        setLoadingCategories(true);
        await Axios.put(`/catalog/categories/${selectedCategory._id}`, {
          category: selectedCategory,
        });

        setRefreshToken(Math.random());
        setLoadingCategories(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingCategories(false);
    }
  };

  return (
    <SimpleDialog
      dialogTitle="Escolher categoria"
      content={
        <Grid container>
          {loadingCategories ? (
            <Grid item xs={6}>
              <SimpleBackdrop loading={loadingCategories} />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <CategoryTree
                categoriesList={categoriesList}
                setRefreshToken={setRefreshToken}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSearchTextViaVarejo={setSearchTextViaVarejo}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography variant="h6">Mapeamento</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Para facilitar a criação de novos anúncios você pode vincular sua categoria
                      interna com outras categorias nos marketplaces.
                    </Typography>
                  </Grid>

                  {!selectedCategory ? (
                    <Grid item>
                      <Typography>Selecione uma sub-categoria ao lado.</Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography variant="subtitle2">
                        Categoria selecionada:{' '}
                        {selectedCategory.parentName ? `${selectedCategory.parentName} / ` : ''}{' '}
                        {selectedCategory.name}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Divider />
              {selectedCategory && selectedCategory.slug ? (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Box padding={1}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item>
                              <img
                                className={classes.sourceBadge}
                                src="/assets/imgs/via-badge.svg"
                                alt=""
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6">Via Varejo</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <SearchField
                            submitSearch={handleOpenDialogCategoryViaVarejo}
                            labelSearch="Pesquisar categoria"
                            preSearchText={selectedCategory.name}
                            key={selectedCategory.name}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomInput
                            label="Categoria vinculada"
                            value={
                              selectedCategory &&
                              selectedCategory.viaVarejoCategory &&
                              selectedCategory.viaVarejoCategory.id
                                ? selectedCategory.viaVarejoCategory.name
                                : 'Nenhuma categoria vinculada'
                            }
                            disabled
                            helperText={
                              selectedCategory && selectedCategory.viaVarejoCategory
                                ? selectedCategory.viaVarejoCategory.pathFromRoot
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Divider />
                </Grid>
              ) : null}
            </Grid>

            {openDialogCategoryViaVarejo ? (
              <DialogViaVarejo
                openDialog={openDialogCategoryViaVarejo}
                handleClose={handleCloseDialogCategoryViaVarejo}
                searchTerm={searchTextViaVarejo}
                handleClick={handleSelectViaVarejoCategory}
              />
            ) : null}
          </Grid>
        </Grid>
      }
      handleClose={handleCloseDialogCategory}
      openDialog={openDialogCategory}
      cancelButtonText="Cancelar"
      confirmButtonText="Salvar"
      disableConfirmButton={!selectedCategory}
      handleConfirmButton={handleSaveCategory}
    />
  );
}

export default DialogCategory;
