import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';
import filterOrderSourceInitial from '../../../../constant/filterOrderSourceInitial';

const useStyles = makeStyles(() => ({
  helpIcon: {
    marginRight: -5,
    marginLeft: 10,
    marginBottom: -4,
  },
  select: {
    minWidth: 190,
  },
}));

function TopTab({ orderForm, setOrderForm, setSelectedCompany, setSnackbar }) {
  const classes = useStyles();

  const handleChangeCompany = (_, newValue) => {
    if (newValue) {
      setOrderForm((oldFields) => ({
        ...oldFields,
        company: newValue,
      }));
    } else {
      setOrderForm((oldFields) => ({
        ...oldFields,
        company: null,
      }));
    }
  };

  const handleChangeSource = (source) =>
    setOrderForm((oldFields) => ({
      ...oldFields,
      source,
    }));

  useEffect(() => {
    if (!orderForm.orderId) {
      if (orderForm.source === 'Loja física' || orderForm.source === 'Telemarketing') {
        handleChangeCompany(null, '64b67ebcf49f11e4433e22f7');
      } else {
        handleChangeCompany(null, '62cc8da75e1e72293c3842f9');
      }
    }
  }, [orderForm.source]);

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Chip
          color="primary"
          label={
            orderForm.orderId ? (
              <Grid container alignItems="center" justifyContent="space-between">
                Código: {orderForm.orderId}
                {orderForm.othersIds ? (
                  <Tooltip
                    placement="bottom-start"
                    title={
                      <Grid container direction="column" spacing={1}>
                        {Object.keys(orderForm.othersIds).map((key) => (
                          <Grid item key={`${key} ${orderForm.othersIds[key]}`}>
                            <Grid container direction="column">
                              <Typography variant="caption">{key}</Typography>
                              <Typography variant="caption">{orderForm.othersIds[key]}</Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    }
                  >
                    <Box>
                      <HelpIcon width="30" className={classes.helpIcon} />
                    </Box>
                  </Tooltip>
                ) : null}
              </Grid>
            ) : (
              'Novo pedido'
            )
          }
        />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <CompanyAutocomplete
              setSnackbar={setSnackbar}
              handleOnChange={handleChangeCompany}
              selectedCompany={orderForm.company}
              setSelectedCompany={setSelectedCompany}
              error={!orderForm.company}
              disabled={
                !(
                  orderForm.status === 'Orçamento' ||
                  orderForm.status === 'Pedido criado' ||
                  orderForm.status === 'Pedido confirmado'
                )
              }
              variant="filled"
              className={classes.select}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel>Origem</InputLabel>
                <Select
                  fullWidth
                  className={classes.select}
                  onChange={(event) => handleChangeSource(event.target.value)}
                  value={orderForm.source}
                  error={!orderForm.source}
                  disabled={orderForm.status !== 'Orçamento'}
                >
                  {filterOrderSourceInitial.map((source) => (
                    <MenuItem value={source.name} key={source.name}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopTab;
