import Axios from 'axios';

export const getWeighingBalances = async () => {
  const response = await Axios.get('/weighing-balances');
  return response.data;
};

export const getBalanceWeight = async (balance) => {
  const response = await Axios.get('/weighing-balances/weight', {
    params: balance,
  });
  return response.data;
};

const getAutoBalancesWeight = async ({ setSnackbar }) => {
  try {
    const balances = await getWeighingBalances();
    const promiseResults = [];

    balances.forEach((balance) => {
      promiseResults.push(getBalanceWeight(balance));
    });

    const results = await Promise.all(promiseResults);

    if (results.filter((result) => parseFloat(result.weight) > 0).length > 1) {
      setSnackbar({
        message: 'No modo automático use apenas uma balança por vez',
        open: true,
        type: 'error',
      });

      return '00000';
    }

    if (results.length > 0) {
      const result = results.find((findResult) => parseFloat(findResult.weight) > 0);

      if (
        result &&
        (result.string.split('').includes('p') || result.string.split('').includes('q'))
      ) {
        setSnackbar({
          message: result.message,
          open: true,
          type: result.success ? 'success' : 'error',
        });

        return result.weight;
      }
    }
    return '00000';
  } catch (error) {
    let errorMessage = 'Algum erro ocorreu ao buscar o peso das balanças';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    setSnackbar({
      message: errorMessage,
      open: true,
      type: 'error',
    });

    return '00000';
  }
};

export default getAutoBalancesWeight;
