import { TableCell, TableFooter, TableRow } from '@mui/material';
import React from 'react';
import CustomPagination from '../../../Common/CustomPagination';

function UsersTableFooter({ page, totalUsers, limit, handleChangePage }) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={7}>
          <CustomPagination
            page={page}
            total={totalUsers}
            limit={limit}
            handleChangePage={handleChangePage}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default UsersTableFooter;
