import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function FilterOrdenateOptions({
  inputLabel = 'Filtrar',
  width = 200,
  size = 'small',
  filtersOrdenateOptions,
  setFiltersOrdenateOptions,
}) {
  const classes = useStyles();

  const [temporaryFiltersOptions, setTemporaryFiltersOptions] = useState(filtersOrdenateOptions);
  const [openFilterSelect, setOpenFilterSelect] = useState(false);
  const handleOpenFilterSelect = () => setOpenFilterSelect(true);
  const handleCloseFilterSelect = () => setOpenFilterSelect(false);

  const handleGetRenderValue = () => {
    let filterName = 'Nenhum selecionado';
    filtersOrdenateOptions.forEach((filter) => {
      if (filter.active) {
        filterName = filter.name || filter.description;
      }
    });
    return filterName;
  };

  const handleSetFilter = (index) => {
    const newFilterOptions = temporaryFiltersOptions;
    newFilterOptions.forEach((_, indexInside) => {
      if (index === indexInside) {
        newFilterOptions[indexInside].active = !newFilterOptions[indexInside].active;
      } else {
        newFilterOptions[indexInside].active = false;
      }
    });
    return setTemporaryFiltersOptions(() => [...newFilterOptions]);
  };

  const handleConfirmFilter = () => {
    setFiltersOrdenateOptions(temporaryFiltersOptions);
    handleCloseFilterSelect();
  };

  useEffect(() => {
    setTemporaryFiltersOptions(filtersOrdenateOptions);
  }, [filtersOrdenateOptions]);

  return (
    <Box width={width} className={classes.selectBackground}>
      <FormControl fullWidth variant="filled" size={size} color="secondary">
        <InputLabel>{inputLabel}</InputLabel>

        <Select
          fullWidth
          multiple
          value={['Todos selecionados']}
          renderValue={handleGetRenderValue}
          open={openFilterSelect}
          onClose={handleCloseFilterSelect}
          onClick={handleOpenFilterSelect}
          MenuProps={{ disableScrollLock: true }}
        >
          <Box width={250}>
            <ClickAwayListener onClickAway={handleCloseFilterSelect}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  {temporaryFiltersOptions.map((filter, index) => (
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      key={filter.description}
                      onClick={() => handleSetFilter(index)}
                    >
                      <Grid item>
                        <Checkbox checked={filter.active} />
                      </Grid>
                      <Grid item>
                        {filter.customName || filter.name ? (
                          <ListItemText primary={filter.customName || filter.name} />
                        ) : null}
                        {filter.description ? (
                          <Typography
                            style={{
                              fontSize: 12,
                              color: 'grey',
                            }}
                          >
                            {filter.description}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" justifyContent="center">
                    <Button size={size} onClick={handleConfirmFilter} variant="contained">
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Box>
        </Select>
      </FormControl>
    </Box>
  );
}

export default memo(FilterOrdenateOptions);
