import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { formatDateWithHour } from '../../../../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  tableRowEntry: {
    cursor: 'pointer',
  },
}));

function MovementTable({ handleSelectOrder, stockMovement }) {
  const classes = useStyles();

  const getLabelColorType = (entry) => {
    if (entry.type === 'Entrada') {
      return { bg: '#45046a', color: '#fff' };
    }
    return { bg: '#ffdcb4', color: '#000' };
  };

  return (
    <Box position="relative" marginBottom={5}>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">Quantidade</TableCell>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">Origem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockMovement.map((movement) => (
                <Tooltip
                  key={movement._id}
                  title={<Typography align="center">{movement.description}</Typography>}
                >
                  <TableRow
                    onClick={() => handleSelectOrder(movement)}
                    className={movement.sourceId ? classes.tableRowEntry : null}
                  >
                    <TableCell align="center">
                      <Chip
                        label={movement.type}
                        size="small"
                        style={{
                          backgroundColor: getLabelColorType(movement).bg,
                          color: getLabelColorType(movement).color,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{movement.quantity}</TableCell>
                    <TableCell align="center">{formatDateWithHour(movement.date)}</TableCell>
                    <TableCell align="center">
                      {movement.sourceId
                        ? `${movement.sourceId} - ${movement.sourceType}`
                        : `${movement.sourceType}`}
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
              {stockMovement.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>Nenhuma movimentação para exibir</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default MovementTable;
