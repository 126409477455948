import Axios from 'axios';
import { Alert, Grid, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { formatReal, numberToRealWithPeriod, onlyNumbersValue } from '../../../helpers/formatData';
import { createCheque, updateCheque } from '../../../services/melhorGestao/cheques';
import { isCMC7 } from '../../../services/melhorGestao/validator';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import CustomDatePicker from '../../CustomDatePicker';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 3,
  },
  valueField: {
    width: 180,
  },
});

function ChequeDetails({
  goodFor,
  openDialogDetail,
  handleCloseDialogDetail,
  selectedCheque,
  handleConfirmCheque = () => {},
  blockEdit = false,
  refreshOnSave = () => {},
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [cheque, setCheque] = useState(selectedCheque);
  const [value, setValue] = useState(cheque.value.toFixed(2));
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (goodFor) {
      setCheque((oldFields) => ({
        ...oldFields,
        goodFor,
      }));
    }
  }, [goodFor]);

  const [fieldError, setFieldError] = useState({
    bankNumber: false,
    agency: false,
    account: false,
    chequeNumber: false,
    barCode: false,
    value: false,
    name: false,
    goodFor: false,
    emissionDate: false,
  });

  const handleConfirm = useCallback(async () => {
    if (
      !cheque.bankNumber ||
      !cheque.agency ||
      !cheque.account ||
      !cheque.chequeNumber ||
      !cheque.barCode ||
      !cheque.value ||
      !cheque.name ||
      !cheque.goodFor ||
      !cheque.emissionDate
    ) {
      setFieldError({
        bankNumber: !cheque.bankNumber,
        agency: !cheque.agency,
        account: !cheque.account,
        chequeNumber: !cheque.chequeNumber,
        barCode: !cheque.barCode,
        value: !cheque.value,
        name: !cheque.name,
        goodFor: !cheque.goodFor,
        emissionDate: !cheque.emissionDate,
      });

      return setSnackbar({
        message: 'Preencha todos os campos',
        open: true,
        type: 'error',
      });
    }

    let result;
    if (cheque.chequeId) {
      result = await updateCheque({ setSnackbar, cheque });
    } else {
      result = await createCheque({ setSnackbar, cheque });
    }

    if (result) {
      handleConfirmCheque(result);
      refreshOnSave();

      return handleCloseDialogDetail();
    }
    return null;
  }, [cheque]);

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setFieldError((oldFields) => ({
      ...oldFields,
      [fieldName]: !fieldValue,
    }));

    setCheque((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const handleChangeValue = (event) => {
    const newTotalValue = formatReal(onlyNumbersValue(event.target.value));
    setValue(newTotalValue);

    setFieldError((oldFields) => ({
      ...oldFields,
      value: parseFloat(newTotalValue) <= 0,
    }));

    setCheque((oldFields) => ({
      ...oldFields,
      value: parseFloat(newTotalValue),
    }));
  };

  const handleChangeEmissionDate = (date) => {
    setFieldError((oldFields) => ({
      ...oldFields,
      emissionDate: !date,
    }));

    setCheque((oldFields) => ({
      ...oldFields,
      emissionDate: date,
    }));
  };

  const handleChangeGoodForDate = (date) => {
    setFieldError((oldFields) => ({
      ...oldFields,
      goodFor: !date,
    }));

    setCheque((oldFields) => ({
      ...oldFields,
      goodFor: date,
    }));
  };

  useEffect(() => {
    setReadOnly(blockEdit);
  }, [blockEdit]);

  const [timeoutValidatorCmc7Id, setTimeoutValidatorCmc7Id] = useState();
  const [loadingValidatorCmc7, setLoadingValidatorCmc7] = useState(false);

  const handleGetChequeData = async (barCode) => {
    const resultCmc7 = await isCMC7(barCode);

    setFieldError((oldFields) => ({
      ...oldFields,
      barCode: !resultCmc7,
    }));

    if (resultCmc7) {
      const getCmc7Data = (cmc7) => ({
        bankNumber: cmc7.substring(0, 3),
        agency: cmc7.substring(3, 7),
        account: cmc7.substring(23, 29),
        chequeNumber: cmc7.substring(11, 17),
      });

      const responseCheques = await Axios.patch('/financial/cheques', {
        ids: [barCode],
      });
      const getCheques = responseCheques.data;

      if (getCheques.length > 0) {
        setReadOnly(true);
        setCheque((oldFields) => ({
          ...oldFields,
          ...getCheques[0],
          ...getCmc7Data(getCheques[0].barCode),
        }));
        setValue(getCheques[0].value);
      } else {
        setCheque((oldFields) => ({
          ...oldFields,
          ...getCmc7Data(barCode),
        }));
      }
    }
  };

  useEffect(() => {
    if (cheque.barCode) {
      setLoadingValidatorCmc7(true);

      if (timeoutValidatorCmc7Id) {
        clearTimeout(timeoutValidatorCmc7Id);
      }

      const newTimeoutId = setTimeout(() => {
        handleGetChequeData(cheque.barCode);
        setLoadingValidatorCmc7(false);
      }, 1000);

      setTimeoutValidatorCmc7Id(newTimeoutId);
    }
  }, [cheque.barCode]);

  return (
    <SimpleDialog
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      maxWidth="sm"
      content={
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <CustomInput
              label="Banco"
              value={cheque.bankNumber}
              onChange={handleChange}
              disabled={Boolean(cheque.bankNumber) && readOnly}
              error={fieldError.bankNumber}
              name="bankNumber"
              number
            />
          </Grid>

          <Grid item xs={2}>
            <CustomInput
              label="Agência"
              value={cheque.agency}
              onChange={handleChange}
              disabled={Boolean(cheque.agency) && readOnly}
              error={fieldError.agency}
              name="agency"
              number
            />
          </Grid>

          <Grid item xs={5}>
            <CustomInput
              label="Conta"
              value={cheque.account}
              onChange={handleChange}
              disabled={Boolean(cheque.account) && readOnly}
              error={fieldError.account}
              name="account"
              number
            />
          </Grid>

          <Grid item xs={3}>
            <CustomInput
              label="Cheque Nº"
              value={cheque.chequeNumber}
              onChange={handleChange}
              disabled={Boolean(cheque.chequeNumber) && readOnly}
              error={fieldError.chequeNumber}
              name="chequeNumber"
              number
            />
          </Grid>

          <Grid item xs={8}>
            <CustomInput
              label="Código de Barra"
              value={cheque.barCode}
              onChange={handleChange}
              disabled={Boolean(cheque.barCode) && readOnly}
              name="barCode"
              number
              error={fieldError.barCode}
              loading={loadingValidatorCmc7}
            />
          </Grid>

          <Grid item xs={4}>
            <CustomInput
              label="Valor"
              value={value ? numberToRealWithPeriod(value) : '0,00'}
              onChange={handleChangeValue}
              disabled={Boolean(cheque.value) && readOnly}
              error={fieldError.value}
              InputStartAdornment="R$"
              InputAdornmentStyle={classes.inputAdornment}
              className={classes.valueField}
            />
          </Grid>

          <Grid item xs={8}>
            <CustomInput
              label="Titular"
              value={cheque.name}
              onChange={handleChange}
              disabled={Boolean(cheque.name) && readOnly}
              error={fieldError.name}
              name="name"
            />
          </Grid>

          <Grid item xs={4}>
            <CustomDatePicker
              variant="standard"
              label="Data de emissão"
              format="DD/MM/YYYY"
              value={cheque.emissionDate}
              disabled={Boolean(cheque.emissionDate) && readOnly}
              error={fieldError.emissionDate}
              onChange={handleChangeEmissionDate}
            />
          </Grid>

          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
      }
      dialogTitle={
        <Grid container spacing={3} alignItems="center">
          <Grid item>Adicionar cheque</Grid>
          <Grid item>
            <CustomDatePicker
              size="small"
              disabled={readOnly}
              label="Bom para"
              format="DD/MM/YYYY"
              value={cheque.goodFor}
              error={fieldError.goodFor}
              onChange={handleChangeGoodForDate}
            />
          </Grid>
        </Grid>
      }
      handleCancelButton={handleCloseDialogDetail}
      handleClose={handleCloseDialogDetail}
      handleConfirmButton={handleConfirm}
      openDialog={openDialogDetail}
    />
  );
}

export default ChequeDetails;
