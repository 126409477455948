const newCheque = {
  bankNumber: '',
  agency: '',
  account: '',
  chequeNumber: '',
  barCode: '',
  value: 0,
  name: '',
  situation: 'Novo',
  emissionDate: null,
  goodFor: null,
  movements: [],
};

export default newCheque;
