import React, { memo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import MercadoLivreAttribute from './MercadoLivreAttribute';

function MercadoLivreAttributeDialog({
  openMercadoLivreAttributeDialog,
  handleCloseMercadoLivreAttributeDialog,
  adForm,
  setAdForm,
}) {
  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });
  };

  const [attributesFieldsFromProduct, setAttributesFieldsFromProduct] = useState([]);

  const handleConfirmButton = () => {
    setAdForm((oldFields) => ({
      ...oldFields,
      attributes: attributesFieldsFromProduct,
    }));

    return handleCloseMercadoLivreAttributeDialog();
  };

  return (
    <SimpleDialog
      openDialog={openMercadoLivreAttributeDialog}
      handleClose={handleCloseMercadoLivreAttributeDialog}
      dialogTitle="Características do anúncio"
      dialogText="Preencha as características técnicas do produto referente a categoria"
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseMercadoLivreAttributeDialog}
      handleConfirmButton={handleConfirmButton}
      content={
        <>
          <MercadoLivreAttribute
            adForm={adForm}
            attributesFieldsFromProduct={attributesFieldsFromProduct}
            setAttributesFieldsFromProduct={setAttributesFieldsFromProduct}
            setSnackbar={setSnackbar}
          />
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </>
      }
      maxWidth="sm"
    />
  );
}

export default memo(MercadoLivreAttributeDialog);
