import Axios from 'axios';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import TocIcon from '@mui/icons-material/Toc';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatDate, formatHandleError, numberToReal } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';
import SearchField from '../../../../Common/SearchField';
import CustomPagination from '../../../../Common/CustomPagination';

function ItemsBoughtButton({
  orderForm,
  setUserOrders,
  userItemsBought,
  setUserItemsBought,
  setSnackbar,
}) {
  const [openDialogItemsBought, setOpenDialogItemsBought] = useState(false);
  const handleOpenDialogItemsBought = () => setOpenDialogItemsBought(true);
  const handleCloseDialogItemsBought = () => setOpenDialogItemsBought(false);
  const [searchText, setSearchText] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);

  useEffect(() => {
    const fetchUserOrders = async (userId) => {
      try {
        const response = await Axios.get(`/orders/user/${userId}`, {
          params: {
            searchText,
            page,
            limit,
            skip: page * limit - limit,
          },
        });

        setTotalItems(response.data.totalItems);
        setUserItemsBought(response.data.items);
        setUserOrders(response.data.orders);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar pedidos do usuário',
          error,
        });
      }
    };

    if (orderForm.customer.userId) {
      fetchUserOrders(orderForm.customer.userId);
    }
  }, [orderForm.customer, searchText, page]);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1);
  }, [searchText]);

  return (
    <Box>
      <Tooltip title={<Typography align="center">Produtos que este cliente já comprou</Typography>}>
        <IconButton onClick={handleOpenDialogItemsBought} size="small">
          <TocIcon />
        </IconButton>
      </Tooltip>
      {openDialogItemsBought ? (
        <SimpleDialog
          openDialog={openDialogItemsBought}
          handleClose={handleCloseDialogItemsBought}
          dialogTitle={
            <Grid container spacing={3} alignItems="center">
              <Grid item>Itens que este cliente já comprou</Grid>
              <Grid item>
                <SearchField submitSearch={setSearchText} labelSearch="Buscar produto" />
              </Grid>
            </Grid>
          }
          content={
            <Box width={900}>
              <Paper elevation={4}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">SKU</TableCell>
                      <TableCell align="center">Imagem</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell align="center">Preço</TableCell>
                      <TableCell align="center">Quantidade</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">Pedido</TableCell>
                      <TableCell align="center">Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userItemsBought && userItemsBought.length > 0
                      ? userItemsBought.map((item) => (
                          <TableRow key={`${item.orderId}${item.productId}`}>
                            <TableCell align="center">{item.productId}</TableCell>
                            <TableCell align="center">
                              <Box maxWidth="56px" maxHeight="56px">
                                {item.imageSrc ? (
                                  <SimpleImage
                                    src={item.imageSrc}
                                    height={56}
                                    width={56}
                                    alt={item.name}
                                  />
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell align="center">R${numberToReal(item.price)}</TableCell>
                            <TableCell align="center">{item.quantity}</TableCell>
                            <TableCell align="center">R${numberToReal(item.totalPrice)}</TableCell>
                            <TableCell align="center">{item.orderId}</TableCell>
                            <TableCell align="center">{formatDate(item.createdAt)}</TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <CustomPagination
                          page={page}
                          total={totalItems}
                          limit={limit}
                          handleChangePage={handleChangePage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Box>
          }
        />
      ) : null}
    </Box>
  );
}

export default ItemsBoughtButton;
