import { Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { numberToRealWithPeriod } from '../../../../../helpers/formatData';
import { getUser } from '../../../../../services/melhorGestao/users';

function CustomerCreditBlok({ customer }) {
  const [loading, setLoading] = useState(false);
  const [credit, setCredit] = useState(customer.credit || {});

  useEffect(() => {
    const fetchData = async () => {
      if (customer.userId) {
        setLoading(true);
        const user = await getUser(customer.userId);

        if (user.credit) {
          setCredit(user.credit);
        }

        setLoading(false);
      }
    };
    fetchData();
  }, [customer.userId]);

  if (!customer.userId) {
    return null;
  }

  return (
    <>
      <Grid item xs={8}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Débito / Limite</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                {loading ? (
                  <Skeleton animation="wave" width={100} height={25} />
                ) : (
                  <Typography style={{ color: '#ff2222' }}>
                    R$ {numberToRealWithPeriod(credit.totalPending || 0)}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography>/</Typography>
              </Grid>
              <Grid item>
                {loading ? (
                  <Skeleton animation="wave" width={100} height={25} />
                ) : (
                  <Typography style={{ color: '#00c600' }}>
                    R$ {numberToRealWithPeriod(credit.negativeCreditLimit || 0)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="column">
          <Typography variant="caption">Crédito</Typography>
          {loading ? (
            <Skeleton animation="wave" width={100} height={25} />
          ) : (
            <Typography style={{ color: '#0d00fc' }}>
              R$ {numberToRealWithPeriod(credit.totalValue || 0)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerCreditBlok;
