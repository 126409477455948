import Axios from 'axios';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import CustomDatePicker from '../../CustomDatePicker';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import filterProductStatusInitial from '../../../constant/filterProductStatusInitial';
import Spreadsheet from './Spreadsheet';
import { formatHandleError } from '../../../helpers/formatData';

function StocksReports() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [filterCompany, setFilterCompany] = useState([]);
  const [filterStatus, setFilterStatus] = useState(filterProductStatusInitial);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'month').endOf('month'));
  const [fromTheStart, setFromTheStart] = useState(true);
  const [onlyWithInvoice, setOnlyWithInvoice] = useState(false);
  const [mergeInAveragePrice, setMergeInAveragePrice] = useState(false);
  const [inputsWithSubtractedOutputs, setInputsWithSubtractedOutputs] = useState(false);
  const [loading, setLoading] = useState(false);

  const [importedProductsFromXlsx, setImportedProductsFromXlsx] = useState([]);

  const [filterType, setFilterType] = useState([
    {
      name: 'Saída',
      active: false,
    },
    {
      name: 'Entrada',
      active: false,
    },
  ]);

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const getReports = async () => {
    try {
      setLoading(true);

      const response = await Axios.post('/reports/stocks', {
        filterCompany,
        filterType,
        filterStatus,
        startDate: fromTheStart ? new Date(1) : startDate,
        endDate,
        onlyWithInvoice,
        mergeInAveragePrice,
        inputsWithSubtractedOutputs,
        importedProductsFromXlsx,
      });

      const { url } = response.data;

      window.open(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar relatório de estoque',
        error,
      });
    }
  };

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <CompanySelectOptions
                    inputLabel="Empresa"
                    filtersOptions={filterCompany}
                    setFiltersOptions={setFilterCompany}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Tipo"
                    filtersOptions={filterType}
                    setFiltersOptions={setFilterType}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FilterSelectOptions
                    inputLabel="Status"
                    filtersOptions={filterStatus}
                    setFiltersOptions={setFilterStatus}
                    hideButton
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={onlyWithInvoice}
                        onChange={() => setOnlyWithInvoice(!onlyWithInvoice)}
                        checked={onlyWithInvoice}
                        color="secondary"
                      />
                    }
                    label="Buscar somente faturados"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={fromTheStart}
                        onChange={() => setFromTheStart(!fromTheStart)}
                        checked={fromTheStart}
                        color="secondary"
                      />
                    }
                    label="Desde o início"
                  />
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data inicial"
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleChangeStartDate}
                      disabled={fromTheStart}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={200}>
                    <CustomDatePicker
                      size="small"
                      label="Data final"
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleChangeEndDate}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={inputsWithSubtractedOutputs}
                        onChange={() => {
                          setInputsWithSubtractedOutputs(!inputsWithSubtractedOutputs);
                          setMergeInAveragePrice(!inputsWithSubtractedOutputs);
                        }}
                        checked={inputsWithSubtractedOutputs}
                        color="secondary"
                      />
                    }
                    label="Entradas com saídas subtraídas"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                {loading ? (
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                      <Grid item>
                        <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        getReports();
                      }}
                    >
                      Baixar relatório
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Spreadsheet
                    setImportedProductsFromXlsx={setImportedProductsFromXlsx}
                    setSnackbar={setSnackbar}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={mergeInAveragePrice}
                        onChange={() => setMergeInAveragePrice(!mergeInAveragePrice)}
                        checked={mergeInAveragePrice}
                        color="secondary"
                      />
                    }
                    label="Mesclar no preço médio"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export default StocksReports;
