import React, { useState } from 'react';
import { Box, Grid, MenuItem, MenuList, SvgIcon, Typography } from '@mui/material';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../Common/SimpleDialog';
import { availableIntegrations } from '../../../../constant/availableIntegrations';
import SimpleImage from '../../../Common/SimpleImage';

const useStyles = makeStyles({
  openedTab: {
    fontSize: '1.9rem',
    margin: 'auto',
    animation: '$opening 0.5s 1',
  },
  closedTab: {
    fontSize: '1.9rem',
    margin: 'auto',
    transform: 'rotate(90deg)',
    animation: '$closing 0.5s 1',
  },
  optionsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: 5,
    backgroundColor: '#f1f1f1',
    borderRadius: 5,
    height: 40,
    cursor: 'pointer',
  },
  '@keyframes opening': {
    '0%': {
      transform: 'rotate(90deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes closing': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(90deg)',
    },
  },
});

function SelectIntegrationDialog({
  openDialogSelectIntegration,
  handleCloseDialogSelectIntegration,
  setSelectedIntegration,
}) {
  const classes = useStyles();

  const [newSelectedCategoryIntegration, setNewSelectedCategoryIntegration] = useState('');

  const handleSelectNewIntegration = (option) => {
    setSelectedIntegration({ ...option, isNew: true });
    handleCloseDialogSelectIntegration();
  };

  return (
    <SimpleDialog
      maxWidth="xl"
      dialogTitle="Nova integração"
      content={
        <Box width={500}>
          <MenuList>
            {availableIntegrations.map((integration) => (
              <Grid container key={integration.category} style={{ justifyContent: 'center' }}>
                <Grid item xs={12}>
                  <MenuItem
                    onClick={() =>
                      setNewSelectedCategoryIntegration(
                        integration.category === newSelectedCategoryIntegration
                          ? ''
                          : integration.category,
                      )
                    }
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item marginTop={0.8}>
                            <SvgIcon component={integration.icon} />
                          </Grid>
                          <Grid item>
                            <Typography>{integration.category}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item marginTop={0.8}>
                        <KeyboardArrowRightOutlined
                          className={
                            integration.category !== newSelectedCategoryIntegration
                              ? classes.openedTab
                              : classes.closedTab
                          }
                        />
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    style={{
                      gap: 10,
                      padding: 10,
                      justifyContent: 'center',
                    }}
                  >
                    {newSelectedCategoryIntegration === integration.category
                      ? integration.options.map((option) => (
                          <Box
                            className={classes.optionsBox}
                            key={option.name}
                            onClick={() => handleSelectNewIntegration(option)}
                          >
                            <SimpleImage src={option.iconSrc} alt={option.name} imgWidth={100} />
                          </Box>
                        ))
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </MenuList>
        </Box>
      }
      handleCancelButton={handleCloseDialogSelectIntegration}
      handleClose={handleCloseDialogSelectIntegration}
      openDialog={openDialogSelectIntegration}
    />
  );
}

export default SelectIntegrationDialog;
