import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, CircularProgress, Grid, Grow, Typography } from '@mui/material';
import { getMagazineLuizaProductsBySku } from '../../../../../services/melhorGestao/magazineLuiza';
import MagazineLuizaAdResume from './MagazineLuizaAdResume';
import CustomDivider from '../../../../CustomDivider';

function MagazineLuizaAd({ classes, productForm, setProductForm, setFormChanged, refreshSearch }) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingMagazineLuizaAds, setLoadingMagazineLuizaAds] = useState(false);
  const [magazineLuizaAds, setMagazineLuizaAds] = useState([]);

  useEffect(() => {
    if (productForm.productId) {
      getMagazineLuizaProductsBySku({
        setMagazineLuizaAds,
        setLoadingMagazineLuizaAds,
        productId: productForm.productId,
      });
    }
  }, [productForm.productId, refreshSearch]);

  if (!magazineLuizaAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img className={classes.sourceBadge} src="/assets/imgs/magalu-badge.svg" alt="" />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Magazine Luiza
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingMagazineLuizaAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {magazineLuizaAds.length > 0 &&
                magazineLuizaAds.map((magazineLuizaAd) => (
                  <Grid item key={magazineLuizaAd.IdSku}>
                    <MagazineLuizaAdResume
                      magazineLuizaAd={magazineLuizaAd}
                      product={productForm}
                      setProductForm={setProductForm}
                      setFormChanged={setFormChanged}
                    />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

export default MagazineLuizaAd;
