import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { numberToReal } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomDatePicker from '../../../CustomDatePicker';
import OurProductOfferPaper from './OurProductOfferPaper';
import marksUpTo50 from '../../../../constant/marksUpTo50';

const useStyles = makeStyles(() => ({
  smallTypography: {
    fontSize: '0.85rem',
  },
}));

function OffersTab({ productForm, setProductForm }) {
  const classes = useStyles();

  const [changeOfferPricePercentage, setChangeOfferPricePercentage] = useState(false);
  const [offerPricePercentage, setOfferPricePercentage] = useState(0);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(5, 'day'));
  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const [openDialogOfferPrice, setOpenDialogOfferPrice] = useState(false);
  const handleOpenDialogOfferPrice = useCallback(() => setOpenDialogOfferPrice(true), []);
  const handleCloseDialogOfferPrice = useCallback(() => setOpenDialogOfferPrice(false), []);

  const handleChangeOfferStatus = () => {
    let newOfferPrice = {};
    const currentOffers = [];

    productForm.offers.forEach((offer) => {
      if (offer.endDate) {
        if (new Date(offer.startDate) <= new Date() && new Date(offer.endDate) >= new Date()) {
          currentOffers.push(offer);
        }
      } else if (new Date(offer.startDate) <= new Date()) {
        currentOffers.push(offer);
      }
    });

    if (currentOffers.length > 0) {
      const currentOffer = currentOffers.reduce((a, b) =>
        a.discountPercentage > b.discountPercentage ? a : b
      );

      if (!productForm.offer && currentOffer.discountPercentage > 0) {
        newOfferPrice = {
          offer: !productForm.offer,
          offerPrice: productForm.price * (1 - currentOffer.discountPercentage / 100),
        };
      }
    }

    if (productForm.offer) {
      newOfferPrice = {
        offer: !productForm.offer,
        offerPrice: 0,
      };
    }

    return setProductForm((oldFields) => ({
      ...oldFields,
      ...newOfferPrice,
    }));
  };

  const handleCreateOffer = async () => {
    handleCloseDialogOfferPrice();
    const offers = [...productForm.offers];

    offers.push({
      name: `Desconto de ${offerPricePercentage}%`,
      description: 'Desconto criado a partir do painel de edição individual do produto',
      type: 'Temporário',
      quantity: 1,
      discountPercentage: offerPricePercentage,
      offerFor: 'product',
      offerForCode: String(productForm.productId),
      startDate,
      endDate,
    });

    let newOfferPrice = {};
    if (startDate <= new Date() && endDate >= new Date()) {
      const currentOffer = offers
        .filter((offer) => offer.offerFor !== 'payment')
        .reduce((a, b) => (a.discountPercentage > b.discountPercentage ? a : b));

      newOfferPrice = {
        offer: true,
        offerPrice: productForm.price * (1 - currentOffer.discountPercentage / 100),
      };
    }

    setProductForm((oldFields) => ({
      ...oldFields,
      ...newOfferPrice,
      offers,
    }));
  };

  useEffect(() => {
    if (changeOfferPricePercentage) {
      setProductForm((oldFields) => ({
        ...oldFields,
        offerPrice: oldFields.price * (1 - offerPricePercentage / 100),
      }));

      setChangeOfferPricePercentage(false);
    }
  }, [offerPricePercentage, productForm]);

  useEffect(() => {
    const offerDiscountPercentage = (productForm.offerPrice / productForm.price - 1) * 100 || 0;
    setOfferPricePercentage(parseInt(Math.round(offerDiscountPercentage), 10));
  }, []);

  const handleChangeOfferPriceSlider = useCallback((event, newValue) => {
    setChangeOfferPricePercentage(true);
    setOfferPricePercentage(newValue);
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (startDate < new Date(new Date().setDate(new Date().getDate() - 1))) {
      return setStartDate(new Date());
    }
    if (startDate > endDate) {
      setErrorMessage('A data de início deve ser menor que a de término');
      return setDisableConfirmButton(true);
    }
    if (startDate > new Date(new Date().setDate(new Date().getDate() + 60))) {
      setErrorMessage('A data de início deve ser dentro de 60 dias');
      return setDisableConfirmButton(true);
    }
    if (endDate > new Date(new Date().setDate(new Date().getDate() + 90))) {
      setErrorMessage('A data de término deve contar no máximo 60 dias a partir do início');
      return setDisableConfirmButton(true);
    }
    if (offerPricePercentage === 0) {
      setErrorMessage('A valor da porcentagem deve ser no mínimo 1%');
      return setDisableConfirmButton(true);
    }
    setErrorMessage(`Isso será feito no produto de SKU: ${productForm.productId}`);
    return setDisableConfirmButton(false);
  }, [startDate, endDate, offerPricePercentage]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Promoções</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  value={productForm.offer}
                  onChange={handleChangeOfferStatus}
                  checked={productForm.offer}
                  name="offer"
                  color="primary"
                />
              }
              label={
                <Box className={classes.smallTypography}>
                  {productForm.offer ? 'Ativadas' : 'Desativadas'}
                </Box>
              }
            />
          </Grid>
          <Grid item>
            <Tooltip title={<Typography align="center">Crie aqui uma nova promoção</Typography>}>
              <Box>
                <Button variant="contained" onClick={handleOpenDialogOfferPrice}>
                  Nova Promoção
                </Button>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={5}>
        <Grid container spacing={1}>
          {productForm.offers.length > 0 ? (
            productForm.offers.map((productOffer, index) => (
              <Grid item key={productOffer._id || Math.random()}>
                <OurProductOfferPaper
                  productForm={productForm}
                  setProductForm={setProductForm}
                  productOffer={productOffer}
                  productOfferIndex={index}
                />
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>Nenhuma promoção foi criada para este produto</Typography>
            </Grid>
          )}
        </Grid>

        {openDialogOfferPrice && (
          <SimpleDialog
            openDialog={openDialogOfferPrice}
            handleClose={handleCloseDialogOfferPrice}
            dialogTitle="Alterar preço promocional"
            dialogText="Informe o valor do desconto em porcentagem. Máximo 50%"
            cancelButtonText="Cancelar"
            confirmButtonText="Confirmar"
            handleCancelButton={handleCloseDialogOfferPrice}
            handleConfirmButton={handleCreateOffer}
            disableConfirmButton={disableConfirmButton}
            content={
              <Grid container direction="column">
                <Grid item>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                      <Grid container direction="column" justifyContent="center">
                        <Grid item variant="caption">
                          <Typography variant="caption">Preço mínimo</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="baseline" justifyContent="center">
                            <Typography variant="h6" align="center" color="textSecondary">
                              R${numberToReal(productForm.price * (1 - 30 / 100))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justifyContent="center">
                        <Grid item variant="caption">
                          <Typography variant="caption">Preço promocional</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="baseline" justifyContent="center">
                            <Typography variant="h6" align="center">
                              R${numberToReal(productForm.offerPrice)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justifyContent="center">
                        <Grid item variant="caption">
                          <Typography variant="caption">Desconto em %</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="baseline" justifyContent="center">
                            <Typography variant="h6">{offerPricePercentage}%</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item>
                      <Grid container direction="column" justifyContent="center">
                        <Grid item variant="caption">
                          <Typography variant="caption">Inicia em</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="baseline" justifyContent="center">
                            <CustomDatePicker
                              variant="standard"
                              size="small"
                              format="DD/MM/YYYY"
                              value={startDate}
                              onChange={handleChangeStartDate}
                              error={startDate > endDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" justifyContent="center">
                        <Grid item variant="caption">
                          <Typography variant="caption">Termina em</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="baseline" justifyContent="center">
                            <CustomDatePicker
                              variant="standard"
                              size="small"
                              format="DD/MM/YYYY"
                              value={endDate}
                              onChange={handleChangeEndDate}
                              error={endDate < startDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box marginTop={2} padding="0px 14px">
                    <Slider
                      min={0}
                      max={50}
                      step={1}
                      value={offerPricePercentage}
                      onChange={handleChangeOfferPriceSlider}
                      aria-labelledby="input-slider"
                      marks={marksUpTo50}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  {errorMessage && disableConfirmButton && (
                    <Typography color="error">{errorMessage}</Typography>
                  )}
                  {errorMessage && !disableConfirmButton && <Typography>{errorMessage}</Typography>}
                </Grid>
              </Grid>
            }
          />
        )}
      </Box>
    </Grid>
  );
}

export default memo(OffersTab);
