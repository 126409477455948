import React from 'react';
import { Grid, MenuItem, Paper, SvgIcon, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  menuIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
    marginRight: 8,
  },
  menuIconActive: {
    width: '1.4em',
    height: '1.4em',
    color: '#ffb000',
    marginRight: 8,
  },
}));

function TopBarSettings({ menuItems, activeMenu, setActiveMenu }) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Configurações
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1} justifyContent="flex-end">
            {menuItems.map((menuItem) => (
              <Grid key={menuItem.name} item>
                <Paper>
                  <MenuItem onClick={() => setActiveMenu(menuItem.name)}>
                    <SvgIcon
                      component={menuItem.icon}
                      className={
                        activeMenu === menuItem.name ? classes.menuIconActive : classes.menuIcon
                      }
                    />
                    {menuItem.name}
                  </MenuItem>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default TopBarSettings;
