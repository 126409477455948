import {
  Box,
  Chip,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { formatProductToOrder, numberToReal } from '../../../helpers/formatData';
import SearchField from '../../Common/SearchField';
import DiscountDialog from './DiscountDialog';
import SimpleImage from '../../Common/SimpleImage';
import StockBlock from '../elements/StockBlock';
import CustomInput from '../../CustomInput';
import CustomPagination from '../../Common/CustomPagination';
import PriceBlock from '../elements/PriceBlock';
import getProductPrice from '../../../helpers/getProductPrice';
import SimpleLinearProgress from '../../Common/SimpleLinearProgress';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 40,
  },
  mediumCell: {
    width: 83,
  },
  nameCell: {
    width: 150,
  },
  noPaddingCell: {
    padding: 0,
  },
  img: {
    width: '100%',
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
  },
  searchTopRight: {
    position: 'fixed',
    top: 20,
  },
  fabLeftAbove: {
    position: 'fixed',
    bottom: 60,
  },
  fabLeftBelow: {
    position: 'fixed',
    bottom: 20,
  },
  tableRowEmptyStock: {
    backgroundColor: '#fff2f2',
    '&:hover': {
      backgroundColor: '#ffefef',
    },
    cursor: 'pointer',
  },
  tableRowProduct: {
    cursor: 'pointer',
  },
  tableColumnCash: {
    width: 90,
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
}));

function TableProductsModal({
  productsList,
  countProducts,
  handleChangePage,
  page,
  limit,
  productsToDevolution,
  alreadySelectedProducts,
  handleConfirmSelect,
  onClose,
  userItemsBought = [],
  submitSearchProducts,
  orderCompany,
  orderSource,
  loadingProductsList,
}) {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    setSelectedProducts(alreadySelectedProducts || []);
  }, [alreadySelectedProducts]);

  const [itemsTotal, setItemsTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [normalTotal, setNormalTotal] = useState(0);

  useEffect(() => {
    let totalItemsAccumulator = 0;
    let normalTotalAccumulator = 0;

    selectedProducts.forEach((selectedProduct) => {
      totalItemsAccumulator += selectedProduct.selectedPrice * selectedProduct.quantity;
      normalTotalAccumulator += selectedProduct.price * selectedProduct.quantity;
    });

    setItemsTotal(totalItemsAccumulator);
    setDiscountTotal(normalTotalAccumulator - totalItemsAccumulator);
    setNormalTotal(normalTotalAccumulator);
  }, [selectedProducts]);

  const handleChangeSelectedQuantity = useCallback(
    ({ quantity, product }) => {
      let selectedPrice = product.price;
      let quantityOnOrder = quantity;

      if (quantityOnOrder < 0) {
        quantityOnOrder = 0;
      }

      const productPrice = getProductPrice(product);

      if (orderSource === 'Site' || orderSource === 'Telemarketing') {
        if (productPrice.paymentOffer) {
          selectedPrice = productPrice.paymentOfferPrice;
        } else if (product.offer) {
          selectedPrice = product.offerPrice;
        }
      }

      if (orderSource === 'Loja física' && productPrice.physicalStorePrice) {
        selectedPrice = productPrice.physicalStorePrice;
      }

      if (productsToDevolution) {
        selectedPrice = product.selectedPrice;

        const productToDevolution = productsToDevolution.find(
          (productFind) => productFind.productId === product.productId,
        );

        if (quantityOnOrder > productToDevolution.quantity) {
          quantityOnOrder = productToDevolution.quantity;
        }
      }

      setSelectedProducts((alreadySelecteds) => {
        const productIndex = alreadySelecteds.findIndex(
          (selectedProduct) => selectedProduct.productId === product.productId,
        );

        const formattedProduct = {
          ...formatProductToOrder(product),
          quantity: quantityOnOrder,
          selectedPrice,
          positionOnList: productIndex !== -1 ? productIndex : selectedProducts.length,
        };

        if (productIndex >= 0) {
          return alreadySelecteds.map((selectedProduct, index) =>
            index === productIndex ? formattedProduct : selectedProduct,
          );
        }
        return [...alreadySelecteds, formattedProduct];
      });
    },
    [selectedProducts],
  );

  const handleUnselectProduct = useCallback(
    ({ product }) => {
      const newSelectedProducts = selectedProducts.filter(
        (selectedProduct) => selectedProduct.productId !== product.productId,
      );

      const reorderedProducts = newSelectedProducts.map((newSelectedProduct, index) => ({
        ...newSelectedProduct,
        positionOnList: index,
      }));

      setSelectedProducts(reorderedProducts);
    },
    [selectedProducts],
  );

  const checkSelectedProduct = useCallback(
    ({ product }) => {
      let isSelected = false;
      selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.productId === product.productId) {
          isSelected = true;
        }
      });
      return isSelected;
    },
    [selectedProducts],
  );

  const [openDialogDiscount, setOpenDialogDiscount] = useState(false);
  const [selectedProductForDiscount, setSelectedProductForDiscount] = useState(false);

  const handleOpenDialogDiscount = useCallback(({ product }) => {
    setSelectedProductForDiscount(product);
    return setOpenDialogDiscount(true);
  }, []);

  const handleCloseDialogDiscount = () => setOpenDialogDiscount(false);

  const getAlreadyBoughtTag = ({ productId }) => {
    let bought = false;
    userItemsBought.forEach((itemBought) => {
      if (itemBought.productId === productId) {
        bought = true;
      }
    });

    return bought;
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      {discountTotal > 0 ? (
        <Box position="relative" right="10px">
          <Box className={classes.fabLeftAbove}>
            <Fab variant="extended" size="small">
              Desconto: R$ {numberToReal(discountTotal)} (
              {Math.round((1 - (1 - discountTotal / normalTotal)) * 100)}%)
            </Fab>
          </Box>
        </Box>
      ) : null}
      <Box position="relative" right="10px">
        <Box className={classes.fabLeftBelow}>
          <Fab variant="extended" size="small">
            Total dos produtos: R$ {numberToReal(itemsTotal)}
          </Fab>
        </Box>
      </Box>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Produtos selecionados:</Typography>
        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell} align="center">
                  SKU
                </TableCell>
                <TableCell className={classes.smallCell} align="center">
                  Imagem
                </TableCell>
                <TableCell className={classes.nameCell}>Nome</TableCell>
                <TableCell className={classes.tableColumnCash} align="center">
                  Preço
                </TableCell>
                <TableCell className={classes.mediumCell} align="center">
                  Quantidade
                </TableCell>
                <TableCell className={classes.tableColumnCash} align="center">
                  Total
                </TableCell>
                <TableCell className={classes.smallCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts.map((selectedProduct) => (
                <TableRow key={selectedProduct.productId}>
                  <TableCell align="center">{selectedProduct.productId}</TableCell>
                  <TableCell align="center">
                    {selectedProduct.images && selectedProduct.images.length > 0 ? (
                      <SimpleImage
                        src={selectedProduct.images[0].src}
                        height={75}
                        width={75}
                        alt={selectedProduct.name}
                        className={classes.img}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">{selectedProduct.technicalName}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>R${numberToReal(selectedProduct.selectedPrice)}</Typography>
                    {!productsToDevolution ? (
                      <Chip
                        size="small"
                        label={`Desconto${
                          (1 - selectedProduct.selectedPrice / selectedProduct.price) * 100 > 0
                            ? ` ${Math.round(
                                (1 - selectedProduct.selectedPrice / selectedProduct.price) * 100,
                              )}%`
                            : ''
                        }`}
                        color={
                          (1 - selectedProduct.selectedPrice / selectedProduct.price) * 100 > 0
                            ? 'primary'
                            : 'default'
                        }
                        onClick={() => handleOpenDialogDiscount({ product: selectedProduct })}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell align="center">
                    <Grid container justifyContent="space-between" wrap="nowrap" spacing={2}>
                      <Grid item>
                        <CustomInput
                          value={selectedProduct.quantity}
                          onChange={(event) =>
                            handleChangeSelectedQuantity({
                              quantity: event.target.value,
                              product: selectedProduct,
                            })
                          }
                          helperText={
                            selectedProduct.stock - selectedProduct.reservedStock <= 0
                              ? 'Sem estoque'
                              : null
                          }
                          size="medium"
                          number
                          centerText
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    R${numberToReal(selectedProduct.selectedPrice * selectedProduct.quantity)}
                  </TableCell>
                  <TableCell>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => handleUnselectProduct({ product: selectedProduct })}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}

              {selectedProducts.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography>Nenhum produto selecionado</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Selecionar produtos:</Typography>

        <Paper className={classes.paperTable}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.smallCell} align="center">
                    SKU
                  </TableCell>
                  <TableCell className={classes.smallCell} align="center">
                    Imagem
                  </TableCell>
                  <TableCell className={classes.nameCell}>Nome</TableCell>
                  <TableCell>Local</TableCell>
                  <TableCell>Estoque</TableCell>
                  <TableCell>Preço</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsList.map((product) => (
                  <TableRow
                    className={
                      product.stock - product.reservedStock > 0
                        ? classes.tableRowProduct
                        : classes.tableRowEmptyStock
                    }
                    key={product.productId}
                    selected={!!checkSelectedProduct({ product })}
                    onClick={() => {
                      handleChangeSelectedQuantity({
                        quantity: productsToDevolution ? product.quantity : 1,
                        product,
                      });
                    }}
                  >
                    <TableCell align="center">{product.productId}</TableCell>
                    <TableCell align="center">
                      {product.images && product.images.length > 0 ? (
                        <SimpleImage
                          src={product.images[0].src}
                          height={75}
                          width={75}
                          alt={product.name}
                          className={classes.img}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="caption">{product.technicalName}</Typography>
                        </Grid>
                        <Grid item>
                          {getAlreadyBoughtTag({ productId: product.productId }) ? (
                            <Chip size="small" label="Já comprou" color="secondary" />
                          ) : null}
                          {product.stock - product.reservedStock <= 0 ? (
                            <Chip size="small" label="Sem estoque" />
                          ) : null}
                          {product.tags
                            ? product.tags.map((tag) => (
                                <Chip key={tag} size="small" label={tag} color="primary" />
                              ))
                            : null}
                          {product.composition ? (
                            <Chip size="small" label="KIT" color="secondary" />
                          ) : null}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption">{product.localization}</Typography>
                    </TableCell>
                    <TableCell>
                      {productsToDevolution ? (
                        product.quantity
                      ) : (
                        <StockBlock product={product} filterByCompany={orderCompany} />
                      )}
                    </TableCell>
                    <TableCell>
                      <PriceBlock product={product} />
                    </TableCell>
                  </TableRow>
                ))}

                {productsList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <SimpleLinearProgress loading={loadingProductsList} />
                      {!loadingProductsList ? (
                        <Typography>Nenhum produto para exibir</Typography>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomPagination
                      page={page}
                      total={countProducts}
                      limit={limit}
                      handleChangePage={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      {openDialogDiscount ? (
        <DiscountDialog
          openDialogDiscount={openDialogDiscount}
          handleCloseDialogDiscount={handleCloseDialogDiscount}
          selectedProduct={selectedProductForDiscount}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : null}

      <Box position="relative" right="235px">
        <Box className={classes.searchTopRight}>
          <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar produto" />
        </Box>
      </Box>

      <Box position="relative" right={selectedProducts.length > 0 ? '105px' : '61px'}>
        <Box className={classes.fabRight}>
          <Fab
            variant="extended"
            color={selectedProducts.length > 0 ? 'primary' : 'default'}
            onClick={() => {
              if (selectedProducts.length > 0) {
                handleConfirmSelect({ selectedProducts });
              }
              onClose();
            }}
          >
            {selectedProducts.length > 0 ? 'Inserir itens' : 'Fechar'}
          </Fab>
        </Box>
      </Box>
    </Grid>
  );
}

export default memo(TableProductsModal);
