import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CustomDatePicker from '../../CustomDatePicker';
import UserCityAutocomplete from '../../Common/UserCityAutocomplete';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import UserAutocomplete from '../../Common/UserAutocomplete';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    width: 200,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBar({
  listBy,
  setListBy,
  filterOrderCompany,
  setFilterOrderCompany,
  selectedUser,
  setSelectedUser,
  selectedCity,
  setSelectedCity,
  dateBy,
  setDateBy,
  preferenceDate,
  setPreferenceDate,
  setSnackbar,
}) {
  const classes = useStyles();

  const handleOnChangeUser = (event, newValue) => {
    if (newValue) {
      setSelectedUser(newValue);
    } else {
      setSelectedUser(null);
    }
  };

  const handleOnChangeCity = (event, newValue) => {
    if (newValue) {
      setSelectedCity(newValue);
    } else {
      setSelectedCity(null);
    }
  };

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Vendas
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Box className={classes.selectBackground}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Listar por</InputLabel>
                  <Select onChange={(event) => setListBy(event.target.value)} value={listBy}>
                    <MenuItem value="Origem">Origem</MenuItem>
                    <MenuItem value="Vendedor">Vendedor</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <UserAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleOnChangeUser}
                  selectedUser={selectedUser}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <CompanySelectOptions
                setSnackbar={setSnackbar}
                filtersOptions={filterOrderCompany}
                setFiltersOptions={setFilterOrderCompany}
                width={200}
                multiple
              />
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <UserCityAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleOnChangeCity}
                  selectedCity={selectedCity}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Data de</InputLabel>
                  <Select onChange={(event) => setDateBy(event.target.value)} value={dateBy}>
                    <MenuItem value="createdAt">Criação</MenuItem>
                    <MenuItem value="payments.payDate">Recebimento</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <CustomDatePicker
                  value={preferenceDate}
                  onChange={(date) => setPreferenceDate(date)}
                  label="Data base"
                  format="DD/MM/YYYY"
                  size="small"
                  variant="filled"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default TopBar;
