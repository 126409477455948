import Axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { formatHandleError } from '../../../helpers/formatData';

function MercadoLivreTaxRulesMenu({
  setSnackbar = () => {},
  handleOnChange = () => {},
  companyId,
  selectedMercadoLivreTaxRule,
  label = 'Referência',
  variant,
}) {
  const [mercadoLivreTaxRulesList, setMercadoLivreTaxRulesList] = useState([]);
  const [loadingMercadoLivreTaxRulesList, setLoadingMercadoLivreTaxRulesList] = useState(true);

  const getMercadoLivreTaxRules = async () => {
    try {
      setLoadingMercadoLivreTaxRulesList(true);
      const response = await Axios.get(`/mercado-livre/tax-rules/${companyId}`);
      const { taxRules } = response.data;

      setMercadoLivreTaxRulesList(taxRules);
      setLoadingMercadoLivreTaxRulesList(false);
    } catch (error) {
      console.log(error);

      setLoadingMercadoLivreTaxRulesList(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar lista de regras de impostos do Mercado Livre',
        error,
      });
    }
  };

  useEffect(() => {
    getMercadoLivreTaxRules();
  }, []);

  return (
    <Box sx={{ width: 295 }}>
      {loadingMercadoLivreTaxRulesList ? (
        <Skeleton variant="rectangular" width={295} height={45} />
      ) : (
        <FormControl fullWidth variant={variant} size="small">
          <InputLabel>{label}</InputLabel>
          <Select
            name={companyId}
            value={selectedMercadoLivreTaxRule}
            onChange={handleOnChange}
            variant={variant}
          >
            <MenuItem value="Padrão">
              <Typography variant="inherit">
                Padrão - Usa a primeira regra de impostos criada
              </Typography>
            </MenuItem>
            {mercadoLivreTaxRulesList.map((mercadoLivreTaxRule) => (
              <MenuItem key={mercadoLivreTaxRule.id} value={mercadoLivreTaxRule.id}>
                <Typography variant="inherit">
                  {mercadoLivreTaxRule.id} - {mercadoLivreTaxRule.description}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

export default memo(MercadoLivreTaxRulesMenu);
