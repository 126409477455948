const newMadeiraMadeiraAd = {
  company: '',
  name: '',
  category: {
    categoryId: '',
    name: '',
  },
  price: 0,
  offerPrice: false,
  quantity: 1,
};

export default newMadeiraMadeiraAd;
