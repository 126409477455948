import { Grid, Paper, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { getFinancesResume } from '../../../services/melhorGestao/finances';
import { getOrdersResume } from '../../../services/melhorGestao/orders';
import { getPaymentsResume } from '../../../services/melhorGestao/payments';
import { getProductsResume } from '../../../services/melhorGestao/products';
import FilterSelectPapers from '../FilterSelectPapers';
import { getMercadoLivreResume } from '../../../services/melhorGestao/mercadoLivre';
import { getInvoicesResume } from '../../../services/melhorGestao/invoices';

const useStyles = makeStyles((theme) => ({
  situationPaper: {
    width: 145,
    height: 70,
    marginRight: 1,
    padding: 7,
    borderLeftColor: theme.palette.grey[400],
    borderLeftStyle: 'solid',
    borderLeftWidth: '0.5em',
    '&:hover': {
      borderLeftColor: '#ffb000',
    },
  },
}));

function SituationsFiltersPapers({
  refreshFiltersWithQuantity,
  situationsFilters,
  setSituationsFilters,
  handleClickFilter,
  products,
  orders,
  payments,
  finances,
  invoices,
  mercadoLivreAds,
  setSnackbar = () => {},
}) {
  const classes = useStyles();

  const [loadingSituationsResume, setLoadingSituationsResume] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      if (products) {
        getProductsResume({
          setLoadingProductsResume: setLoadingSituationsResume,
          setFiltersOptions: setSituationsFilters,
          filtersOptions: situationsFilters,
          setSnackbar,
        });
      }
      if (orders) {
        getOrdersResume({
          setLoadingOrdersResume: setLoadingSituationsResume,
          setFilterOrderStatus: setSituationsFilters,
          filterOrderStatus: situationsFilters,
          setSnackbar,
        });
      }
      if (payments) {
        getPaymentsResume({
          setLoadingPaymentsResume: setLoadingSituationsResume,
          setFiltersPaymentsStatus: setSituationsFilters,
          filtersPaymentsStatus: situationsFilters,
          setSnackbar,
        });
      }
      if (finances) {
        getFinancesResume({
          ...finances,
          setLoadingFinancesResume: setLoadingSituationsResume,
          setFiltersFinancesStatus: setSituationsFilters,
          filtersFinancesStatus: situationsFilters,
          setSnackbar,
        });
      }
      if (invoices) {
        getInvoicesResume({
          ...invoices,
          setLoadingInvoicesResume: setLoadingSituationsResume,
          setFilterInvoicesStatus: setSituationsFilters,
          filtersInvoicesStatus: situationsFilters,
          setSnackbar,
        });
      }
      if (mercadoLivreAds) {
        getMercadoLivreResume({
          ...mercadoLivreAds,
          setLoadingProductsResume: setLoadingSituationsResume,
          setFiltersOptions: setSituationsFilters,
          filtersOptions: situationsFilters,
          setSnackbar,
        });
      }
    };

    fetchData();
  }, [refreshFiltersWithQuantity]);

  return !loadingSituationsResume ? (
    <FilterSelectPapers
      filtersOptions={situationsFilters}
      setFiltersOptions={setSituationsFilters}
      handleClickFilter={handleClickFilter}
      finances={Boolean(finances) || Boolean(invoices)}
    />
  ) : (
    <Grid container justifyContent="flex-start" spacing={1}>
      {[1, 2, 3].map((n) => (
        <Grid item key={n}>
          <Paper elevation={1} className={classes.situationPaper}>
            <Skeleton animation="wave" width={40} height={30} />
            <Skeleton animation="wave" />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default SituationsFiltersPapers;
