import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import Axios from 'axios';
import { formatHandleError } from '../../../helpers/formatData';

function TagsAutocomplete({ type, handleOnChange = () => {}, selectedValue, setSnackbar }) {
  const [tagList, setTagList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAutoCompleteValues = async () => {
      try {
        setLoading(true);

        const route = type === 'users' ? '/users/tag-list' : '/catalog/product-tag-list';
        const response = await Axios.get(route);

        const tags = response.data;
        setTagList(tags);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as tags',
          error,
        });
      }
    };

    if (type) {
      getAutoCompleteValues();
    }
  }, [selectedValue, type]);

  return (
    <Autocomplete
      multiple
      options={tagList}
      freeSolo
      loading={loading}
      value={selectedValue}
      onChange={handleOnChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => <TextField {...params} label="Tags" placeholder="Adicionar tags" />}
    />
  );
}

export default TagsAutocomplete;
