import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import CategoryTree from '../DialogCategory/CategoryTree';

function CategoryProduct({
  openDialogCategory,
  handleCloseDialogCategory,
  setProductForm,
  productCategories,
}) {
  const [productCategoriesIndex, setProductCategoriesIndex] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const getAllCategories = async () => {
    try {
      setLoadingCategories(true);

      const response = await Axios.get('/catalog/categories');
      const { categories } = response.data;

      setCategoriesList(categories);

      if (productCategories) {
        const listIndex = [];
        productCategories.forEach((productCategory) => {
          categories.forEach((category, index) => {
            if (productCategory.slug === category.slug) listIndex.push(`${index}`);
          });
        });
        setProductCategoriesIndex(listIndex);
      }

      setLoadingCategories(false);
    } catch (error) {
      console.log(error);
      setLoadingCategories(false);
    }
  };

  const [refreshToken, setRefreshToken] = useState();

  useEffect(() => {
    getAllCategories();
  }, [refreshToken]);

  const handleSaveCategory = async () => {
    if (selectedCategory && selectedCategory._id) {
      handleCloseDialogCategory();

      const categories = [];

      const getParentSelectedCategory = categoriesList.find(
        (category) => category.slug === selectedCategory.parent,
      );

      if (getParentSelectedCategory) {
        const getParentCategory = categoriesList.find(
          (category) => category.slug === getParentSelectedCategory.parent,
        );

        if (getParentCategory) {
          categories.push({
            name: getParentCategory.name,
            slug: getParentCategory.slug,
            parent: getParentCategory.parent || '',
            ourCategory: true,
          });
        }

        categories.push({
          name: getParentSelectedCategory.name,
          slug: getParentSelectedCategory.slug,
          parent: getParentSelectedCategory.parent || '',
          ourCategory: true,
        });
      }

      categories.push({
        name: selectedCategory.name,
        slug: selectedCategory.slug,
        parent: selectedCategory.parent || '',
        ourCategory: true,
      });

      setProductForm((oldFields) => ({
        ...oldFields,
        categories,
      }));
    }
  };

  return (
    <SimpleDialog
      dialogTitle="Escolher categoria"
      content={
        <Grid container direction="column">
          {loadingCategories ? (
            <Grid item>
              <SimpleBackdrop loading={loadingCategories} />
            </Grid>
          ) : (
            <Grid item>
              <CategoryTree
                productCategoriesIndex={productCategoriesIndex}
                categoriesList={categoriesList}
                setRefreshToken={setRefreshToken}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </Grid>
          )}
        </Grid>
      }
      handleClose={handleCloseDialogCategory}
      openDialog={openDialogCategory}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      disableConfirmButton={!selectedCategory}
      handleConfirmButton={handleSaveCategory}
    />
  );
}

export default CategoryProduct;
