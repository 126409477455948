import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAuth } from '../../contexts/auth';

const useStyles = makeStyles({
  loginButtom: {
    width: 300,
    height: 48,
  },
});

function Login() {
  const classes = useStyles();
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');

  const { login } = useAuth();

  useEffect(() => {
    setShowLoginPanel(true);
  }, []);

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitLogin = async () => {
    if (email && password) {
      await login(email, password, setLoginMessage);
    } else {
      setLoginMessage('Digite seu e-mail e senha');
    }
  };

  const submitOnEnter = async (event) => {
    if (event.code === 'Enter') {
      submitLogin();
    }
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      bgcolor="#1C325F"
    >
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Zoom in={showLoginPanel} timeout={1000}>
              <Grid container justifyContent="center" alignItems="center">
                <Box marginBottom={1}>
                  <img
                    className={classes.logo}
                    alt="Logo Mais Hub"
                    src="/assets/imgs/logo-mais-hub.svg"
                    width="210px"
                  />
                </Box>
              </Grid>
            </Zoom>
          </Grid>

          <Grid item>
            <Zoom in={showLoginPanel} timeout={1000}>
              <Paper elevation={8}>
                <Box width="340px" padding={2}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h2" align="center">
                            Acesse sua conta
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box width="300px" onKeyPress={submitOnEnter}>
                            <TextField
                              fullWidth
                              label="Email"
                              variant="filled"
                              size="small"
                              value={email}
                              name="email"
                              onChange={handleChange}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width="300px" onKeyPress={submitOnEnter}>
                            <TextField
                              fullWidth
                              label="Senha"
                              variant="filled"
                              size="small"
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              name="password"
                              onChange={handleChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width="300px" padding={1} textAlign="center">
                            <Typography variant="caption">{loginMessage}</Typography>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.loginButtom}
                            onClick={submitLogin}
                          >
                            ENTRAR
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Zoom>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
