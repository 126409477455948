import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import SimpleBackdrop from '../../../../Common/SimpleBackdrop';
import { getUsers } from '../../../../../services/melhorGestao/users';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatDateWithHour,
} from '../../../../../helpers/formatData';

function InventoryMovements({ productId, openDialogInventory, handleCloseDialogInventory }) {
  const [inventoryMovements, setInventoryMovements] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInventoryMovements = async () => {
      try {
        setLoading(true);

        const response = await Axios.get(`/inventory/${productId}`);
        const { inventoryMovement } = response.data;

        const users = await getUsers(inventoryMovement.map((movement) => movement.userId));
        const findByUserId = (userId) => users.find((user) => user.userId === userId);

        setInventoryMovements(
          inventoryMovement.map((movement) => ({
            ...movement,
            user: findByUserId(movement.userId),
          }))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(
          error.response.data.message ||
            'Algum erro ocorreu ao buscar as movimentações do inventário'
        );
      }
    };
    getInventoryMovements();
  }, []);

  return (
    <SimpleDialog
      dialogTitle="Movimentações do Inventário"
      openDialog={openDialogInventory}
      handleClose={handleCloseDialogInventory}
      content={
        <Box width={600}>
          <SimpleBackdrop loading={loading} />
          <Box position="relative" marginBottom={5}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Colaborador</TableCell>
                      <TableCell align="center">Estoque Anterior</TableCell>
                      <TableCell align="center">Novo Estoque</TableCell>
                      <TableCell align="center">Data da Modificação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inventoryMovements.map((movement) => (
                      <TableRow key={movement._id}>
                        <TableCell align="center">
                          {movement.userId} -{' '}
                          {capitalizeString(extractFirstAndLastName(movement.user.name))}
                        </TableCell>
                        <TableCell align="center">{movement.lastStockQuantity}</TableCell>
                        <TableCell align="center">{movement.newStockQuantity}</TableCell>
                        <TableCell align="center">{formatDateWithHour(movement.date)}</TableCell>
                      </TableRow>
                    ))}
                    {inventoryMovements.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <Typography>
                            {loading ? 'Carregando...' : 'Não há movimentação neste produto'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
      }
    />
  );
}

export default InventoryMovements;
