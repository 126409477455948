import React, { memo, useEffect } from 'react';
import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchField from '../Common/SearchField';
import FilterSelectOptions from '../Common/FilterSelectOptions';
import CompanySelectOptions from '../Common/CompanySelectOptions';

const useStyles = makeStyles((theme) => ({
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

function TopBarOrders({
  submitSearchOrders,
  filterOrderStatus,
  filterOrderSource,
  filterOrderCompany,
  setFilterOrderStatus,
  setFilterOrderSource,
  setFilterOrderCompany,
  setPage,
}) {
  const classes = useStyles();

  useEffect(() => {
    setPage(1);
  }, [filterOrderStatus, filterOrderSource, filterOrderCompany]);

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={1}>
          <Typography variant="h5" className={classes.contrastText}>
            Pedidos
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <CompanySelectOptions
                inputLabel="Empresa"
                filtersOptions={filterOrderCompany}
                setFiltersOptions={setFilterOrderCompany}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Origem do pedido"
                filtersOptions={filterOrderSource}
                setFiltersOptions={setFilterOrderSource}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Situação do pedido"
                filtersOptions={filterOrderStatus}
                setFiltersOptions={setFilterOrderStatus}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearchOrders} labelSearch="Pesquisar pedido" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarOrders);
