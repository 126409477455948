import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginTop: 2,
    justifyContent: 'flex-end',
  },
});

function Footer({ pageNumber, pageQuantity }) {
  return (
    <View style={styles.row}>
      <Text>
        Página: {pageNumber}/{pageQuantity}
      </Text>
    </View>
  );
}

export default Footer;
