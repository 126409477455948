import React from 'react';
import { Grid, Toolbar, Typography, Paper, MenuItem, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  menuIcon: {
    width: '1.5em',
    height: '1.5em',
    color: '#1C325F',
    margin: 5,
  },
  menuIconActive: {
    width: '1.5em',
    height: '1.5em',
    color: '#ffb000',
    margin: 5,
  },
}));

function TopBarReports({ menuItems, selectedMenuItem, setSelectedMenuItem }) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Relatórios
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Paper>
            <Grid container>
              {menuItems.map((menuItem) => (
                <Grid key={menuItem.name} item>
                  <MenuItem onClick={() => setSelectedMenuItem(menuItem.name)}>
                    <SvgIcon
                      component={menuItem.icon}
                      className={
                        selectedMenuItem === menuItem.name
                          ? classes.menuIconActive
                          : classes.menuIcon
                      }
                    />
                    {menuItem.name}
                  </MenuItem>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default TopBarReports;
