import React, { memo, useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import dayjs from 'dayjs';
import SimpleDialog from '../../Common/SimpleDialog';
import { getUser } from '../../../services/melhorGestao/users';
import paymentDescriptionList from '../../../constant/paymentDescriptionList';
import { getOrder } from '../../../services/melhorGestao/orders';
import {
  formatReal,
  numberToReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../helpers/formatData';
import PaymentMethods from '../PaymentMethods';
import CustomInput from '../../CustomInput';
import CategoryPaymentAutocomplete from '../../Common/CategoryPaymentAutocomplete';
import UserAutocomplete from '../../Common/UserAutocomplete';
import WalletAutocomplete from '../../Common/WalletAutocomplete';
import CustomDatePicker from '../../CustomDatePicker';
import getUserCreditLimit from '../../../helpers/getUserCreditLimit';

const useStyles = makeStyles({
  input: {
    fontSize: 25,
  },
  swithSmallLabel: {
    lineHeight: 1,
  },
  inputAdornment: {
    marginBottom: 0,
  },
});

function PaymentDialog({
  openDialog,
  handleClose,
  payment,
  refreshWallet = () => {},
  blockEdit,
  blockType,
  blockPayer,
  blockCategory,
  blockPayee,
  blockPrice,
  orderForm,
  handleSaveForm,
  setLoading,
}) {
  const classes = useStyles();

  const [type, setType] = useState(payment.type);
  const [paymentValue, setPaymentValue] = useState(payment.value.toFixed(2));
  const [additionalValue, setAdditionalValue] = useState(payment.additionalValue.toFixed(2));
  const [discountValue, setDiscountValue] = useState(payment.discountValue.toFixed(2));
  const [finalValue, setFinalValue] = useState(payment.finalValue.toFixed(2));
  const [category, setCategory] = useState(payment.category);
  const [dueDate, setDueDate] = useState(payment.dueDate);
  const [payDate, setPayDate] = useState(payment.payDate);
  const [payed, setPayed] = useState(payment.payed);
  const [valuePaid, setValuePaid] = useState((payment.value - payment.valuePaid).toFixed(2));
  const [paymentMethod, setPaymentMethod] = useState(payment.paymentMethod);
  const [description, setDescription] = useState(payment.description);
  const [selectedPayee, setSelectedPayee] = useState(payment.payee);
  const [selectedPayer, setSelectedPayer] = useState(payment.payer);
  const [selectedWallet, setSelectedWallet] = useState(payment.wallet);
  const [selectedCondition, setSelectedCondition] = useState(payment.condition);
  const [loadingSavePayment, setLoadingSavePayment] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handlePayeeOnChange = (event, newValue) => {
    setSelectedPayee(newValue && newValue.userId ? newValue : payment.payee);
  };

  const handlePayerOnChange = (event, newValue) => {
    setSelectedPayer(newValue && newValue.userId ? newValue : payment.payer);
  };

  const handleCategoryOnChange = (event, newValue) => {
    if (newValue && newValue.categoryId) {
      setCategory(newValue);
    } else {
      setCategory(null);
    }
  };

  const handleWalletOnChange = (event, newValue) => {
    if (newValue && newValue.walletId) {
      setSelectedWallet(newValue);
    } else {
      setSelectedWallet(null);
    }
  };

  const handleConditionOnChange = (event, newValue) => {
    setSelectedCondition(newValue || 'A VISTA');
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleSetReceiving = () => {
    if (type === 'Pagamento') {
      setType('Recebimento');
      setCategory(payment.category);
      setSelectedPayee(payment.payee);
      setSelectedPayer(payment.payer);
    }
  };
  const handleSetSending = () => {
    if (type === 'Recebimento') {
      setType('Pagamento');
      setCategory(payment.category);
      setSelectedPayee(payment.payee);
      setSelectedPayer(payment.payer);
      setSelectedCondition('A VISTA');
    }
  };

  const handleChangeValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setPaymentValue(formatReal(value));
  };
  const handleChangeAdditionalValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setAdditionalValue(formatReal(value));
  };
  const handleChangeDiscountValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setDiscountValue(formatReal(value));
  };
  const handleChangeValuePaid = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setValuePaid(formatReal(value));
  };
  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    setFinalValue(paymentValue);
  }, [paymentValue]);

  const handleSave = async () => {
    try {
      if (
        (type && !blockEdit) ||
        !payment.paymentMethod ||
        payment.paymentMethod === 'Aguardando financeiro'
      ) {
        setLoading(true);
        setLoadingSavePayment(true);

        let checkoutLink = {};

        if (paymentMethod === 'Link de pagamento' && type === 'Recebimento') {
          if (!payment.orderId) {
            return setSnackbar({
              message: 'Não é possível criar pagamento por link sem um pedido',
              open: true,
              type: 'error',
            });
          }

          const gatewayPaymentId = 'Mercado Pago Checkout Pro';

          const response = await Axios.post(`/orders/payment/${payment.orderId}`, {
            gatewayPaymentId,
          });

          if (response.data.success) {
            checkoutLink = {
              checkoutLink: response.data.checkoutLink,
            };
          }
        } else {
          let payer;
          let payee;

          if (type === 'Recebimento') {
            const user = await getUser(selectedPayer.userId);
            payer = user._id;
            payee = '6086cab8cecf3321c48e762a';
          }
          if (type === 'Pagamento') {
            const user = await getUser(selectedPayee.userId);
            payer = '6086cab8cecf3321c48e762a';
            payee = user._id;
          }

          if (!payment.paymentId) {
            await Axios.post('/payments', {
              description,
              type,
              category: category._id,
              dueDate,
              payDate,
              payer,
              payee,
              wallet: selectedWallet._id,
              condition: selectedCondition,
              deductionDescription: 'Taxas de intermediação',
              paymentMethod: payed ? paymentMethod : 'Aguardando financeiro',
              payed,
              orderId: payment.orderId ? payment.orderId : null,
              value: {
                base: finalValue,
                interest: 0,
                discount: discountValue,
                total: finalValue,
                payed: payed ? finalValue : 0,
                deduction: additionalValue,
                received: finalValue,
              },
            });
          }
        }

        if (orderForm) {
          const updatedOrder = await getOrder(orderForm.orderId);

          await handleSaveForm({
            updateFields: {
              ...updatedOrder,
              payments: [
                ...updatedOrder.payments,
                {
                  type,
                  method: paymentMethod,
                  value: finalValue,
                  valuePaid: payed ? finalValue * 100 : 0,
                  financialCost: 0,
                  status: payed ? 'Pago' : 'Não pago',
                  ...checkoutLink,
                },
              ],
            },
            dontCloseDialog: true,
            customSaveMessage: 'Pagamento adicionado, pedido salvo',
          });
        }

        setLoading(false);
        setLoadingSavePayment(false);

        handleClose();
        refreshWallet();
        return true;
      }
      return false;
    } catch (error) {
      setLoading(false);
      setLoadingSavePayment(false);

      return setSnackbar({
        message: error?.response?.data?.message
          ? error.response.data.message
          : 'Algum erro ocorreu ao salvar o lançamento',
        open: true,
        type: 'error',
      });
    }
  };

  const handleChangeDueDate = (date) => {
    setDueDate(date);
  };
  const handleChangePayDate = (date) => {
    setPayDate(date);
  };

  const handleSetPayed = () => {
    if (paymentMethod !== 'Link de pagamento') {
      setPayDate(new Date());
      setPayed(!payed);
    }
  };

  const [generateLink, setGenerateLink] = useState(false);

  const handleGenerateLink = () => {
    setGenerateLink(!generateLink);

    if (generateLink) {
      setPaymentMethod('Aguardando financeiro');
    } else {
      setPaymentMethod('Link de pagamento');
    }
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (blockEdit && paymentMethod !== 'Aguardando financeiro' && payment.paymentMethod) {
        setErrorMessage('');
        return setDisableConfirmButton(true);
      }

      if (!category?.name) {
        setErrorMessage('Escolha uma categoria');
        return setDisableConfirmButton(true);
      }

      if (type === 'Pagamento' && (!selectedPayee || !selectedPayee.name)) {
        setErrorMessage('Escolha o recebedor');
        return setDisableConfirmButton(true);
      }

      if (!selectedWallet || !selectedWallet._id || !selectedWallet.name) {
        setErrorMessage('A carteira não está selecionada');
        return setDisableConfirmButton(true);
      }

      if (generateLink) {
        if (!/mercado pago/gi.test(selectedWallet.name)) {
          setErrorMessage('A carteira selecionada não gera link, selecione outra');
          return setDisableConfirmButton(true);
        }
      }

      if (payed) {
        if (!paymentMethod || paymentMethod === 'Aguardando financeiro') {
          setErrorMessage('Escolha uma forma de pagamento');
          return setDisableConfirmButton(true);
        }
      }

      if (parseFloat(paymentValue) <= 0 || Number.isNaN(parseFloat(paymentValue))) {
        setErrorMessage('Valor tem que ser maior que 0');
        return setDisableConfirmButton(true);
      }

      if (
        parseFloat(additionalValue) < 0 ||
        (Number.isNaN(parseFloat(additionalValue)) && additionalValue !== '')
      ) {
        setErrorMessage('Valor adicional inválido');
        return setDisableConfirmButton(true);
      }

      if (
        parseFloat(discountValue) < 0 ||
        (Number.isNaN(parseFloat(discountValue)) && discountValue !== '')
      ) {
        setErrorMessage('Valor de desconto inválido');
        return setDisableConfirmButton(true);
      }

      if (parseFloat(finalValue) <= 0 || Number.isNaN(parseFloat(finalValue))) {
        setErrorMessage('Valor final tem que ser maior que 0');
        return setDisableConfirmButton(true);
      }

      if (!dayjs(dueDate).isValid()) {
        setErrorMessage('A data de vencimento escolhida é inválida');
        return setDisableConfirmButton(true);
      }

      if (payed && !dayjs(payDate).isValid()) {
        setErrorMessage('A data de pagamento escolhida é inválida');
        return setDisableConfirmButton(true);
      }

      if (type === 'Recebimento' && (!selectedPayer || !selectedPayer.name)) {
        setErrorMessage('Escolha o pagador');
        return setDisableConfirmButton(true);
      }

      if (paymentMethod === 'Crédito') {
        if (type === 'Recebimento') {
          const user = await getUser(selectedPayer.userId);

          if (user && user.credit) {
            const creditLimit = getUserCreditLimit(user);

            if (finalValue > creditLimit) {
              setErrorMessage(`Crédito disponível: R$ ${numberToReal(creditLimit)}`);
              if (creditLimit > 0 && !payed) {
                return setDisableConfirmButton(false);
              }
              return setDisableConfirmButton(true);
            }
          }
          if (user && !user.credit) {
            setErrorMessage('Crédito insuficiente');
            return setDisableConfirmButton(payed);
          }
        }
      }

      setErrorMessage('');
      return setDisableConfirmButton(false);
    };
    fetchData();
  }, [
    additionalValue,
    discountValue,
    finalValue,
    paymentValue,
    dueDate,
    payDate,
    payed,
    category,
    selectedPayer,
    selectedPayee,
    type,
    selectedWallet,
    blockEdit,
    paymentMethod,
  ]);

  const [openConfirmSetToPayed, setOpenConfirmSetToPayed] = useState(false);
  const handleOpenConfirmSetToPayed = () => {
    setOpenConfirmSetToPayed(true);
  };
  const handleCloseConfirmSetToPayed = () => {
    setOpenConfirmSetToPayed(false);
  };

  const handleSetToPayedAndSave = async () => {
    try {
      if (payment.paymentId) {
        handleCloseConfirmSetToPayed(false);
        setLoading(true);

        if (!payment.paymentMethod || payment.paymentMethod === 'Aguardando financeiro') {
          await Axios.put(`/payments/${payment.paymentId}`, {
            description,
            paymentMethod,
          });
        }

        await Axios.post('/payments/payed', {
          paymentId: payment.paymentId,
          valuePaid,
        });

        if (payment.valuePaid + parseFloat(valuePaid) >= parseFloat(finalValue)) {
          setPayed(true);
        }

        setLoading(false);
        handleClose();
        return refreshWallet();
      }

      return setSnackbar({
        message: 'Não encontramos o pagamento que deseja marcar como pago. Informe o suporte.',
        open: true,
        type: 'error',
      });
    } catch (error) {
      setLoading(false);
      return setSnackbar({
        message: error?.response?.data?.message
          ? error.response.data.message
          : 'Algum erro ocorreu ao marcar como pago',
        open: true,
        type: 'error',
      });
    }
  };

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleClose}
      dialogTitle={blockEdit || blockType ? payment.type : 'Novo lançamento'}
      maxWidth="xs"
      content={
        <Grid container justifyContent="space-between" direction="column">
          <Grid item>
            <Grid container direction="column" justifyContent="center" spacing={2}>
              {snackbar.open && (
                <Snackbar
                  open={snackbar.open}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                    {snackbar.message}
                  </Alert>
                </Snackbar>
              )}
              <Grid item>
                {!blockEdit && !blockType ? (
                  <ButtonGroup variant="contained" styles={{ marginTop: 14 }}>
                    <Button
                      onClick={handleSetReceiving}
                      disabled={blockEdit || (blockType && type === 'Pagamento')}
                      color={type === 'Recebimento' ? 'primary' : 'default'}
                    >
                      Recebimento
                    </Button>

                    <Button
                      onClick={handleSetSending}
                      disabled={blockEdit || (blockType && type === 'Recebimento')}
                      color={type === 'Pagamento' ? 'primary' : 'default'}
                    >
                      Pagamento
                    </Button>
                  </ButtonGroup>
                ) : null}
              </Grid>

              <Grid item>
                <CategoryPaymentAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleCategoryOnChange}
                  type={type}
                  selectedCategory={category?._id ? category : null}
                  disabled={blockEdit || blockCategory}
                  error={!category?._id}
                  variant="outlined"
                />
              </Grid>

              {type === 'Pagamento' ? (
                <Grid item>
                  <UserAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={handlePayeeOnChange}
                    selectedUser={selectedPayee?._id ? selectedPayee : null}
                    label="Recebedor"
                    disabled={blockEdit || blockPayee}
                    error={!selectedPayee?._id}
                    variant="outlined"
                  />
                </Grid>
              ) : null}

              {type === 'Recebimento' ? (
                <Grid item>
                  <UserAutocomplete
                    setSnackbar={setSnackbar}
                    handleOnChange={handlePayerOnChange}
                    selectedUser={selectedPayer?._id ? selectedPayer : null}
                    label="Pagador"
                    disabled={blockEdit || blockPayer}
                    error={!selectedPayer?._id}
                    variant="outlined"
                  />
                </Grid>
              ) : null}

              <Grid item>
                <WalletAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleWalletOnChange}
                  selectedWallet={selectedWallet?._id ? selectedWallet : null}
                  disabled={blockEdit}
                  error={!selectedWallet?._id}
                  variant="outlined"
                />
              </Grid>

              <Grid item>
                <TextField
                  size="small"
                  value={description}
                  label="Observações"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={2}
                  disabled={blockEdit && payment.payed}
                  onChange={handleChangeDescription}
                />
              </Grid>

              <Grid item>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Valor"
                      value={paymentValue ? numberToRealWithPeriod(paymentValue) : '0,00'}
                      onChange={handleChangeValue}
                      error={
                        parseFloat(paymentValue) <= 0 || Number.isNaN(parseFloat(paymentValue))
                      }
                      disabled={blockEdit || blockPrice}
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Valor adicional"
                      value={additionalValue ? numberToRealWithPeriod(additionalValue) : '0,00'}
                      onChange={handleChangeAdditionalValue}
                      error={
                        parseFloat(additionalValue) < 0 ||
                        (Number.isNaN(parseFloat(additionalValue)) && additionalValue !== '')
                      }
                      disabled
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Desconto"
                      value={discountValue ? numberToRealWithPeriod(discountValue) : '0,00'}
                      onChange={handleChangeDiscountValue}
                      error={
                        parseFloat(discountValue) < 0 ||
                        (Number.isNaN(parseFloat(discountValue)) && discountValue !== '')
                      }
                      disabled
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Autocomplete
                  size="small"
                  options={paymentDescriptionList.map(
                    (paymentDescription) => paymentDescription.string,
                  )}
                  disabled={blockEdit}
                  getOptionLabel={(option) => option}
                  value={selectedCondition}
                  onChange={handleConditionOnChange}
                  noOptionsText="Opção não encontrada"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Condição"
                      variant="outlined"
                      error={!selectedCondition}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={4}>
                    <CustomDatePicker
                      size="small"
                      margin="normal"
                      label="Vencimento"
                      format="DD/MM"
                      value={dueDate}
                      disabled={blockEdit}
                      onChange={handleChangeDueDate}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Box position="relative" height="65px">
                      <Box position="absolute" top="20px" left="-9px">
                        <Box>
                          <FormControlLabel
                            disabled={blockEdit}
                            control={
                              <Switch
                                value={payed}
                                onChange={handleSetPayed}
                                checked={payed}
                                color="primary"
                              />
                            }
                            label={
                              <Box className={classes.swithSmallLabel}>
                                {payed ? 'Pago' : 'Não pago'}
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    {payed ? (
                      <CustomDatePicker
                        disabled={blockEdit}
                        size="small"
                        margin="normal"
                        label="Pagamento"
                        format="DD/MM"
                        value={payDate}
                        onChange={handleChangePayDate}
                      />
                    ) : (
                      <Box position="relative" height="65px">
                        <Box position="absolute" top="20px" left="-9px">
                          <Box>
                            <FormControlLabel
                              disabled={blockEdit}
                              control={
                                <Switch
                                  value={generateLink}
                                  onChange={handleGenerateLink}
                                  checked={generateLink}
                                  name="composition"
                                  color="primary"
                                />
                              }
                              label={
                                <Box className={classes.swithSmallLabel}>
                                  {generateLink ? 'Gerar link' : 'Não gerar link'}
                                </Box>
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {payed ? (
                <PaymentMethods
                  blockEdit={blockEdit}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              ) : null}

              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  {openConfirmSetToPayed ? (
                    <SimpleDialog
                      openDialog={openConfirmSetToPayed}
                      handleClose={handleCloseConfirmSetToPayed}
                      dialogTitle="Deseja marcar esta conta como paga?"
                      dialogText="Esta ação não pode ser desfeita"
                      cancelButtonText="Cancelar"
                      confirmButtonText="Marcar como pago"
                      handleCancelButton={handleCloseConfirmSetToPayed}
                      handleConfirmButton={handleSetToPayedAndSave}
                      disableConfirmButton={
                        parseFloat(valuePaid) <= 0 ||
                        Number.isNaN(parseFloat(valuePaid)) ||
                        !paymentMethod ||
                        paymentMethod === 'Aguardando financeiro'
                      }
                      tooltipConfirmButton={
                        parseFloat(valuePaid) <= 0 ||
                        Number.isNaN(parseFloat(valuePaid)) ||
                        !paymentMethod ||
                        paymentMethod === 'Aguardando financeiro'
                          ? 'Confira o valor e selecione a forma de pagamento que foi usada'
                          : 'Confirmar pagamento do valor digitado'
                      }
                      content={
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <CustomInput
                              label="Valor pago"
                              value={valuePaid ? numberToRealWithPeriod(valuePaid) : '0,00'}
                              onChange={handleChangeValuePaid}
                              error={
                                parseFloat(valuePaid) <= 0 || Number.isNaN(parseFloat(valuePaid))
                              }
                              InputStartAdornment="R$"
                              InputAdornmentStyle={classes.inputAdornment}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item>
                            <PaymentMethods
                              paymentMethod={paymentMethod}
                              setPaymentMethod={setPaymentMethod}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  ) : null}
                  <Grid item xs={6}>
                    {blockEdit && !payed && !payment.payed ? (
                      <Button
                        onClick={handleOpenConfirmSetToPayed}
                        variant="contained"
                        color="primary"
                      >
                        Marcar como pago
                      </Button>
                    ) : null}
                    <Grid item>
                      {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <CustomInput
                      label="Valor final"
                      value={finalValue ? numberToRealWithPeriod(finalValue) : '0,00'}
                      disabled
                      error={parseFloat(finalValue) <= 0 || Number.isNaN(parseFloat(finalValue))}
                      InputStartAdornment="R$"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Salvar"
      confirmButtonLoading={loadingSavePayment}
      handleConfirmButton={handleSave}
      disableConfirmButton={disableConfirmButton}
      handleCancelButton={handleClose}
    />
  );
}

export default memo(PaymentDialog);
