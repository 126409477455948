import Axios from 'axios';
import { Alert, Box, Snackbar } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TableChartIcon from '@mui/icons-material/TableChart';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import TopBarInvoice from './TopBar';
import { searchInvoices } from '../../services/melhorGestao/invoices';
import TableInvoices from './Table';
import SimpleLinearProgress from '../Common/SimpleLinearProgress';
import FabGroupButtons from '../Common/FabGroupButtons';
import ImportXmlDialog from './ImportXmlDialog';
import ReportInvoiceDialog from './ReportInvoiceDialog';
import SituationsFiltersPapers from '../Common/SituationsFiltersPapers';
import { formatHandleError } from '../../helpers/formatData';

const invoicesModels = [
  {
    id: '55',
    name: 'NF-e',
    active: false,
  },
  {
    id: '65',
    name: 'NFC-e',
    active: false,
  },
];

const invoicesSeries = [
  {
    id: '1',
    name: '1',
    active: false,
  },
  {
    id: '2',
    name: '2',
    active: false,
  },
];

const invoicesOperations = [
  {
    id: '0',
    name: 'Entrada',
    active: false,
  },
  {
    id: '1',
    name: 'Saída',
    active: false,
  },
];

function InvoiceFinder() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [page, setPage] = useState(1);
  const [limit] = useState(40);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [invoicesList, setInvoicesList] = useState([]);
  const [loadingInvoicesList, setLoadingInvoicesList] = useState(false);
  const [countInvoices, setCountInvoices] = useState(0);
  const [filterModelOptions, setFilterModelOptions] = useState(invoicesModels);
  const [filterSerieOptions, setFilterSerieOptions] = useState(invoicesSeries);
  const [filterOperationOptions, setFilterOperationOptions] = useState(invoicesOperations);
  const [filterCompanyOptions, setFilterCompanyOptions] = useState([]);
  const [filterUserOptions, setFilterUserOptions] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [situationsFilters, setSituationsFilters] = useState([
    {
      id: 'totalValue',
      name: 'Total das Notas',
      description: 'Total das Notas',
      totalResults: 0,
      active: false,
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/invoices/field-distinct', {
          params: {
            field: 'nfeProc.NFe.infNFe.ide.serie',
          },
        });

        setFilterSerieOptions(
          response.data.map((serie) => ({
            id: serie,
            name: serie,
            active: false,
          })),
        );
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar series',
          error,
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    searchInvoices({
      limit,
      skip: page * limit - limit,
      filterModelOptions,
      filterSerieOptions,
      filterOperationOptions,
      filterCompanyOptions,
      filterUserOptions,
      searchText: searchTextSubmited,
      sortBy: 'nfeProc.NFe.infNFe.ide.dhEmi',
      sortDirection: 'desc',
      startDate,
      endDate,
      setInvoicesList,
      setLoadingInvoicesList,
      setCountInvoices,
      setSnackbar,
    });
  }, [
    filterModelOptions,
    filterSerieOptions,
    filterOperationOptions,
    filterCompanyOptions,
    filterUserOptions,
    searchTextSubmited,
    page,
    limit,
    startDate,
    endDate,
  ]);

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const [openReportInvoiceDialog, setOpenReportInvoiceDialog] = useState(false);
  const handleOpenReportInvoiceDialog = () => setOpenReportInvoiceDialog(true);
  const handleCloseReportInvoiceDialog = () => setOpenReportInvoiceDialog(false);

  const [openImportXmlDialog, setOpenImportXmlDialog] = useState(false);
  const handleOpenImportXmlDialog = () => setOpenImportXmlDialog(true);
  const handleCloseImportXmlDialog = () => setOpenImportXmlDialog(false);

  return (
    <Box position="relative">
      <SimpleLinearProgress loading={loadingInvoicesList} fixedPosition />
      <Box marginTop={2}>
        <TopBarInvoice
          submitSearchInvoices={setSearchTextSubmited}
          filterModelOptions={filterModelOptions}
          setFilterModelOptions={setFilterModelOptions}
          filterSerieOptions={filterSerieOptions}
          setFilterSerieOptions={setFilterSerieOptions}
          filterOperationOptions={filterOperationOptions}
          setFilterOperationOptions={setFilterOperationOptions}
          filterCompanyOptions={filterCompanyOptions}
          setFilterCompanyOptions={setFilterCompanyOptions}
          filterUserOptions={filterUserOptions}
          setFilterUserOptions={setFilterUserOptions}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Box>
      <Box marginTop={2}>
        <SituationsFiltersPapers
          refreshFiltersWithQuantity={`${filterModelOptions
            .filter((option) => option.active)
            .map((option) => option.id)
            .join(' ')} ${filterSerieOptions
            .filter((option) => option.active)
            .map((option) => option.id)
            .join(' ')} ${filterOperationOptions
            .filter((option) => option.active)
            .map((option) => option.id)
            .join(' ')} ${filterCompanyOptions
            .filter((option) => option.active)
            .map((option) => option.document)
            .join(' ')} ${filterUserOptions
            .filter((option) => option.active)
            .map((option) => option.document)
            .join(' ')} ${searchTextSubmited} ${startDate} ${endDate}`}
          situationsFilters={situationsFilters}
          setSituationsFilters={setSituationsFilters}
          handleClickFilter={() => {
            setPage(1);
          }}
          invoices={{
            filterModelOptions,
            filterSerieOptions,
            filterOperationOptions,
            filterCompanyOptions,
            filterUserOptions,
            searchText: searchTextSubmited,
            startDate,
            endDate,
          }}
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <TableInvoices
          invoicesList={invoicesList}
          countInvoices={countInvoices}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          loadingInvoicesList={loadingInvoicesList}
          setSnackbar={setSnackbar}
        />
      </Box>
      <FabGroupButtons
        fabButtons={[
          {
            title: 'Relatórios',
            onClick: handleOpenReportInvoiceDialog,
            icon: <TableChartIcon />,
          },
          {
            title: 'Importar XMLs',
            onClick: handleOpenImportXmlDialog,
            icon: <UploadFileIcon />,
          },
        ]}
      />
      {openReportInvoiceDialog ? (
        <ReportInvoiceDialog
          openReportInvoiceDialog={openReportInvoiceDialog}
          handleCloseReportInvoiceDialog={handleCloseReportInvoiceDialog}
          filterModelOptions={filterModelOptions}
          filterSerieOptions={filterSerieOptions}
          filterOperationOptions={filterOperationOptions}
          filterCompanyOptions={filterCompanyOptions}
          filterUserOptions={filterUserOptions}
          defaultStartDate={startDate}
          defaultEndDate={endDate}
        />
      ) : null}
      {openImportXmlDialog ? (
        <ImportXmlDialog
          openImportXmlDialog={openImportXmlDialog}
          handleCloseImportXmlDialog={handleCloseImportXmlDialog}
          setSnackbar={setSnackbar}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default InvoiceFinder;
