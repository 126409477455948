const newCompany = {
  active: true,
  name: '',
  cnpj: '',
  nomeFantasia: '',
  razaoSocial: '',
  type: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    state: '',
    district: '',
    complement: '',
    postalCode: '',
  },
};

export default newCompany;
