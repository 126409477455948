import React, { useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import { TreeView } from '@mui/x-tree-view';
import CategoryTreeItem from './CategoryTreeItem';
import DialogCreateCategory from './DialogCreateCategory';
import simulateMongoObjectId from '../../../../../../helpers/simulateMongoObjectId';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

function CategoryTree({
  productCategoriesIndex,
  categoriesList,
  setRefreshToken,
  selectedCategory,
  setSelectedCategory,
  setSearchTextViaVarejo,
}) {
  const classes = useStyles();

  const [openDialogCreateCategory, setOpenDialogCreateCategory] = useState(false);
  const handleOpenDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(true), []);
  const handleCloseDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(false), []);

  const handleChangeSelectedCategory = (categoryInside) => {
    setSelectedCategory(categoryInside);

    if (setSearchTextViaVarejo) {
      setSearchTextViaVarejo(categoryInside.name);
    }
  };

  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={productCategoriesIndex || []}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <CategoryTreeItem
          nodeId={simulateMongoObjectId()}
          labelText="Incluir categoria"
          icon={<AddIcon />}
          onClick={() => {
            setSelectedCategory({});
            handleOpenDialogCreateCategory();
          }}
        />
        {categoriesList.map((category) => {
          if (!category.parent) {
            return (
              <CategoryTreeItem
                key={category._id}
                nodeId={category._id}
                labelText={category.name}
                onClick={() => handleChangeSelectedCategory(category)}
              >
                <CategoryTreeItem
                  nodeId={simulateMongoObjectId()}
                  labelText="Incluir sub-categoria"
                  icon={<AddIcon />}
                  onClick={() => {
                    setSelectedCategory(
                      categoriesList.find((categoryFind) => categoryFind.slug === category.slug),
                    );
                    handleOpenDialogCreateCategory();
                  }}
                />
                {categoriesList
                  .filter((categoryInside) => categoryInside.parent === category.slug)
                  .map((categoryInside) => (
                    <CategoryTreeItem
                      key={categoryInside._id}
                      nodeId={categoryInside._id}
                      labelText={categoryInside.name}
                      viaVarejoMapped={
                        categoryInside.viaVarejoCategory && categoryInside.viaVarejoCategory.id
                          ? categoryInside.viaVarejoCategory.id
                          : null
                      }
                      americanasMapped
                      onClick={() => handleChangeSelectedCategory(categoryInside)}
                    >
                      <CategoryTreeItem
                        nodeId={simulateMongoObjectId()}
                        labelText="Incluir sub-categoria"
                        labelIcon={AddIcon}
                        onClick={() => {
                          setSelectedCategory(
                            categoriesList.find(
                              (categoryFind) => categoryFind.slug === categoryInside.slug,
                            ),
                          );
                          handleOpenDialogCreateCategory();
                        }}
                      />
                      {categoriesList
                        .filter(
                          (categoryInsideLast) => categoryInsideLast.parent === categoryInside.slug,
                        )
                        .map((categoryInsideLast) => (
                          <CategoryTreeItem
                            key={categoryInsideLast._id}
                            nodeId={categoryInsideLast._id}
                            labelText={categoryInsideLast.name}
                            viaVarejoMapped={
                              categoryInsideLast.viaVarejoCategory &&
                              categoryInsideLast.viaVarejoCategory.id
                                ? categoryInsideLast.viaVarejoCategory.id
                                : null
                            }
                            americanasMapped
                            onClick={() => handleChangeSelectedCategory(categoryInsideLast)}
                          />
                        ))}
                    </CategoryTreeItem>
                  ))}
              </CategoryTreeItem>
            );
          }
          return null;
        })}
      </TreeView>

      {openDialogCreateCategory && (
        <DialogCreateCategory
          openDialogCreateCategory={openDialogCreateCategory}
          handleCloseDialogCreateCategory={handleCloseDialogCreateCategory}
          selectedCategory={selectedCategory}
          setRefreshToken={setRefreshToken}
        />
      )}
    </>
  );
}

export default CategoryTree;
