import React, { useCallback, useEffect, useState } from 'react';
import { TreeView } from '@mui/x-tree-view';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import PaymentCategoryTreeItem from './PaymentCategoryTreeItem';
import DialogPaymentCategory from './DialogPaymentCategory';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import { getFinancesResume } from '../../../../services/melhorGestao/finances';
import simulateMongoObjectId from '../../../../helpers/simulateMongoObjectId';
import newPaymentCategory from '../../../../constant/newPaymentCategory';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

function CategoryPaymentTree({
  paymentCategoriesIndex,
  type,
  categoriesList,
  setRefreshToken,
  selectedCategory,
  setSelectedCategory,
  disableCreateCategory,
  finance,
}) {
  const classes = useStyles();

  const [openDialogCreateCategory, setOpenDialogCreateCategory] = useState(false);
  const handleOpenDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(true), []);
  const handleCloseDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(false), []);

  const handleChangeSelectedCategory = (categoryInside) => {
    if (selectedCategory?._id === categoryInside._id) {
      setSelectedCategory(newPaymentCategory);
    } else {
      setSelectedCategory({ ...newPaymentCategory, ...categoryInside });
    }
  };

  const [categoriesWithValue, setCategoriesWithValue] = useState([]);

  useEffect(() => {
    if (categoriesList.length && finance) {
      getFinancesResume({
        setLoadingFinancesResume: () => {},
        setFiltersFinancesStatus: setCategoriesWithValue,
        filtersFinancesStatus: categoriesList
          .filter((category) => category.type === type)
          .map((category) => ({
            id: category._id,
            name: category.name,
            description: `Categoria de ${type.toLowerCase()}`,
            active: false,
          })),
        type,
        ...finance,
        setSnackbar: () => {},
      });
    }
  }, [categoriesList, finance]);

  const getCategoryValue = (id) => {
    if (finance) {
      const categoryWithValue = categoriesWithValue.find(
        (categoryValue) => categoryValue.id === id,
      );

      if (categoryWithValue) {
        return categoryWithValue.totalResults;
      }
    }
    return 0;
  };

  return (
    <>
      <TreeView
        className={classes.root}
        defaultExpanded={paymentCategoriesIndex || []}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {disableCreateCategory ? null : (
          <PaymentCategoryTreeItem
            nodeId={simulateMongoObjectId()}
            labelText="Incluir categoria"
            icon={<AddIcon />}
            onClick={() => {
              setSelectedCategory(newPaymentCategory);
              handleOpenDialogCreateCategory();
            }}
          />
        )}
        {categoriesList
          .filter((category) => !category.parentId)
          .map((category) => {
            let labelInfo = null;
            const categoryValue = getCategoryValue(category._id);

            if (categoryValue) {
              labelInfo = `R$ ${numberToRealWithPeriod(categoryValue)}`;
            }

            const categoriesListInside = categoriesList.filter(
              (categoryInside) => categoryInside.parentId === category.categoryId,
            );

            if (disableCreateCategory && !categoriesListInside.length) {
              return (
                <PaymentCategoryTreeItem
                  key={category._id}
                  nodeId={category._id}
                  labelText={category.name}
                  labelInfo={labelInfo}
                  labelIcon={EditOutlinedIcon}
                  onClickIcon={handleOpenDialogCreateCategory}
                  onClick={() => handleChangeSelectedCategory(category)}
                />
              );
            }

            return (
              <PaymentCategoryTreeItem
                key={category._id}
                nodeId={category._id}
                labelText={category.name}
                labelInfo={labelInfo}
                labelIcon={EditOutlinedIcon}
                onClickIcon={handleOpenDialogCreateCategory}
                onClick={() => handleChangeSelectedCategory(category)}
              >
                {disableCreateCategory ? null : (
                  <PaymentCategoryTreeItem
                    nodeId={simulateMongoObjectId()}
                    labelText="Incluir sub-categoria"
                    icon={<AddIcon />}
                    onClick={() => {
                      const findSelectedCategory = categoriesList.find(
                        (categoryFind) => categoryFind.categoryId === category.categoryId,
                      );

                      if (findSelectedCategory) {
                        setSelectedCategory({
                          ...newPaymentCategory,
                          ...findSelectedCategory,
                          name: '',
                          parentId: findSelectedCategory.categoryId,
                          parentName: findSelectedCategory.name,
                        });

                        handleOpenDialogCreateCategory();
                      }
                    }}
                  />
                )}
                {categoriesListInside.map((categoryInside) => {
                  let labelInfoInside = null;
                  const categoryValueInside = getCategoryValue(categoryInside._id);

                  if (categoryValueInside) {
                    labelInfoInside = `R$ ${numberToRealWithPeriod(categoryValueInside)}`;
                  }

                  const categoriesListInsideLast = categoriesList.filter(
                    (categoryInsideLast) =>
                      categoryInsideLast.parentId === categoryInside.categoryId,
                  );

                  if (disableCreateCategory && !categoriesListInsideLast.length) {
                    return (
                      <PaymentCategoryTreeItem
                        key={categoryInside._id}
                        nodeId={categoryInside._id}
                        labelText={categoryInside.name}
                        labelInfo={labelInfoInside}
                        labelIcon={EditOutlinedIcon}
                        onClickIcon={handleOpenDialogCreateCategory}
                        onClick={() => handleChangeSelectedCategory(categoryInside)}
                      />
                    );
                  }

                  return (
                    <PaymentCategoryTreeItem
                      key={categoryInside._id}
                      nodeId={categoryInside._id}
                      labelText={categoryInside.name}
                      labelInfo={labelInfoInside}
                      labelIcon={EditOutlinedIcon}
                      onClickIcon={handleOpenDialogCreateCategory}
                      onClick={() => handleChangeSelectedCategory(categoryInside)}
                    >
                      {disableCreateCategory ? null : (
                        <PaymentCategoryTreeItem
                          nodeId={simulateMongoObjectId()}
                          labelText="Incluir sub-categoria"
                          labelIcon={AddIcon}
                          onClick={() => {
                            const findSelectedCategory = categoriesList.find(
                              (categoryFind) =>
                                categoryFind.categoryId === categoryInside.categoryId,
                            );

                            if (findSelectedCategory) {
                              setSelectedCategory({
                                ...newPaymentCategory,
                                ...findSelectedCategory,
                                name: '',
                                parentId: findSelectedCategory.categoryId,
                                parentName: findSelectedCategory.name,
                              });

                              handleOpenDialogCreateCategory();
                            }
                          }}
                        />
                      )}
                      {categoriesListInsideLast.map((categoryInsideLast) => {
                        let labelInfoInsideLast = null;
                        const categoryValueInsideLast = getCategoryValue(categoryInsideLast._id);

                        if (categoryValueInsideLast) {
                          labelInfoInsideLast = `R$ ${numberToRealWithPeriod(
                            categoryValueInsideLast,
                          )}`;
                        }

                        return (
                          <PaymentCategoryTreeItem
                            key={categoryInsideLast._id}
                            nodeId={categoryInsideLast._id}
                            labelText={categoryInsideLast.name}
                            labelInfo={labelInfoInsideLast}
                            labelIcon={EditOutlinedIcon}
                            onClickIcon={handleOpenDialogCreateCategory}
                            onClick={() => handleChangeSelectedCategory(categoryInsideLast)}
                          />
                        );
                      })}
                    </PaymentCategoryTreeItem>
                  );
                })}
              </PaymentCategoryTreeItem>
            );
          })}
      </TreeView>

      {openDialogCreateCategory ? (
        <DialogPaymentCategory
          openDialogCreateCategory={openDialogCreateCategory}
          handleCloseDialogCreateCategory={handleCloseDialogCreateCategory}
          selectedCategory={selectedCategory}
          setRefreshToken={setRefreshToken}
        />
      ) : null}
    </>
  );
}

export default CategoryPaymentTree;
