import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { memo } from 'react';
import { formatDate, numberToRealWithPeriod } from '../../../../../helpers/formatData';
import simulateMongoObjectId from '../../../../../helpers/simulateMongoObjectId';

function PaymentsTable({ paymentsList }) {
  return (
    <Paper elevation={4}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Método</TableCell>
            <TableCell align="center">Valor</TableCell>
            <TableCell align="center">Vencimento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentsList && paymentsList.length > 0
            ? paymentsList.map((payment) => (
                <TableRow key={payment._id || simulateMongoObjectId()}>
                  <TableCell align="center">{payment.method}</TableCell>
                  <TableCell align="center">R$ {numberToRealWithPeriod(payment.value)}</TableCell>
                  <TableCell align="center">{formatDate(payment.dueDate)}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default memo(PaymentsTable);
