import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  sku: {
    width: '8%',
    textAlign: 'center',
    fontSize: 8,
  },
  image: {
    width: '8%',
    textAlign: 'center',
    fontSize: 8,
  },
  name46: {
    width: '40%',
    margin: 1,
    fontSize: 8,
  },
  name58: {
    width: '50%',
    margin: 1,
    fontSize: 8,
  },
  code: {
    width: '22%',
    textAlign: 'center',
    fontSize: 8,
  },
  quantity: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
  unity: {
    width: '10%',
    textAlign: 'center',
    fontSize: 8,
  },
});

function TableRow({ orderId, items }) {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.positionOnList}>
      <Text style={styles.sku}>{item.productId}</Text>
      <View style={styles.image}>
        {item.images && item.images.length > 0 ? <Image src={item.images[0].src} /> : null}
      </View>
      <Text style={orderId ? styles.name46 : styles.name58}>
        {item.positionOnList + 1} - {item.technicalName}
      </Text>
      <Text style={styles.code}>
        {item.supplierOwnId
          ? item.supplierOwnId
          : item.suppliers.map((supplier) => supplier.factoryId).join('\n')}
      </Text>
      <Text style={styles.quantity}>{item.quantity}</Text>
      {orderId ? <Text style={styles.unity}>{item.entryUnity || 'Indefinida'}</Text> : null}
    </View>
  ));
  return rows;
}

export default TableRow;
