import Axios from 'axios';
import { Alert, Autocomplete, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState } from 'react';
import newWallet from '../../../constant/newWallet';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import {
  formatHandleError,
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../helpers/formatData';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function TransferBetweenWallets({ openDialog, handleClose, refreshWallet }) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [loadingWallets, setLoadingWallets] = useState(false);
  const [walletsList, setWalletsList] = useState(newWallet);

  const [selectedFromWallet, setSelectedFromWallet] = useState(newWallet);
  const [selectedToWallet, setSelectedToWallet] = useState(newWallet);
  const [transferValue, setTransferValue] = useState(0);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };
  const handleFromWalletOnChange = (event, newValue) => {
    if (newValue && newValue._id) {
      setSelectedFromWallet(newValue);
    } else {
      setSelectedFromWallet(newWallet);
    }
  };
  const handleToWalletOnChange = (event, newValue) => {
    if (newValue && newValue._id) {
      setSelectedToWallet(newValue);
    } else {
      setSelectedToWallet(newWallet);
    }
  };
  const handleChangeValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    setTransferValue(formatReal(value));
  };

  const handleGetWallets = async () => {
    setLoadingWallets(true);
    const response = await Axios.get('/wallets/list');
    const wallets = response.data;
    setWalletsList([newWallet, ...wallets]);
    setLoadingWallets(false);
  };

  useEffect(() => {
    handleGetWallets();
  }, []);

  const handleConfirmTransferBetweenWallets = async () => {
    try {
      await Axios.patch('/payments/transfer-between-wallets', {
        fromWallet: selectedFromWallet._id,
        toWallet: selectedToWallet._id,
        value: transferValue,
      });

      handleClose();
      return refreshWallet();
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao fazer a transferência',
        error,
      });
    }
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (!selectedFromWallet || !selectedFromWallet._id || !selectedFromWallet.name) {
      setErrorMessage('Selecione a carteira de onde será debitado o saldo');
      return setDisableConfirmButton(true);
    }
    if (!selectedToWallet || !selectedToWallet._id || !selectedToWallet.name) {
      setErrorMessage('Selecione a carteira onde será creditado o saldo');
      return setDisableConfirmButton(true);
    }
    if (parseFloat(transferValue) <= 0 || Number.isNaN(parseFloat(transferValue))) {
      setErrorMessage('Digite o valor a ser transferido');
      return setDisableConfirmButton(true);
    }

    setErrorMessage('');
    return setDisableConfirmButton(false);
  }, [selectedFromWallet, selectedToWallet, transferValue]);

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleClose}
      dialogTitle="Transferência entre carteiras"
      dialogText="Transfira saldo de uma carteira para outra"
      cancelButtonText="Cancelar"
      confirmButtonText="Transferir"
      handleCancelButton={handleClose}
      handleConfirmButton={handleConfirmTransferBetweenWallets}
      content={
        <Grid container justifyContent="space-between" direction="column" spacing={2}>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
          <Grid item>
            {!loadingWallets ? (
              <Autocomplete
                size="small"
                options={walletsList}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.walletId === value.walletId}
                value={selectedFromWallet}
                onChange={handleFromWalletOnChange}
                noOptionsText="Opção não encontrada"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Debitar de"
                    variant="outlined"
                    error={!selectedFromWallet || !selectedFromWallet.name}
                  />
                )}
              />
            ) : null}
          </Grid>
          <Grid item>
            {!loadingWallets ? (
              <Autocomplete
                size="small"
                options={walletsList}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.walletId === value.walletId}
                value={selectedToWallet}
                onChange={handleToWalletOnChange}
                noOptionsText="Opção não encontrada"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Creditar em"
                    variant="outlined"
                    error={!selectedToWallet || !selectedToWallet.name}
                  />
                )}
              />
            ) : null}
          </Grid>
          <Grid item>
            <CustomInput
              label="Valor"
              value={transferValue ? numberToRealWithPeriod(transferValue) : '0,00'}
              onChange={handleChangeValue}
              error={parseFloat(transferValue) <= 0 || Number.isNaN(parseFloat(transferValue))}
              InputStartAdornment="R$"
              InputAdornmentStyle={classes.inputAdornment}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
          </Grid>
        </Grid>
      }
      disableConfirmButton={disableConfirmButton}
    />
  );
}

export default memo(TransferBetweenWallets);
