import Axios from 'axios';

export const getMadeiraMadeiraProductsBySku = async ({
  setMadeiraMadeiraAds,
  setLoadingMadeiraMadeiraAds,
  productId,
}) => {
  try {
    setLoadingMadeiraMadeiraAds(true);

    const response = await Axios.get(`/madeira-madeira/products/by-sku/${productId}`);
    const { madeiraMadeiraProductsList } = response.data;

    setLoadingMadeiraMadeiraAds(false);
    return setMadeiraMadeiraAds(madeiraMadeiraProductsList);
  } catch (error) {
    setLoadingMadeiraMadeiraAds(false);
    return error;
  }
};

export const getMadeiraMadeiraPrice = (ourProduct, quantity, inOffer) => {
  const promotionalPrice = (inOffer ? ourProduct.offerPrice : ourProduct.price) * quantity;
  const productPrice = (inOffer ? ourProduct.price : ourProduct.price * 1.1) * quantity;

  return {
    newPromotionalPrice: (promotionalPrice * 1.15).toFixed(2),
    newProductPrice: (productPrice * 1.15).toFixed(2),
  };
};
