import Axios from 'axios';
import { Alert, Box, CircularProgress, Grid, Grow, Snackbar, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import MercadoLivreAdResume from './MercadoLivreAdResume';
import { getMercadoLivreProductsBySku } from '../../../../../services/melhorGestao/mercadoLivre';
import CustomDivider from '../../../../CustomDivider';
import { formatHandleError } from '../../../../../helpers/formatData';

function MercadoLivreAd({
  classes,
  productForm,
  setMercadoLivreAd,
  refreshSearch,
  setRefreshSearch,
}) {
  const [activeTab, setActiveTab] = useState(false);
  const [loadingMercadoLivreAds, setLoadingMercadoLivreAds] = useState(false);
  const [mercadoLivreConfigsList, setMercadoLivreConfigsList] = useState([]);
  const [mercadoLivreAds, setMercadoLivreAds] = useState([]);

  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingMercadoLivreAds(true);
        const response = await Axios.get('/mercado-livre/configs');
        setMercadoLivreConfigsList(response.data);

        if (productForm.productId) {
          getMercadoLivreProductsBySku({
            setMercadoLivreAd,
            setMercadoLivreAds,
            setLoadingMercadoLivreAds,
            productId: productForm.productId,
          });
        }
      } catch (error) {
        setLoadingMercadoLivreAds(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar anúncios do Mercado Livre',
          error,
        });
      }
    };
    fetchData();
  }, [productForm.productId, refreshSearch]);

  if (!mercadoLivreAds.length) {
    return null;
  }

  return (
    <>
      <CustomDivider />
      <Grid item onClick={() => setActiveTab(!activeTab)} className={classes.cursorPointer}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <img
                  className={classes.sourceBadge}
                  src="/assets/imgs/mercado-livre-badge.svg"
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.marketplaceTitle}>
                  Mercado Livre
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ExpandMoreIcon className={activeTab ? classes.openedTab : classes.closedTab} />
          </Grid>
        </Grid>
      </Grid>

      <Grow in={activeTab} unmountOnExit>
        <Grid item>
          <Box>
            {loadingMercadoLivreAds ? (
              <Box display="flex" height="100px" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : null}

            <Grid container spacing={1}>
              {mercadoLivreAds.map((mercadoLivreAd) => (
                <Grid item key={mercadoLivreAd.mlb}>
                  <MercadoLivreAdResume
                    mercadoLivreConfig={mercadoLivreConfigsList.find(
                      (mercadoLivreConfig) => mercadoLivreConfig.company === mercadoLivreAd.company,
                    )}
                    mercadoLivreAd={mercadoLivreAd}
                    productForm={productForm}
                    setRefreshSearch={setRefreshSearch}
                    setSnackbar={setSnackbar}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grow>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default MercadoLivreAd;
