const mergeOrderItems = (orderItemsArrays) => {
  const mergedOrderItems = [];

  orderItemsArrays.forEach((item) => {
    mergedOrderItems.push(...item);
  });

  const deDuplicatedOrderItems = mergedOrderItems.reduce((uniqueOrderItems, newOrderItem) => {
    if (!uniqueOrderItems.some((orderItem) => orderItem.productId === newOrderItem.productId)) {
      const duplicatedOrderItems = mergedOrderItems.filter(
        (orderItem) => orderItem.productId === newOrderItem.productId
      );

      const totalSelectedPrice = duplicatedOrderItems
        .map((orderItem) => orderItem.selectedPrice * orderItem.quantity)
        .reduce((acc, cur) => acc + cur, 0);

      const multiplicatedQuantity = duplicatedOrderItems
        .map((orderItem) => orderItem.quantity)
        .reduce((acc, cur) => acc + cur, 0);

      const selectedPrice = totalSelectedPrice / multiplicatedQuantity;

      uniqueOrderItems.push({
        ...newOrderItem,
        selectedPrice,
        quantity: multiplicatedQuantity,
      });
    }
    return uniqueOrderItems;
  }, []);

  return deDuplicatedOrderItems;
};

export default mergeOrderItems;
