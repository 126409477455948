import Axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState, useEffect } from 'react';
import DialogCategory from '../../Products/Details/InformationTab/DialogCategory';
import CustomInput from '../../CustomInput';
import SimpleDialog from '../../Common/SimpleDialog';
import TableSettingsCategory from './TableSettingsCategory';
import TableSettingsOccurrenceType from './TableSettingsOccurrenceTypes';
import TableSettingsEntryUnities from './TableSettingsEntryUnities';
import CategoryPaymentManager from '../../Common/CategoryPaymentManager';
import { formatHandleError } from '../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

const menuItems = [
  { name: 'Categorias dos produtos' },
  { name: 'Categorias dos pagamentos' },
  { name: 'Categorias dos recebimentos' },
  { name: 'Tipos de ocorrências' },
  { name: 'Unidades de medida' },
];

function SettingsCategory() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [menuItem, setMenuItem] = useState(menuItems[0].name);
  const [categoriesList, setCategoriesList] = useState([]);

  const [openDialogCategory, setOpenDialogCategory] = useState(false);
  const handleOpenDialogCategory = useCallback(() => setOpenDialogCategory(true), []);
  const handleCloseDialogCategory = useCallback(() => setOpenDialogCategory(false), []);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newUnityQuantity, setNewUnityQuantity] = useState(1);

  const [openDialogCreateCategory, setOpenDialogCreateCategory] = useState(false);
  const handleOpenDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(true), []);
  const handleCloseDialogCreateCategory = useCallback(() => setOpenDialogCreateCategory(false), []);

  const handleChangeUnityQuantity = (event) => {
    if (event.target.value === '') {
      return setNewUnityQuantity('');
    }

    let value = parseFloat(event.target.value);
    if (Number.isNaN(value) || value < 0) {
      value = 1;
    }
    return setNewUnityQuantity(value);
  };

  const [refreshToken, setRefreshToken] = useState(Math.random());
  const handleRefresh = () => setRefreshToken(Math.random());

  const handleCreateCategory = async () => {
    try {
      if (menuItem === 'Tipos de ocorrências') {
        await Axios.post('/occurrences/types', {
          name: newCategoryName,
        });
      }
      if (menuItem === 'Unidades de medida') {
        await Axios.post('/entries/unity', {
          name: newCategoryName,
          quantity: typeof newUnityQuantity === 'string' ? 1 : newUnityQuantity,
        });
      }
      handleCloseDialogCreateCategory();
      handleRefresh();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: `Algum erro ocorreu ao adicionar "${menuItem}"`,
        error,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (menuItem === 'Categorias dos produtos') {
          const response = await Axios.get('/catalog/categories');
          setCategoriesList(response.data.categories);
        }
        if (menuItem === 'Tipos de ocorrências') {
          const response = await Axios.get('/occurrences/types');
          setCategoriesList(response.data);
        }
        if (menuItem === 'Unidades de medida') {
          const response = await Axios.get('/entries/unity');
          setCategoriesList(response.data);
        }
      } catch (error) {
        setCategoriesList([]);
        formatHandleError({
          setSnackbar,
          defaultMessage: `Algum erro ocorreu ao buscar "${menuItem}"`,
          error,
        });
      }
    };
    fetchData();
  }, [menuItem, refreshToken]);

  useEffect(() => {
    if (!newCategoryName) {
      return setDisableConfirmButton(true);
    }
    return setDisableConfirmButton(false);
  }, [newCategoryName, openDialogCreateCategory]);

  return (
    <Box marginTop={3} marginBottom={5}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Typography variant="h5">Categorias</Typography>
            </Box>

            <MenuList>
              {menuItems.map((item) => (
                <MenuItem
                  key={item.name}
                  onClick={() => setMenuItem(item.name)}
                  className={menuItem === item.name ? classes.menuItemSelected : null}
                >
                  {item.name}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Grid>

        <Grid item sm={8}>
          <Paper>
            <Box padding={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{menuItem}</Typography>
                </Grid>
                {menuItem === 'Categorias dos produtos' ||
                menuItem === 'Tipos de ocorrências' ||
                menuItem === 'Unidades de medida' ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        menuItem === 'Categorias dos produtos'
                          ? handleOpenDialogCategory
                          : handleOpenDialogCreateCategory
                      }
                    >
                      Adicionar
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              <Box marginTop={2}>
                {menuItem === 'Categorias dos produtos' ? (
                  <TableSettingsCategory categoriesList={categoriesList} />
                ) : null}
                {menuItem === 'Categorias dos pagamentos' ||
                menuItem === 'Categorias dos recebimentos' ? (
                  <CategoryPaymentManager
                    setSnackbar={setSnackbar}
                    type={menuItem === 'Categorias dos pagamentos' ? 'Pagamento' : 'Recebimento'}
                  />
                ) : null}
                {menuItem === 'Tipos de ocorrências' ? (
                  <TableSettingsOccurrenceType occurrencesList={categoriesList} />
                ) : null}
                {menuItem === 'Unidades de medida' ? (
                  <TableSettingsEntryUnities unitiesList={categoriesList} />
                ) : null}
              </Box>
            </Box>
          </Paper>
          {openDialogCategory ? (
            <DialogCategory
              openDialogCategory={openDialogCategory}
              handleCloseDialogCategory={handleCloseDialogCategory}
            />
          ) : null}
          {openDialogCreateCategory ? (
            <SimpleDialog
              content={
                <Box>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <CustomInput
                        label="Nome"
                        value={newCategoryName}
                        onChange={(event) => setNewCategoryName(event.target.value)}
                      />
                    </Grid>
                    {menuItem === 'Unidades de medida' ? (
                      <Grid item>
                        <CustomInput
                          number
                          label="Quantidade"
                          value={newUnityQuantity}
                          onChange={handleChangeUnityQuantity}
                          error={typeof newUnityQuantity !== 'number' || !(newUnityQuantity > 0)}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              }
              dialogTitle={menuItem}
              handleClose={handleCloseDialogCreateCategory}
              openDialog={openDialogCreateCategory}
              handleConfirmButton={handleCreateCategory}
              disableConfirmButton={disableConfirmButton}
              confirmButtonText="Confirmar"
            />
          ) : null}
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SettingsCategory;
