import React, { useState, useEffect } from 'react';
import { Alert, Box, CircularProgress, Grid, Paper, Snackbar } from '@mui/material';
import Axios from 'axios';
import dayjs from 'dayjs';
import ResumeTopBar from './TopBar';
import SquareWidget from './SquareWidget';
import { formatHandleError } from '../../helpers/formatData';

const orderStatusList = [
  'Orçamento',
  'Pedido criado',
  'Pedido confirmado',
  'Pedido enviado',
  'Pedido entregue',
  'Pedido não entregue',
  'Pedido cancelado',
  'Pedido devolvido',
];

function OrdersStatusResume() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [preferenceDate, setPreferenceDate] = useState(dayjs());
  const [resumeOrders, setResumeOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const promiseOrdersResume = [];

      orderStatusList.forEach((status) => {
        promiseOrdersResume.push(
          Axios.get('/orders/resume/source', {
            params: {
              status,
              date: preferenceDate,
            },
          }),
        );
      });

      try {
        const responses = await Promise.all(promiseOrdersResume);
        const ordersResume = responses.map((response) => response.data.ordersResume);
        setResumeOrders(ordersResume);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar resumo dos pedidos',
          error,
        });
      }
      setLoading(false);
    };
    fetchData();
  }, [preferenceDate]);

  return (
    <Box position="relative" marginBottom={5}>
      <Box marginTop={2}>
        <ResumeTopBar preferenceDate={preferenceDate} setPreferenceDate={setPreferenceDate} />
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {loading
            ? orderStatusList.map((status) => (
                <Grid item sm={3} style={{ width: '170%' }} key={status}>
                  <Paper>
                    <Box height="285px" width="100%" paddingTop={17}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              ))
            : resumeOrders.map((resume, index) => (
                <Grid item sm={3} style={{ width: '170%' }} key={orderStatusList[index]}>
                  <SquareWidget
                    title={orderStatusList[index]}
                    month={resume.month}
                    week={resume.week}
                    day={resume.day}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default OrdersStatusResume;
