import Axios from 'axios';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { formatHandleError } from '../../../helpers/formatData';

function WebmaniaClassesImpostosMenu({
  setSnackbar = () => {},
  handleOnChange = () => {},
  companyId,
  selectedWebmaniaClasseImposto,
  label = 'Referência',
  variant,
}) {
  const [webmaniaClassesList, setWebmaniaClassesList] = useState([]);
  const [loadingWebmaniaClassesList, setLoadingWebmaniaClassesList] = useState(true);

  const getWebmaniaClassesImpostos = async () => {
    try {
      setLoadingWebmaniaClassesList(true);
      const response = await Axios.get('/webmania/classes-impostos', {
        params: {
          companyId,
        },
      });
      setWebmaniaClassesList(response.data);
      setLoadingWebmaniaClassesList(false);
    } catch (error) {
      setLoadingWebmaniaClassesList(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar lista de classes de impostos do Webmania',
        error,
      });
    }
  };

  useEffect(() => {
    getWebmaniaClassesImpostos();
  }, []);

  return (
    <Box sx={{ width: 295 }}>
      {loadingWebmaniaClassesList ? (
        <Skeleton variant="rectangular" width={295} height={45} />
      ) : (
        <FormControl fullWidth variant={variant} size="small">
          <InputLabel>{label}</InputLabel>
          <Select
            name={companyId}
            value={selectedWebmaniaClasseImposto}
            onChange={handleOnChange}
            variant={variant}
          >
            <MenuItem value="Padrão">
              <Typography variant="inherit">
                Padrão - Usa a classe de impostos geral configurada
              </Typography>
            </MenuItem>
            {webmaniaClassesList.map((webmaniaClasse) => (
              <MenuItem key={webmaniaClasse.webmaniaRef} value={webmaniaClasse.webmaniaRef}>
                <Typography variant="inherit">
                  {webmaniaClasse.webmaniaRef} - {webmaniaClasse.description}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

export default memo(WebmaniaClassesImpostosMenu);
