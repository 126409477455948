import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const createFinance = async ({ setSnackbar, setLoadingFinances = () => {}, finance }) => {
  try {
    setLoadingFinances(true);

    const response = await Axios.post('/financial', finance);

    setLoadingFinances(false);
    return response.data;
  } catch (error) {
    setLoadingFinances(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao cadastrar um financeiro',
      error,
    });

    return null;
  }
};

export const updateFinance = async ({ setSnackbar, setLoadingFinances = () => {}, finance }) => {
  try {
    setLoadingFinances(true);

    const response = await Axios.put(`/financial/${finance.financeId}`, finance);

    setLoadingFinances(false);
    return response.data;
  } catch (error) {
    setLoadingFinances(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar um financeiro',
      error,
    });

    return null;
  }
};

export const getFinancesResume = async ({
  setLoadingFinancesResume,
  setFiltersFinancesStatus,
  filtersFinancesStatus,
  company,
  user,
  startDate,
  endDate,
  payed,
  type,
  setSnackbar = () => {},
  batchSize = 10,
}) => {
  try {
    setLoadingFinancesResume(true);

    const batches = [];

    for (let i = 0; i < filtersFinancesStatus.length; i += batchSize) {
      batches.push(filtersFinancesStatus.slice(i, i + batchSize));
    }

    const responses = await Promise.all(
      batches.map(async (batch) => {
        const response = await Axios.get(`/financial/resume`, {
          params: {
            filtersFinancesStatus: batch,
            company,
            user,
            startDate,
            endDate,
            payed,
            type,
          },
        });

        return response.data;
      }),
    );

    const combinedResponse = responses.reduce((acc, data) => acc.concat(data), []);

    setFiltersFinancesStatus(combinedResponse);
    return setLoadingFinancesResume(false);
  } catch (error) {
    setLoadingFinancesResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar o resumo do financeiro',
      error,
    });
  }
};

const getFinances = async ({
  setSnackbar,
  setLoadingFinances = () => {},
  skip,
  limit,
  company,
  user,
  category,
  startDate,
  endDate,
  payed,
  type,
}) => {
  try {
    setLoadingFinances(true);
    const response = await Axios.get('/financial', {
      params: {
        skip,
        limit,
        company,
        user,
        category,
        startDate,
        endDate,
        payed,
        type,
      },
    });

    setLoadingFinances(false);
    return response.data;
  } catch (error) {
    setLoadingFinances(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar a lista do financeiro',
      error,
    });

    return null;
  }
};

export default getFinances;
