import { Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { numberToReal } from '../../../../helpers/formatData';
import ProductsModal from '../../../Products/TableModal';
import DevolutionsTable from './DevolutionsTable';
import InvoiceActonsBlok from '../InvoiceActionsBlok';
import InvoiceDevolutionButton from './InvoiceDevolutionButton';
import { createDevolution, getDevolutions } from '../../../../services/melhorGestao/orders';
import CancelOrderButton from './CancelOrderButton';

function DevolutionsTab({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  formChanged,
  devolutions,
  setDevolutions,
  setDevolutionData,
  userItemsBought,
  setSnackbar,
}) {
  const [openDialogDevolutions, setOpenDialogDevolutions] = useState(false);
  const handleOpenDialogDevolutions = useCallback(() => setOpenDialogDevolutions(true), []);
  const handleCloseDialogDevolutions = useCallback(() => setOpenDialogDevolutions(false), []);

  const [loadindgDevolutions, setLoadindgDevolutions] = useState(false);

  useEffect(() => {
    if (!loadindgDevolutions && orderForm.orderId) {
      getDevolutions({
        orderId: orderForm.orderId,
        setLoadindgDevolutions,
        setDevolutions,
      });
    }
  }, []);

  const handleSelectDevolutionsToOrder = async ({ selectedProducts }) => {
    const inDevolution = {
      orderId: orderForm.orderId,
      items: selectedProducts,
    };

    const newDevolution = await createDevolution({ devolution: inDevolution, setSnackbar });

    if (newDevolution) {
      setDevolutions((oldDevolutions) => [...oldDevolutions, newDevolution]);
      setDevolutionData(newDevolution);
    }
  };

  const [blockDevolution, setBlockDevolution] = useState(true);
  const [blockDevolutionMessage, setBlockDevolutionMessage] = useState('');

  useEffect(() => {
    if (formChanged || !orderForm.orderId) {
      setBlockDevolutionMessage('Salve o pedido primeiro');
      return setBlockDevolution(true);
    }
    if (
      orderForm.status === 'Orçamento' ||
      orderForm.status === 'Pedido criado' ||
      orderForm.status === 'Pedido cancelado'
    ) {
      setBlockDevolutionMessage('Este pedido não foi confirmado');
      return setBlockDevolution(true);
    }
    if (orderForm.status === 'Pedido devolvido') {
      setBlockDevolutionMessage('Este pedido já foi totalmente devolvido');
      return setBlockDevolution(true);
    }
    if (dayjs(orderForm.createdAt).add(90, 'day').isBefore(dayjs())) {
      setBlockDevolutionMessage('Pedido criado a mais de 90 dias');
      return setBlockDevolution(true);
    }
    setBlockDevolutionMessage('Devolver um ou todos itens do pedido');
    return setBlockDevolution(false);
  }, [orderForm, formChanged]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Devoluções</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <CancelOrderButton
                  orderForm={orderForm}
                  setOrderForm={setOrderForm}
                  setInitialOrderForm={setInitialOrderForm}
                  formChanged={formChanged}
                  setSnackbar={setSnackbar}
                />
              </Grid>
              <Tooltip title={<Typography align="center">{blockDevolutionMessage}</Typography>}>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleOpenDialogDevolutions}
                    disabled={blockDevolution}
                  >
                    Fazer devolução
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {devolutions && devolutions.length > 0
        ? devolutions.map((devolution) => (
            <React.Fragment key={devolution.devolutionId}>
              {devolution.items && devolution.items.length > 0 && orderForm.payments.length > 0 ? (
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                        <Grid item>
                          <Typography>Total devolvido:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            R$ {numberToReal(devolution.devolutionTotal)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                        <Grid item>
                          <Typography>Total dos itens:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            R$ {numberToReal(devolution.itemsTotal)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {orderForm.orderId ? (
                <Grid item xs={12}>
                  <DevolutionsTable devolution={devolution} />
                </Grid>
              ) : null}
              {devolution.invoice && devolution.invoice.key ? (
                <InvoiceActonsBlok
                  orderId={orderForm.orderId}
                  invoice={devolution.invoice}
                  setOrderForm={setOrderForm}
                  setInitialOrderForm={setInitialOrderForm}
                  setSnackbar={setSnackbar}
                  hideButtons
                />
              ) : (
                <InvoiceDevolutionButton
                  orderForm={orderForm}
                  devolution={devolution}
                  setDevolutions={setDevolutions}
                  setSnackbar={setSnackbar}
                />
              )}
            </React.Fragment>
          ))
        : null}
      {openDialogDevolutions ? (
        <ProductsModal
          openProductsModal={openDialogDevolutions}
          handleCloseProductsModal={handleCloseDialogDevolutions}
          handleConfirmSelect={handleSelectDevolutionsToOrder}
          productsToDevolution={orderForm.items}
          alreadySelectedProducts={[]}
          userItemsBought={userItemsBought}
          orderCompany={orderForm.company}
          orderSource={orderForm.source}
        />
      ) : null}
    </Grid>
  );
}

export default DevolutionsTab;
