import React, { useState } from 'react';
import {
  DashboardOutlined,
  CategoryOutlined,
  DiscountOutlined,
  ScaleOutlined,
  IntegrationInstructionsOutlined,
  HomeWorkOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import TopBarSettings from './TopBarSettings';
import SettingsCms from './SettingsCms';
import SettingsCategory from './SettingsCategory';
import FixedOffers from './FixedOffers';
import IntegrationsErrors from './IntegrationsErrors';
import Companies from './Companies';
import WeighingBalance from './WeighingBalance';

const menuItems = [
  {
    name: 'Empresas',
    icon: HomeWorkOutlined,
  },
  {
    name: 'Websites',
    icon: DashboardOutlined,
  },
  {
    name: 'Categorias',
    icon: CategoryOutlined,
  },
  {
    name: 'Promoções',
    icon: DiscountOutlined,
  },
  {
    name: 'Erros de Integrações',
    icon: IntegrationInstructionsOutlined,
  },
  {
    name: 'Balanças',
    icon: ScaleOutlined,
  },
];

function Settings() {
  const [activeMenu, setActiveMenu] = useState(menuItems[0].name);

  return (
    <Box position="relative" marginBottom={5}>
      <Box marginTop={2}>
        <TopBarSettings
          menuItems={menuItems}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
      </Box>
      <Box marginTop={2}>
        {activeMenu === 'Empresas' && <Companies />}
        {activeMenu === 'Websites' && <SettingsCms />}
        {activeMenu === 'Categorias' && <SettingsCategory />}
        {activeMenu === 'Promoções' && <FixedOffers />}
        {activeMenu === 'Erros de Integrações' && <IntegrationsErrors />}
        {activeMenu === 'Balanças' && <WeighingBalance />}
      </Box>
    </Box>
  );
}

export default Settings;
