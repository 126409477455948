import Axios from 'axios';
import React, { useState, useCallback, useEffect } from 'react';
import { Alert, Box, Snackbar } from '@mui/material';
import { getMercadoLivreProducts } from '../../services/melhorGestao/mercadoLivre';
import Table from './Table';
import TopBar from './TopBar';
import SituationsFiltersPapers from '../Common/SituationsFiltersPapers';
import filterMlAdsSituationsInitial from '../../constant/filterMlAdsSituationsInitial';
import filterMlAdsOptionsInitial from '../../constant/filterMlAdsOptionsInitial';
import DialogMercadoLivreAdStatus from './MarketplacesDetails/MercadoLivreDetails/DialogMercadoLivreAdStatus';
import SimpleLinearProgress from '../Common/SimpleLinearProgress';

function MarketplacesAds() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [refreshSearch, setRefreshSearch] = useState(Math.random());
  const [selectedAd, setSelectedAd] = useState(null);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [mercadoLivreAdsList, setMercadoLivreAdsList] = useState([]);
  const [loadingMercadoLivreAdsList, setLoadingMercadoLivreAdsList] = useState(false);
  const [mercadoLivreAdsCount, setMercadoLivreAdsCount] = useState(0);
  const [situationsFilters, setSituationsFilters] = useState(filterMlAdsSituationsInitial);
  const [filtersOptions, setFiltersOptions] = useState(filterMlAdsOptionsInitial);
  const [page, setPage] = useState(1);
  const [limit] = useState(40);

  const [openDialogMercadoLivreAdStatus, setOpenDialogMercadoLivreAdStatus] = useState(false);
  const handleOpenDialogMercadoLivreAdStatus = useCallback(
    () => setOpenDialogMercadoLivreAdStatus(true),
    [],
  );
  const handleCloseDialogMercadoLivreAdStatus = useCallback(
    () => setOpenDialogMercadoLivreAdStatus(false),
    [],
  );

  const [mercadoLivreConfig, setMercadoLivreConfig] = useState(null);
  const [mercadoLivreConfigsList, setMercadoLivreConfigsList] = useState([]);
  const [loadingMercadoLivreConfigs, setLoadingMercadoLivreConfigs] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingMercadoLivreConfigs(true);
        const response = await Axios.get('/mercado-livre/configs');
        setMercadoLivreConfigsList(response.data);

        if (response.data.length > 0) {
          setMercadoLivreConfig(response.data[0]);
        }
        setLoadingMercadoLivreConfigs(false);
      } catch (error) {
        setLoadingMercadoLivreConfigs(false);
        setSnackbar({
          message: error.message,
          open: true,
          type: 'error',
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (mercadoLivreConfig?.sellerId) {
      const fetchData = () => {
        getMercadoLivreProducts({
          setSnackbar,
          setLoadingMercadoLivreAdsList,
          setMercadoLivreAdsList,
          setMercadoLivreAdsCount,
          limit,
          skip: page * limit - limit,
          sortBy: 'productId',
          sortDirection: 'asc',
          sellerId: mercadoLivreConfig.sellerId,
          searchText: searchTextSubmited,
          filtersOptions,
        });
      };
      fetchData();
    }
  }, [page, limit, mercadoLivreConfig, searchTextSubmited, filtersOptions, refreshSearch]);

  const selectAd = (ad) => {
    setSelectedAd(ad);
    handleOpenDialogMercadoLivreAdStatus();
  };

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, mercadoLivreAdsList],
  );

  useEffect(() => {
    setPage(1);
  }, [searchTextSubmited]);

  const checkIfAnyActive = (filterArray) => {
    let anyActive = false;
    filterArray.forEach((filter) => {
      if (filter.active) {
        anyActive = true;
      }
    });
    return anyActive;
  };

  const handleClickFilter = () => {
    if (checkIfAnyActive(situationsFilters)) {
      setFiltersOptions(() =>
        filtersOptions.map((filtersOption) => {
          const situationFilter = situationsFilters.find(
            (filter) => filter.active && filter.id === filtersOption.id,
          );

          if (situationFilter) {
            return {
              ...filtersOption,
              active: true,
            };
          }

          return {
            ...filtersOption,
            active: false,
          };
        }),
      );
    }

    if (!checkIfAnyActive(situationsFilters)) {
      setFiltersOptions(() =>
        filtersOptions.map((filtersOption) => ({
          ...filtersOption,
          active: false,
        })),
      );
    }

    setPage(1);
  };

  return (
    <>
      <SimpleLinearProgress loading={loadingMercadoLivreAdsList} fixedPosition />
      <Box marginTop={2}>
        <TopBar
          mercadoLivreConfig={mercadoLivreConfig}
          setMercadoLivreConfig={setMercadoLivreConfig}
          mercadoLivreConfigsList={mercadoLivreConfigsList}
          loadingMercadoLivreConfigs={loadingMercadoLivreConfigs}
          submitSearchProducts={setSearchTextSubmited}
          filtersOptions={filtersOptions}
          setFiltersOptions={setFiltersOptions}
        />
      </Box>
      {mercadoLivreConfig?.sellerId ? (
        <Box marginTop={2}>
          <SituationsFiltersPapers
            key={mercadoLivreConfig.sellerId}
            situationsFilters={situationsFilters}
            setSituationsFilters={setSituationsFilters}
            handleClickFilter={handleClickFilter}
            mercadoLivreAds={{
              sellerId: mercadoLivreConfig.sellerId,
            }}
          />
        </Box>
      ) : null}
      <Box marginTop={2}>
        <Table
          selectAd={selectAd}
          mercadoLivreConfig={mercadoLivreConfig}
          mercadoLivreAdsList={mercadoLivreAdsList}
          mercadoLivreAdsCount={mercadoLivreAdsCount}
          loadingMercadoLivreAdsList={loadingMercadoLivreAdsList}
          setRefreshSearch={setRefreshSearch}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          setSnackbar={setSnackbar}
        />
      </Box>
      {openDialogMercadoLivreAdStatus ? (
        <DialogMercadoLivreAdStatus
          setRefreshSearch={setRefreshSearch}
          openDialogMercadoLivreAdStatus={openDialogMercadoLivreAdStatus}
          handleCloseDialogMercadoLivreAdStatus={handleCloseDialogMercadoLivreAdStatus}
          selectedAd={selectedAd}
          setSnackbar={setSnackbar}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default MarketplacesAds;
