import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import SearchField from '../../../../../Common/SearchField';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../../../helpers/formatData';

function ViaVarejoCategoryDialog({ openDialog, handleClose, handleClick, searchTerm }) {
  const [predictedCategoryList, setPredictedCategoryList] = useState([]);
  const [loadingPredictCategory, setLoadingPredictCategory] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const searchViaVarejoCategory = useCallback(async (searchText) => {
    try {
      setLoadingPredictCategory(true);
      setErrorMessage('');

      if (searchText) {
        const response = await Axios.get(`/via-varejo/categories/search/`, {
          params: {
            searchText,
          },
        });

        const { categoriesList } = response.data;
        setPredictedCategoryList(categoriesList || []);
      }

      setLoadingPredictCategory(false);
    } catch (error) {
      setPredictedCategoryList([]);
      setLoadingPredictCategory(false);
      formatHandleError({
        setSnackbar: ({ message }) => setErrorMessage(message),
        defaultMessage: 'Algum erro ocorreu ao buscar categorias na Via Varejo',
        error,
      });
    }
  }, []);

  useEffect(() => {
    searchViaVarejoCategory(searchTerm);
  }, []);

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleClose}
      dialogTitle="Via Varejo"
      dialogText="Pesquisar categoria da Via Varejo"
      content={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <SearchField
              submitSearch={searchViaVarejoCategory}
              labelSearch="Pesquisar categoria"
              preSearchText={searchTerm}
            />
          </Grid>
          {predictedCategoryList.length > 0 && !loadingPredictCategory
            ? predictedCategoryList.map((category) => (
                <Grid key={category._id} item>
                  <Grid container direction="column">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClick(category)}
                      >
                        {category.name}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{category.pathFromRoot}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : null}
          {predictedCategoryList.length === 0 && !errorMessage && !loadingPredictCategory ? (
            <Grid item>
              <Typography>Nenhuma categoria encontrada</Typography>
            </Grid>
          ) : null}
          {loadingPredictCategory ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {errorMessage ? (
            <Grid item>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          ) : null}
        </Grid>
      }
    />
  );
}

export default memo(ViaVarejoCategoryDialog);
