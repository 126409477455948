/* eslint-disable jsx-a11y/label-has-associated-control */
import Axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../SimpleDialog';
import SimpleImage from '../SimpleImage';
import { formatHandleError } from '../../../helpers/formatData';

const useStyles = makeStyles(() => ({
  imageItem: {
    cursor: 'pointer',
  },
}));

function ImagesManagerDialog({
  openImagesManagerDialog,
  handleCloseImagesManagerDialog,
  onSelectSrcImage,
  selectedSrcImage,
  directory,
  title,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loadingImage, setLoadingImage] = useState(false);
  const [srcLinks, setSrcLinks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingImage(true);

        const response = await Axios.get(
          `${process.env.REACT_APP_REST_URL_IMG}/${directory}/links`,
        );

        setSrcLinks(response.data);
        setLoadingImage(false);
      } catch (error) {
        setLoadingImage(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar a imagens',
          error,
        });
      }
    };
    fetchData();
  }, []);

  const handleUploadImage = async (event) => {
    try {
      if (event.target.files) {
        setLoadingImage(true);

        await Array.from(event.target.files).reduce(async (promise, file) => {
          await promise;

          if (file) {
            const formData = new FormData();
            formData.append('image', file);

            const response = await Axios.post(
              `${process.env.REACT_APP_REST_URL_IMG}/${directory}/upload`,
              formData,
              {
                params: {
                  name: title,
                },
                headers: {
                  'token-mg-admin': process.env.REACT_APP_API_TOKEN_ADMIN,
                  'content-type': 'multipart/form-data',
                },
              },
            );

            setSrcLinks((oldLinks) => [response.data.link, ...oldLinks]);
          }
        }, Promise.resolve());

        setLoadingImage(false);
      }
    } catch (error) {
      setLoadingImage(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao enviar a imagem',
        error,
      });
    }
  };

  const [openDialogConfirmDeleteImage, setOpenDialogConfirmDeleteImage] = useState(false);
  const handleOpenDialogConfirmDeleteImage = () => setOpenDialogConfirmDeleteImage(true);
  const handleCloseDialogConfirmDeleteImage = () => setOpenDialogConfirmDeleteImage(false);

  const [srcToDelete, setSrcToDelete] = useState('');

  const handleDeleteImage = async (src) => {
    setSrcToDelete(src);
    handleOpenDialogConfirmDeleteImage();
  };

  const handleConfirmDeleteImage = async () => {
    try {
      const fileId = srcToDelete.split('/').pop().split('-')[0];

      await Axios.delete(`${process.env.REACT_APP_REST_URL_IMG}/${directory}/delete`, {
        params: {
          fileId,
        },
        headers: {
          'token-mg-admin': process.env.REACT_APP_API_TOKEN_ADMIN,
        },
      });

      handleCloseDialogConfirmDeleteImage();
      setSrcLinks((oldLinks) => oldLinks.filter((srcLink) => srcLink !== srcToDelete));

      if (selectedSrcImage === srcToDelete) {
        onSelectSrcImage('');
      } else {
        setSrcToDelete('');
      }
    } catch (error) {
      setSrcToDelete('');
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao deletar a imagem',
        error,
      });
    }
  };

  return (
    <SimpleDialog
      actionButton={
        <label htmlFor="upload-images">
          <input
            id="upload-images"
            accept="image/*"
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={handleUploadImage}
          />
          <Tooltip title={<Typography>Enviar Imagens</Typography>}>
            <IconButton component="span">
              <CloudUploadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </label>
      }
      content={
        <Box>
          {loadingImage ? (
            <ImageList sx={{ width: 900, height: 600 }} cols={4}>
              {[1, 2, 3, 4].map((item) => (
                <ImageListItem key={item} className={classes.imageItem}>
                  <SimpleImage
                    height={200}
                    width={200}
                    alt={`Carregando imagem ${item} - ${title}`}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <ImageList sx={{ width: 900, height: 600 }} cols={4}>
              {srcLinks.map((src, index) => (
                <ImageListItem
                  key={src}
                  onClick={() => onSelectSrcImage(src)}
                  className={classes.imageItem}
                >
                  <ImageListItemBar
                    sx={{
                      background: 'rgba(0,0,0,0)',
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        color="secondary"
                        onClick={(event) => {
                          handleDeleteImage(src);
                          event.stopPropagation();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                    actionPosition="right"
                  />
                  <SimpleImage
                    src={src}
                    height={200}
                    width={200}
                    alt={`Imagem ${index + 1} - ${title}`}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}

          {openDialogConfirmDeleteImage ? (
            <SimpleDialog
              openDialog={openDialogConfirmDeleteImage}
              handleClose={handleCloseDialogConfirmDeleteImage}
              dialogTitle="Deletar Imagem"
              dialogText="Tem certeza? Esta ação não pode ser desfeita."
              content={<SimpleImage src={srcToDelete} width={300} alt={title} />}
              cancelButtonText="Cancelar"
              confirmButtonText="Confirmar"
              handleCancelButton={handleCloseDialogConfirmDeleteImage}
              handleConfirmButton={handleConfirmDeleteImage}
              confirmButtonLoading={loadingImage}
            />
          ) : null}

          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Box>
      }
      dialogTitle="Gerenciador de Imagens"
      handleClose={handleCloseImagesManagerDialog}
      openDialog={openImagesManagerDialog}
    />
  );
}

export default memo(ImagesManagerDialog);
