import Axios from 'axios';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { changeEntryOrderStatus } from '../../../../services/melhorGestao/orders';
import { formatHandleError } from '../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  checkMobile: {
    [theme.breakpoints.down('xs')]: {
      width: 155,
      marginLeft: -405,
    },
  },
}));

function EntryInvoiceEmissionButton({
  entryOrder,
  setEntryOrder,
  setInitialEntryOrder,
  setSnackbar,
  previewOnly,
}) {
  const classes = useStyles();

  const [loadingCheckNfeStatus, setLoadingCheckNfeStatus] = useState(false);

  const handleCreateInvoice = async () => {
    setLoadingCheckNfeStatus(true);

    if (entryOrder.invoice?.status !== 'aprovado') {
      try {
        const responseInvoice = await Axios.post('/invoices/entry', {
          entryOrderId: entryOrder.entryOrderId,
          previewOnly,
        });

        const result = responseInvoice.data;

        if (!result) {
          setLoadingCheckNfeStatus(false);

          return null;
        }

        const responseEntryOrder = await Axios.get(`/entries/${entryOrder.entryOrderId}`);
        const entryOrderUpdated = responseEntryOrder.data.order;

        setEntryOrder((oldFields) => ({ ...oldFields, ...entryOrderUpdated }));
        setInitialEntryOrder((oldFields) => ({ ...oldFields, ...entryOrderUpdated }));

        if (result.previaDanfe) {
          window.open(result.danfeLink);
        }

        setLoadingCheckNfeStatus(false);

        return setSnackbar({
          message: result.description,
          open: true,
          type: result.result ? 'success' : 'error',
        });
      } catch (error) {
        setLoadingCheckNfeStatus(false);
        return formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao emitir nota fiscal da entrada',
          error,
        });
      }
    }

    const updatedEntryOrder = await changeEntryOrderStatus({
      entryOrderId: entryOrder.entryOrderId,
      toStatus: 'Pedido faturado',
      setLoadingUpdateOrder: setLoadingCheckNfeStatus,
      setSnackbar,
    });

    if (!updatedEntryOrder) {
      return null;
    }

    setEntryOrder(updatedEntryOrder);
    return setInitialEntryOrder(updatedEntryOrder);
  };

  return entryOrder.invoice?.status !== 'aprovado' ? (
    <Grid item className={classes.checkMobile}>
      <LoadingButton
        loading={loadingCheckNfeStatus}
        variant="contained"
        color="secondary"
        onClick={handleCreateInvoice}
      >
        {previewOnly ? 'Prévia NF-e' : 'Emitir NF-e'}
      </LoadingButton>
    </Grid>
  ) : null;
}

export default EntryInvoiceEmissionButton;
