import { Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import InvoiceInquiryLink from '../../../../Common/InvoiceInquiryLink';

function InvoiceBlok({ invoice }) {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>Nota Fiscal:</Grid>
      <Grid item>
        <Typography variant="h3">{invoice.number}</Typography>
      </Grid>
      {invoice?.danfe ? (
        <Grid item>
          <Chip onClick={() => window.open(invoice.danfe)} size="small" label="DANFE" />
        </Grid>
      ) : (
        <Grid item>
          <InvoiceInquiryLink invoice={invoice} />
        </Grid>
      )}
      {invoice?.xml ? (
        <Grid item>
          <Chip onClick={() => window.open(invoice.xml)} size="small" label="XML" />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default InvoiceBlok;
