import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const getTasks = async ({ userForm, setLoadingAllTasks = () => {}, setSnackbar = () => {} }) => {
  try {
    const formatedTasks = [];
    setLoadingAllTasks(true);

    const response = await Axios.get('/tasks');
    const { tasks } = response.data;
    tasks.forEach((task) => {
      let active = false;
      task.usersIds.forEach((userId) => {
        if (userId === userForm.userId) {
          active = true;
        }
      });
      formatedTasks.push({
        name: task.name,
        roles: task.roles,
        active,
      });
    });

    setLoadingAllTasks(false);
    return formatedTasks;
  } catch (error) {
    setLoadingAllTasks(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao carregar as permissões do usuário',
      error,
    });

    return [];
  }
};

export default getTasks;
