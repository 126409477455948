import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const searchCheques = async ({
  limit,
  skip,
  searchText,
  user,
  situations,
  setLoadingChequesList = () => {},
  setChequesList,
  setChequesCount = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingChequesList(true);

    const response = await Axios.get('/financial/cheques/search', {
      params: {
        limit,
        skip,
        searchText,
        user,
        situations,
      },
    });

    const { totalResults, chequesList } = response.data;
    setChequesList(chequesList);
    setChequesCount(totalResults);

    return setLoadingChequesList(false);
  } catch (error) {
    setLoadingChequesList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar os cheques',
      error,
    });
  }
};

export const createCheque = async ({
  setSnackbar = () => {},
  setLoadingCheques = () => {},
  cheque,
}) => {
  try {
    setLoadingCheques(true);

    const response = await Axios.post('/financial/cheques', cheque);

    setLoadingCheques(false);
    return response.data;
  } catch (error) {
    setLoadingCheques(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao cadastrar o cheque',
      error,
    });

    return null;
  }
};

export const updateCheque = async ({
  setSnackbar = () => {},
  setLoadingCheques = () => {},
  cheque,
}) => {
  try {
    setLoadingCheques(true);

    const response = await Axios.put('/financial/cheques', cheque);

    setLoadingCheques(false);
    return response.data;
  } catch (error) {
    setLoadingCheques(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar o cheque',
      error,
    });

    return null;
  }
};
