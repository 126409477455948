import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  backdropAbsolutePosition: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
  },
}));

function SimpleBackdrop({ loading, absolutePosition }) {
  const classes = useStyles();

  return (
    <Backdrop
      className={absolutePosition ? classes.backdropAbsolutePosition : classes.backdrop}
      open={loading}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}

export default memo(SimpleBackdrop);
