const newPaymentCategory = {
  _id: '',
  categoryId: 0,
  name: '',
  parentId: 0,
  parentName: '',
  type: '',
};

export default newPaymentCategory;
