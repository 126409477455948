import Axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Box, Chip, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDate, formatHandleError } from '../../../helpers/formatData';
import SimpleDialog from '../../Common/SimpleDialog';
import LimitTypography from '../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  boxPositionRelative: {
    position: 'relative',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxDeleteIcon: {
    position: 'absolute',
    bottom: 110,
    right: 0,
  },
  gridHeight: {
    height: 20,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 95,
    paddingBottom: '0px!important',
  },
}));

function OfferPaper({ offer, setLoadingOffers, refreshOffers, setSnackbar }) {
  const classes = useStyles();

  const [openDialogConfirmDeleteOffer, setOpenDialogConfirmDeleteOffer] = useState(false);
  const handleOpenDialogConfirmDeleteOffer = useCallback(() =>
    setOpenDialogConfirmDeleteOffer(true),
  );
  const handleCloseDialogConfirmDeleteOffer = useCallback(() =>
    setOpenDialogConfirmDeleteOffer(false),
  );
  const handleConfirmDeleteOffer = async () => {
    handleCloseDialogConfirmDeleteOffer();

    try {
      setLoadingOffers(true);
      await Axios.delete(`/offers/${offer.offerId}`);
      setLoadingOffers(false);
      refreshOffers();
    } catch (error) {
      setLoadingOffers(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao excluir a promoção',
        error,
      });
    }
  };

  const [totalProducts, setTotalProducts] = useState(offer.totalProducts);
  const handleUpdateTotalProducts = async () => {
    try {
      const response = await Axios.get(`/offers/check/${offer.offerId}`);
      const updatedOffer = response.data;
      setTotalProducts(updatedOffer.totalProducts);
      return setSnackbar({
        open: true,
        message: 'A promoção foi atualizada nos produtos',
        type: 'success',
      });
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao atualizar a promoção nos produtos',
        error,
      });
    }
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">{offer.name}</Typography>
            </Grid>
            <Grid item>
              <LimitTypography
                text={
                  offer.offerForCode
                    ? `${offer.offerForCode} - ${offer.description}`
                    : offer.description
                }
                maxLength={55}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Produtos totais</Typography>
                            </Grid>
                            <Grid container className={classes.gridHeight} alignItems="center">
                              <Typography>{totalProducts}</Typography>
                              {totalProducts < 1000 ? (
                                <IconButton size="small" onClick={handleUpdateTotalProducts}>
                                  <RefreshIcon />
                                </IconButton>
                              ) : (
                                <IconButton size="small" disabled>
                                  <RefreshIcon />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Tipo</Typography>
                            </Grid>
                            <Grid container className={classes.gridHeight} alignItems="center">
                              <Typography>{offer.type}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={offer.status === 'Ativo' ? 'primary' : 'disabled'}
                                />
                                <Typography variant="caption" color="textPrimary">
                                  {offer.status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {offer.offerFor === 'payment' ? (
                      <Grid item xs={4}>
                        <Grid container>
                          <Grid item>
                            <Grid container direction="column">
                              <Grid item>
                                <Typography variant="caption">Marketplaces</Typography>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  className={classes.gridHeight}
                                >
                                  <FiberManualRecordIcon
                                    color={offer.considerOnMarketplaces ? 'primary' : 'disabled'}
                                  />
                                  <Typography variant="caption" color="textPrimary">
                                    {offer.considerOnMarketplaces
                                      ? 'Considerado'
                                      : 'Não considerado'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      <Chip label={`Quantidade mínima: ${offer.quantity}`} />
                    </Grid>
                    <Grid item>
                      <Chip label={`Início: ${formatDate(offer.startDate)}`} />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={`Término: ${
                          offer.endDate ? formatDate(offer.endDate) : 'Data indefinida'
                        }`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" style={{ color: '#ff0000' }}>
                -{offer.discountPercentage}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        <Box className={classes.boxDeleteIcon}>
          <Tooltip
            title={<Typography align="center">Excluir permanentemente a promoção</Typography>}
          >
            <IconButton size="small" onClick={handleOpenDialogConfirmDeleteOffer}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {openDialogConfirmDeleteOffer && (
        <SimpleDialog
          cancelButtonText="Cancelar"
          content={
            <Grid container>
              <Grid item>
                <Typography align="center">Você está prestes a excluir uma promoção.</Typography>
                <Typography align="center">Esta ação não poderá ser desfeita.</Typography>
              </Grid>
            </Grid>
          }
          confirmButtonText="Excluir"
          dialogTitle="Excluir promoção"
          handleCancelButton={handleCloseDialogConfirmDeleteOffer}
          handleClose={handleCloseDialogConfirmDeleteOffer}
          handleConfirmButton={handleConfirmDeleteOffer}
          openDialog={openDialogConfirmDeleteOffer}
        />
      )}
    </Paper>
  );
}

export default OfferPaper;
