const newEntryOrder = {
  supplier: {
    cnpj: '',
    ie: '',
    name: '',
    razaoSocial: '',
  },
  products: [],
  totalValueProducts: 0,
  totalValue: 0,
  shipping: {
    payer: '',
    value: 0,
    address: {},
    deliveryRange: {},
  },
  status: 'Orçamento',
  statusPending: '',
  company: null,
  items: [],
  type: '',
  payments: [],
  othersIds: {},
  stockMovement: false,
  itemsTotal: 0,
  orderTotal: 0,
};

export default newEntryOrder;
