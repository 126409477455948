import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { getShopeeBrands } from '../../../../../services/melhorGestao/shopee';

function ShopeeBrandsAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedBrand,
  companyId,
  categoryId,
  label = 'Marca',
  disabled,
  error,
  variant,
  className,
}) {
  const [brandList, setBrandList] = useState([]);
  const [loadingBrandList, setLoadingBrandList] = useState(false);

  const filterBrand = createFilterOptions({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    if (categoryId) {
      getShopeeBrands({
        companyId,
        categoryId,
        setBrandList,
        setLoadingBrandList,
        setSnackbar,
      });
    }
  }, [categoryId]);

  return (
    <Autocomplete
      size="small"
      options={brandList}
      filterOptions={filterBrand}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <Typography {...{ ...props, key: option.id }} variant="caption">
          {option.name}
        </Typography>
      )}
      disabled={disabled}
      value={selectedBrand}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      loading={loadingBrandList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(ShopeeBrandsAutocomplete);
