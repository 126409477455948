import { Tooltip, Typography } from '@mui/material';
import React from 'react';

function LimitTypography({ style, text, maxLength = 60, additionalText, variant }) {
  if (!text) {
    return null;
  }

  return text.length > maxLength ? (
    <Tooltip title={<Typography variant={variant}>{text}</Typography>}>
      <Typography style={style} variant={variant}>{`${text
        .substring(0, maxLength)
        .trim()}...`}</Typography>
    </Tooltip>
  ) : (
    <Typography style={style} variant={variant}>
      {text}
      {additionalText ? ` ${additionalText}` : null}
    </Typography>
  );
}

export default LimitTypography;
