import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import Footer from './Footer';
import TableItems from './TableItems';

const styles = StyleSheet.create({
  text: {
    fontSize: 9,
  },
});

function CompleteOrderPrint({ order, items, devolutionsTotal, pageNumber, pageQuantity }) {
  const ordemItems = [];

  items.forEach((item) => {
    if (!item.isDevolution) {
      ordemItems.push(item);
    }
  });

  const devolutionItems = [];

  items.forEach((item) => {
    if (item.isDevolution) {
      devolutionItems.push(item);
    }
  });
  return (
    <>
      <TableItems items={ordemItems} />
      {devolutionItems.length > 0 ? (
        <>
          <Text style={styles.text}>Itens devolvidos</Text>
          <TableItems items={devolutionItems} />
        </>
      ) : null}
      <Footer
        order={order}
        pageNumber={pageNumber}
        pageQuantity={pageQuantity}
        devolutionsTotal={devolutionsTotal}
      />
    </>
  );
}

export default CompleteOrderPrint;
