import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import {
  getMercadoLivreCategoryChildren,
  getMercadoLivreCategoryPrediction,
  getMercadoLivreCategoryRoot,
} from '../../../../../../services/melhorGestao/mercadoLivre';
import SearchField from '../../../../../Common/SearchField';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../../../helpers/formatData';

function MercadoLivreCategoryDialog({ openDialog, handleClose, handleClick, adForm }) {
  const [step, setStep] = useState(1);
  const [categoriesRoot, setCategoriesRoot] = useState([]);
  const [selectedCategoryRoot, setSelectedCategoryRoot] = useState();
  const [categoriesChildren, setCategoriesChildren] = useState([]);
  const [loadingChildrenCategory, setLoadingChildrenCategory] = useState(false);
  const [predictedCategoryList, setPredictedCategoryList] = useState([]);
  const [loadingPredictCategory, setLoadingPredictCategory] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePredictCategory = async (searchText) => {
    try {
      if (adForm.companies.length > 0) {
        setLoadingPredictCategory(true);
        setErrorMessage('');

        const getCategoryList = await getMercadoLivreCategoryPrediction(
          adForm.companies[0],
          searchText,
        );

        const pathCategoryList = [];

        getCategoryList.forEach((category) => {
          pathCategoryList.push({
            ...category,
            path: category.pathFromRoot.map((path) => path.name).join(' / '),
          });
        });

        setPredictedCategoryList(pathCategoryList);
        setLoadingPredictCategory(false);
      }
    } catch (error) {
      setPredictedCategoryList([]);
      setLoadingPredictCategory(false);
      formatHandleError({
        setSnackbar: ({ message }) => setErrorMessage(message),
        defaultMessage: 'Algum erro ocorreu ao buscar categorias no Mercado Livre',
        error,
      });
    }
  };

  useEffect(() => {
    handlePredictCategory(adForm.title);
  }, []);

  const handleSelectCategoryManually = async () => {
    try {
      setStep(2);

      const categoriesList = await getMercadoLivreCategoryRoot();
      return setCategoriesRoot(categoriesList);
    } catch (error) {
      return formatHandleError({
        setSnackbar: ({ message }) => setErrorMessage(message),
        defaultMessage: 'Algum erro ocorreu ao buscar categorias no Mercado Livre',
        error,
      });
    }
  };

  const selectRootCategory = async (category) => setSelectedCategoryRoot(category);

  const getChildrenCategories = async (category) => {
    try {
      if (adForm.companies.length > 0) {
        setLoadingChildrenCategory(true);
        setStep(3);

        const categoriesList = await getMercadoLivreCategoryChildren(
          adForm.companies[0],
          category.id,
        );

        if (categoriesList.childrenCategories.length > 0) {
          setLoadingChildrenCategory(false);
          return setCategoriesChildren(categoriesList.childrenCategories);
        }

        setLoadingChildrenCategory(false);
        return handleClick(category);
      }
      return null;
    } catch (error) {
      setLoadingChildrenCategory(false);
      return formatHandleError({
        setSnackbar: ({ message }) => setErrorMessage(message),
        defaultMessage: 'Algum erro ocorreu ao buscar categorias no Mercado Livre',
        error,
      });
    }
  };

  useEffect(() => {
    if (selectedCategoryRoot) {
      getChildrenCategories(selectedCategoryRoot);
    }
  }, [selectedCategoryRoot]);

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleClose}
      dialogTitle="Categoria do anúncio"
      dialogText="Pesquisar categoria do Mercado Livre"
      content={
        <>
          {step === 1 && (
            <Grid container spacing={2} direction="column">
              <Grid item>
                <SearchField
                  submitSearch={handlePredictCategory}
                  labelSearch="Pesquisar categoria"
                  preSearchText={adForm.title}
                />
              </Grid>
              {!loadingPredictCategory && predictedCategoryList.length > 0
                ? predictedCategoryList.map((category) => (
                    <Grid key={category.id} item>
                      <Grid container direction="column">
                        <Grid item>
                          <Button
                            onClick={() => getChildrenCategories(category)}
                            variant="contained"
                            color="secondary"
                          >
                            {category.name}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">{category.path}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : null}
              {predictedCategoryList.length === 0 && !errorMessage && !loadingPredictCategory ? (
                <Grid item>
                  <Typography>Nenhuma categoria encontrada</Typography>
                </Grid>
              ) : null}
              {loadingPredictCategory ? (
                <Grid item>
                  <CircularProgress />
                </Grid>
              ) : null}
              <Grid item>
                <Typography>
                  Caso nenhuma das categorias sugeridas sejam adequadas para este anúncio clique
                  abaixo para escolher a categoria manualmente.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleSelectCategoryManually}>
                  Escolher categoria manulmente
                </Button>
              </Grid>
              {errorMessage ? (
                <Grid item>
                  <Typography color="error">{errorMessage}</Typography>
                </Grid>
              ) : null}
            </Grid>
          )}

          {step === 2 && (
            <Grid container spacing={1} direction="column">
              {categoriesRoot.length > 0 ? (
                <Grid item>
                  <Typography>Quais das opções descreve melhor o anúncio?</Typography>
                </Grid>
              ) : (
                <Grid item>
                  <CircularProgress />
                </Grid>
              )}
              {categoriesRoot.length > 0 &&
                categoriesRoot.map((category) => (
                  <Grid key={category.name} item>
                    <Button
                      onClick={() => selectRootCategory(category)}
                      variant="contained"
                      color="secondary"
                    >
                      {category.name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          )}

          {step === 3 && (
            <Grid container spacing={1} direction="column">
              {loadingChildrenCategory ? (
                <Grid item>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item>
                  <Typography>Quais das opções descreve melhor o anúncio?</Typography>
                </Grid>
              )}
              {!loadingChildrenCategory &&
                categoriesChildren.length > 0 &&
                categoriesChildren.map((category) => (
                  <Grid key={category.name} item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => getChildrenCategories(category)}
                    >
                      {category.name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      }
      maxWidth="sm"
    />
  );
}

export default memo(MercadoLivreCategoryDialog);
