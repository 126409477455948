import Axios from 'axios';

export const isURL = async (value) => {
  try {
    const response = await Axios.patch('/validator/url', {
      value,
    });

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao validar a URL');
    return false;
  }
};

export const isEmail = async (value) => {
  try {
    const response = await Axios.patch('/validator/email', {
      value,
    });

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao validar o E-mail');
    return false;
  }
};

export const isEAN = async (value) => {
  try {
    const response = await Axios.patch('/validator/ean', {
      value,
    });

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao validar o código EAN');
    return false;
  }
};

export const isCMC7 = async (value) => {
  try {
    const response = await Axios.patch('/validator/cmc7', {
      value,
    });

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao validar o código CMC7');
    return false;
  }
};

export const isValidIe = async (ie, uf, pd) => {
  try {
    const response = await Axios.patch('/validator/ie', {
      ie,
      uf,
      pd,
    });

    return response.data;
  } catch (error) {
    console.log(
      error.response?.data?.message || 'Algum erro ocorreu ao validar a Inscrição Estadual',
    );
    return false;
  }
};
