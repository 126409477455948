import { Alert, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import newAddress from '../../../../constant/newAddress';
import { formatHandleError, onlyNumbersValue } from '../../../../helpers/formatData';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';

function AddressDialog({
  editAddress,
  openDialogAddress,
  handleCloseDialogAddress,
  handleAddAddress,
}) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [address, setAddress] = useState(editAddress || newAddress);

  const handleConfirm = useCallback(() => {
    handleAddAddress({ address });
    return handleCloseDialogAddress();
  }, [address]);

  const handleChange = (event) => {
    let fieldValue = event.target.value;
    const fieldName = event.target.name;

    if (fieldName === 'postalCode') {
      fieldValue = onlyNumbersValue(event.target.value);
    }

    setAddress((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const [loadingViaCep, setLoadingViaCep] = useState(false);

  const getViaCep = useCallback(async () => {
    try {
      const onlyNumbersAddress = onlyNumbersValue(address.postalCode);
      if (onlyNumbersAddress.length === 8) {
        setLoadingViaCep(true);
        const response = await Axios.get(`/users/viacep/${onlyNumbersAddress}`);
        const { addressData } = response.data;
        setAddress((oldFields) => ({
          ...oldFields,
          complement: oldFields.complement ? oldFields.complement : addressData.complemento,
          street: addressData.logradouro,
          city: addressData.localidade,
          state: addressData.uf,
          district: addressData.bairro,
        }));
        setLoadingViaCep(false);
      }
    } catch (error) {
      setLoadingViaCep(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao buscar o endereço',
        error,
      });
    }
  }, [address.postalCode]);

  useEffect(() => {
    getViaCep();
  }, [address.postalCode]);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);

  useEffect(() => {
    if (address && address.street && address.streetNumber && address.state && address.city) {
      setDisableConfirmButton(false);
    } else {
      setDisableConfirmButton(true);
    }
  }, [address]);

  return (
    <SimpleDialog
      disableConfirmButton={disableConfirmButton}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      maxWidth="sm"
      content={
        <Grid container direction="row" spacing={1}>
          <Grid item xs={3}>
            <Grid container direction="column">
              <CustomInput
                label="CEP"
                length="8"
                value={onlyNumbersValue(address.postalCode)}
                onChange={handleChange}
                name="postalCode"
              />
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container direction="column">
              <CustomInput
                label="Logradouro"
                value={address.street}
                onChange={handleChange}
                name="street"
              />
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container direction="column">
              <CustomInput
                label="Número"
                value={address.streetNumber}
                onChange={handleChange}
                name="streetNumber"
              />
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container direction="column">
              <CustomInput label="UF" value={address.state} onChange={handleChange} name="state" />
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid container direction="column">
              <CustomInput
                label="Cidade"
                value={address.city}
                onChange={handleChange}
                name="city"
              />
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid container direction="column">
              <CustomInput
                label="Bairro"
                value={address.district}
                onChange={handleChange}
                name="district"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column">
              <CustomInput
                label="Complemento"
                value={address.complement}
                onChange={handleChange}
                name="complement"
              />
            </Grid>
          </Grid>

          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}

          <SimpleBackdrop loading={loadingViaCep} />
        </Grid>
      }
      dialogTitle="Adicionar endereço"
      handleCancelButton={handleCloseDialogAddress}
      handleClose={handleCloseDialogAddress}
      handleConfirmButton={handleConfirm}
      openDialog={openDialogAddress}
    />
  );
}

export default AddressDialog;
