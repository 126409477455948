import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

const searchTemplatesEmails = async ({
  limit = 20,
  skip = 0,
  setLoadingTemplatesEmailsList = () => {},
  setTemplatesEmailsList,
  setTotalTemplatesEmails,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingTemplatesEmailsList(true);

    const response = await Axios.get('/emails', {
      params: {
        skip,
        limit,
      },
    });

    const { totalEmails, templatesEmails } = response.data;

    setTemplatesEmailsList(templatesEmails);
    setTotalTemplatesEmails(totalEmails);

    return setLoadingTemplatesEmailsList(false);
  } catch (error) {
    setLoadingTemplatesEmailsList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar os templates de e-mails',
      error,
    });
  }
};

export default searchTemplatesEmails;
