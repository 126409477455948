import { Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { formatDate, numberToRealWithPeriod } from '../../../../helpers/formatData';

const useStyles = makeStyles({
  gridCheque: {
    position: 'relative',
    marginBottom: 9,
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
  },
  gridChequeButtons: {
    position: 'absolute',
    right: 5,
  },
});

function ChequeCard({ cheque, chequeIndex, handleDeleteCheque = () => {} }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.gridCheque}>
      {chequeIndex !== undefined ? (
        <Grid item className={classes.gridChequeButtons}>
          <IconButton
            size="small"
            onClick={() => {
              handleDeleteCheque({ deleteIndex: chequeIndex });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      ) : null}
      <Grid container justifyContent="space-between">
        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="caption">Banco</Typography>
            <Typography>{cheque.bankNumber}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid container direction="column">
            <Typography variant="caption">Agência</Typography>
            <Typography>{cheque.agency}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Grid container direction="column">
            <Typography variant="caption">Conta</Typography>
            <Typography>{cheque.account}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container direction="column">
            <Typography variant="caption">Cheque Nº</Typography>
            <Typography>{cheque.chequeNumber}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="column">
            <Typography variant="caption">Código de Barra</Typography>
            <Typography>{cheque.barCode}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="column">
            <Typography variant="caption">Titular</Typography>
            <Typography>{cheque.name}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container direction="column">
            <Typography variant="caption">Valor</Typography>
            <Typography>R$ {numberToRealWithPeriod(cheque.value)}</Typography>
          </Grid>
        </Grid>

        {cheque.goodFor ? (
          <Grid item xs={4}>
            <Grid container direction="column">
              <Typography variant="caption">Bom para</Typography>
              <Typography>{formatDate(cheque.goodFor)}</Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default ChequeCard;
