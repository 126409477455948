const checkConsiderOffersOnMarketplaces = (item) => {
  let selectedPrice = parseFloat(
    (item.offer && item.offerPrice > 0 ? item.offerPrice : item.price).toFixed(2),
  );

  const getPaymentMethodOffers = item.offers.filter(
    (offer) => offer.offerFor === 'payment' && !!offer.considerOnMarketplaces,
  );

  if (getPaymentMethodOffers.length > 0) {
    const offerWithMaxPercentage = getPaymentMethodOffers.reduce((a, b) =>
      a.discountPercentage > b.discountPercentage ? a : b,
    );

    const selectedPriceWithPaymentOffer =
      selectedPrice * (1 - offerWithMaxPercentage.discountPercentage / 100);

    if (selectedPriceWithPaymentOffer < selectedPrice) {
      selectedPrice = parseFloat(selectedPriceWithPaymentOffer.toFixed(2));
    }
  }

  return selectedPrice;
};

export default checkConsiderOffersOnMarketplaces;
