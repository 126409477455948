const filterProductOptionsInitial = [
  {
    id: 'duplicateStockMovements',
    description: 'Movimentações duplicadas',
    active: false,
  },
  {
    id: 'showOnlyWithoutImages',
    description: 'Produtos sem imagens',
    active: false,
  },
  {
    id: 'showOnlyWithLessThanThreeImages',
    description: 'Menos de três imagens',
    active: false,
  },
  {
    id: 'showOnlyWithoutCategory',
    description: 'Produtos sem categoria',
    active: false,
  },
  {
    id: 'showOnlyWithoutSubCategory',
    description: 'Sem sub-categoria',
    active: false,
  },
  {
    id: 'showOnlyWithoutDescription',
    description: 'Produtos sem descrição',
    active: false,
  },
  {
    id: 'incompleteFeatures',
    description: 'Poucas características',
    active: false,
  },
  {
    id: 'noFeatures',
    description: 'Sem características',
    active: false,
  },
  {
    id: 'descriptionToReview',
    description: 'Descrição a revisar',
    active: false,
  },
  {
    id: 'weightToReview',
    description: 'Peso a revisar',
    active: false,
  },
  {
    id: 'priceToReview',
    description: 'Preço a revisar',
    active: false,
  },
];

export default filterProductOptionsInitial;
