import React, { useState } from 'react';
import { Alert, Button, Grid, Snackbar, TextField } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import { newMarkExpressoCity, weekDays } from '../../../../../../constant/availableIntegrations';
import CustomInput from '../../../../../CustomInput';
import UserAutocomplete from '../../../../../Common/UserAutocomplete';
import FilterSelectOptions from '../../../../../Common/FilterSelectOptions';
import { formatHandleError } from '../../../../../../helpers/formatData';

const useStyles = makeStyles({
  cursorPointer: {
    cursor: 'pointer',
  },
});

function CityConfiguration({ selectedCity }) {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'error',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      message: '',
      open: false,
    }));
  };

  const [cityConfiguration, setCityConfiguration] = useState(selectedCity || newMarkExpressoCity);

  const [driverConfiguration, setDriverConfiguration] = useState({
    user: null,
    weekDays: [],
  });

  const handleChangeFieldValue = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setCityConfiguration((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const handleChangeDriver = (_, newValue) => {
    setDriverConfiguration((oldFields) => ({
      ...oldFields,
      user: newValue,
    }));
  };

  const handleChangeWeekDays = (options) => {
    const formattedWeekDays = options
      .filter((option) => option.active)
      .map((option) => option.name);

    setDriverConfiguration((oldFields) => ({
      ...oldFields,
      weekDays: formattedWeekDays,
    }));
  };

  const handleAddNewDriver = () => {
    if (!driverConfiguration.user || !driverConfiguration.weekDays.length) {
      return setSnackbar({
        open: true,
        message: 'Preecha todos os campos corretamente',
        type: 'error',
      });
    }

    if (
      cityConfiguration.drivers.findIndex(
        (driver) => driver.user._id === driverConfiguration.user._id,
      ) >= 0
    ) {
      return setSnackbar({
        open: true,
        message: `O motorista ${driverConfiguration.user.name.split(' ')[0]} já está cadastrado`,
        type: 'error',
      });
    }

    setCityConfiguration((oldFields) => ({
      ...oldFields,
      drivers: [...oldFields.drivers, driverConfiguration],
    }));

    return setDriverConfiguration({
      user: null,
      weekDays: [],
    });
  };

  const handleRemoveDriver = (_id) => {
    const updatedDriversCityConfiguration = cityConfiguration.drivers.filter(
      (driver) => driver.user._id !== _id,
    );

    setCityConfiguration((oldFields) => ({
      ...oldFields,
      drivers: updatedDriversCityConfiguration,
    }));
  };

  const handleSaveCityConfigurations = async () => {
    if (
      !cityConfiguration.cityName ||
      !cityConfiguration.startCep ||
      !cityConfiguration.endCep ||
      !cityConfiguration.drivers.length
    ) {
      return setSnackbar({
        open: true,
        message: 'Preencha todos os campos corretamente',
        type: 'error',
      });
    }

    try {
      if (cityConfiguration?.cityId) {
        const response = await Axios.put(
          `/own-shipping/${cityConfiguration.cityId}`,
          cityConfiguration,
        );

        setCityConfiguration(response.data);
        return setSnackbar({
          open: true,
          message: `Configurações da rota de ${cityConfiguration.cityName} atualizadas com sucesso`,
          type: 'success',
        });
      }

      const response = await Axios.post('/own-shipping', cityConfiguration);
      setCityConfiguration(response.data);
      return setSnackbar({
        open: true,
        message: `Configurações da rota de ${cityConfiguration.cityName} cadastradas com sucesso`,
        type: 'success',
      });
    } catch (error) {
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar os dados da cidade',
        error,
      });
    }
  };

  const generateAcronyms = (weekDaysPerDriver) => {
    const weekDaysAcronyms = [];

    weekDaysPerDriver.forEach((weekDay) => {
      weekDaysAcronyms.push(weekDay.slice(0, 3));
    });

    return weekDaysAcronyms.join(', ');
  };

  return (
    <>
      <Grid item>
        <Grid container justifyContent="flex-end" rowSpacing={1}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CustomInput
                  value={cityConfiguration.cityName}
                  label="Nome da Cidade"
                  variant="outlined"
                  name="cityName"
                  onChange={handleChangeFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomInput
                      value={cityConfiguration.startCep}
                      label="CEP Inicial"
                      variant="outlined"
                      name="startCep"
                      onChange={handleChangeFieldValue}
                      number
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      value={cityConfiguration.endCep}
                      label="CEP Final"
                      variant="outlined"
                      name="endCep"
                      onChange={handleChangeFieldValue}
                      number
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <UserAutocomplete
                      label="Motorista"
                      roles={['staff']}
                      selectedUser={driverConfiguration.user}
                      handleOnChange={handleChangeDriver}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FilterSelectOptions
                      inputLabel="Dias da semana"
                      filtersOptions={weekDays}
                      setFiltersOptions={handleChangeWeekDays}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button onClick={handleAddNewDriver} variant="contained">
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {cityConfiguration?.drivers?.length
                ? cityConfiguration.drivers.map((driver) => (
                    <Grid item xs={12} key={driver.user?._id ? driver.user._id : driver.user}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={5}>
                          <UserAutocomplete
                            label="Motorista"
                            roles={['staff']}
                            selectedUser={driver.user}
                            handleOnChange={handleChangeDriver}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            style={{ width: '100%' }}
                            value={generateAcronyms(driver.weekDays)}
                            disabled
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <DeleteForeverOutlined
                            onClick={() => handleRemoveDriver(driver.user._id)}
                            className={classes.cursorPointer}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
          <Grid item>
            <Button onClick={handleSaveCityConfigurations} variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default CityConfiguration;
