import { Box, FormControlLabel, Grid, Paper, Typography, Switch, Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { numberToRealWithPeriod } from '../../../../../../../helpers/formatData';
import ProfitabilityExamples from './ProfitabilityExamples';
import GraphBar from './GraphBar';

const useStyles = makeStyles({
  pricePaper: {
    height: 75,
    marginRight: 10,
    padding: 10,
    cursor: 'pointer',
  },
  priceTypography: {
    fontSize: '1.30rem',
  },
  smallTypography: {
    fontSize: '0.85rem',
  },
  offerLabelTypography: {
    color: '#fff',
  },
  offerPriceTypography: {
    color: '#fff',
    fontSize: '1.30rem',
  },
  offerSmallTypography: {
    color: '#fff',
    fontSize: '0.85rem',
  },
});

function PriceTab({
  selectedProduct,
  priceValue,
  costValue,
  difalValue,
  ipiValue,
  stValue,
  shippingValue,
  othersCostValue,
  simplesValue,
  profitValue,
  mlPriceValue,
  bouthCostValue,
  salesCostValue,
  handleChangePriceConfirmed,
  handleChangeHasAdditionalOnline,
  blockFields,
}) {
  const classes = useStyles();

  return (
    <Paper>
      <Box padding={2}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Box marginBottom={1}>
                      <Typography variant="h3">Preço de venda</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">{`R$ ${numberToRealWithPeriod(priceValue)}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      value={selectedProduct.priceConfirmed || false}
                      onClick={handleChangePriceConfirmed}
                      checked={selectedProduct.priceConfirmed || false}
                      color="primary"
                    />
                  }
                  label={
                    <Box>
                      {selectedProduct.priceConfirmed ? 'Preço confirmado' : 'Preço não confirmado'}
                    </Box>
                  }
                  disabled={blockFields}
                />
              </Grid>
            </Grid>
          </Grid>
          {priceValue ? (
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <GraphBar
                    priceValue={priceValue}
                    costValue={costValue}
                    difalValue={difalValue}
                    ipiValue={ipiValue}
                    stValue={stValue}
                    shippingValue={shippingValue}
                    othersCostValue={othersCostValue}
                    simplesValue={simplesValue}
                    profitValue={profitValue}
                  />
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <ProfitabilityExamples
                        selectedProduct={selectedProduct}
                        priceValue={priceValue}
                        simplesValue={simplesValue}
                        mlPriceValue={mlPriceValue}
                        bouthCostValue={bouthCostValue}
                      />
                    </Grid>
                    <Grid item>
                      <Box marginBottom={1} marginTop={4}>
                        <Tooltip
                          title={
                            <Typography align="center">Custo total sem margem de lucro</Typography>
                          }
                        >
                          <Paper
                            elevation={2}
                            className={classes.pricePaper}
                            sx={
                              selectedProduct.hasAdditionalOnline
                                ? { backgroundColor: 'primary.main' }
                                : null
                            }
                            onClick={handleChangeHasAdditionalOnline}
                          >
                            <Grid container direction="column">
                              <Grid item>
                                <Typography
                                  variant="caption"
                                  className={
                                    selectedProduct.hasAdditionalOnline
                                      ? classes.offerLabelTypography
                                      : null
                                  }
                                >
                                  Custo de venda
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="baseline">
                                  <Typography
                                    className={
                                      selectedProduct.hasAdditionalOnline
                                        ? classes.offerSmallTypography
                                        : classes.smallTypography
                                    }
                                  >
                                    R$
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    className={
                                      selectedProduct.hasAdditionalOnline
                                        ? classes.offerPriceTypography
                                        : classes.priceTypography
                                    }
                                  >
                                    {numberToRealWithPeriod(salesCostValue)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Paper>
  );
}

export default memo(PriceTab);
