import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { numberToRealWithPeriod } from '../../../../../helpers/formatData';

function ValueBlock({ title, value, quantity }) {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
              <Grid item>
                <Box marginRight="3px">
                  <Typography variant="overline">R$</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography>{` ${numberToRealWithPeriod(value)}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Registros</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
              <Grid item>
                <Typography>{quantity}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ValueBlock;
