import { Grid, Typography } from '@mui/material';
import React, { useState, memo, useEffect } from 'react';
import {
  getMercadoLivreProduct,
  updateMercadoLivreAdStatus,
} from '../../../../../services/melhorGestao/mercadoLivre';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import ProductAutocomplete from '../../../../Common/ProductAutocomplete';
import { getProduct } from '../../../../../services/melhorGestao/products';
import { formatHandleError, numberToRealWithPeriod } from '../../../../../helpers/formatData';
import SimpleImage from '../../../../Common/SimpleImage';

function DialogMercadoLivreAdStatus({
  setRefreshSearch,
  openDialogMercadoLivreAdStatus,
  handleCloseDialogMercadoLivreAdStatus,
  selectedAd,
  setSnackbar,
}) {
  const [loadingChangeMercadoLivreAdStatus, setLoadingChangeMercadoLivreAdStatus] = useState(false);
  const [quantityMercadoLivreAdStatus, setQuantityMercadoLivreAdStatus] = useState(
    selectedAd.quantity || 1,
  );

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [mercadoLivreSellerSku, setMercadoLivreSellerSku] = useState(null);
  const [mercadoLivreGtin, setMercadoLivreGtin] = useState(null);
  const [mercadoLivreBrand, setMercadoLivreBrand] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedAd?.productId) {
          const product = await getProduct(selectedAd.productId);
          setSelectedProduct(product);
        } else {
          const mercadoLivreProduct = await getMercadoLivreProduct(selectedAd.mlb);

          const mlSellerSku = mercadoLivreProduct?.attributes?.find(
            (attribute) => attribute.id === 'SELLER_SKU',
          );

          if (mlSellerSku?.value_name) {
            setMercadoLivreSellerSku(mlSellerSku.value_name);
            const product = await getProduct(mlSellerSku.value_name);
            setSelectedProduct(product);
          }

          const mlGtin = mercadoLivreProduct?.attributes?.find(
            (attribute) => attribute.id === 'GTIN',
          );

          if (mlGtin?.value_name) {
            if (!mlSellerSku?.value_name) {
              const product = await getProduct(mlGtin.value_name);
              setSelectedProduct(product);
            }
            setMercadoLivreGtin(mlGtin.value_name);
          }

          const mkBrand = mercadoLivreProduct?.attributes?.find(
            (attribute) => attribute.id === 'BRAND',
          );

          if (mkBrand?.value_name) {
            setMercadoLivreBrand(mkBrand.value_name);
          }
        }
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar produto',
          error,
        });
      }
    };
    fetchData();
  }, []);

  const handleChangeMercadoLivreAdStatus = async () => {
    await updateMercadoLivreAdStatus({
      mercadoLivreAdStatus: {
        mlb: selectedAd.mlb,
        productId: selectedProduct?.productId,
        quantity: quantityMercadoLivreAdStatus,
        offerPrice: selectedAd.offerPrice,
      },
      setLoadingChangeMercadoLivreAdStatus,
    });

    setRefreshSearch(Math.random());
    return handleCloseDialogMercadoLivreAdStatus();
  };

  const handleProductOnChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  return (
    <SimpleDialog
      cancelButtonText="Cancelar"
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container>
              <Grid item xs={2}>
                <SimpleImage
                  src={selectedAd.thumbnail}
                  imgHeight={50}
                  imgWidth={50}
                  alt={selectedAd.title}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Título do anúncio</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{selectedAd.title}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Preço</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>R$ {numberToRealWithPeriod(selectedAd.price)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">SKU</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{mercadoLivreSellerSku || 'Não informado'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">GTIN</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{mercadoLivreGtin || 'Não informado'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Marca</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{mercadoLivreBrand || 'Não informado'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption">Catálogo</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{selectedAd.catalogListing ? 'Sim' : 'Não'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <CustomInput
                  label="Kit com"
                  value={quantityMercadoLivreAdStatus}
                  onChange={(event) => setQuantityMercadoLivreAdStatus(event.target.value)}
                  size="medium"
                  number
                />
              </Grid>
              <Grid item xs={10}>
                <ProductAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleProductOnChange}
                  selectedProduct={selectedProduct}
                  variant="standard"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          {selectedProduct ? (
            <Grid item>
              <Grid container>
                <Grid item xs={2}>
                  {selectedProduct?.images?.length > 0 ? (
                    <SimpleImage
                      src={selectedProduct.images[0].src}
                      height={50}
                      width={50}
                      imgHeight={50}
                      imgWidth={50}
                      alt={selectedProduct.name}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={10}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">Nome técnico</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedProduct.technicalName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {selectedProduct ? (
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">Preço</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        R${' '}
                        {numberToRealWithPeriod(
                          selectedProduct.offer
                            ? selectedProduct.offerPrice
                            : selectedProduct.price,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">SKU</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedProduct.productId}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">GTIN</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedProduct.othersIds?.gtin || 'Não informado'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">Marca</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{selectedProduct.brand || 'Não informado'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">Unidade</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {selectedProduct.taxFields?.unity?.name || 'Não informado'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      }
      maxWidth="sm"
      confirmButtonText="Confirmar"
      dialogText="Informe o produto que pertence o anúncio e digite a quantidade deste produto no anúncio."
      dialogTitle="Sincronizar anúncio"
      handleCancelButton={handleCloseDialogMercadoLivreAdStatus}
      handleClose={handleCloseDialogMercadoLivreAdStatus}
      handleConfirmButton={handleChangeMercadoLivreAdStatus}
      confirmButtonLoading={loadingChangeMercadoLivreAdStatus}
      openDialog={openDialogMercadoLivreAdStatus}
    />
  );
}

export default memo(DialogMercadoLivreAdStatus);
