import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';
import { getUser, getUsers } from './users';

export const searchOrders = async ({
  limit = 20,
  skip = 0,
  searchText = '',
  sortBy = 'createdAt',
  sortDirection = 'desc',
  setLoadingOrdersList,
  setOrdersList,
  setCountOrders = () => {},
  filterByUserId,
  filtersOptions = [],
  filterOrderSource,
  filterOrderStatus,
  filterOrderCompany,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingOrdersList(true);
    const ordersWithOfficialUsers = [];

    const response = await Axios.get('/orders/search', {
      params: {
        limit,
        skip,
        searchText,
        sortBy,
        sortDirection,
        filterByUserId,
        filtersOptions,
        filterOrderSource,
        filterOrderStatus,
        filterOrderCompany,
      },
    });

    const { totalResults, ordersList } = response.data;

    const userIds = [];

    ordersList.forEach((order) => {
      if (order.customer && order.customer.userId) {
        const alreadyExistsUserId = userIds.find((userId) => userId === order.customer.userId);

        if (!alreadyExistsUserId) {
          userIds.push(order.customer.userId);
        }
      }
      if (order.seller) {
        const alreadyExistsSellerId = userIds.find((sellerId) => sellerId === order.seller);

        if (!alreadyExistsSellerId) {
          userIds.push(order.seller);
        }
      }
    });

    const users = await getUsers(userIds);
    const findByUserId = (userId) => users.find((user) => user.userId === userId);
    const findBySellerId = (sellerId) => users.find((user) => user._id === sellerId);

    ordersList.forEach((order) => {
      const othersDocuments = [];
      let officialDocument = {};

      if (order.customer && order.customer.userId) {
        const user = findByUserId(parseInt(order.customer.userId, 10));

        if (user) {
          officialDocument = {
            name: user.name,
          };

          if (user.documents) {
            if (user.documents.pessoa === 'PF') {
              officialDocument = {
                ...officialDocument,
                cpf: user.documents.cpf,
              };
            }
            if (user.documents.pessoa === 'PJ') {
              officialDocument = {
                ...officialDocument,
                cnpj: user.documents.cnpj,
              };
            }

            if (user.documents.othersDocuments) {
              othersDocuments.push(...user.documents.othersDocuments);
            }
          }
        }
      } else if (order.customer) {
        officialDocument = {
          name: order.customer.name,
        };

        if (order.customer.documents) {
          if (order.customer.documents.pessoa === 'PF') {
            officialDocument = {
              ...officialDocument,
              cpf: order.customer.documents.cpf,
            };
          }
          if (order.customer.documents.pessoa === 'PJ') {
            officialDocument = {
              ...officialDocument,
              cnpj: order.customer.documents.cnpj,
            };
          }

          if (order.customer.documents.othersDocuments) {
            othersDocuments.push(...order.customer.documents.othersDocuments);
          }
        }
      }

      ordersWithOfficialUsers.push({
        ...order,
        seller: findBySellerId(order.seller),
        customer: {
          ...order.customer,
          documents: {
            ...order.customer.documents,
            othersDocuments,
          },
          officialDocument,
        },
      });
    });

    setOrdersList(ordersWithOfficialUsers);
    setCountOrders(totalResults);

    return setLoadingOrdersList(false);
  } catch (error) {
    setLoadingOrdersList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao pesquisar os pedidos',
      error,
    });
  }
};

export const getOrder = async (orderId) => {
  try {
    const response = await Axios.get(`/orders/${orderId}`);
    const { order } = response.data;

    if (order.customer && order.customer.userId) {
      const user = await getUser(order.customer.userId);

      const othersDocuments = [];
      let officialDocument = {
        name: user.name,
      };

      if (user.documents) {
        if (user.documents.pessoa === 'PF') {
          officialDocument = {
            ...officialDocument,
            cpf: user.documents.cpf,
          };
        }
        if (user.documents.pessoa === 'PJ') {
          officialDocument = {
            ...officialDocument,
            cnpj: user.documents.cnpj,
          };
        }

        if (user.documents.othersDocuments) {
          othersDocuments.push(...user.documents.othersDocuments);
        }
      }
      return {
        ...order,
        customer: {
          ...order.customer,
          documents: {
            ...order.customer.documents,
            othersDocuments,
          },
          officialDocument,
        },
      };
    }

    return order;
  } catch (error) {
    formatHandleError({
      setSnackbar: (snack) => console.log(snack.message),
      defaultMessage: 'Algum erro ocorreu ao buscar o pedido',
      error,
    });

    return null;
  }
};

export const updateOrder = async ({
  order,
  setLoadingUpdateOrder = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingUpdateOrder(true);

    const response = await Axios.put(`/orders/protect/${order.orderId}`, {
      order,
    });

    setLoadingUpdateOrder(false);
    return response.data.updatedOrder;
  } catch (error) {
    setLoadingUpdateOrder(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar o pedido',
      error,
    });

    return null;
  }
};

export const changeOrderStatus = async ({
  orderId,
  userName = 'Administrador',
  toStatus,
  reason,
  setLoadingUpdateOrder = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingUpdateOrder(true);

    const response = await Axios.put(`/orders/protect/status/${orderId}`, {
      userName,
      toStatus,
      reason,
    });

    setLoadingUpdateOrder(false);
    return response.data.updatedOrder;
  } catch (error) {
    setLoadingUpdateOrder(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao mudar o status do pedido',
      error,
    });

    return null;
  }
};

export const changeEntryOrderStatus = async ({
  entryOrderId,
  toStatus,
  reason,
  setLoadingUpdateOrder = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingUpdateOrder(true);

    const response = await Axios.put(`/entries/status/${entryOrderId}`, {
      toStatus,
      reason,
    });

    setLoadingUpdateOrder(false);
    return response.data.updatedEntryOrder;
  } catch (error) {
    setLoadingUpdateOrder(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao mudar o status da entrada',
      error,
    });

    return null;
  }
};

export const createOrder = async ({ order, setLoadingSaveOrder, setSnackbar = () => {} }) => {
  try {
    setLoadingSaveOrder(true);

    const response = await Axios.post('/orders/protect', {
      order,
    });

    setLoadingSaveOrder(false);
    return response.data.newOrder;
  } catch (error) {
    setLoadingSaveOrder(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar o pedido',
      error,
    });

    return null;
  }
};

export const createDevolution = async ({ devolution, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/devolution', {
      orderId: devolution.orderId,
      items: devolution.items,
    });

    return response.data.devolution;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar devolução',
      error,
    });

    return null;
  }
};

export const getDevolution = async ({
  orderId,
  setLoadindgDevolution,
  setDevolution,
  setSnackbar = () => {},
}) => {
  try {
    setLoadindgDevolution(true);

    const response = await Axios.get('/devolution', {
      params: { orderId },
    });

    const { devolution } = response.data;

    if (devolution && devolution.items && devolution.items.length > 0) {
      setDevolution(devolution);
    }

    return setLoadindgDevolution(false);
  } catch (error) {
    setLoadindgDevolution(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar a devolução',
      error,
    });
  }
};

export const getDevolutions = async ({
  orderId,
  setLoadindgDevolutions,
  setDevolutions,
  setSnackbar = () => {},
}) => {
  try {
    setLoadindgDevolutions(true);

    const response = await Axios.get('/devolution/list', {
      params: { orderId },
    });

    const { devolutions } = response.data;

    if (devolutions && devolutions.length > 0) {
      setDevolutions(devolutions);
    }

    return setLoadindgDevolutions(false);
  } catch (error) {
    setLoadindgDevolutions(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar as devoluções',
      error,
    });
  }
};

export const getOrdersResume = async ({
  setLoadingOrdersResume,
  setFilterOrderStatus,
  filterOrderStatus,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingOrdersResume(true);

    const response = await Axios.get(`/orders/resume`, {
      params: {
        filterOrderStatus,
      },
    });

    setFilterOrderStatus(response.data);
    return setLoadingOrdersResume(false);
  } catch (error) {
    setLoadingOrdersResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar o resumo dos pedidos',
      error,
    });
  }
};
