import Axios from 'axios';

export const getGoogleShoppingProductsBySku = async ({
  setGoogleShoppingAds,
  setLoadingGoogleShoppingAds,
  productId,
}) => {
  try {
    setLoadingGoogleShoppingAds(true);

    const response = await Axios.get(`/google-shopping/products/by-sku/${productId}`);
    const { googleShoppingProductsList } = response.data;

    setLoadingGoogleShoppingAds(false);
    return setGoogleShoppingAds(googleShoppingProductsList);
  } catch (error) {
    setLoadingGoogleShoppingAds(false);
    return error;
  }
};

export const deleteGoogleShoppingProduct = async (company, googleShoppingId) => {
  try {
    const response = await Axios.delete('/google-shopping/products', {
      params: {
        company,
        googleShoppingId,
      },
    });

    const { googleShoppingProductDeleted } = response.data;
    return googleShoppingProductDeleted;
  } catch (error) {
    return error;
  }
};
