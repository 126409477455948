import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const getMagazineLuizaProductsBySku = async ({
  setMagazineLuizaAds,
  setLoadingMagazineLuizaAds,
  productId,
}) => {
  try {
    setLoadingMagazineLuizaAds(true);

    const response = await Axios.get(`/magazine-luiza/products/by-sku/${productId}`);
    const { magazineLuizaProductsList } = response.data;

    setLoadingMagazineLuizaAds(false);
    return setMagazineLuizaAds(magazineLuizaProductsList);
  } catch (error) {
    setLoadingMagazineLuizaAds(false);
    return error;
  }
};

export const getMagazineLuizaPrice = async (
  company,
  productId,
  quantity,
  offerPrice,
  setSnackbar = () => {},
) => {
  try {
    const response = await Axios.get('/magazine-luiza/product/price', {
      params: {
        company,
        productId,
        quantity,
        offerPrice,
      },
    });

    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: `Algum erro ocorreu ao buscar preço para Magazine Luiza`,
      error,
    });
    return {
      newPromotionalPrice: '0',
      newProductPrice: '0',
    };
  }
};

export const getMagazineLuizaShippingStamp = async ({
  orders,
  setLoadingShippingStamp,
  setSnackbar,
}) => {
  try {
    setLoadingShippingStamp(true);
    const ordersToMagazineLuizaShipping = [];

    orders.forEach((order) => {
      if (order.othersIds?.idMagazineLuiza) {
        ordersToMagazineLuizaShipping.push({
          idMagazineLuiza: order.othersIds.idMagazineLuiza,
        });
      }
    });

    const response = await Axios.get(`/magazine-luiza/stamps/bulk-print`, {
      params: {
        ordersToMagazineLuizaShipping,
      },
    });

    const { stampsLinks } = response.data;

    stampsLinks.forEach((stamp) => {
      window.open(stamp);
    });

    setLoadingShippingStamp(false);
  } catch (error) {
    setLoadingShippingStamp(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Não conseguimos imprimir a etiqueta. Verifique o sistema de impressão.',
      error,
    });
  }
};
