import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  createFilterOptions,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import Axios from 'axios';
import SearchField from '../../../../Common/SearchField';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { formatHandleError } from '../../../../../helpers/formatData';
import CompanyAutocomplete from '../../../../Common/CompanyAutocomplete';

function ShopeeCategoryDialog({ openDialog, handleClose, handleClick, searchTerm }) {
  const [categoryList, setCategoryList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState(searchTerm);
  const [errorMessage, setErrorMessage] = useState('');
  const [company, setCompany] = useState(null);
  const [filterCompaniesByIds, setFilterCompaniesByIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/shopee/configs');
        const shopeeCompaniesIds = response.data.map((shopeeConfig) => shopeeConfig.company);
        setFilterCompaniesByIds(shopeeCompaniesIds);

        if (shopeeCompaniesIds.length > 0) {
          setCompany(shopeeCompaniesIds[0]);
        }
      } catch (error) {
        formatHandleError({
          setSnackbar: ({ message }) => setErrorMessage(message),
          defaultMessage: 'Algum erro ocorreu ao buscar configurações da Shopee',
          error,
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async (companyId) => {
      try {
        setLoadingCategories(true);
        setErrorMessage('');

        const response = await Axios.get(`/shopee/categories/${companyId}`);
        const { categoriesList } = response.data;

        setCategoryList(categoriesList || []);
        setLoadingCategories(false);
      } catch (error) {
        setCategoryList([]);
        setLoadingCategories(false);
        formatHandleError({
          setSnackbar: ({ message }) => setErrorMessage(message),
          defaultMessage: 'Algum erro ocorreu ao buscar categorias na Shopee',
          error,
        });
      }
    };

    if (company) {
      fetchData(typeof company === 'object' ? company._id : company);
    } else {
      setCategoryList([]);
      setErrorMessage('Selecione a empresa');
    }
  }, [company]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name + option.pathFromRoot,
  });

  useEffect(() => {
    const shopeeCategoriesFiltered = filterOptions(categoryList, {
      inputValue: searchedTerm,
      getOptionLabel: (option) => option.name + option.pathFromRoot,
    });

    setFilteredCategories(shopeeCategoriesFiltered);
  }, [categoryList, searchedTerm]);

  return (
    <SimpleDialog
      openDialog={openDialog}
      handleClose={handleClose}
      dialogTitle="Shopee"
      dialogText="Pesquisar categoria da Shopee"
      actionButton={
        <Box width={200}>
          <CompanyAutocomplete
            handleOnChange={(event, newValue) => setCompany(newValue)}
            selectedCompany={company}
            filterCompaniesByIds={filterCompaniesByIds}
          />
        </Box>
      }
      content={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <SearchField
              submitSearch={(searchText) => setSearchedTerm(searchText)}
              labelSearch="Pesquisar categoria"
              preSearchText={searchedTerm}
            />
          </Grid>
          {filteredCategories.length > 0 && !loadingCategories
            ? filteredCategories.map((category) => (
                <Grid key={category.id} item>
                  <Grid container direction="column">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleClick(category)}
                      >
                        {category.name}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{category.pathFromRoot}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            : null}
          {filteredCategories.length === 0 && !errorMessage && !loadingCategories ? (
            <Grid item>
              <Typography>Nenhuma categoria encontrada</Typography>
            </Grid>
          ) : null}
          {loadingCategories ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {errorMessage ? (
            <Grid item>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          ) : null}
        </Grid>
      }
    />
  );
}

export default memo(ShopeeCategoryDialog);
