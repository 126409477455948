import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const getShopeeProductsBySku = async ({
  productId,
  setShopeeAds,
  setLoadingShopeeAds = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingShopeeAds(true);
    const response = await Axios.get(`/shopee/products/by-sku/${productId}`);
    const { shopeeProductsList } = response.data;
    setShopeeAds(shopeeProductsList);
    setLoadingShopeeAds(false);
  } catch (error) {
    setLoadingShopeeAds(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar produto da Shopee',
      error,
    });
  }
};

export const deleteShopeeProduct = async (company, productId) => {
  try {
    const response = await Axios.delete(`/shopee/products/${company}/${productId}`);
    const { shopeeProductDeleted } = response.data;
    return shopeeProductDeleted;
  } catch (error) {
    return error;
  }
};

export const getShopeeBrands = async ({
  companyId,
  categoryId,
  setBrandList,
  setLoadingBrandList = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingBrandList(true);
    const response = await Axios.get(`/shopee/brands/${companyId}/${categoryId}`);
    const { shopeeBrands } = response.data;
    setBrandList(shopeeBrands);
    setLoadingBrandList(false);
  } catch (error) {
    setLoadingBrandList(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar marcas na Shopee',
      error,
    });
  }
};

export const getShopeePrice = async ({
  productId,
  quantity,
  offerPrice,
  setSnackbar = () => {},
}) => {
  try {
    const response = await Axios.get('/shopee/product/cost', {
      params: {
        productId,
        quantity,
        offerPrice,
      },
    });

    const { newPrice } = response.data;
    return newPrice;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar marcas na Shopee',
      error,
    });

    return 0;
  }
};

export const getShopeeShippingStamp = async ({ orders, setLoadingShippingStamp, setSnackbar }) => {
  try {
    setLoadingShippingStamp(true);
    const ordersToShopeeShipping = [];

    orders.forEach((order) => {
      if (order.othersIds?.idShopee) {
        ordersToShopeeShipping.push({
          shopeeOrderId: order.othersIds.idShopee,
          company: order.company,
        });
      }
    });

    const response = await Axios.get(`/shopee/stamps/bulk-print`, {
      params: {
        ordersToShopeeShipping,
      },
    });

    const { stampsLinks } = response.data;

    stampsLinks.forEach((stamp) => {
      window.open(stamp);
    });

    setLoadingShippingStamp(false);
  } catch (error) {
    setLoadingShippingStamp(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Não conseguimos imprimir a etiqueta. Verifique o sistema de impressão.',
      error,
    });
  }
};
