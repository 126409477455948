const filterOrdenateOptions = [
  {
    id: 'name',
    description: 'A - Z',
    active: false,
    default: 'unchecked',
  },
  {
    id: 'name',
    description: 'Z - A',
    active: false,
    default: 'unchecked',
  },
];

export default filterOrdenateOptions;
