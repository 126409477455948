import React, { useState } from 'react';
import Axios from 'axios';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import { formatHandleError } from '../../../../../helpers/formatData';

function DialogPrintProductStamp({ openDialog, handleCloseDialog, product, setSnackbar }) {
  const [quantity, setQuantity] = useState(1);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const handleChangeQuantity = (event) => {
    const { value } = event.target;

    if (!Number.isNaN(value) && value >= 1) {
      setQuantity(event.target.value);
    }
  };

  const printProductStamp = async () => {
    try {
      setLoadingPrint(true);
      const response = await Axios.post('/catalog/products/stamps', [
        {
          productId: product.productId,
          quantity,
        },
      ]);
      setLoadingPrint(false);

      setSnackbar({
        message: response.data,
        open: true,
        type: 'success',
      });

      return handleCloseDialog();
    } catch (error) {
      setLoadingPrint(false);

      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu tentar imprimir a etiqueta',
        error,
      });

      return null;
    }
  };

  return (
    <SimpleDialog
      dialogTitle="Imprimir etiquetas"
      dialogText={`Produto: ${product.technicalName}`}
      content={
        <CustomInput
          fullWidth
          number
          value={quantity}
          label="Quantidade de etiquetas"
          onChange={handleChangeQuantity}
        />
      }
      handleClose={handleCloseDialog}
      confirmButtonText="Imprimir"
      cancelButtonText="Cancelar"
      handleCancelButton={handleCloseDialog}
      handleConfirmButton={printProductStamp}
      confirmButtonLoading={loadingPrint}
      openDialog={openDialog}
    />
  );
}

export default DialogPrintProductStamp;
