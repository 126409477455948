import dayjs from 'dayjs';

const newOrderPayment = {
  type: 'Recebimento',
  method: 'Aguardando financeiro',
  value: 0,
  valuePaid: 0,
  financialCost: 0,
  decreaseValue: 0,
  additionalValue: 0,
  condition: 'A VISTA',
  dueDate: dayjs().add(4, 'day'),
  status: 'Pago',
  observation: '',
};

export default newOrderPayment;
