import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useState } from 'react';
import MercadoLivreAd from './MercadoLivreAd';
import MadeiraMadeiraAd from './MadeiraMadeiraAd';
import AmericanasAd from './AmericanasAd';
import MagazineLuizaAd from './MagazineLuizaAd';
import ViaVarejoAd from './ViaVarejoAd';
import GoogleShoppingAd from './GoogleShoppingAd';
import ShopeeAd from './ShopeeAd';
import MarketplacesDetails from '../../../MarketplacesAds/MarketplacesDetails';
import newMercadoLivreAd from '../../../../constant/newMercadoLivreAd';

const useStyles = makeStyles({
  openedTab: {
    fontSize: '1.9rem',
    margin: 'auto',
    animation: '$opening 1s 1',
  },
  closedTab: {
    fontSize: '1.9rem',
    margin: 'auto',
    transform: 'rotate(90deg)',
    animation: '$closing 1s 1',
  },
  '@keyframes opening': {
    '0%': {
      transform: 'rotate(90deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes closing': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(90deg)',
    },
  },
  sourceBadge: {
    width: 50,
    height: 50,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  marketplaceTitle: {
    fontWeight: 'normal',
    padding: 10,
  },
});

function AdvertsTab({ productForm, setProductForm, setFormChanged }) {
  const classes = useStyles();

  const [openMarketplacesAdDetails, setOpenMarketplacesAdDetails] = useState(false);
  const handleOpenMarketplacesAdDetails = () => setOpenMarketplacesAdDetails(true);
  const handleCloseMarketplacesAdDetails = () => setOpenMarketplacesAdDetails(false);

  const [mercadoLivreAd, setMercadoLivreAd] = useState(newMercadoLivreAd);
  const [refreshSearch, setRefreshSearch] = useState();

  return (
    <Box position="relative">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">Anúncios</Typography>
            </Grid>

            <Grid item>
              <Tooltip
                title={
                  <Typography align="center">Crie aqui um novo anúncio nos Marketplaces</Typography>
                }
              >
                <Box>
                  <Button variant="contained" onClick={handleOpenMarketplacesAdDetails}>
                    Anunciar
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <MercadoLivreAd
          classes={classes}
          productForm={productForm}
          setMercadoLivreAd={setMercadoLivreAd}
          refreshSearch={refreshSearch}
          setRefreshSearch={setRefreshSearch}
        />

        <ShopeeAd
          classes={classes}
          productForm={productForm}
          setProductForm={setProductForm}
          setFormChanged={setFormChanged}
          refreshSearch={refreshSearch}
          setRefreshSearch={setRefreshSearch}
        />

        <AmericanasAd
          classes={classes}
          productForm={productForm}
          setProductForm={setProductForm}
          setFormChanged={setFormChanged}
          refreshSearch={refreshSearch}
          setRefreshSearch={setRefreshSearch}
        />

        <MagazineLuizaAd
          classes={classes}
          productForm={productForm}
          setProductForm={setProductForm}
          setFormChanged={setFormChanged}
          refreshSearch={refreshSearch}
        />

        <MadeiraMadeiraAd
          classes={classes}
          productForm={productForm}
          setProductForm={setProductForm}
          setFormChanged={setFormChanged}
          refreshSearch={refreshSearch}
        />

        <ViaVarejoAd
          classes={classes}
          productForm={productForm}
          setProductForm={setProductForm}
          setFormChanged={setFormChanged}
          refreshSearch={refreshSearch}
          setRefreshSearch={setRefreshSearch}
        />

        <GoogleShoppingAd
          classes={classes}
          productForm={productForm}
          refreshSearch={refreshSearch}
          setRefreshSearch={setRefreshSearch}
        />
      </Grid>

      {openMarketplacesAdDetails ? (
        <MarketplacesDetails
          openMarketplacesAdDetails={openMarketplacesAdDetails}
          handleCloseMarketplacesAdDetails={handleCloseMarketplacesAdDetails}
          productForm={productForm}
          mercadoLivreAd={mercadoLivreAd}
          setRefreshSearch={setRefreshSearch}
        />
      ) : null}
    </Box>
  );
}

export default memo(AdvertsTab);
