import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  smallCell: {
    width: 70,
  },
  mediumCell: {
    width: 125,
  },
  bigCell: {
    width: 300,
  },
});

function ChequesTableHead({ checkedCheques, handleUncheckAll, handleCheckAll }) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.smallCell}>
          <Checkbox
            onClick={checkedCheques.length > 0 ? handleUncheckAll : handleCheckAll}
            checked={checkedCheques.length > 0}
          />
        </TableCell>
        <TableCell className={classes.mediumCell}>Cheque Nº</TableCell>
        <TableCell className={classes.bigCell}>Titular</TableCell>
        <TableCell className={classes.bigCell}>Código de Barra</TableCell>
        <TableCell className={classes.smallCell}>Banco</TableCell>
        <TableCell className={classes.mediumCell}>Valor</TableCell>
        <TableCell className={classes.smallCell}>Histórico</TableCell>
        <TableCell className={classes.mediumCell}>Situação</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ChequesTableHead;
