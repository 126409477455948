const filterOrderSourceInitial = [
  {
    name: 'Loja física',
    active: false,
  },
  {
    name: 'Telemarketing',
    active: false,
  },
  {
    name: 'Site',
    active: false,
  },
  {
    name: 'App',
    active: false,
  },
  {
    name: 'Mercado Livre',
    active: false,
  },
  {
    name: 'Mercado Livre Full',
    active: false,
  },
  {
    name: 'Shopee',
    active: false,
  },
  {
    name: 'Magazine Luiza',
    active: false,
  },
  {
    name: 'Americanas',
    active: false,
  },
  {
    name: 'Madeira Madeira',
    active: false,
  },
  {
    name: 'Via Varejo',
    active: false,
  },
  {
    name: 'Devolução fornecedor',
    active: false,
  },
  {
    name: 'Remessa conserto',
    active: false,
  },
  {
    name: 'Uso e consumo',
    active: false,
  },
  {
    name: 'Transferência de estoque',
    active: false,
  },
];

export default filterOrderSourceInitial;
