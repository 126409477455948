import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React, { useState, useCallback, useEffect, memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import dayjs from 'dayjs';
import SimpleDialog from '../../Common/SimpleDialog';
import PaymentMethods from '../../Payments/PaymentMethods';
import {
  formatDate,
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../helpers/formatData';
import CustomInput from '../../CustomInput';
import CategoryPaymentAutocomplete from '../../Common/CategoryPaymentAutocomplete';
import UserAutocomplete from '../../Common/UserAutocomplete';
import ChequeCard from './ChequeCard';
import ChequeDialog from './ChequeDialog';
import ChequeDevolutionDialog from './ChequeDevolutionDialog';
import AddDocumentDialog from './AddDocumentDialog';
import CustomDatePicker from '../../CustomDatePicker';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';
import paymentMethodList from '../../../constant/paymentMethodList';
import AddInstallmentsDialog from './AddInstallmentsDialog';
import userRolesFiltersInitials from '../../../constant/userRolesFiltersInitials';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
  valueField: {
    width: 180,
  },
  gridDocuments: {
    position: 'relative',
    padding: 5,
    borderRadius: 5,
    backgroundColor: grey[100],
  },
  gridDocumentsButtons: {
    position: 'absolute',
    top: 2,
    right: 5,
  },
});

const gatewayPaymentsIds = [
  {
    gatewayId: 'Sicoob Checkout Transparente',
    methods: ['Pix', 'Boleto'],
  },
  {
    gatewayId: 'Bradesco Checkout Transparente',
    methods: ['Pix', 'Boleto'],
  },
];

function FinanceDialog({
  openDialog,
  handleClose,
  loadingFinances = () => {},
  handleSave,
  type,
  setType,
  setCompany,
  payed,
  setPayed,
  finance,
  setFinance,
  categoriesList,
  handleRefresh,
  setSnackbar,
}) {
  const classes = useStyles();
  const [numberOfInstallments, setNumberOfInstallments] = useState(1);

  const [openAddDialogInstallments, setOpenDialogInstallments] = useState(false);
  const handleOpenDialogInstallments = useCallback(() => setOpenDialogInstallments(true), []);
  const handleCloseAddDialogInstallments = useCallback(() => setOpenDialogInstallments(false), []);

  const [openAddDialogDocument, setOpenDialogDocuments] = useState(false);
  const handleOpenDialogDocuments = useCallback(() => setOpenDialogDocuments(true), []);
  const handleCloseAddDialogDocument = useCallback(() => setOpenDialogDocuments(false), []);

  const [openDialogCheque, setOpenDialogCheque] = useState(false);
  const handleOpenDialogCheque = useCallback(() => setOpenDialogCheque(true), []);
  const handleCloseChequeDialog = useCallback(() => setOpenDialogCheque(false), []);

  const [openDialogChequeDevolution, setOpenDialogChequeDevolution] = useState(false);
  const handleOpenDialogChequeDevolution = useCallback(
    () => setOpenDialogChequeDevolution(true),
    [],
  );
  const handleCloseDialogChequeDevolution = useCallback(
    () => setOpenDialogChequeDevolution(false),
    [],
  );

  const handleChangePayed = (event) => {
    if (event.target.value === 'Todos') {
      setPayed('Pago');
    }
    if (event.target.value === 'Não pago') {
      setPayed('Pago');
    }
    if (event.target.value === 'Pago') {
      setPayed('Não pago');
    }
  };

  const handleChangePayDate = (date) => {
    setFinance((oldFields) => ({
      ...oldFields,
      payDate: date,
    }));
  };

  const handleChangeCompany = (event, newValue) => {
    setFinance((oldFields) => ({
      ...oldFields,
      company: newValue,
    }));

    setCompany(newValue);
  };

  const handleCategoryOnChange = (event, newValue) => {
    if (newValue && newValue.categoryId) {
      setFinance((oldFields) => ({
        ...oldFields,
        categories: [newValue],
      }));
    } else {
      setFinance((oldFields) => ({
        ...oldFields,
        categories: [],
      }));
    }
  };

  const handleSubCategoryOnChange = (event, newValue) => {
    if (newValue && newValue.categoryId) {
      setFinance((oldFields) => ({
        ...oldFields,
        categories: [...oldFields.categories, newValue],
      }));
    } else {
      setFinance((oldFields) => ({
        ...oldFields,
        categories: [],
      }));
    }
  };

  const handleUserOnChange = (event, newValue) => {
    if (newValue && newValue.userId) {
      setFinance((oldFields) => ({
        ...oldFields,
        user: newValue,
      }));
    } else {
      setFinance((oldFields) => ({
        ...oldFields,
        user: null,
      }));
    }
  };

  const handleChangeValue = (event) => {
    const newTotalValue = onlyNumbersValue(event.target.value);

    setFinance((oldFields) => ({
      ...oldFields,
      value: formatReal(newTotalValue),
    }));
  };

  const handleChangeObs = (event) => {
    setFinance((oldFields) => ({
      ...oldFields,
      obs: event.target.value,
    }));
  };

  const handleChangeGatewayPaymentId = (event, newValue) => {
    if (newValue) {
      setFinance((oldFields) => ({
        ...oldFields,
        method: '',
        othersIds: {
          ...oldFields.othersIds,
          gatewayPaymentId: newValue.gatewayId,
        },
      }));
    } else {
      setFinance((oldFields) => ({
        ...oldFields,
        method: '',
        othersIds: {
          ...oldFields.othersIds,
          gatewayPaymentId: undefined,
        },
      }));
    }
  };

  const handleDeleteDocument = ({ deleteIndex }) => {
    const updatedDocuments = finance.documents.filter((document, index) => index !== deleteIndex);
    return setFinance((oldFields) => ({
      ...oldFields,
      documents: updatedDocuments,
    }));
  };

  const handleAddCheque = (cheque) => {
    handleCloseChequeDialog();

    setFinance((oldFields) => ({
      ...oldFields,
      value: cheque.value,
      cheque,
    }));
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [excludeMethods, setExcludeMethods] = useState(['Crédito', 'Link de pagamento']);

  const hasSubCategory = (categoryId) =>
    categoriesList.filter((category) => category.parentId === categoryId).length > 0;

  useEffect(() => {
    const selectedGateway = gatewayPaymentsIds.find(
      (gateway) => gateway.gatewayId === finance.othersIds?.gatewayPaymentId,
    );

    if (selectedGateway) {
      const methodsExcluded = paymentMethodList
        .filter((method) => !selectedGateway.methods.includes(method.name))
        .map((method) => method.name);

      setExcludeMethods([...methodsExcluded, 'Crédito', 'Link de pagamento']);
    } else {
      setExcludeMethods(['Crédito', 'Link de pagamento']);
    }

    let categoryQuantityCheck = 1;

    if (finance.categories) {
      finance.categories.forEach((category) => {
        if (hasSubCategory(category.categoryId)) {
          categoryQuantityCheck += 1;
        }
      });
    }

    if (!finance.payDate) {
      setErrorMessage('Escolha uma data de pagamento');
      return setDisableConfirmButton(true);
    }

    if (!finance.company?.cnpj) {
      setErrorMessage('Escolha a empresa');
      return setDisableConfirmButton(true);
    }

    if (finance.categories?.length < categoryQuantityCheck) {
      setErrorMessage('Escolha uma categoria');
      return setDisableConfirmButton(true);
    }

    if (!finance.user?.name) {
      setErrorMessage(`Escolha um ${type === 'Recebimento' ? 'pagador' : 'recebedor'}`);
      return setDisableConfirmButton(true);
    }

    if (parseFloat(finance.value) <= 0 || Number.isNaN(parseFloat(finance.value))) {
      setErrorMessage('Valor tem que ser maior que 0');
      return setDisableConfirmButton(true);
    }

    if (!finance.method) {
      setErrorMessage('Escolha uma forma de pagamento');
      return setDisableConfirmButton(true);
    }

    if (finance.method === 'Cheque' && !finance.cheque) {
      setErrorMessage('Adicione o cheque');
      return setDisableConfirmButton(true);
    }

    setErrorMessage('');
    return setDisableConfirmButton(false);
  }, [
    finance.payDate,
    finance.categories,
    finance.user,
    finance.value,
    finance.method,
    finance.cheque,
    finance.othersIds?.gatewayPaymentId,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (finance.financeId && finance.installments) {
        const response = await Axios.patch('/financial/byIds', {
          ids: finance.installments.map((installment) => installment.installmentId),
        });
        const byIds = response.data;

        setFinance((oldFields) => ({
          ...oldFields,
          installments: oldFields.installments.map((installment) => {
            const getFinance = byIds.finances.find(
              (financeById) => financeById.financeId === installment.installmentId,
            );

            if (getFinance) {
              return {
                ...installment,
                value: getFinance.value,
                payDate: getFinance.payDate,
                payed: getFinance.payed,
              };
            }

            return installment;
          }),
        }));
      } else {
        const installments = [...Array(numberOfInstallments)].map((_, index) => ({
          value: finance.value / numberOfInstallments,
          payDate: dayjs(finance.payDate).add(index, 'month'),
        }));

        setFinance((oldFields) => ({
          ...oldFields,
          installments: installments.length > 1 ? installments : [],
        }));
      }
    };
    fetchData();
  }, [numberOfInstallments]);

  return (
    <>
      <SimpleDialog
        openDialog={openDialog}
        handleClose={handleClose}
        dialogTitle={
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{type}</Grid>
            <Grid item>
              <Box className={classes.valueField}>
                <FormControlLabel
                  control={
                    <Switch
                      value={payed}
                      onChange={handleChangePayed}
                      checked={payed === 'Pago'}
                      disabled={finance.payed}
                      color="primary"
                    />
                  }
                  label={payed === 'Pago' ? 'Pago' : 'Não pago'}
                />
              </Box>
            </Grid>
          </Grid>
        }
        maxWidth="xs"
        content={
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ButtonGroup variant="contained">
                <Button
                  onClick={() => {
                    setFinance((oldFields) => ({
                      ...oldFields,
                      categories: [],
                    }));
                    setType('Recebimento');
                  }}
                  disabled={finance.payed}
                  color={type === 'Recebimento' ? 'primary' : 'default'}
                >
                  Recebimento
                </Button>
                <Button
                  onClick={() => {
                    setFinance((oldFields) => ({
                      ...oldFields,
                      categories: [],
                    }));
                    setType('Pagamento');
                  }}
                  disabled={finance.payed}
                  color={type === 'Pagamento' ? 'primary' : 'default'}
                >
                  Pagamento
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item>
              <CustomDatePicker
                fullWidth
                size="small"
                disabled={finance.payed}
                variant="outlined"
                label="Data de pagamento"
                format="DD/MM/YYYY"
                value={finance.payDate}
                error={!finance.payDate}
                onChange={handleChangePayDate}
              />
            </Grid>
            <Grid item>
              <CompanyAutocomplete
                handleOnChange={handleChangeCompany}
                selectedCompany={finance.company}
                disabled={finance.payed}
                error={!finance.company}
              />
            </Grid>
            <Grid item>
              <CategoryPaymentAutocomplete
                setSnackbar={setSnackbar}
                handleOnChange={handleCategoryOnChange}
                type={type}
                selectedCategory={finance.categories?.[0]?._id ? finance.categories[0] : null}
                parentId={0}
                disabled={finance.payed}
                error={!finance.categories?.[0]?._id}
                variant="outlined"
              />
            </Grid>
            {finance.categories?.[0]?.categoryId &&
            hasSubCategory(finance.categories?.[0]?.categoryId) ? (
              <Grid item>
                <CategoryPaymentAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleSubCategoryOnChange}
                  type={type}
                  selectedCategory={finance.categories?.[1]?._id ? finance.categories[1] : null}
                  parentId={finance.categories?.[0]?.categoryId}
                  label="Sub-categoria"
                  disabled={finance.payed}
                  error={!finance.categories?.[1]?._id}
                  variant="outlined"
                />
              </Grid>
            ) : null}
            {finance.categories?.[1]?.categoryId &&
            hasSubCategory(finance.categories?.[1]?.categoryId) ? (
              <Grid item>
                <CategoryPaymentAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleSubCategoryOnChange}
                  type={type}
                  selectedCategory={finance.categories?.[2]?._id ? finance.categories[2] : null}
                  parentId={finance.categories?.[1]?.categoryId}
                  label="Sub-categoria"
                  disabled={finance.payed}
                  error={!finance.categories?.[2]?._id}
                  variant="outlined"
                />
              </Grid>
            ) : null}
            <Grid item>
              <UserAutocomplete
                setSnackbar={setSnackbar}
                handleOnChange={handleUserOnChange}
                selectedUser={finance.user?._id ? finance.user : null}
                label={type === 'Recebimento' ? 'Pagador' : 'Recebedor'}
                disabled={finance.payed}
                error={!finance.user?._id}
                variant="outlined"
                roles={userRolesFiltersInitials
                  .filter((option) => option.active)
                  .map((option) => option.role)}
              />
            </Grid>
            <Grid item>
              <CustomInput
                label="Valor"
                value={finance.value ? numberToRealWithPeriod(finance.value) : '0,00'}
                onChange={handleChangeValue}
                disabled={Boolean(finance.cheque) || finance.payed}
                error={parseFloat(finance.value) <= 0 || Number.isNaN(parseFloat(finance.value))}
                InputStartAdornment="R$"
                InputAdornmentStyle={classes.inputAdornment}
                variant="outlined"
                className={classes.valueField}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                value={finance.obs}
                label="Observações"
                disabled={finance.payed}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                onChange={handleChangeObs}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                size="small"
                options={gatewayPaymentsIds}
                disabled={finance.payed}
                getOptionLabel={(option) => option.gatewayId}
                value={
                  gatewayPaymentsIds.find(
                    (gateway) => gateway.gatewayId === finance.othersIds?.gatewayPaymentId,
                  ) || null
                }
                onChange={handleChangeGatewayPaymentId}
                noOptionsText="Opção não encontrada"
                renderInput={(params) => (
                  <TextField {...params} label="Processar pagamento com" variant="outlined" />
                )}
              />
            </Grid>
            {finance.payDate && finance.value > 0 ? (
              <Grid item>
                <Grid container justifyContent="space-between" direction="column" spacing={1}>
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h6">Parcelamento</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleOpenDialogInstallments}
                          disabled={!!finance.financeId}
                          variant="contained"
                          color="primary"
                        >
                          Adicionar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {finance.installments && finance.installments.length > 0 ? (
                    <Grid item>
                      <Grid container spacing={1}>
                        {finance.installments.map((installment, index) => (
                          <Grid item xs={12} key={`${index + 1}`}>
                            <Grid
                              container
                              justifyContent="space-around"
                              className={classes.gridDocuments}
                              columnSpacing={1}
                            >
                              <Grid item>
                                <Typography>{index + 1}</Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  R$ {numberToRealWithPeriod(installment.value)}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>{formatDate(installment.payDate)}</Typography>
                              </Grid>
                              <Grid item>
                                {installment.payed ? (
                                  <Typography
                                    style={{
                                      color: '#00c600',
                                    }}
                                  >
                                    Pago
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      color: '#ff2222',
                                    }}
                                  >
                                    Não pago
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}
            <Grid item>
              <Grid container justifyContent="space-between" direction="column" spacing={1}>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6">Documentos</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleOpenDialogDocuments}
                        disabled={finance.payed}
                        variant="contained"
                        color="primary"
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {finance.documents && finance.documents.length > 0 ? (
                  <Grid item>
                    <Grid container spacing={1}>
                      {finance.documents.map((document, index) => (
                        <Grid item xs={12} key={`${document.type} ${document.number}`}>
                          <Grid container justifyContent="center" className={classes.gridDocuments}>
                            <Grid item xs={6}>
                              <Typography>{document.type}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography>{document.number}</Typography>
                            </Grid>
                            <Grid item className={classes.gridDocumentsButtons}>
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteDocument({ deleteIndex: index })}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <Grid item>
              <PaymentMethods
                blockEdit={finance.payed}
                paymentMethod={finance.method}
                setPaymentMethod={(method) => setFinance((oldFields) => ({ ...oldFields, method }))}
                excludeMethods={excludeMethods}
              />
            </Grid>

            {finance.method === 'Cheque' ? (
              <Grid item>
                <Grid container justifyContent="space-between" direction="column" spacing={2}>
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h6">Cheque</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button
                              onClick={handleOpenDialogChequeDevolution}
                              disabled={!finance.payed}
                              variant="contained"
                              color="primary"
                            >
                              Devolver
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={handleOpenDialogCheque}
                              disabled={finance.payed}
                              variant="contained"
                              color="primary"
                            >
                              Adicionar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {finance.cheque && finance.cheque.barCode ? (
                    <Grid item>
                      <ChequeCard
                        cheque={finance.cheque}
                        chequeIndex={finance.payed ? undefined : 0}
                        handleDeleteCheque={() =>
                          setFinance((oldFields) => ({ ...oldFields, cheque: null }))
                        }
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            {errorMessage ? (
              <Grid item>
                <Typography color="error">{errorMessage}</Typography>
              </Grid>
            ) : null}
          </Grid>
        }
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        handleConfirmButton={handleSave}
        confirmButtonLoading={loadingFinances}
        disableConfirmButton={disableConfirmButton}
        handleCancelButton={handleClose}
      />
      {openAddDialogInstallments ? (
        <AddInstallmentsDialog
          openAddDialogInstallments={openAddDialogInstallments}
          handleCloseAddDialogInstallments={handleCloseAddDialogInstallments}
          finance={finance}
          setFinance={setFinance}
          numberOfInstallments={numberOfInstallments}
          setNumberOfInstallments={setNumberOfInstallments}
        />
      ) : null}
      {openAddDialogDocument ? (
        <AddDocumentDialog
          openAddDialogDocument={openAddDialogDocument}
          handleCloseAddDialogDocument={handleCloseAddDialogDocument}
          finance={finance}
          setFinance={setFinance}
        />
      ) : null}
      {openDialogCheque ? (
        <ChequeDialog
          payDate={finance.payDate}
          openChequeDialog={openDialogCheque}
          handleCloseChequeDialog={handleCloseChequeDialog}
          handleSelectChequeToPayment={handleAddCheque}
        />
      ) : null}
      {openDialogChequeDevolution ? (
        <ChequeDevolutionDialog
          openDialogChequeDevolution={openDialogChequeDevolution}
          handleCloseDialogChequeDevolution={handleCloseDialogChequeDevolution}
          handleCloseFinanceDialog={handleClose}
          finance={finance}
          setPayed={setPayed}
          setSnackbar={setSnackbar}
          handleRefresh={handleRefresh}
        />
      ) : null}
    </>
  );
}

export default memo(FinanceDialog);
