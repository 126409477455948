import { Divider } from '@mui/material';
import React from 'react';

function CustomDivider({ orientation, variant, flexItem }) {
  return (
    <Divider
      sx={
        orientation === 'vertical'
          ? { marginLeft: 1, marginRight: -1, marginTop: 1, marginBottom: -1 }
          : { marginTop: 1, marginBottom: -2 }
      }
      orientation={orientation}
      variant={variant}
      flexItem={flexItem}
    />
  );
}

export default CustomDivider;
