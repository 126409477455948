const filterOrderStatusInitial = [
  {
    name: 'Orçamento',
    active: false,
  },
  {
    name: 'Pedido criado',
    active: false,
  },
  {
    name: 'Pedido confirmado',
    active: false,
  },
  {
    name: 'Pedido faturado',
    active: false,
  },
  {
    name: 'Pedido embalado',
    active: false,
  },
  {
    name: 'Pedido enviado',
    active: false,
  },
  {
    name: 'Pedido entregue',
    active: false,
  },
  {
    name: 'Pedido concluído',
    active: false,
  },
  {
    name: 'Pedido não entregue',
    active: false,
  },
  {
    name: 'Pedido devolvido',
    active: false,
  },
  {
    name: 'Pedido cancelado',
    active: false,
  },
];

export default filterOrderStatusInitial;
