import { Alert, Box, Grid, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useCallback, useEffect, memo } from 'react';
import Axios from 'axios';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import DateIcon from '@mui/icons-material/DateRange';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import dayjs from 'dayjs';
import getFinances, { createFinance, updateFinance } from '../../services/melhorGestao/finances';
import TableFinances from './FinanceTable';
import TopBarFinances from './FinanceTopBar';
import newFinance from '../../constant/newFinance';
import FabGroupButtons from '../Common/FabGroupButtons';
import FinanceDialog from './FinanceDialog';
import { getCategoriesByType } from '../../services/melhorGestao/payments';
import { getUsers } from '../../services/melhorGestao/users';
import SimpleDialog from '../Common/SimpleDialog';
import ChequesPage from '../Cheques/Page';
import SituationsFiltersPapers from '../Common/SituationsFiltersPapers';
import CustomDatePicker from '../CustomDatePicker';
import OrdersTermModal from './OrdersTermModal';
import SimpleLinearProgress from '../Common/SimpleLinearProgress';

const useStyles = makeStyles({
  box: {
    maxWidth: 900,
    height: '90vh',
  },
});

function Finances() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [financesList, setFinancesList] = useState([]);
  const [totalFinancesValue, setTotalFinancesValue] = useState(0);
  const [quantityFinances, setQuantityFinances] = useState(0);
  const [loadingFinances, setLoadingFinances] = useState(false);
  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [finance, setFinance] = useState(newFinance);
  const [page, setPage] = useState(1);
  const [limit] = useState(15);
  const [checkedFinances, setCheckedFinances] = useState([]);
  const [refreshSituationsFilters, setRefreshSituationsFilters] = useState(Math.random());
  const [situationsFilters, setSituationsFilters] = useState([]);

  const [type, setType] = useState('Pagamento');
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [payed, setPayed] = useState('Todos');

  const [openDialogFinance, setOpenDialogFinance] = useState(false);
  const handleOpenDialogFinance = useCallback(() => setOpenDialogFinance(true), []);
  const handleCloseDialogFinance = useCallback(() => setOpenDialogFinance(false), []);

  const [openOrdersTermModal, setOpenOrdersTermModal] = useState(false);
  const handleOpenOrdersTermModal = useCallback(() => setOpenOrdersTermModal(true), []);
  const handleCloseOrdersTermModal = useCallback(() => setOpenOrdersTermModal(false), []);

  const [openDialogDate, setOpenDialogDate] = useState(false);
  const handleOpenDialogDate = () => setOpenDialogDate(true);
  const handleCloseDialogDate = () => {
    setOpenDialogDate(false);
    setPage(1);
  };

  const [openDialogCheques, setOpenDialogCheques] = useState(false);
  const handleOpenDialogCheques = useCallback(() => setOpenDialogCheques(true), []);
  const handleCloseDialogCheques = useCallback(() => setOpenDialogCheques(false), []);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, financesList],
  );

  const handleRefresh = useCallback(() => {
    setPage(1);
    setRefreshToken(Math.random());
  }, []);

  const [categoriesList, setCategoriesList] = useState([]);

  const handeGetCategories = useCallback(async () => {
    try {
      const paymentCategories = await getCategoriesByType({
        type: 'Pagamento',
        setSnackbar,
      });
      const receivementCategories = await getCategoriesByType({
        type: 'Recebimento',
        setSnackbar,
      });
      const getCategories = [...(paymentCategories || {}), ...(receivementCategories || {})];

      setSituationsFilters([
        {
          id: 'todos',
          name: 'Total de todos',
          description: 'Total de todos lançamentos',
          active: false,
        },
        ...getCategories
          .filter((category) => category.parentId === 0 && category.type === type)
          .map((category) => ({
            id: category._id,
            name: category.name,
            description: `Categoria de ${type.toLowerCase()}`,
            active: false,
          })),
      ]);

      setCategoriesList(getCategories);
    } catch (error) {
      setSnackbar({
        message: error.message,
        open: true,
        type: 'error',
      });
    }
  }, [type, company, startDate, endDate, payed, situationsFilters]);

  useEffect(() => {
    handeGetCategories();
  }, [refreshToken, type, company, startDate, endDate, payed]);

  const getFinancesList = useCallback(
    async (noRefreshSituations = false) => {
      setLoadingFinances(true);
      const category =
        situationsFilters.find(
          (situationsFilter) =>
            situationsFilter.active &&
            situationsFilter.description === `Categoria de ${type.toLowerCase()}`,
        )?.id || '';

      const result = await getFinances({
        setSnackbar,
        skip: page * limit - limit,
        limit,
        company: company?._id ? company?._id : '',
        user: user?._id ? user?._id : '',
        category,
        startDate: dayjs(startDate).startOf('day'),
        endDate: dayjs(endDate).endOf('day'),
        payed,
        type,
      });

      const financeListFull = [];
      const findById = (list, id) => list.find((item) => item._id === id);

      const responseCompanies = await Axios.get('/configuration/companies');
      const getCompanies = responseCompanies.data;

      const users = await getUsers(result.finances.map((financeItem) => financeItem.user));

      const responseCheques = await Axios.patch('/financial/cheques', {
        ids: result.finances.map((financeItem) => financeItem.cheque),
      });
      const getCheques = responseCheques.data;

      result.finances.forEach((financeItem) => {
        financeListFull.push({
          ...financeItem,
          company: financeItem.company ? findById(getCompanies, financeItem.company) : null,
          user: financeItem.user ? findById(users, financeItem.user) : null,
          cheque: financeItem.cheque ? findById(getCheques, financeItem.cheque) : null,
        });
      });

      setFinancesList(financeListFull);
      setTotalFinancesValue(result.totalFinancesValue);
      setQuantityFinances(result.count);
      setLoadingFinances(false);

      if (!noRefreshSituations) {
        setRefreshSituationsFilters(Math.random());
      }
    },
    [page, limit, company, user, startDate, endDate, payed, type, situationsFilters],
  );

  useEffect(() => {
    getFinancesList();
  }, [refreshToken, page, company, user, startDate, endDate, payed, type]);

  useEffect(() => setPage(1), [company, user, startDate, endDate, payed, type]);

  const [fabButtons, setFabButtons] = useState([]);

  const selectFinance = useCallback((fields) => {
    if (fields.payed) {
      setPayed('Pago');
    }
    if (!fields.payed) {
      setPayed('Não pago');
    }

    setFinance(fields);
    handleOpenDialogFinance();
  }, []);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Atualizar listagem',
        onClick: handleRefresh,
        icon: <RefreshIcon />,
      },
      {
        title: 'Notas a prazo em aberto',
        onClick: () => handleOpenOrdersTermModal(),
        icon: <RequestQuoteOutlinedIcon />,
      },
      {
        title: 'Buscar por data',
        onClick: handleOpenDialogDate,
        icon: <DateIcon />,
      },
      {
        title: 'Consulta de cheques',
        onClick: () => handleOpenDialogCheques(),
        icon: <RateReviewOutlinedIcon />,
      },
      {
        title: 'Novo registro',
        onClick: () => selectFinance({ ...newFinance, type }),
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, [type, company, payed]);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleSave = async () => {
    let result;

    if (finance.financeId) {
      result = await updateFinance({
        setSnackbar,
        setLoadingFinances,
        finance: {
          ...finance,
          type,
          payed: payed === 'Pago',
        },
      });
    } else {
      result = await createFinance({
        setSnackbar,
        setLoadingFinances,
        finance: {
          ...finance,
          type,
          payed: payed === 'Pago',
        },
      });
    }

    if (result) {
      if (Array.isArray(result)) {
        result = { ...result[0] };
      }

      handleCloseDialogFinance();
      setFinance(newFinance);

      if (result.payed) {
        setPayed('Pago');
      }
      if (!result.payed) {
        setPayed('Não pago');
      }

      handleRefresh();
      setPage(1);
    }
  };

  return (
    <Box>
      <SimpleLinearProgress loading={loadingFinances} fixedPosition />
      <Box marginTop={2}>
        <TopBarFinances
          type={type}
          setType={setType}
          company={company}
          setCompany={setCompany}
          user={user}
          setUser={setUser}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          payed={payed}
          setPayed={setPayed}
        />
      </Box>
      <Box marginTop={2}>
        <SituationsFiltersPapers
          refreshFiltersWithQuantity={refreshSituationsFilters}
          situationsFilters={situationsFilters}
          setSituationsFilters={setSituationsFilters}
          handleClickFilter={() => {
            getFinancesList(true);
            setPage(1);
          }}
          finances={{
            company: company && company._id ? company._id : '',
            user: user && user._id ? user._id : '',
            startDate,
            endDate,
            payed,
            type,
          }}
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <TableFinances
          financesList={financesList}
          selectFinance={selectFinance}
          totalFinancesValue={totalFinancesValue}
          quantityFinances={quantityFinances}
          loadingFinances={loadingFinances}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          checkedFinances={checkedFinances}
          setCheckedFinances={setCheckedFinances}
          categoriesList={categoriesList}
        />
      </Box>

      {openDialogFinance ? (
        <FinanceDialog
          openDialog={openDialogFinance}
          handleClose={handleCloseDialogFinance}
          loadingFinances={loadingFinances}
          handleSave={handleSave}
          type={type}
          setType={setType}
          setCompany={setCompany}
          payed={payed}
          setPayed={setPayed}
          finance={finance}
          setFinance={setFinance}
          categoriesList={categoriesList}
          handleRefresh={handleRefresh}
          setSnackbar={setSnackbar}
        />
      ) : null}

      {openOrdersTermModal ? (
        <OrdersTermModal
          openOrdersTermModal={openOrdersTermModal}
          handleCloseOrdersTermModal={handleCloseOrdersTermModal}
          selectFinance={selectFinance}
          setType={setType}
        />
      ) : null}

      {openDialogDate ? (
        <SimpleDialog
          maxWidth="xs"
          content={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <CustomDatePicker
                  margin="normal"
                  label="Data inicial"
                  format="DD/MM/YYYY"
                  value={startDate || null}
                  onChange={(date) => setStartDate(date)}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  margin="normal"
                  label="Data final"
                  format="DD/MM/YYYY"
                  value={endDate || null}
                  onChange={(date) => setEndDate(date)}
                />
              </Grid>
            </Grid>
          }
          dialogTitle="Selecionar data para filtrar"
          dialogText="Escolha a data inicial e a final para filtrar lançamentos pela data."
          handleClose={handleCloseDialogDate}
          openDialog={openDialogDate}
        />
      ) : null}

      {openDialogCheques ? (
        <SimpleDialog
          content={
            <Box className={classes.box}>
              <ChequesPage />
            </Box>
          }
          dialogTitle="Cadastro de cheques"
          handleClose={handleCloseDialogCheques}
          openDialog={openDialogCheques}
        />
      ) : null}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <FabGroupButtons fabButtons={fabButtons} />
    </Box>
  );
}

export default memo(Finances);
