const newDiscountCoupon = {
  code: '',
  discountType: '',
  sourceCoupon: [
    { id: 'Site', name: 'Site', active: true },
    { id: 'App', name: 'App', active: true },
  ],
  limitCouponUse: 0,
  limitPerUser: 0,
  discountPercentage: 0,
  active: true,
  minimumValueToGetDiscount: 0,
  startDate: new Date(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 5)),
};

export default newDiscountCoupon;
