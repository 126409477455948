import { Alert, Box, Grid, Snackbar } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SquareWidget from './SquareWidget';
import TopBar from './TopBar';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../helpers/formatData';
import { getUsers } from '../../services/melhorGestao/users';
import SimpleLinearProgress from '../Common/SimpleLinearProgress';

function SalesResume() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [listBy, setListBy] = useState('Origem');
  const [dateBy, setDateBy] = useState('createdAt');
  const [preferenceDate, setPreferenceDate] = useState(dayjs());
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSourcers = async () => {
      try {
        setLoading(true);

        let sourcers = [
          {
            source: '',
          },
        ];

        const response = await Axios.get('/orders/sourcers', {
          params: {
            date: preferenceDate,
            userId: selectedUser?.userId,
            filterOrderCompany,
            city: selectedCity?.city,
            state: selectedCity?.state,
            dateBy,
            onlyPaid: dateBy === 'payments.payDate',
          },
        });

        const excludedSources = [
          'Devolução fornecedor',
          'Remessa conserto',
          'Uso e consumo',
          'Transferência de estoque',
        ];

        sourcers = [
          ...sourcers,
          ...response.data
            .map((source) => ({ source }))
            .filter((data) => !excludedSources.includes(data.source)),
        ];

        setSourceList(sourcers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar origens dos pedidos',
          error,
        });
      }
    };

    const fetchSellers = async () => {
      try {
        setLoading(true);

        const response = await Axios.get('/orders/sellers', {
          params: {
            date: preferenceDate,
            userId: selectedUser?.userId,
            filterOrderCompany,
            status: [
              'Pedido confirmado',
              'Pedido faturado',
              'Pedido embalado',
              'Pedido enviado',
              'Pedido entregue',
              'Pedido concluído',
            ],
            city: selectedCity?.city,
            state: selectedCity?.state,
            dateBy,
            onlyPaid: dateBy === 'payments.payDate',
          },
        });

        const users = await getUsers(response.data);
        setSellerList(users);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar vendedores',
          error,
        });
      }
    };

    if (listBy === 'Origem') {
      fetchSourcers();
    }
    if (listBy === 'Vendedor') {
      fetchSellers();
    }
  }, [listBy, dateBy, preferenceDate, filterOrderCompany, selectedUser, selectedCity]);

  return (
    <Box position="relative" marginBottom={5}>
      <SimpleLinearProgress loading={loading} fixedPosition />
      <Box marginTop={2}>
        <TopBar
          listBy={listBy}
          setListBy={setListBy}
          filterOrderCompany={filterOrderCompany}
          setFilterOrderCompany={setFilterOrderCompany}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          dateBy={dateBy}
          setDateBy={setDateBy}
          preferenceDate={preferenceDate}
          setPreferenceDate={setPreferenceDate}
          setSnackbar={setSnackbar}
        />
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {!loading && listBy === 'Origem'
            ? sourceList.map(({ source }) => (
                <Grid item sm={3} key={source}>
                  <SquareWidget
                    darkMode={!source}
                    title={source || 'Total em vendas'}
                    source={source}
                    filterOrderCompany={filterOrderCompany}
                    selectedUser={selectedUser}
                    selectedCity={selectedCity}
                    dateBy={dateBy}
                    preferenceDate={preferenceDate}
                    setSnackbar={setSnackbar}
                  />
                </Grid>
              ))
            : null}
          {!loading && listBy === 'Vendedor'
            ? sellerList.map(({ _id, name }) => (
                <Grid item sm={3} key={_id}>
                  <SquareWidget
                    title={capitalizeString(extractFirstAndLastName(name))}
                    sellerId={_id}
                    filterOrderCompany={filterOrderCompany}
                    selectedUser={selectedUser}
                    selectedCity={selectedCity}
                    dateBy={dateBy}
                    preferenceDate={preferenceDate}
                    setSnackbar={setSnackbar}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SalesResume;
