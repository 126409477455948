import Axios from 'axios';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createInvoices } from '../../../../../../services/melhorGestao/invoices';
import filterOrderStatusInitial from '../../../../../../constant/filterOrderStatusInitial';
import filterOrderSourceInitial from '../../../../../../constant/filterOrderSourceInitial';
import { changeOrderStatus } from '../../../../../../services/melhorGestao/orders';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../../../../helpers/formatData';

function InvoiceEmissionButton({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  setSnackbar,
  submitSearchOrders = () => {},
  setFilterOrderStatus = () => {},
  setFilterOrderSource = () => {},
  setFilterOrderCompany = () => {},
  handleCloseAddNfeDialog = () => {},
  closeOrderDetails = () => {},
}) {
  const [loadingCheckNfeStatus, setLoadingCheckNfeStatus] = useState(false);

  const handleCreateInvoices = async () => {
    if (orderForm.invoice?.status !== 'aprovado') {
      const results = await createInvoices({
        setSnackbar,
        setLoadingInvoices: setLoadingCheckNfeStatus,
        ordersIds: [orderForm.orderId],
        previaDanfe: orderForm.previaDanfe,
      });

      if (!results) {
        return null;
      }

      try {
        setLoadingCheckNfeStatus(true);
        const response = await Axios.get(`/orders/${orderForm.orderId}`);
        const getUpdatedOrder = response.data.order;

        setOrderForm(getUpdatedOrder);
        setInitialOrderForm(getUpdatedOrder);

        if (results[0].previaDanfe) {
          window.open(results[0].danfeLink);
        }

        setLoadingCheckNfeStatus(false);

        if (results[0].result) {
          return handleCloseAddNfeDialog();
        }

        return setSnackbar({
          message: results[0].description,
          open: true,
          type: 'error',
        });
      } catch (error) {
        setLoadingCheckNfeStatus(false);
        return formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar pedido atualizado',
          error,
        });
      }
    }

    const updatedStatusOrder = await changeOrderStatus({
      orderId: orderForm.orderId,
      toStatus: 'Pedido faturado',
      setLoadingUpdateOrder: setLoadingCheckNfeStatus,
      setSnackbar,
    });

    if (!updatedStatusOrder) {
      return null;
    }

    setOrderForm(updatedStatusOrder);
    setInitialOrderForm(updatedStatusOrder);
    return handleCloseAddNfeDialog();
  };

  const handleCheckNfeStatus = async () => {
    try {
      setLoadingCheckNfeStatus(true);

      if (!orderForm.customer?.documents?.cpf && !orderForm.customer?.documents?.cnpj) {
        setLoadingCheckNfeStatus(false);

        return setSnackbar({
          message: 'Cliente sem documento, adicione um CPF ou CNPJ no usuário para prosseguir',
          open: true,
          type: 'error',
        });
      }

      const responseCompanies = await Axios.get('/configuration/companies');
      const filterOrderCompany = responseCompanies.data.map((company) => ({
        id: company._id,
        name: `${company.companyId} - ${capitalizeString(extractFirstAndLastName(company.name))}`,
        active: false,
      }));

      const responseOrdersSearch = await Axios.get('/orders/search', {
        params: {
          limit: 40,
          searchText:
            orderForm.customer.documents.pessoa === 'PF'
              ? orderForm.customer.documents.cpf
              : orderForm.customer.documents.cnpj,
          filterOrderSource: [
            {
              name: orderForm.source,
              active: true,
            },
          ],
          filterOrderStatus: [
            {
              name: 'Pedido confirmado',
              active: true,
            },
          ],
          filterOrderCompany: filterOrderCompany
            .filter((company) => company.id === orderForm.company)
            .map((company) => ({ ...company, active: true })),
        },
      });

      const { ordersList } = responseOrdersSearch.data;

      const excludedSources = [
        'Loja física',
        'Devolução fornecedor',
        'Remessa conserto',
        'Uso e consumo',
        'Transferência de estoque',
      ];

      if (excludedSources.includes(orderForm.source) || ordersList.length === 1) {
        await handleCreateInvoices();
      } else {
        handleCloseAddNfeDialog();

        setSnackbar({
          message: 'Este cliente possui outros pedidos confirmados em aberto',
          open: true,
          type: 'info',
        });

        setFilterOrderStatus(() =>
          filterOrderStatusInitial.map((oldField) => {
            if (oldField.name === 'Pedido confirmado') {
              return {
                ...oldField,
                active: true,
              };
            }
            return {
              ...oldField,
              active: false,
            };
          }),
        );
        setFilterOrderSource(() =>
          filterOrderSourceInitial.map((oldField) => {
            if (oldField.name === orderForm.source) {
              return {
                ...oldField,
                active: true,
              };
            }
            return {
              ...oldField,
              active: false,
            };
          }),
        );
        setFilterOrderCompany(() =>
          filterOrderCompany.map((oldField) => {
            if (oldField.id === orderForm.company) {
              return {
                ...oldField,
                active: true,
              };
            }
            return {
              ...oldField,
              active: false,
            };
          }),
        );

        const masterDocument =
          orderForm.customer.documents.pessoa === 'PF'
            ? orderForm.customer.documents.cpf
            : orderForm.customer.documents.cnpj;

        setTimeout(() => {
          submitSearchOrders(masterDocument);
          closeOrderDetails();
        }, 3000);
      }

      return setLoadingCheckNfeStatus(false);
    } catch (error) {
      setLoadingCheckNfeStatus(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar faturar o pedido, tente mais tarde.',
        error,
      });
    }
  };

  return (
    <LoadingButton
      loading={loadingCheckNfeStatus}
      variant="contained"
      color="secondary"
      onClick={handleCheckNfeStatus}
    >
      Emitir nova NF-e
    </LoadingButton>
  );
}

export default InvoiceEmissionButton;
