import React, { useState } from 'react';
import { Alert, Checkbox, Grid, Snackbar, Typography } from '@mui/material';
import Axios from 'axios';
import SimpleDialog from '../../../Common/SimpleDialog';
import FilterCategoriesOptions from '../../../Common/FilterCategoriesOptions';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import { formatHandleError } from '../../../../helpers/formatData';
import FilterBrandsOptions from '../../../Common/FilterBrandsOptions';

function CatalogProductsDialog({ openDialog, handleCloseDialog }) {
  const [filtersOptions, setFiltersOptions] = useState({
    categories: [],
    brands: [],
    onlyWithStock: false,
  });
  const [loading, setLoading] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleChangeFilterOption = (fieldName, value) => {
    if (fieldName === 'categories' || fieldName === 'brands') {
      return setFiltersOptions((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }

    return setFiltersOptions((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const categories = filtersOptions.categories
        .filter((filter) => filter.active)
        .map((filter) => filter.name);
      const brands = filtersOptions.brands
        .filter((filter) => filter.active)
        .map((filter) => (filter.name !== 'Sem marca' ? filter.name : ''));

      const response = await Axios.get('/catalog', {
        params: {
          categories,
          brands,
          onlyWithStock: filtersOptions.onlyWithStock,
        },
      });
      const url = response.data;
      window.open(url, '_blank');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao gerar o catálogo de produtos',
        error,
      });
    }
  };

  return (
    <SimpleDialog
      cancelButtonText="Cancelar"
      confirmButtonText="Gerar catálogo"
      maxWidth="sm"
      content={
        <>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Typography>A geração do catálogo pode levar até 5 minutos</Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1} direction="row">
                <Grid item>
                  <FilterCategoriesOptions
                    inputLabel="Filtrar por categorias"
                    handleChangeCategoriesFilter={(categories) =>
                      handleChangeFilterOption('categories', categories)
                    }
                  />
                </Grid>
                <Grid item>
                  <FilterBrandsOptions
                    inputLabel="Filtrar por marcas"
                    handleChangeBrandsFilter={(brands) =>
                      handleChangeFilterOption('brands', brands)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Checkbox
                      onClick={() => handleChangeFilterOption('onlyWithStock')}
                      checked={filtersOptions.onlyWithStock}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Somente produtos com estoque</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SimpleBackdrop loading={loading} />
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </>
      }
      dialogTitle="Gerar catálogo de produtos"
      handleCancelButton={handleCloseDialog}
      handleClose={handleCloseDialog}
      handleConfirmButton={handleConfirm}
      openDialog={openDialog}
    />
  );
}

export default CatalogProductsDialog;
