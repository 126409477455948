const paymentMethodList = [
  {
    name: 'Dinheiro',
    srcIcon: '/assets/imgs/icon-money.svg',
  },
  {
    name: 'Boleto',
    srcIcon: '/assets/imgs/icon-boleto.svg',
  },
  {
    name: 'Cheque',
    srcIcon: '/assets/imgs/icon-cheque.svg',
  },
  {
    name: 'Pix',
    srcIcon: '/assets/imgs/icon-pix.svg',
  },
  {
    name: 'Débito',
    srcIcon: '/assets/imgs/icon-debto.svg',
  },
  {
    name: 'Cartão',
    srcIcon: '/assets/imgs/icon-card.svg',
  },
  {
    name: 'Cartão de Débito',
    srcIcon: '/assets/imgs/icon-debit-card.svg',
  },
  {
    name: 'Crédito',
    srcIcon: '/assets/imgs/icon-credito.svg',
  },
  {
    name: 'Link de pagamento',
    srcIcon: '/assets/imgs/icon-digital-payment.svg',
  },
];

export default paymentMethodList;
