import Axios from 'axios';
import { Box, Checkbox, Drawer, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { numberToRealWithPeriod } from '../../../helpers/formatData';
import { getUsers } from '../../../services/melhorGestao/users';

function WalletsDetails({
  openWalletsDetails,
  closeWalletsDetails,
  filterWallet,
  setFilterWallet,
  setPage,
}) {
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [walletsByUserList, setWalletsList] = useState([]);

  const getWallets = async () => {
    try {
      setLoadingWallets(true);

      const response = await Axios.get('/wallets');
      const wallets = response.data;

      if (filterWallet.length === 0) {
        setFilterWallet(wallets.map((wallet) => ({ ...wallet, active: false })));
      }

      const sortedWalletsByUser = [];

      wallets.forEach((wallet) => {
        wallet.userIds.forEach((userId) => {
          if (sortedWalletsByUser.find((findWallet) => findWallet.userId === userId)) {
            sortedWalletsByUser.forEach((userWallet, index) => {
              if (userWallet.userId === userId) {
                sortedWalletsByUser[index].wallets.push({
                  walletId: wallet.walletId,
                  name: wallet.name,
                  value: wallet.value,
                });
              }
            });
          } else {
            sortedWalletsByUser.push({
              userId,
              wallets: [
                {
                  walletId: wallet.walletId,
                  name: wallet.name,
                  value: wallet.value,
                },
              ],
            });
          }
        });
      });

      const users = await getUsers(sortedWalletsByUser.map((wallet) => wallet.userId));
      const findByUserId = (userId) => users.find((user) => user.userId === userId);

      setWalletsList(
        sortedWalletsByUser.map((wallet) => {
          const { name } = findByUserId(wallet.userId);
          return { ...wallet, name };
        })
      );
      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getWallets();
  }, []);

  const handleSelectWallet = (selectedWallet) => {
    setPage(1);

    let index;
    const newFilterOptions = filterWallet;

    newFilterOptions.forEach((filterOption, indexInside) => {
      if (selectedWallet.walletId === filterOption.walletId) {
        index = indexInside;
      }
    });

    newFilterOptions[index].active = !newFilterOptions[index].active;
    return setFilterWallet(() => [...newFilterOptions]);
  };

  return (
    <Drawer anchor="left" open={openWalletsDetails} onClose={closeWalletsDetails}>
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={5}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h1">Carteiras</Typography>
          </Grid>
          {!loadingWallets &&
            walletsByUserList.map((user) => (
              <Grid item key={user.userId}>
                <Paper>
                  <Box padding={2}>
                    <Box marginBottom={1}>
                      <Typography variant="h3">{user.name}</Typography>
                    </Box>
                    <Grid container direction="column">
                      {user.wallets.map((wallet) => (
                        <Grid item key={wallet.walletId} onClick={() => handleSelectWallet(wallet)}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            <Grid item>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Checkbox
                                    checked={
                                      filterWallet.find(
                                        (findWallet) => findWallet.walletId === wallet.walletId
                                      ).active
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>{wallet.name}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>R$ {numberToRealWithPeriod(wallet.value)}</Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Drawer>
  );
}

export default WalletsDetails;
