import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import CitiesListTable from './CitiesListTable';
import CityConfiguration from './CityConfiguration';

function CompanyLogPaper({ listCities = false, selectedCity, setSelectedCity }) {
  return (
    <Paper>
      <Box padding={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Integração com Mark Ferragens</Typography>
          </Grid>
          {listCities && !selectedCity ? (
            <CitiesListTable setSelectedCity={setSelectedCity} />
          ) : (
            <CityConfiguration selectedCity={selectedCity} />
          )}
        </Grid>
      </Box>
    </Paper>
  );
}

export default CompanyLogPaper;
