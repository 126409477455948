import Axios from 'axios';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { cleanUpDuplicateSpaces } from '../../../../../helpers/formatData';
import SimpleDialog from '../../../SimpleDialog';
import CustomInput from '../../../../CustomInput';

function DialogPaymentCategory({
  openDialogCreateCategory,
  handleCloseDialogCreateCategory,
  selectedCategory,
  setRefreshToken,
}) {
  const [category, setCategory] = useState(selectedCategory);
  const [loadingCreateCategory, setLoadingCreateCategory] = useState(false);

  const createOrUpdateCategory = async () => {
    try {
      setLoadingCreateCategory(true);
      if (category.categoryId) {
        await Axios.put('/payments/categories', category);
      } else {
        await Axios.post('/payments/categories', category);
      }
      setLoadingCreateCategory(false);
      setRefreshToken(Math.random());
      return handleCloseDialogCreateCategory();
    } catch (error) {
      setLoadingCreateCategory(false);
      const errorMessage =
        error && error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Algum erro ocorreu ao criar categoria';

      return console.log(errorMessage);
    }
  };

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    if (fieldName === 'name') {
      return setCategory((oldFields) => ({
        ...oldFields,
        [fieldName]: cleanUpDuplicateSpaces(fieldValue),
      }));
    }

    return setCategory((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      content={
        <Box width={400}>
          <Grid container spacing={1}>
            <Grid item>
              <CustomInput
                name="parentName"
                label="Parente"
                value={category.parentName || 'Principal'}
                disabled
              />
            </Grid>
            <Grid item>
              <CustomInput name="name" label="Nome" value={category.name} onChange={handleChange} />
            </Grid>
            <Grid item>
              <CustomInput name="type" label="Tipo" value={category.type} disabled />
            </Grid>
          </Grid>
        </Box>
      }
      dialogTitle="Nova categoria ou sub-categoria"
      handleClose={handleCloseDialogCreateCategory}
      openDialog={openDialogCreateCategory}
      handleConfirmButton={createOrUpdateCategory}
      confirmButtonLoading={loadingCreateCategory}
      confirmButtonText={category.categoryId ? 'Atualizar' : 'Criar'}
    />
  );
}

export default DialogPaymentCategory;
