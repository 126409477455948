import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import { changeOrderStatus } from '../../../../../services/melhorGestao/orders';

function CancelOrderButton({
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  formChanged,
  setSnackbar,
}) {
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const handleOpenComfirmDialog = () => setOpenDialogConfirm(true);
  const handleCloseComfirmDialog = () => setOpenDialogConfirm(false);

  const handleCancelOrder = async () => {
    const updatedOrder = await changeOrderStatus({
      orderId: orderForm.orderId,
      toStatus: 'Pedido cancelado',
      setLoadingUpdateOrder: setLoadingUpdateStatus,
      setSnackbar,
    });

    if (updatedOrder) {
      setOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
      setInitialOrderForm((oldFields) => ({ ...oldFields, ...updatedOrder }));
      handleCloseComfirmDialog();
    }
  };

  const [blockCancel, setBlockCancel] = useState(true);

  useEffect(() => {
    if (!formChanged) {
      if (orderForm.status === 'Pedido criado' || orderForm.status === 'Pedido confirmado') {
        return setBlockCancel(false);
      }
    }
    return setBlockCancel(true);
  }, [orderForm, formChanged]);

  return (
    <Box>
      <Button
        variant="contained"
        color="secondary"
        disabled={blockCancel}
        onClick={handleOpenComfirmDialog}
      >
        Cancelar
      </Button>
      {openDialogConfirm ? (
        <SimpleDialog
          openDialog={openDialogConfirm}
          handleClose={handleCloseComfirmDialog}
          dialogTitle="Cancelar pedido"
          dialogText={'O status mudará para "Pedido cancelado"'}
          content={
            <Typography>
              Ao cancelar o pedido, a reserva de estoque será cancelada, será possível retornar para
              orçamento e o pedido poderá ser editado.
            </Typography>
          }
          cancelButtonText="Cancelar"
          maxWidth="xs"
          confirmButtonText="Confirmar"
          confirmButtonLoading={loadingUpdateStatus}
          handleCancelButton={handleCloseComfirmDialog}
          handleConfirmButton={handleCancelOrder}
          disableConfirmButton={formChanged}
        />
      ) : null}
    </Box>
  );
}

export default CancelOrderButton;
