import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Link as LinkMaterial,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LinkIcon from '@mui/icons-material/Link';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { numberToReal } from '../../../../../../helpers/formatData';
import { deleteGoogleShoppingProduct } from '../../../../../../services/melhorGestao/googleShopping';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import SimpleImage from '../../../../../Common/SimpleImage';
import LimitTypography from '../../../../../Common/LimitTypography';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    padding: 10,
  },
  thumbnail: {
    width: '100%',
  },
  boxPositionRelative: {
    position: 'relative',
  },
  boxChips: {
    position: 'absolute',
    top: 16,
    width: 588,
  },
  boxDeleteIcon: {
    position: 'absolute',
    bottom: 155,
    right: 430,
  },
  boxEditIcon: {
    position: 'absolute',
    bottom: 155,
    right: 0,
  },
  smallIcon: {
    marginRight: 10,
  },
  sellerLogoGridMark: {
    height: 48,
    borderRadius: 8,
    backgroundColor: '#000',
  },
  sellerLogoImgMark: {
    width: 160,
  },
  gridHeight: {
    height: 48,
  },
  gridPrice: {
    height: 48,
    width: 75,
  },
  gridNoMarginBottom: {
    paddingBottom: '0px!important',
  },
  gridResume: {
    height: 136,
    paddingBottom: '0px!important',
  },
}));
function GoogleShoppingAdResume({ googleShoppingAd, product, setRefreshSearch }) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    message: '',
    open: false,
    type: 'info',
  });

  const [loadingGoogleShoppingAdStatus] = useState(false);
  const [openDialogGoogleShoppingDeleteProduct, setOpenDialogGoogleShoppingDeleteProduct] =
    useState(false);

  const handleCloseSnackbar = () =>
    setSnackbar({
      message: '',
      open: false,
      type: 'info',
    });

  const handleDeleteGoogleShoppingProduct = async () => {
    const company = 'Mark Ferragens';

    await deleteGoogleShoppingProduct(company, googleShoppingAd.id);
    setOpenDialogGoogleShoppingDeleteProduct(false);
    setRefreshSearch(Math.random());

    return setSnackbar({
      message: 'Anúncio excluido com sucesso',
      open: true,
      type: 'success',
    });
  };

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const getQuantity = product.marketplaces?.find(
      (marketplace) => marketplace.marketplaceOwnId === googleShoppingAd.id,
    )?.quantity;

    setQuantity(getQuantity || 0);
  }, [product.marketplaces]);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} className={classes.gridNoMarginBottom}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="caption">Título do anúncio</Typography>
            </Grid>
            <Grid item>
              <LimitTypography text={googleShoppingAd.title} maxLength={55} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridResume}>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                      <Typography variant="caption">Vendedor</Typography>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.sellerLogoGridMark}
                      >
                        <img
                          className={classes.sellerLogoImgMark}
                          src="/assets/imgs/logo-mark-ferragens-white.svg"
                          alt=""
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Preço</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container className={classes.gridPrice} alignItems="center">
                                <Typography variant="caption">R$</Typography>
                                <Typography>
                                  {numberToReal(googleShoppingAd.price.value)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography variant="caption">Situação</Typography>
                            </Grid>

                            <Grid item>
                              <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={classes.gridHeight}
                              >
                                <FiberManualRecordIcon
                                  color={
                                    googleShoppingAd.availability === 'in stock'
                                      ? 'primary'
                                      : 'disabled'
                                  }
                                />

                                <Typography variant="caption" color="textPrimary">
                                  {googleShoppingAd.availability === 'in stock' && 'Ativo'}
                                  {googleShoppingAd.availability === 'out of stock' && 'Pausado'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.boxPositionRelative}>
                  <Grid container spacing={1} className={classes.boxChips} alignItems="center">
                    <Grid item>
                      {loadingGoogleShoppingAdStatus && (
                        <CircularProgress variant="indeterminate" size={26} />
                      )}
                    </Grid>

                    <Grid item>
                      <Chip label={googleShoppingAd.id} />
                    </Grid>

                    <Grid item>
                      <Chip
                        label={`Estoque: ${
                          googleShoppingAd.availability === 'in stock'
                            ? 'Em estoque'
                            : 'Sem estoque'
                        }`}
                      />
                    </Grid>

                    {quantity ? (
                      <Grid item>
                        <Chip label={`Kit com: ${quantity}`} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <SimpleImage
                src={googleShoppingAd.imageLink}
                height={100}
                width={100}
                alt={googleShoppingAd.title}
                className={classes.thumbnail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.boxPositionRelative}>
        <Box className={classes.boxDeleteIcon}>
          <Tooltip
            title={
              <Typography align="center">
                Excluir permanentemente o anúncio do Google Shopping
              </Typography>
            }
          >
            <IconButton size="small" onClick={() => setOpenDialogGoogleShoppingDeleteProduct(true)}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className={classes.boxEditIcon}>
          <Tooltip
            title={<Typography align="center">Link do produto no Google Shopping</Typography>}
          >
            <LinkMaterial href={googleShoppingAd.link} target="_blank" rel="noreferrer">
              <IconButton size="small" className={classes.smallIcon}>
                <LinkIcon />
              </IconButton>
            </LinkMaterial>
          </Tooltip>
        </Box>
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {openDialogGoogleShoppingDeleteProduct && (
        <SimpleDialog
          cancelButtonText="Cancelar"
          content={
            <Grid container>
              <Grid item>
                <Typography align="center">
                  Você está prestes a excluir permanentemente um anúncio
                </Typography>
                <Typography align="center">
                  do Google Shopping, esta ação não poderá ser desfeita.
                </Typography>
              </Grid>
            </Grid>
          }
          confirmButtonText="Excluir"
          dialogTitle="Excluir anúncio do Google Shopping"
          handleCancelButton={() => setOpenDialogGoogleShoppingDeleteProduct(false)}
          handleClose={() => setOpenDialogGoogleShoppingDeleteProduct(false)}
          handleConfirmButton={handleDeleteGoogleShoppingProduct}
          openDialog={openDialogGoogleShoppingDeleteProduct}
        />
      )}
    </Paper>
  );
}

export default GoogleShoppingAdResume;
