import React, { memo } from 'react';
import { Box, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchField from '../Common/SearchField';
import CompanyAutocomplete from '../Common/CompanyAutocomplete';
import FilterSelectOptions from '../Common/FilterSelectOptions';

const useStyles = makeStyles((theme) => ({
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarProducts({
  mercadoLivreConfig,
  setMercadoLivreConfig,
  mercadoLivreConfigsList,
  setMercadoLivreConfigsList,
  submitSearchProducts,
  filtersOptions,
  setFiltersOptions,
}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item xs={2}>
          <Typography variant="h5" className={classes.contrastText}>
            Mercado Livre
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Box width={250} className={classes.selectBackground}>
                {mercadoLivreConfigsList && !setMercadoLivreConfigsList ? (
                  <CompanyAutocomplete
                    handleOnChange={(event, newValue) => {
                      if (newValue) {
                        setMercadoLivreConfig(
                          mercadoLivreConfigsList.find(
                            (mercadoLivreConfigList) =>
                              mercadoLivreConfigList.company === newValue?._id,
                          ) || null,
                        );
                      }
                    }}
                    selectedCompany={mercadoLivreConfig?.company || null}
                    filterCompaniesByIds={mercadoLivreConfigsList.map(
                      (mlConfig) => mlConfig.company,
                    )}
                    label="Contas"
                    variant="filled"
                  />
                ) : null}
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar anúncios"
                filtersOptions={filtersOptions}
                setFiltersOptions={setFiltersOptions}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar anúncio" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarProducts);
