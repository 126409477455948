import React, { memo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomInput from '../../../../CustomInput';
import TitleHelpContent from '../../MercadoLivreDetails/MercadoLivreEditTitle/TitleHelpContent';
import SimpleDialog from '../../../../Common/SimpleDialog';

const useStyles = makeStyles({
  helpTitleTypography: {
    cursor: 'pointer',
  },
});

function MadeiraMadeiraEditTitle({ productForm, adForm, setAdForm, setSnackbar }) {
  const classes = useStyles();

  const [openDialogTitleHelp, setOpenDialogTitleHelp] = useState(false);
  const handleOpenDialogTitleHelp = () => setOpenDialogTitleHelp(true);
  const handleCloseDialogTitleHelp = () => setOpenDialogTitleHelp(false);

  const repeatProductTitle = () => {
    if (productForm.name.length > 100) {
      setSnackbar({
        message:
          'Atenção! O título do anúncio foi cortado pois contém mais de 100 caracteres. Adapte-o para caber em 100 caracteres.',
        open: true,
        type: 'error',
      });
    }

    return setAdForm((oldFields) => ({
      ...oldFields,
      name: productForm.name.substring(0, 100),
    }));
  };

  const handleChangeMadeiraMadeiraTitle = (event) => {
    const name = event.target.value;

    return setAdForm((oldFields) => ({
      ...oldFields,
      name: name.substring(0, 100),
    }));
  };

  return (
    <>
      <CustomInput
        length={100}
        label="Título do anúncio"
        value={adForm.name ? adForm.name : ''}
        onChange={handleChangeMadeiraMadeiraTitle}
        color="secondary"
        name="name"
      />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography onClick={repeatProductTitle} className={classes.helpTitleTypography}>
            Puxar título do produto
          </Typography>
        </Grid>
        <Grid item>
          <Typography onClick={handleOpenDialogTitleHelp} className={classes.helpTitleTypography}>
            Dicas para um bom título
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{adForm.name ? `${adForm.name.length}/100` : '0/100'}</Typography>
        </Grid>
      </Grid>
      {openDialogTitleHelp ? (
        <SimpleDialog
          dialogTitle="Como criar um bom título para o Madeira Madeira"
          openDialog={openDialogTitleHelp}
          handleClose={handleCloseDialogTitleHelp}
          content={<TitleHelpContent />}
        />
      ) : null}
    </>
  );
}

export default memo(MadeiraMadeiraEditTitle);
