import React, { useState, forwardRef } from 'react';
import { AppBar, Dialog, Box, Grid, IconButton, Toolbar, Typography, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SimpleImage from '../../Common/SimpleImage';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function DialogProductImage({
  selectedProduct,
  openDialogProductImages,
  handleCloseDialogProductImages,
}) {
  const [selectedImageSrc, setSelectedImageSrc] = useState(selectedProduct.images[0].src);

  const prevImage = () => {
    if (selectedImageSrc) {
      const currentIndex = selectedProduct.images.findIndex(
        (image) => image.src === selectedImageSrc
      );

      if (currentIndex <= 0) {
        setSelectedImageSrc(selectedProduct.images[selectedProduct.images.length - 1].src);
      } else {
        setSelectedImageSrc(selectedProduct.images[currentIndex - 1].src);
      }
    }
  };

  const nextImage = () => {
    if (selectedImageSrc) {
      const currentIndex = selectedProduct.images.findIndex(
        (image) => image.src === selectedImageSrc
      );

      const imagesLength = selectedProduct.images?.length;

      if (currentIndex >= imagesLength - 1) {
        setSelectedImageSrc(selectedProduct.images[0].src);
      } else {
        setSelectedImageSrc(selectedProduct.images[currentIndex + 1].src);
      }
    }
  };

  return (
    <Dialog
      fullScreen
      open={openDialogProductImages}
      onClose={handleCloseDialogProductImages}
      TransitionComponent={Transition}
      transitionDuration={400}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="secondary"
            onClick={handleCloseDialogProductImages}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Grid container justifyContent="center">
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedProduct.name}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box margin={5} marginTop={10}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <IconButton color="primary" onClick={prevImage}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <SimpleImage
              key={selectedImageSrc}
              src={selectedImageSrc}
              height={750}
              width={750}
              alt={selectedProduct.name}
            />
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={nextImage}>
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
export default DialogProductImage;
