export const productImportTransports = [
  { id: '1', name: '1 - Marítima' },
  { id: '2', name: '2 - Fluvial' },
  { id: '3', name: '3 - Lacustrea' },
  { id: '4', name: '4 - Aérea' },
  { id: '5', name: '5 - Postal' },
  { id: '6', name: '6 - Ferroviária' },
  { id: '7', name: '7 - Rodoviária' },
  { id: '8', name: '8 - Conduto / Rede Transmissão' },
  { id: '9', name: '9 - Meios Próprios' },
  { id: '10', name: '10 - Entrada / Saída ficta' },
  { id: '11', name: '11 - Courier' },
  { id: '12', name: '12 - Em mãos' },
  { id: '13', name: '13 - Por reboque' },
];

export const productImportIntermediations = [
  { id: '1', name: '1 - Importação por conta própria' },
  { id: '2', name: '2 - Importação por conta e ordem' },
  { id: '3', name: '3 - Importação por encomenda' },
];

const productOrigins = [
  {
    id: '0',
    name: '0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8',
  },
  {
    id: '1',
    name: '1 - Estrangeira - Importação direta, exceto a indicada no código 6',
  },
  {
    id: '2',
    name: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7',
  },
  {
    id: '3',
    name: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%',
  },
  {
    id: '4',
    name: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
  },
  {
    id: '5',
    name: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%',
  },
  {
    id: '6',
    name: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural',
  },
  {
    id: '7',
    name: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural',
  },
  {
    id: '8',
    name: '8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%',
  },
];

export default productOrigins;
