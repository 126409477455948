import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import SearchField from '../../Common/SearchField';
import UserAutocomplete from '../../Common/UserAutocomplete';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    width: 180,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function ChequesTopBar({ user, setUser, setFormatedFiltersSituations, submitSearch }) {
  const classes = useStyles();

  const handleUserOnChange = (event, newValue) => {
    if (newValue && newValue._id) {
      setUser(newValue);
    } else {
      setUser(null);
    }
  };

  const situationsFilter = [
    {
      name: 'Novo',
      active: false,
    },
    {
      name: 'Recebido',
      active: false,
    },
    {
      name: 'Repassado',
      active: false,
    },
    {
      name: 'Depositado',
      active: false,
    },
    {
      name: 'Devolvido',
      active: false,
    },
  ];

  const [filterSituations, setFilterSituations] = useState(situationsFilter);

  useEffect(() => {
    setFormatedFiltersSituations(
      filterSituations.filter((option) => option.active).map((option) => option.name)
    );
  }, [filterSituations]);

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" className={classes.topBarTitle}>
            Cheques
          </Typography>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <UserAutocomplete
                handleOnChange={handleUserOnChange}
                selectedUser={user?._id ? user : null}
                label="Cliente"
                variant="filled"
                className={classes.selectBackground}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Situação"
                filtersOptions={filterSituations}
                setFiltersOptions={setFilterSituations}
              />
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearch} labelSearch="Pesquisar cheque" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default ChequesTopBar;
